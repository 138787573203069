import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import LotusAccordion from 'components/dataDisplay/accordion/LotusAccordion';
import PermissionsPanel from './PermissionsPanel';
import { Typography } from '@mui/material';
import LotusSubsectionHeader from 'components/headers/LotusSubsectionHeader';
import LotusSubsectionContent from 'components/dataDisplay/content/LotusSubsectionContent';
import LotusStackedFormItems from 'components/form/LotusStackedFormItems';

export default function CategoriesPanel({ values, errors, readOnly }) {
  const { setFieldValue } = useFormikContext();
  const [initialized, setInitialized] = useState(false);
  const [startingTemplateId, setStartingTemplateId] = useState();

  const resetPermissions = () => {
    values.categories.forEach((category) => {
      category.permissions.forEach((permission) => {
        permission.enabled = false;
      });
    });
  };

  useEffect(() => {
    if (
      (!initialized || values.startingTemplateId !== startingTemplateId) &&
      values.startingTemplate &&
      values.startingTemplate.data &&
      values.categories &&
      values.categories.length > 0
    ) {
      resetPermissions();
      values.startingTemplate.data.forEach((existingPermission) => {
        values.categories.forEach((displayCategory) => {
          displayCategory.permissions.forEach((displayPermission) => {
            if (displayPermission.key === existingPermission.key) {
              displayPermission.enabled = existingPermission.enabled;
            }
          });
        });
      });
      setInitialized(true);
      setStartingTemplateId(values.startingTemplateId);
      // without this doesn't always seem to update the display
      setFieldValue('categories', values.categories);
    }
  }, [values.startingTemplate, values.categories]);

  return (
    values.categories.length > 0 && (
      <>
        <LotusSubsectionHeader title="Permissions" />
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            {values.categories.map((item, index) => {
              return (
                <LotusAccordion
                  key={`category-accordion-${item.id}`}
                  collapsedContent={item.name}
                  expandedContent={
                    <LotusStackedFormItems>
                      {errors.categories && errors.categories[index] && (
                        <Typography variant="body2" color="error">
                          {errors.categories[index]}
                        </Typography>
                      )}
                      <PermissionsPanel
                        key={`category-panel-${item.id}`}
                        permissionsContainer={item}
                        name="categories"
                        categoriesIndex={index}
                        readOnly={readOnly}
                      />
                    </LotusStackedFormItems>
                  }
                  onExpandedChanged={() => {}}
                  showBottomBorder={false}
                  isExpanded={readOnly}
                />
              );
            })}
          </LotusStackedFormItems>
        </LotusSubsectionContent>
      </>
    )
  );
}
