import React from 'react';
import { Grid } from '@mui/material';
import { useField } from 'formik';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import moment from 'moment';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';

const PREFIX = 'DeceasedFieldPanel';

const classes = {
  radioButton: `${PREFIX}-radioButton`,
  radioLabel: `${PREFIX}-radioLabel`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {
    [`& .${classes.radioButton}`]: {
      color: theme.palette.text.primary,
      padding: '5px 5px 5px 9px',
    },
    [`& .${classes.radioLabel}`]: {
      color: theme.palette.text.primary,
      paddingLeft: 10,
      fontSize: 16,
    },
  };
});

export default function DeceasedFieldPanel({ isDeceasedFieldName, deathDateFieldName, causeOfDeathFieldName, showDeathDateField, showCauseOfDeathField, disabled }) {

  const [isDeceasedField] = useField(isDeceasedFieldName);

  return (
    <StyledFormControl>
      <Grid container>
        <Grid item xs={12}>
          <LotusTrueFalseRadioGroup
            name={isDeceasedFieldName}
            label="Deceased Status"
            disabled={disabled}
          />
        </Grid>
        {isDeceasedField.value && showDeathDateField &&
        <Grid item xs={12} style={{marginTop: 24}}>
          <LotusDatePicker
            name={deathDateFieldName}
            label="Death Date"
            maxDate={moment().format('MM/DD/YYYY')}
            disabled={disabled}
            required
          />
        </Grid>
        }
        {isDeceasedField.value && showCauseOfDeathField &&
        <Grid item xs={12} style={{marginTop: 24}}>
          <LotusTextInput
            name={causeOfDeathFieldName}
            label="Cause of Death"
            required
            maxLength={500}
            disabled={disabled}
            multiline
            rows={5}
          />
        </Grid>
        }
      </Grid>
    </StyledFormControl>
  );
}
