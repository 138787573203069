import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import BarrierForm from './BarrierForm';
import GoalList from './GoalList';
import ProgressNoteList from './ProgressNoteList';
import { Stack } from '@mui/material';
import { useProgram } from 'contexts/ProgramContext';
import { Button } from "@lotus/components";
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { useLists } from 'contexts/ListsContext';

export default function BarrierPanel({ barrier, readOnly }) {
  const [barrierEditMode, setBarrierEditMode] = useState(false);

  const { currentProgram } = useProgram();
  const {careCategories, lifeAreas } = useLists();

  const afterBarrierSave = async () => {
    setBarrierEditMode(false);
  };

  // Leaving inline styling here as this doesnt seem like a standard thing
  return barrier && currentProgram &&
    <div style={{padding: 20, borderRadius: '15px', border: '2px solid #4A97A640', backgroundColor: '#F6FAFB'}}>
      {barrierEditMode &&
        <BarrierForm
          barrier={barrier}
          readOnly={readOnly}
          afterSave={afterBarrierSave}
          handleCancel={() => setBarrierEditMode(false)}
        />
      }
      {!barrierEditMode &&
        <>
        <LotusSpacedBlock>
          <Grid container>
            <Grid item xs={9}>
              <div style={{fontSize: 20, fontWeight: 500}}>{barrier.name}</div>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <Stack direction="column" spacing={1}>
                  {currentProgram.barrierCareCategoryEnabled && careCategories && (
                    <Typography variant="body2" color="#7a7a7a">
                      Category: {careCategories.find(cc => cc.id === barrier.careCategoryId)?.name}
                    </Typography>
                  )}
                  {currentProgram.barrierLifeAreaEnabled && lifeAreas && (
                    <Typography variant="body2" color="#7a7a7a">
                      Life Area: {lifeAreas.find(la => la.id === barrier.careLifeAreaId)?.name}
                    </Typography>
                  )}
                </Stack>
                <Button onClick={() => setBarrierEditMode(true)} variant="text">
                  <EditIcon />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </LotusSpacedBlock>
        <GoalList
          readOnly={readOnly}
          barrierId={barrier.id}
          goals={barrier.goals}
        />
        {barrier.progressNotes.length > 0 && (
          <ProgressNoteList
            progressNotes={barrier.progressNotes}
          />
        )}
        </>
      }
    </div>;
}
