import { ELIGIBILITY_APPLICATION_STATUS } from "lib/eligibilityEnums";
import { useState } from "react";
import LotusButton from 'components/widgets/Forms/LotusButton';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';
import { useNavigate } from 'react-router-dom';
import ActionDialog from "components/Dialogs/ActionDialog";
import { Typography } from "@mui/material";
import { useAuthState } from "contexts/AuthProvider";
import { PERMISSIONS } from "lib/permissionEnums";

export default function EligibilityApplicationViewerButton({ appId, appStatusKey, appClientId, appProcessorId, userId }) {

  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const { startEligibilityApplicationReview, continueEligibilityApplication } = useEligibilityApplication();
  const navigate = useNavigate();
  const { verifyPermission } = useAuthState();

  // open application based on application status
  const clickApplicationButton = () => {
    // Warning message for processors if the application is already started review by someone else
    if (ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW === appStatusKey && appProcessorId !== userId && !checkSubmitOnly() && !openWarningDialog) {
      setOpenWarningDialog(true);
    } else {
      openApplication();
    }
  };

  // open application
  const openApplication = async () => {
    if(appId && appClientId) {
      // start review process if processor
      switch(appStatusKey) {
        // start review process if processor
        case ELIGIBILITY_APPLICATION_STATUS.SUBMITTED:
        case ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW:
          if(!checkSubmitOnly()) {
            await startEligibilityApplicationReview(appClientId, appId);
          }
          break;
        case ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED:
          if(checkAdapPermission()) {
            await startEligibilityApplicationReview(appClientId, appId);
          }
          break;
        // continue updating application if case manager
        case ELIGIBILITY_APPLICATION_STATUS.NOT_STARTED:
        case ELIGIBILITY_APPLICATION_STATUS.STARTED:
        case ELIGIBILITY_APPLICATION_STATUS.PENDING:
          await continueEligibilityApplication(appClientId, appId);
          break;
        default: // unknown status
          break;
      }

      setOpenWarningDialog(false);
      navigate(`/client/${appClientId}/eligibility/application/${appId}`);
    }
  }

  // Check the user has only submit permission
  const checkSubmitOnly = () => {
    return (verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) || verifyPermission(PERMISSIONS.APPLICATION_COMPLETER)) &&
       !verifyPermission(PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY) && !verifyPermission(PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY) &&
      !verifyPermission(PERMISSIONS.ADAP_PROCESSOR);
  };
  
  // Check the user has adap review permission
  const checkAdapPermission = () => {
    return verifyPermission(PERMISSIONS.ADAP_PROCESSOR);
  };

  // get button text based on status key
  const getButtonText = () => {
    switch(appStatusKey) {
      case ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW:
        return 'In Review';
      case ELIGIBILITY_APPLICATION_STATUS.NOT_STARTED:
        return 'Start';
      case ELIGIBILITY_APPLICATION_STATUS.STARTED:
        return 'Continue';
      case ELIGIBILITY_APPLICATION_STATUS.PENDING:
        return 'Make Updates';
      case ELIGIBILITY_APPLICATION_STATUS.SUBMITTED:
        return checkSubmitOnly() ? 'View' : 'Start Review';
      case ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED:
        return checkAdapPermission() ? 'Start Review' : 'View';
      default:
        break;
    }
  };
  
  // get button width based on status text
  const getButtonWidth = () => {
    switch(appStatusKey) {
      case ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW:
        return 110;
      case ELIGIBILITY_APPLICATION_STATUS.STARTED:
        return 100;
      case ELIGIBILITY_APPLICATION_STATUS.SUBMITTED:
        return checkSubmitOnly() ? 50 : 140;
      default:
        return 140;
    }
  };

  // closing the warning popup and back to application list
  const acknowledgeMessage = () => {
    openApplication(ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW, appId, appClientId);
  };

  return (
    <>
      <LotusButton 
          onClick={clickApplicationButton}
          variant={checkSubmitOnly() && ELIGIBILITY_APPLICATION_STATUS.SUBMITTED === appStatusKey ? 'outlined' : 'contained'}
          style={{width: getButtonWidth(), maxHeight: 50}}>
        {getButtonText()}
      </LotusButton>
      {openWarningDialog && (
        <ProcessorWarningDialog
          open={openWarningDialog}
          handleClose={() => setOpenWarningDialog(false)}
          handleSubmit={acknowledgeMessage}
        />
      )}
    </>
  );
}

const ProcessorWarningDialog = ({ open, handleClose, handleSubmit }) => {
  return (
    <ActionDialog
      open={open}
      handleClose={handleClose}
      content={
        <>
          <Typography variant='h3'>Another processor may already be working in this application.</Typography>
          <Typography>Are you sure you want to open this application?</Typography>
        </>
      }
      actions={
        <div>
          <LotusButton onClick={handleClose}>
            Back
          </LotusButton>

          <LotusButton
            variant='outlined'
            onClick={() => handleSubmit()}
          >
            Continue
          </LotusButton>
        </div>
      }
    />
  );
};