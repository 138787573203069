import { Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useAuthState } from "contexts/AuthProvider";
import { FieldArray, useField, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import moment from 'moment';
import { PERMISSIONS } from "lib/permissionEnums";
import LotusSpacedBlock from "components/widgets/Layout/LotusSpacedBlock";

export default function SectionCommentsChain({ chainFieldName, disabled }) {

  const [chainField, meta] = useField(chainFieldName);
  const { setFieldValue } = useFormikContext();
  const [newComment, setNewComment] = useState('');
  const [addingComment, setAddingComment] = useState();
  const [lastCommentResolved, setLastCommentResolved] = useState();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const isProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR) || verifyPermission(PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY) || verifyPermission(PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY);

  useEffect(() => {
    if (chainField.value) {
      if (chainField.value.length === 0) {
        setAddingComment(true);
      }
      if (chainField.value.length > 0) {
        setLastCommentResolved(chainField.value[chainField.value.length - 1].resolutionDate);
      }
    }
  }, [chainField.value]);

  return (isProcessor || (chainField && chainField.value && chainField.value.length > 0)) && (
    <FieldArray 
      name={chainFieldName}
      render={() => {
        
        const addComment = () => {
          const newItem = {
            commentText: newComment,
            commenterId: loggedInUser.id,
            commenterName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
            commentDate: moment().format('MM/DD/YYYY')
          }
          setAddingComment(false);
          setNewComment('');
          setFieldValue(chainFieldName, [...chainField.value, newItem]);
        };

        const resolveComment = () => {
          // mark any unresolved comments as resolved
          chainField.value.forEach(comm => {
            if (!comm.resolverId) {
              comm.resolverId = loggedInUser.id;
              comm.resolverName = loggedInUser.firstName + ' ' + loggedInUser.lastName;
              comm.resolutionDate = moment().format('MM/DD/YYYY');
            }
          });
          
          setFieldValue(chainFieldName, [...chainField.value]);
        };

        return (
          <Paper style={{marginLeft: 20, padding: 20, paddingTop: 5, position: 'sticky', top: 40, maxWidth: 400}}>
            <LotusSpacedBlock>
              <div style={{fontSize: 20, fontWeight: 500}}>{isProcessor ? "Contact Application Submitter" : "Update Requested"}</div>
            </LotusSpacedBlock>
            {chainField.value && chainField.value.length > 0 &&
              chainField.value.map((item, index) => {
                return (
                  <div style={{marginBottom: 10}} key={index}>
                    <div style={{paddingLeft: 10, backgroundColor: "#e3e3e3", borderBottom: "1px solid black"}}>
                      <div style={{paddingTop: 10, fontWeight: 200}}>{item.commenterName}</div>
                      <div style={{paddingTop: 5, fontWeight: 200}}>{item.commentDate}</div>
                      <div style={{paddingTop: 5, paddingBottom: 5}}>{item.commentText}</div>
                    </div>
                    {item.resolutionDate &&
                      <Stack direction="column" alignItems="flex-end" style={{marginTop: 5, fontWeight: 500, color: "#021365"}}>
                        <div>Marked Complete by {item.resolverName}</div>
                        <div>{item.resolutionDate}</div>
                      </Stack>
                    }
                  </div>
                );
              })
            }
            {meta.touched && meta.error && (
              <LotusSpacedBlock>
                <Typography variant="body2" color="error">
                  {meta.error}
                </Typography>
              </LotusSpacedBlock>
            )}
            {!disabled &&
              <>
              <LotusSpacedBlock>
                {addingComment &&
                  <TextField 
                    label={isProcessor ? "Application Request" : "Reply"}
                    placeholder={isProcessor ? "Enter your request for additional information or change to the application submitter here....." : ""}
                    value={newComment} 
                    onChange={(e) => setNewComment(e.target.value)}
                    style={{width: "100%"}}
                    multiline={true}
                    rows={5}
                  />
                }
              </LotusSpacedBlock>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                {addingComment &&
                <Button variant="outlined" onClick={() => {setAddingComment(false); setNewComment('')}}>
                  Cancel
                </Button>  
                }
                {addingComment && isProcessor &&
                <Button variant="contained" disabled={!newComment} onClick={addComment}>
                  Save Request
                </Button>   
                }  
                {chainField.value.length > 0 && !addingComment && !lastCommentResolved &&
                <Button variant="outlined" onClick={resolveComment}>
                  Resolve Comment
                </Button>   
                }  
                {chainField.value.length > 0 && !addingComment && isProcessor &&
                <Button variant="outlined" onClick={() => setAddingComment(true)}>
                  Add Request
                </Button>   
                } 
                {chainField.value.length > 0 && !addingComment && !isProcessor &&
                <Button variant="outlined" onClick={() => setAddingComment(true)}>
                  Reply
                </Button>   
                }  
                {addingComment && !isProcessor &&
                <Button variant="contained" disabled={!newComment} onClick={addComment}>
                  Reply
                </Button>   
                }  
              </Stack>
              </>
            }
          </Paper>
        );
      }}
    />
  );
}
