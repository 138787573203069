import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import { MetricsView } from "./MetricsView";
import { cloneDeep } from "lodash";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

export function GranteeAgencyDashboardsView({
  dashboardConfig,
  agencyMetrics,
  cycleTimeMetrics,
  processorProductivityMetrics,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if (dashboardConfig && agencyMetrics && !initialValues) {
      const newInitialValues = {
        dashboardConfig: {
          cycleTimeDashboardName: dashboardConfig.cycleTimeDashboardName || 'Cycle Time Dashboard',
          processorProductivityDashboardName: dashboardConfig.processorProductivityDashboardName || 'Processor Productivity Dashboard',
        },
        agencyMetrics: agencyMetrics.map(am => cloneDeep(am))
      };
      setInitialValues(newInitialValues);
    }
  }, [dashboardConfig, agencyMetrics]);

  return initialValues && cycleTimeMetrics && processorProductivityMetrics && (
    <LotusStaticForm
      title="Dashboards"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 700}}>
        <LotusSubsectionHeader title="Cycle Time Dashboard" divider={false}/>
        <LotusSubsectionContent>
          <LotusTextInput
            name="dashboardConfig.cycleTimeDashboardName"
            label="Cycle Time Dashboard Name"
            required
          />
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Cycle Time Metrics"/>
        <LotusSubsectionContent excludeTopPadding>
          <MetricsView metrics={cycleTimeMetrics}/>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Processor Productivity Dashboard"/>
        <LotusSubsectionContent>
          <LotusTextInput
            name="dashboardConfig.processorProductivityDashboardName"
            label="Processor Productivity Dashboard Name"
            required
          />
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Processor Productivity Metrics"/>
        <LotusSubsectionContent excludeTopPadding>
          <MetricsView metrics={processorProductivityMetrics}/>
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
  );
}