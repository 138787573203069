import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import { useEffect, useState } from "react";
import ClientInfoConfigView from "./ClientInfoConfigView";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import LotusMultiSelect from "components/form/select/LotusMultiSelect";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";


export function GranteeAgencyClientInfoView({
  clientInfoConfig,
  clientInfoCustomLists,
  clientRelationships,
  hispanicEthnicities,
  agencyLanguages,
  allLanguages,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (clientInfoConfig && agencyLanguages) {
      const newInitialValues = {
        registration: {
          showMiddleName: clientInfoConfig.registration.showMiddleName || false,
          showNameSuffix: clientInfoConfig.registration.showNameSuffix || false,
          showPreferredName: clientInfoConfig.registration.showPreferredName || false,
          showBirthGender: clientInfoConfig.registration.showBirthGender || false,
          showGenderPronouns: clientInfoConfig.registration.showGenderPronouns || false,
          showPrimaryAddress: clientInfoConfig.registration.showPrimaryAddress || false,
          showPhoneNumbers: clientInfoConfig.registration.showPhoneNumbers || false,
          showEmailAddress: clientInfoConfig.registration.showEmailAddress || false,
          showPrimaryPhoneType: clientInfoConfig.registration.showPrimaryPhoneType || false,
          showCellPhone: clientInfoConfig.registration.showCellPhone || false,
          showHomePhone: clientInfoConfig.registration.showHomePhone || false,
          showWorkPhone: clientInfoConfig.registration.showWorkPhone || false,
          showOtherPhone: clientInfoConfig.registration.showOtherPhone || false,
        },
        existingClients: {
          // Client Details - Basic Information
          externalSystems: clientInfoConfig.existingClients.externalSystems || [],
          showMiddleName: clientInfoConfig.existingClients.showMiddleName || false,
          showNameSuffix: clientInfoConfig.existingClients.showNameSuffix || false,
          showPreferredName: clientInfoConfig.existingClients.showPreferredName || false,
          showSocialSecurityNumber: clientInfoConfig.existingClients.showSocialSecurityNumber || false,
          showExternalId: clientInfoConfig.existingClients.showExternalId || false,
          showGrandfathered: clientInfoConfig.existingClients.showGrandfathered || false,
          // Client Details - Gender
          showGenderSection: clientInfoConfig.existingClients.showGenderSection || false,
          showBirthGender: clientInfoConfig.existingClients.showBirthGender || false,
          showCurrentGenderIdentity: clientInfoConfig.existingClients.showCurrentGenderIdentity || false,
          showGenderPronouns: clientInfoConfig.existingClients.showGenderPronouns || false,
          // Client Details - Phone and Email
          showClientPhoneAndEmailSection: clientInfoConfig.existingClients.showClientPhoneAndEmailSection || false,
          showPrimaryPhoneType: clientInfoConfig.existingClients.showPrimaryPhoneType || false,
          showConfidentialityRequested: clientInfoConfig.existingClients.showConfidentialityRequested || false,
          showCellPhone: clientInfoConfig.existingClients.showCellPhone || false,
          showCommunicationByText: clientInfoConfig.existingClients.showCommunicationByText || false,
          showHomePhone: clientInfoConfig.existingClients.showHomePhone || false,
          showWorkPhone: clientInfoConfig.existingClients.showWorkPhone || false,
          showOtherPhone: clientInfoConfig.existingClients.showOtherPhone || false,
          showPhoneCallConsent: clientInfoConfig.existingClients.showPhoneCallConsent || false,
          showVoiceMailConsent: clientInfoConfig.existingClients.showVoiceMailConsent || false,
          showEmailAddress: clientInfoConfig.existingClients.showEmailAddress || false,
          showAdditionalEmailAddresses: clientInfoConfig.existingClients.showAdditionalEmailAddresses || false,
          showEmailConsent: clientInfoConfig.existingClients.showEmailConsent || false,
          showPrefProgNotifMethod: clientInfoConfig.existingClients.showPrefProgNotifMethod || false,
          showCommunicationNotes: clientInfoConfig.existingClients.showCommunicationNotes || false,
          // Client Details - Race
          showRaceSection: clientInfoConfig.existingClients.showRaceSection || false,
          showAsianPicklist: clientInfoConfig.existingClients.showAsianPicklist || false,
          showNhpiPicklist: clientInfoConfig.existingClients.showNhpiPicklist || false,
          // Client Details - Ethnicity
          showEthnicitySection: clientInfoConfig.existingClients.showEthnicitySection || false,
          showHispanicEthnicityPicklist: clientInfoConfig.existingClients.showHispanicEthnicityPicklist || false,
          selectedHispanicEthnicities: clientInfoConfig.existingClients.selectedHispanicEthnicities || [],
          // Client Details - Language
          showLanguageSection: clientInfoConfig.existingClients.showLanguageSection || false,
          showSecondaryLanguage: clientInfoConfig.existingClients.showSecondaryLanguage || false,
          showPreferredWrittenCommLanguageId: clientInfoConfig.existingClients.showPreferredWrittenCommLanguageId || false,
          // Client Details - Other Demographics
          showOtherDemographicsSection: clientInfoConfig.existingClients.showOtherDemographicsSection || false,
          showMaritalStatus: clientInfoConfig.existingClients.showMaritalStatus || false,
          showVeteranStatus: clientInfoConfig.existingClients.showVeteranStatus || false,
          showLawfulResidentDate: clientInfoConfig.existingClients.showLawfulResidentDate || false,
          showCitizenshipStatus: clientInfoConfig.existingClients.showCitizenshipStatus || false,
          showIsTobaccoUser: clientInfoConfig.existingClients.showIsTobaccoUser || false,
          // Client Details - Comments
          showClientDetailAdditionalInformation: clientInfoConfig.existingClients.showClientDetailAdditionalInformation || false,
          showAdditionalAssistanceInterests: clientInfoConfig.existingClients.showAdditionalAssistanceInterests || false,
          additionalAssistanceInterests: clientInfoConfig.existingClients.additionalAssistanceInterests || [],
          // Client Details - Deceased
          showDeceasedSection: clientInfoConfig.existingClients.showDeceasedSection || false,
          showDeathDate: clientInfoConfig.existingClients.showDeathDate || false,
          showCauseOfDeath: clientInfoConfig.existingClients.showCauseOfDeath || false,
          // Addresses
          showSecondaryAddress: clientInfoConfig.existingClients.showSecondaryAddress || false,
          selectedClientRelationships: clientInfoConfig.existingClients.selectedClientRelationships || [],
          showLegalGuardian: clientInfoConfig.existingClients.showLegalGuardian || false,
          showCaregiverOfClient: clientInfoConfig.existingClients.showCaregiverOfClient || false,
          showNextOfKin: clientInfoConfig.existingClients.showNextOfKin || false,
          showPowerOfAttorney: clientInfoConfig.existingClients.showPowerOfAttorney || false,
          showAwareOfHivStatus: clientInfoConfig.existingClients.showAwareOfHivStatus || false,
          // Income
          useMagi: clientInfoConfig.existingClients.useMagi || false,
          // Benefits
          showMedicarePolicyHolder: clientInfoConfig.existingClients.showMedicarePolicyHolder || false,
          showMedicaidRx: clientInfoConfig.existingClients.showMedicaidRx || false,
          showMedicareRx: clientInfoConfig.existingClients.showMedicareRx || false,
          showBenefitSubsidies: clientInfoConfig.existingClients.showBenefitSubsidies || false,
          // Health
          showHivStatusSubcategory: clientInfoConfig.existingClients.showHivStatusSubcategory || false,
          // Insurance
          showDentalInsurance: clientInfoConfig.existingClients.showDentalInsurance || false,
          showVisionInsurance: clientInfoConfig.existingClients.showVisionInsurance || false,
          acaUsesHealthPlanDatabase: clientInfoConfig.existingClients.acaUsesHealthPlanDatabase || false,
          employerSponsoredUsesHealthPlanDatabase: clientInfoConfig.existingClients.employerSponsoredUsesHealthPlanDatabase || false,
          individualMarketplaceUsesHealthPlanDatabase: clientInfoConfig.existingClients.individualMarketplaceUsesHealthPlanDatabase || false,
          medicareUsesHealthPlanDatabase: clientInfoConfig.existingClients.medicareUsesHealthPlanDatabase || false,
          medicarePartDUsesHealthPlanDatabase: clientInfoConfig.existingClients.medicarePartDUsesHealthPlanDatabase || false,
          medicaidUsesHealthPlanDatabase: clientInfoConfig.existingClients.medicaidUsesHealthPlanDatabase || false,
          visionUsesHealthPlanDatabase: clientInfoConfig.existingClients.visionUsesHealthPlanDatabase || false,
          dentalUsesHealthPlanDatabase: clientInfoConfig.existingClients.dentalUsesHealthPlanDatabase || false,
        },
        // control will set these, being maintained in tables rather than json config
        clientInfoDetailsDocumentationTypes: [], 
        clientInfoAddressDocumentationTypes: [], 
        clientInfoHivDocumentationTypes: [],
        clientInfoIncomeDocumentationTypes: [],
        clientInfoInsuranceDocumentationTypes: [],
        clientInfoIncomeSourceTypes: [],
        newClientInfoDetailsDocumentationType: '', 
        newClientInfoAddressDocumentationType: '', 
        newClientInfoHivDocumentationType: '',
        newClientInfoIncomeDocumentationType: '',
        newClientInfoInsuranceDocumentationType: '',
        newClientInfoIncomeSourceType: '',
        selectedLanguages: agencyLanguages.map((al) => al.languageId)
      };
      setInitialValues(newInitialValues);
    }
  }, [clientInfoConfig, agencyLanguages]);

  return initialValues && (
    <LotusStaticForm
      title="Client Info"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 600}}>
        <ClientInfoConfigView
          clientInfoCustomLists={clientInfoCustomLists}
          clientRelationships={clientRelationships}
          hispanicEthnicities={hispanicEthnicities}
        />
        <LotusSubsectionHeader title="Agency Languages"/>
        <LotusSubsectionContent>
          <LotusMultiSelect
            name="selectedLanguages"
            label="Select Languages"
            items={allLanguages} 
          />
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
  );
}