import React from 'react';
import { DataDictionaryProvider } from 'contexts/DataDictionaryContext';
import DataDictionaryList from './DataDictionaryList';

export default function DataDictionaryForm() {

  return (
    <DataDictionaryProvider>
      <DataDictionaryList />
    </DataDictionaryProvider>
  );
}
