export const messageTypes = {
  REQUEST_CREATION_SUCCESS: 'REQUEST_CREATION_SUCCESS',
  APPROVING_PERMISSION_TEMPLATE_REQUEST_SUCCESS: 'APPROVING_PERMISSION_TEMPLATE_REQUEST_SUCCESS',
  DENYING_PERMISSION_TEMPLATE_REQUEST_SUCCESS: 'DENYING_PERMISSION_TEMPLATE_REQUEST_SUCCESS',
  LOADING_PENDING_REQUESTS_SUCCESS: 'LOADING_PENDING_REQUESTS_SUCCESS',
  LOADING_RECENT_APPROVALS_SUCCESS: 'LOADING_RECENT_APPROVALS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.REQUEST_CREATION_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [payload.request, ...state.pendingRequests] : undefined;
        return {
          ...state,
          pendingRequests: newRequests
        };
      }
    case messageTypes.APPROVING_PERMISSION_TEMPLATE_REQUEST_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [...state.pendingRequests.filter(r => r.id !== payload.approval.id)] : undefined;
        const newApprovals = state.recentApprovals ? [payload.approval, ...state.recentApprovals] : undefined;
        return {
          ...state,
          pendingRequests: newRequests,
          recentApprovals: newApprovals
        };
      }
    case messageTypes.DENYING_PERMISSION_TEMPLATE_REQUEST_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [...state.pendingRequests.filter(r => r.id !== payload.denial.id)] : undefined;
        const newApprovals = state.recentApprovals ? [payload.denial, ...state.recentApprovals] : undefined;
        return {
          ...state,
          pendingRequests: newRequests,
          recentApprovals: newApprovals
        };
      }
    case messageTypes.LOADING_PENDING_REQUESTS_SUCCESS:
    case messageTypes.LOADING_RECENT_APPROVALS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
