import React from 'react';

// Just standardizing border width
// Should match what's used in page header
export default function LotusPageContent({ children }) {

  return (
    <div style={{padding: "0 24px"}}>
      {children}
    </div>
  );
}
