import { useState } from "react";
import LotusSectionHeader from "components/headers/LotusSectionHeader";
import LotusButtonList from "components/button/LotusButtonList";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusDialog from "components/feedback/dialog/LotusDialog";
import IntegrationListView from "./IntegrationListView";
import IntegrationView from "./IntegrationView";

export default function GranteeAgencyIntegrationsView({
  integrations,
  agencyConnectors,
  integrationDetailLoaderFunc,
  handleSave
}) {
  
  const [addingIntegration, setAddingIntegration] = useState(false);

  const handleCancel = () => {
    setAddingIntegration(false);
  }

  const localHandleSave = async (values) => {
    await handleSave(values);
    setAddingIntegration(false);
  }

  return integrations && agencyConnectors && (
    <>
      <LotusSectionHeader 
        title="Integrations"
        actionButtonList={
          <LotusButtonList>
            <LotusAddButton onClick={() => setAddingIntegration(true)} itemLabel="New Integration" />
          </LotusButtonList>
        }
      />
      <IntegrationListView
        integrations={integrations}
        agencyConnectors={agencyConnectors}
        integrationDetailLoaderFunc={integrationDetailLoaderFunc}
        handleSave={handleSave}
      />
      <LotusDialog
        staticWidth='sm'
        open={addingIntegration}
        handleClose={handleCancel}
        content={
          <IntegrationView 
            agencyConnectors={agencyConnectors}
            existingIntegrationNames={integrations.map(i => i.name)}
            handleSave={localHandleSave}
            handleCancel={handleCancel} />
        }
      />
    </>
  );
}