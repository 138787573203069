import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";
import LotusPhoneInput from "components/form/inputField/common/LotusPhoneInput";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusFormItemsRow from "components/form/LotusFormItemsRow";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSelect from "components/form/select/LotusSelect";
import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useProperties } from "contexts/PropertiesContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";

export default function GranteeAgencyDetails() {

  const [logInProviders, setLogInProviders] = useState();

  const { properties } = useProperties();
  const {agency, updateAgencyOptionalFeatures } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  useEffect(() => {

    const providers = [];
    providers.push({ value: 'local', label: 'Application Managed' });
    if (properties) {
      const idps = JSON.parse(properties['REACT_APP_EXTERNAL_IDPS']);
      idps.forEach((idp) => {
        providers.push({ value: idp.ProviderName, label: idp.ProviderName + ' (External)' });
      });
    }
    setLogInProviders(providers);

  }, [properties]);

  const saveOptionalFeatures = async (values) => {
    await updateAgencyOptionalFeatures(values.optionalFeatures);
    await loadUserAgency();
  };

  return (
    agency && <GranteeAgencyDetailsView
      granteeAgency={agency}
      logInProviders={logInProviders}
      handleSave={saveOptionalFeatures}
      handleCancel={() => {}} // staying on page, just reverting changes
    />
  );
}

export function GranteeAgencyDetailsView({
  granteeAgency,
  logInProviders,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues({
      id: granteeAgency.id,
      name: granteeAgency.name || '-',
      shortName: granteeAgency.shortName || '-',
      mailingAddress: {
        address1: granteeAgency.address.address1 || '-', 
        address2: granteeAgency.address.address2 || '-', 
        city: granteeAgency.address.city || '-', 
        state: granteeAgency.address.state || '-', 
        zip: granteeAgency.address.zip || '-',
        county: granteeAgency.address.county || '-'
      },
      email: granteeAgency.email,
      phoneNumber: granteeAgency.phoneNumber,
      optionalFeatures: {
        summary: granteeAgency.optionalFeatures?.summary || false,
        eligibility: granteeAgency.optionalFeatures?.eligibility || false,
        idp: granteeAgency.optionalFeatures?.idp || 'local',
        dashboard: granteeAgency.optionalFeatures?.dashboard || false,
        subdomain: granteeAgency.optionalFeatures?.subdomain || '',
      }
    });
  }, [granteeAgency]);

  return initialValues && logInProviders && (
    <LotusStaticForm
      title="Agency Details"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 500}}>
        <LotusSubsectionHeader title="Basic Information" divider={false}/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusFormItemsRow>
              <LotusTextInput
                name="name"
                label="Agency Name"
                readOnly
              />
              <LotusTextInput
                name="shortName"
                label="Agency Short Name"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="email"
                label="Email Address"
                readOnly
              />
              <LotusPhoneInput
                name="phoneNumber"
                label="Phone Number"
                readOnly
              />
            </LotusFormItemsRow>
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Mailing Address"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.address1"
                label="Address"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.address2"
                label="Apt / Lot / Floor"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.city"
                label="City"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.state"
                label="State"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.zip"
                label="Zip Code"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.county"
                label="County"
                readOnly
              />
            </LotusFormItemsRow>
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Optional Features"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusSwitch
              name="optionalFeatures.summary"
              label="Summary Enabled"
            />
            <LotusSwitch
              name="optionalFeatures.eligibility"
              label="Eligibility Enabled"
            />
            <LotusSwitch
              name="optionalFeatures.dashboard"
              label="Dashboard Enabled"
            />
            {logInProviders && (
              <LotusSelect
                name="optionalFeatures.idp"
                label="Log In Provider"
                items={logInProviders}
                disallowNoSelectionIfNotRequired
              />
            )}
            <LotusTextInput
              name="optionalFeatures.subdomain"
              label="Subdomain"
              regexToMatch='^[0-9a-zA-Z\-]{2,255}$'
              regexValidationMessage='Subdomain can contain only letters, numbers and hyphens (-)'
            />
          </LotusStackedFormItems>
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
  );
}