import * as Yup from 'yup';

const addCustomMethods = () => {
  Yup.addMethod(
    Yup.string,
    'optionalMatches',
    function (regexStr, ignoreValue, errorMessage) {
      return this.test(`optionalMatches`, errorMessage, function (value) {
        if (value && (!ignoreValue || ignoreValue !== value)) {
          const regex = new RegExp(regexStr);
          const matches = regex.test(value);
          return matches;
        }

        return true;
      });
    }
  );
};

const createValidationSchemaForField = (schema, field) => {
  const { id, validation } = field;
  if (field.type == 'multi-object') {
    const objectSchema = Yup.object(field.itemFields.reduce(createValidationSchemaForField, {}));
    const arraySchema = Yup.array().of(objectSchema);
    schema[id] = arraySchema;
  } 
  else if (validation) {
    const fieldValidator = createFieldValidator(validation);
    schema[id] = fieldValidator;
  }
  return schema;
};

const createFieldValidator = (validation) => {

  let validator = Yup[validation.type]();

  validation.checks.forEach((check) => {
    const { parameters, type: checkType, errorMessage, nullable } = check;

    if (!validator[checkType]) {
      console.log({ message: `No validator for check of type ${checkType}` });
      return;
    }

    if (checkType === 'when') {
      const criteria = check.parameters[1];

      if (criteria.then && criteria.then.checks) {
        const val = createFieldValidator(criteria.then);
        criteria.then = () => val;
      }

      if (criteria.otherwise && criteria.otherwise.checks) {
        const val = createFieldValidator(criteria.otherwise);
        criteria.otherwise = () => val;
      }
    }

    if (checkType === 'matches') {
      parameters[0] = new RegExp(parameters[0], 'g');
    }

    const checkParameters = parameters ? [...parameters, errorMessage] : [errorMessage];
    validator = validator[checkType](...checkParameters);
    if (nullable) {
      validator = validator.nullable();
    }
  });
  
  return validator;
};

export function createValidationSchemaForForm(formDefinition) {
  addCustomMethods();

  if (formDefinition.fields) {
    return Yup.object().shape(
      formDefinition.fields.reduce(createValidationSchemaForField, {})
    );
  }

  if (formDefinition.sections) {
    let schema = {};
    formDefinition.sections.forEach(section => {
      section.fields.reduce(createValidationSchemaForField, schema);
    });
    return Yup.object().shape(schema);
  }

  return {};
}

// NOTE: These are basically the same as the funcs above, but specifically
// building a validation schema excluding the required fields.  Could have tried
// to pass a "excludeRequiredFields" flag but cant do that super cleanly with
// the reducers and didn't want to make things even more confusing than they
// already are.  So largely duplicated the code above to do it for non-required 
// fields only here.
// NOTE: This doesnt work with fields of type 'address' for now as we cant 
// specify the validation of the underlying fields.  Will fix that later if it becomes
// a necessity
const createValidationSchemaExcludingRequiredChecksForField = (schema, field) => {
  const { id, validation } = field;
  if (field.type === 'multi-object') {
    const objectSchema = Yup.object(field.itemFields.reduce(createValidationSchemaExcludingRequiredChecksForField, {}));
    const arraySchema = Yup.array().of(objectSchema);
    schema[id] = arraySchema;
  } 
  else if (validation) {
    const fieldValidator = createFieldValidatorExcludingRequiredChecks(validation);
    if (fieldValidator) {
      schema[id] = fieldValidator;
    }
  }
  return schema;
};

const createFieldValidatorExcludingRequiredChecks = (validation) => {

  let validator = Yup[validation.type]();

  validation.checks.forEach((check) => {
    const { parameters, type: checkType, errorMessage, nullable } = check;

    if (checkType === 'required') {
      return;
    }

    if (!validator[checkType]) {
      console.log({ message: `No validator for check of type ${checkType}` });
      return;
    }

    if (checkType === 'when') {
      const criteria = check.parameters[1];

      if (criteria.then && criteria.then.checks) {
        const val = createFieldValidatorExcludingRequiredChecks(criteria.then);
        criteria.then = () => val;
      }

      if (criteria.otherwise && criteria.otherwise.checks) {
        const val = createFieldValidatorExcludingRequiredChecks(criteria.otherwise);
        criteria.otherwise = () => val;
      }
    }

    if (checkType === 'matches') {
      parameters[0] = new RegExp(parameters[0], 'g');
    }

    const checkParameters = parameters ? [...parameters, errorMessage] : [errorMessage];
    validator = validator[checkType](...checkParameters);
    if (nullable) {
      validator = validator.nullable();
    }
  });
  
  return validator;
};

export function createValidationSchemaExcludingRequiredChecksForForm(formDefinition) {
  addCustomMethods();

  if (formDefinition.fields) {
    return Yup.object().shape(
      formDefinition.fields.reduce(createValidationSchemaExcludingRequiredChecksForField, {})
    );
  }

  if (formDefinition.sections) {
    let schema = {};
    formDefinition.sections.forEach(section => {
      section.fields.reduce(createValidationSchemaExcludingRequiredChecksForField, schema);
    });
    return Yup.object().shape(schema);
  }

  return {};
}


