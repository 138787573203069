import React from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useField } from 'formik';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

// Note that styling here is duplicated within LotusRadio
// Not using LotusRadio here as those are intended to be used individually
const PREFIX = 'LotusRadioGroup';

const classes = {
  radioButton: `${PREFIX}-radioButton`,
  radioLabel: `${PREFIX}-radioLabel`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {
    [`& .${classes.radioButton}`]: {
      color: theme.palette.text.primary,
      padding: '5px 5px 5px 9px',
    },
    [`& .${classes.radioLabel}`]: {
      color: theme.palette.text.primary,
      paddingLeft: 10,
      fontSize: 16,
    },
  };
});

export default function LotusRadioGroup({ required, dontCheckRequiredFields, label, labelStyle, items, handleChange, ...props }) {
  const [field, meta, utils] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && (!val)) {
      return `${label} is required`;
    }
  }});

  let lblStyle = { ...labelStyle };
  lblStyle.paddingBottom = 5;
  const labelText = label + (required ? ' * ' : '');

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    if (handleChange) {
      handleChange(newValue);
    }
    utils.setTouched(true);
    utils.setValue(e.currentTarget.value);
  };

  return (
    !props.disabled ? 
    <StyledFormControl component="fieldset">
      <FormLabel component="legend">
        <Typography style={lblStyle} variant="body1" color="textPrimary">
          {labelText}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-label={label}
        id={field.name}
        name={field.name}
        value={field.value}
        onChange={handleLocalChange}
        {...props}
      >
        {items &&
          items.map((option) => (
            <FormControlLabel
              key={option.value}
              classes={{
                label: classes.radioLabel,
              }}
              control={
                <Radio
                  checked={field.value === option.value}
                  disabled={props.disabled}
                  className={classes.radioButton}
                />
              }
              label={option.label}
              value={option.value.toString()}
            />
          ))}
      </RadioGroup>

      <Typography variant="body2" color="error">
        {meta.touched && meta.error}
      </Typography>
    </StyledFormControl> :
    <LotusReadOnlyFieldView label={label} value={(items && items.find(x => x.value === field.value)?.label) || ''} />
  );
}
