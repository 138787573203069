import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ListsContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  icd10Codes: undefined,
  languages: undefined,
  states: undefined,
  us_states: undefined,
  countiesByState: undefined,
  eligibilityApplicationStatuses: undefined
};

const ListsContext = createContext();

export const ListsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ListsContext.Provider value={{ state, dispatch }}>
      {children}
    </ListsContext.Provider>
  );
};

// This is a context for the miscellaneous lists of things (e.g., picklists)
// that dont really have a home that suits them
export const useLists = () => {
  const { state, dispatch } = useContext(ListsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadIcd10Codes = async () => {
    addBusyBee('loadIcd10Codes');

    try {
      const { data: icdData } = await axios.get('api/staticList/icd_codes');
      const listData = parseApiResult(icdData).body;

      const icdList = listData.map((node) => {
        return {
          name: node.icd_code + '-' + node.icd_description,
          id: node.icd_code,
        };
      });

      dispatch({
        type: messageTypes.LOADING_ICD10_CODES_SUCCESS,
        payload: {
          icd10Codes: icdList,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadIcd10Codes');
    }
  };

  const loadLanguages = async () => {
    addBusyBee('loadLanguages');

    try {
      const { data: languageData } = await axios.get('api/staticList/languages');
      const listData = parseApiResult(languageData).body;

      dispatch({
        type: messageTypes.LOADING_LANGUAGES_SUCCESS,
        payload: {
          languages: listData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadLanguages');
    }
  };

  const loadProgramTypes = async () => {
    addBusyBee('loadProgramTypes');

    try {
      const { data: programTypesData } = await axios.get('api/staticList/program_types');
      const listData = parseApiResult(programTypesData).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_TYPES_SUCCESS,
        payload: {
          programTypes: listData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramTypes');
    }
  };

  const loadMeetingStatuses = async () => {
    addBusyBee('loadMeetingStatuses');

    try {
      const { data } = await axios.get('api/staticList/meeting_statuses');
      const listData = parseApiResult(data).body;

      const unique = [
        ...new Map(listData.map((item) => [item.name, item])).values(),
      ];

      dispatch({
        type: messageTypes.LOADING_MEETING_STATUSES_SUCCESS,
        payload: {
          meetingStatuses: unique,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMeetingStatuses');
    }
  };

  const loadStates = async () => {
    addBusyBee('loadStates');
  
    try {
      const { data: stateData } = await axios.get('api/staticList/states');
      const listData = parseApiResult(stateData).body;
  
      dispatch({
        type: messageTypes.LOADING_STATES_SUCCESS,
        payload: {
          states: listData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadStates');
    }
  };

  const loadCountiesForState = async (st) => {
    addBusyBee('loadCountiesForState');
  
    try {
      const { data: countyData } = await axios.get(`api/staticList/counties?state=${st}`);
      const listData = parseApiResult(countyData).body;
  
      const countyList = listData.map((node) => {      
        return node.county_name
      });
      
      dispatch({
        type: messageTypes.LOADING_COUNTIES_FOR_STATE_SUCCESS,
        payload: {
          state: st,
          counties: countyList,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadCountiesForState');
    }
  };

  const loadClientInfoPicklists = async (st) => {
    addBusyBee('loadClientInfoPicklists');
  
    try {
      const { data: picklistData } = await axios.get(`api/staticList/client_info_picklists`);
      const listData = parseApiResult(picklistData).body;

      const getList = (picklistName) => {
        return listData.filter(x => x.picklistTypeName === picklistName).sort((a,b) => a.itemSequence - b.itemSequence);
      }

      const birthGenders = getList('birth_gender');
      const currentGenderIdentities = getList('current_gender_identity');
      const phoneTypes = getList('phone_type');
      const missingSsnReasons = getList('missing_ssn_reason');
      const preferredProgramNotificationMethods = getList('pref_prog_notif_method');
      const races = getList('race');
      const asianRaces = getList('asian_race');
      const nhpiRaces = getList('nhpi_race');
      const hispanicEthnicities = getList('hispanic_ethnicity');
      const maritalStatuses = getList('marital_status');
      const citizenshipStatuses = getList('citizenship_status');
      const housingStatuses = getList('housing_status');
      const temporaryHousingTypes = getList('temporary_housing_type');
      const stableHousingTypes = getList('stable_housing_type');
      const unstableHousingTypes = getList('unstable_housing_type');
      const clientRelationships = getList('client_relationship');
      const employmentStatuses = getList('employment_status');
      const taxFilingStatuses = getList('tax_filing_status');
      const medicareStatuses = getList('medicare_status');
      const medicarePartDStatuses = getList('medicare_part_d_status');
      const medicareLowIncomeSubsidyStatuses = getList('medicare_low_income_subsidy_status');
      const medicareLowIncomeSubsidyTypes = getList('medicare_low_income_subsidy_type');
      const fullLowIncomeSubsidyStatuses = getList('full_low_income_subsidy_status');
      const vaHealthBenefitsStatuses = getList('va_health_benefits_status');
      const ihsHealthBenefitsStatuses = getList('ihs_health_benefits_status');
      const hivDiseaseStages = getList('hiv_disease_stage');
      const hivRiskFactors = getList('hiv_risk_factor');
      const arvDenialReasons = getList('arv_denial_reason');
      const privateInsuranceStatuses = getList('private_insurance_status');
      const privateInsuranceSources = getList('private_insurance_source');
      const dentalInsuranceStatuses = getList('dental_insurance_status');
      const visionInsuranceStatuses = getList('vision_insurance_status');
      const eligibilityApplicationUrgencyTypes = getList('eligibility_application_urgency_type');
      const eligibilityStartDateTypes = getList('eligibility_start_date_type');
      const incomePeriods = getList('income_period');
      const incomeAdjustmentTypes = getList('income_adjustment_type');
      const premiumPaymentFrequencies = getList('premium_payment_frequency');
      const adapServiceDenialReasons = getList('adap_services_denial_reason');
      const medicationAssistanceLevels = getList('medication_assistance_level');
      const premiumAssistanceCoverageTypes = getList('premium_assistance_coverage_type');
      const adapEnrollmentTerminationReasons = getList('adap_enrollment_termination_reason');
      const clientServicesDataDeletionReasons = getList('client_services_data_deletion_reason');
      const medicaidStatuses = getList('medicaid_status');
      const medicareCoverageTypes = getList('medicare_coverage_types');
      const medicaidCategories = getList('medicaid_category');

      dispatch({
        type: messageTypes.LOADING_CLIENT_INFO_PICKLISTS_SUCCESS,
        payload: {
          state: state,
          birthGenders: birthGenders,
          currentGenderIdentities: currentGenderIdentities,
          phoneTypes: phoneTypes,
          missingSsnReasons: missingSsnReasons,
          preferredProgramNotificationMethods: preferredProgramNotificationMethods,
          races: races,
          asianRaces: asianRaces,
          nhpiRaces: nhpiRaces,
          hispanicEthnicities: hispanicEthnicities,
          maritalStatuses: maritalStatuses,
          citizenshipStatuses: citizenshipStatuses,
          housingStatuses: housingStatuses,
          stableHousingTypes: stableHousingTypes,
          temporaryHousingTypes: temporaryHousingTypes,
          unstableHousingTypes: unstableHousingTypes,
          clientRelationships: clientRelationships,
          employmentStatuses: employmentStatuses,
          taxFilingStatuses: taxFilingStatuses,
          medicareStatuses: medicareStatuses,
          medicarePartDStatuses: medicarePartDStatuses,
          medicareLowIncomeSubsidyStatuses: medicareLowIncomeSubsidyStatuses,
          medicareLowIncomeSubsidyTypes: medicareLowIncomeSubsidyTypes,
          fullLowIncomeSubsidyStatuses: fullLowIncomeSubsidyStatuses,
          vaHealthBenefitsStatuses: vaHealthBenefitsStatuses,
          ihsHealthBenefitsStatuses: ihsHealthBenefitsStatuses,
          hivDiseaseStages: hivDiseaseStages,
          hivRiskFactors: hivRiskFactors,
          arvDenialReasons: arvDenialReasons,
          privateInsuranceStatuses: privateInsuranceStatuses,
          privateInsuranceSources: privateInsuranceSources,
          dentalInsuranceStatuses: dentalInsuranceStatuses,
          visionInsuranceStatuses: visionInsuranceStatuses,
          eligibilityApplicationUrgencyTypes: eligibilityApplicationUrgencyTypes,
          eligibilityStartDateTypes: eligibilityStartDateTypes,
          incomePeriods: incomePeriods,
          incomeAdjustmentTypes: incomeAdjustmentTypes,
          premiumPaymentFrequencies: premiumPaymentFrequencies,
          adapServiceDenialReasons: adapServiceDenialReasons,
          medicationAssistanceLevels: medicationAssistanceLevels,
          premiumAssistanceCoverageTypes: premiumAssistanceCoverageTypes,
          adapEnrollmentTerminationReasons: adapEnrollmentTerminationReasons,
          clientServicesDataDeletionReasons: clientServicesDataDeletionReasons,
          medicaidStatuses: medicaidStatuses,
          medicareCoverageTypes: medicareCoverageTypes,
          medicaidCategories: medicaidCategories
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientInfoPicklists');
    }
  };

  const loadEligibilityApplicationStatuses = async () => {
    addBusyBee('loadEligibilityApplicationStatuses');

    try {
      const { data } = await axios.get('api/staticList/eligibility_application_statuses');
      const listData = parseApiResult(data).body;

      const unique = [
        ...new Map(listData.map((item) => [item.name, item])).values(),
      ];

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_STATUSES_SUCCESS,
        payload: {
          eligibilityApplicationStatuses: unique,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationStatuses');
    }
  };

  const loadFederalPovertyGuidelines = async () => {
    addBusyBee('loadFederalPovertyGuidelines');

    try {
      const { data: povData } = await axios.get('api/staticList/federal_poverty_guidelines');
      const listData = parseApiResult(povData).body;

      dispatch({
        type: messageTypes.LOADING_FEDERAL_POVERTY_GUIDELINES_SUCCESS,
        payload: {
          federalPovertyGuidelines: listData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadFederalPovertyGuidelines');
    }
  };

  const loadAccountStatuses = async () => {
    addBusyBee('loadAccountStatuses');

    try {
      const { data } = await axios.get('api/staticList/account_statuses');
      const listData = parseApiResult(data).body;

      const unique = [
        ...new Map(listData.map((item) => [item.name, item])).values(),
      ];

      dispatch({
        type: messageTypes.LOADING_ACCOUNT_STATUSES_SUCCESS,
        payload: {
          accountStatuses: unique,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAccountStatuses');
    }
  };

  const loadBarrierStatuses = async () => {

    addBusyBee('loadBarrierStatuses');

    try {
      const result = await axios.get('/api/staticList/barrier_statuses');
      let barrierStatusData = parseApiResult(result.data).body;

      let barrierStatusesList = barrierStatusData.map(
        (cs) => {
          return cs;
        }
      );

      dispatch({
        type: messageTypes.LOADING_BARRIER_STATUSES_SUCCESS,
        payload: {
          barrierStatuses: barrierStatusesList,
        },
      });

      return barrierStatusesList;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadBarrierStatuses');
    }
  };

  const loadGoalStatuses = async () => {

    addBusyBee('loadGoalStatuses');

    try {
      const result = await axios.get('/api/staticList/goal_statuses');
      let goalStatusData = parseApiResult(result.data).body;

      let goalStatusList = goalStatusData.map((gs) => {
        return gs;
      });

      dispatch({
        type: messageTypes.LOADING_GOAL_STATUSES_SUCCESS,
        payload: {
          goalStatuses: goalStatusList,
        },
      });

      return goalStatusList;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadGoalStatuses');
    }
  };

  const loadInterventionStatuses = async () => {

    addBusyBee('loadInterventionStatuses');

    try {

      const result = await axios.get('/api/staticList/intervention_statuses');
      let interventionStatusData = parseApiResult(result.data).body;

      let interventionStatusList =
        interventionStatusData.map((is) => {
          return is;
        });

      dispatch({
        type: messageTypes.LOADING_INTERVENTION_STATUSES_SUCCESS,
        payload: {
          interventionStatuses: interventionStatusList,
        },
      });

      return interventionStatusList;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadInterventionStatuses');
    }
  };

  const loadCareCategories = async () => {

    addBusyBee('loadCareCategories');

    try {
      const result = await axios.get('/api/staticList/care_categories');
      let categoriesData = parseApiResult(result.data).body;

      const careCategories = categoriesData.map((node) => {
        return {
          ...node,
        };
      });

      dispatch({
        type: messageTypes.LOADING_CARE_CATEGORIES_SUCCESS,
        payload: {
          careCategories,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadCareCategories');
    }
  };

  const loadLifeAreas = async () => {

    addBusyBee('loadLifeAreas');

    try {
      const result = await axios.get('/api/staticList/care_life_areas');
      let lifeAreasData = parseApiResult(result.data).body;

      const lifeAreas = lifeAreasData.map((node) => {
        return {
          ...node,
        };
      });

      dispatch({
        type: messageTypes.LOADING_LIFE_AREAS_SUCCESS,
        payload: {
          lifeAreas,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadLifeAreas');
    }
  };

  const loadContactTypes = async () => {
    try {
      addBusyBee('loadContactTypes');

      const result = await axios.get('/api/staticList/contact_types');
      let contactTypesData = parseApiResult(result.data).body;

      const contactTypesList = contactTypesData.map(
        (node) => {
          return { ...node };
        }
      );

      dispatch({
        type: messageTypes.LOADING_CONTACT_TYPES_SUCCESS,
        payload: {
          contactTypes: contactTypesList,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadContactTypes');
    }
  };

  return {
    ...state,
    loadIcd10Codes,
    loadLanguages,
    loadMeetingStatuses,
    loadStates,
    loadCountiesForState,
    loadClientInfoPicklists,
    loadEligibilityApplicationStatuses,
    loadFederalPovertyGuidelines,
    loadProgramTypes,
    loadAccountStatuses,
    loadBarrierStatuses,
    loadGoalStatuses,
    loadInterventionStatuses,
    loadCareCategories,
    loadLifeAreas,
    loadContactTypes
  };
};
