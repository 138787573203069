import { populateInitialFieldValue } from './formInitialization';
import { createValidationSchemaForForm, createValidationSchemaExcludingRequiredChecksForForm } from './validationUtilities';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import FieldElementView from 'components/Forms/components/FieldElementView';
import moment from 'moment';
import LotusForm from 'components/widgets/Forms/LotusForm';

export function combineStaticAndDynamicFormValues(staticFormValues, dynamicFormValues, dynamicFormDefinition) {
  let combinedInitialValues = staticFormValues ? {...staticFormValues} : {};
  if (dynamicFormDefinition && (dynamicFormDefinition.fields || dynamicFormDefinition.sections)) {
    let fields = dynamicFormDefinition.fields;
    if (dynamicFormDefinition.sections) {
      fields = dynamicFormDefinition.sections.map(section => section.fields).flat();
    }
    fields.forEach((field) => {
      populateInitialFieldValue(combinedInitialValues, field, dynamicFormValues);
    });
  }
  combinedInitialValues.asOfDate = moment().format('MM/DD/YYYY HH:mm:ss');
  return combinedInitialValues;
}

export function combineStaticAndDynamicFormValidationSchema(staticFormValidationSchema, dynamicFormDefinition, dontCheckRequiredFields) {
  let schema = staticFormValidationSchema;
  if (dynamicFormDefinition && (dynamicFormDefinition.fields || dynamicFormDefinition.sections)) {
    // need to combine schema with static if defined
    const dynSchema = dontCheckRequiredFields ? createValidationSchemaExcludingRequiredChecksForForm(dynamicFormDefinition) : createValidationSchemaForForm(dynamicFormDefinition);
    schema = schema ? schema.concat(dynSchema) : dynSchema;
  }
  return schema;
}

export function combineFormElementsAndSections ({staticFormSectionNameBuilder, staticFormDefinitionBuilder, dynamicFormDefinition, disabled, dontCheckRequiredFields}) {
  const combinedFieldElements = [];
  const combinedSections = [];

  if (staticFormSectionNameBuilder) {
    let staticFormSectionNames = staticFormSectionNameBuilder();
    staticFormSectionNames.forEach(s => {
      combinedSections.push({title: s, url: `#${s}`});
    });
  }

  if (staticFormDefinitionBuilder) {
    const s = staticFormDefinitionBuilder(disabled, dontCheckRequiredFields);
    combinedFieldElements.push(s);
  }

  if (dynamicFormDefinition && (dynamicFormDefinition.fields || dynamicFormDefinition.sections)) {
    const buildDynamicFormFieldElements = (fields) => {
      return fields.map((fieldDefinition) => {
        return (
          <LotusFormItem key={fieldDefinition.id}>
            <FieldElementView
              disabled={disabled}
              formDefinition={dynamicFormDefinition}
              fieldDefinition={fieldDefinition}
              dontCheckRequiredFields={dontCheckRequiredFields}
            />
          </LotusFormItem>
        );
      })
    }

    // Someday maybe we integrate the dynamic fields within the static fields
    // For now, just put them all afterward
    if (dynamicFormDefinition.fields) {
      combinedFieldElements.push(<LotusForm>{buildDynamicFormFieldElements(dynamicFormDefinition.fields)}</LotusForm>);
    }
    if (dynamicFormDefinition.sections) {
      const allSections = dynamicFormDefinition.sections.map(section => {
        const sectionElements = buildDynamicFormFieldElements(section.fields);
        const sect = (
          <LotusForm>
            <LotusFormSection key={section.name} name={section.name} disabled={disabled}>
              {sectionElements.map(el => {return (el)})}
            </LotusFormSection>
          </LotusForm>
        );
        combinedSections.push({title: section.name, url: `#${section.name}`});
        return sect;
      });
      combinedFieldElements.push(...allSections);
    }
  }

  return {combinedFieldElements, combinedSections};
}