import { Typography } from "@mui/material";
import { useAppStatus } from "contexts/AppStatusContext";
import { useField, useFormikContext } from "formik";
import { generateThumbnailsForUrl } from "lib/documents";
import LotusFileUploader from "./core/LotusFileUploader";

export default function LotusSingleAgencyFileUploader({
  name, // if part of a list, should be like `consentDocumentsList.${index}.document`
  label,
  granteeId,
  agencyId,
  subfolderName,
  generatePreview,
  required,
  dontCheckRequiredFields
}) {

  const { validateField } = useFormikContext();

  const [field, meta, utils] = useField({name, validate: (val) => {
    if (required && !dontCheckRequiredFields && (!val || Object.keys(field.value).length === 0)) {
      return `${label} is required`;
    }
  }});
  const { addBusyBee, removeBusyBee } = useAppStatus();

  return (
    <div>
      <LotusFileUploader
        maxFiles={1}
        name={name}
        granteeId={granteeId}
        agencyId={agencyId}
        subfolderName={subfolderName}
        existingFiles={
          Object.keys(field.value).length > 0
            ? [{
              source: field.value.uniqueName,
              options: {
                type: 'local',
                file: { name: field.value.displayName, type: field.value.type }
              }
            }]
            : []
        }
        onFileAdded={async (newFile, base64Str) => {
          try {
            addBusyBee('generatingThumbnail');
            const sizes = generatePreview ? [64, 300] : [64]
            const thumbnails = await generateThumbnailsForUrl(base64Str, newFile.type, sizes);
            newFile.thumbnail = thumbnails[0];
            newFile.preview = generatePreview ? thumbnails[1] : undefined;
            utils.setValue(newFile);
            setTimeout(async () => await validateField(name));
          }
          catch (err) {
            console.log(err);
            utils.setValue({});
            utils.setError('An error occurred while generating thumbnail images for file');
          } finally {
            removeBusyBee('generatingThumbnail');
          }
        }}
        onFileDeleted={(newFile) => {
          utils.setValue({});
          utils.setError(undefined);
          setTimeout(async () => await validateField(name));
        }}
      />
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error">
            {meta.error}
          </Typography>
        </div>
      )}
      {field.value && field.value.thumbnail &&
        <img
          alt="Thumbnail of document"
          src={field.value.thumbnail}
          style={{ maxWidth: 100, maxHeight: 100, marginRight: 10 }}
        />
      }
    </div>
  );
}