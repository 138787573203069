import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLists } from 'contexts/ListsContext';
import { Grid } from '@mui/material';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusFormItem from '../Forms/LotusFormItem';
import LotusFormItems from '../Forms/LotusFormItems';
import { useFormikContext } from 'formik';

export default function HousingTypeFieldPanel({ housingStatusKey, housingTypeFieldName, incarcerationDateFieldName, projectedIncarcerationReleaseDateFieldName, disabled, required, dontCheckRequiredFields, useSeedData }) {

  const [housingTypeField, meta, utils] = useField(housingTypeFieldName);
  const [incarcerationDateField, incarcerationDateFieldMeta, incarcerationDateFieldUtils] = useField(incarcerationDateFieldName);
  const [releaseDateField, releaseDateFieldMeta, releaseDateFieldUtils] = useField(projectedIncarcerationReleaseDateFieldName);

  const { stableHousingTypes, temporaryHousingTypes, unstableHousingTypes } = useLists();

  const [stableHousingTypeList, setStableHousingTypeList] = useState();
  const [temporaryHousingTypeList, setTemporaryHousingTypeList] = useState();
  const [unstableHousingTypeList, setUnstableHousingTypeList] = useState();
  const [housingTypeListToUse, setHousingTypeListToUse] = useState();
  const [showIncarcerationDates, setShowIncarcerationDates] = useState();
  const { validateForm } = useFormikContext();

  useEffect(() => {
    if (useSeedData) {
      setStableHousingTypeList([{label: "House", value: 1, itemKey:'house'},{label: "Apt", value: 2, itemKey: 'apt'}]);
      setTemporaryHousingTypeList([{label: "Hotel", value: 1, itemKey:'hotel'},{label: "Motel", value: 2, itemKey: 'motel'}]);
      setUnstableHousingTypeList([{label: "Shelter", value: 1, itemKey:'jail'},{label: "Jail", value: 2, itemKey: 'jail'}]);
    }
  }, [useSeedData]);

  useEffect(() => {
    if (stableHousingTypes && temporaryHousingTypes && unstableHousingTypes && !useSeedData) {
      setStableHousingTypeList(stableHousingTypes.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
      setTemporaryHousingTypeList(temporaryHousingTypes.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
      setUnstableHousingTypeList(unstableHousingTypes.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
  }, [stableHousingTypes, temporaryHousingTypes, unstableHousingTypes, useSeedData]);

  useEffect(() => {
    if (housingStatusKey) {
      if (housingStatusKey === 'stable') {
        setHousingTypeListToUse(stableHousingTypeList);
      } else if (housingStatusKey === 'temporary') {
        setHousingTypeListToUse(temporaryHousingTypeList);
      } else if (housingStatusKey === 'unstable') {
        setHousingTypeListToUse(unstableHousingTypeList);
      }
    } else {
      setHousingTypeListToUse([]);
    }
  }, [housingStatusKey, stableHousingTypeList, temporaryHousingTypeList, unstableHousingTypeList]);

  useEffect(() => {
    if (housingTypeField.value && housingTypeListToUse) {
      let typ_key = housingTypeListToUse.find(t => t.value === housingTypeField.value)?.itemKey;
      if (!typ_key) {
        utils.setValue('');
      } else {
        let incarcerated = typ_key === 'jail';
        if (incarcerated) {
          setShowIncarcerationDates(true);
        } else {
          setShowIncarcerationDates(false);
          incarcerationDateFieldUtils.setValue(null);
          releaseDateFieldUtils.setValue(null);
        }
      }
    }
    if (!housingTypeField.value) {
      setShowIncarcerationDates(false);
      incarcerationDateFieldUtils.setValue(null);
      releaseDateFieldUtils.setValue(null);
    }
  }, [housingTypeField.value, housingTypeListToUse]);

  return (
    <Grid container>
      {housingTypeListToUse &&
      <LotusFormItem>
        <LotusSelect
            name={housingTypeFieldName}
            label="Housing Type"
            items={housingTypeListToUse}
            autoSort={false}
            required={required}
            dontCheckRequiredFields={dontCheckRequiredFields}
            disabled={disabled}
          />
      </LotusFormItem>
      }
      {showIncarcerationDates &&
      <LotusFormItems>
        <LotusDatePicker
          name={incarcerationDateFieldName}
          label="Incarceration Start Date"
          required={required}
          dontCheckRequiredFields={dontCheckRequiredFields}
          disabled={disabled}
          handleDateChange={() => {
            setTimeout(() => validateForm());
          }}
        />
        <LotusDatePicker
          name={projectedIncarcerationReleaseDateFieldName}
          label="Expected Release Date"
          dontCheckRequiredFields={dontCheckRequiredFields}
          disabled={disabled}
          minDate={incarcerationDateField.value}
        />
      </LotusFormItems>
      }
    </Grid>
  );
}
