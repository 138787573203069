import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, Stack } from '@mui/material';
import { useAuthState } from 'contexts/AuthProvider';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { Formik } from 'formik';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { useDashboards } from 'contexts/DashboardsContext';
import { isEqual } from 'lodash';
import { Table } from '@lotus/components';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import uniq from "lodash/uniq";
import LotusDateRangePickerPopup from 'components/widgets/Forms/LotusDateRangePickupPopup';
import moment from 'moment';

export default function MetricMatrixDashboard() {

  const { type: dashboardType } = useParams();
  const { user } = useAuthState();

  const [metrics, setMetrics] = useState();
  const [subprogramList, setSubprogramList] = useState();
  const [userList, setUserList] = useState();
  const [appTypeList, setAppTypeList] = useState();
  const [initialSearchFilters, setInitialSearchFilters] = useState();

  // These filters are filtering the query result in the ui, not sent along with api request, populated by the select boxes
  const [resultFilters, setResultFilters] = useState();
  // These filters are being sent along with api request, populated by the select boxes
  const [queryFilters, setQueryFilters] = useState();
  // These is the most recent query that we sent to the api
  const [queryParams, setQueryParams] = useState();
  // This is the most recent query result we got from api
  const [queryResult, setQueryResult] = useState();
  // This is the query result with the selectedResultFilters applied
  const [filteredQueryResult, setFilteredQueryResult] = useState();

  const {userAgency, loadUserAgencyMetrics, userAgencyMetrics} = useUserAgency();
  const {loadMultiMetricMatrixQueryResult, multiMetricMatrixQueryResult} = useDashboards();

  useEffect(() => {
    setAppTypeList([
      { label: 'All Application Types', value: 'all', disabled: true },
      { label: 'New', value: 'New' },
      { label: 'Recertification', value: 'Recertification' },
      { label: 'Update', value: 'Update' },
      { label: `${userAgency.eligibilityConfig.application.selectedAssistanceProgramName || 'ADAP'} Only`, value: 'ADAP Only' }
    ]);
  }, []);

  useEffect(() => {
    if (user && dashboardType) {
      // Load dashboard config
      loadUserAgencyMetrics([dashboardType]);
    }
  }, [user, dashboardType]);

  useEffect(() => {
    if (userAgency) {
      let lst = userAgency.eligibilityConfig.application.adapSubprograms.map(s => {return {label: s.name, value: s.name}});
      lst = [{label: 'All Subprograms', value: 'all', disabled: true}, {label: 'Care Only', value: 'Care Only'}, ...lst];
      setSubprogramList(lst);

      let initialFilters = {
        subprograms: ['all'],
        appTypes: ['all'],
        users: ['all'],
        startDate: moment().subtract(1, 'weeks').startOf('isoweek').format('MM/DD/YYYY'),
        endDate: moment().subtract(1, 'weeks').endOf('isoweek').format('MM/DD/YYYY')
      };
      setInitialSearchFilters(initialFilters);
      setResultFilters({subprograms: initialFilters.subprograms, appTypes: initialFilters.appTypes, users: initialFilters.users});
      setQueryFilters({startDate: initialFilters.startDate, endDate: initialFilters.endDate});
    }
  }, [userAgency]);

  useEffect(() => {
    if (userAgencyMetrics && dashboardType in userAgencyMetrics) {
      let mapped = userAgencyMetrics[dashboardType].map(am => {
        return {
          id: am.metricId,
          key: am.metricKey,
          name: am.title,
          data: am.data,
          entityType: am.entityType,
        }
      });
      setMetrics(mapped);
    }
  }, [userAgencyMetrics]);

  const doMetricQuery = (metricList, queryFilters) => {
    const params = {
      metrics: metricList.map(m => { return {id: m.id, key: m.key, entityType: m.entityType} }),
      filters: queryFilters,
    };
    loadMultiMetricMatrixQueryResult({params});
    setQueryParams(params);
  }

  useEffect(() => {
    if (multiMetricMatrixQueryResult && isEqual(multiMetricMatrixQueryResult.queryParams, queryParams)) {
      let users = uniq(multiMetricMatrixQueryResult.matrix.map(r => r.userName));
      if (!resultFilters.users.includes('all')) {
        let newUserFilters = [];
        resultFilters.users.forEach(u => {
          if (users.includes(u)) {
            newUserFilters.push(u);
          }
        });
        setResultFilters({...resultFilters, users: newUserFilters});
      }
      setUserList([{label: 'All Users', value: 'all', disabled: true}, ...users.map(u => {return {label: u, value: u}})]);
      setQueryResult(multiMetricMatrixQueryResult.matrix);
    } 
  }, [multiMetricMatrixQueryResult]);

  useEffect(() => {
    if (metrics && metrics.length > 0 && queryFilters) {
      doMetricQuery(metrics, queryFilters);
    }
  }, [metrics, queryFilters]);

  useEffect(() => {
    if (resultFilters && queryResult) {
      let filteredResult = [...queryResult];
      if (!resultFilters.subprograms.includes('all')) {
        filteredResult = filteredResult.filter(r => resultFilters.subprograms.includes(r.subprogramName));
      }
      if (!resultFilters.users.includes('all')) {
        filteredResult = filteredResult.filter(r => resultFilters.users.includes(r.userName));
      }
      if (!resultFilters.appTypes.includes('all')) {
        filteredResult = filteredResult.filter(r => resultFilters.appTypes.includes(r.appType));
      }
      setFilteredQueryResult(filteredResult);
    }
  }, [resultFilters, queryResult]);

  return (
    <>
      {metrics && metrics.length === 0 &&
        <div>No metrics enabled. Please ask your agency admin to configure your dashboard metrics.</div>
      }
      {metrics && metrics.length > 0 &&
        <>
        <Formik
          initialValues={initialSearchFilters}
          enableReinitialize
        >
          {({values, setFieldValue}) => {
            return values && (
              <Stack direction="row" spacing={1} justifyContent="flex-start">
                {userList && 
                  <LotusMultiSelect
                    name="users"
                    label="User(s)"
                    items={userList}
                    style={{width: 300}}
                    onChange={(e) => { 
                      let lst = [...e.target.value];
                      if (lst.length > 1 && lst.includes('all')) {
                        lst.splice(lst.indexOf('all'), 1);
                      }
                      if (lst.length === 0) {
                        lst = ['all'];
                      }
                      setFieldValue('users', lst);
                      let newResultFilters = {...resultFilters};
                      newResultFilters.users = lst;
                      setResultFilters(newResultFilters);
                    }}
                  />
                }
                {user.isGrantee && subprogramList &&
                  <LotusMultiSelect
                    name="subprograms"
                    label="Subprogram(s)"
                    items={subprogramList}
                    style={{width: 300}}
                    onChange={(e) => { 
                      let lst = [...e.target.value];
                      if (lst.length > 1 && lst.includes('all')) {
                        lst.splice(lst.indexOf('all'), 1);
                      }
                      if (lst.length === 0) {
                        lst = ['all'];
                      }
                      setFieldValue('subprograms', lst);
                      let newResultFilters = {...resultFilters};
                      newResultFilters.subprograms = lst;
                      setResultFilters(newResultFilters);
                    }}
                  />
                }
                {appTypeList &&
                  <LotusMultiSelect
                    name="appTypes"
                    label="Application Type(s)"
                    items={appTypeList}
                    style={{width: 300}}
                    onChange={(e) => { 
                      let lst = [...e.target.value];
                      if (lst.length > 1 && lst.includes('all')) {
                        lst.splice(lst.indexOf('all'), 1);
                      }
                      if (lst.length === 0) {
                        lst = ['all'];
                      }
                      setFieldValue('appTypes', lst);
                      let newResultFilters = {...resultFilters};
                      newResultFilters.appTypes = lst;
                      setResultFilters(newResultFilters);
                    }}
                  />
                }
                <LotusDateRangePickerPopup
                  startDateFieldName="startDate"
                  endDateFieldName="endDate"
                  label="Date Range"
                  startDateLabel="Start Date"
                  endDateLabel="End Date"
                  handleDateRangeChange={(startDate, endDate) => {
                    if (startDate != queryFilters.startDate || endDate != queryFilters.endDate) {
                      setQueryFilters({startDate: startDate, endDate: endDate});
                    }
                  }}
                  required
                />
              </Stack>
            );
          }}
        </Formik>
        <LotusSpacedBlock/>
        {metrics && metrics.length > 0 && metrics[0].entityType === 'user_subp_apptyp_ct' &&
          <UserSubprogramAppTypeMetricMatrixTable
            metrics={metrics}
            matrix={filteredQueryResult}
            tableName="userSubprogramAppTypeMetricMatrixTable"
            eligibilityConfig={userAgency.eligibilityConfig}
          />
        }
        </>
      }
    </>
  );
}

export function UserSubprogramAppTypeMetricMatrixTable({ metrics, matrix, tableName, eligibilityConfig }) {

  const [columns, setColumns] = useState();

  useEffect(() => {
    if (metrics) {
      let cols = [
        {
          name: 'userId',
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
          },
        },
        {
          label: 'Processor Name',
          name: 'userName',
          options: {
            filter: false,
            sort: true,
          },
        },
        {
          label: 'Sub-program',
          name: 'subprogramName',
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
              return value && <Chip label={value} size="medium" />;
            },
          },
        },
        {
          label: 'Application Type', 
          name: 'appType',
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
              return value && <Chip label={value === 'ADAP Only' ? `${eligibilityConfig.application.selectedAssistanceProgramName || 'ADAP'} Only` : value} size="medium" />;
            },
          },
        }
      ];
      let metricOrder = [
        'app_reviews_started',
        'apps_pended',
        'apps_rw_approved',
        'apps_rw_ineligible',
        'apps_adap_approved',
        'apps_adap_denied_waitlist',
        'apps_adap_denied_full_medicaid',
        'apps_adap_denied_other_coverage',
        'apps_adap_denied_other_elig_chg',
        'app_determinations',
        'clients_term_from_adap'
      ];
      metricOrder.forEach(metricKey => {
        let mt = metrics.find(m => m.key === metricKey);
        if (mt) {
          cols.push({
            label: mt.name, 
            name: mt.key,
            options: {
              filter: false,
              customBodyRender: (value) => {
                return (<div>{value || 0}</div>);
              }
            }
          });
        }
      });

      setColumns(cols);
    }
  }, [metrics]);

  return columns && matrix && (
    <Table
      tableName={tableName}
      enabled={Boolean(matrix)}
      options={{ 
        serverSide: false, 
        customSearch: undefined, 
        download: false, 
        print: false,
        filter: false,
        search: false,
        searchAlwaysOpen: false,
        viewColumns: false
      }}
      columns={columns}
      data={matrix}
      totalRows={matrix && matrix.length}
    />
  );
}
