
import React, { useEffect, useState } from 'react';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusExpandableItemList from 'components/widgets/Forms/LotusExpandableItemList';
import StaticForm from 'components/Forms/StaticForm';
import { useProgram } from 'contexts/ProgramContext';
import { useLists } from 'contexts/ListsContext';

export default function BarriersToCare({ barriers, barrierDisplayName, clientId, assessmentId, programId, allowEdit=true }) {

  const { upsertBarriers } = useClientCarePlan();
  const {
    careCategories, lifeAreas, barrierStatuses,
    loadCareCategories, loadLifeAreas, loadBarrierStatuses } = useLists();
  const { 
    currentProgram, setProgram, 
    currentProgramCarePlanLifeAreaIds, currentProgramCarePlanCategoryIds, loadProgramCarePlanPicklists, 
  } = useProgram();
  
  const [categories, setCategories] = useState();
  const [areas, setAreas] = useState();
  const [statuses, setStatuses] = useState();
  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if (!barrierStatuses) {
      loadBarrierStatuses();
    }
  }, []);

  useEffect(() => {
    if (programId && (!currentProgram || currentProgram.id !== programId)) {
      setProgram(programId);
    }
  }, [programId]);

  useEffect(() => {
    if (currentProgram) {
      let loadedProgramPicklists = false;
      if (currentProgram.barrierLifeAreaEnabled) {
        if (!currentProgramCarePlanLifeAreaIds && !loadedProgramPicklists) {
          loadProgramCarePlanPicklists();
          loadedProgramPicklists = true;
        }
        if (!lifeAreas) {
          loadLifeAreas();
        }
      }
      
      if (currentProgram.barrierCareCategoryEnabled) {
        if (!currentProgramCarePlanCategoryIds && !loadedProgramPicklists) {
          loadProgramCarePlanPicklists();
          loadedProgramPicklists = true;
        }
        if (!careCategories) {
          loadCareCategories();
        }
      }
    }
  }, [currentProgram]);

  useEffect(() => {
    // React gets pissed about null values bound to controls
    const lst = barriers.map(b => { 
      return {
        id: b.id,
        name: b.name || '',
        comments: b.comments || '',
        lastName: b.lastName || '',
        careStatusId: b.careStatusId || '',
        careLifeAreaId: b.careLifeAreaId || '',
        careCategoryId: b.careCategoryId || '',
        assessmentId: b.assessmentId,
        programId: b.programId,
        clientId: b.clientId
      }
    });
    lst.sort((a,b) => a.name < b.name ? -1 : 1);
    setInitialValues({barriers: lst});
  }, [barriers]);

  useEffect(() => {
    if (careCategories && currentProgramCarePlanCategoryIds && initialValues) {
      const cats = careCategories.filter(c => currentProgramCarePlanCategoryIds.includes(c.id) || (initialValues.barriers.find(b => b.careCategory === c.id)))
        .map(c => {return {label: c.name, value: c.id}});
      setCategories(cats);
    }
  }, [careCategories, currentProgramCarePlanCategoryIds, initialValues]);

  useEffect(() => {
    if (lifeAreas && currentProgramCarePlanLifeAreaIds && initialValues) {
      const areas = lifeAreas.filter(la => currentProgramCarePlanLifeAreaIds.includes(la.id) || (initialValues.barriers.find(b => b.lifeArea === la.id)))
        .map(la => {return {label: la.name, value: la.id}});
      setAreas(areas);
    }
  }, [lifeAreas, currentProgramCarePlanLifeAreaIds, initialValues]);

  useEffect(() => {
    if (barrierStatuses) {
      setStatuses(barrierStatuses.map(c => {return {label: c.name, value: c.id}}));
    }
  }, [barrierStatuses]);

  const emptyBarrier = {
    name: '',
    comment: '',
    careStatusId: '',
    careLifeAreaId: '',
    careCategoryId: '',
    programId: programId,
    clientId: clientId,
    assessmentId: assessmentId
  }

  const buildDetailsPanel = (barrier, index, isDisabled) => {
    return (
      <LotusFormItems key={index}>
        <LotusTextInput
          name={`barriers.${index}.name`}
          label="Name"
          required
          disabled={isDisabled}
          maxLength={35}
        />
        {statuses &&
          <LotusSelect
            name={`barriers.${index}.careStatusId`}
            label="Status"
            items={statuses}
            disabled={isDisabled}
            required
          />
        }
        {categories && currentProgram.barrierCareCategoryEnabled &&
          <LotusSelect
            name={`barriers.${index}.careCategoryId`}
            label="Category"
            items={categories}
            disabled={isDisabled}
          />
        }
        {areas && currentProgram.barrierLifeAreaEnabled &&
          <LotusSelect
            name={`barriers.${index}.careLifeAreaId`}
            label="Life Area"
            items={areas}
            disabled={isDisabled}
          />
        }
      </LotusFormItems>
    );
  }

  const handleSubmit = (newValues) => {
    upsertBarriers(newValues, clientId, programId, assessmentId);
  }

  const buildForm = (disabled) => {
    return (
      <LotusForm>
        <LotusFormItems>
          <LotusExpandableItemList
            disabled={disabled} 
            name="barriers" 
            itemName={barrierDisplayName}
            buildItemSectionName={(item) => item.name}
            buildItemTitle={(item) => item.name || `[${barrierDisplayName}]`}
            buildItemSummary={(item, index) => {
              return (<div></div>);
            }}
            buildItemDetail={(item, index, isDisabled) => buildDetailsPanel(item, index, isDisabled)}
            buildItemTemplate={() => {return {...emptyBarrier}}}
            allowRemove={false}
          />
        </LotusFormItems>
      </LotusForm>
    );
  }

  return initialValues && (
    <StaticForm
      headerName={`${barrierDisplayName}s`}
      formValues={initialValues}
      allowEdit={allowEdit}
      onSubmitForm={handleSubmit}
      buildFormContent={buildForm} />
  );
}