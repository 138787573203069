export const messageTypes = {
  LOADING_METRIC_QUERY_RESULT_SUCCESS: 'LOADING_METRIC_QUERY_RESULT_SUCCESS',
  LOADING_METRIC_CLIENT_APPLICATION_DETAILS_SUCCESS: 'LOADING_METRIC_CLIENT_APPLICATION_DETAILS_SUCCESS',
  LOADING_METRIC_ENTITY_TYPE_TOTAL_COUNT_SUCCESS: 'LOADING_METRIC_ENTITY_TYPE_TOTAL_COUNT_SUCCESS',
  LOADING_MULTI_METRIC_MATRIX_QUERY_RESULT_SUCCESS: 'LOADING_MULTI_METRIC_MATRIX_QUERY_RESULT_SUCCESS',
  LOADING_METRICS_SUCCESS: 'LOADING_METRICS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_METRIC_QUERY_RESULT_SUCCESS:
    case messageTypes.LOADING_METRIC_CLIENT_APPLICATION_DETAILS_SUCCESS:
    case messageTypes.LOADING_MULTI_METRIC_MATRIX_QUERY_RESULT_SUCCESS:
    case messageTypes.LOADING_METRICS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case messageTypes.LOADING_METRIC_ENTITY_TYPE_TOTAL_COUNT_SUCCESS:
      let newEntityCounts = null;
      if (state.metricEntityTypeTotalCounts) {
        newEntityCounts = [...state.metricEntityTypeTotalCounts];
      } else {
        newEntityCounts = [];
      }
      newEntityCounts.push(payload.metricEntityTypeTotalCount);
      return {
        ...state,
        metricEntityTypeTotalCounts: newEntityCounts
      };
    default:
      break;
  }
};
