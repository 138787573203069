import React, { useEffect, useState } from 'react';
import { useProgram } from 'contexts/ProgramContext';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import { useClient } from 'contexts/ClientContext';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import InlineEditableStaticForm from 'components/Forms/InlineEditableStaticForm';
import { useLists } from 'contexts/ListsContext';

// NOTE: This is very similar to the component in components/BrriersToCare
// But that one is geared toward inserting a list of barriers, where here
// we are only dealing with one at a time.
// Seems better not to try to merge the uses and keep it simple.  This isnt that big

export default function BarrierToCareForm ({
  barrier,
  handleCancel,
  afterSave,
  readOnly,
}) {
  const [initialValues, setInitialValues] = useState();

  const { currentProgram, currentProgramCarePlanLifeAreaIds, currentProgramCarePlanCategoryIds } = useProgram();
  const { client } = useClient();
  const { upsertBarrier } = useClientCarePlan();
  const {careCategories, lifeAreas, barrierStatuses } = useLists();
  const [categories, setCategories] = useState();
  const [areas, setAreas] = useState();
  const [statuses, setStatuses] = useState();

  useEffect(() => {
    if (careCategories && currentProgramCarePlanCategoryIds && initialValues) {
      const cats = careCategories.filter(c => currentProgramCarePlanCategoryIds.includes(c.id) || (initialValues.careCategory && initialValues.careCategory === c.id))
        .map(c => {return {label: c.name, value: c.id}});
      setCategories(cats);
    }
  }, [careCategories, currentProgramCarePlanCategoryIds, initialValues]);

  useEffect(() => {
    if (lifeAreas && currentProgramCarePlanLifeAreaIds && initialValues) {
      const areas = lifeAreas.filter(la => currentProgramCarePlanLifeAreaIds.includes(la.id) || (initialValues.lifeArea && initialValues.lifeArea === la.id))
        .map(la => {return {label: la.name, value: la.id}});
      setAreas(areas);
    }
  }, [lifeAreas, currentProgramCarePlanLifeAreaIds, initialValues]);

  useEffect(() => {
    if (barrierStatuses) {
      setStatuses(barrierStatuses.map(c => {return {label: c.name, value: c.id}}));
    }
  }, [barrierStatuses]);
  
  useEffect(() => {
    if (currentProgram) {
      let newInitialValues = barrier
        ? {
            id: barrier.id,
            name: barrier.name,
            programId: barrier.programId,
            clientId: client.id,
            careStatusId: barrier.careStatusId || '',
            careLifeAreaId: barrier.careLifeAreaId || '',
            careCategoryId: barrier.careCategoryId || '',
            assessmentId: barrier.assessmentId
          }
        : {
            programId: currentProgram.id,
            clientId: client.id,
            name: '',
            careStatusId: '',
            careLifeAreaId: '',
            careCategoryId: '',
          };
      setInitialValues(newInitialValues);
    }
  }, [barrier, currentProgram]);

  const onSubmit = async (newValues) => {
    await upsertBarrier(newValues, client.id, currentProgram.id);
    afterSave(newValues);
  }

  const buildFormContent = (isDisabled, formValues) => {
    return (
      <LotusFormItems>
        <LotusTextInput
          name="name"
          label="Name"
          required
          disabled={isDisabled}
          maxLength={35}
        />
        {statuses &&
          <LotusSelect
            name="careStatusId"
            label="Status"
            items={statuses}
            disabled={isDisabled}
            required
          />
        }
        {categories && currentProgram.barrierCareCategoryEnabled &&
          <LotusSelect
            name="careCategoryId"
            label="Category"
            items={categories}
            disabled={isDisabled}
          />
        }
        {areas && currentProgram.barrierLifeAreaEnabled &&
          <LotusSelect
            name="careLifeAreaId"
            label="Life Area"
            items={areas}
            disabled={isDisabled}
          />
        }
      </LotusFormItems>
    );
  }

  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(barrier ? 'Edit' : 'Add')} ${currentProgram.barrierDisplayName || 'Barrier'}`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      disabled={readOnly}
      buildFormContent={buildFormContent}
    />
  );
}