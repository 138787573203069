import React, { useEffect } from 'react';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { FormGroup, Typography } from '@mui/material';
import { Stack, Grid } from '@mui/material';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { v4 as uuidv4 } from 'uuid';

export default function ContactSiteVisitTypeLinker({
  values,
  handleChange,
  setFieldValue,
}) {

  const addContactSiteVisitType = () => {
    if (values.addedContactSiteVisitType.name) {
      values.currentContactSiteVisitTypes.push({...values.addedContactSiteVisitType, id: uuidv4()});
      setFieldValue('currentContactSiteVisitTypes', values.currentContactSiteVisitTypes);
      values.selectedContactSiteVisitTypes.push(`${values.addedContactSiteVisitType.name}-${values.addedContactSiteVisitType.methodId}`);
      setFieldValue('selectedContactSiteVisitTypes', values.selectedContactSiteVisitTypes);
      setFieldValue('addedContactSiteVisitType', {name: '', methodId: ''});
    }
  };

  useEffect(() => {
    if (values.currentContactMethods) {
      values.currentContactSiteVisitTypes.forEach(t => {
        if (!values.currentContactMethods.filter(m => m.active === true).map(m => m.id).includes(t.methodId)) {
          t.methodId = '';
        }
      });
      if (values.addedContactSiteVisitType.methodId && !values.currentContactMethods.find((ss) => ss.active && ss.id === values.addedContactSiteVisitType.methodId)) {
        values.addedContactSiteVisitType.name = '';
        values.addedContactSiteVisitType.methodId = '';
      }

    }
  }, [values.currentContactMethods, values.currentContactSiteVisitTypes]);

  return (
    <>
      <LotusFormItem>
        <FormGroup>
          {values.currentContactSiteVisitTypes.map((node, index) => {
            return (
              <Grid key={`contacttype-${index}`} container>
                <Grid item xs={6}>
                  <LotusCheckbox
                    name={'selectedContactSiteVisitTypes'}
                    label={node.name}
                    value={`${node.name}-${node.methodId}`}
                    checked={values.selectedContactSiteVisitTypes.find((n) => n === `${node.name}-${node.methodId}`) !== undefined}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{marginTop: 10}}>{node.methodId ? 'Method: ' + (values.currentContactMethods.find((m => m.id === node.methodId)) || {name: ''}).name: 'Method: '}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </FormGroup>
      </LotusFormItem>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
        <LotusTextInput
          name="addedContactSiteVisitType.name"
          label="Name"
        />
        <LotusSelect
          style={{width: 200}}
          name='addedContactSiteVisitType.methodId'
          label='Method'
          items={values.currentContactMethods && values.currentContactMethods.filter(m => m.active).map(s => {return {label: s.name, value: s.id}})}
        />
        <LotusButton onClick={addContactSiteVisitType}>
          Add
        </LotusButton>
      </Stack>
    </>
  );
}
