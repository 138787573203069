import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './DashboardsContextReducer';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';
import { parseApiResult } from "lib/utils";

const initialState = {
  metricEntityTypeTotalCounts: undefined
};

const DashboardsContext = createContext();

export const DashboardsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DashboardsContext.Provider value={{ state, dispatch }}>
      {children}
    </DashboardsContext.Provider>
  );
};


export const useDashboards = () => {
  const { state, dispatch } = useContext(DashboardsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadMetricQueryResult = async ({params, sortOrder}) => {
    try {
      addBusyBee('loadMetricQueryResult');
      const url = `/api/metrics`;

      const { data } = await axios.post(url, {
        operationName: 'getMetricResult',
        params,
        sortBy: sortOrder ? [sortOrder] : [],
      });
      let keys = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_METRIC_QUERY_RESULT_SUCCESS,
        payload: {
          metricQueryResult : {
            queryParams: params,
            keys
          }
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMetricQueryResult');
    }
  };

  const loadMetricClientApplicationDetails = async ({clientIds}) => {
    try {
      addBusyBee('loadMetricClientApplicationDetails');
      const url = `/api/metrics`;

      const { data } = await axios.post(url, {
        operationName: 'getMetricDetails',
        entityType: 'client_applications',
        ids: clientIds
      });
      let details = parseApiResult(data).body;

      // Need to make sure the details are in the same order as the clientIds
      details = clientIds.map(id => {return details.find(d => d.clientId === id);});

      dispatch({
        type: messageTypes.LOADING_METRIC_CLIENT_APPLICATION_DETAILS_SUCCESS,
        payload: {
          metricClientApplicationDetailsResult : {
            clientIds,
            details
          }
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMetricClientApplicationDetails');
    }
  };

  const loadMetricEntityTypeTotalCount = async (entityType, restrictToRequestingUser, filters) => {
    try {
      addBusyBee('loadMetricEntityTypeTotalCount');
      const url = `/api/metrics`;

      const { data } = await axios.post(url, {
        operationName: 'getMetricEntityTypeTotalCount',
        entityType,
        restrictToRequestingUser,
        filters
      });
      let {userId, agencyId, count} = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_METRIC_ENTITY_TYPE_TOTAL_COUNT_SUCCESS,
        payload: {
          metricEntityTypeTotalCount : {
            entityType,
            filters,
            userId,
            agencyId,
            count
          }
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMetricEntityTypeTotalCount');
    }
  };

  const loadMultiMetricMatrixQueryResult = async ({params}) => {
    try {
      addBusyBee('loadMultiMetricMatrixQueryResult');
      const url = `/api/metrics`;

      const { data } = await axios.post(url, {
        operationName: 'getMultiMetricMatrixResult',
        params
      });
      let matrix = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_MULTI_METRIC_MATRIX_QUERY_RESULT_SUCCESS,
        payload: {
          multiMetricMatrixQueryResult : {
            queryParams: params,
            matrix
          }
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMultiMetricMatrixQueryResult');
    }
  };

  const loadMetrics = async (categories) => {
    try {
      addBusyBee('loadMetrics');

      const url = `/api/metrics`;
      const { data } = await axios.post(url, { operationName: 'getMetrics', categories: categories });
      const metrics = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_METRICS_SUCCESS,
        payload: {
          metrics,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMetrics');
    }
  };

  return {
    ...state,
    loadMetricQueryResult,
    loadMetricClientApplicationDetails,
    loadMetricEntityTypeTotalCount,
    loadMultiMetricMatrixQueryResult,
    loadMetrics
  };
};
