export const messageTypes = {
  LOADING_GRANTEE_AGENCIES_SUCCESS: 'LOADING_GRANTEE_AGENCIES_SUCCESS',
  LOADING_AGENCIES_FOR_GRANTEE_SUCCESS: 'LOADING_AGENCIES_FOR_GRANTEE_SUCCESS',
  LOADING_AGENCY_CASE_MANAGERS_SUCCESS: 'LOADING_AGENCY_CASE_MANAGERS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.LOADING_GRANTEE_AGENCIES_SUCCESS:
    case messageTypes.LOADING_AGENCIES_FOR_GRANTEE_SUCCESS:
    case messageTypes.LOADING_AGENCY_CASE_MANAGERS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
