import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useLists } from "contexts/ListsContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";
import { GranteeAgencyEligibilityView } from "./GranteeAgencyEligibilityView";
import { useAgencySearch } from "contexts/AgencySearchContext";

export default function GranteeAgencyEligibility() {

  const [initialEligibilityStartDateList, setInitialEligibilityStartDateList] = useState();
  const [annualCoveragePeriodList, setAnnualCoveragePeriodList] = useState();
  const [eligibilityStartDateDayList, setEligibilityStartDateDayList] = useState();
  const [childAgenciesList, setChildAgenciesList] = useState();
  const [agencyLevelConsents, setAgencyLevelConsents] = useState();
  const [hivDiseaseStageList, setHivDiseaseStageList] = useState();
  const [medicationAssistanceLevelList, setMedicationAssistanceLevelList] = useState();
  const [premiumAssistanceCoverageTypeList, setPremiumAssistanceCoverageTypeList] = useState();

  const { eligibilityApplicationUrgencyTypes, eligibilityStartDateTypes, hivDiseaseStages, loadClientInfoPicklists,
    medicationAssistanceLevels, premiumAssistanceCoverageTypes, adapServiceDenialReasons } = useLists();
  const {agenciesForGrantee, loadAgenciesForGrantee} = useAgencySearch();
  const { agency, consentTypes, loadAgencyConsentTypes, updateAgencyEligibilityConfig } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  useEffect(() => {
    if (!eligibilityApplicationUrgencyTypes || !eligibilityStartDateTypes || !hivDiseaseStages || !adapServiceDenialReasons) {
      loadClientInfoPicklists();
    }
    let days = [];
    for(let i = 1; i <= 31; i++) {
      days.push({label: i, value: i})
    }
    setEligibilityStartDateDayList(days);
  }, []);
  
  useEffect(() => {
    if (agency) {
      if (!consentTypes) {
        loadAgencyConsentTypes();
      }
      if (!agenciesForGrantee) {
        loadAgenciesForGrantee(agency.id);
      }
    }
  }, [agency]);

  useEffect(() => {
    if (consentTypes && agency) {
      setAgencyLevelConsents(consentTypes.filter(ct => ct.isAgencyWide || agency.eligibilityConfig.application.requiredConsents?.includes(ct.id)).map(ct => { return {value: ct.id, label: ct.name } }));
    }
  }, [consentTypes, agency]);

  useEffect(() => {
    if(eligibilityStartDateTypes) {
      setInitialEligibilityStartDateList(eligibilityStartDateTypes.filter(types =>
        types.itemKey === 'date_of_processing' || types.itemKey === 'first_day_of_month'
      ).map(type => {return {label: type.itemName, value: type.id}}));
      setAnnualCoveragePeriodList(eligibilityStartDateTypes.filter(types =>
        types.itemKey !== 'first_day_of_month'
      ).map(type => {return {label: type.itemName, value: type.id}}))
    }
  }, [eligibilityStartDateTypes]);

  useEffect(() => {
    if (hivDiseaseStages) {
      setHivDiseaseStageList(hivDiseaseStages.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [hivDiseaseStages]);

  useEffect(() => {
    if (medicationAssistanceLevels) {
      setMedicationAssistanceLevelList(medicationAssistanceLevels.map(g => { return {label: g.itemName, value: g.itemKey}}));
    }
  }, [medicationAssistanceLevels]);

  useEffect(() => {
    if (premiumAssistanceCoverageTypes) {
      setPremiumAssistanceCoverageTypeList(premiumAssistanceCoverageTypes.map(g => { return {label: g.itemName, value: g.itemKey}}));
    }
  }, [premiumAssistanceCoverageTypes]);

  useEffect(() => {
    if(agenciesForGrantee && agenciesForGrantee.length > 0) {
      const childAgencies = agenciesForGrantee.map(c => {return {label: c.name, value: c.id}});
      setChildAgenciesList(childAgencies || []);
    }
  }, [agenciesForGrantee]);

  const saveEligibilityConfig = async (values) => {
    if(values.application.selectedInitialExtensionType !== 'extension_months') {
      values.application.selectedExtensionMonths = '';
    }
    if(annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Birth Date')}).length > 0) {
      values.application.selectedEligibilityStartDateMonth = '';
      values.application.selectedEligibilityStartDateDay = '';
    }
    if(!values.application.selectedAnnualCoveragePeriodType || annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Date of Processing')}).length > 0) {
      values.application.selectedEligibilityStartDateMonth = '';
      values.application.selectedEligibilityStartDateDay = '';
      values.application.selectedInitialExtensionType = '';
      values.application.selectedExtensionMonths = '';
    }
    await updateAgencyEligibilityConfig(values);
    await loadUserAgency();
  };
  
  return (
    agency && initialEligibilityStartDateList && annualCoveragePeriodList && eligibilityStartDateDayList && 
    childAgenciesList && agencyLevelConsents && hivDiseaseStageList && medicationAssistanceLevelList &&
    premiumAssistanceCoverageTypeList && adapServiceDenialReasons && (
      <GranteeAgencyEligibilityView
        eligibilityConfig={agency.eligibilityConfig}
        initialEligibilityStartDateList={initialEligibilityStartDateList}
        annualCoveragePeriodList={annualCoveragePeriodList}
        eligibilityStartDateDayList={eligibilityStartDateDayList}
        childAgenciesList={childAgenciesList}
        agencyLevelConsents={agencyLevelConsents}
        hivDiseaseStageList={hivDiseaseStageList}
        medicationAssistanceLevelList={medicationAssistanceLevelList}
        premiumAssistanceCoverageTypeList={premiumAssistanceCoverageTypeList}
        adapServiceDenialReasons={adapServiceDenialReasons}
        eligibilityApplicationUrgencyTypes={eligibilityApplicationUrgencyTypes}
        handleSave={saveEligibilityConfig}
        handleCancel={() => {}} // staying on page, just reverting changes
      />
    )
  );
}
