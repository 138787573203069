export const userLeftUnresolvedComment = (values, loggedInUser) => {
  const checkChain = (chain) => {
    return (chain && chain.length > 0 && chain[chain.length - 1].commenterId === loggedInUser.id && !chain[chain.length - 1].resolverId);
  }
  let result = (
    checkChain(values.applicantCommentsChain) ||
    checkChain(values.incomeCommentsChain) ||
    checkChain(values.healthCommentsChain) ||
    checkChain(values.insuranceCommentsChain) ||
    checkChain(values.personalContactCommentsChain) ||
    checkChain(values.subprogramCommentsChain) ||
    checkChain(values.addressCommentsChain) || 
    checkChain(values.consentCommentsChain)
  );
  return result;
}