import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from "@lotus/components";
import PageSection from 'components/widgets/Layout/LotusPageSection';
import { Grid, styled } from '@mui/material';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

// todo, fix this in the table component
const StyledGrid = styled(Grid)(() => ({
  ['.MuiTableRow-root']: {
    cursor: 'pointer'
  },
}));

export default function DuplicateClientHandler({duplicateInfo, agencyId, registerClient}) {
  const navigate = useNavigate();
  const [originalRow, setOriginalRow] = useState();
  const [duplicateRows, setDuplicateRows] = useState();

  const originalColumns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'First Name',
      name: 'firstName',
    },
    {
      label: 'Last Name',
      name: 'lastName',
    },
    {
      label: 'SSN',
      name: 'socialSecurityNumber',
    },
    {
      label: 'DOB',
      name: 'birthDate',
    },
  ];

  const duplicateColumns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'First Name',
      name: 'firstName',
    },
    {
      label: 'Last Name',
      name: 'lastName',
    },
    {
      label: 'SSN',
      name: 'socialSecurityNumber',
    },
    {
      label: 'Agency',
      name: 'agencyName',
    },
    {
      label: 'Created By',
      name: 'createdBy',
    },
    {
      label: 'DOB',
      name: 'birthDate'
    },
  ];

  useEffect(() => {
    if (duplicateInfo) {
      setOriginalRow({
        id: duplicateInfo.original.client_id,
        firstName: duplicateInfo.original.firstName,
        lastName: duplicateInfo.original.lastName,
        socialSecurityNumber: duplicateInfo.original.socialSecurityNumber,
        birthDate: duplicateInfo.original.birthDate
      });
      setDuplicateRows(duplicateInfo.duplicates.map((dupInfo) => {
        return {
          id: dupInfo.client_id,
          firstName: dupInfo.firstName,
          lastName: dupInfo.lastName,
          socialSecurityNumber: dupInfo.socialSecurityNumber,
          createdBy: dupInfo.createdBy,
          birthDate: dupInfo.birthDate,
          agencyName: dupInfo.agencyName
        }
      }));
    }
  }, [duplicateInfo]);

  const registerClicked = async () => {
    await registerClient(originalRow.id, duplicateInfo.original, agencyId, true);
  }

  return (
      <>
      <LotusPageTitle title="Potential Duplicate Detected"/>
      <LotusSpacedBlock>
        Client may already exist. Check the 'Existing Records' list below to see if you see the client you intended to create is present.
        If not, and this client is indeed a unique new client, click the 'Confirm New Registration' button to continue and create the new client record.
      </LotusSpacedBlock>
      {originalRow &&
        <PageSection header="Your Entry">
          <LotusSpacedBlock>
          <Table
            columns={originalColumns}
            data={[originalRow]}
            options={{
              search: false,
              searchAlwaysOpen: false,
              download: false,
              print: false,
              filter: false,
              pagination: false,
              viewColumns: false,
              selectableRows: 'none',
              selectableRowsHideCheckboxes: false,
              selectableRowsHeader: true,
            }}
          />
          </LotusSpacedBlock>
          <LotusSpacedBlock>
            <Button onClick={registerClicked}>
                Confirm New Registration
            </Button>
          </LotusSpacedBlock>
        </PageSection>
      }
      {duplicateRows &&
        <PageSection header="Existing Records">
          <LotusSpacedBlock>
            If you see the client you intended to create in the list below, click on that client to use the existing record instead of your entry.
          </LotusSpacedBlock>
          <StyledGrid>
            <Table
              columns={duplicateColumns}
              data={duplicateRows}
              options={{
                search: false,
                searchAlwaysOpen: false,
                download: false,
                print: false,
                filter: false,
                pagination: false,
                viewColumns: false,
                selectableRows: 'none',
                selectableRowsHideCheckboxes: false,
                selectableRowsHeader: true,
                onRowClick: (rowData) => {
                  const id = rowData[0];
                  return navigate(`/client/${id}/summary/summary`);
                },
              }}
            />
          </StyledGrid>
        </PageSection>
      }
      </>
  );
}