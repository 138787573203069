import { useState, useEffect } from "react";
import { Stack, Select, MenuItem, InputLabel, FormControl } from '@mui/material';

export default function ApplicationCustomSearch ({
  doSearch, 
  subprogramFilterOn, 
  subprogramOptionList,
  subagencyFilterOn, 
  agencyOptionList,
  currentFilterValues
}) {
  const [agencyName, setAgencyName] = useState(null);
  const [subprograms, setSubprograms] = useState(null);

  useEffect(() => {
    if (currentFilterValues) {
      if (currentFilterValues.find(x => x.name === 'subprograms')) {
        let subp = currentFilterValues.find(x => x.name === 'subprograms').value;
        if (subp !== 'all') {
          setSubprograms(subp);
        }
      }
      if (currentFilterValues.find(x => x.name === 'agencyName')) {
        let ag = currentFilterValues.find(x => x.name === 'agencyName').value;
        if (ag !== 'all') {
          setAgencyName(ag);
        }
      }
    }
  }, [currentFilterValues]);

  return (
    <Stack direction="row" spacing={1}>
      {subprogramFilterOn && subprogramOptionList && subprogramOptionList.length > 0 &&
        <FormControl fullWidth variant= "outlined" size="small" style={{width: '100%', minWidth: 150}}>
          <InputLabel id={`subprogram-label`}>Subprogram</InputLabel>
          <Select
            labelId={`subprogram-label`}
            label="Subprogram"
            value={currentFilterValues?.find(x => x.name === 'subprograms')?.value || 'all'}
            onChange={(e) => {setSubprograms(e.target.value); doSearch({subprograms: e.target.value !== 'all' ? e.target.value : null, agencyName: agencyName !== 'all' ? agencyName : null})}}
          >
            {subprogramOptionList &&
              subprogramOptionList.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.key}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      }
      {subagencyFilterOn && agencyOptionList && agencyOptionList.length > 0 &&
        <FormControl fullWidth variant= "outlined" size="small" style={{width: '100%', minWidth: 150}}>
          <InputLabel id={`agencyname-label`}>Agency Group</InputLabel>
          <Select
            variant= "outlined" 
            size="small"
            label="Agency Group"
            value={currentFilterValues?.find(x => x.name === 'agencyName')?.value || 'all'}
            onChange={(e) => {setAgencyName(e.target.value); doSearch({subprograms: subprograms !== 'all' ? subprograms : null, agencyName: e.target.value !== 'all' ? e.target.value : null})}}
          >
            {agencyOptionList &&
              agencyOptionList.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.key}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      }
    </Stack>
  );
}