
export const messageTypes = {
  LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS: 'LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS',
  TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS: 'TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS',
  LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS: 'LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS',
  LOADING_ENROLLMENT_DETAILS_FOR_APPLICATION_SUCCESS: 'LOADING_ENROLLMENT_DETAILS_FOR_APPLICATION_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        adapProgramEnrollments: payload.adapProgramEnrollments,
      };
    case messageTypes.TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS:
      let adapEnrollments = state.adapProgramEnrollments || [];
      let enrollmentsWithoutTerminatedOne = adapEnrollments.filter(e => e.id !== payload.enrollment.id);
      enrollmentsWithoutTerminatedOne.push(payload.enrollment);
      return {
        ...state,
        adapProgramEnrollments: enrollmentsWithoutTerminatedOne,
      };
    case messageTypes.LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.LOADING_ENROLLMENT_DETAILS_FOR_APPLICATION_SUCCESS:
      let newAppDetails = state.appEnrollmentDetails ? [...state.appEnrollmentDetails.filter(ad => ad.appId !== payload.appId)] : [];
      newAppDetails.push({appId: payload.appId, details: payload.details});
      return {
        ...state,
        appEnrollmentDetails: newAppDetails
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
