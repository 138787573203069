import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useFormikContext } from "formik";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusIntegerInput from "components/form/inputField/common/LotusIntegerInput";
import LotusRemoveButton from "components/button/common/LotusRemoveButton";
import { Stack } from "@mui/material";
import LotusButtonList from "components/button/LotusButtonList";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

export default function MetricView({ metric, agencyMetric, agencyMetricIndex, isFirst}) {
  
  const { values, setFieldValue } = useFormikContext();

  function addAgencyMetric(metric) {
    const agencyMetric = {metricId: metric.id, title: metric.name, data: {}};
    if (metric.key === 'apps_expiring_soon') {
      agencyMetric.data.appsExpiringDays = '';
    }
    if (metric.key === 'unsubmitted_apps') {
      agencyMetric.data.unsubmittedDays = '';
    }
    if (metric.key === 'undetermined_apps') {
      agencyMetric.data.determinationDays = '';
    }
    if (metric.key === 'pending_apps_not_resub') {
      agencyMetric.data.pendingAppDays = '';
    }
    if (metric.key === 'unreviewed_apps') {
      agencyMetric.data.reviewNotStartedDays = '';
    }
    if (metric.key === 'apps_in_recert_window') {
      agencyMetric.data.recertificationWindowDays = '';
    }
    if (metric.key === 'retention') {
      agencyMetric.data.missingTestMonths = '';
    }
    if (metric.key === 'suppression') {
      agencyMetric.data.testType = '';
      agencyMetric.data.valueExceeded = '';
    }
    setFieldValue('agencyMetrics', [...values.agencyMetrics, agencyMetric]);
  }

  function removeAgencyMetric(metric) {
    setFieldValue('agencyMetrics', values.agencyMetrics.filter(am => am.metricId !== metric.id));
  }

  return (
    <>
      <LotusSubsectionHeader 
        title={metric.name} 
        indentLevel={1}
        divider={!isFirst}
        actionButtonList={
          <LotusButtonList>
            {!agencyMetric && (
              <LotusAddButton onClick={() => addAgencyMetric(metric)} />
            )}
            {agencyMetric && (
              <LotusRemoveButton onClick={() => removeAgencyMetric(metric)} />
            )}
          </LotusButtonList>
        } 
      />
      {agencyMetric &&
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusTextInput
              name={`agencyMetrics.${agencyMetricIndex}.title`}
              label="Title"
              maxLength={100}
              required
            />
            {metric.key === 'apps_expiring_soon' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.appsExpiringDays`}
                label="Applications that will expire in x days"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'unsubmitted_apps' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.unsubmittedDays`}
                label="Applications not submitted within x days"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'undetermined_apps' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.determinationDays`}
                label="Applications submitted without a determination within x days"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'pending_apps_not_resub' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.pendingAppDays`}
                label="Pending Applications without being resubmitted within x days"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'unreviewed_apps' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.reviewNotStartedDays`}
                label="Submitted Applications where review has not been started within x days"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'apps_in_recert_window' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.recertificationWindowDays`}
                label="Unstarted Applications that are x days into the recertification window"
                required
                minValue={1}
                maxValue={100}
              />
            )}

            {metric.key === 'retention' && (
              <LotusIntegerInput
                name={`agencyMetrics.${agencyMetricIndex}.data.missingTestMonths`}
                label="No test within x months"
                required
                minValue={1}
                maxValue={100}
              />
            )}
            {metric.key === 'suppression' && (
              <>
                <LotusTextInput
                  name={`agencyMetrics.${agencyMetricIndex}.data.testType`}
                  label="Test Type"
                  maxLength={100}
                  required
                />
                <LotusIntegerInput
                  name={`agencyMetrics.${agencyMetricIndex}.data.valueExceeded`}
                  label="Value Exceeded"
                  required
                  minValue={1}
                  maxValue={100}
                />
              </>
            )}
          </LotusStackedFormItems>
        </LotusSubsectionContent>
      }
    </>
  );
}