import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { HashLink } from 'react-router-hash-link';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

const PREFIX = 'LotusStickyNav';

const classes = {
  navbar: `${PREFIX}-navbar`,
  selected: `${PREFIX}-selected`,
  title: `${PREFIX}-title`,
  navigation: `${PREFIX}-navigation`,
};

const Root = styled('header')(({ theme }) => ({
  [`&.${classes.navbar}`]: {
    width: '100%',
    maxWidth: '100%',
    transition: 'all .2s ease',
  },

  [`& .${classes.selected}`]: {
    color: theme.palette.secondary.main,
  },

  [`& .${classes.title}`]: {
    fontSize: 16,
  },

  [`& .${classes.navigation}`]: {
    '& ul': {
      boxSizing: 'border-box',
      listStyleType: 'none',
      minHeight: '6vh',
      justifyContent: 'flex-end',
      alignItems: 'left',
      paddingInlineStart: 0
    },
    '& li': {
      padding: 5,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    position: 'sticky',
    top: 40,
    paddingRight: 15
  },
}));

export default function LotusStickyNav({ linkData }) {
  const [scrolled, setScrolled] = useState(false);
  const [savedLocation, setSavedLocation] = useState(-1);
  const [links, setLinks] = useState([]);
  const elementRef = useRef();

  useEffect(() => {
    if (linkData) {
      const newLinks = linkData.map((data, index) => {
        return (
          <li key={`${data.title}${index}`}>
            <HashLink smooth to={data.url}>
              {data.title}
            </HashLink>
          </li>
        );
      });

      setLinks(newLinks);
    }
  }, [linkData]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const shouldBeScrolled = currPos.y <= 0;

      if (scrolled !== shouldBeScrolled) {
        setScrolled(shouldBeScrolled);
      }
    },
    [],
    elementRef
  );

  useScrollPosition(({ prevPos, currPos }) => {
    if (scrolled) {
      if (savedLocation === -1) {
        setSavedLocation(currPos.y);
      } else {
        const shouldBeScrolled = currPos.y <= savedLocation;

        if (scrolled !== shouldBeScrolled) {
          setScrolled(shouldBeScrolled);
          setSavedLocation(-1);
        }
      }
    }
  });

  return (
    <Root
      ref={elementRef}
      className={classes.navbar}
    >
      <nav className={classes.navigation}>
        <ul>{links}</ul>
      </nav>
    </Root>
  );
}