import React from 'react';
import { FieldArray } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

export default function EnrollmentAssigneeLinker({
  values,
  touched,
  errors,
  newAssigneeTemplate,
  handleChange,
}) {
  return (
    <>
      <LotusFormItem>
        <div>Enrollment Assignees for Program</div>
      </LotusFormItem>
      <FieldArray name="enrollmentAssignees">
        {({push, remove}) => {
        return (
          <>
            <LotusFormItem>
            {values.enrollmentAssignees.length > 0 && values.enrollmentAssignees.map((assignee, index) => (
              <LotusFormItem key={assignee.id || index}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <LotusTextInput
                      name={`enrollmentAssignees.${index}.name`}
                      label="Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LotusButton onClick={() => remove(index)}>
                      <DeleteIcon /> Remove
                    </LotusButton>
                  </Grid>
                </Grid>
              </LotusFormItem>
            ))}
            </LotusFormItem>
            <LotusButton plusIcon onClick={() => push({...newAssigneeTemplate, id: uuidv4()})}>Add Assignee</LotusButton>
          </>
        );
      }}
      </FieldArray>
    </>
  );
}
