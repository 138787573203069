import React, {useEffect} from 'react';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { FormGroup, Typography, Grid, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

export default function ActivityLogSubjectLinker({
  values,
  handleChange,
  setFieldValue,
}) {
  const addActivityLogSubject = () => {
    if (values.addedActivityLogSubject.name) {
      values.currentActivityLogSubjects.push({...values.addedActivityLogSubject, id: uuidv4()});
      setFieldValue('currentActivityLogSubjects', values.currentActivityLogSubjects);
      values.selectedActivityLogSubjects.push(`${values.addedActivityLogSubject.name}-${values.addedActivityLogSubject.typeId}`);
      setFieldValue('selectedActivityLogSubjects', values.selectedActivityLogSubjects);
      setFieldValue('addedActivityLogSubject', {name: '', typeId: ''});
    }
  };

  useEffect(() => {
    if (values.currentActivityLogTypes) {
      values.currentActivityLogSubjects.forEach(s => {
        if (!values.currentActivityLogTypes?.filter(t => t.active).map(t => t.id).includes(s.typeId)) {
          s.typeId = '';
        }
      });
      if (values.addedActivityLogSubject.typeId && !values.currentActivityLogTypes.find((ss) => ss.active && ss.id === values.addedActivityLogSubject.typeId)) {
        values.addedActivityLogSubject.name = '';
        values.addedActivityLogSubject.typeId = '';
      }
    }
  }, [values.currentActivityLogTypes]);

  return (
    <>
      <LotusFormItem>
        <FormGroup>
          {values.currentActivityLogSubjects.map((node, index) => {
            return (
              <Grid key={`activitylogsubject-${index}`} container>
                <Grid item xs={8}>
                  <LotusCheckbox
                    name={'selectedActivityLogSubjects'}
                    label={node.name}
                    value={`${node.name}-${node.typeId}`}
                    checked={values.selectedActivityLogSubjects.find((ss) => ss === `${node.name}-${node.typeId}`) !== undefined}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{marginTop: 10}}>{ node.typeId ? "Type: " + (values.currentActivityLogTypes.find((t => t.id === node.typeId)) || {name: ''}).name  : "Type: "}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </FormGroup>
      </LotusFormItem>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
        <LotusTextInput
          name="addedActivityLogSubject.name"
          label="Name"
        />
        <LotusSelect
          name='addedActivityLogSubject.typeId'
          label='Activity Type'
          style={{width: 300}}
          items={values.currentActivityLogTypes && values.currentActivityLogTypes?.filter(t => t.active).map(s => {return {label: s.name, value: s.id}})}
        />
        <LotusButton onClick={addActivityLogSubject}>
          Add
        </LotusButton>
      </Stack>
    </>
  );
}
