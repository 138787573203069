import React, { useEffect, useState } from 'react';
import { useDataImport } from 'contexts/DataImportContext';
import moment from 'moment';
import { Table } from '@lotus/components';
import ActionDialog from 'components/Dialogs/ActionDialog';
import LotusFileViewer from 'components/widgets/LotusFileViewer';
import { TXT, CSV } from '../../lib/docTypes';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Typography } from '@mui/material';

export default function ImportsTable({ agencyId }) {
  const { importRequests, getImportRequests, revertImportedData } = useDataImport();
  const [requests, setRequests] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [metaData, setMetaData] = useState();
  const [documentToView, setDocumentToView] = useState();
  const [selectedRequestId, setSelectedRequestId] = useState();
  const DELETE_UNSUPORRTED_TYPES = ['Agencies', 'Accounts', 'Health Plans'];

  const canDelete = (type) => {
    if (type) {
      if (DELETE_UNSUPORRTED_TYPES.indexOf(type) >= 0) {
        return false;
      }
    }
    return true;
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  const handleDeleteBatchClicked = (reqId) => {
    setSelectedRequestId(reqId)
    setShowConfirmDelete(true);
  };

  const handleConfirmDeleteClose = () => {
    setDocumentToView(null)
    setShowConfirmDelete(false);
  };

  const handleConfirmDeleteSubmit = () => {
    revertImportedData(agencyId, selectedRequestId);
    setDocumentToView(null)
    setShowConfirmDelete(false);
  };

  const formatStatus = (r) => {
    if (r.complete_date) {
      if (r.batch_deleted_date) {
        if (r.errors) {
          return 'Delete Failed';
        } else {
          return ('Delete' + (r.records_deleted ? 'd' : ' in Progress'))
        }
      }
      else {
        return (r.test_mode ? 'Test' : 'Import') + ' Complete'
      }
    }
    else {
      return (r.test_mode ? 'Test' : 'Import') + (r.errors ? ' Failed' : ' In Progress');
    }
  };

  const formatResult = (r) => {
    if (r.errors) {
      return r.errors
    }

    if (r.complete_date) {
      if (r.errors) {
        return r.errors
      }
      if (r.batch_deleted_date) {
        if (r.records_deleted) {
          return r.records_deleted + ' records deleted'
        } else {
          return '';
        }
      }
      if ((r.records_processed && r.records_processed > 0) || (r.number_of_errors && r.number_of_errors > 0)) {
        return (r.number_of_errors > 0 ? 'Failed ' + r.number_of_errors + ' of  ' + (r.records_in_file - (r.rows_skipped || 0)) :
          'Success: ' + (r.records_processed) + ' records')

      }
    } else {
      if (r.records_in_file) {
        return (r.records_processed + ' of ' + (r.records_in_file - (r.rows_skipped || 0)) + ' records processed')
      }
    }
  };


  const viewRequestDetailsForRow = async (rowData, rowMeta) => {
    const importRequest = importRequests.find(i => i.id === rowData[0]);
    const type = importRequest.file_name.toLowerCase().endsWith('csv') ? CSV : TXT;
    let specIndex = importRequest.display_file_name.lastIndexOf('.');
    let errFile = importRequest.display_file_name.substring(0, specIndex) + '_errors' + importRequest.display_file_name.substring(specIndex);

    let file = {
      type: type, uniqueFileName: importRequest.file_name, displayName: importRequest.display_file_name
    }
    let errorFile = {
      type: type, uniqueFileName: 'errors.' + importRequest.file_name, displayName: errFile
    }

    setDocumentToView(importRequest?.complete_date ? [file, errorFile] : [file]);

    if (importRequest?.complete_date) {
      setMetaData({
        field1: { label: 'Status:', value: formatStatus(importRequest) },
        field2: { label: 'Result:', value: formatResult(importRequest) },
        field3: { label: 'Import Type:', value: importRequest.import_type_name },
        field4: { label: 'Desc:', value: importRequest.description },
        field5: { label: 'Delimiter:', value: importRequest.delimiter },

      });
    } else {
      if (!importRequest?.errors) {
        setMetaData({
          field1: { label: 'Status:', value: (importRequest.test_mode ? 'Test' : 'Import') + ' In Progress' },
        });
      } else {
        setMetaData({
          field1: { label: 'Error:', value: importRequest.errors[0] },
          field2: { label: 'Status:', value: (importRequest.test_mode ? 'Test' : 'Import') + ' Failed' },
        });
      }
    }
  };

  useEffect(() => {
    if (agencyId) {
      getImportRequests(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (importRequests) {
      const reqs = importRequests.map((r) => {
        return {
          id: r.id,
          description: r.description,
          importTypeName: r.import_type_name,
          requestDate: moment(r.request_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
          requestDateStr: moment(
            r.request_date,
            'YYYY-MM-DDTHH:mm:ss.SSSZ'
          ).format('MM/DD/YYYY HH:mm'),
          startDateStr: r.start_date
            ? moment(r.start_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
              'MM/DD/YYYY HH:mm'
            )
            : '',
          statusStr: formatStatus(r),
          resultStr: formatResult(r),
          fileName: r.display_file_name || '',
        };
      });
      reqs.sort((a, b) => {
        return b.requestDate - a.requestDate;
      });
      setRequests(reqs);
    }
  }, [importRequests]);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      label: 'Description',
      name: 'description',
    },
    {
      label: 'Import Date',
      name: 'requestDateStr',
    },
    {
      label: 'Import Type',
      name: 'importTypeName',
    },
    {
      label: 'Status',
      name: 'statusStr',
    },
    {
      label: 'Result',
      name: 'resultStr',
    },
    {
      label: 'File Name',
      name: 'fileName',
    },
    {
      label: 'Actions',
      name: 'actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div direction="column" >
              {tableMeta?.rowData[4] === 'Import Complete' && canDelete(tableMeta?.rowData[3]) && (
                <LotusButton onClick={() => handleDeleteBatchClicked(tableMeta?.rowData[0])}>
                  Delete Batch
                </LotusButton>
              )}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={requests}
        options={{
          pagination: true,
          filter: false,
          search: false,
          searchOpen: false,
          download: false,
          print: false,
          viewColumns: false,
        }}
        handleRowClicked={viewRequestDetailsForRow}
      />

      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentToView) && !showConfirmDelete}
        content={<LotusFileViewer agencyId={agencyId} subfolderName="imports" title={'Request Details'} file={documentToView} metaData={metaData} handleClose={closeDocumentView} compactMode={true} />}
      />

      <ActionDialog
        title={`Delete Batch`}
        open={showConfirmDelete}
        handleClose={handleConfirmDeleteClose}
        content={
          <>
            <Typography variant='body1'>Are you sure you want to delete the imported data?</Typography>
          </>
        }
        actions={
          <div>
            <LotusButton onClick={handleConfirmDeleteClose}>
              Cancel
            </LotusButton>

            <LotusButton
              onClick={() => handleConfirmDeleteSubmit()}>
              Continue
            </LotusButton>
          </div>
        }
      />

    </div>


  );
}
