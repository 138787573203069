import React, { useState } from 'react';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function DynamicFormPreviewer({ formData }) {

  const [formToRender, setFormToRender] = useState();

  return (
    <>
      <LotusSpacedBlock>
        <LotusButton
          color="secondary"
          onClick={() => {
            // create a copy so that the original definition doesn't get messed up.
            try {
              const copy = JSON.parse(formData);
              setFormToRender({data: copy});
            } catch (e) {
              console.log("Not a valid JSON - " + e);
            }
          }}
        >
          Preview Form
        </LotusButton>
      </LotusSpacedBlock>
      {formToRender && (formToRender.data.fields || formToRender.data.sections) && (
          <HybridForm
            title={formToRender.label}
            dynamicFormDefinition={formToRender}
            dynamicFormValues={null}
          />
      )}
    </>
  );
}
