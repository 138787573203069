import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";

export default function ClientDispenseViewer({
  dispensesObj,
  dispensesConfig
}) {
  
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (dispensesObj && dispensesConfig) {
      setInitialValues({
        dispenseDate: dispensesObj.dispenseDate || '',
        status: dispensesObj.status || '',
        drugName: dispensesObj.drugName || '',
        genericName: dispensesObj.genericName || '',
        quantityDispensed: dispensesObj.quantityDispensed || '',
        daysSupply: dispensesObj.daysSupply || '',
        prescriberNpi: dispensesObj.prescriberNpi || '',
        prescriberFirstName: dispensesObj.prescriberFirstName || '',
        prescriberLastName: dispensesObj.prescriberLastName || '',
        pharmacyNpi: dispensesObj.pharmacyNpi || '',
        ndc: dispensesObj.ndc || '',
        manufacturer: dispensesObj.manufacturer || '',
        paymentType: dispensesObj.paymentType || '',
        drugCostPaid: dispensesObj.drugCostPaid || '',
        dispensingFeePaid: dispensesObj.dispensingFeePaid || '',
        adminFeePaid: dispensesObj.adminFeePaid || '',
        copayAmount: dispensesObj.copayAmount || '',
        pbmClientId: dispensesConfig?.enabled && dispensesConfig?.systemCategory?.pbmClientId ? dispensesObj.pbmClientId || '' : '',
        reversal: dispensesConfig?.enabled && dispensesConfig?.typeCategory?.reversal ? 
        (dispensesObj.reversal ? dispensesObj.reversal : (dispensesObj.reversal === null ? '' : 'false')) || '' : '',
        reversalType: dispensesConfig?.enabled && dispensesConfig?.typeCategory?.reversalType ? dispensesObj.reversalType || '' : '',
        prescriberPhoneNumber: dispensesConfig?.enabled && dispensesConfig?.prescriberCategory?.prescriberPhoneNumber ? dispensesObj.prescriberPhoneNumber || '' : '',
        pharmacyName: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyName ? dispensesObj.pharmacyName || '' : '',
        pharmacyNo: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyNo ? dispensesObj.pharmacyNo || '' : '',
        pharmacyAddress1: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyAddress1 ? dispensesObj.pharmacyAddress1 || '' : '',
        pharmacyAddress2: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyAddress2 ? dispensesObj.pharmacyAddress2 || '' : '',
        pharmacyCity: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyCity ? dispensesObj.pharmacyCity || '' : '',
        pharmacyState: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyState ? dispensesObj.pharmacyState || '' : '',
        pharmacyZipCode: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyZipCode ? dispensesObj.pharmacyZipCode || '' : '',
        pharmacyPhone: dispensesConfig?.enabled && dispensesConfig?.pharmacyCategory?.pharmacyPhone ? dispensesObj.pharmacyPhone || '' : '',
        ajudicationDate: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.ajudicationDate ? dispensesObj.ajudicationDate || '' : '',
        cycleEndDate: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.cycleEndDate ? dispensesObj.cycleEndDate || '' : '',
        rxNumber: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.rxNumber ? dispensesObj.rxNumber || '' : '',
        rxNumberQual: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.rxNumberQual ? dispensesObj.rxNumberQual || '' : '',
        fillNumber: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.fillNumber ? dispensesObj.fillNumber || '' : '',
        fillDate: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.fillDate ? dispensesObj.fillDate || '' : '',
        datePrescriptionWritten: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.datePrescriptionWritten ? dispensesObj.datePrescriptionWritten || '' : '',
        dispenseAsWrittenCode: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.dispenseAsWrittenCode ? dispensesObj.dispenseAsWrittenCode || '' : '',
        numberRefillsAuthorized: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.numberRefillsAuthorized ? dispensesObj.numberRefillsAuthorized || '' : '',
        unitOfMeasure: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.unitOfMeasure ? dispensesObj.unitOfMeasure || '' : '',
        originalQuantity: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.originalQuantity ? dispensesObj.originalQuantity || '' : '',
        originalDaySupply: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.originalDaySupply ? dispensesObj.originalDaySupply || '' : '',
        dawCode: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.dawCode ? dispensesObj.dawCode || '' : '',
        productStrength: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.productStrength ? dispensesObj.productStrength || '' : '',
        dosageFormCode: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.dosageFormCode ? dispensesObj.dosageFormCode || '' : '',
        drugType: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.drugType ? dispensesObj.drugType || '' : '',
        drugCategoryCode: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.drugCategoryCode ? dispensesObj.drugCategoryCode || '' : '',
        drugTier: dispensesConfig?.enabled && dispensesConfig?.prescriptionCategory?.drugTier ? dispensesObj.drugTier || '' : '',
        claimId: dispensesConfig?.enabled && dispensesConfig?.paymentCategory?.claimId ? dispensesObj.claimId || '' : '',
        groupId: dispensesConfig?.enabled && dispensesConfig?.paymentCategory?.groupId ? dispensesObj.groupId || '' : '',
        carrierNumber: dispensesConfig?.enabled && dispensesConfig?.paymentCategory?.carrierNumber ? dispensesObj.carrierNumber || '' : '',
        amountSalesTax: dispensesConfig?.enabled && dispensesConfig?.paymentCategory?.amountSalesTax ? dispensesObj.amountSalesTax || '' : '',
        patientPayAmount: dispensesConfig?.enabled && dispensesConfig?.paymentCategory?.patientPayAmount ? dispensesObj.patientPayAmount || '' : ''
      });
    }
  }, [dispensesObj, dispensesConfig]);

  return (
     initialValues &&
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
      >
        {({values}) => {
          return (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey"}}>
                  <Grid item xs={6}>
                    <Typography variant="h2">Dispense Record</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20, marginBottom: 24}}>
                  <LotusTextInput
                      name="dispenseDate"
                      label="Dispense Date"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="status"
                      label="Status"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="drugName"
                      label="Drug Name"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="genericName"
                      label="Generic Drug Name"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="quantityDispensed"
                      label="Quantity Dispensed"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="daysSupply"
                      label="daysSupply"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="prescriberNpi"
                      label="Prescriber NPI"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="prescriberFirstName"
                      label="Prescriber First Name"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="prescriberLastName"
                      label="Prescriber Last Name"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="pharmacyNpi"
                      label="Pharmacy NPI"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="ndc"
                      label="NDC"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="manufacturer"
                      label="Manufacturer"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="paymentType"
                      label="Payment Type"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="drugCostPaid"
                      label="Drug Cost Paid"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="dispensingFeePaid"
                      label="Dispensing Fee Paid"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="adminFeePaid"
                      label="Admin Fee Paid"
                      disabled={true}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="copayAmount"
                      label="Co-Pay Amount"
                      disabled={true}
                    />
                </Grid>
              </Grid>
              {dispensesConfig && dispensesConfig?.enabled &&
              <Grid container>
                {dispensesConfig?.systemCategory?.pbmClientId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pbmClientId"
                        label="PBM Client ID"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.typeCategory?.reversal &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="reversal"
                        label="Reversal"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.typeCategory?.reversalType &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="reversalType"
                        label="Reversal Type"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriberCategory?.prescriberPhoneNumber &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="prescriberPhoneNumber"
                        label="Prescriber Phone Number"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyName &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyName"
                        label="Pharmacy Name"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyNo &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyNo"
                        label="Pharmacy No"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyAddress1 &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyAddress1"
                        label="Pharmacy Address1"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyAddress2 &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyAddress2"
                        label="Pharmacy Address2"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyState &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyState"
                        label="Pharmacy State"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyZipCode &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyZipCode"
                        label="Pharmacy Zip Code"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.pharmacyCategory?.pharmacyPhone &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="pharmacyPhone"
                        label="Pharmacy Phone"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.ajudicationDate &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="ajudicationDate"
                        label="Ajudication Date"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.cycleEndDate &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="cycleEndDate"
                        label="Cycle End Date"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.rxNumber &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="rxNumber"
                        label="Rx Number"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.rxNumberQual &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="rxNumberQual"
                        label="Rx Number Qualifier"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.fillNumber &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="fillNumber"
                        label="Fill Number"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.fillDate &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="fillDate"
                        label="Fill Date"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.datePrescriptionWritten &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="datePrescriptionWritten"
                        label="Date Prescription Written"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.dispenseAsWrittenCode &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="dispenseAsWrittenCode"
                        label="Dispense As Written Code"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.numberRefillsAuthorized &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="numberRefillsAuthorized"
                        label="Number Refills Authorized"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.unitOfMeasure &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="unitOfMeasure"
                        label="Unit Of Measure"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.originalQuantity &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="originalQuantity"
                        label="Original Quantity"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.originalDaySupply &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="originalDaySupply"
                        label="Original Day Supply"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.dawCode &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="dawCode"
                        label="DAW Code"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.productStrength &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="productStrength"
                        label="Product Strength"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.dosageFormCode &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="dosageFormCode"
                        label="Dosage Form Code"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.drugType &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="drugType"
                        label="Drug Type"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.drugCategoryCode &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="drugCategoryCode"
                        label="Drug Category Code"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.prescriptionCategory?.drugTier &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="drugTier"
                        label="Drug Tier"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.paymentCategory?.claimId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="claimId"
                        label="Claim ID"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.paymentCategory?.groupId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="groupId"
                        label="Group ID"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.paymentCategory?.carrierNumber &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="carrierNumber"
                        label="Carrier Number"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.paymentCategory?.amountSalesTax &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="amountSalesTax"
                        label="Amount Sales Tax"
                        disabled={true}
                      />
                  </Grid>
                }
                {dispensesConfig?.paymentCategory?.patientPayAmount &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="patientPayAmount"
                        label="Patient Pay Amount"
                        disabled={true}
                      />
                  </Grid>
                }
            </Grid>
            }
            </Grid> 
          );
        }}
      </Formik>
    </Grid>) || <div></div>
}