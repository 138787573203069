import React, {createContext, useContext, useReducer} from "react";
import axios from '../axiosInterceptor';
import {
  reducer,
  messageTypes
} from "./EnrollmentsContextReducer";
import { useAppStatus } from "./AppStatusContext";
import { parseApiResult } from "lib/utils";
import moment from "moment";

const initialState = {
  customProgramEnrollments: undefined,
  ryanWhiteProgramEnrollments: undefined,
  adapProgramEnrollments: undefined,
  enrolledPrograms: undefined
};

const EnrollmentsContext = createContext();

export const EnrollmentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EnrollmentsContext.Provider value={{ state, dispatch }}>
      {children}
    </EnrollmentsContext.Provider>
  );
};

export const useEnrollments = () => {
  const { state, dispatch } = useContext(EnrollmentsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  
  const loadEnrollmentsForClient = async (clientId) => {

    addBusyBee('loadEnrollmentsForClient');

    try {

      const {data} = await axios.post(`/api/clientEnrollments`, {operationName: 'getEnrollments', clientId});
      let { enrollments } = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ENROLLMENTS_FOR_CLIENT_SUCCESS,
        payload: {
          ...enrollments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEnrollmentsForClient');
    }
  };

  const loadAdapEnrollmentsForClient = async (clientId) => {

    addBusyBee('loadAdapEnrollmentsForClient');

    try {

      const {data} = await axios.post(`/api/clientEnrollments`, {operationName: 'getAdapEnrollments', clientId});
      let { enrollments } = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS,
        payload: {
          adapProgramEnrollments: enrollments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAdapEnrollmentsForClient');
    }
  };

  const loadRyanWhiteEnrollmentsForClient = async (clientId) => {

    addBusyBee('loadRyanWhiteEnrollmentsForClient');

    try {

      const {data} = await axios.post(`/api/clientEnrollments`, {operationName: 'getRyanWhiteEnrollments', clientId});
      let { enrollments } = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_RYAN_WHITE_ENROLLMENTS_FOR_CLIENT_SUCCESS,
        payload: {
          ryanWhiteProgramEnrollments: enrollments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadRyanWhiteEnrollmentsForClient');
    }
  };

  const upsertCustomProgramEnrollment = async (enrollmentValues) => {
    try {
      addBusyBee('upsertCustomProgramEnrollment');

      const toPost = {
        operationName: 'upsertCustomProgramEnrollment',
        enrollment: enrollmentValues,
      };
      
      const { data } = await axios.post(`/api/clientEnrollments`, toPost);
      const results = parseApiResult(data);
      const { enrollment } = results.body;
      
      dispatch({
        type: messageTypes.UPSERTING_CUSTOM_PROGRAM_ENROLLMENT_SUCCESS,
        payload: {
          enrollment
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertCustomProgramEnrollment');
    }
  };

  const terminateAdapProgramEnrollment = async (enrollmentValues) => {
    try {
      addBusyBee('terminateAdapProgramEnrollment');

      const toPost = {
        operationName: 'terminateAdapProgramEnrollment',
        enrollment: enrollmentValues,
      };

      const { data } = await axios.post(`/api/clientEnrollments`, toPost);
      const { enrollment } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS,
        payload: {
          enrollment
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('terminateAdapProgramEnrollment');
    }
  };

  const findEnrollment = (programId) => {
    // Custom enrollments have only one entity per program
    let custom = state.customProgramEnrollments?.find(e => e.programId === programId);
    if (custom) {
      return custom;
    }
    // RW and ADAP can have multiple entities per program, find the current one
    let now = moment().startOf('day');
    let rw = state.ryanWhiteProgramEnrollments?.find(e => e.programId === programId && moment(e.startDate,'MM/DD/YYYY') <= now && moment(e.endDate,'MM/DD/YYYY') >= now);
    if (rw) {
      return rw;
    }

    let adap = state.adapProgramEnrollments?.find(e => e.programId === programId && moment(e.startDate,'MM/DD/YYYY') <= now && (!e.endDate || moment(e.endDate,'MM/DD/YYYY') >= now));
    if (adap) {
      return adap;
    }

    return null;
  }

  return {
    ...state,
    loadEnrollmentsForClient,
    loadAdapEnrollmentsForClient,
    loadRyanWhiteEnrollmentsForClient,
    upsertCustomProgramEnrollment,
    findEnrollment,
    terminateAdapProgramEnrollment
  };
};
