import { styled } from '@mui/material/styles';
import { NavigationContextProvider } from 'contexts/NavigationContext';

import Header from './Header';
import Grid from '@mui/material/Grid';
import NavBar from './NavBar';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'contexts/AuthProvider';
import { useUserAgency } from 'contexts/UserAgencyContext';

const PREFIX = 'Layout';

const classes = {
  container: `${PREFIX}-container`,
  navContainer: `${PREFIX}-navContainer`,
  detailsContainer: `${PREFIX}-detailsContainer`,
};

const StyledNavigationContextProvider = styled(NavigationContextProvider)(
  () => ({
    [`& .${classes.container}`]: {
      minHeight: 'calc(100vh - 64px)',
    },

    [`& .${classes.navContainer}`]: {
      width: '100%',
    },

    [`& .${classes.detailsContainer}`]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  })
);

export default function Layout({ children }) {
  const [collapsed, setCollapsed] = useState(false);

  const { user } = useAuthState();
  const { userAgency, loadUserAgency } = useUserAgency();
  
  useEffect(() => {
    if (user && !userAgency) {
      loadUserAgency();
    }
  }, [user, userAgency]);

  return (
    <StyledNavigationContextProvider>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Header user={user} />
        </Grid>

        <Grid item xs={12}>
          {!user && <div>{children}</div>}

          {user && userAgency && (
            <Grid container>
              <Grid
                className={classes.navContainer}
                item
                xs={collapsed ? 1 : 4}
                sm={collapsed ? 1 : 3}
                lg={collapsed ? 1 : 2}
              >
                <NavBar
                  userAgency={userAgency}
                  collapsedClicked={() => setCollapsed(!collapsed)}
                  collapsed={collapsed}
                />
              </Grid>

              <Grid
                className={classes.detailsContainer}
                item
                xs={collapsed ? 11 : 8}
                sm={collapsed ? 11 : 9}
                lg={collapsed ? 11 : 10}
              >
                {children}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </StyledNavigationContextProvider>
  );
}
