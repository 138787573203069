import React, { useEffect, useState } from 'react';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useUserRequests } from 'contexts/UserRequestsContext';

export default function AgencyAccessPanel({
  values,
  disableEditAgency,
  setFieldValue,
  imported
}) {
  const [agencies, setAgencies] = useState();
  const [availablePermissionTemplates, setAvailablePermissionTemplates] = useState();
  const [availablePrograms, setAvailablePrograms] = useState();
  const {granteeAgencies, agenciesForGrantee, loadGranteeAgencies, loadAgenciesForGrantee} = useAgencySearch();
  const {agencyProgramMap, loadAgencyPrograms, agencyPermissionTemplateMap, loadAgencyPermissionTemplates} = useUserRequests();
  const {userAgency} = useUserAgency();

  const [agencyChanged, setAgencyChanged] = useState();
  
  useEffect(() => {
    if (userAgency) {
      if (userAgency.isRoot && !granteeAgencies) {
        loadGranteeAgencies();
      } else if (!userAgency.isRoot && userAgency.isGranteeAgency && !agenciesForGrantee) {
        loadAgenciesForGrantee(userAgency.granteeAgencyId);
      } else if (!userAgency.isRoot && !userAgency.isGranteeAgency) {
        setAgencies([{id: userAgency.id, name: userAgency.name}]);
      }
      if (values?.agencyId){
        loadAvailablePermissionTemplatesForAgency(values.agencyId);
        loadAvailableProgramsForAgency(values.agencyId);
        
      }
    }
  }, [userAgency]);

  useEffect(() => {
    if (granteeAgencies) {
      setAgencies(granteeAgencies);
    } else if (agenciesForGrantee) {
      setAgencies(agenciesForGrantee);
    }
  }, [granteeAgencies, agenciesForGrantee]);

  const loadAvailableProgramsForAgency = (agencyId) => {
    if (agencyId) {
      if (!agencyProgramMap || agencyId in agencyProgramMap === false) {
        loadAgencyPrograms(agencyId);
      }
    }
  };

  useEffect(() => {
    if (values.agencyId && agencyProgramMap && values.agencyId in agencyProgramMap) {
      setupPrograms(agencyProgramMap[values.agencyId]);
    }
  }, [agencyProgramMap, values.agencyId]);

  const setupPrograms = (agencyPrograms) => {
    const sortedPrograms = [...agencyPrograms].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setAvailablePrograms(sortedPrograms);
    if (agencyChanged) {
      let selectedPrograms = sortedPrograms.map((p) => {
        return { programId: p.id, access: '' };
      });
      setFieldValue('programPermissions', selectedPrograms);
    } else {
      let selectedPrograms = sortedPrograms.map((p) => {
        let currentProgramPermission = values.programPermissions.find((pp) => pp.programId === p.id);
        return {
          programId: p.id,
          access: currentProgramPermission ? currentProgramPermission.access : '',
        };
      });
      setFieldValue('programPermissions', selectedPrograms);
    }
  };

  const loadAvailablePermissionTemplatesForAgency = (agencyId) => {
    if (agencyId) {
      if (!agencyPermissionTemplateMap || agencyId in agencyPermissionTemplateMap === false) {
        loadAgencyPermissionTemplates(agencyId);
      }
    }
  };

  useEffect(() => {
    if (values.agencyId && agencyPermissionTemplateMap && values.agencyId in agencyPermissionTemplateMap) {
      setupPermissionTemplates(agencyPermissionTemplateMap[values.agencyId]);
    }
  }, [agencyPermissionTemplateMap, values.agencyId]);

  const setupPermissionTemplates = (agencyPermissionTemplates) => {
    const sortedTemplates = [...agencyPermissionTemplates].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setAvailablePermissionTemplates(sortedTemplates);
    if (agencyChanged) {
      setFieldValue('permissionTemplateIds', []);
    }
  };

  const onAgencyChange = (agencyId) => {
    loadAvailablePermissionTemplatesForAgency(agencyId);
    loadAvailableProgramsForAgency(agencyId);
    setAgencyChanged(true);
  };

  // need to trigger load of permission templates and programs for existing user's agency
  useEffect(() => {
    if (disableEditAgency && values.agencyId) {
      loadAvailablePermissionTemplatesForAgency(values.agencyId);
      loadAvailableProgramsForAgency(values.agencyId);
    }
  }, [disableEditAgency, values.agencyId]);

  return (
    <>
      {agencies && (
        <LotusFormItem>
          <LotusSelect
            disabled={disableEditAgency}
            label="Agency Name"
            name="agencyId"
            items={agencies.map(a => { return {label: a.name, value: a.id}})}
            handleChange={onAgencyChange}
            required
          />
        </LotusFormItem>
      )}

      {values.agencyId && availablePermissionTemplates && (
        <LotusFormSection name="Permissions for user at selected agency">
          <LotusCheckboxList
            name="permissionTemplateIds"
            items={availablePermissionTemplates.map(pt => {return {label: pt.name, value: pt.id}})}
          />
        </LotusFormSection>
      )}

      {values.agencyId && availablePrograms && (
        <LotusFormSection name="Programs for user at selected agency">
          {availablePrograms.map((program, index) => {
            return (
              <LotusFormItem key={`program-${program.id}`}>
                <LotusRadioGroup
                  row
                  name={`programPermissions.${index}.access`}
                  label={program.name}
                  items={[
                    { label: 'None', value: '' },
                    { label: 'Read', value: 'read' },
                    { label: 'Edit', value: 'edit' },
                  ]}
                  value={values?.programPermissions[index]?.access}
                />
              </LotusFormItem>
            );
          })}
        </LotusFormSection>
      )}
    </>
  );
}
