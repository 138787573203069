import React from 'react';
import { Typography } from '@mui/material';

export default function AddUserConfirmPanel({ email, newUser = true }) {
  return (
    <>
      <Typography variant="h6">Invite Request Sent</Typography>
        <p>
          <Typography variant="body">
            Invite Requested For <b>{email}</b>{' '}
          </Typography>
        </p>
        <br />
        <p>
          <Typography variant="body">
            <b>Next Steps</b>{' '}
          </Typography>
        </p>
        <p>
          <Typography variant="body">
            You will receive a message when the user {!newUser ? 'reactivation' : ''} request is approved or
            denied.
            <br />
            {newUser ? 'Another message will be sent to you when the new user account(s) are set up.' : '' }
          </Typography>
        </p>        
    </>
  );
}
