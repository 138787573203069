import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import LotusDateOfBirth from 'components/widgets/Forms/LotusDateOfBirth';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusExpandableItemList from 'components/widgets/Forms/LotusExpandableItemList';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useClient } from 'contexts/ClientContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { useFormikContext } from 'formik';
import AddressFieldPanelWithLocationSearch from 'components/widgets/Composites/AddressFieldPanelWithLocationSearch';
import Alert from '@mui/material/Alert';

const validationSchema = Yup.object().shape({});

const buildSectionNames = () => { 
  return [];
}

const ClientPersonalContactsStaticForm = ({existingClientConfig, disabled, updateRestrictions}) => {
 
  const {clientRelationships} = useLists();
  const [clientRelationshipList, setClientRelationshipList] = useState();

  const { values } = useFormikContext();

  useEffect(() => {
    if (clientRelationships) {
      setClientRelationshipList(clientRelationships.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
  }, [clientRelationships]);

  const emptyPersonalContact = {
    firstName: '',
    lastName: '',
    birthDate: null,
    clientRelationshipId: '',
    phoneNumber: '',
    emergencyContact: '',
    emailAddress: '',
    addressSameAsClient: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      county: '',
      zip: ''
    },
    legalGuardian: '',
    caregiverOfClient: '',
    nextOfKin: '',
    powerOfAttorney: '',
    awareOfHivStatus: ''
  }

  const buildDetailsPanel = (personalContact, index, isDisabled) => {

    const relationshipList = clientRelationshipList.filter(
      t => t.value === personalContact.clientRelationshipId || (existingClientConfig.selectedClientRelationships && existingClientConfig.selectedClientRelationships.includes(t.value)));

    return (
      <LotusForm key={index}>
        <LotusFormItems>
          <LotusTextInput
            name={`contactList.${index}.firstName`}
            label="First Name"
            required
            disabled={isDisabled}
            maxLength={35}
          />
          <LotusTextInput
            name={`contactList.${index}.lastName`}
            label="Last Name"
            required
            disabled={isDisabled}
            maxLength={35}
          />
          {clientRelationshipList &&
            <LotusSelect
              name={`contactList.${index}.clientRelationshipId`}
              label="Relationship to Client"
              items={relationshipList}
              disabled={isDisabled}
              autoSort={false}
              required
            />
          }
          <LotusDateOfBirth
            name={`contactList.${index}.birthDate`}
            label="Date of Birth"
            disabled={isDisabled}
          />
          <LotusPhoneInput
            name={`contactList.${index}.phoneNumber`}
            label='Phone Number' 
            disabled={isDisabled}
          />
          <LotusTrueFalseRadioGroup
            name={`contactList.${index}.emergencyContact`}
            label="Is this person an emergency contact?"
            disabled={isDisabled}
          />
          <LotusTextInput
            name={`contactList.${index}.emailAddress`}
            label="Email Address"
            disabled={isDisabled}
            maxLength={254}
          />
          <LotusTrueFalseSelect
            name={`contactList.${index}.addressSameAsClient`}
            label="Address same as Client's Address?"
            disabled={isDisabled}
            />
          {personalContact.addressSameAsClient === false &&
            <AddressFieldPanelWithLocationSearch
              name={`contactList.${index}.address`}
              disabled={isDisabled}
            />
          }
          {existingClientConfig.showLegalGuardian &&
            <LotusTrueFalseRadioGroup
              name={`contactList.${index}.legalGuardian`}
              label="Legal Guardian?"
              disabled={isDisabled}
            />
          }
          {existingClientConfig.showCaregiverOfClient &&
            <LotusTrueFalseRadioGroup
              name={`contactList.${index}.caregiverOfClient`}
              label="Caregiver of Client?"
              disabled={isDisabled}
            />
          }
          {existingClientConfig.showNextOfKin &&
            <LotusTrueFalseSelect
              name={`contactList.${index}.nextOfKin`}
              label="Next of Kin?"
              disabled={isDisabled}
              />
          }
          {existingClientConfig.showPowerOfAttorney &&
            <LotusTrueFalseSelect
              name={`contactList.${index}.powerOfAttorney`} 
              label="Power of Attorney?"
              disabled={isDisabled}
              />
          }
          {existingClientConfig.showAwareOfHivStatus &&
            <LotusTrueFalseRadioGroup
              name={`contactList.${index}.awareOfHivStatus`}
              label="Is this person aware of client's HIV status?"
              disabled={isDisabled}
            />
          }
        </LotusFormItems>
      </LotusForm>
    );
  }

  return values && existingClientConfig && clientRelationshipList && (
    <>
    {updateRestrictions && (updateRestrictions.all || updateRestrictions.personalContacts) &&
    <LotusFormSection>
      <LotusFormItem>
        <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
      </LotusFormItem>
    </LotusFormSection>
    }
    <LotusExpandableItemList
      typ={disabled ? 'disabled' : 'editable'}
      disabled={disabled} 
      name="contactList" 
      itemName="Contact"
      buildItemSectionName={(item) => `${item.clientRelationshipId ? ((clientRelationships && clientRelationships.find(r => r.id === item.clientRelationshipId)?.itemName) || '[Relationship]') : '[Relationship]'}`}
      buildItemTitle={(item) => {
        return `${item.firstName || '[First Name]'} ${item.lastName || '[Last Name]'} - ${item.clientRelationshipId ? ((clientRelationships && clientRelationships.find(r => r.id === item.clientRelationshipId)?.itemName) || '[Relationship]') : '[Relationship]'}`}}
      buildItemSummary={(item, index) => {
        return (
          <Grid container spacing={1}>
            {item.emergencyContact && 
              <Grid item xs={12} style={{color: "#3395FF", paddingBottom: 5}}>Emergency Contact</Grid>
            }
            <Grid item xs={6} style={{paddingRight: 5}}>
              <LotusTextInput name={`contactList.${index}.emailAddress`} label='Email Address' disabled={true}/>
            </Grid>
            <Grid item xs={6} style={{paddingLeft: 5}}>
              <LotusPhoneInput name={`contactList.${index}.phoneNumber`} label='Phone Number' disabled={true}/>
            </Grid>
          </Grid>);
      }}
      buildItemDetail={(item, index, isDisabled) => buildDetailsPanel(item, index, isDisabled)}
      buildItemTemplate={() => {return {...emptyPersonalContact}}}
    />
    </>
  );
};

export function buildStaticForm({...props}) {
  return <ClientPersonalContactsStaticForm {...props}/>
}

export default function ClientPersonalContactsForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions}) {
 
  const { clientPersonalContacts, loadClientPersonalContacts, upsertClientPersonalContacts } = useClient();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (client) {
      loadClientPersonalContacts(client.id);
    }
  }, [client]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      contactList,
      ...dynamicFormData} = formData;

    const personalContactInfo = {
      contactList
    };
    return [personalContactInfo, dynamicFormData];
  }

  useEffect(() => {
    if (clientPersonalContacts) {
      const lst = clientPersonalContacts.contactList.map(c => { 
        return {
          id: c.id,
          firstName: c.firstName || '',
          lastName: c.lastName || '',
          birthDate: c.birthDate || null,
          clientRelationshipId:  c.clientRelationshipId || '',
          relationshipTypeName: c.relationshipTypeName || '',
          phoneNumber: c.phoneNumber || '',
          emergencyContact: c.emergencyContact !== null ? c.emergencyContact : false, 
          emailAddress: c.emailAddress || '',
          addressSameAsClient: c.addressSameAsClient !== null ? c.addressSameAsClient : '', 
          address: {
            address1: c.address.address1 || '',
            address2: c.address.address2 || '',
            city: c.address.city || '',
            state: c.address.state || '',
            county: c.address.county || '',
            zip: c.address.zip || '',
          },
          legalGuardian: c.legalGuardian !== null ? c.legalGuardian : false,
          caregiverOfClient: c.caregiverOfClient !== null ? c.caregiverOfClient : false,
          nextOfKin: c.nextOfKin !== null ? c.nextOfKin : '',
          powerOfAttorney: c.powerOfAttorney !== null ? c.powerOfAttorney : '',
          awareOfHivStatus: c.awareOfHivStatus !== null ? c.awareOfHivStatus : false,
        }
      });

      // Sort by emergency contact, then relationship name
      lst.sort((a,b) => {
        if (a.emergencyContact && !b.emergencyContact) {
          return -1;
        }
        if (b.emergencyContact && !a.emergencyContact) {
          return 1;
        }
        return a.relationshipTypeName < b.relationshipTypeName ? -1 : 1;
      });

      setInitialValues( {
        contactList: lst
      });
    }
  }, [clientPersonalContacts]);

  const saveForm = async (values) => {
    await upsertClientPersonalContacts(client.id, values);
  }

  return initialValues && (
    <HybridForm 
      title="Personal Contacts"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled || updateRestrictions?.all || updateRestrictions?.personalContacts, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.personalContacts}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}