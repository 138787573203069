import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramCareManagementOptions({handleSubmit}) {

  const { currentProgram } = useProgramManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (currentProgram) {
      let defaultValues = {
        assessments: false,
        carePlan: false,
        referrals: false,
      };
      setInitialValues({ ...defaultValues, ...currentProgram.careManagementOptions });
    }
  }, [currentProgram]);

  return (
    <LotusPageSection header="Care Management Options">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="assessments"
                    value={values.assessments}
                    label="Assessments Enabled"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="carePlan"
                    value={values.carePlan}
                    label="Care Plan Enabled"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="referrals"
                    value={values.referrals}
                    label="Referrals Enabled"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
