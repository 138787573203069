import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '@lotus/components';
import { formatUsDateTime } from 'lib/formatting';
import { useFormDefinitions } from 'contexts/DynamicFormContext';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function FormsList() {
  const { userAgency } = useUserAgency();
  const { formDefinitions, loadAllAgencyFormDefinitions } = useFormDefinitions();
  const [formDefinitionList, setFormDefinitionList] = useState();

  useEffect(() => {
    if (!formDefinitions) {
        loadAllAgencyFormDefinitions(userAgency.granteeAgencyId);
    }
  }, [formDefinitions]);

  useEffect(() => {
    if (formDefinitions) {
      const defs = formDefinitions.map(def => {
        return {
          ...def,
          subtypeToDisplay: def.formSubtypeName || def.formSubtypeCustomName || ''
        }
      });
      defs.sort((a, b) => a.formTypeName < b.formTypeName ? -1 :
        (a.formTypeName > b.formTypeName ? 1 : (a.formSubtypeName < b.formSubtypeName ? -1 : 1)));
      setFormDefinitionList(defs);
    }
  }, [formDefinitions]);

  const columns = [
    {
      name: 'formId',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Form Type',
      name: 'formTypeName',
      options: {
        customBodyRender: (value, tableMeta) => {
          const formId = tableMeta.rowData[0];
          return <Link to={`/forms/edit/${formId}`}>{value}</Link>;
        },
      },
    },
    {
      label: 'Form Subtype',
      name: 'subtypeToDisplay',
    },
    {
      label: 'Program',
      name: 'programName',
    },
    {
      label: 'Assessment Type',
      name: 'assessmentTypeName',
    },
    {
      label: 'Active Date',
      name: 'dateActive',
      options: {
        customBodyRender: (value) => {
          return <div>{formatUsDateTime(value)}</div>;
        },
        filter: false,
      },
    },
  ];

  return (
      formDefinitionList && (
            <Table
              columns={columns}
              data={formDefinitionList}
              totalRows={formDefinitionList.length}
              options={{
                selectableRows: 'none',
                selectableRowsHideCheckboxes: false,
                selectableRowsHeader: false,
              }}
            />
      )
  );
}
