export const messageTypes = {
  REQUEST_CREATION_SUCCESS: 'REQUEST_CREATION_SUCCESS',
  APPROVING_USER_REQUEST_SUCCESS: 'APPROVING_USER_REQUEST_SUCCESS',
  DENYING_USER_REQUEST_SUCCESS: 'DENYING_USER_REQUEST_SUCCESS',
  LOADING_PENDING_REQUESTS_SUCCESS: 'LOADING_PENDING_REQUESTS_SUCCESS',
  LOADING_RECENT_APPROVALS_SUCCESS: 'LOADING_RECENT_APPROVALS_SUCCESS',
  LOADING_AGENCY_PROGRAMS_SUCCESS: 'LOADING_AGENCY_PROGRAMS_SUCCESS',
  LOADING_AGENCY_PERMISSION_TEMPLATES_SUCCESS: 'LOADING_AGENCY_PERMISSION_TEMPLATES_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.REQUEST_CREATION_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [payload.request, ...state.pendingRequests] : undefined;
        return {
          ...state,
          pendingRequests: newRequests
        };
      }
    case messageTypes.APPROVING_USER_REQUEST_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [...state.pendingRequests.filter(r => r.id !== payload.approval.id)] : undefined;
        const newApprovals = state.recentApprovals ? [payload.approval, ...state.recentApprovals] : undefined;
        return {
          ...state,
          pendingRequests: newRequests,
          recentApprovals: newApprovals
        };
      }
    case messageTypes.DENYING_USER_REQUEST_SUCCESS:
      {
        const newRequests = state.pendingRequests ? [...state.pendingRequests.filter(r => r.id !== payload.denial.id)] : undefined;
        const newApprovals = state.recentApprovals ? [payload.denial, ...state.recentApprovals] : undefined;
        return {
          ...state,
          pendingRequests: newRequests,
          recentApprovals: newApprovals
        };
      }
    case messageTypes.LOADING_PENDING_REQUESTS_SUCCESS:
    case messageTypes.LOADING_RECENT_APPROVALS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS:
      let newAgencyProgramMap = {};
      Object.entries(state.agencyProgramMap || {}).forEach(([k, v]) => {
        newAgencyProgramMap[k] = v;
      });
      newAgencyProgramMap[payload.agencyId] = payload.programs;
      return {
        ...state,
        agencyProgramMap: newAgencyProgramMap,
      };
    case messageTypes.LOADING_AGENCY_PERMISSION_TEMPLATES_SUCCESS:
      let newAgencyPermissionTemplateMap = {};
      Object.entries(state.agencyPermissionTemplateMap || {}).forEach(([k, v]) => {
        newAgencyPermissionTemplateMap[k] = v;
      });
      newAgencyPermissionTemplateMap[payload.agencyId] = payload.templates;
      return {
        ...state,
        agencyPermissionTemplateMap: newAgencyPermissionTemplateMap,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
