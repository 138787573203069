
import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useEffect, useState } from "react";
import GranteeAgencyIntegrationsView from "./GranteeAgencyIntegrationsView";
import { useAgencyIntegrations } from "contexts/AgencyIntegrationsContext";

export default function GranteeAgencyIntegrations() {

  const { 
    integrations, loadAgencyIntegrations, createAgencyIntegration, updateAgencyIntegration,
    connectors, loadAgencyConnectors, 
    loadAgencyIntegrationDetailsSync } = useAgencyIntegrations();
  const { agency } = useAgencyManagement();

  const [loadedAgencyIntegrations, setLoadedAgencyIntegrations] = useState([]);

  useEffect(() => {
    if (agency && !integrations) {
      loadAgencyIntegrations(agency.id);
    }
    if (agency && !connectors) {
      loadAgencyConnectors(agency.id);
    }
  }, [agency]);

  useEffect(() => {
    if (integrations) {
      const lst = [...integrations];
      lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyIntegrations(lst);
    }
  }, [integrations]);

  const handleUpsertIntegration = async (values) => {
    if (values.arn) {
      await updateAgencyIntegration(agency.id, values);
    } else {
      await createAgencyIntegration(agency.id, values, loadedAgencyIntegrations.length > 0);
    }
  };

  const integrationDetailLoader = async (name) => {
    return await loadAgencyIntegrationDetailsSync(agency.id, name);
  }

  return (
    agency && loadedAgencyIntegrations && connectors && (
      <GranteeAgencyIntegrationsView
        integrations={loadedAgencyIntegrations}
        agencyConnectors={connectors}
        integrationDetailLoaderFunc={integrationDetailLoader}
        handleSave={handleUpsertIntegration}
        handleCancel={() => {}} // staying on page, just reverting changes
      />
    )
  );
}
