const { useUserAgency } = require("contexts/UserAgencyContext");
const { useClientForms } = require("contexts/ClientFormContext");
const { useState, useEffect } = require("react");

export function useClientProgramSubforms(clientId, formTypeKey, programId) {
  const { userAgency } = useUserAgency();
  const {clientForms, loadClientForms, buildClientFormKey} = useClientForms();
  const [clientSubformData, setClientSubformData] = useState();
  const [currProgramId, setCurrProgramId] = useState();

  useEffect(() => {
    if (userAgency && currProgramId && clientId) {
      const key = buildClientFormKey(clientId, formTypeKey, currProgramId);
      if (!clientForms || key in clientForms === false) {
        loadClientForms({clientId: clientId, formTypeKey: formTypeKey, programId: currProgramId});
      }
    }
  }, [userAgency, currProgramId, clientId]);

  useEffect(() => {
    if (userAgency && currProgramId && clientId) {
      const key = buildClientFormKey(clientId, formTypeKey, currProgramId);
      if (clientForms && key in clientForms) {
        setClientSubformData(clientForms[key]);
      }
    }
  }, [userAgency, currProgramId, clientId, clientForms]);

  if (currProgramId !== programId) {
    setClientSubformData(undefined);
    setCurrProgramId(programId);
  }

  return clientSubformData;
}
