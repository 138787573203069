import React, { useState } from 'react';
import { Table } from '@lotus/components';
import { useField } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import ActionDialog from 'components/Dialogs/ActionDialog';
import ClientDispensesViewer from './ClientDispensesViewer';
import moment from 'moment';

export default function ClientDispensesList({ dispensesConfig, dispenses }) {

    const [dispensesField] = useField(dispenses);
    const [dispensesView, setDispensesView] = useState();
    const [rowId, setRowId] = useState();

    const closeDispensesViewer = () => {
        setDispensesView(null);
        setRowId(null);
    };

    const handleRowClicked = (rowData) => {
        const rowId = rowData[0];
        setRowId(rowId);
        setDispensesView(true);
    };

    const getDispensesById = (rowId) => {
        return dispensesField && dispensesField.value ? dispensesField.value.filter(result => result.id === rowId)[0] : null;
    }

    // Standard fields
    let columns = [
        {
            name: 'id',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'clientId',
            options: {
                display: 'excluded'
            }
        },
        {
            label: 'Dispense Date',
            name: 'sortable_dispense_date',
            options: {
                customBodyRender: (value) => {
                    return <div>{value ? moment(value).format("MM/DD/YYYY") : 'N/A'}</div>;
                }
            }
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Drug Name',
            name: 'drugName',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Generic Drug Name',
            name: 'genericName',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Quantity Dispensed',
            name: 'quantityDispensed',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Days of Supply',
            name: 'daysSupply',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Prescriber NPI',
            name: 'prescriberNpi',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Prescriber First Name',
            name: 'prescriberFirstName',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Prescriber Last Name',
            name: 'prescriberLastName',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Pharmacy NPI',
            name: 'pharmacyNpi',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'NDC',
            name: 'ndc',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Manufacturer',
            name: 'manufacturer',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Payment Type',
            name: 'paymentType',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Drug Cost Paid',
            name: 'drugCostPaid',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Dispensing Fee Paid',
            name: 'dispensingFeePaid',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Admin Fee Paid',
            name: 'adminFeePaid',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Co-Pay Amount',
            name: 'copayAmount',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        }
    ];

    if(dispensesConfig && dispensesConfig?.enabled) {
        const configuredFields = [
            {
                label: 'PBM Client ID',
                name: 'pbmClientId',
                options: {
                    sort: false,
                    display: dispensesConfig.systemCategory?.pbmClientId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Reversal',
                name: 'reversal',
                options: {
                    sort: false,
                    display: dispensesConfig.typeCategory?.reversal ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? 'true' : (value === null ? 'N/A' : 'false')
                    }
                }
            },
            {
                label: 'Reversal Type',
                name: 'reversalType',
                options: {
                    sort: false,
                    display: dispensesConfig.typeCategory?.reversalType ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Prescriber Phone Number',
                name: 'prescriberPhoneNumber',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriberCategory?.prescriberPhoneNumber ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy Name',
                name: 'pharmacyName',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyName ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy No',
                name: 'pharmacyNo',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyNo ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy Address1',
                name: 'pharmacyAddress1',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyAddress1 ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy Address2',
                name: 'pharmacyAddress2',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyAddress2 ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy City',
                name: 'pharmacyCity',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyCity ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy State',
                name: 'pharmacyState',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyState ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy Zip Code',
                name: 'pharmacyZipCode',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyZipCode ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Pharmacy Phone',
                name: 'pharmacyPhone',
                options: {
                    sort: false,
                    display: dispensesConfig.pharmacyCategory?.pharmacyPhone ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Ajudication Date',
                name: 'ajudicationDate',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.ajudicationDate ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Cycle End Date',
                name: 'cycleEndDate',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.cycleEndDate ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Rx Number',
                name: 'rxNumber',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.rxNumber ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Rx Number Qualifier',
                name: 'rxNumberQual',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.rxNumberQual ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Fill Number',
                name: 'fillNumber',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.fillNumber ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Fill Date',
                name: 'fillDate',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.fillDate ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Date Prescription Written',
                name: 'datePrescriptionWritten',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.datePrescriptionWritten ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Dispense As Written Code',
                name: 'dispenseAsWrittenCode',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.dispenseAsWrittenCode ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Number Refills Authorized',
                name: 'numberRefillsAuthorized',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.numberRefillsAuthorized ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Unit Of Measure',
                name: 'unitOfMeasure',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.unitOfMeasure ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Original Quantity',
                name: 'originalQuantity',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.originalQuantity ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Original Day Supply',
                name: 'originalDaySupply',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.originalDaySupply ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'DAW Code',
                name: 'dawCode',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.dawCode ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Product Strength',
                name: 'productStrength',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.productStrength ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Dosage Form Code',
                name: 'dosageFormCode',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.dosageFormCode ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Drug Type',
                name: 'drugType',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.drugType ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Drug Category Code',
                name: 'drugCategoryCode',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.drugCategoryCode ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Drug Tier',
                name: 'drugTier',
                options: {
                    sort: false,
                    display: dispensesConfig.prescriptionCategory?.drugTier ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Claim ID',
                name: 'claimId',
                options: {
                    sort: false,
                    display: dispensesConfig.paymentCategory?.claimId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Group ID',
                name: 'groupId',
                options: {
                    sort: false,
                    display: dispensesConfig.paymentCategory?.groupId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Carrier Number',
                name: 'carrierNumber',
                options: {
                    sort: false,
                    display: dispensesConfig.paymentCategory?.carrierNumber ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Amount Sales Tax',
                name: 'amountSalesTax',
                options: {
                    sort: false,
                    display: dispensesConfig.paymentCategory?.amountSalesTax ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Patient Pay Amount',
                name: 'patientPayAmount',
                options: {
                    sort: false,
                    display: dispensesConfig.paymentCategory?.patientPayAmount ? false : 'excluded',    
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            }
        ];
        columns = columns.concat(configuredFields);
    }

    return (
    <>
        <LotusFormItem extraWide>
            <Table
            columns={columns}
            data={dispensesField.value}
            options={{
                pagination: true,
                filter: false,
                search: false,
                searchOpen: false,
                download: false,
                print: false,
                viewColumns: true,
                searchAlwaysOpen: false,
                rowsPerPageOptions: [10, 20, 50],
                textLabels: {
                    body: {
                      noMatch: "Sorry, no medication dispenses found for this client."
                    }
                }
            }}
            handleRowClicked={handleRowClicked}
            />
        </LotusFormItem>

        <ActionDialog
            maxWidth="sm"
            open={Boolean(dispensesView)}
            handleClose={closeDispensesViewer}
            scroll="body"
            content={
                <ClientDispensesViewer
                    dispensesObj={getDispensesById(rowId)}
                    dispensesConfig={dispensesConfig}
              />
            }
        />
    </>
    );
}