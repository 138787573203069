import React, { useEffect, useState } from 'react';
import { Table, FilterChecklistPanel } from '@lotus/components';
import { formatUsDateTime } from 'lib/formatting';
import { useUserSearch } from 'contexts/UserSearchContext';
import { useLists } from 'contexts/ListsContext';
import { useNavigate } from 'react-router-dom';
import { PERMISSIONS } from 'lib/permissionEnums'
import { useAuthState } from 'contexts/AuthProvider';

  
export default function UserList() {
  
  const { users, totalUsers, loadManageableUsers } = useUserSearch();

  const { accountStatuses, loadAccountStatuses } = useLists();
  const navigate = useNavigate();
  const { verifyPermission } = useAuthState();
  const hasRequestUserPermission = verifyPermission(PERMISSIONS.REQUEST_NEW_ACCOUNT);

  const [currentTableData, setCurrentTableData] = useState();
  const defaultSortOrder = { name: 'last_name', direction: 'asc' }

  const handleDataChange = async ({
    pageNumber,
    rowsPerPage,
    searchText,
    sortOrder,
    filters
  }) => {
    setCurrentTableData({
      searchText,
      sortOrder,
      filters,
    });

    const newPageNumber = currentTableData?.searchText === searchText ? pageNumber : 0;

    await loadManageableUsers({
      offset: newPageNumber * rowsPerPage,
      limit: rowsPerPage,
      searchText,
      sortOrder,
      filters
    });
  };

  useEffect(() => {
    if (!accountStatuses) {
      loadAccountStatuses();
    }
  }, [accountStatuses]);

  const handleApplyFilter = (values, filterList, index, onChange, column) => {
    const selected = Object.keys(values).filter((key) => {
      return values[key] === true;
    });

    filterList[index] = selected;

    onChange(filterList[index], index, column);
  };

  const handleRowClicked = (rowData) => {
    const id = rowData[0];
    if (rowData[6] === 'Imported' && hasRequestUserPermission) {
      navigate(`/users/addimporteduser/${id}`);
    } else {
      navigate(`/users/edit/${id}`);
    }
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        download: false
      },
    },
    {
      name: 'request',
      options: {
        display: 'excluded',
        filter: false,
        download: false
      },
    },
    {
      label: 'Last Name',
      name: 'last_name',
      options: {
        filter: false
      },
    },
    {
      label: 'First Name',
      name: 'first_name',
      options: {
        filter: false
      },
    },
    {
      label: 'Email',
      name: 'email',
      options: {
        filter: false
      },
    },
    {
      label: 'Agency',
      name: 'agency_name',
      options: {
        filter: false
      },
    },
    {
      label: 'Account Status',
      name: 'account_status_name',
      options: {
        filterType: 'custom',
        filterOptions: {
          fullWidth: true,
          logic: (location, filters, row) => {
            return false;
          },
          display: (filterList, onChange, index, column) => {
            return (
              <FilterChecklistPanel
                headerText="Account Status"
                items={accountStatuses.map(status => status.name)}
                selectedItems={filterList[index]}
                handleApply={(values) =>
                  handleApplyFilter(values, filterList, index, onChange, column)
                }
              />
            );
          },
        },
      },
    },
    {
      label: 'Last Login',
      name: 'last_login_date',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (value && formatUsDateTime(value)) || '';
        },
      },
    },
    {
      name: 'account_status_key',
      options: {
        display: 'excluded',
        filter: false,
        download: false
      },
    },
  ];

  return (
    <Table
      columns={columns}
      data={users}
      totalRows={users && users.length > 0 ? totalUsers : null}
      handleDataChange={handleDataChange}
      defaultSortOrder={defaultSortOrder}
      handleRowClicked={handleRowClicked}
      options={{
        serverSide: true,
        selectableRows: 'none',
        selectableRowsHideCheckboxes: false,
        selectableRowsHeader: false,
        customSearch: undefined, 
      }}
    />
  );
}
