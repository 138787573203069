import { useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { useAppStatus } from 'contexts/AppStatusContext';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Button } from "@lotus/components";
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from "lib/documents";

export default function DocumentationUploader ({
  documentationTypes,
  clientId,
  handleCancel,
  handleSave,
}) {
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [ thumbnails, setThumbnails ] = useState([]);
  const [ initialValues, setInitialValues ] = useState();
  

  useEffect(() => {
    setInitialValues({
      documentationTypeId: '',
      documentationFiles: [],
    });
  }, []);

  return (
  <Grid container>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={async (values) => {
        let result = {};
        if (values.documentationFiles.length === 0) {
          result.documentFiles = 'Program document file(s) are required';
        }
        return result;
      }}
      onSubmit={async (newValues, actions) => {
        await handleSave({
          documentationTypeId: newValues.documentationTypeId, 
          documentationFiles: newValues.documentationFiles, 
        });
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldTouched, handleSubmit, setFieldValue }) => {
        return values && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                <Grid item xs={6}>
                  <Typography variant="h2">Upload Documentation</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent='flex-end'>
                  <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                    <Typography variant="h6">Cancel</Typography>
                  </Button>
                  <Button onClick={() => {handleSubmit(values);}}>
                    <Typography variant="h6">Upload</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{paddingBottom: 24}}>
                <LotusSelect
                  label="Documentation Type"
                  items={documentationTypes.map((dt) => {return { label: dt.name, value: dt.id };})}
                  name="documentationTypeId"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <LotusFileUploader
                  name="clientDocumenationtUploader"
                  clientId={clientId}
                  subfolderName="documents"
                  maxFiles={99}
                  onFileAdded={async (newFile, base64Str) => {
                    try {
                      addBusyBee('generatingThumbnail');
                      const newFileThumbnails = await generateThumbnailsForUrl(base64Str, newFile.type, [100]);

                      const addThumbnails = async ({thumbnails, newFileThumbnails}) => {
                        thumbnails.push(newFileThumbnails);

                        values.documentationFiles.push(newFile);

                        setFieldTouched('documentFiles');
                        setFieldTouched('documentFilesError');
                        setFieldValue('documentFilesError', '');
                      };

                      await addThumbnails({
                        thumbnails,
                        newFileThumbnails
                      });
                    }
                    catch (err) {
                      console.log(err);
                      setFieldTouched('documentFiles');
                      setFieldTouched('documentFilesError');
                      await setFieldValue('documentFiles', []);
                      setFieldValue('documentFilesError', 'An error occurred while generating thumbnail images for file');
                    } finally {
                      removeBusyBee('generatingThumbnail');
                    }
                  }}
                  onFileDeleted={(newFile) => {
                    console.log('Deleted', newFile);
                    let newFiles = [...values.documentationFiles];
                    const removeIndex = values.documentationFiles.findIndex((file) => file.displayName === newFile.displayName);
                    if (removeIndex >= 0) {
                      values.documentationFiles.splice(removeIndex, 1);
                      thumbnails.splice(removeIndex, 1);
                    }

                    setFieldTouched('documentFiles');
                    setFieldValue('documentFiles', newFiles);
                  }}
                  onError={(err, action, msg) => {
                    console.log(err, msg);
                    setFieldTouched('documentFiles');
                    setFieldTouched('documentFilesError');
                    const errMsg = msg || 'An error occurred while processing the document';
                    if (action !== 'preview') {
                      setFieldValue('documentFiles', {});
                    }
                    setFieldValue('documentFilesError', errMsg);
                  }}
                />
                <div style={{color:'#b53f3f', marginTop: 10}}>
                  <ErrorMessage name="documentFiles" />
                </div>
                <div style={{color:'#b53f3f', marginTop: 10}}>
                  <ErrorMessage name="documentFilesError" />
                </div>
              </Grid>
            </Grid>
            <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
              {thumbnails && thumbnails.map((t,idx) => {
                return (<img key={idx} alt='Thumbnail of documents' src={t} style={{maxWidth: 100, maxHeight: 100, marginRight: 10}}/>);
              })}
            </div>
          </Grid>
        );
      }}
    </Formik>
  </Grid>);
}