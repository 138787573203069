import React from 'react';
import { useField } from 'formik';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { Stack } from '@mui/material';
const { v4: uuidv4 } = require('uuid');

export default function GenericListLinker({name, title, itemName}) {
  
  const [field, meta, utils] = useField(name);

  const addListItem = () => {
    const lst = [...field.value];
    lst.push({id: uuidv4(), name: '', active: true});
    utils.setValue(lst);
  };

  return (
    <>
    <LotusFormItem>
      <div>{title}</div>
    </LotusFormItem>
    {field.value.length > 0 && field.value.map((itm, index) => {
        return (
          <LotusFormItem key={index}>
            <Stack direction="row" spacing={1}>
              <LotusCheckbox
                name={`${name}.${index}.active`}
                checked={itm.active}
              />
              <LotusTextInput
                name={`${name}.${index}.name`}
                required
                disabled={itm.readOnly ? itm.readOnly : false}
                style={{width: 500}}
              />
            </Stack>
          </LotusFormItem>
        );
      })
    }
    <LotusFormItem>
      <LotusButton plusIcon onClick={addListItem} style={{ marginTop: 5}}>
        Add {itemName}
      </LotusButton>
    </LotusFormItem>
    </>
  );
}
