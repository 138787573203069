import { Stack } from "@mui/material";
import LotusTabs from "components/navigation/LotusTabs";
import { AgencyManagementProvider, useAgencyManagement } from "contexts/AgencyManagementContext";
import { useAuthState } from "contexts/AuthProvider";
import { PERMISSIONS } from "lib/permissionEnums";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GranteeAgencyDetails from "./grantee/details/GranteeAgencyDetails";
import SubagencyDetails from "./subagency/SubagencyDetails";
import { AgencyIntegrationProvider } from "contexts/AgencyIntegrationsContext";
import GranteeAgencyCareManagement from "./grantee/care/GranteeAgencyCareManagement";
import GranteeAgencyClientInfo from "./grantee/clientinfo/GranteeAgencyClientInfo";
import GranteeAgencyEligibility from "./grantee/eligibility/GranteeAgencyEligibility";
import GranteeAgencyDashboards from "./grantee/dashboards/GranteeAgencyDashboards";
import GranteeAgencyConsentTypes from "./grantee/consents/GranteeAgencyConsentTypes";
import GranteeAgencyIntegrations from "./grantee/integrations/GranteeAgencyIntegrations";
import GranteeAgencyTestResults from "./grantee/testresults/GranteeAgencyTestResults";
import GranteeAgencyPremiumsPaid from "./grantee/premiums/GranteeAgencyPremiumsPaid";
import GranteeAgencyMedicationDispenses from "./grantee/dispenses/GranteeAgencyMedicationDIspenses";
import LotusPageHeader from "components/headers/LotusPageHeader";
import LotusPageContent from "components/dataDisplay/content/LotusPageContent";
import LotusButtonList from "components/button/LotusButtonList";
import LotusSecondaryActionButton from "components/button/LotusSecondaryActionButton";
import { ArrowBack } from "@mui/icons-material";

function EditAgencyImpl() {
  const [tabs, setTabs] = useState();
  const { agency, loadAgency } = useAgencyManagement();
  const navigate = useNavigate();
  const { id: agencyId, section } = useParams();
  const { verifyPermission } = useAuthState();
  const hasEditIntegrations = verifyPermission(PERMISSIONS.AGENCY_EDIT_INTEGRATIONS);

  useEffect(() => {
    if (agencyId && !agency) {
      loadAgency(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    const tabsForAgency = [];
    if (agency && agency.isGranteeAgency) {
      tabsForAgency.push(
        { label: 'Agency Details', link: `/agencies/edit/${agencyId}/details`, cleanName: 'details' },
        { label: 'Care Management', link: `/agencies/edit/${agencyId}/care`, cleanName: 'care' },
        { label: 'Client Info', link: `/agencies/edit/${agencyId}/clientinfo`, cleanName: 'clientinfo' },
        { label: 'Consents', link: `/agencies/edit/${agencyId}/consents`, cleanName: 'consents' },
        { label: 'Dashboards', link: `/agencies/edit/${agencyId}/dashboards`, cleanName: 'dashboards' },
        { label: 'Eligibility', link: `/agencies/edit/${agencyId}/eligibility`, cleanName: 'eligibility' },
      );
      if (agency.isGranteeAgency && hasEditIntegrations) {
        tabsForAgency.push({ label: 'Integrations', link: `/agencies/edit/${agencyId}/integrations`, cleanName: 'integrations' });
      }
      tabsForAgency.push(
        { label: 'Medication Dispenses', link: `/agencies/edit/${agencyId}/dispenses`, cleanName: 'dispenses' },
        { label: 'Premiums Paid', link: `/agencies/edit/${agencyId}/premiums`, cleanName: 'premiums' },
        { label: 'Test Results', link: `/agencies/edit/${agencyId}/testresults`, cleanName: 'testresults' },
      );
      setTabs(tabsForAgency);
    }
  }, [agency]);

  return agency && (
    <>
      <LotusPageHeader
        title={agency.name}
        actionButtonList={
          <LotusButtonList>
            <LotusSecondaryActionButton onClick={() => navigate('/agencies/list')} startIcon={<ArrowBack/>}>
              All Agencies
            </LotusSecondaryActionButton>
          </LotusButtonList>
        }
      />
      <LotusPageContent>
        {agency.isGranteeAgency && tabs && (
          <Stack direction="column" spacing={2}>
            <LotusTabs
              selectedTabName={section}
              tabs={tabs}
              tabPropertyToMatch="cleanName"
              isScrollable
            />
            {section === "details" && <GranteeAgencyDetails />}
            {section === "care" && <GranteeAgencyCareManagement />}
            {section === 'clientinfo' && <GranteeAgencyClientInfo />}
            {section === 'consents' && <GranteeAgencyConsentTypes />}
            {section === 'dashboards' && <GranteeAgencyDashboards />}
            {section === 'eligibility' && <GranteeAgencyEligibility />}
            {section === 'integrations' && <GranteeAgencyIntegrations />}
            {section === 'premiums' && <GranteeAgencyPremiumsPaid />}
            {section === 'testresults' && <GranteeAgencyTestResults />}
            {section === 'dispenses' && <GranteeAgencyMedicationDispenses />}
          </Stack>
        )}
        {!agency.isGranteeAgency &&
          <SubagencyDetails />
        }
      </LotusPageContent>
    </>
  );
}

export default function EditAgency(...props) {
  return (
    <AgencyManagementProvider>
      <AgencyIntegrationProvider>
        <EditAgencyImpl {...props}/>
      </AgencyIntegrationProvider>
    </AgencyManagementProvider>
  );
}