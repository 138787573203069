import React from 'react';
import Typography from '@mui/material/Typography';

export default function UpdateAccountConfirmPanel({ email }) {
  return (
    <>
        <Typography variant="h6">Update User Access Request Sent</Typography>
        <p>
          <Typography variant="body">
            User Access Requested For <b>{email}</b>{' '}
          </Typography>
        </p>
        <br />
        <p>
          <Typography variant="body">
            <b>Next Steps</b>{' '}
          </Typography>
        </p>
        <p>
          <Typography variant="body">
            You will receive a message when the Update User Access request is
            approved or denied.
          </Typography>
        </p>
    </>
  );
}
