import { Stack, Typography } from '@mui/material';
import LotusHorizontalDivider from 'components/dataDisplay/divider/LotusHorizontalDivider';
import React from 'react';


export default function LotusSectionHeader({
  title,
  subtitle,
  actionButtonList
}) {
  return (
    // No left/right padding as expecting this will go in a container that already has left padding applied
    <Stack direction="column" spacing={3} style={{paddingBottom: 24}}>
      <Stack direction="column" spacing={1} style={{paddingTop: 24}}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{title}</Typography>
          {actionButtonList}
        </Stack>
        {subtitle &&
          <Typography variant="subtitle1">{subtitle}</Typography>
        }
      </Stack>
      <div>
        <LotusHorizontalDivider/>
      </div>
    </Stack>

  );
}