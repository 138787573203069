import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const NavigationContextProvider = ({ children }) => {
  const [state, setState] = useState({
    selectedAccount: null,
    selectedClient: null,
    selectedTemplate: null,
    selectedAgency: null,
    selectedForm: null,
  });

  return (
    <NavigationContext.Provider value={{ state, setState }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const navContext = useContext(NavigationContext);
  if (navContext) {
    const { state, setState } = navContext;
    return {
      state,
      setState,
    };
  }
};
