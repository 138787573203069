import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const LotusReadOnlyTextField = styled(TextField)({
  '& .MuiInput-input.Mui-disabled': {
    color: '#000000',
    WebkitTextFillColor: '#000000',
    paddingBottom: 0,
  },
});

export default function LotusReadOnlyFieldView({label, value, multiline, rows, style, adornment}) {

  return (
    <LotusReadOnlyTextField
      className="roView"
      fullWidth
      variant="standard"
      label={label}
      disabled={true}
      value={value !== null && value !== undefined ? value : ''}
      multiline={multiline}
      rows={multiline && rows ? rows : 1}
      style={{borderBottom: "1px solid black", maxWidth: '100%', ...style}}
      InputLabelProps={{ 
        shrink: true, // stupid react
      }} 
      InputProps={{
        startAdornment: adornment ? <InputAdornment disableTypography position="start">{adornment}</InputAdornment> : undefined 
      }}
    />
  );
}
