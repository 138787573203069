import React from 'react';
import Grid from '@mui/material/Grid';
import FieldElementView from './FieldElementView';
import {populateInitialArrayItem} from '../utils/formInitialization';
import LotusExpandableItemList from 'components/widgets/Forms/LotusExpandableItemList';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

const FieldArrayPanel = ({
  disabled,
  dontCheckRequiredFields,
  formDefinition,
  fieldDefinition
}) => {
  const getFieldValue = (value, fieldDefinition) => {
    if ('select' === fieldDefinition.type) {
      return getDisplayNameForValue(value, fieldDefinition);
    }

    return value;
  };

  const getDisplayNameForValue = (value, fieldDefinition) => {
    if (fieldDefinition.items) {
      const filtered = fieldDefinition.items.filter(
        (item) => item.value === value
      );
      return filtered.length > 0 ? filtered[0].label : value;
    }

    return value;
  };

  const populateTemplate = (item, template) => {
    const {itemFields} = fieldDefinition;
    let templateWithReplacements = template;

    if (templateWithReplacements) {
      Object.keys(item).map((key) => {
        const fieldDef = itemFields.find((field) => field.id === key);
        if (fieldDef) {
          const replacement = getFieldValue(item[key], fieldDef);
          templateWithReplacements = templateWithReplacements.replace(`{${key}}`, replacement || `[${fieldDef.label}]`);
        }
      });
    }

    return templateWithReplacements;
  };

  const buildDetailsPanel = (index, isDisabled) => {
    return (
      <Grid container>
        {fieldDefinition && fieldDefinition.itemFields.map((def) => {
          return (
            <LotusFormItem key={`${fieldDefinition.id}.${index}.${def.id}`}>
              <FieldElementView
                disabled={isDisabled}
                dontCheckRequiredFields={dontCheckRequiredFields}
                formDefinition={formDefinition}
                fieldDefinition={def}
                parentDefinition={fieldDefinition}
                parentIndex={index}
              />
            </LotusFormItem>
          );
        })}
      </Grid>
    );
  };

  return (
    <LotusExpandableItemList
      disabled={disabled} 
      label={fieldDefinition.label}
      name={fieldDefinition.id}
      itemName={fieldDefinition.itemName}
      buildItemSectionName={(item) => populateTemplate(item, fieldDefinition.sectionNameTemplate)}
      buildItemTitle={(item) => populateTemplate(item, fieldDefinition.headerTemplate)}
      buildItemSummary={(item, index) => { return <div></div>; }}
      buildItemDetail={(item, index, isDisabled) => buildDetailsPanel(index, isDisabled)}
      buildItemTemplate={() => populateInitialArrayItem(fieldDefinition, {})}
    />
  );
};

export default FieldArrayPanel;
