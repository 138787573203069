import LotusGenericListLinker from "components/form/linkers/LotusGenericListLinker";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useFormikContext } from "formik";
import CustomListLinker from "./CustomListLinker";
import ClientInfoListSubsetLinker from "./ClientInfoListSubsetLinker";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";


export default function ClientInfoConfigView({
  clientInfoCustomLists,
  clientRelationships, 
  hispanicEthnicities
}) {

  const {values} = useFormikContext();

  return (
    <>
      <LotusSubsectionHeader title="Registration" divider={false}/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="registration.showMiddleName"
            label="Show Middle Name"
          />
          <LotusSwitch
            name="registration.showNameSuffix"
            label="Show Name Suffix"
          />
          <LotusSwitch
            name="registration.showPreferredName"
            label="Show Preferred Name"
          />
          <LotusSwitch
            name="registration.showBirthGender"
            label="Show Birth Gender"
          />
          <LotusSwitch
            name="registration.showGenderPronouns"
            label="Show Gender Pronouns"
          />
          <LotusSwitch
            name="registration.showPrimaryAddress"
            label="Show Primary Address"
          />
          <LotusSwitch
            name="registration.showEmailAddress"
            label="Show Email Address"
          />
          <LotusSwitch
            name="registration.showPhoneNumbers"
            label="Show Phone Numbers"
          />
          {values.registration.showPhoneNumbers &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="registration.showPrimaryPhoneType"
                  label="Show Primary Phone Type"
                />
                <LotusSwitch
                  name="registration.showCellPhone"
                  label="Show Cell Phone"
                />
                <LotusSwitch
                  name="registration.showHomePhone"
                  label="Show Home Phone"
                />
                <LotusSwitch
                  name="registration.showWorkPhone"
                  label="Show Work Phone"
                />
                <LotusSwitch
                  name="registration.showOtherPhone"
                  label="Show Other Phone"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Basic Information"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showMiddleName"
            label="Show Middle Name"
          />
          <LotusSwitch
            name="existingClients.showNameSuffix"
            label="Show Name Suffix"
          />
          <LotusSwitch
            name="existingClients.showPreferredName"
            label="Show Preferred Name"
          />
          <LotusSwitch
            name="existingClients.showSocialSecurityNumber"
            label="Show Social Security Number"
          />
          <LotusSwitch
            name="existingClients.showExternalId"
            label="Show External System IDs"
          />
          <LotusSwitch
            name="existingClients.showGrandfathered"
            label="Show GrandFathered Status"
          />
          <CustomListLinker
            listFieldName='clientInfoDetailsDocumentationTypes' 
            newItemFieldName='newClientInfoDetailsDocumentationType'
            allListItems={clientInfoCustomLists.clientInfoDetailsDocumentationTypes}
            fieldDescription="Client Details Documentation Type"
            listHeader="Client Details Documentation Types"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Gender'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showGenderSection"
            label="Show Gender Section"
          />
          {values.existingClients.showGenderSection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showBirthGender"
                  label="Show Birth Gender"
                />
                <LotusSwitch
                  name="existingClients.showCurrentGenderIdentity"
                  label="Show Current Gender Identity"
                />
                <LotusSwitch
                  name="existingClients.showGenderPronouns"
                  label="Show Gender Pronouns"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Contact Phone & Email'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showClientPhoneAndEmailSection"
            label="Show Client Phone and Email Section"
          />
          {values.existingClients.showClientPhoneAndEmailSection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showPrimaryPhoneType"
                  label="Show Primary Phone Type"
                />
                <LotusSwitch
                  name="existingClients.showConfidentialityRequested"
                  label="Show Confidentiality Requested"
                />
                <LotusSwitch
                  name="existingClients.showCellPhone"
                  label="Show Cell Phone"
                />
                <LotusSwitch
                  name="existingClients.showCommunicationByText"
                  label="Show Communication By Text"
                />
                <LotusSwitch
                  name="existingClients.showHomePhone"
                  label="Show Home Phone"
                />
                <LotusSwitch
                  name="existingClients.showWorkPhone"
                  label="Show Work Phone"
                />
                <LotusSwitch
                  name="existingClients.showOtherPhone"
                  label="Show Other Phone"
                />
                <LotusSwitch
                  name="existingClients.showPhoneCallConsent"
                  label="Show Phone Call Consent"
                />
                <LotusSwitch
                  name="existingClients.showVoiceMailConsent"
                  label="Show Voice Mail Consent"
                />
                <LotusSwitch
                  name="existingClients.showEmailAddress"
                  label="Show Email Address"
                />
                <LotusSwitch
                  name="existingClients.showAdditionalEmailAddresses"
                  label="Show Additional Email Addresses"
                />
                <LotusSwitch
                  name="existingClients.showEmailConsent"
                  label="Show Email Consent"
                />
                <LotusSwitch
                  name="existingClients.showPrefProgNotifMethod"
                  label="Show Preferred Method of Receiving Program Notifications"
                />
                <LotusSwitch
                  name="existingClients.showCommunicationNotes"
                  label="Show Communication Notes"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Race'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showRaceSection"
            label="Show Race Section"
          />    
          {values.existingClients.showRaceSection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showAsianPicklist"
                  label="Show Asian Picklist"
                />
                <LotusSwitch
                  name="existingClients.showNhpiPicklist"
                  label="Show Native Hawaiian Pacific Islander Picklist"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Ethnicity'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showEthnicitySection"
            label="Show Ethnicity Section"
          />
          {values.existingClients.showEthnicitySection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showHispanicEthnicityPicklist"
                  label="Show Hispanic Ethnicity Picklist"
                />
                {hispanicEthnicities && values.existingClients.showHispanicEthnicityPicklist &&
                  <div>
                    <ClientInfoListSubsetLinker 
                      name='existingClients.selectedHispanicEthnicities' 
                      masterList={hispanicEthnicities}
                      title="Hispanic Ethnicities for Agency"
                    />
                  </div>
                }
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Languages'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showLanguageSection"
            label="Show Language Section"
          />
          {values.existingClients.showLanguageSection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showSecondaryLanguage"
                  label="Show Secondary Language"
                />
                <LotusSwitch
                  name="existingClients.showPreferredWrittenCommLanguageId"
                  label="Show Preferred Written Communications Language"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Other Demographics'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showOtherDemographicsSection"
            label="Show Other Demographics Section"
          />
          {values.existingClients.showOtherDemographicsSection &&
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showMaritalStatus"
                  label="Show Marital Status"
                />
                <LotusSwitch
                  name="existingClients.showVeteranStatus"
                  label="Show Veteran Status"
                />
                <LotusSwitch
                  name="existingClients.showLawfulResidentDate"
                  label="Show Date became Lawfully Present Resident"
                />
                <LotusSwitch
                  name="existingClients.showCitizenshipStatus"
                  label="Show Citizenship Status"
                />
                <LotusSwitch
                  name="existingClients.showIsTobaccoUser"
                  label="Show Tobacco Use"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Additional Information'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showClientDetailAdditionalInformation"
            label="Show Additional Information"
          />
          {values.existingClients.showClientDetailAdditionalInformation && 
            <div>
              <LotusStackedFormItems>
                <LotusSwitch
                  name="existingClients.showAdditionalAssistanceInterests"
                  label="Show Additional Assistance Interests"
                />
                {values.existingClients.showAdditionalAssistanceInterests &&
                  <div>
                    <LotusGenericListLinker
                      name="existingClients.additionalAssistanceInterests"
                      title="Additional Assistance Interests"
                      itemName="Assistance Type"
                    />
                  </div>
                }
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Existing Clients - Deceased'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showDeceasedSection"
            label="Show Deceased Section"
          />
          {values.existingClients.showDeceasedSection &&
            <div>
              <LotusStackedFormItems>               
                <LotusSwitch
                  name="existingClients.showDeathDate"
                  label="Show Death Date"
                />
                <LotusSwitch
                  name="existingClients.showCauseOfDeath"
                  label="Show Cause of Death"
                />
              </LotusStackedFormItems>
            </div>
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Addresses"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showSecondaryAddress"
            label="Show Secondary Address"
          />
          <CustomListLinker
            listFieldName='clientInfoAddressDocumentationTypes' 
            newItemFieldName='newClientInfoAddressDocumentationType' 
            allListItems={clientInfoCustomLists.clientInfoAddressDocumentationTypes}
            fieldDescription="Address Documentation Type"
            listHeader="Address Documentation Types"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Personal Contacts"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showLegalGuardian"
            label="Show Legal Guardian Selection"
          />
          <LotusSwitch
            name="existingClients.showCaregiverOfClient"
            label="Show Caregiver of Client Selection"
          />
          <LotusSwitch
            name="existingClients.showNextOfKin"
            label="Show Next of Kin Selection"
          />
          <LotusSwitch
            name="existingClients.showPowerOfAttorney"
            label="Show Power of Attorney Selection"
          />
          <LotusSwitch
            name="existingClients.showAwareOfHivStatus"
            label="Show Aware of HIV Status Selection"
          />
          <ClientInfoListSubsetLinker
            name='existingClients.selectedClientRelationships' 
            masterList={clientRelationships}
            title="Client Relationships for Agency"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Income"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.useMagi"
            value={values.existingClients.useMagi}
            label="Use MAGI"
            labelPlacement="start"
          />
          <CustomListLinker
            listFieldName='clientInfoIncomeSourceTypes' 
            newItemFieldName='newClientInfoIncomeSourceType'
            allListItems={clientInfoCustomLists.clientInfoIncomeSourceTypes}
            fieldDescription="Income Source Type"
            listHeader="Income Source Types"
          />
          <CustomListLinker
            listFieldName='clientInfoIncomeDocumentationTypes'
            newItemFieldName='newClientInfoIncomeDocumentationType'
            allListItems={clientInfoCustomLists.clientInfoIncomeDocumentationTypes}
            fieldDescription="Income Documentation Type"
            listHeader="Income Documentation Types"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Health"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="existingClients.showHivStatusSubcategory"
            label="Show HIV Status Subcategory"
          />
          {values.existingClients.showHivStatusSubcategory  &&
            <CustomListLinker
              listFieldName='clientInfoHivDocumentationTypes' 
              newItemFieldName='newClientInfoHivDocumentationType' 
              allListItems={clientInfoCustomLists.clientInfoHivDocumentationTypes}
              fieldDescription="HIV Documentation Type"
              listHeader="HIV Documentation Types"
            />
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Existing Clients - Insurance"/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>  
          <LotusSwitch
            name="existingClients.showDentalInsurance"
            value={values.existingClients.showDentalInsurance}
            label="Show Dental Insurance"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.showVisionInsurance"
            value={values.existingClients.showVisionInsurance}
            label="Show Vision Insurance"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.acaUsesHealthPlanDatabase"
            value={values.existingClients.acaUsesHealthPlanDatabase}
            label="Private Insurance - ACA Exchange Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.employerSponsoredUsesHealthPlanDatabase"
            value={values.existingClients.employerSponsoredUsesHealthPlanDatabase}
            label="Private Insurance - Employer Sponsored Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.individualMarketplaceUsesHealthPlanDatabase"
            value={values.existingClients.individualMarketplaceUsesHealthPlanDatabase}
            label="Private Insurance - Individual Marketplace Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.medicareUsesHealthPlanDatabase"
            value={values.existingClients.medicareUsesHealthPlanDatabase}
            label="Medicare Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.medicarePartDUsesHealthPlanDatabase"
            value={values.existingClients.medicarePartDUsesHealthPlanDatabase}
            label="Medicare Part D Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.medicaidUsesHealthPlanDatabase"
            value={values.existingClients.medicaidUsesHealthPlanDatabase}
            label="Medicaid Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.visionUsesHealthPlanDatabase"
            value={values.existingClients.visionUsesHealthPlanDatabase}
            label="Vision Uses Health Plan Database"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.dentalUsesHealthPlanDatabase"
            value={values.existingClients.dentalUsesHealthPlanDatabase}
            label="Dental Uses Health Plan Database"
            labelPlacement="start"
          />
          <CustomListLinker
            listFieldName='clientInfoInsuranceDocumentationTypes' 
            newItemFieldName='newClientInfoInsuranceDocumentationType' 
            allListItems={clientInfoCustomLists.clientInfoInsuranceDocumentationTypes}
            fieldDescription="Insurance Documentation Type"
            listHeader="Insurance Documentation Types"
          />
          <LotusSwitch
            name="existingClients.showMedicaidRx"
            value={values.existingClients.showMedicaidRx}
            label="Show Medicaid Rx Fields"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.showMedicarePolicyHolder"
            value={values.existingClients.showMedicarePolicyHolder}
            label="Show Medicare Policy Holder Field"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.showMedicareRx"
            value={values.existingClients.showMedicareRx}
            label="Show Medicare Rx Fields"
            labelPlacement="start"
          />
          <LotusSwitch
            name="existingClients.showBenefitSubsidies"
            value={values.existingClients.showBenefitSubsidies}
            label="Show Benefit Subsidies"
            labelPlacement="start"
          />
          <LotusGenericListLinker
            name="existingClients.externalSystems"
            title="External Systems"
            itemName="System"
          />  
        </LotusStackedFormItems>
      </LotusSubsectionContent>
    </>
  );
}
