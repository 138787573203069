import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLists } from 'contexts/ListsContext';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from '../Forms/LotusProgressiveDisclosure';

export default function EthnicityFieldPanel({ isHispanicFieldName, hispanicEthnicityFieldName, agencyHispanicEthnicities, disabled, required, dontCheckRequiredFields, showHispanicEthnicities, useSeedData }) {

  const [hispanicEthnicityField] = useField(hispanicEthnicityFieldName);

  const {hispanicEthnicities } = useLists();
  const [hispanicEthnicityList, setHispanicEthnicityList] = useState();

  useEffect(() => {
    if (useSeedData) {
      setHispanicEthnicityList([{label: "White", value: 1, itemKey:'white'},{label: "Asian", value: 2, itemKey: 'asian'},{label: "Hawaiian", value: 3, itemKey: 'native_hawaiian'}]);
    }
  }, [useSeedData]);

  useEffect(() => {
    if (hispanicEthnicities) {
      setHispanicEthnicityList(hispanicEthnicities.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}).filter(t => t.value === hispanicEthnicityField.value || (agencyHispanicEthnicities && agencyHispanicEthnicities.includes(t.value))));
    }
  }, [hispanicEthnicities, agencyHispanicEthnicities]);

  return (
    <LotusProgressiveDisclosure
      name={isHispanicFieldName}
      label="Is the Client ethnically Hispanic or Latino?"
      required={required}
      disabled={disabled}
      dontCheckRequiredFields={dontCheckRequiredFields}
    >
      <LotusProgressiveDisclosureItem
        itemValue='true'
        label="Hispanic Latino/a"
      >
        {showHispanicEthnicities &&
        <LotusMultiSelect
          name={hispanicEthnicityFieldName}
          label="Ethnicity - Hispanic or Latino/a"
          disabled={disabled}
          items={hispanicEthnicityList}
          readOnlyRows={2}
        />
        }
      </LotusProgressiveDisclosureItem>
      <LotusProgressiveDisclosureItem
        itemValue='false'
        label="Non-Hispanic Latino/a" 
      />
    </LotusProgressiveDisclosure>
  );
}
