export const messageTypes = {
  LOADING_BARRIER_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_BARRIER_SUMARRIESS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  LOADING_GOALS_AND_INTERVENTION_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_GOALS_AND_INTERVENTION_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  LOADING_BARRIER_DETAILS_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_BARRIER_DETAILS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  UPSERTING_CLIENT_BARRIER_SUCCESS: 'UPSERTING_CLIENT_BARRIER_SUCCESS',
  UPSERTING_CLIENT_GOAL_SUCCESS: 'UPSERTING_CLIENT_GOAL_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  function copyObject(curObj) {
    let newItems = {};
    Object.entries(curObj || {}).forEach(([k, v]) => {
      newItems[k] = v;
    });
    return newItems;
  }

  switch (type) {
    case messageTypes.LOADING_BARRIER_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS:
      const newBarrierSummaries = copyObject(state.barrierSummaries);
      newBarrierSummaries[payload.programId] = payload.items;
      return {
        ...state,
        barrierSummaries: newBarrierSummaries,
      };
    case messageTypes.LOADING_GOALS_AND_INTERVENTION_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS:
      const newGoals = copyObject(state.goalAndInterventionSummaries);
      newGoals[payload.programId] = payload.items;
      return {
        ...state,
        goalAndInterventionSummaries: newGoals,
      };
    case messageTypes.LOADING_BARRIER_DETAILS_FOR_CLIENT_AND_PROGRAM_SUCCESS: {
      const newBarrierDetails = copyObject(state.barrierDetails);
      newBarrierDetails[payload.programId] = payload.items;
      const newBarrierSummaries = copyObject(state.barrierSummaries);
      newBarrierSummaries[payload.programId] = payload.items;
      const newGoalSummaries = copyObject(state.goalAndInterventionSummaries);
      newGoalSummaries[payload.programId] = payload.allGoalsForProgram;

      return {
        ...state,
        barrierDetails: newBarrierDetails,
        barrierSummaries: newBarrierSummaries,
        goalAndInterventionSummaries: newGoalSummaries,
      };
    }
    case messageTypes.UPSERTING_CLIENT_BARRIER_SUCCESS: {
      const newBarrierDetails = copyObject(state.barrierDetails);
      if (payload.programId in newBarrierDetails) {
        const newProgramBarrierDetails = newBarrierDetails[payload.programId].filter(b => b.id !== payload.barrier.id);
        newProgramBarrierDetails.push(payload.barrier);
        newBarrierDetails[payload.programId] = newProgramBarrierDetails;
      }
      const newBarrierSummaries = copyObject(state.barrierSummaries);
      if (payload.programId in newBarrierSummaries) {
        const newProgramBarrierSummaries = newBarrierSummaries[payload.programId].filter(b => b.id !== payload.barrier.id);
        newProgramBarrierSummaries.push(payload.barrier);
        newBarrierSummaries[payload.programId] = newProgramBarrierSummaries;
      }
      return {
        ...state,
        barrierDetails: newBarrierDetails,
        barrierSummaries: newBarrierSummaries,
      };
    }
    case messageTypes.UPSERTING_CLIENT_GOAL_SUCCESS: {
      const newBarrierDetails = copyObject(state.barrierDetails);
      const newProgramBarrier = newBarrierDetails[payload.programId].find(b => b.id === payload.goal.barrierId);
      const newBarrierGoals = newProgramBarrier.goals.filter(g => g.id !== payload.goal.id);
      newBarrierGoals.push(payload.goal);
      newProgramBarrier.goals = newBarrierGoals;

      const newGoalsByProgram = copyObject(state.goalAndInterventionSummaries);
      const newProgramGoals = newGoalsByProgram[payload.programId].filter(g => g.id !== payload.goal.id);
      newProgramGoals.push(payload.goal);
      newGoalsByProgram[payload.programId] = newProgramGoals;

      return {
        ...state,
        barrierDetails: newBarrierDetails,
        goalAndInterventionSummaries: newGoalsByProgram,
      };
    }
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
