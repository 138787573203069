import React, { useState, useEffect } from 'react';
import ProgramPropertiesForm from './forms/ProgramProperties';
import ProgramAdditionalClientDataForm from './forms/ProgramAdditionalClientData';
import ProgramCareManagementOptions from './forms/ProgramCareManagementOptions';
import ProgramAgenciesForm from './forms/ProgramAgencies';
import ProgramAssessmentsForm from './forms/ProgramAssessments';
import ProgramEnrollmentForm from './forms/ProgramEnrollment';
import ProgramCarePlanForm from './forms/ProgramCarePlan';
import ProgramProgressLogForm from './forms/ProgramProgressLogs';
import ProgramInterpretationServicesForm from './forms/ProgramInterpretationServices';
import { useParams } from 'react-router-dom';
import ProgramConsentTypesForm from './forms/ProgramConsentTypes';
import ProgramDocumentTypes from './forms/ProgramDocumentTypes';
import ProgramActivityLogForm from './forms/ProgramActivityLogs';
import ProgramMeetingForm from './forms/ProgramMeeting';
import ProgramContactsForm from './forms/ProgramContacts';
import LotusTabPanel from 'components/widgets/Layout/LotusTabPanel';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function EditProgramPanel() {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    currentProgram,
    updateProgramProperties,
    updateProgramAgencies,
    updateProgramAssessments,
    updateProgramEnrollmentConfig,
    updateProgramCarePlanConfig,
    updateProgramInterpretationServices,
    setProgram,
    updateProgramAdditionalClientDataConfig,
    updateProgramConsentTypes,
    updateProgramDocumentTypes,
    updateProgramMeetingConfig,
    updateProgramActivityLogConfig,
    updateProgramContactConfig,
    updateProgramProgressLogConfig,
    updateProgramCareManagementOptions
  } = useProgramManagement();
  const { loadUserAgencyPrograms, loadUserGranteeAgencyPrograms } = useUserAgency();
  const { action: programId } = useParams();

  useEffect(() => {
    if (programId) {
      setProgram(programId);
    }
  }, [programId]);

  const handleTabChange = async (event, newValue) => {
    setTabIndex(newValue);
  };

  const saveProgramProperties = async (values) => {
    await updateProgramProperties(values);
    await loadUserAgencyPrograms();
    await loadUserGranteeAgencyPrograms();
  };

  const saveProgramAgencies = async (values) => {
    await updateProgramAgencies(values.selectedAgencies, values.id);
    await loadUserAgencyPrograms();
  };

  const saveProgramAssessments = async (values) => {
    await updateProgramAssessments(values.selectedAssessments, values.id);
  };

  const saveProgramEnrollmentConfig = async (values) => {
    await updateProgramEnrollmentConfig(values, values.id);
  };

  const saveProgramProgressLogConfig = async (values) => {
    await updateProgramProgressLogConfig(values, values.id);
  };

  const saveProgramMeetingConfig = async (values) => {
    await updateProgramMeetingConfig(values, values.id);
  };

  const saveProgramActivityLogConfig = async (values) => {
    await updateProgramActivityLogConfig(values, values.id);
  };

  const saveProgramContactConfig = async (values) => {
    await updateProgramContactConfig(values, values.id);
  };

  const saveProgramCarePlanConfig = async (values) => {
    await updateProgramCarePlanConfig(values);
  };

  const saveProgramCareManagementOptions = async (values) => {
    await updateProgramCareManagementOptions(values);
  };  

  const saveProgramInterpretationServices = async (values) => {
    await updateProgramInterpretationServices(values.currentInterpretationServiceNames, values.id);
  };

  const saveProgramAdditionalClientDataConfig = async (values) => {
    await updateProgramAdditionalClientDataConfig(values, programId);
    await loadUserAgencyPrograms();
  };

  const saveProgramConsentTypes = async (values) => {
    await updateProgramConsentTypes(values.selectedConsentTypes, values.id);
  };

  const saveProgramDocumentTypes = async (values) => {
    await updateProgramDocumentTypes(values.selectedDocumentTypes, values.id);
  };

  return (
    currentProgram && (
    <>
      <LotusPageTitle title={'Edit Program - ' + currentProgram.name}/>
      <LotusSpacedBlock>
        <LotusTabPanel
          value={tabIndex}
          handleTabChanged={handleTabChange}
          tabs={[
            { label: 'General', link: '' },
            { label: 'Activity Logs', link: '' },
            { label: 'Additional Client Data', link: '' },
            { label: 'Agencies', link: '' },
            { label: 'Assessments', link: '' },
            { label: 'Care Management', link: '' },
            { label: 'Care Plan', link: '' },
            { label: 'Consent Types', link: '' },
            { label: 'Contacts', link: '' },
            { label: 'Document Types', link: '' },
            { label: 'Enrollment', link: '' },
            { label: 'Interpretation Services', link: '' },
            { label: 'Meetings', link: '' },
            { label: 'Progress Logs', link: '' },
          ]}
          variant="scrollable"
        />
      </LotusSpacedBlock>
      {tabIndex === 0 && (
        <LotusPageSection header="General">
          <ProgramPropertiesForm
            handleSubmit={saveProgramProperties}
          />
        </LotusPageSection>
      )}
      {tabIndex === 1 && (
        <ProgramActivityLogForm
          handleSubmit={saveProgramActivityLogConfig}
        />
      )}
      {tabIndex === 2 && (
        <ProgramAdditionalClientDataForm
          handleSubmit={saveProgramAdditionalClientDataConfig}
        />
      )}
      {tabIndex === 3 && (
        <ProgramAgenciesForm
          handleSubmit={saveProgramAgencies}
        />
      )}
      {tabIndex === 4 && (
        <ProgramAssessmentsForm
          handleSubmit={saveProgramAssessments}
        />
      )}
      {tabIndex === 5 && (
        <ProgramCareManagementOptions
          handleSubmit={saveProgramCareManagementOptions}
        />
      )}
      {tabIndex === 6 && (
        <ProgramCarePlanForm
          handleSubmit={saveProgramCarePlanConfig}
        />
      )}
      {tabIndex === 7 && (
        <ProgramConsentTypesForm
          handleSubmit={saveProgramConsentTypes}
        />
      )}
      {tabIndex === 8 && (
        <ProgramContactsForm
          handleSubmit={saveProgramContactConfig}
        />
      )}
      {tabIndex === 9 && (
        <ProgramDocumentTypes
          handleSubmit={saveProgramDocumentTypes}
        />
      )}
      {tabIndex === 10 && (
        <ProgramEnrollmentForm
          handleSubmit={saveProgramEnrollmentConfig}
        />
      )}
      {tabIndex === 11 && (
        <ProgramInterpretationServicesForm
          handleSubmit={saveProgramInterpretationServices}
        />
      )}
      {tabIndex === 12 && (
        <ProgramMeetingForm
          handleSubmit={saveProgramMeetingConfig}
        />
      )}
      {tabIndex === 13 && (
        <ProgramProgressLogForm
          handleSubmit={saveProgramProgressLogConfig}/>
      )}
    </>
  ));
}
