
import { FormGroup, Stack } from "@mui/material";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusCheckbox from "components/form/checkbox/LotusCheckbox";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import { FieldArray, useFormikContext } from "formik";


export default function GranteeAgencyProviderTypesView() {
  const {values, setFieldValue} = useFormikContext();

  const addProviderType = () => {
    if (values.addedProviderTypeName) {
      values.providerTypes.push({name: values.addedProviderTypeName, selected: true, subtypes: []});
      setFieldValue('providerTypes', values.providerTypes);
      setFieldValue('addedProviderTypeName', '');
    }
  };

  return (
    <LotusStackedFormItems>
      {values.providerTypes.length > 0 && (
        <FormGroup>
          {values.providerTypes.map((providerType, index) => {
            return (
              <div key={index}>
                <LotusCheckbox
                  name={`providerTypes.${index}.selected`}
                  label={providerType.name}
                  handleChange={(newVal) => {
                    if (!newVal) {
                      values.providerTypes[index].subtypes.forEach(sub => {
                        sub.selected = false;
                      });
                      setFieldValue('providerTypes', values.providerTypes);
                    }
                  }}
                />
                <FieldArray name={`providerTypes.${index}.subtypes`}>
                  {({ push }) => {
                    const addSubtype = (idx) => {
                      push({name: values.providerTypes[idx].addedProviderSubtypeName, selected: true});
                      setFieldValue(`providerTypes.${idx}.addedProviderSubtypeName`, '');
                    }
                    return (
                      <div style={{paddingLeft: 16}}>
                        {providerType.subtypes.length > 0 && providerType.subtypes.map((subtype, sindex) => (
                          <LotusCheckbox
                            key={sindex}
                            name={`providerTypes.${index}.subtypes.${sindex}.selected`}
                            label={subtype.name}
                          />
                        ))}
                        <Stack direction="row" spacing={2}>
                          <div style={{width: 300}}>
                            <LotusTextInput
                              name={`providerTypes.${index}.addedProviderSubtypeName`}
                              label="New Provider Subtype"
                            />
                          </div>
                          <LotusAddButton onClick={() => addSubtype(index)} />
                        </Stack>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>
            );
          })}
        </FormGroup>
      )}
      <Stack direction="row" spacing={2}>
        <div style={{width: 300}}>
          <LotusTextInput 
            name="addedProviderTypeName" 
            label="New Provider Type"/>
        </div>
        <LotusAddButton onClick={addProviderType} />
      </Stack>
    </LotusStackedFormItems>
  );
}