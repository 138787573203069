import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLists } from 'contexts/ListsContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFormItems from '../Forms/LotusFormItems';
import LotusItemList from '../Forms/LotusItemList';
import IncomeSamplesPanel from './IncomeSamplesPanel';

export default function IncomeAdjustmentsPanel({ incomeAdjustmentsFieldName, relationships, disabled, required, dontCheckRequiredFields, useSeedData }) {

  const { incomeAdjustmentTypes, incomePeriods } = useLists();

  const [incomeAdjustmentTypeList, setIncomeAdjustmentTypeList] = useState();
  const [incomePeriodList, setIncomePeriodList] = useState();

  const [incomeAdjustmentsField] = useField(incomeAdjustmentsFieldName);

  useEffect(() => {
    if (incomeAdjustmentTypes) {
      setIncomeAdjustmentTypeList(incomeAdjustmentTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (incomePeriods) {
      setIncomePeriodList(incomePeriods.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [incomePeriods, incomeAdjustmentTypes]);

  const emptyIncomeAdjustment = {
    recipientRelationshipId: '',
    incomeAdjustmentTypeId: '',
    incomePeriodId: '',
    samples: [{amount: '', sampleDate: null}]
  };
  
  function buildIncomeAdjustmentPanel(incomeAdjustment, index, disabled) {
    return (
      <div style={{padding: 8}} key={index}>
        <LotusFormItems>
          <div style={{fontSize: 18, fontWeight: 400, marginBottom: 16}}>Income Adjustment {index + 1}</div>
          {relationships && (   
            <div style={{marginBottom: 16}}>                    
              <LotusSelect
                disabled={disabled}
                name={`${incomeAdjustmentsFieldName}.${index}.recipientRelationshipId`}
                label="Adjustment Recipient"
                required={required}
                disallowNoSelectionIfNotRequired
                dontCheckRequiredFields={dontCheckRequiredFields}
                items={relationships}
              />
            </div>
          )}
          {incomeAdjustmentTypeList && (  
            <div style={{marginBottom: 16}}>                        
              <LotusSelect
                disabled={disabled}
                name={`${incomeAdjustmentsFieldName}.${index}.incomeAdjustmentTypeId`}
                label="Income Adjustment Type"
                required={required}
                dontCheckRequiredFields={dontCheckRequiredFields}
                items={incomeAdjustmentTypeList}
                autoSort={false}
              />
            </div>
          )}
          {incomePeriodList && (  
            <div style={{marginBottom: 16}}>                        
              <LotusSelect
                disabled={disabled}
                name={`${incomeAdjustmentsFieldName}.${index}.incomePeriodId`}
                label="Period"
                required={required}
                dontCheckRequiredFields={dontCheckRequiredFields}
                items={incomePeriodList}
                autoSort={false}
              />
            </div>
          )}
          {incomeAdjustmentsField.value[index] && incomeAdjustmentsField.value[index].incomePeriodId &&   
            <IncomeSamplesPanel 
              incomeSamplesFieldName={`${incomeAdjustmentsFieldName}.${index}.samples`}
              disabled={disabled}
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              itemName="Amount"
            />
          }
        </LotusFormItems>
      </div>
    );
  }

  return (
    <LotusItemList
      disabled={disabled} 
      name={incomeAdjustmentsFieldName}
      itemName="Income Adjustment"
      label="Income Adjustments"
      buildItemDetail={(item, index, isDisabled) => buildIncomeAdjustmentPanel(item, index, isDisabled)}
      buildItemTemplate={() => {return {...emptyIncomeAdjustment}}}
    />
  );
}