import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusSelect from "components/form/select/LotusSelect";
import { useFormikContext } from "formik";
import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusIntegerInput from "components/form/inputField/common/LotusIntegerInput";
const cronstrue = require('cronstrue');
import * as yup from 'yup';
import { INTEGRATION_TYPES } from "./enums";
import Cron from 'react-cron-generator'
import { HEADER } from 'react-cron-generator';
import { Typography } from "@mui/material";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

function IntegrationViewImpl({
  agencyConnectors
}) {

  const { values, setFieldValue } = useFormikContext();

  const [scheduleText, setScheduleText] = useState();

  useEffect(() => {
    if (values.schedule) {
      let txt = cronstrue.toString(values.schedule);
      if (txt.includes('AM') || txt.includes('PM')) {
        txt = txt + ' Eastern';
      }
      setScheduleText(`Integration will trigger: ${txt}`);
    }
  }, [values.schedule]);

  const reinitializeParams = (integrationTyp) => {
    const params = {}
    if (integrationTyp === 'careware_export_recent') {
      params.numberOfDays = '14';
    }
    setFieldValue("integrationParams", params);
  }

  return values && (
    <>
      <LotusSubsectionContent excludeTopPadding>
        <LotusStackedFormItems>
          <LotusTextInput
            name="name"
            label="Integration Name"
            disabled={Boolean(values.arn)}
            required
            regexToMatch='^[0-9a-zA-Z\-]{1,64}$'
            regexValidationMessage='Only letters, numbers and hyphen (-) are allowed. Max 64 characters.'
          />
          <LotusSelect
            label="Integration Type"
            name="integrationType"
            items={INTEGRATION_TYPES.map(atype => { return { label: atype.name, value: atype.integrationType } })}
            required
            handleChange={(val) => setTimeout(() => reinitializeParams(val))}
          />
          {values.integrationType === 'careware_export_recent' && values.integrationParams.numberOfDays !== undefined && (
            <LotusIntegerInput
              name="integrationParams.numberOfDays"
              label="Number of Days"
              required
            />
          )}
          {agencyConnectors && (
            <LotusSelect
              label="External Servers"
              name="ConnectorId"
              items={agencyConnectors.map(connector => { return { label: connector.Url, value: connector.ConnectorId } })}
              required
            />
          )}
          <LotusTextInput
            name="subdirectory"
            label="Remote Server Subdirectory"
            regexToMatch='^[0-9a-zA-Z-_\/]{1,255}$'
            regexValidationMessage='Only letters, numbers, forward slash (/), hyphen (-), and underscore (_) are allowed. Max 255 characters.'
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Schedule" />
      <LotusSubsectionContent>
        <Cron
          label="Schedule"
          name="schedule"
          onChange={(e, t) => { setFieldValue('schedule', e); }}
          value={values.schedule || undefined}
          options={{ headers: [HEADER.DAILY, HEADER.HOURLY, HEADER.MINUTES] }}
        />
        <Typography variant="body1" >{scheduleText}</Typography>
      </LotusSubsectionContent>
    </>
  );
}

export default function IntegrationView({ 
  integration,
  agencyConnectors,
  integrationDetailLoaderFunc,
  existingIntegrationNames,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();
  
  const initializeExistingIntegration = async () => {
    const integrationDetail = await integrationDetailLoaderFunc(integration.name);

    setInitialValues({
      name: integration.name,
      integrationType: integrationDetail.integrationType,
      ConnectorId: integrationDetail.connectorId,
      arn: integrationDetail.arn,
      integrationParams: integrationDetail.integrationParams || {},
      subdirectory: integrationDetail.subdirectory,
      schedule: integrationDetail.schedule
    })
  }

  useEffect(() => {
    if (integration) {
      initializeExistingIntegration();
    } else {
      setInitialValues({
        name: '',
        integrationType: '',
        ConnectorId: '',
        arn: '',
        integrationParams: {},
        subdirectory: '',
        schedule: undefined
      });
    }
  }, [integration]);

  const validationSchema = yup.object({
    name: yup.string().test({
      message: () => 'Name must be unique',
      test(value) {
        return !existingIntegrationNames || existingIntegrationNames.indexOf(value) === -1;
      },
    })
  });

  return initialValues && (
    <LotusStaticForm
      title={`${integration ? 'Edit' : 'Add'} integration${integration ? `: ${integration.name}` : ''}`}
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
      validationSchema={validationSchema}
      showBackToTop={false}
    >
      <div style={{width: 500}}>
        <IntegrationViewImpl 
          agencyConnectors={agencyConnectors}
          integrationDetailLoaderFunc={integrationDetailLoaderFunc}
          existingIntegrationNames={existingIntegrationNames}
        />
      </div>
    </LotusStaticForm>
  );
  
}