import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useState } from 'react';
import { useField } from 'formik';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Error } from '@mui/icons-material';

function LotusCheckboxListItem({
  name,
  label,
  value,
  checked,
  disabled
}) {
  const [field, meta, utils] = useField(name);

  const handleLocalChange = (e) => {
    field.onChange(e);
    utils.setTouched(true, false);
  };

  return (
    <FormControlLabel
      style={{paddingLeft: 14, width: 'fit-content'}}
      control={
        <Checkbox
          name={name}
          onChange={handleLocalChange}
          disabled={disabled}
          checked={checked}
          value={value}
        />
      } 
      label={label}
    />
  );
}

export default function LotusCheckboxList({ 
  name,
  label,
  helperText,
  items,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  handleChange,
  autoSort=true,
  excludeInactiveOrDeleted=false,
  otherItemFieldName
}) {
  const [allItems, setAllItems] = useState();

  const [ field, meta, utils ] = useField({name, validate: (val) => {
    if (required && !dontCheckRequiredFields && !((val && val.length > 0) || (otherItemFieldName && otherField.value))) {
      return `${label} is required!`;
    } else {
      utils.setError(undefined);
    }
  }});

  const otherName = otherItemFieldName || 'ignoreThisFieldName';
  const [otherField, otherMeta, otherUtils] = useField({name: otherName, validate: (val) => {
    if (required && !dontCheckRequiredFields && !((field.value && field.value.length > 0) || (otherItemFieldName && val))) {
      utils.setError(`${label} is required!!`);
    } else {
      utils.setError(undefined);
    }
  }});

  useEffect(() => {
    if (handleChange) {
      handleChange({values: field.value, otherValue: otherField.value});
    }
  }, [field.value, otherField.value]);

  const labelText = label ? label + (required ? ' * ' : '') : null;

  useEffect(() => {
    if (items) {
      let lst = [...items];
      if (autoSort) {
        lst = lst.sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        });
      }
      if (excludeInactiveOrDeleted) {
        // Exclude inactive or deleted items unless the item is the current selection
        lst = lst.filter(itm => (itm.isActive && !itm.isDeleted) || itm.value === field.value);
      }
      setAllItems(lst);
    }
  }, [items]);

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && 
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1">
              {labelText}
            </Typography>
            {meta.touched && meta.error &&
              <Error color='error'/>
            }
          </Stack>
          <FormGroup>
            {field.value && allItems &&
              allItems.map((node) => {
                return (
                  <LotusCheckboxListItem
                    key={`cbxitm-${node.value}`}
                    name={field.name}
                    label={node.label}
                    value={node.value}
                    checked={field.value.find((aId) => aId === node.value) !== undefined}
                    disabled={Boolean(disabled || node.disabled)}
                  />
                );
              })
            }
            {otherItemFieldName &&
              <Stack direction="row" style={{paddingLeft: 14}}>
                <LotusCheckbox name={`cbx${otherField.name}`} label='' checked={Boolean(otherField.value)} style={{marginRight: -15}}/>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  size="small"
                  style={{width: '100%', marginTop: 0}} // Mui-TextField has extra 8px margin top which make things look inconsistent with other controls
                  onBlur={(e) => {otherUtils.setValue(e.target.value.trim()); otherUtils.setTouched(true, true); setTimeout(() => utils.setTouched(true, true),0) }}
                  name={otherField.name}
                  value={otherField.value}
                  disabled={disabled}
                  inputProps={{maxLength: 100}}
                  onChange={(e) => {otherUtils.setValue(e.target.value.trim()); otherUtils.setTouched(true, true); setTimeout(() => utils.setTouched(true, true),0)}}
                />
              </Stack>
            }
          </FormGroup>
        </>
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={`${items && (field.value || []).map(fv => items.find(x => x.value === fv)?.label).join(', ')}${otherItemFieldName && otherField && otherField.value ? `${field.value && field.value.length > 0 ? ', ' : ''}${otherField.value}` : ''}`} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
