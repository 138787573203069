import { useEffect, useState } from "react";
import moment from 'moment';
import { Grid, Typography } from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import LotusSwitch from "components/widgets/Forms/LotusSwitch";
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { Button } from "@lotus/components";
import { generateThumbnailsForUrl } from "lib/documents";
import { useAppStatus } from "contexts/AppStatusContext";

export default function ConsentDocumentUploader({
  consentType,
  clientId,
  handleCancel,
  handleSave,
}) {
  const [thumbnails, setThumbnails] = useState();
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues({
      document: {},
      receivedAudibleConsent: false,
      dateSigned: moment().format('MM/DD/YYYY'),
    });
  }, []);

  return (
    consentType && initialValues &&
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={async (values) => {
          let result = {};
          if (!values.dateSigned) {
            result.dateSigned = "Date signed is required";
          }
          // Doing all the document validation here as the upload component
          // doesnt natively support it
          if (!values.receivedAudibleConsent) {
            if (Object.keys(values.document).length === 0) {
              result.document = "Consent document is required";
            }
            if (values.docErr) {
              result.docErr = values.docErr;
            }
          }
          return result;
        }}
        onSubmit={async (newValues, actions) => {
          await handleSave(newValues);
          actions.setSubmitting(false);
        }}
      >
        {({values, touched, errors, setFieldTouched, handleChange, handleSubmit, setFieldValue}) => {
          return (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey"}}>
                  <Grid item xs={6}>
                    <Typography variant="h2">Upload Consent</Typography>
                  </Grid>
                  <Grid item xs={6} container justifyContent='flex-end'>
                    <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                      <Typography variant="h6">Cancel</Typography>
                    </Button>
                    <Button onClick={() => {handleSubmit(values);}}>
                      <Typography variant="h6">Save</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop: 20, marginBottom: 24}}>
                  <Typography color='primary' variant='body2'>Consent Type: {consentType.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <LotusDatePicker
                    name="dateSigned"
                    label={values.receivedAudibleConsent ? "Date Discussed" : "Date Signed"}
                    required
                    style={{width: 200}}
                    value={values.dateSigned}
                    error={touched.dateSigned && Boolean(errors.dateSigned)}
                  />
                </Grid>
                <Grid item xs={6}>
                  {consentType.supportsVerbal &&
                  <LotusSwitch
                    name="receivedAudibleConsent"
                    value={values.receivedAudibleConsent}
                    label='Received audible consent'
                    labelPlacement='end'
                    onChange={handleChange}
                  />
                  }
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <LotusFileUploader
                    name="clientConsentUploader"
                    maxFiles={1}
                    clientId={clientId}
                    subfolderName="consents"
                    disabled={values.receivedAudibleConsent}
                    existingFiles={
                      Object.keys(values.document).length > 0
                        ? [{
                          source: values.document.uniqueName,
                          options: {
                            type: 'local',
                            file: { name: values.document.displayName, type: values.document.type }
                          }
                        }]
                        : []
                    }
                    onFileAdded={async (newFile, base64Str) => {
                      console.log('Added', newFile);
                    try {
                      addBusyBee('generatingThumbnail');
                      const thumbnails = await generateThumbnailsForUrl(
                        base64Str,
                        newFile.type,
                        [100]
                      );
                        setThumbnails(thumbnails);
                        setFieldTouched('docErr');
                        setFieldValue('docErr', '');
                        setFieldTouched('document');
                        setFieldValue('document', newFile);
                      }
                      catch (err) {
                        console.log(err);
                        setFieldTouched('document');
                        setFieldTouched('docErr');
                        setFieldValue('document', {});
                        setFieldValue('docErr', 'An error occurred while generating thumbnail images for file');
                      } finally {
                        removeBusyBee('generatingThumbnail');
                      }
                    }}
                    onFileDeleted={(newFile) => {
                      console.log('Deleted', newFile);
                      setFieldTouched('document');
                      setFieldValue('document', {});
                      setThumbnails(null);
                    }}
                    onError={(err, action, msg) => {
                      console.log(err, msg);
                      setFieldTouched('document');
                      setFieldTouched('docErr');
                      const errMsg = msg || 'An error occurred while processing the document';
                      if (action !== 'preview') {
                        setFieldValue('document', {});
                      }
                      setFieldValue('docErr', errMsg);
                    }}
                  />
                  <div style={{color:'#b53f3f', marginTop: 10}}>
                    <ErrorMessage name="document" />
                  </div>
                  <div style={{color:'#b53f3f', marginTop: 10}}>
                    <ErrorMessage name="docErr" />
                  </div>
                </Grid> 
              </Grid>
              <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {thumbnails && thumbnails.map((t,idx) => {
                    return (<img key={idx} src={t} style={{marginRight: 10}}/>);
                  })}
              </div>
            </Grid> 
          );
        }}
      </Formik>
    </Grid>) || <div></div>
}
