import React, { Children, useEffect, useState } from 'react';
import { useField } from 'formik';
import LotusSpacer from '../Layout/LotusSpacer';
import LotusRadio from './LotusRadio';
import { Typography } from '@mui/material';

// Using this to pass the name/disabled props from the parent to the child LotusProgressiveDisclosureItems
// so user doesnt have to 
const ProgressiveDisclosureContext = React.createContext(); 

export function LotusProgressiveDisclosureItem({label, itemValue, children}) {

  const pdContext = React.useContext(ProgressiveDisclosureContext);

  const [itemExpanded, setItemExpanded] = useState(false);

  const [field] = useField(pdContext.name);

  useEffect(() => {
    setItemExpanded(String(field.value) === itemValue);
  }, [field.value]);

  return (
    <div style={{width: '100%'}}>
      <LotusRadio
        name={pdContext.name} 
        disabled={pdContext.disabled} 
        label={label} 
        value={itemValue}
      />
      {children && itemExpanded &&
        <div style={{padding: "16px 48px"}}>{children}</div>
      }
    </div>
  );
}

// name prop is the form value underneath the radio buttons
export function LotusProgressiveDisclosure({name, label, required, disabled, dontCheckRequiredFields, children}) {
  const [items, setItems] = useState();

  const labelText = label + (required ? ' * ' : '');

  const [field, meta] = useField({name, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && val === '') {
      return `${lbl} is required`;
    }
  }});

  useEffect(() => {
    let itemList = [];
    Children.forEach(children, child => {
      if (child.type === LotusProgressiveDisclosureItem) { // this seems to fail when in dev and hot update happens
        itemList.push(child);
      }
    })
    setItems(itemList);
  }, [children]);

  return (
    <ProgressiveDisclosureContext.Provider value={{ name: name, disabled: disabled }}>
      {labelText}
      <LotusSpacer/>
      {items && items.map((itm,idx) => {
        return (
          <div key={idx}>
            {itm}
          </div>
        );
      })
      }
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </ProgressiveDisclosureContext.Provider>
  );
}
