export const messageTypes = {
  LOADING_USER_AGENCY_SUCCESS: 'LOADING_USER_AGENCY_SUCCESS',
  LOADING_AGENCY_ASSESSMENTS_SUCCESS: 'LOADING_AGENCY_ASSESSMENTS_SUCCESS',
  LOADING_AGENCY_CONSENT_TYPES_SUCCESS: 'LOADING_AGENCY_CONSENT_TYPES_SUCCESS',
  LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS: 'LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS',
  LOADING_AGENCY_LANGUAGES_SUCCESS: 'LOADING_AGENCY_LANGUAGES_SUCCESS',
  LOADING_AGENCY_PROGRAMS_SUCCESS: 'LOADING_AGENCY_PROGRAMS_SUCCESS',
  LOADING_AGENCY_PROVIDER_TYPES_SUCCESS: 'LOADING_AGENCY_PROVIDER_TYPES_SUCCESS',
  LOADING_AGENCY_ADAP_SUBPROGRAMS_SUCCESS: 'LOADING_AGENCY_ADAP_SUBPROGRAMS_SUCCESS',
  LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS: 'LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS',
  LOADING_AGENCY_METRICS_SUCCESS: 'LOADING_AGENCY_METRICS_SUCCESS',
  LOADING_GRANTEE_AGENCY_PROGRAMS_SUCCESS: 'LOADING_GRANTEE_AGENCY_PROGRAMS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_USER_AGENCY_SUCCESS:
    case messageTypes.LOADING_AGENCY_ASSESSMENTS_SUCCESS:
    case messageTypes.LOADING_AGENCY_CONSENT_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_LANGUAGES_SUCCESS:
    case messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS:
    case messageTypes.LOADING_AGENCY_PROVIDER_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_ADAP_SUBPROGRAMS_SUCCESS:
    case messageTypes.LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS:
    case messageTypes.LOADING_AGENCY_ADAP_SUBPROGRAMS_SUCCESS:
    case messageTypes.LOADING_GRANTEE_AGENCY_PROGRAMS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.LOADING_AGENCY_METRICS_SUCCESS:
      let newMetricsByCategory = null;
      if (state.newMetricsByCategory) {
        newMetricsByCategory = {...state.userAgencyMetrics};
      } else {
        newMetricsByCategory = {};
      }
      payload.metricsByCategory.forEach(cm => newMetricsByCategory[cm.category] = cm.metrics);
      return {
        ...state,
        userAgencyMetrics: newMetricsByCategory
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
