import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useField } from 'formik';
import { Box, Checkbox, Chip, Typography, InputLabel, OutlinedInput, FilledInput, Stack, FormControlLabel, Switch } from '@mui/material';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Error } from '@mui/icons-material';

export default function LotusMultiSelect({
  name,
  label,
  helperText,
  items,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  readOnlyRows,
  handleChange,
  autoSort=true,
  excludeInactiveOrDeleted=false,
  showSelectAll=false
}) {

  const [selectAllChecked, setSelectAllChecked] = useState();
  const [field, meta, utils] = useField({name, validate: (val) => {
    if (required && !dontCheckRequiredFields && (!val || val.length === 0)) {
      return `${label} is required`;
    }
  }});

  const labelText = label + (required ? ' * ' : '');

  let selectItems = items ? [...items] : [];
  if (excludeInactiveOrDeleted) {
    // Exclude inactive or delete items unless the item is the current selection
    selectItems = selectItems.filter(itm => (itm.isActive && !itm.isDeleted) || (field.value && field.value.includes(itm.value)));
  }
  if (autoSort) {
    selectItems.sort((a, b) => (a.label > b.label ? 1 : -1));
  }

  useEffect(() => {
    if (showSelectAll) {
      if (field.value && field.value.length === selectItems.length) {
        setSelectAllChecked(true);
      } else {
        setSelectAllChecked(false);
      }
    }
  }, [field.value]);

  const handleLocalChange = (e) => {
    const newValue = e.target.value; 
    if (newValue && !newValue.includes(undefined)) { // select all toggled
      if (handleChange) {
        handleChange(newValue);
      }
    }
    field.onChange(e);
  };

  const handleSelectAllChange = (e) => {
    if (!e.target.disabled && e.target.checked) {
      utils.setValue(selectItems.map(i => i.value));
      setTimeout(() => {
        if (handleChange) {
          handleChange(selectItems.map(i => i.value));
        }
      });
    }
  }

  const handleCheckboxChange = (e) => {
    if (!e.target.checked) {
      utils.setValue(field.value.filter(v => v !== e.target.value));
    } else {
      utils.setValue([...field.value, e.target.value]);
    }
  }

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && 
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControl fullWidth variant={disabled ? "filled" : "outlined"} style={{width: '100%'}} size="small" error={meta.touched && Boolean(meta.error)}>
            <InputLabel id={`${name}-outlined-label`}>{labelText}</InputLabel>
            <Select
              labelId={`${name}-outlined-label`}
              {...field}
              multiple
              onChange={handleLocalChange}
              required={required}
              disabled={disabled}
              input={disabled ? <FilledInput label={labelText}/> : <OutlinedInput label={labelText} />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected && selected.length < 5 && selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          (selectItems &&
                            selectItems.find((itm) => itm.value === value) &&
                            selectItems.find((itm) => itm.value === value).label) ||
                          value
                        }
                      />
                    ))}
                    {selected && selected.length >= 5 &&
                      <Chip
                        key='manyselected'
                        label='5+ items'
                      />
                    }
                  </Box>
                );}
              }
            >
              {field.value && selectItems && (
                [
                  showSelectAll && <MenuItem key='selectall' disabled={Boolean(selectAllChecked)} style={{marginLeft: 16}}>
                    <FormControlLabel 
                      control={
                        <Switch 
                          checked={Boolean(selectAllChecked)}
                          onChange={handleSelectAllChange}
                        />
                      } 
                      label="Select All"
                      style={{paddingBottom: 8, borderBottom: "1px solid black", width: '100%'}} />
                  </MenuItem>,
                  selectItems.map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
                        <Checkbox checked={field.value.indexOf(item.value) > -1} value={item.value} disabled={item.disabled} onChange={handleCheckboxChange} />
                        <Typography variant="body2">{item.label}</Typography>
                      </MenuItem>
                    );
                  })
                ]
              )}
            </Select>
          </FormControl>
          {meta.touched && meta.error &&
            <Error color='error'/>
          }
        </Stack>
      }
      {readOnly &&
        <LotusReadOnlyFieldView 
          label={label} 
          value={(selectItems && (field.value || []).map(fv => selectItems.find(x => x.value === fv)?.label).join(', ')) || ''}
          multiline={readOnlyRows ? true : false}
          rows={readOnlyRows}
        />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}