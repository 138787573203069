export const messageTypes = {
  LOADING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS',
  UPDATING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS: 'UPDATING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS',
  UPSERTING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS: 'UPSERTING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS',
  STARTING_ELIGIBILITY_APPLICATION_SUCCESS: 'STARTING_ELIGIBILITY_APPLICATION_SUCCESS',
  SUBMITTING_ELIGIBILITY_APPLICATION_SUCCESS: 'SUBMITTING_ELIGIBILITY_APPLICATION_SUCCESS',
  COMPLETING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS: 'COMPLETING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS',
  PENDING_ELIGIBILITY_APPLICATION_SUCCESS: 'PENDING_ELIGIBILITY_APPLICATION_SUCCESS',
  STARTING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS: 'STARTING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS',
  CONTINUING_ELIGIBILITY_APPLICATION_SUCCESS: 'CONTINUING_ELIGIBILITY_APPLICATION_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS:
    case messageTypes.UPDATING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS: 
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS:
    case messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS:
    case messageTypes.STARTING_ELIGIBILITY_APPLICATION_SUCCESS:
    case messageTypes.SUBMITTING_ELIGIBILITY_APPLICATION_SUCCESS:
    case messageTypes.COMPLETING_ELIGIBILITY_APPLICATION_SUCCESS:
    case messageTypes.PENDING_ELIGIBILITY_APPLICATION_SUCCESS:
    case messageTypes.STARTING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS:
    case messageTypes.COMPLETING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
