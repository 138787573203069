import React, { useState } from 'react'
import LotusButton from 'components/widgets/Forms/LotusButton';
import Typography from '@mui/material/Typography';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Grid, Box } from "@mui/material";
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';

export default function EnterMFACodeForm({
  values,
  handleChange,
  touched,
  errors,
}) {
  const [otp, setOtp] = useState('');

  const handleOtpChange = (newValue) => {
    values.otp = newValue;
    values.errorMessage = '';
    setOtp(newValue);
  }
  return (
    <>
      {values.operation === 'enterMFACode' && (

        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body">Enter the code from your MFA application.</Typography>

          <LotusSpacer /><LotusSpacer />
          <Grid item style={{ width: "400px", border: '10px' }}>
            <MuiOtpInput autoFocus length={6} value={values.otp}
              onChange={handleOtpChange}
              TextFieldsProps={{ type: "password", autoComplete: "off", size: 'small' }}
              error={touched.otp && Boolean(errors.otp)} />
          </Grid>
          <LotusSpacer /><LotusSpacer />
          
          {(values.operation === 'enterMFACode' && values.errorMsg) && (
            <Typography variant="body" color='#b53f3f' style={{ width: 400, fontSize: 16 }}>{values.errorMsg}</Typography>
          )}
          <LotusSpacer /><LotusSpacer />
          <LotusButton style={{ width: "340px", height: '42px', fontWeight: 500, fontSize: 15 }} type="submit"
            disabled={!Boolean(values?.otp?.length === 6)}>
            LOG IN
          </LotusButton>
        </Box>
      )}
    </>
  );
};