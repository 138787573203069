import React from 'react';
import LotusSecondaryActionButton from '../LotusSecondaryActionButton';
import Add from '@mui/icons-material/Add';


export default function LotusAddButton({
  onClick,
  isDanger,
  isSuccess,
  disabled,
  itemLabel
}) {
  return (
    <LotusSecondaryActionButton
      startIcon={<Add/>}
      onClick={onClick}
      isDanger={isDanger}
      isSuccess={isSuccess}
      disabled={disabled}
    >
      Add{itemLabel ? ` ${itemLabel}` : ''}
    </LotusSecondaryActionButton>
  );
}