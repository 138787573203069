import { Grid } from '@mui/material';
import React from 'react';

export default function LotusFormSubSection({ name, disabled, ...props }) {

  return (
    <>
    <Grid item xs={12} container style={{paddingBottom: 8}}>
      {name &&
      <Grid item xs={12} style={{paddingBottom: 16}}>
        <div id={`${disabled ? '' : 'edit'}${name}`} style={{fontSize: 20, fontWeight: 400}}>{name}</div>
      </Grid>
      }
      {props.children}
    </Grid>
    </>
  );
}