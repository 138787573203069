import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useEffect, useState } from "react";
import GranteeAgencyConsentTypesView from "./GranteeAgencyConsentTypesView";

export default function GranteeAgencyConsentTypes() {

  const { agency, 
    consentTypes, loadAgencyConsentTypes, upsertAgencyConsentType,
    languages, loadAgencyLanguages } = useAgencyManagement();

  const [loadedAgencyConsentTypes, setLoadedAgencyConsentTypes] = useState();
  const [loadedAgencyLanguages, setLoadedAgencyLanguages] = useState();

  useEffect(() => {
    if (agency && !languages) {
      loadAgencyLanguages();
    }
  }, [agency, languages]);

  useEffect(() => {
    if (agency && languages) {
      setLoadedAgencyLanguages(languages);
    }
  }, [agency, languages]);

  useEffect(() => {
    if (agency && !consentTypes) {
      loadAgencyConsentTypes();
    }
  }, [agency]);

  useEffect(() => {
    if (consentTypes) {
      let consentTypesLst = [...consentTypes];
      consentTypesLst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyConsentTypes(consentTypesLst);
    }
  }, [consentTypes]);

  const saveConsentType = async (newValues) => {
    if (!newValues.supportMultipleLanguages) {
      newValues.languageDocuments = [];
    } else {
      newValues.languageDocuments.map((ld) => {
        ld.consentDocumentFileName = ld.document.displayName;
        ld.consentDocumentUniqueFileName = ld.document.uniqueName;
        ld.consentDocumentFileType = ld.document.type;
        ld.consentDocumentThumbnail = ld.document.thumbnail;
      });
    }
    newValues.defaultConsentDocumentFileName = newValues.defaultConsentDocument.displayName;
    newValues.defaultConsentDocumentUniqueFileName = newValues.defaultConsentDocument.uniqueName;
    newValues.defaultConsentDocumentFileType = newValues.defaultConsentDocument.type;
    newValues.defaultConsentDocumentThumbnail = newValues.defaultConsentDocument.thumbnail;
    newValues.defaultConsentDocumentPreview = newValues.defaultConsentDocument.preview;
    newValues.isActive = !newValues.discontinued;
    upsertAgencyConsentType(newValues);
  }

  return loadedAgencyConsentTypes && (
    <GranteeAgencyConsentTypesView
      consentTypes={loadedAgencyConsentTypes}
      agency={agency}
      agencyLanguages={loadedAgencyLanguages}
      handleSave={saveConsentType}
    />
  );
}