import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientRegistrationContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";

const initialState = {
  client: undefined,
  clientDuplicates: undefined
};

const ClientRegistrationContext = createContext();

export const ClientRegistrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientRegistrationContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientRegistrationContext.Provider>
  );
};

export const useClientRegistration = () => {
  const { state, dispatch } = useContext(ClientRegistrationContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const registerClient = async (clientId, clientData, agencyId, skipDuplicateCheck) => {
    try {
      addBusyBee('registerClient');
      const url = `api/client`;
      
      const toPost = {
        operationName: 'registerClient',
        clientId,
        clientData: clientData,
        agencyId: agencyId,
        skipDuplicateCheck: skipDuplicateCheck
      }

      const { data } = await axios.post(url, toPost);

      const {duplicateInfo, client} = parseApiResult(data).body;

      if (duplicateInfo) {
        dispatch({
          type: messageTypes.REGISTERING_CLIENT_ENCOUNTERED_DUPLICATES,
          payload: {
            clientDuplicates: duplicateInfo,
          },
        });
      } else {
        dispatch({
          type: messageTypes.REGISTERING_CLIENT_SUCCESS,
          payload: {
            client: {
              id: client.id,
              agencyId: client.agency_id,
              name: client.name
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('registerClient');
    }
  };

  return {
    ...state,
    registerClient
  };
};
