import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';
import { StaticDatePicker } from '@mui/x-date-pickers';
import ActionDialog from 'components/Dialogs/ActionDialog';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LotusButton from './LotusButton';

export default function LotusDateRangePickerPopup({
  startDateFieldName,
  endDateFieldName,
  minDate,
  maxDate,
  views,
  label,
  startDateLabel,
  endDateLabel,
  handleDateRangeChange,
  required,
  dontCheckRequiredFields,
  disabled,
  style,
  ...props
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [dateRangeText, setDateRangeText] = useState('');

  const handleClickShowPopup = () => { setShowPopup(true); }

  const handleMouseDownPopup = (e) => { e.preventDefault(); };

  const closePopup = () => {
    setShowPopup(false);
    if (handleDateRangeChange) {
      handleDateRangeChange(startDateField.value, endDateField.value);
    }
  }

  const [startDateField, startDateMeta, startDateUtils] = useField({name: startDateFieldName, validate: (val) => {
    const lbl = label || 'Date Range';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    return null;
  }});

  const [endDateField, endDateMeta, endDateUtils] = useField({name: endDateFieldName, validate: (val) => {
    const lbl = label || 'Date Range';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    return null;
  }});

  useEffect(() => {
    if (startDateField.value && endDateField.value) {
      setDateRangeText(`${startDateField.value} - ${endDateField.value}`);
    } else {
      setDateRangeText('None');
    }
  }, [startDateField.value, endDateField.value]);

  return (
    !disabled ? 
    <div>
      <FormControl sx={{ width: 275 }} variant="outlined">
        <InputLabel margin="dense" size="small" htmlFor="dateRange" style={{paddingTop: 4}}>{label}</InputLabel>
        <OutlinedInput
          id="dateRange"
          type='text'
          inputProps={{style: {padding: "13px 13px"}}}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPopup}
                onMouseDown={handleMouseDownPopup}
                edge="end"
              >
                <CalendarMonthIcon  />
              </IconButton>
            </InputAdornment>
          }
          label={label}
          value={dateRangeText}
          onClick={handleClickShowPopup}
          onMouseDown={handleMouseDownPopup}
        />
      </FormControl>
      <ActionDialog
        maxWidth="lg"
        open={showPopup}
        content={
          <DateRangePopup
            startDateFieldName={startDateFieldName}
            endDateFieldName={endDateFieldName}
            startDateLabel={startDateLabel}
            endDateLabel={endDateLabel}
            minDate={minDate}
            maxDate={maxDate}
            views={views}
            required={required}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        }
        handleClose={(!startDateMeta.error && !endDateMeta.error && closePopup) || null}
      />
    </div>
    : 
    <div>
      <LotusReadOnlyFieldView label={label} value={startDateField.value && endDateField.value ? `${startDateField.value} - ${endDateField.value}` : ''} />
      {startDateMeta.touched && startDateMeta.error && (
        <Typography variant="body2" color="error">
          {startDateMeta.error}
        </Typography>
      )}
      {endDateMeta.touched && endDateMeta.error && (
        <Typography variant="body2" color="error">
          {endDateMeta.error}
        </Typography>
      )}
    </div>
  );
}

function DateRangePopup({
  startDateFieldName,
  endDateFieldName,
  minDate,
  maxDate,
  views,
  startDateLabel,
  endDateLabel,
  handleDateRangeChange,
  required,
  dontCheckRequiredFields,
  style,
  ...props
}) {
  const DATE_FORMAT = 'MM/DD/YYYY';

  const [momentStartDate, setMomentStartDate] = useState();
  const [momentEndDate, setMomentEndDate] = useState();

  const {validateForm} = useFormikContext();

  const [startDateField, startDateMeta, startDateUtils] = useField({name: startDateFieldName, validate: (val) => {
    const lbl = startDateLabel || 'Start Date';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    if (!required && val && !momentEndDate) {
      return `If ${lbl} is specified, ${endDateLabel || 'End Date'} is required`;
    }
    if (val) {
      let toValidate = val;
      if (typeof(val) === 'string') {
        toValidate = moment(val, DATE_FORMAT);
      }
      if (!toValidate.isValid()){ 
        return `${lbl} must be a valid date`;
      }
      if (maxDate) {
        const today = moment().format(DATE_FORMAT);
        if (moment(maxDate,DATE_FORMAT) < toValidate) {
          if (maxDate === today) {
            return `${lbl} cannot be in the future`;
          } else {
            return `${lbl} exceeds maximum`;
          }
        }
      }
      if (minDate) {
        if (moment(minDate,DATE_FORMAT) > toValidate) {
          return `${lbl} is too old`;
        }
      }
      if (toValidate.year() < 1900) { // this datepicker gives an error border if the date is before 1900 but no message
        return `${lbl} is too old`;
      }
      if (momentEndDate) {
        if (moment(toValidate, DATE_FORMAT) > momentEndDate) {
          return `${lbl} must be before ${endDateLabel || 'End Date'}`;
        }
      }
    }
    return null;
  }});

  const [endDateField, endDateMeta, endDateUtils] = useField({name: endDateFieldName, validate: (val) => {
    const lbl = endDateLabel || 'End Date';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    if (!required && val && !momentStartDate) {
      return `If ${lbl} is specified, ${startDateLabel || 'Start Date'} is required`;
    }
    if (val) {
      let toValidate = val;
      if (typeof(val) === 'string') {
        toValidate = moment(val, DATE_FORMAT);
      }
      if (!toValidate.isValid()){ 
        return `${lbl} must be a valid date`;
      }
      if (maxDate) {
        const today = moment().format(DATE_FORMAT);
        if (moment(maxDate,DATE_FORMAT) < toValidate) {
          if (maxDate === today) {
            return `${lbl} cannot be in the future`;
          } else {
            return `${lbl} exceeds maximum`;
          }
        }
      }
      if (minDate) {
        if (moment(minDate,DATE_FORMAT) > toValidate) {
          return `${lbl} is too old`;
        }
      }
      if (toValidate.year() < 1900) { // this datepicker gives an error border if the date is before 1900 but no message
        return `${lbl} is too old`;
      }
      if (momentStartDate) {
        if (moment(toValidate, DATE_FORMAT) < momentStartDate) {
          return `${lbl} must be before ${startDateLabel || 'Start Date'}`;
        }
      }
    }
    return null;
  }});

  useEffect(() => {
    if (startDateField.value !== undefined) {
      const newMomentDate = startDateField.value ? moment(startDateField.value,DATE_FORMAT) : null;
      if (momentStartDate?.format(DATE_FORMAT) !== newMomentDate?.format(DATE_FORMAT)) {
        setMomentStartDate(startDateField.value ? moment(startDateField.value,DATE_FORMAT) : null);
      }
      else if (handleDateRangeChange) {
        handleDateRangeChange(startDateField.value, endDateField.value);
      }
    }
  }, [startDateField.value]); // startDateField.value is a string

  useEffect(() => {
    if (endDateField.value !== undefined) {
      const newMomentDate = endDateField.value ? moment(endDateField.value,DATE_FORMAT) : null;
      if (momentEndDate?.format(DATE_FORMAT) !== newMomentDate?.format(DATE_FORMAT)) {
        setMomentEndDate(endDateField.value ? moment(endDateField.value,DATE_FORMAT) : null);
      }
      else if (handleDateRangeChange) {
        handleDateRangeChange(startDateField.value, endDateField.value);
      }
    }
  }, [endDateField.value]); // endDateField.value is a string

  useEffect(() => {
    if (momentStartDate !== undefined) {
      const curMomentDateStr = momentStartDate ? momentStartDate.format(DATE_FORMAT) : null;
      if (startDateField.value !== curMomentDateStr) {
        startDateUtils.setTouched(true);
        startDateUtils.setValue(curMomentDateStr, true);
      }
    }
  }, [momentStartDate]); // momentStartDate is a moment

  useEffect(() => {
    if (momentEndDate !== undefined) {
      const curMomentDateStr = momentEndDate ? momentEndDate.format(DATE_FORMAT) : null;
      if (endDateField.value !== curMomentDateStr) {
        endDateUtils.setTouched(true);
        endDateUtils.setValue(curMomentDateStr, true);
      }
    }
  }, [momentEndDate]); // momentEndDate is a moment

  const EmptyToolbar = ({isLandscape, onAccept, onClear, onSetToday, onViewChange}) => {
    return (<div></div>);
  }
  const EmptyActionBar = ({isLandscape, onAccept, onClear, onSetToday, onViewChange}) => {
    return (<div></div>);
  }

  return (
    // This is the dumbest thing ever.  Picking a date should be unrelated to time zones
    <Stack direction="row" spacing={1}>
      <Stack direction="column" spacing={1} alignItems="center">
        <div style={{fontSize: 20, textAlign: 'center'}}>
          {startDateLabel || 'Start Date'}: {startDateField.value || '--'}
        </div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker
            slots={{
              actionBar: EmptyActionBar,
              toolbar: EmptyToolbar
            }}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                required: required
              },
            }}
            sx={{width: '100%', ...style}}
            views={views || ['year', 'day']}
            minDate={minDate ? moment(minDate) : null}
            maxDate={maxDate ? moment(maxDate) : null}
            value={momentStartDate || null}
            onAccept={(newValue) => setMomentStartDate(newValue)}
            onChange={(newValue) => setMomentStartDate(newValue)}
          />
          {startDateMeta.touched && startDateMeta.error && (
            <Typography variant="body2" color="error" style={style}>
              {startDateMeta.error}
            </Typography>
          )}
        </LocalizationProvider>
      </Stack>
      <Divider orientation="vertical" flexItem/>
      <Stack direction="column" spacing={1} alignItems="center">
        <div style={{fontSize: 20, textAlign: 'center'}}>
          {endDateLabel || 'End Date'}: {endDateField.value || '--'}
        </div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker
            slots={{
              actionBar: EmptyActionBar,
              toolbar: EmptyToolbar
            }}
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small",
                required: required
              },
            }}
            sx={{width: '100%', ...style}}
            views={views || ['year', 'day']}
            minDate={minDate ? moment(minDate) : null}
            maxDate={maxDate ? moment(maxDate) : null}
            value={momentEndDate || null}
            onAccept={(newValue) => setMomentEndDate(newValue)}
            onChange={(newValue) => setMomentEndDate(newValue)}
          />
          {endDateMeta.touched && endDateMeta.error && (
            <Typography variant="body2" color="error" style={style}>
              {endDateMeta.error}
            </Typography>
          )}
        </LocalizationProvider>
      </Stack>
      <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Today
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().subtract(1, 'weeks').startOf('isoweek').format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().subtract(1, 'weeks').endOf('isoweek').format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Last Week
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Last Month
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().subtract(3, 'months').startOf('month').format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Last 3 Months
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().subtract(6, 'months').startOf('month').format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Last 6 Months
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(moment().subtract(12, 'months').startOf('month').format(DATE_FORMAT)); startDateUtils.setTouched(true); endDateUtils.setValue(moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT)); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Last 12 Months
        </LotusButton>
        <LotusButton
          onClick={() => {startDateUtils.setValue(null); startDateUtils.setTouched(true); endDateUtils.setValue(null); endDateUtils.setTouched(true); setTimeout(() => validateForm());}}
          variant="outlined"
          style={{width: "100%"}}
        >
          Clear
        </LotusButton>
      </Stack>
    </Stack>
  );
}
