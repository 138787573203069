import React from 'react';
import LotusSelect from 'components/widgets/Forms/LotusSelect';

export default function LotusTrueFalseSelect({ allowNull=true, yesLabel='Yes', noLabel='No', label, name, disabled, dontCheckRequiredFields }) {
  
  return (
    <LotusSelect
      label={label}
      name={name}
      disabled={disabled}
      autoSort={false}
      required={!allowNull}
      dontCheckRequiredFields={dontCheckRequiredFields}
      items={[
        {label: yesLabel, value: true},
        {label: noLabel, value: false},
      ]}/>
  );
}