import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import { useFormikContext } from "formik";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

function PremiumsPaidView() {
  
  const { values } = useFormikContext();

  return (
    <>
      <LotusSubsectionContent excludeTopPadding>
        <LotusSwitch
          name="enabled"
          label="Premiums Paid Enabled"
        />
      </LotusSubsectionContent>
      {values.enabled && (
        <>
          <LotusSubsectionHeader title='Type Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="typeCategory.adjustmentFlag"
                label="Adjustment Flag Enabled"
              />
              <LotusSwitch
                name="typeCategory.refunded"
                label="Refunded Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Payment Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="paymentCategory.voucherDate"
                label="Voucher Date Enabled"
              />
              <LotusSwitch
                name="paymentCategory.voucherId"
                label="Voucher ID Enabled"
              />
              <LotusSwitch
                name="paymentCategory.authNum"
                label="Authorization Number Enabled"
              />
              <LotusSwitch
                name="paymentCategory.paymentMethod"
                label="Payment Method Enabled"
              />
              <LotusSwitch
                name="paymentCategory.paymentId"
                label="Payment ID Enabled"
              />
              <LotusSwitch
                name="paymentCategory.paymentRefundUniqueId"
                label="Payment Refund Unique ID Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Premium Coverage Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="premiumCoverageCategory.planName"
                label="Plan Name Enabled"
              />
              <LotusSwitch
                name="premiumCoverageCategory.planMemberId"
                label="Plan Member ID Enabled"
              />
              <LotusSwitch
                name="premiumCoverageCategory.planType"
                label="Plan Type Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
        </>
      )}
    </>
  );
}

export function GranteeAgencyPremiumsPaidView({
  premiumsPaidConfig,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if (premiumsPaidConfig) {
      const newInitialValues = {
        enabled: premiumsPaidConfig.enabled || false,
        // Premiums Paid Component - Type Category
        typeCategory: {
          adjustmentFlag: premiumsPaidConfig.typeCategory.adjustmentFlag || false,
          refunded: premiumsPaidConfig.typeCategory.refunded || false
        },
        // Premiums Paid Component - Payment Category
        paymentCategory: {
          voucherDate: premiumsPaidConfig.paymentCategory.voucherDate || false,
          voucherId: premiumsPaidConfig.paymentCategory.voucherId || false,
          authNum: premiumsPaidConfig.paymentCategory.authNum || false,
          paymentMethod: premiumsPaidConfig.paymentCategory.paymentMethod || false,
          paymentId: premiumsPaidConfig.paymentCategory.paymentId || false,
          paymentRefundUniqueId: premiumsPaidConfig.paymentCategory.paymentRefundUniqueId || false
        },
        // Premiums Paid Component - Premium Coverage Category
        premiumCoverageCategory: {
          planName: premiumsPaidConfig.premiumCoverageCategory.planName || false,
          planMemberId: premiumsPaidConfig.premiumCoverageCategory.planMemberId || false,
          planType: premiumsPaidConfig.premiumCoverageCategory.planType || false
        }
      };
      setInitialValues(newInitialValues);
    }
  }, [premiumsPaidConfig]);

  return initialValues && (
    <LotusStaticForm
      title="Premiums Paid Configuration"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 500}}>
        <PremiumsPaidView />
      </div>
    </LotusStaticForm>
  );
}