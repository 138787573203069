import { Form, Formik, yupToFormErrors } from 'formik';
import LotusSectionHeader from 'components/headers/LotusSectionHeader';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusCancelButton from 'components/button/common/LotusCancelButton';
import LotusSaveButton from 'components/button/common/LotusSaveButton';
import LotusStickyNav from 'components/navigation/LotusStickyNav';
import { Stack } from '@mui/material';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';
import { HashLink } from 'react-router-hash-link';


// This form will use the input components validation, for now not doing yup schema
export default function LotusStaticForm({
  title,
  sections, // {title: s, url: `#${s}`}
  initialFormValues,
  showSaveButton = true,
  showCancelButton = true,
  additionalButtons, // other than save/cancel, they should have their own handlers and "key" attr
  handleSave,
  afterSaveFunc,
  handleCancel,
  disabled,
  // Either have validationFunc or validationSchema (or neither), not both
  validationFunc, // async (values) => {fieldName1: errorText1, fieldName2: errorText2, etc.}
  validationSchema,
  showBackToTop = true,
  // "children" should be a component containing the actual form content. 
  // it should use useFormikContext to get the form values or other helper funcs if needed (e.g., for conditional display)
  children, 
}) {

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={async (newValues, actions) => {
        await handleSave(newValues);
        actions.setSubmitting(false);
        actions.resetForm(newValues);
        if (afterSaveFunc) {
          afterSaveFunc();
        }
      }}
      validate={async (values) => {
        if (validationFunc) {
          return await validationFunc(values);
        }
        if (validationSchema) {
          try {
            validationSchema.validateSync(values, { abortEarly: false });
          } catch (err) {
            console.log(err);
            let formErrors = yupToFormErrors(err);
            console.log(formErrors);
            return formErrors;
          }
        }
        return {};
      }}
    >
      {({values, handleSubmit, handleReset}) => {
        return values && (
          <Form autoComplete="off" id="topOfForm">
            <LotusSectionHeader
              title={title}
              actionButtonList={
                <LotusButtonList>
                  {showCancelButton &&
                    <LotusCancelButton disabled={disabled} onClick={() => {handleReset(); handleCancel();}}/>
                  }
                  {showSaveButton &&
                    <LotusSaveButton disabled={disabled} onClick={handleSubmit}/>
                  }
                  {additionalButtons && additionalButtons.map(btn => {
                    return btn;
                  })}
                </LotusButtonList>
              }
            />
            <Stack direction="row" spacing={4} style={{width: '100%'}} alignItems="flex-start">
              {sections && sections.length > 0 &&
                <LotusStickyNav linkData={sections} />
              }
              <div style={{width: '100%'}}>
                {children}
                {showBackToTop &&
                  <div style={{textAlign: "center", marginTop: 24}}>
                    <HashLink smooth to="#topOfForm">
                      <LotusSecondaryActionButton variant="outlined">
                        Back to Top
                      </LotusSecondaryActionButton>
                    </HashLink>
                  </div>
                }
              </div>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}