import React from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useField } from 'formik';

const PREFIX = 'LotusRadio';

const classes = {
  radioButton: `${PREFIX}-radioButton`,
  radioLabel: `${PREFIX}-radioLabel`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {
    [`& .${classes.radioButton}`]: {
      color: theme.palette.text.primary,
      padding: '5px 5px 5px 9px',
    },
    [`& .${classes.radioLabel}`]: {
      color: theme.palette.text.primary,
      paddingLeft: 10,
      fontSize: 16,
    },
  };
});

// Note: This component is intended to be used as a standalone radio button (e.g., in another control)
// as opposed to within a group
export default function LotusRadio({ name, value, disabled, label, handleChange }) {
  const [field, meta, utils] = useField(name);

  return (
    <StyledFormControl>
      <FormControlLabel
        classes={{
          label: classes.radioLabel,
        }}
        control={
          <Radio
            name={name}
            checked={String(field.value) === value}
            disabled={disabled}
            className={classes.radioButton}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            onChange={(event) => {
              if (handleChange) {
                handleChange(event.currentTarget.value);
              }
              utils.setTouched(true);
              // Special case of booleans represented by 'true' or 'false'
              if (event.currentTarget.value === 'true') {
                utils.setValue(true);
              } else if (event.currentTarget.value === 'false') {
                utils.setValue(false);
              } else {
                utils.setValue(event.currentTarget.value);
              }
            }}
          />
        }
        label={label}
        value={value.toString()}
      />
    </StyledFormControl>
  );
}
