import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function LotusDialog({
  staticWidth, // sm, md, lg, xl, or unspecified.  if unspecified, dialog will be autosized according to content and window
  actionButtonList,
  content,
  title,
  open,
  handleClose,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={staticWidth}
      fullWidth={Boolean(staticWidth)}
      scroll="paper"
    >
      {title &&
        <DialogTitle onClose={handleClose}>
          {title}
        </DialogTitle>
      }

      <DialogContent>{content}</DialogContent>

      {actionButtonList && (
        <DialogActions style={{padding: 16}}>
          {actionButtonList}
        </DialogActions>
      )}
    </Dialog>
  );
}
