import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { Autocomplete } from '@mui/material';
import { isEqual } from 'lodash';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusAutocomplete({
  label,
  required,
  dontCheckRequiredFields,
  style,
  options,
  disabled,
  ...props
}) {

  const [field, meta, helpers] = useField({...props, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
  }});

  return (
    !disabled ? 
    <div>
      <Autocomplete
        size="small"
        autoHighlight
        options={options}
        value={field.value}
        disabled={disabled}
        getOptionLabel={(option) => option.name || option}
        {...props}
        onInputChange={(event, newSelectionText, reason) => {
          if (newSelectionText === '' && reason === 'clear') {
            // if sets null value for a reset ends up with infinite loop
            helpers.setValue(null);
          }
          else if (options && options.length > 0) {
            const newVal = options.find(o => o.name === newSelectionText || o === newSelectionText);
            if (newVal !== undefined && (!field.value || !isEqual(field.value, newVal))) {
              // Weird inf loop happens without the check when adding one of these in an array panel
              helpers.setValue(newVal);
            }
          }
        }}
        isOptionEqualToValue={(option, value) => option === value || option?.id === value?.id}
        renderInput={(params) => (
          <TextField 
            label={label + (required ? ' * ' : '')}
            variant="outlined"
            margin="dense"
            size="small"
            style={{width: '100%', ...style}}
            {...params} 
          />
        )}
        required={required}
      />
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </div> :
    <LotusReadOnlyFieldView label={label} value={typeof field.value === 'object' ? field.value?.name : field.value} style={style} />
  );
}
