import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './HealthPlansContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  healthPlanCarriersByPlanType: {}, // key by plan type (e.g., 'ACA')
  healthPlansByPlanTypeAndCarrier: {}, // key by plan type and then by carrier name
  
};

const HealthPlansContext = createContext();

export const HealthPlansProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <HealthPlansContext.Provider value={{ state, dispatch }}>
      {children}
    </HealthPlansContext.Provider>
  );
};

export const useHealthPlans = () => {
  const { state, dispatch } = useContext(HealthPlansContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadCarriersForPlanType = async (planType) => {

    addBusyBee('loadCarriersForPlanType');

    try {
      const toPost = {
        operationName: 'getCarriers',
        planType: planType
      };
      const { data } = await axios.post('/api/healthPlans', toPost);

      let carriersData = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CARRIERS_FOR_PLAN_TYPE_SUCCESS,
        payload: {
          planType,
          carriers: carriersData
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadCarriersForPlanType');
    }
  };

  const loadPlansForPlanTypeAndCarrier = async (planType, carrierName) => {

    addBusyBee('loadPlansForPlanTypeAndCarrier');

    try {
      const toPost = {
        operationName: 'getPlans',
        planType: planType,
        carrierName: carrierName
      };
      const { data } = await axios.post('/api/healthPlans', toPost);

      let plansData = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PLANS_FOR_PLAN_TYPE_AND_CARRIER_SUCCESS,
        payload: {
          planType,
          carrierName,
          plans: plansData
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPlansForPlanTypeAndCarrier');
    }
  };

  return {
    ...state,
    loadCarriersForPlanType,
    loadPlansForPlanTypeAndCarrier,
  };
};
