import { CognitoUserPool } from 'amazon-cognito-identity-js';


export const getUserPool = async () => {

  const userPoolId = sessionStorage.getItem('CognitoIdentityServiceProvider.userPoolId');
  const clientId = sessionStorage.getItem('CognitoIdentityServiceProvider.clientId');

  const poolData = {
    UserPoolId: `${userPoolId}`,
    ClientId: `${clientId}`,
    Storage: window.sessionStorage
  }

  if (userPoolId) {
    return new CognitoUserPool(poolData);

  }
  return undefined;
}

export const getUserSession = async () => {
  try {
    const userPoolId = sessionStorage.getItem('CognitoIdentityServiceProvider.userPoolId');
    let result;
    if (userPoolId) {
      result = await getSessionSync();
    }
    return result;
  } catch (err) {
    return { error: err.message, errorCode: err.code };
  }
}

const getSessionSync = async () => {
  let userpool = await getUserPool();

  if (!userpool) {
    return undefined;
  }
  const currUser = userpool.getCurrentUser();

  if (!currUser) {
    return;
  }

  return new Promise(function(resolve, reject) {
    currUser.getSession(function(err, session) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}
