import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './AgencyIntegrationsContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';

const AgencyIntegrationsContext = createContext();

const initialState = {
  connectors: undefined,
  integrations: undefined
};

export const AgencyIntegrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgencyIntegrationsContext.Provider value={{ state, dispatch }}>
      {children}
    </AgencyIntegrationsContext.Provider>
  );
};

export const useAgencyIntegrations = () => {
  const { state, dispatch } = useContext(AgencyIntegrationsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadAgencyIntegrations = async (agencyId) => {
    try {
      addBusyBee('loadAgencyIntegrations');
      const toPost = {
        operationName: 'getSchedules',
        agencyId: agencyId,
      };

      const url = `/api/integrations`;
      const { data } = await axios.post(url, toPost);
      const { schedules } = parseApiResult(data).body;


      dispatch({
        type: messageTypes.LOADING_AGENCY_INTEGRATIONS_SUCCESS,
        payload: {
          integrations: schedules
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyIntegrations');
    }
  };

  const loadAgencyIntegrationDetailsSync = async (agencyId, name) => {
    try {
      addBusyBee('loadAgencyIntegrationDetails');
      const toPost = {
        operationName: 'getScheduleDetails',
        agencyId: agencyId,
        name: name
      };

      const url = `/api/integrations`;
      const { data } = await axios.post(url, toPost);
      const { integrationDetails } = parseApiResult(data).body;
      return integrationDetails;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyIntegrationDetails');
    }
  };

  const loadAgencyConnectors = async (agencyId) => {

    if (agencyId) {
      try {
        addBusyBee('loadAgencyConnectors');

        const toPost = {
          operationName: 'getConnectors',
          agencyId: agencyId
        }

        const url = `/api/integrations`;
        const { data } = await axios.post(url, toPost);
        const connectors = parseApiResult(data).body;

        dispatch({
          type: messageTypes.LOADING_AGENCY_CONNECTORS_SUCCESS,
          payload: {
            connectors
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('loadAgencyConnectors');
      }
    }
  };

  const createAgencyIntegration = async (agencyId, integrationData, groupExists) => {
    try {
      addBusyBee('createAgencyIntegration');

      const toPost = {
        operationName: 'createSchedule',
        name: integrationData.name,
        agencyId: agencyId,
        integrationType: integrationData.integrationType,
        connectorId: integrationData.ConnectorId,
        schedule: integrationData.schedule,
        groupExists: groupExists,
        integrationParams: integrationData.integrationParams,
        subdirectory: integrationData.subdirectory
      };

      const url = `/api/integrations`;
      const { data } = await axios.post(url, toPost);
      const resultObj = parseApiResult(data).body;

      dispatch({
        type: messageTypes.CREATING_AGENCY_INTEGRATION_SUCCESS,
        payload: {
          integration: { ...integrationData, arn: resultObj.arn }
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createAgencyIntegration');
    }
  };

  const updateAgencyIntegration = async (agencyId, integrationData) => {
    try {
      addBusyBee('updateAgencyIntegration');
      const toPost = {
        operationName: 'updateSchedule',
        name: integrationData.name,
        agencyId: agencyId,
        integrationType: integrationData.integrationType,
        connectorId: integrationData.ConnectorId,
        schedule: integrationData.schedule,
        integrationParams: integrationData.integrationParams,
        subdirectory: integrationData.subdirectory
      };

      const url = `/api/integrations`;
      const { data } = await axios.post(url, toPost);
      const resultObj = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_INTEGRATION_SUCCESS,
        payload: {
          integration: { ...integrationData, arn: resultObj.arn }
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyIntegration');
    }

  };



  if (state) {
    return {
      ...state,
      loadAgencyIntegrations,
      loadAgencyConnectors,
      createAgencyIntegration,
      updateAgencyIntegration,
      loadAgencyIntegrationDetailsSync
    };
  }

  return {};
};
