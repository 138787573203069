import React, { useEffect, useState } from 'react';
import { FieldArray, useField } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Card, Typography } from '@mui/material';

export default function LotusItemList({
  name,
  label,
  itemName,
  disabled,
  buildItemDetail,
  buildItemTemplate,
  allowRemove=true,
  dontCheckRequiredFields,
  isSubList
}) {
  const [field] = useField({name});

  return (
    <FieldArray 
      name={name}
      render={arrayHelpers => {
        
        const addItem = () => {
          const newItem = buildItemTemplate();
          arrayHelpers.push(newItem);
        };

        const removeItem = (index) => {
          arrayHelpers.remove(index);
        };

        return (
          <Grid item xs={12} container>
            {label &&
            <Grid item xs={12} style={{marginBottom: 20}}>
              <div style={{fontSize: isSubList ? 16 : 20, fontWeight: 400}}>{label}</div>
            </Grid>
            }
            <Grid item xs={12} >
            {label && field.value && field.value.length === 0 &&
              <Typography variant="body2" style={{marginBottom: 20}}>None</Typography>
            }
            </Grid>
            <Grid item container>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                {field.value && field.value.length > 0 &&
                  field.value.map((item, index) => {
                    return (
                      <Card 
                        elevation={3} 
                        style={{paddingLeft: 10, marginBottom: 20, paddingBottom: 10, paddingRight: 10}} 
                        key={index} 
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <div style={{paddingTop: 10}}>{buildItemDetail(item, index, disabled, dontCheckRequiredFields)}</div>
                          </Grid>
                          {!disabled && allowRemove &&
                            <Grid item xs={12}>
                              <Button onClick={() => removeItem(index)} style={{ color: 'grey', float: 'right'}}>
                                <DeleteIcon /> Remove
                              </Button>
                            </Grid>
                          }
                        </Grid>
                      </Card>
                    );
                  })
                }
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Button variant="outlined" disabled={disabled} onClick={addItem}>
                  <AddIcon/> Add {itemName}
                </Button>        
              </Grid>
          </Grid>
        );
      }}
    />
  );
}
