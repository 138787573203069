import React from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

export default function LotusButton({
  color = 'primary',
  children,
  variant = 'contained',
  onClick,
  plusIcon,
  pencilIcon,
  garbageIcon,
  backgroundColor,
  closeIcon,
  ...props
}) {
  return (
    <Button
      {...props}
      onClick={onClick}
      variant={variant}
      color={color}
      startIcon={
        plusIcon ? (
          <AddIcon />
        ) : pencilIcon ? (
          <EditIcon />
        ) : garbageIcon ? (
          <DeleteIcon />
        ) : (
          ''
        )
      }
      endIcon={
        closeIcon ? <CloseIcon/> : ''
      }
    >
      {children}
    </Button>
  );
}