export const messageTypes = {
  LOADING_CARRIERS_FOR_PLAN_TYPE_SUCCESS: 'LOADING_CARRIERS_FOR_PLAN_TYPE_SUCCESS',
  LOADING_PLANS_FOR_PLAN_TYPE_AND_CARRIER_SUCCESS: 'LOADING_PLANS_FOR_PLAN_TYPE_AND_CARRIER_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_CARRIERS_FOR_PLAN_TYPE_SUCCESS:
      let newCarriers = {};
      Object.entries(state.healthPlanCarriersByPlanType || {}).forEach(([k, v]) => {
        newCarriers[k] = v;
      });
      newCarriers[payload.planType] = payload.carriers;
      return {
        ...state,
        healthPlanCarriersByPlanType: newCarriers,
      };
    case messageTypes.LOADING_PLANS_FOR_PLAN_TYPE_AND_CARRIER_SUCCESS:
      let newPlans = {};
      Object.entries(state.healthPlansByPlanTypeAndCarrier || {}).forEach(([k, v]) => {
        newPlans[k] = v;
      });
      if (payload.planType in newPlans === false) {
        newPlans[payload.planType] = {};
      }
      newPlans[payload.planType][payload.carrierName] = payload.plans;
      return {
        ...state,
        healthPlansByPlanTypeAndCarrier: newPlans,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
