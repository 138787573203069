import React, {useEffect, useState} from "react";
import LotusSelect from "components/widgets/Forms/LotusSelect";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import LotusSwitch from "components/widgets/Forms/LotusSwitch";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import { useClientDocumentation } from "contexts/ClientDocumentationContext";
import { useProgram } from "contexts/ProgramContext";
import { useClient } from "contexts/ClientContext";
import { useAuthState } from "contexts/AuthProvider";
import { useEnrollments } from "contexts/EnrollmentsContext";
import moment from "moment";
import LotusRadioGroup from "components/widgets/Forms/LotusRadioGroup";
import LotusForm from "components/widgets/Forms/LotusForm";
import LotusFormItems from "components/widgets/Forms/LotusFormItems";
import InlineEditableStaticForm from "components/Forms/InlineEditableStaticForm";

export default function ActivityLogForm({
  activityLog,
  readOnly = true,
  afterSave,
  handleCancel,
  activityLogDisplayName
}) {
  const { user: loggedInUser } = useAuthState();
  const { client } = useClient();
  const {
    currentProgram,
    currentProgramActivityLogTypes,
    currentProgramActivityLogStatuses,
    currentProgramActivityLogSubjects,
    currentProgramActivityLogWithOptions,
    currentProgramInterpretationServices,
    loadProgramActivityLogPicklists,
    loadProgramInterpretationServices
  } = useProgram();
  const { upsertActivityLog } = useClientDocumentation();
  const [initialValues, setInitialValues] = useState();
  const { enrolledPrograms, findEnrollment, loadEnrollmentsForClient } = useEnrollments();
  const [enrollment, setEnrollment] = useState();
  const [disallowEdit, setDisallowEdit] = useState();
  const [showMadeAttempted, setShowMadeAttempted] = useState(false);
  const [showContactFields, setShowContactFields] = useState(false);
  const [showWithOptions, setShowWithOptions] = useState(false);
  const [subjectsForType, setSubjectsForType] = useState(false);
  
  useEffect(() => {
    setDisallowEdit(readOnly);
  }, [readOnly]);

  useEffect(() => {
    if (client) {
      loadEnrollmentsForClient(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (enrolledPrograms && client) {
      const enrollment = findEnrollment(currentProgram.id);
      setEnrollment(enrollment);
    }
  }, [enrolledPrograms, client, currentProgram]);

  useEffect(() => {
    if (currentProgram) {
      loadProgramActivityLogPicklists();
      if (currentProgram.activityLogConfig.interpretationServiceEnabled) {
        loadProgramInterpretationServices(currentProgram.id);
      }
    }
  }, [currentProgram]);

  useEffect(() => {
    if (activityLog && currentProgramActivityLogSubjects && currentProgramActivityLogTypes) {
      const type = currentProgramActivityLogTypes.find(t => t.id === activityLog.activityLogTypeId);
      setSubjectsForType((type && currentProgramActivityLogSubjects.filter(s => s.typeId === type.id)) || []);
    }
  }, [activityLog, currentProgramActivityLogSubjects, currentProgramActivityLogTypes]);

  useEffect(() => {
    if (activityLog && currentProgramActivityLogTypes) {
      const typ = currentProgramActivityLogTypes && currentProgramActivityLogTypes.find(t => t.id === activityLog.activityLogTypeId);
      setShowMadeAttempted(typ?.isMadeAttemptedEnabled);
      setShowContactFields(typ?.contactFieldsEnabled);
      setShowWithOptions(typ?.activityWithEnabled);
    }
  }, [activityLog, currentProgramActivityLogTypes]);

  useEffect(() => {
    if (loggedInUser && enrollment && client && currentProgram) {
      let initialValue = activityLog ?
      {
        id: activityLog.id,
        clientId: activityLog.clientId,
        programId: activityLog.programId,
        activityLogStatusId: activityLog.activityLogStatusId || '',
        recordedDate: activityLog.recordedDate ? activityLog.recordedDate : null,
        durationMinutes: activityLog.durationMinutes || '',
        activityLogTypeId: activityLog.activityLogTypeId || '',
        activityLogSubjectId: activityLog.activityLogSubjectId || '',
        activityLogWithOptionId: activityLog.activityLogWithOptionId || '',
        notes: activityLog.notes || '',
        conductorAccountId: activityLog.conductorAccountId || '',
        conductorName: activityLog.conductorName || '',
        interpretationServiceUsed: activityLog.interpretationServiceUsed || false,
        programInterpretationServiceId: activityLog.programInterpretationServiceId || '',
        coordinatorAccountId: activityLog.coordinatorAccountId || '',
        coordinatorName: activityLog.coordinatorName || '',
        madeOrAttempted: activityLog.madeOrAttempted || '',
        contactFields: activityLog.contactFields || {name: '', phone: ''}
      } :
      {
        clientId: client.id,
        programId: currentProgram.id,
        activityLogStatusId: '',
        recordedDate: moment().format('MM/DD/YYYY'),
        durationMinutes: '',
        activityLogTypeId: '',
        activityLogSubjectId: '',
        activityLogWithOptionId: '',
        notes: '',
        conductorAccountId: loggedInUser.id,
        conductorName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
        interpretationServiceUsed: false,
        programInterpretationServiceId: '',
        coordinatorAccountId: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserId : '',
        coordinatorName: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserName : '',
        madeOrAttempted: '',
        contactFields: {name: '', phone: ''}
      };
      setInitialValues(initialValue);
    }
  }, [activityLog, loggedInUser, enrollment, client, currentProgram]);

  const onSubmit = async (newValues) => {
    if (!disallowEdit) {
      await upsertActivityLog(newValues);
    }

    if (afterSave) {
      await afterSave(newValues);
    }
  }

  function buildFormContent(disallowEdit, formValues, formHelpers) {
    const handleTypeChange = (newValue) => {
      const typ = currentProgramActivityLogTypes && currentProgramActivityLogTypes.find(t => t.id === newValue);
      setShowMadeAttempted(typ?.isMadeAttemptedEnabled);
      setShowContactFields(typ?.contactFieldsEnabled);
      setShowWithOptions(typ?.activityWithEnabled);
      formHelpers.setFieldValue('activityLogSubjectId', '');
      setSubjectsForType((typ && currentProgramActivityLogSubjects && currentProgramActivityLogSubjects.filter(s => s.typeId === typ.id)) || []);
      setTimeout(() => formHelpers.validateForm());
    }
    const handleMinutesChange = (e) => {
      let min = Number(e.target.value);
      const interval = currentProgram?.activityLogConfig.minutesRoundingInterval;
      if (min && interval) {
        const rounded = Math.ceil(min/interval)*interval;
        formHelpers.setFieldValue('durationMinutes', rounded);
      }
    }

    return (
      <LotusForm>
        <LotusFormItems>
          {currentProgram.activityLogConfig.statusEnabled && currentProgramActivityLogStatuses &&
            <LotusSelect
              disabled={disallowEdit}
              name='activityLogStatusId'
              required
              label='Status'
              items={currentProgramActivityLogStatuses.map(s => {return {label: s.name, value: s.id}})}
            />   
          } 
          <LotusDatePicker
            disabled={disallowEdit}
            name="recordedDate"
            label="Date"
            required
          />
          {currentProgram.activityLogConfig.minutesEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='durationMinutes'
              label='Minutes'
              required
              integer
              onBlur={handleMinutesChange}
            /> 
          }
          {currentProgramActivityLogTypes &&
            <LotusSelect
              disabled={disallowEdit}
              name='activityLogTypeId'
              required
              label='Type'
              items={currentProgramActivityLogTypes.map(s => {return {label: s.name, value: s.id}})}
              handleChange={handleTypeChange}
            />   
          } 
          {showContactFields && formValues.contactFields &&
            <LotusTextInput
              name='contactFields.name'
              disabled={disallowEdit}
              label='Contact Name'
            />
          }
          {showContactFields && formValues.contactFields &&
            <LotusTextInput
              name='contactFields.phone'
              disabled={disallowEdit}
              label='Contact Phone'
            />
          }
          {showMadeAttempted &&
            <LotusRadioGroup
              name="madeOrAttempted"
              label="Made / Attempted"
              disabled={disallowEdit}
              items={[
                {
                  label: 'Made',
                  value: 'Made',
                },
                {
                  label: 'Attempted',
                  value: 'Attempted',
                },
              ]}
            />
          }
          {currentProgram.activityLogConfig.subjectEnabled && subjectsForType && subjectsForType.length > 0 &&
            <LotusSelect
              disabled={disallowEdit}
              name='activityLogSubjectId'
              required
              label='Subject'
              items={subjectsForType && subjectsForType.map(s => {return {label: s.name, value: s.id}})}
            />    
          } 
          {showWithOptions && currentProgramActivityLogWithOptions &&
            <LotusSelect
              disabled={disallowEdit}
              name='activityLogWithOptionId'
              label='Activity With'
              items={currentProgramActivityLogWithOptions.map(s => {return {label: s.name, value: s.id}})}
            />   
          } 
          <LotusTextInput
            disabled={disallowEdit}
            name="notes"
            multiline
            rows={5}
            label="Description"
          />
          {currentProgram.activityLogConfig.conductedByEnabled && 
            <LotusTextInput
              disabled={true}
              name='conductorName'
              label='Conducted By'
            />
          }
          {currentProgram.activityLogConfig.isInterpretationServiceUsedEnabled && 
            <LotusSwitch
              name="interpretationServiceUsed"
              label="Interpretation Service Used?"
              labelPlacement="start"
              disabled={disallowEdit}
            />
          }
          {formValues.interpretationServiceUsed && currentProgram.activityLogConfig.interpretationServiceEnabled && currentProgramInterpretationServices &&
            <LotusSelect
              disabled={disallowEdit}
              name='programInterpretationServiceId'
              label='Interpretation Service'
              items={currentProgramInterpretationServices.map(s => {return {label: s.name, value: s.id}})}
            />   
          } 
          {currentProgram.activityLogConfig.coordinatorEnabled && 
            <LotusTextInput
              disabled={true}
              name='coordinatorName'
              label='Coordinator'
            /> 
          }
        </LotusFormItems>
      </LotusForm>
    );
  }

  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(activityLog ? 'Edit' : 'Add')} ${activityLogDisplayName}`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      buildFormContent={buildFormContent}
    />
  );
}
