import React from 'react';
import { useField } from 'formik';
import { Stack, Typography } from '@mui/material';
import LotusStackedFormItems from '../LotusStackedFormItems';
import LotusAddButton from 'components/button/common/LotusAddButton';
import LotusCheckbox from '../checkbox/LotusCheckbox';
import LotusTextInput from '../inputField/common/LotusTextInput';
const { v4: uuidv4 } = require('uuid');

export default function LotusGenericListLinker({name, title, itemName}) {
  
  const [field, meta, utils] = useField(name);

  const addListItem = () => {
    const lst = [...field.value];
    lst.push({id: uuidv4(), name: '', active: true});
    utils.setValue(lst);
  };

  return (
    <LotusStackedFormItems>
      <div>
        <Typography variant="body1">{title}</Typography>
      </div>
      <div style={{width: 500}}>
        <LotusStackedFormItems>
        {field.value.length > 0 && field.value.map((itm, index) => {
          return (
            <Stack direction="row" spacing={1} key={index}>
              <div>
              <LotusCheckbox
                name={`${name}.${index}.active`}
                checked={itm.active}
              />
              </div>
              <LotusTextInput
                name={`${name}.${index}.name`}
                required
                disabled={itm.readOnly ? itm.readOnly : false}
              />
            </Stack>
          );
        })}
        </LotusStackedFormItems>
      </div>
      <div style={{width: 'fit-content'}}>
        <LotusAddButton onClick={addListItem} itemLabel={itemName} />
      </div>
    </LotusStackedFormItems>
  );
}
