import React, { useEffect, useState } from 'react';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function AgencyLanguageSelector({
  label,
  ...props
}) {
  const { userAgency, userAgencyLanguages, loadUserAgencyLanguages } = useUserAgency();
  const [languageList, setLanguageList] = useState();

  useEffect(() => {
    if (userAgency && !userAgencyLanguages) {
      loadUserAgencyLanguages();
    }
  }, [userAgency, userAgencyLanguages]);

  useEffect(() => {
    if (userAgencyLanguages) {
      const lst = userAgencyLanguages.map(l => {return {label: l.name, value: l.languageId}});
      setLanguageList(lst);
    }
  }, [userAgencyLanguages]);

  return (
    languageList ? <LotusSelect {...props} label={label} items={languageList || []}/> : <div></div>
  );
}
