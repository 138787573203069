import React from 'react';
import { FieldArray, useField } from 'formik';
import { Stack, Typography } from '@mui/material';
import LotusCardList from 'components/dataDisplay/card/LotusCardList';
import LotusCard from 'components/dataDisplay/card/LotusCard';
import LotusAddButton from 'components/button/common/LotusAddButton';
import LotusRemoveButton from 'components/button/common/LotusRemoveButton';
import { useEffect } from 'react';

export default function LotusItemList({
  name,
  label,
  itemLabel,
  disabled,
  readOnly,
  dontCheckRequiredFields,
  buildItemDetail,
  buildItemTemplate,
  allowRemove=true,
  required,
  onRemove,
  isSubList
}) {
  const [field, meta, utils] = useField(name);

  useEffect(() => {
    if (required && (!field.value || field.value.length === 0)) {
      utils.setValue([buildItemTemplate()]);
    }
  }, [field.value]);

  return (
    <FieldArray 
      name={name}
      render={arrayHelpers => {
        
        const addItem = () => {
          const newItem = buildItemTemplate();
          arrayHelpers.push(newItem);
        };

        const removeItem = (index) => {
          if (onRemove) {
            onRemove(index);
          }
          arrayHelpers.remove(index);
        };

        return (
          <Stack direction="column" spacing={2}>
            {label &&
              <Typography variant={`${isSubList ? "body2" : "body1"}`}>{label}</Typography>
            }
            {label && field.value && field.value.length === 0 &&
              <Typography variant="body1">None</Typography>
            }
            {field.value && field.value.length > 0 &&
              <>
                <LotusCardList>
                  {field.value.map((item, index) => {
                    return (
                      <LotusCard key={index}>
                        <Stack direction="column" spacing={2}>
                          <div>
                            {buildItemDetail(item, index, disabled, readOnly, dontCheckRequiredFields)}
                          </div>
                          {!disabled && !readOnly && allowRemove && (!required || field.value.length > 1) &&
                            <Stack direction="horizontal" justifyContent="flex-start">
                              <LotusRemoveButton onClick={() => removeItem(index)}/>
                            </Stack>
                          }
                        </Stack>
                      </LotusCard>
                    );
                  })}
                </LotusCardList>
                
              </>
            }
            {!readOnly &&
              <Stack direction="horizontal" justifyContent="flex-start">
                <LotusAddButton disabled={disabled} onClick={addItem} itemLabel={itemLabel}/>      
              </Stack> 
            }
          </Stack>
        );
      }}
    />
  );
}
