import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import { useEffect, useState } from "react";
import EligibilityConfigView from "./EligibilityConfigView";
import * as yup from 'yup';

export function GranteeAgencyEligibilityView({
  eligibilityConfig,
  initialEligibilityStartDateList,
  annualCoveragePeriodList,
  eligibilityStartDateDayList,
  childAgenciesList,
  agencyLevelConsents,
  hivDiseaseStageList,
  medicationAssistanceLevelList,
  premiumAssistanceCoverageTypeList,
  eligibilityApplicationUrgencyTypes,
  adapServiceDenialReasons,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (eligibilityConfig) {
      const newInitialValues = {
        application: {
          // Applicant - Basic Information
          showPreferredName: eligibilityConfig.application.showPreferredName || false,
          // Applicant - Gender
          showGenderPronouns: eligibilityConfig.application.showGenderPronouns || false,
          // Applicant - Phone and Email
          showCommunicationNotes: eligibilityConfig.application.showCommunicationNotes || false,
          // Applicant - Language
          showSecondaryLanguage: eligibilityConfig.application.showSecondaryLanguage || false,
          showPreferredWrittenCommLanguageId: eligibilityConfig.application.showPreferredWrittenCommLanguageId || false,
          // Applicant - Other Demographics
          showLawfulResidentDate: eligibilityConfig.application.showLawfulResidentDate || false,
          showCitizenshipStatus: eligibilityConfig.application.showCitizenshipStatus || false,
          showIsTobaccoUser: eligibilityConfig.application.showIsTobaccoUser || false,
          // Applicant - Urgent Application
          showIsUrgentApplication: eligibilityConfig.application.showIsUrgentApplication || false,
          selectedEligibilityApplicationUrgencyTypes: eligibilityConfig.application.selectedEligibilityApplicationUrgencyTypes || [],
          // Eligibility Timeline - initial application start date type
          selectedInitialEligibilityStartDateType: eligibilityConfig.application.selectedInitialEligibilityStartDateType || '',
          // Eligibility Timeline - annual coverage period
          selectedAnnualCoveragePeriodType: eligibilityConfig.application.selectedAnnualCoveragePeriodType || '',
          selectedEligibilityStartDateMonth: eligibilityConfig.application.selectedEligibilityStartDateMonth || '',
          selectedEligibilityStartDateDay: eligibilityConfig.application.selectedEligibilityStartDateDay || '',
          selectedInitialExtensionType: eligibilityConfig.application.selectedInitialExtensionType || '',
          selectedExtensionMonths: eligibilityConfig.application.selectedExtensionMonths || '',
          // Recertification - time before recertification (days)
          selectedTimeBeforeRecert: eligibilityConfig.application.selectedTimeBeforeRecert || 1,
          expirationDaysForStartedButUnsubmittedApps: eligibilityConfig.application.expirationDaysForStartedButUnsubmittedApps || '',
          expirationDaysForPendingApps: eligibilityConfig.application.expirationDaysForPendingApps || '',
          selectedAssistanceProgramName: eligibilityConfig.application.selectedAssistanceProgramName || 'ADAP',
          // Addresses - Secondary Address
          showSecondaryAddress: eligibilityConfig.application.showSecondaryAddress || false,
          // Health
          showGeneralHealthInfo: eligibilityConfig.application.showGeneralHealthInfo || false,
          showCurrentArvCount: eligibilityConfig.application.showCurrentArvCount || false,
          showCd4AndViralLoads: eligibilityConfig.application.showCd4AndViralLoads || false,
          // Insurance
          showPrivateInsurancePremiumOverdueAmount: eligibilityConfig.application.showPrivateInsurancePremiumOverdueAmount || false,
          showPrivateInsurancePremiumOverdueMonths: eligibilityConfig.application.showPrivateInsurancePremiumOverdueMonths || false,
          showMedicarePremiumOverdueAmount: eligibilityConfig.application.showMedicarePremiumOverdueAmount || false,
          showMedicarePremiumOverdueMonths: eligibilityConfig.application.showMedicarePremiumOverdueMonths || false,
          
          // ADAP
          allowMultipleAdapSubprogramEnrollments: eligibilityConfig.application.allowMultipleAdapSubprogramEnrollments || false,
          adapSubprograms: eligibilityConfig.application.adapSubprograms || [],
          selectedAdapServiceDenialReasons: eligibilityConfig.application.selectedAdapServiceDenialReasons || [],
          // Select Application List - Care
          selectedCareApplicationType: eligibilityConfig.application.selectedCareApplicationType || 'rw_only',
          agencyGroups: eligibilityConfig.application.agencyGroups || [],
          // Select Application List - ADAP
          selectedADAPApplicationType: eligibilityConfig.application.selectedADAPApplicationType || 'all_subprograms',
          requiredConsents: eligibilityConfig.application.requiredConsents || [],
          // Additional Information
          showAdditionalAssistanceInterests: eligibilityConfig.application.showAdditionalAssistanceInterests || false,
        },
        rwScoring: {
          fplLimit: eligibilityConfig.rwScoring.fplLimit || '',
          state: eligibilityConfig.rwScoring.state || '',
          diseaseStages: eligibilityConfig.rwScoring.diseaseStages || []
        }
      };
      setInitialValues(newInitialValues);
    }
  }, [eligibilityConfig]);

  const validationSchema = yup.object({
    application: yup.object().shape({
      adapSubprograms: yup.array().of(
        yup.object().shape({
          name: yup.string().test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              if (ctx.from[1].value.adapSubprograms.filter(a => a.name === value).length > 1) {
                return ctx.createError({message: 'Name must be unique across subprograms'});
              }
              return true;
            }
          }),
          supportsMedicationAssistance: yup.bool().test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              let subprogram = ctx.parent;
              if (!(subprogram.supportsMedicationAssistance || subprogram.supportsPremiumAssistance)) {
                return ctx.createError({message: 'Subprogram must support at least one form of assistance'});
              }
              return true;
            }
          }),
          supportsPremiumAssistance: yup.bool().test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              let subprogram = ctx.parent;
              if (!(subprogram.supportsMedicationAssistance || subprogram.supportsPremiumAssistance)) {
                return ctx.createError({message: 'Subprogram must support at least one form of assistance'});
              }
              return true;
            }
          }),
        })
      ),
      agencyGroups: yup.array().of(
        yup.object().shape({
          name: yup.string().test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              if (ctx.from[1].value.agencyGroups.filter(a => a.name === value).length > 1) {
                return ctx.createError({message: 'Name must be unique across agency groups'});
              }
              return true;
            }
          }),
          selectedAgencies: yup.array().test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              let agencyGroups = ctx.parent;
              if (agencyGroups.selectedAgencies.length === 0) {
                return ctx.createError({message: 'At least one agency must be selected'});
              } else {
                const selectedAgencies = ctx.from[1].value.agencyGroups.map(a => a.selectedAgencies);
                let dupAgencies;
                for (let i = 1; i < selectedAgencies.length; i++) {
                  dupAgencies = selectedAgencies[i - 1].filter(val => selectedAgencies[i].indexOf(val) !== -1)
                }
                if(dupAgencies && dupAgencies.length > 0) {
                  return ctx.createError({message: 'Subagencies can only be applied once for all groups'});
                }
              }
              return true;
            }
          })
        })
      )
    })
  });

  return initialValues && (
    <LotusStaticForm
      title="Eligibility"
      initialFormValues={initialValues}
      validationSchema={validationSchema}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 600}}>
        <EligibilityConfigView
          initialEligibilityStartDateList={initialEligibilityStartDateList}
          annualCoveragePeriodList={annualCoveragePeriodList}
          eligibilityStartDateDayList={eligibilityStartDateDayList}
          childAgenciesList={childAgenciesList}
          agencyLevelConsents={agencyLevelConsents}
          hivDiseaseStageList={hivDiseaseStageList}
          medicationAssistanceLevelList={medicationAssistanceLevelList}
          premiumAssistanceCoverageTypeList={premiumAssistanceCoverageTypeList}
          adapServiceDenialReasons={adapServiceDenialReasons}
          eligibilityApplicationUrgencyTypes={eligibilityApplicationUrgencyTypes}
        />
      </div>
    </LotusStaticForm>
  );
}