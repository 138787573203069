import axios from 'axiosInterceptor';
import AddressFieldPanelWithLocationSearch from 'components/composites/AddressFieldPanel/AddressFieldPanelWithLocationSearch';
import LotusSubsectionContent from 'components/dataDisplay/content/LotusSubsectionContent';
import LotusPhoneInput from 'components/form/inputField/common/LotusPhoneInput';
import LotusTextInput from 'components/form/inputField/common/LotusTextInput';
import LotusStackedFormItems from 'components/form/LotusStackedFormItems';
import LotusStaticForm from 'components/form/staticForm/LotusStaticForm';
import LotusSubsectionHeader from 'components/headers/LotusSubsectionHeader';
import { useAgencyRequests } from 'contexts/AgencyRequestsContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { normalizeData } from 'lib/utils';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function AddAgency() {

  const { userAgency } = useUserAgency();
  const { createAgencyRequest } = useAgencyRequests();
  const navigate = useNavigate();

  const dupChecker = debounce(async (agencyValues) => {
    if (agencyValues) {
      try {
        const { data } = await axios.post('/api/agencies', {...agencyValues, operationName: 'checkForDuplicates'} );
        return normalizeData(data).body.duplicates.length === 0;
      } catch (error) {
        return false;
      }
    } else {
      return true;
    }
  }, 500);

  const handleCreateAgency = async (agencyData) => {
    await createAgencyRequest(agencyData);
    navigate('/agencies/requests');
  };

  return (
    <AddAgencyView
      granteeAgencyId={userAgency.isGranteeAgency ? userAgency.granteeAgencyId : null}
      handleCreateAgency={handleCreateAgency}
      duplicateChecker={dupChecker}
      handleClose={() => navigate('/agencies/requests')}
    />
  );
}

export function AddAgencyView({
  granteeAgencyId,
  handleCreateAgency,
  duplicateChecker,
  handleClose,
  useSeedData
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues({
      name: '',
      shortName: '',
      mailingAddress: {
        address1: '', 
        address2: '', 
        city: '', 
        state: '', 
        zip: '',
        county: ''
      },
      email: '',
      phoneNumber: '',
      faxNumber: '',
      granteeAgencyId: granteeAgencyId || null
    });
  }, []);

  return (
    <LotusStaticForm
      title="Request New Agency"
      initialFormValues={initialValues}
      handleSave={handleCreateAgency}
      handleCancel={handleClose}
      validationFunc={async (values) => {
        if (
          values.name.trim() ||
          values.mailingAddress.address1.trim() ||
          values.mailingAddress.address2.trim() ||
          values.mailingAddress.city.trim() ||
          values.mailingAddress.state.trim() ||
          values.mailingAddress.zip.trim()
        ) {
          const ok = await duplicateChecker({
            name: values.name,
            address: {
              address1: values.mailingAddress.address1,
              address2: values.mailingAddress.address2,
              city: values.mailingAddress.city,
              state: values.mailingAddress.state,
              zip: values.mailingAddress.zip
            },
          });
          // this seems to be undefined the first time
          if (ok === false) {
            return {
              name: 'Duplicate agency found',
            };
          } else {
            return {};
          }
        }
      }}
    >
      <div style={{width: 500}}>
        <LotusSubsectionContent excludeTopPadding>
          <LotusStackedFormItems>
            <LotusTextInput
              name="name"
              required
              label="Agency Name"
            />
            <LotusTextInput
              name="shortName"
              label="Agency Short Name"
            />
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Mailing Address"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <AddressFieldPanelWithLocationSearch
              name='mailingAddress'
              required 
              useSeedData={useSeedData}
            />
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="General Agency Contact Information"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusTextInput
              name="email"
              label="Email Address"
              required
            />
            <LotusPhoneInput
              name="phoneNumber"
              label="Phone Number"
              required
            />
            <LotusPhoneInput
              name="faxNumber"
              label="Fax Number"
            />
          </LotusStackedFormItems>
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
    
  );
}