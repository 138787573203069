import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import { Button } from "@lotus/components";
import LotusSelect from "components/widgets/Forms/LotusSelect";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import LotusFormSection from "components/widgets/Forms/LotusFormSection";
import LotusFormItem from "components/widgets/Forms/LotusFormItem";
import moment from 'moment';
import { useAuthState } from 'contexts/AuthProvider';
import { useLists } from "contexts/ListsContext";
import { CONSTANTS } from "./ClientServicesEnums";
import { useClient } from "contexts/ClientContext";
import ClientServicesDocumentation from "./ClientServicesDocumentation";

export default function ClientLabResultViewer({
  labResultId,
  modalType,
  editDisabled,
  entryMode = CONSTANTS.IMPORT_ENTRY_MODE,
  clientId,
  labResultObj,
  testResultConfig,
  handleCancel,
  handleSave
}) {
  
  const { clientLabResultDocuments, loadClientLabResultDocuments } = useClient();
  const { user: loggedInUser } = useAuthState();
  const disabled = modalType === CONSTANTS.VIEW_MODAL_TYPE && labResultObj && labResultObj?.entryMode.toLowerCase() !== CONSTANTS.MANUAL_ENTRY_MODE;
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const [submitBtnState, setSubmitBtnState] = useState();
  const [initialValues, setInitialValues] = useState();
  const [headerLabel, setHeaderLabel] = useState();
  const { clientServicesDataDeletionReasons } = useLists();
  const [deletionReasonList, setDeletionReasonList] = useState();

  useEffect(() => {
    if(labResultId && entryMode === CONSTANTS.MANUAL_ENTRY_MODE && modalType !== CONSTANTS.ADD_MODAL_TYPE) {
      loadClientLabResultDocuments(clientId, labResultId);
    }
  }, [labResultId]);

  useEffect(() => {
    if (clientServicesDataDeletionReasons) {
      setDeletionReasonList(clientServicesDataDeletionReasons.map(r => {return {value: r.id, label: r.itemName}}));
    }
  }, [clientServicesDataDeletionReasons]);

  useEffect(() => {
    if(modalType === CONSTANTS.ADD_MODAL_TYPE) {
      setHeaderLabel('Add');
    }
    else if(entryMode === CONSTANTS.MANUAL_ENTRY_MODE) {
      setHeaderLabel('Edit');
    } else {
      setHeaderLabel('');
    }
  }, [modalType]);
  
  useEffect(() => {
    if (labResultObj && testResultConfig) {
      setInitialValues({
        completedDateTime: labResultObj.completedDateTime || null,
        testName: labResultObj.testName || '',
        claTestResultModifier: labResultObj.claTestResultModifier || '',
        testMeasure: labResultObj.testMeasure || '',
        testResult: labResultObj.testResult || '',
        entryMode: labResultObj.entryMode ? labResultObj.entryMode.charAt(0).toUpperCase() + labResultObj.entryMode.substring(1) : 'Manual',
        testTech: testResultConfig?.enabled && testResultConfig?.basicConfig?.testTech ? labResultObj.testTech || '' : '',
        testCompletedTime: testResultConfig?.enabled && testResultConfig?.basicConfig?.testCompletedTime ? labResultObj.testCompletedTime || '' : '',
        testStatus: testResultConfig?.enabled && testResultConfig?.basicConfig?.testStatus ? labResultObj.testStatus || '' : '',
        testResultType: testResultConfig?.enabled && testResultConfig?.basicConfig?.testResultType ? labResultObj.testResultType || '' : '',
        testResultText: testResultConfig?.enabled && testResultConfig?.basicConfig?.testResultText ? labResultObj.testResultText || '' : '',
        testResultNumeric: testResultConfig?.enabled && testResultConfig?.basicConfig?.testResultNumeric ? labResultObj.testResultNumeric || '' : '',
        testResultKeyword: testResultConfig?.enabled && testResultConfig?.basicConfig?.testResultKeyword ? labResultObj.testResultKeyword || '' : '',
        testReferenceId: testResultConfig?.enabled && testResultConfig?.basicConfig?.testReferenceId ? labResultObj.testReferenceId || '' : '',
        testSource: testResultConfig?.enabled && testResultConfig?.basicConfig?.testSource ? labResultObj.testSource || '' : '',
        testFacility: testResultConfig?.enabled && testResultConfig?.basicConfig?.testFacility ? labResultObj.testFacility || '' : '',
        testCompletedBy: testResultConfig?.enabled && testResultConfig?.basicConfig?.testCompletedBy ? labResultObj.testCompletedBy || '' : '',
        normalcyStatus: testResultConfig?.enabled && testResultConfig?.basicConfig?.normalcyStatus ? labResultObj.normalcyStatus || '' : '',
        comments: testResultConfig?.enabled && testResultConfig?.basicConfig?.comments ? labResultObj.comments || '' : '',
        labIdentifiedDob: testResultConfig?.enabled && testResultConfig?.hl7Config?.labIdentifiedDob ? labResultObj.labIdentifiedDob || '' : '',
        labIdentifiedGender: testResultConfig?.enabled && testResultConfig?.hl7Config?.labIdentifiedGender ? labResultObj.labIdentifiedGender || '' : '',
        labIdentifiedLoinc: testResultConfig?.enabled && testResultConfig?.hl7Config?.labIdentifiedLoinc ? labResultObj.labIdentifiedLoinc || '' : '',
        labIdentifiedLoincDesc: testResultConfig?.enabled && testResultConfig?.hl7Config?.labIdentifiedLoincDesc ? labResultObj.labIdentifiedLoincDesc || '' : '',
        testCptCode: testResultConfig?.enabled && testResultConfig?.hl7Config?.testCptCode ? labResultObj.testCptCode || '' : '',
        referenceRange: testResultConfig?.enabled && testResultConfig?.hl7Config?.referenceRange ? labResultObj.referenceRange || '' : '',
        labPanelId: testResultConfig?.enabled && testResultConfig?.hl7Config?.labPanelId ? labResultObj.labPanelId || '' : '',
        labResultDocumentation: ((modalType === CONSTANTS.ADD_MODAL_TYPE || entryMode !== CONSTANTS.MANUAL_ENTRY_MODE) ? [] : clientLabResultDocuments) || [],
        deletionReasonId: '',
        deletionComments: ''
      });
    }
  }, [labResultObj, testResultConfig, clientLabResultDocuments]);

  return (
     initialValues &&
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (newValues, actions) => {
          await handleSave(newValues, submitBtnState);
          actions.setSubmitting(false);
        }}
      >
        {({values, touched, errors, handleSubmit}) => {
          return (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey"}}>
                  <Grid item xs={6}>
                    <Typography variant="h2">{headerLabel} Lab Result</Typography>
                    {deleteEnabled && 
                    <>
                      <Typography variant="body">Deletion Date: {moment().format('MMM Do, YYYY')}</Typography>
                      <Typography variant="body" style={{marginLeft: 10, marginRight: 10}}>|</Typography>
                      <Typography variant="body">By: {loggedInUser?.data?.firstName + ' ' + loggedInUser?.data?.lastName }</Typography>
                    </>
                    }
                  </Grid>
                  {!disabled && modalType === CONSTANTS.ADD_MODAL_TYPE && !deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button onClick={() => {setSubmitBtnState("add");handleSubmit(values);}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Save</Typography>
                      </Button>
                    </Grid>
                  }
                  {!disabled && modalType === CONSTANTS.VIEW_MODAL_TYPE && entryMode === CONSTANTS.MANUAL_ENTRY_MODE && !deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button variant="text" onClick={() => {setDeleteEnabled(true);setHeaderLabel('Delete')}} style={{marginRight: 10, border: editDisabled ? '1px solid #7A7A7A66' : '1px solid red'}} disabled={editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5, color: editDisabled ? "" :"red"}}>Delete</Typography>
                      </Button>
                      <Button onClick={() => {setSubmitBtnState("edit");handleSubmit(values);}} disabled={editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Save</Typography>
                      </Button>
                    </Grid>
                  }
                  {deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {setDeleteEnabled(false);handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button variant="contained" color="error" onClick={() => {setSubmitBtnState("delete");handleSubmit(values);}} style={{marginRight: 10}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5, color: "#fff"}}>Delete</Typography>
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid item xs={12} style={{marginTop: 20, marginBottom: 24}}>
                  <LotusDatePicker
                    name="completedDateTime"
                    label={"Date Completed"}
                    maxDate={moment().format('MM/DD/YYYY')}
                    required
                    style={{width: 200}}
                    value={values.completedDateTime}
                    error={touched.completedDateTime && Boolean(errors.completedDateTime)}
                    disabled={disabled || editDisabled}
                  />
                </Grid>
                <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                  <LotusSelect
                    name="testName"
                    label="Test Name"
                    items={CONSTANTS.TEST_NAME_OPTIONS}
                    required
                    disabled={disabled || editDisabled}
                  />
                </Grid>
                <Grid item xs={5} style={{marginBottom: 24}}>
                  <LotusSelect
                    name="claTestResultModifier"
                    label="Test Result Modifier"
                    items={CONSTANTS.TEST_RESULT_MODIFIER_OPTIONS}
                    required
                    disabled={disabled || editDisabled}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                  <LotusSelect
                      name="testMeasure"
                      label="Test Measure"
                      items={CONSTANTS.TEST_MEASURE_OPTIONS}
                      required
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={5} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="testResult"
                      label="Test Result"
                      required
                      floatingPoint={true}
                      maxLength={750}
                      disabled={disabled || editDisabled}
                    />
                </Grid>
              </Grid>
              {testResultConfig && testResultConfig?.enabled &&
              <Grid container>
                {testResultConfig?.basicConfig?.testTech &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusSelect
                        name="testTech"
                        label="Test Technology"
                        items={CONSTANTS.TEST_TECH_OPTIONS}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testCompletedTime &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testCompletedTime"
                        label="Test Completed Time"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {testResultConfig?.basicConfig?.testStatus &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                      <LotusSelect
                        name="testStatus"
                        label="Test Status"
                        items={CONSTANTS.TEST_STATUS_OPTIONS}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testResultType &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testResultType"
                        label="Test Result Type"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testResultText &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testResultText"
                        label="Test Result Text"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testResultNumeric &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testResultNumeric"
                        label="Test Result Numeric"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testResultKeyword &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testResultKeyword"
                        label="Test Result Keyword"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testReferenceId &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testReferenceId"
                        label="Test Reference ID"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testSource &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testSource"
                        label="Test Source"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testFacility &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testFacility"
                        label="Test Facility"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.testCompletedBy &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testCompletedBy"
                        label="Test Completed By"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {testResultConfig?.basicConfig?.normalcyStatus &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="normalcyStatus"
                        label="Normalcy Status"
                        maxLength={10}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.basicConfig?.comments &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="comments"
                        label="Comments"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.labIdentifiedDob &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="labIdentifiedDob"
                        label="Lab Identified Date Of Birth"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.labIdentifiedGender &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="labIdentifiedGender"
                        label="Lab Identified Gender"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.labIdentifiedLoinc &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="labIdentifiedLoinc"
                        label="Lab Identified LOINC"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.labIdentifiedLoincDesc &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="labIdentifiedLoincDesc"
                        label="Lab Identified LOINC Description"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.testCptCode &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="testCptCode"
                        label="Test CPT Code"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.referenceRange &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="referenceRange"
                        label="Reference Range"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && testResultConfig?.hl7Config?.labPanelId &&
                  <Grid item xs={5} style={{marginRight: 10, marginBottom: 24}}>
                    <LotusTextInput
                        name="labPanelId"
                        label="Lab Panel ID"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
            </Grid>
            }
              <Grid container>
                <Grid item xs={6} style={{marginBottom: 24}}>
                  <LotusTextInput
                    name="entryMode"
                    label="Entry Mode"
                    disabled={true}
                  />
                </Grid>
              </Grid>
              {deleteEnabled && 
                <Grid container>
                  <Grid item xs={6} style={{marginBottom: 24}}>
                    {deletionReasonList &&
                      <LotusSelect
                        name="deletionReasonId"
                        label="Reason for Deletion"
                        items={deletionReasonList}
                        required
                        disabled={disabled || editDisabled}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 24}}>
                    <LotusTextInput
                      name="deletionComments"
                      multiline
                      minRows={5}
                      label="Comments"
                      disabled={disabled || editDisabled}
                    />
                  </Grid>
                </Grid>
              }
              {!disabled && labResultObj &&
                <Grid container>
                  <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusFormSection name="Documentation">
                    <LotusFormItem>
                      <ClientServicesDocumentation
                        clientId={clientId}
                        documentationListFieldName="labResultDocumentation"
                        disabled={disabled || editDisabled}
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  </Grid>
                </Grid>
              }
            </Grid> 
          );
        }}
      </Formik>
    </Grid>) || <div></div>
}