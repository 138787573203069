import React from 'react';
import { formatActionForAccountActionRequest } from 'lib/formatting';
import Typography from '@mui/material/Typography';

function ApproveUserRequestForm({ actionRequest }) {
  return actionRequest ? (
        <Typography variant="body1">
          <span>
            {formatActionForAccountActionRequest('Approve', actionRequest)}
          </span>
        </Typography>
  ) : (
    ''
  );
}

export default ApproveUserRequestForm;
