import React, { useEffect } from 'react';
import Consents from 'components/Consents';
import { useClient } from 'contexts/ClientContext';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { Alert } from '@mui/material';

export const ClientConsentsTab = ({ client, disabled, updateRestrictions }) => {

  const {clientConsents, loadClientConsents, insertClientConsents} = useClient();

  useEffect(() => {
    if (client) {
      loadClientConsents(client.id);
    }
  }, [client]);

  const onConsentAdded = async (consent) => {
    await insertClientConsents(client.id, [consent]);
  };

  return (
    <LotusPageSection header="Consents">
      {updateRestrictions && updateRestrictions.all &&
      <LotusFormSection>
        <LotusFormItem>
          <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
        </LotusFormItem>
      </LotusFormSection>
      }
      <Consents
        consents={clientConsents}
        consentTypeIds={[]}
        onConsentAdded={onConsentAdded}
        disabled={disabled || updateRestrictions?.all}
      />
    </LotusPageSection>
  );
};
