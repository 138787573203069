import React from 'react';
import { useField } from 'formik';
import Switch from '@mui/material/Switch';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';
import { Typography } from '@mui/material';

// Either specify trueText/falseText for dual labels or label/labelPlacement for a single label
export default function LotusSwitch({
  trueText,
  falseText,
  labelPlacement = 'end',
  label,
  ...props
}) {
  const [field, meta] = useField(props);

  let myLabel = label;
  if (trueText || falseText) {
    myLabel = field.value ? trueText : falseText;
  }

  return (
    !props.disabled ? 
    <>
    {label && labelPlacement === 'start' &&
    <span style={{marginRight: 10}}>{label}</span>
    }
    {falseText &&
    <span style={{marginRight: 10}} >{falseText}</span>
    }
    <Switch {...field} {...props} value={field.value} checked={field.value} />
    {label && labelPlacement === 'end' &&
    <span style={{marginLeft: 10}}>{label}</span>
    }
    {trueText &&
    <span style={{marginLeft: 10}}>{trueText}</span>
    }
    {meta.touched && meta.error && (
      <Typography variant="body2" color="error">
        {meta.error}
      </Typography>
    )}
    </>
    :
    <LotusReadOnlyFieldView label={myLabel} value={field.value ? 'Yes' : 'No'} />
  );
}
