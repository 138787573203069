export const messageTypes = {
  GET_EXPORT_TYPES_SUCCESS: 'GET_EXPORT_TYPES_SUCCESS',
  GET_EXPORT_REQUESTS_SUCCESS: 'GET_EXPORT_REQUESTS_SUCCESS',
  SUBMIT_EXPORT_REQUEST_SUCCESS: 'SUBMIT_EXPORT_REQUEST_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.GET_EXPORT_TYPES_SUCCESS:
      return {
        ...state,
        exportTypes: payload.exportTypes,
      };
    case messageTypes.GET_EXPORT_REQUESTS_SUCCESS:
      return {
        ...state,
        exportRequests: payload.exportRequests,
      };
    case messageTypes.SUBMIT_EXPORT_REQUEST_SUCCESS:
      const curRequests = state.exportRequests || [];
      const newRequestsList = [
        ...curRequests.filter((r) => r.id !== payload.submittedRequest.id),
      ];
      newRequestsList.push(payload.submittedRequest);
      return {
        ...state,
        exportRequests: newRequestsList,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
