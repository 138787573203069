import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useField } from 'formik';
import { InputLabel, Stack, Typography } from '@mui/material';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Error } from '@mui/icons-material';


export default function LotusSelect({
  name,
  label,
  helperText,
  items,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  handleChange,
  autoSort=true,
  disallowNoSelectionIfNotRequired=false,
  excludeInactiveOrDeleted=false,
}) {
  const [field, meta] = useField({name, validate: (val) => {
    if (required && !dontCheckRequiredFields && (val === undefined || val === null || val === '')) {
      return `${label} is required`;
    }
  }});
  const labelText = label + (required ? ' * ' : '');

  let selectItems = items ? [...items] : [];
  if (excludeInactiveOrDeleted) {
    // Exclude inactive or delete items unless the item is the current selection
    selectItems = selectItems.filter(itm => (itm.isActive && !itm.isDeleted) || itm.value === field.value);
  }
  if (autoSort) {
    selectItems.sort((a, b) => (a.label > b.label ? 1 : -1));
  }
  if (!required && !disallowNoSelectionIfNotRequired) {
    selectItems.unshift({value: '', label: '- No Selection -'});
  }

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    if (handleChange) {
      handleChange(newValue);
    }
    field.onChange(e);
  };

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && 
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControl fullWidth variant={disabled ? "filled" : "outlined"} style={{width: '100%'}} size="small" error={meta.touched && Boolean(meta.error)} disabled={disabled}>
            <InputLabel id={`${name}-outlined-label`}>{labelText}</InputLabel>
            <Select
              labelId={`${name}-outlined-label`}
              {...field}
              onChange={handleLocalChange}
              required={required}
              label={labelText}
            >
              {selectItems &&
                selectItems.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {meta.touched && meta.error &&
            <Error color='error'/>
          }
        </Stack>
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={(selectItems && selectItems.find(x => x.value === field.value)?.label) || ''} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
