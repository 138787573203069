import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import LotusStickyNav from 'components/widgets/Forms/LotusStickyNav';
import PageSection from 'components/widgets/Layout/LotusPageSection';

import { combineFormElementsAndSections } from '../../utils/hybrid';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { HashLink } from 'react-router-hash-link';

export default function HybridFormTabContent({
  title,
  staticFormDefinitionBuilder,
  staticFormSectionNameBuilder,
  dynamicFormDefinition,
  allowEdit = true,
  dontCheckRequiredFields = false
}) {

  const [fieldElements, setFieldElements] = useState();
  const [formTitle, setFormTitle] = useState();
  const [sections, setSections] = useState();

  useEffect(() => {

    const {combinedFieldElements, combinedSections} = combineFormElementsAndSections({staticFormSectionNameBuilder, staticFormDefinitionBuilder, dynamicFormDefinition, disabled: !allowEdit, dontCheckRequiredFields});
    setFieldElements(combinedFieldElements);
    setSections(combinedSections);

  }, [staticFormDefinitionBuilder, staticFormSectionNameBuilder, dynamicFormDefinition]);

  useEffect(() => {
    if (title) {
      setFormTitle(title);
    }
  }, [title]);

  return fieldElements && sections && (
    <PageSection header={formTitle}>
      <Grid container>
        {sections && sections.length > 0 &&
        <Grid item xs={3} container>
          <LotusStickyNav linkData={sections} />
        </Grid>
        }
        <Grid item xs={sections && sections.length > 0 ? 9 : 12} container style={{paddingTop: 20}}>
          {fieldElements && fieldElements.map((element,idx) => {
            return (
            <Grid key={idx} item xs={12}>
              {element}
            </Grid>
            );
          })}
          <LotusFormItem>
            <HashLink smooth to="#topOfForm">
              <LotusButton variant="outlined">
                Back to top
              </LotusButton>
            </HashLink>
          </LotusFormItem>
        </Grid>
      </Grid>
    </PageSection>
  );
};
