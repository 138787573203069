import React, { useEffect, useState } from 'react';
import { useLists } from 'contexts/ListsContext';
import LotusAutocomplete from './LotusAutocomplete';
import { useField } from 'formik';

export default function LotusCountySelector({
  name, 
  label, 
  helperText, 
  required, 
  dontCheckRequiredFields,
  disabled,
  readOnly,
  state,
  useSeedData,
}) {
  
  const { countiesByState, loadCountiesForState } = useLists();
  const [countyList, setCountyList] = useState();

  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (useSeedData) {
      if (state === 'WI') {
        setCountyList(['Brown','Dane','Milwaukee']);
      }
      else if (state === 'IL') {
        setCountyList(['Cook', 'Iroquois']);
      }
      else if (state === 'MN') {
        setCountyList(['Goodhue', 'Wabasha']);
      }
      else {
        setCountyList([]);
      }
    } else if (state && (!countiesByState || state in countiesByState === false)) {
      loadCountiesForState(state);
    }
  }, [useSeedData, state]);

  useEffect(() => {
    if (countiesByState && state in countiesByState) {
      setCountyList(countiesByState[state]);
    } else if (!useSeedData) {
      setCountyList([]);
    }
  }, [state, countiesByState]);

  useEffect(() => {
    if (countyList && countyList.length > 0 && !countyList.includes(field.value)) {
      helpers.setValue('');
    }
  }, [countyList]);

  return (
    <LotusAutocomplete
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
      readOnly={readOnly}
      options={countyList || []}
    />
  );
}
