export const populateInitialArrayItem = (fieldDefinition, formValue) => {
  let arrayItemData = {};
  fieldDefinition.itemFields.forEach((itemField) => {
    populateInitialFieldValue(arrayItemData, itemField, formValue);
  });
  return arrayItemData;
};

export const populateInitialFieldValue = (initialValues, fieldDefinition, savedFormValues) => {
  
  const savedFieldValue = savedFormValues ? savedFormValues[fieldDefinition.id] : undefined;

  if ('multi-object' === fieldDefinition.type) {
    let initialArrayItems = [];
    
    if (savedFieldValue) { // its an array
      savedFieldValue.forEach((savedArrayItem) => {
        const initialArrayItem = populateInitialArrayItem(fieldDefinition, savedArrayItem);
        initialArrayItems.push(initialArrayItem);
      });
    } else if (fieldDefinition.initialSize > 0) {
      let initialDefaultArrayItem = populateInitialArrayItem(fieldDefinition, {});
      initialArrayItems.push(initialDefaultArrayItem);
    }
    initialValues[fieldDefinition.id] = initialArrayItems;
    return;
  } 
  
  if (fieldDefinition.id) {
    if ('address' === fieldDefinition.type) {
      initialValues[fieldDefinition.id] = savedFieldValue ? savedFieldValue : {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        county: ''
      };
    } else if ('text' === fieldDefinition.type || 'select' === fieldDefinition.type || 'radio' === fieldDefinition.type ||
               'maskedInput' === fieldDefinition.type || 'textarea' === fieldDefinition.type) {
      initialValues[fieldDefinition.id] = savedFieldValue || fieldDefinition.default || '';
    } else if ('date' === fieldDefinition.type || 'dateOfBirth' === fieldDefinition.type || 'phoneNumber' === fieldDefinition.type || 'monthAndYear' === fieldDefinition.type ) {
      initialValues[fieldDefinition.id] = savedFieldValue || fieldDefinition.default || null;
    } else if ('switch' === fieldDefinition.type || 'checkbox' === fieldDefinition.type) {
      initialValues[fieldDefinition.id] = savedFieldValue || fieldDefinition.default || false;
    } else if ('multiselect' === fieldDefinition.type) {
      initialValues[fieldDefinition.id] = savedFieldValue || [];
    } else {
      initialValues[fieldDefinition.id] = savedFieldValue || fieldDefinition.default;
    }
    return;
  }
};
