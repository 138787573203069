import React, { useEffect, useState, useContext } from 'react';
import { parseApiResult } from 'lib/utils';
//axiosInterceptor contains the authentication header
import axiosInterceptor from '../axiosInterceptor';
//axios object does not have authenication header
import axios from 'axios';
import { useAppStatus } from 'contexts/AppStatusContext';
import { useProperties } from 'contexts/PropertiesContext';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { PERMISSIONS } from 'lib/permissionEnums';

const AuthContext = React.createContext();

const getUser = async () => {
  const { data } = await axiosInterceptor.post('/api/user', {operationName: 'verifyUser'});
  let { user, userPermissions } = parseApiResult(data).body;
  if (userPermissions) {
    userPermissions = {
      permissionList: userPermissions.permissionList,
      has: (perm) => userPermissions.permissionList.find(p => p.permissionKey === perm && p.enabled) !== undefined,
      hasProgram: (perm, programId) => userPermissions.permissionList.find(p => p.permissionKey === perm && p.programId === programId && p.enabled) !== undefined
    };
  }
  return {user, userPermissions};
};

export const AuthProvider = ({ children }) => {

  const [state, setState] = useState({ status: 'pending' });
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        addBusyBee('loadUserInfo');
        const {user, userPermissions} = await getUser();
        setState({
          status: 'success',
          user: user,
          permissions: userPermissions,
          tablePreferences: user.tablePreferences,
          cognitoUser: undefined
        });
      } catch (error) {
        setState({
          status: 'success',
          user: null,
          permissions: null,
          cognitoUser: undefined
        });
      } finally {
        removeBusyBee('loadUserInfo');
      }
    };

    loadUserInfo();
  }, []);

  return (
    <AuthContext.Provider value={state}>
      {state.status === 'pending' || state.status === 'error' ? (
        <div></div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuthState = () => {
  const state = useContext(AuthContext);
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const { properties } = useProperties();
  const [userPool, setUserPool] = useState();

  useEffect(() => {
    if (properties && !userPool) {
      const userPoolId = properties['REACT_APP_USER_POOL_ID'];

      const poolData = {
        UserPoolId: `${userPoolId}`,
        ClientId: `${properties['REACT_APP_CLIENT_ID']}`,
        Storage: window.sessionStorage
      }

      const userPool = new CognitoUserPool(poolData);
      setUserPool(userPool);
    }
  }, [properties]);

  const getLogoutUrl = async () => {
    try {
      const logoutUrlResp = await axiosInterceptor.post('/api/user', {operationName: 'getLogoutUrl'});
      const resultObj = parseApiResult(logoutUrlResp.data).body;
      return resultObj.logoutUrl;
    }
    catch (err) {
      return undefined;
    }
  };

  const signIn = async (username, password) => {
    addBusyBee('signIn');
    try {
      const result = await signInWithEmail(username, password);
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    } finally {
      removeBusyBee('signIn');
    }
  }

  const getCognitoUser = (username) => {
    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool,
      Storage: window.sessionStorage
    }
    const cognitoUser = new CognitoUser(userData);

    return cognitoUser
  }

  const isPasswordChangeAllowed = async (email, pwd) => {
    try {
      const { data } = await axios.post('/api/auth', {
        operationName: 'validatePasswordChange',
        email: email,
        pwd: pwd,
      });
      const resultObj = parseApiResult(data).body;
      return { isAllowed: resultObj.isAllowed, error: resultObj.reason };
    }
    catch (err) {
      return undefined;
    }
  }

  const exchangeToken = async (token) => {
    try {
      const { data } = await axios.post('/api/auth', {
        operationName: 'exchangeToken',
        token: token,
      });
      const resultObj = parseApiResult(data).body;
      return { email: resultObj.email, pwd: resultObj.pwd, err: resultObj.failureReason };
    }
    catch (err) {
      return undefined;
    }
  }

  const updatePasswordAndLoginHistory = async (pwd, e) => {
    addBusyBee('updatePasswordAndLoginHistory');

    try {
      let email = e || state?.user.email;
      const { data } = await axiosInterceptor.post('/api/user', {
        operationName: 'updatePasswordAndLoginHistory',
        email: email,
        pwd: pwd,
      });
      const resultObj = parseApiResult(data).body;
      return resultObj.error;
    }
    catch (err) {
      return undefined;
    }
    finally {
      removeBusyBee('updatePasswordAndLoginHistory');
    }
  }

  const signInWithEmail = async (username, password) => {
    return new Promise(function(resolve, reject) {
      const authenticationData = {
        Username: username.toLowerCase(),
        Password: password,
        Storage: window.sessionStorage
      }
      const authenticationDetails = new AuthenticationDetails(authenticationData)

      const cogUser = getCognitoUser(username.toLowerCase());

      state.cognitoUser = cogUser;

      cogUser.authenticateUser(authenticationDetails, {
        onSuccess: function(res) {
          resolve(res)
        },
        onFailure: function(err) {
          reject(err)
        },
        newPasswordRequired: function(userAttributes) {
          delete userAttributes.email_verified;
          resolve({ newPasswordRequired: true, userAttributes: userAttributes })
        },
        mfaSetup: function(challengeName, challengeParameters) {
          resolve({ mfaSetupRequired: true })
        },
        totpRequired: function(challengeName, challengeParameters) {
          resolve({ totpRequired: true })
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  const handleNewPassword = async (sessionUserAttributes, newPassword) => {
    let result;
    try {
      result = await isPasswordChangeAllowed(sessionUserAttributes.email, newPassword);
      if (result.isAllowed) {
        result = await handleNewPasswordSync(newPassword, sessionUserAttributes);
        return result;
      }
      else {
        return { error: result.error, errorCode: {} };
      }
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const handleNewPasswordSync = async (newPassword) => {
    return new Promise(function(resolve, reject) {
      state.cognitoUser.completeNewPasswordChallenge(newPassword, [], {
        onSuccess: function(res) {
          resolve(res)
        },
        onFailure: function(err) {
          reject(err)
        },
        mfaSetup: function(challengeName, challengeParameters) {
          resolve({ mfaSetupRequired: true })
        },
      })

    }).catch((err) => {
      throw err
    })
  }


  const associateSoftwareToken = async () => {
    try {
      const result = await associateSoftwareTokenSync();
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const associateSoftwareTokenSync = async () => {
    if (state.cognitoUser) {
      return new Promise(function(resolve, reject) {
        state.cognitoUser.associateSoftwareToken({
          onSuccess: function(res) {
            resolve(res)
          },
          onFailure: function(err) {
            reject(err)
          },
          associateSecretCode: function(res) {
            resolve(res)
          },
        })
      }).catch((err) => {
        throw err
      })
    }
  }

  const verifySoftwareToken = async (totpCode, friendlyDeviceName) => {
    try {
      const result = await verifySoftwareTokenSync(totpCode, friendlyDeviceName);
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const verifySoftwareTokenSync = async (totpCode, friendlyDeviceName) => {
    if (state.cognitoUser) {
      return new Promise(function(resolve, reject) {
        state.cognitoUser.verifySoftwareToken(totpCode, friendlyDeviceName, {
          onSuccess: function(res) {
            resolve(res)
          },
          onFailure: function(err) {
            reject(err)
          },
          associateSecretCode: function(res) {
            resolve(res)
          },
        })
      }).catch((err) => {
        throw err
      })

    }
  }

  const sendMFACode = async (confirmationCode, mfaType, clientMetadata) => {
    addBusyBee('sendMFACode');
    try {
      const result = await sendMFACodeSync(confirmationCode, mfaType, clientMetadata);
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
    finally {
      removeBusyBee('sendMFACode');
    }
  }

  const sendMFACodeSync = async (confirmationCode, mfaType, clientMetadata) => {
    //use the currentUser from state as the user has not yet been authenticated.
    if (state.cognitoUser) {
      return new Promise(function(resolve, reject) {
        state.cognitoUser.sendMFACode(confirmationCode, {
          onSuccess: function(res) {
            resolve(res)
          },
          onFailure: function(err) {
            reject(err)
          },
          associateSecretCode: function(res) {
            resolve(res)
          },
        }, mfaType || 'SOFTWARE_TOKEN_MFA', clientMetadata)
      }).catch((err) => {
        throw err
      })
    }
  }

  const sendCode = async (username) => {
    try {
      const result = await sendCodeSync(username.toLowerCase());
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }
  const sendCodeSync = async (username) => {
    return new Promise(function(resolve, reject) {
      const cognitoUser = getCognitoUser(username)

      if (!cognitoUser) {
        reject(`could not find ${username}`)
        return
      }

      cognitoUser.forgotPassword({
        onSuccess: function(res) {
          resolve(res)
        },
        onFailure: function(err) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }

  const forgotPassword = async (username, code, password) => {
    let result;

    try {
      result = await isPasswordChangeAllowed(username, password);
      if (result.isAllowed) {
        result = await forgotPasswordSync(username.toLowerCase(), code, password);
        return result;
      }
      else {
        return { error: result.error, errorCode: {} };
      }
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const forgotPasswordSync = async (username, code, password) => {
    return new Promise(function(resolve, reject) {
      const cognitoUser = getCognitoUser(username);

      if (!cognitoUser) {
        reject(`could not find ${username}`)
        return
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: function() {
          resolve('password updated')
        },
        onFailure: function(err) {
          reject(err)
        },
      })
    })
  }

  const changePassword = async (oldPassword, newPassword) => {
    try {

      const session = await getSessionSync();
      const currUser = userPool.getCurrentUser();
      currUser.setSignInUserSession(session);
      let result = await isPasswordChangeAllowed(state.user.email, newPassword);

      if (result.isAllowed) {
        result = await changePasswordSync(currUser, oldPassword, newPassword);
        return result;
      } else {
        return { error: result.error, errorCode: {} };
      }
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const changePasswordSync = async (currUser, oldPassword, newPassword) => {
    return new Promise(function(resolve, reject) {
      currUser.changePassword(oldPassword, newPassword, function(err, res) {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  const getUserSession = async () => {
    try {
      const result = await getSessionSync();
      return result;
    } catch (err) {
      return { error: err.message, errorCode: err.code };
    }
  }

  const getSessionSync = async () => {
    const currUser = userPool.getCurrentUser();

    if (!currUser) {
      //currentUser = userPool.getCurrentUser()
      return;
    }

    return new Promise(function(resolve, reject) {
      currUser.getSession(function(err, session) {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    }).catch((err) => {
      throw err
    })
  }

 const signOut = async () => {
    let logoutUrl = await getLogoutUrl();
    sessionStorage.clear();
    if (logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      window.location.href = window.location.origin;
    }
  }

  const updateTablePreferences = async (preferences) => {
   
    try {
      // Doing this in "background" so not showing busy indicator
      const result = await axiosInterceptor.post(`/api/user`, {
        operationName: 'updateTablePreferences',
        preferences: preferences,
        userId: state.user.id
      });
      const resultObj = parseApiResult(result.data).body;
      state.tablePreferences = preferences;
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  if (state) {
    const permissions = state.permissions;
    const verifyPermission = (permissionKey) => {
      return permissions.has(permissionKey);
    };

    const verifyEditProgramPermission = (programId) => {
      return permissions.hasProgram(PERMISSIONS.EDIT_CLIENT_PROGRAM_DATA, programId);
    };

    const reloadUserInfo = async () => {
      try {
        addBusyBee('reloadUserInfo');
        const {user} = await getUser();
        state.user = user;
      }
      finally {
        removeBusyBee('reloadUserInfo');
      }
    }

    return {
      ...state,
      signIn,
      signOut,
      handleNewPassword,
      changePassword,
      sendMFACode,
      sendCode,
      forgotPassword,
      exchangeToken,
      associateSoftwareToken,
      verifySoftwareToken,
      getUserSession,
      reloadUserInfo,
      verifyPermission,
      verifyEditProgramPermission,
      updatePasswordAndLoginHistory,
      updateTablePreferences
    };
  }

  return {};
};
