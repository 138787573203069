import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";
import { METRIC_CATEGORIES } from 'lib/metricCategoryEnum';
import { useDashboards } from "contexts/DashboardsContext";
import { GranteeAgencyDashboardsView } from "./GranteeAgencyDashboardsView";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from 'uuid';

export default function GranteeAgencyDashboards() {

  const { 
    agency, updateAgencyDashboardConfig,
    agencyMetrics, loadAgencyMetrics, upsertAgencyMetric, deleteAgencyMetric
   } = useAgencyManagement();
  const { metrics, loadMetrics } = useDashboards();
  const { loadUserAgency } = useUserAgency();

  const [cycleTimeMetrics, setCycleTimeMetrics] = useState();
  const [processorProductivityMetrics, setProcessorProductivityMetrics] = useState();

  useEffect(() => {
    loadMetrics([METRIC_CATEGORIES.CYCLE_TIME, METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY]);
  }, []);

  useEffect(() => {
    if (agency) {
      loadAgencyMetrics([METRIC_CATEGORIES.CYCLE_TIME, METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY]);
    }
  }, [agency]);

  useEffect(() => {
    if (metrics) {
      const ctMetrics = metrics.filter(metric => metric.category === METRIC_CATEGORIES.CYCLE_TIME);
      setCycleTimeMetrics(ctMetrics);
      const ppMetrics = metrics.filter(metric => metric.category === METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY);
      setProcessorProductivityMetrics(ppMetrics);
    }
  }, [metrics])

  const saveDashboardConfig = async (values) => {
    await updateAgencyDashboardConfig(values.dashboardConfig);
    // Someday this should be done in the api to avoid all the trips
    for (let am = 0; am < values.agencyMetrics.length; am++) {
      let updatedAgencyMetric = values.agencyMetrics[am];
      if (!updatedAgencyMetric.id) {
        await upsertAgencyMetric(
          uuidv4(),
          agency.id,
          updatedAgencyMetric.metricId,
          updatedAgencyMetric.title,
          updatedAgencyMetric.data
        );
      }
      let existingAgencyMetric = agencyMetrics.find(eam => eam.id === updatedAgencyMetric.id);
      if (existingAgencyMetric && !isEqual(existingAgencyMetric, updatedAgencyMetric)) {
        await upsertAgencyMetric(
          updatedAgencyMetric.id,
          agency.id,
          updatedAgencyMetric.metricId,
          updatedAgencyMetric.title,
          updatedAgencyMetric.data
        );
      }
    }
    for (let eam = 0; eam < agencyMetrics.length; eam++) {
      if (!values.agencyMetrics.find(am => am.id === agencyMetrics[eam].id)) {
        await deleteAgencyMetric(agency.id, agencyMetrics[eam].id);
      }
    }
    await loadUserAgency();
  };

  return agency && agencyMetrics && cycleTimeMetrics && processorProductivityMetrics && (
    <GranteeAgencyDashboardsView
      dashboardConfig={agency.dashboardConfig}
      agencyMetrics={agencyMetrics}
      cycleTimeMetrics={cycleTimeMetrics}
      processorProductivityMetrics={processorProductivityMetrics}
      handleSave={saveDashboardConfig}
      handleCancel={() => {}} // staying on page, just reverting changes
    />
  );
}