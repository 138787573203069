import React from 'react';

export default function LotusCardList({ ...props }) {

  return (
    <div>
      {props.children && props.children.map((card, idx) => {
        return (
          <div style={idx > 0 ? {marginTop: 20} : {}} key={idx}>
            {card}
          </div>
        );
      })}
    </div>
  );
}
