export const messageTypes = {
  LOADING_PERMISSION_TEMPLATES_SUCCESS: 'LOADING_PERMISSION_TEMPLATES_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_PERMISSION_TEMPLATES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
