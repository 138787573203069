import React, { useEffect, useState } from 'react';
import { useFieldSearch } from 'contexts/DataDictionaryContext';
import { Table } from '@lotus/components';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function DataDictionaryTable({ tableName }) {
  const [rows, setRows] = useState();
  const { userAgency } = useUserAgency();

  const { fields, loadFields } = useFieldSearch();

  const defaultSortOrder = { };

  const handleDataChange = async ({
    tableName,
    sortOrder,
  }) => {
    if (tableName) {
      await loadFields({
        agencyId: userAgency.id,
        tableName,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (tableName) {
      handleDataChange({
        tableName: tableName,
        sortOrder: defaultSortOrder,
      });
    }
  }, [tableName]);

  useEffect(() => {
    if (fields) {
      setRows(fields);
    }
  }, [fields]);

  const columns = [
    {
      label: 'Field Name',
      name: 'name',
    },
    {
      label: 'Key',
      name: 'key',
    },
    {
      label: 'Required',
      name: 'required',
    },
    {
      label: 'Description',
      name: 'description',
    },
    {
      label: 'Type',
      name: 'restriction',
      options: {
        customBodyRender: (value) => {
          return (value === 'import_only' ? 'Import Only' : (value === 'export_only' ? 'Export Only' : ''));
        },
      },
    }
  ];
  
  return <Table
    tableName={tableName}
    columns={columns}
    data={rows}
    options={{
      pagination: false,
      filter: false,
      search: false,
      searchOpen: false,
      download: false,
      print: false,
      viewColumns: false,
      serverSide: false,
    }}
    handleDataChange={handleDataChange}
  />
}
