const { useUserAgency } = require("contexts/UserAgencyContext");
const { useFormDefinitions } = require("contexts/DynamicFormContext");
const { useState, useEffect } = require("react");

export function useSubforms(formTypeKey) {
  const { userAgency } = useUserAgency();
  const {formDefinitions, loadFormDefinitionsForFormType, buildFormDefinitionKey} = useFormDefinitions();
  const [subformDefinitions, setSubformDefinitions] = useState();

  useEffect(() => {
    if (userAgency && formTypeKey) {
      const key = buildFormDefinitionKey(formTypeKey);
      if (!formDefinitions || key in formDefinitions === false) {
        loadFormDefinitionsForFormType({formTypeKey: formTypeKey});
      }
    }
  }, [userAgency, formTypeKey]);

  useEffect(() => {
    if (userAgency && formTypeKey) {
      const key = buildFormDefinitionKey(formTypeKey);
      if (formDefinitions && key in formDefinitions) {
        const formDefs = formDefinitions[key];
        formDefs.sort((a, b) => {return (a.formSubtypeCustomSequence || 0) - (b.formSubtypeCustomSequence || 0)});
        setSubformDefinitions(formDefs);
      }
    }
  }, [userAgency, formDefinitions, formTypeKey]);

  return subformDefinitions;
}
