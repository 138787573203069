import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Button } from "@lotus/components";
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

export default function CaseManagerAssignment ({
  leadAgency,
  leadCaseManager,
  handleCancel,
  handleConfirm,
}) {
  const [ initialValues, setInitialValues ] = useState();
  
  useEffect(() => {
    setInitialValues({
        leadAgency: leadAgency,
        leadCaseManager: leadCaseManager
    });
  }, []);

  return (
  <Grid container>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (newValues, actions) => {
        await handleConfirm();
        actions.setSubmitting(false);
      }}
    >
      {({ values, handleSubmit }) => {
        return values && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                  <Typography variant="h2">Agency Assignment</Typography>
              </Grid>
              <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, marginBottom: 20}}>
                <Typography variant="body">
                This client is currently assigned to the case manager below.
                </Typography>
                <Typography variant="body">
                Would you like to become the client's lead case manager?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{paddingBottom: 24}}>
                <LotusFormItem>
                    <LotusTextInput
                        name='leadAgency'
                        label="Agency Name"
                        disabled={true}
                    />
                </LotusFormItem>
                <LotusFormItem>
                    <LotusTextInput
                        name='leadCaseManager'
                        label="Lead Case Manager"
                        disabled={true}
                    />
                </LotusFormItem>
              </Grid>
                <Grid item xs={12} container justifyContent='flex-end'>
                    <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                    <Typography variant="h6">Skip</Typography>
                    </Button>
                    <Button onClick={() => {handleSubmit(values);}}>
                    <Typography variant="h6">Confirm</Typography>
                    </Button>
                </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  </Grid>);
}