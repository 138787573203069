import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BarrierList from './BarrierList';
import { useAuthState } from 'contexts/AuthProvider';
import BarrierForm from './BarrierForm';
import ProgressNoteForm from '../Documentation/ProgressNoteForm';
import { useClient } from 'contexts/ClientContext';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import { useClientDocumentation } from 'contexts/ClientDocumentationContext';
import { useProgram } from 'contexts/ProgramContext';
import { PERMISSIONS } from 'lib/permissionEnums'
import { linkifyTabName } from 'lib/formatting';
import { Button } from "@lotus/components";
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusListFilterTabs from 'components/widgets/Layout/LotusListFilterTabs';
import { useLists } from 'contexts/ListsContext';

export default function Index() {
  const [barriers, setBarriers] = useState();
  const [backView, setBackView] = useState('open');
  const [readOnly, setReadOnly] = useState(true);
  const [filterTabs, setFilterTabs] = useState();

  const { clientId, lvl2: programId, lvl4: view, lvl5: entity } = useParams();
  const navigate = useNavigate();
  const { verifyPermission, verifyEditProgramPermission } = useAuthState();
  const { client } = useClient();

  const { barrierDetails, loadBarrierDetailsForClientAndProgram } = useClientCarePlan();
  const { progressNotes, loadProgressNotesForClientAndProgram } = useClientDocumentation();
  const { 
    currentProgram, 
    currentProgramUsers, 
    loadProgramUsers,
    loadProgramCarePlanPicklists} = useProgram();
  const {
    careCategories, lifeAreas, barrierStatuses, loadCareCategories, loadLifeAreas, loadBarrierStatuses,
    goalStatuses, loadGoalStatuses, interventionStatuses, loadInterventionStatuses } = useLists();

  useEffect(() => {
    if (!barrierStatuses) {
      loadBarrierStatuses();
    }
    if (!goalStatuses) {
      loadGoalStatuses();
    }
    if (!interventionStatuses) {
      loadInterventionStatuses();
    }
  }, []);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId) {
      // Just always load these
      loadProgramCarePlanPicklists();

      if (currentProgram.barrierLifeAreaEnabled) {
        if (!lifeAreas) {
          loadLifeAreas();
        }
      }
      if (currentProgram.barrierCareCategoryEnabled) {
        if (!careCategories) {
          loadCareCategories();
        }
      }

      if (currentProgram.goalAssignedToEnabled && !currentProgramUsers) {
        loadProgramUsers();
      }
    }
  }, [currentProgram]);

  useEffect(() => {
    if (clientId && programId) {
      if (!view || !entity) {
        navigate(`/client/${clientId}/caremanagement/${programId}/careplan/list/open`);
      }

      const tabList = ([
        {label: 'Open', link: `/client/${clientId}/caremanagement/${programId}/careplan/list/open`},
        {label: 'Closed', link: `/client/${clientId}/caremanagement/${programId}/careplan/list/closed`},
        {label: 'Inactive', link: `/client/${clientId}/caremanagement/${programId}/careplan/list/inactive`},
        {label: 'History', link: `/client/${clientId}/caremanagement/${programId}/careplan/list/history`},
      ]);
      setFilterTabs(tabList);
    }
  }, [clientId, programId, view, entity]);

  useEffect(() => {
    if (clientId && programId) {
      loadBarrierDetailsForClientAndProgram(clientId, programId);
      loadProgressNotesForClientAndProgram(clientId, programId);
      setReadOnly(verifyPermission(PERMISSIONS.EDIT_CARE_MANAGEMENT) ? !verifyEditProgramPermission(programId) : !verifyPermission(PERMISSIONS.EDIT_CARE_MANAGEMENT));
    }
  }, [clientId, programId]);

  useEffect(() => {
    // Attach progress notes at the various levels for viewing
    const barrierNotes = [];
    const goalNotes = [];
    const interventionNotes = [];

    if (view !== 'list') {
      return;
    }

    if (progressNotes && programId in progressNotes) {
      progressNotes[programId].forEach((progressNote) => {
        progressNote.barrierNotes.forEach((barrierNote) => {
          barrierNotes.push(barrierNote);
        });
        progressNote.goalNotes.forEach((goalNote) => {
          goalNotes.push(goalNote);
        });
        progressNote.interventionNotes.forEach((interventionNote) => {
          interventionNotes.push(interventionNote);
        });
      });
    }

    if (barrierStatuses && barrierDetails && programId in barrierDetails) {
      const barrierList = barrierDetails[programId]
        .filter((barrier) => linkifyTabName(barrierStatuses.find(s => s.id === barrier.careStatusId).name) === linkifyTabName(entity))
        .map((barrier) => {
          const barrierGoals = barrier.goals.map((goal) => {
            const goalInterventions = goal.interventions.map((intervention) => {
              return {
                ...intervention,
                progressNotes: interventionNotes.filter((iNote) => iNote.interventionId === intervention.id)
              };
            });
            return {
              ...goal,
              interventions: goalInterventions,
              progressNotes: goalNotes.filter((gNote) => gNote.goalId === goal.id)
            };
          });
          return {
            ...barrier,
            goals: barrierGoals,
            progressNotes: barrierNotes.filter((bNote) => bNote.barrierId === barrier.id)
          };
        });

      setBarriers(barrierList);
    }
  }, [barrierStatuses, barrierDetails, progressNotes, clientId, programId, view, entity]);

  const addProgressNoteClick = async () => {
    setBackView(entity);
    await navigate(`/client/${client.id}/caremanagement/${programId}/careplan/create/progressnote`);
  };

  const addBarrierClick = async () => {
    setBackView(entity);
    await navigate(`/client/${client.id}/caremanagement/${programId}/careplan/create/barrier`);
  };

  const afterCreate = async () => {
    await navigate(`/client/${client.id}/caremanagement/${programId}/careplan/list/${backView}`);
  };

  const goBack = async () => {
    await navigate(`/client/${client.id}/caremanagement/${programId}/careplan/list/${backView}`);
  };

  return (
    currentProgram && view && entity && barriers &&
      <LotusPageSection
        header="Care Plan" 
        actions={
          <>
          <Button disabled={readOnly} onClick={addProgressNoteClick}>
            Add Progress Note
          </Button>
          <Button disabled={readOnly} onClick={addBarrierClick}>
            Add {currentProgram.barrierDisplayName || 'Barrier'}
          </Button>
          </>
        }
      >
        {view.toLowerCase() === 'list' && 
          <>
          <LotusListFilterTabs tabs={filterTabs} selectedTabName={entity} />
          <BarrierList barriers={barriers} readOnly={readOnly} />
          </>
        }
        {view.toLowerCase() === 'create' && entity.toLowerCase() === 'progressnote' && (
          <ProgressNoteForm afterSave={afterCreate} handleCancel={goBack} progressNoteDisplayName="Progress Note"/>
        )}
        {view.toLowerCase() === 'create' && entity.toLowerCase() === 'barrier' && (
          <BarrierForm afterSave={afterCreate} handleCancel={goBack} readOnly={false} />
        )}
      </LotusPageSection>
  );
}
