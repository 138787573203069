import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './AgencyContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';


const initialState = {
  agencyCaseManagers: undefined
};

const AgencyContext = createContext({defaultValue: initialState});

export const AgencyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgencyContext.Provider value={{ state, dispatch }}>
      {children}
    </AgencyContext.Provider>
  );
};

export const useAgency = () => {
  const { state, dispatch } = useContext(AgencyContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadAgencyCaseManagers = async (agencyId) => {
    try {
      addBusyBee('loadAgencyCaseManagers');

      const toPost = {
        operationName: 'getAgencyCaseManagers',
        agencyId: agencyId
      }

      const url = `/api/agency`;
      const result = await axios.post(url, toPost);

      let agencyCaseManagers = parseApiResult(result.data).body;
      
      dispatch({
        type: messageTypes.LOADING_AGENCY_CASE_MANAGERS_SUCCESS,
        payload: {
          agencyId,
          agencyCaseManagers
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyCaseManagers');
    }
  };

  if (state) {
    return {
      ...state,
      loadAgencyCaseManagers
    };
  }

  return {};
};
