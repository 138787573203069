export const messageTypes = {
  REGISTERING_CLIENT_ENCOUNTERED_DUPLICATES: 'REGISTERING_CLIENT_ENCOUNTERED_DUPLICATES',
  REGISTERING_CLIENT_SUCCESS: 'REGISTERING_CLIENT_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.REGISTERING_CLIENT_ENCOUNTERED_DUPLICATES:
      return {
        ...state,
        client: undefined,
        clientDuplicates: payload.clientDuplicates,
      };
    case messageTypes.REGISTERING_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload.client,
        clientDuplicates: undefined
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
