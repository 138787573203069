import React from 'react';
import { Form, Formik } from 'formik';
import LotusStackedFormItems from 'components/form/LotusStackedFormItems';
import LotusMultilineTextInput from 'components/form/inputField/common/LotusMultilineTextInput';
import { DialogActions, Typography } from '@mui/material';
import LotusDialog from 'components/feedback/dialog/LotusDialog';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusCancelButton from 'components/button/common/LotusCancelButton';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';


// This is a bit screwy as we're doing a form inside the dialog
// Prob better way to handle this, but not sure its gonna be a common thing to do so leaving it for now
export default function AgencyDenialDialog ({ actionRequest, open, handleClose, handleSubmit }) {
  return (
    <LotusDialog
      staticWidth='sm'
      title="Deny Agency Request"
      open={open}
      content={
        <Formik
          enableReinitialize
          initialValues={{denyReason: ''}}
          onSubmit={async (values, actions) => {
            await handleSubmit(actionRequest, values.denyReason);
            actions.setSubmitting(false);
          }}
        >
          {({handleSubmit: doSubmit}) => {
            return (
              <Form>
                <LotusStackedFormItems>
                  <Typography variant="body1">{'Deny '+ actionRequest.agencyName + '?'}</Typography>
                  <LotusMultilineTextInput
                    name="denyReason"
                    label="Denial Reason"
                    rows={4}
                    required
                  />
                  { /* Buttons need to go in here so the form can be validated/submitted */ }
                  <DialogActions>
                    <LotusButtonList>
                      <LotusCancelButton onClick={handleClose}/>
                      <LotusPrimaryActionButton isDanger onClick={doSubmit}>Deny</LotusPrimaryActionButton>
                    </LotusButtonList>
                  </DialogActions>
                </LotusStackedFormItems>
              </Form>
            );
          }}
        </Formik>
      }
      handleClose={handleClose}
    />
  );
};
