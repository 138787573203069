import React from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import AssessmentsList from './AssessmentsList';
import AssessmentEditor from './AssessmentEditor';

export default function AssessmentsPage() {

  const {lvl4: view} = useParams();

  return (
    <Grid container>
      <Grid item xs={12}>
        {view === 'list' && <AssessmentsList/>}
        {view === 'edit' && <AssessmentEditor/>}
      </Grid>
    </Grid>
  );
}
