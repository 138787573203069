export const messageTypes = {
  SEARCHING_ORGANIZATION_PROVIDERS_SUCCESS: 'SEARCHING_ORGANIZATION_PROVIDERS_SUCCESS',
  SEARCHING_INDIVIDUAL_PROVIDERS_SUCCESS: 'SEARCHING_INDIVIDUAL_PROVIDERS_SUCCESS',
  LOADING_INDIVIDUAL_PROVIDER_SUCCESS: 'LOADING_INDIVIDUAL_PROVIDER_SUCCESS',
  LOADING_ORGANIZATION_PROVIDER_SUCCESS: 'LOADING_ORGANIZATION_PROVIDER_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.SEARCHING_ORGANIZATION_PROVIDERS_SUCCESS:
    case messageTypes.SEARCHING_INDIVIDUAL_PROVIDERS_SUCCESS:
    case messageTypes.LOADING_INDIVIDUAL_PROVIDER_SUCCESS:
    case messageTypes.LOADING_ORGANIZATION_PROVIDER_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
