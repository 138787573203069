import React, {useEffect, useState} from "react";

import LotusSelect from "components/widgets/Forms/LotusSelect";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import { useClientDocumentation } from "contexts/ClientDocumentationContext";
import { useProgram } from "contexts/ProgramContext";
import { useClient } from "contexts/ClientContext";
import { useAuthState } from "contexts/AuthProvider";
import { useLists } from "contexts/ListsContext";
import { useEnrollments } from "contexts/EnrollmentsContext";
import moment from "moment";
import InlineEditableStaticForm from "components/Forms/InlineEditableStaticForm";
import LotusForm from "components/widgets/Forms/LotusForm";
import LotusFormItems from "components/widgets/Forms/LotusFormItems";
import LotusExpandableItemList from "components/widgets/Forms/LotusExpandableItemList";
import LotusTrueFalseSelect from "components/widgets/Forms/LotusTrueFalseSelect";
import LotusSideBySide from "components/widgets/Forms/LotusSideBySide";

export default function MeetingForm({
  meeting,
  readOnly = true,
  afterSave,
  handleCancel,
  meetingDisplayName
}) {
  const { user: loggedInUser } = useAuthState();
  const { client } = useClient();
  const {
    currentProgram,
    currentProgramMeetingTypes,
    currentProgramMeetingInviteeTypes,
    loadProgramMeetingPicklists
  } = useProgram();
  const { upsertMeeting } = useClientDocumentation();
  const { meetingStatuses, loadMeetingStatuses } = useLists();
  const [initialValues, setInitialValues] = useState();
  const { enrolledPrograms, findEnrollment, loadEnrollmentsForClient } = useEnrollments();
  const [enrollment, setEnrollment] = useState();
  const [disallowEdit, setDisallowEdit] = useState();

  useEffect(() => {
    loadMeetingStatuses();
  }, []);

  useEffect(() => {
    if (meetingStatuses) {
      setDisallowEdit((meeting && meetingStatuses.find(s => s.id === meeting.meetingStatusId)?.key === 'completed') || readOnly);
    }
  }, [meeting, readOnly, meetingStatuses]);

  useEffect(() => {
    if (client) {
      loadEnrollmentsForClient(client.id, client.agencyId);
    }
  }, [client]);

  useEffect(() => {
    if (enrolledPrograms && client) {
      const enrollment = findEnrollment(currentProgram.id);
      setEnrollment(enrollment);
    }
  }, [enrolledPrograms, client, currentProgram]);

  useEffect(() => {
    if (currentProgram && !currentProgramMeetingTypes) {
      loadProgramMeetingPicklists();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (loggedInUser && enrollment && client && currentProgram) {
      let ampm = 'AM';
      let hoursMinutes = '';
      if (meeting?.meetingTime) {
        const meetingTimeFormatted = moment(meeting?.meetingTime,'HH:mm').format('hh:mm A');
        hoursMinutes = meetingTimeFormatted.substring(0, 5);
        ampm = meetingTimeFormatted.substring(6);
      }
      let initialValue = meeting ?
      {
        id: meeting.id,
        clientId: meeting.clientId,
        programId: meeting.programId,
        notes: meeting.notes || '',
        schedulingNotes: meeting.schedulingNotes || '',
        meetingStatusId: meeting.meetingStatusId || '',
        coordinatorAccountId: meeting.coordinatorAccountId || '',
        schedulerAccountId: meeting.schedulerAccountId || '',
        coordinatorName: meeting.coordinatorName || '',
        schedulerName: meeting.schedulerName || '',
        schedulingRequestedDate: meeting.schedulingRequestedDate || null,
        dueDate: meeting.dueDate || null,
        lastMeetingDate: meeting.lastMeetingDate || null,
        scheduledDate: meeting.scheduledDate || null,
        meetingDate: meeting.meetingDate || null,
        meetingTimeHoursMinutes: hoursMinutes,
        meetingTimeAmPm: ampm,
        meetingTypeId: meeting.meetingTypeId || '',
        location: meeting.location || '',
        timeSchedulingMeetingMinutes: meeting.timeSchedulingMeetingMinutes || '',
        invitees: meeting.invitees || []
      } :
      {
        clientId: client.id,
        programId: currentProgram.id,
        notes: '',
        schedulingNotes: '',
        meetingStatusId: '',
        coordinatorAccountId: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserId : '',
        coordinatorName: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserName : '',
        schedulerAccountId: loggedInUser.id,
        schedulerName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
        schedulingRequestedDate: null,
        dueDate: null,
        lastMeetingDate: null,
        scheduledDate: null,
        meetingDate: null,
        meetingTimeHoursMinutes: '',
        meetingTimeAmPm: ampm,
        meetingTypeId: '',
        location: '',
        timeSchedulingMeetingMinutes: '',
        invitees: []
      };
      setInitialValues(initialValue);
    }
  }, [meeting, loggedInUser, enrollment, client, currentProgram]);

  const onSubmit = async (newValues) => {
    if (newValues.meetingTimeHoursMinutes) {
      newValues.meetingTime = moment(`${newValues.meetingTimeHoursMinutes} ${newValues.meetingTimeAmPm}`,'h:mm A',true).format('HH:mm');
    }
    if (!disallowEdit) {
      await upsertMeeting(newValues);
    }

    if (afterSave) {
      await afterSave(newValues);
    }
  }

  const emptyInvitee = {
    name: '',
    typeId: '',
    confirmed: false,
    dateConfirmed: null,
  };

  function buildInviteePanel(invitee, index, disabled) {
    return (
      <LotusFormItems key={index}>
        <LotusTextInput
          disabled={disabled}
          name={`invitees.${index}.name`}
          label="Name"
          required
        />
        {currentProgramMeetingInviteeTypes &&
          <LotusSelect
            disabled={disabled}
            name={`invitees.${index}.typeId`}
            label='Invitee Type'
            required
            items={currentProgramMeetingInviteeTypes.map(s => {return {label: s.name, value: s.id}})}
          />
        }
        <LotusTrueFalseSelect
          disabled={disabled}
          name={`invitees.${index}.confirmed`}
          label="Invitee Confirmed?"
        />
        {invitee.confirmed &&
          <LotusDatePicker
            disabled={disallowEdit}
            name={`invitees.${index}.dateConfirmed`}
            label="Date Confirmed"
            required
          />
        }
      </LotusFormItems>
    );
  }

  function buildFormContent(disallowEdit, formValues, formHelpers) {
    const handleStatusChange = (newValue) => {
      if (meetingStatuses.find(s => s.id === newValue).key === 'scheduling_requested') {
        if (!formValues.schedulingRequestedDate) {
          formHelpers.setFieldValue("schedulingRequestedDate", moment().format('MM/DD/YYYY'));
        }
      }
    }

    return (
      <LotusForm>
        <LotusFormItems>
          {meetingStatuses &&
            <LotusSelect
              disabled={disallowEdit}
              name='meetingStatusId'
              required
              label='Status'
              items={meetingStatuses && meetingStatuses.map(s => {return {label: s.name, value: s.id}})}
              handleChange={handleStatusChange}
            />
          }
          <LotusTextInput
            disabled={true}
            name='coordinatorName'
            label='Coordinator'
          />
          <LotusTextInput
            disabled={true}
            name='schedulerName'
            label='Scheduler'
          />
          {meetingStatuses && meetingStatuses.find(s => s.id === formValues.meetingStatusId)?.key === 'scheduling_requested' &&
            <LotusDatePicker
              disabled={disallowEdit}
              name="schedulingRequestedDate"
              label="Scheduling Requested Date"
              required
            />
          }  
          <LotusDatePicker
            disabled={disallowEdit}
            name="dueDate"
            label="Due Date"
            required
          />
          <LotusDatePicker
            disabled={disallowEdit}
            name="lastMeetingDate"
            label="Last Meeting Date"
          />
          {meetingStatuses && meetingStatuses.find(s => s.id === formValues.meetingStatusId)?.key === 'scheduled' &&
            <LotusDatePicker
              disabled={disallowEdit}
              name="scheduledDate"
              label="Scheduled Date"
              required
            />
          }
          {currentProgramMeetingTypes &&
            <LotusSelect
              disabled={disallowEdit}
              name='meetingTypeId'
              label='Meeting Type'
              required
              items={currentProgramMeetingTypes && currentProgramMeetingTypes.map(s => {return {label: s.name, value: s.id}})}
            />   
          }
          <LotusDatePicker
            disabled={disallowEdit}
            name="meetingDate"
            label="Meeting Date"
          />
          <LotusSideBySide
            leftControl={<LotusTextInput
              disabled={disallowEdit}
              name='meetingTimeHoursMinutes'
              label='Meeting Time'
              time
            />}
            rightControl={<LotusSelect
              disabled={disallowEdit}
              name='meetingTimeAmPm'
              label='AM/PM'
              required={Boolean(formValues.meetingTimeHoursMinutes)}
              items={['AM','PM'].map(s => {return {label: s, value: s}})}
              disallowNoSelectionIfNotRequired={true}
            />}
          />
          <LotusTextInput
            disabled={disallowEdit}
            name='location'
            label='Location'
          />
          <LotusTextInput
            disabled={disallowEdit}
            name="notes"
            multiline
            rows={5}
            label="Notes"
          />
          {currentProgram.meetingConfig.timeSpentSchedulingMeetingEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='timeSchedulingMeetingMinutes'
              label='Time Spent Scheduling Meeting (Minutes)'
              integer
            />
          }
          <LotusExpandableItemList
            disabled={disallowEdit} 
            name="invitees" 
            itemName="Invitee"
            label="Invitees"
            buildItemSectionName={(item) => item.name}
            buildItemTitle={(item) => item.name || '[Name]'}
            buildItemSummary={(item, index) => {
              return (<div></div>);
            }}
            buildItemDetail={(item, index, isDisabled) => buildInviteePanel(item, index, isDisabled)}
            buildItemTemplate={() => {return {...emptyInvitee}}}
            allowRemove={true}
            showNav={false}
          />
          <LotusTextInput
            disabled={disallowEdit}
            name="schedulingNotes"
            multiline
            rows={5}
            label="Scheduling Notes"
          />
        </LotusFormItems>
      </LotusForm>
    );
  }
                
  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(meeting ? 'Edit' : 'Add')} ${meetingDisplayName}`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      buildFormContent={buildFormContent}
    />
  );
}
