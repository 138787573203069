import React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, useFormikContext } from 'formik';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { Stack } from '@mui/system';
import SectionCommentsChain from './SectionCommentsChain';
import Consents from 'components/Consents';


export const getConsentsStaticFormSchema = (requiredConsentTypes) => {
  return Yup.object().shape({
    consentList: Yup.array().test({
      name: 'missingRequiredConsents',
      test(value, ctx) {
        const missingConsentTypeNames = [];
        if (requiredConsentTypes && requiredConsentTypes.length > 0) {
          requiredConsentTypes.forEach(requiredConsent => {
            if (value && !value.find(c => c.agencyConsentTypeId === requiredConsent.id)) {
              missingConsentTypeNames.push(requiredConsent.name);
            }
          });
        }
        return missingConsentTypeNames.length > 0 ? ctx.createError({message: `Required consent(s) ${missingConsentTypeNames.join(', ')} are missing`}) : true;
      }
    }),
    consentCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
  });
}

export const getConsentsStaticFormSchemaExcludingRequiredChecks = (eligibilityAppConfig) => {
  return Yup.object().shape({
    
  });
}

export const buildConsentsStaticFormSectionNames = (eligibilityAppConfig) => {
  const lst = [];
  return lst;
}

export const initializeConsentsFormData = (formData) => {
  if (!formData) {
    return formData;
  }

  return {
    consentList: formData.consentList || [],
    consentCommentsChain: formData.consentCommentsChain || []
  };
}

export const getConsentsFormData = (formData) => {
    
  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    consentList, consentCommentsChain
  } = formData;

  const consents = {
    consentList, consentCommentsChain
  };
  return consents;
}

const EligibilityApplicationConsentsStaticForm = ({appConsentTypeIds, requiredConsentTypes, existingClientConfig, eligibilityAppConfig, disabled}) => {

  const { values, errors, setFieldValue } = useFormikContext();

  const onConsentAdded = async (consent) => {
    const lst = [...values.consentList, consent];
    setFieldValue('consentList', lst);
  };
  
  return values && existingClientConfig && eligibilityAppConfig && appConsentTypeIds && requiredConsentTypes && (
    <Stack direction="row">
      <LotusForm>
        <LotusFormSection disabled={disabled}>
          {appConsentTypeIds.length === 0 &&
          <LotusFormItem>
            <div>No consents configured for eligibility</div>
            </LotusFormItem>
          }
          {appConsentTypeIds.length > 0 &&
            <>
              {errors.consentList &&
                <LotusFormItem>
                  <div style={{color:'#b53f3f', marginTop: 10}}>
                    <ErrorMessage name="consentList" />
                  </div>
                </LotusFormItem>
              }
              <LotusFormItem>
                <Consents
                  consents={values.consentList}
                  consentTypeIds={appConsentTypeIds}
                  requiredConsentTypeIds={requiredConsentTypes.map(c => c.id)}
                  onConsentAdded={onConsentAdded}
                  disabled={disabled}
                />
              </LotusFormItem>
            </>
          }
        </LotusFormSection>
      </LotusForm>
      {appConsentTypeIds.length > 0 &&
        <div>
          <SectionCommentsChain chainFieldName="consentCommentsChain"/>
        </div>
      }
    </Stack>
  );
};

export function buildConsentsStaticForm({...props}) {
  return <EligibilityApplicationConsentsStaticForm {...props}/>
}