import React, { useEffect, useState } from 'react';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { useFormikContext } from 'formik';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useClient } from 'contexts/ClientContext';
import { useAgency } from 'contexts/AgencyContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

const buildSectionNames = () => {
  const lst = [
    "Lead Agency"
  ];
  return lst;
}

const ClientCareTeamStaticForm = ({disabled, childAgencies, isGranteeAgency}) => {

  const {agencyCaseManagers, loadAgencyCaseManagers} = useAgency();
  const [caseManagersList, setCaseManagersList] = useState();
  const { verifyPermission } = useAuthState();
  const { values, setFieldValue } = useFormikContext();
  const hasLeadCaseManagerPermission = !isGranteeAgency ? verifyPermission(PERMISSIONS.CAN_BE_A_LEAD_CASE_MANAGER) : false;

  useEffect(() => {
    if (values.leadAgency) {
      loadAgencyCaseManagers(values.leadAgency);
    }
  }, [values.leadAgency]);
  
  useEffect(() => {
    if(agencyCaseManagers && values.leadAgency in agencyCaseManagers) {
      const caseManagers = agencyCaseManagers[values.leadAgency].map(c => {return {label: c.name, value: c.id}});
      setCaseManagersList(caseManagers);
    }
  }, [agencyCaseManagers, values.leadAgency]);

  const getCaseManagerDetails = (caseManagerId) => {
    if (agencyCaseManagers && values.leadAgency in agencyCaseManagers) {
      const caseManager = agencyCaseManagers[values.leadAgency].filter(c => c.id === caseManagerId)[0];
      if (caseManager) {
        setFieldValue('caseManagerPhoneNumber', caseManager.phoneNumber);
        setFieldValue('caseManagerEmailAddress', caseManager.email);
      }
    }
  }

  const onLeadAgencyChange = (leadAgencyId) => {
    setFieldValue('leadCaseManager', '');
    setFieldValue('caseManagerPhoneNumber', '');
    setFieldValue('caseManagerEmailAddress', '');
    if (leadAgencyId) {
      loadAgencyCaseManagers(leadAgencyId);
    }
  }
  
  const onLeadCaseManagerChange = (leadCaseManagerId) => {
    if (leadCaseManagerId) {
      getCaseManagerDetails(leadCaseManagerId);
    }
  }

  return values && (
    <LotusForm>
      <LotusFormSection name="Lead Agency" disabled={disabled}>
        <LotusFormItems>
          {childAgencies &&
            <LotusSelect
                name="leadAgency"
                label="Agency Name"
                items={childAgencies}
                disabled={disabled}
                autoSort={false}
                handleChange={onLeadAgencyChange}
                required
            />
          }
          {caseManagersList && 
            <LotusSelect
                name="leadCaseManager"
                label="Lead Case Manager"
                items={caseManagersList}
                disabled={disabled || isGranteeAgency || !hasLeadCaseManagerPermission}
                autoSort={false}
                handleChange={onLeadCaseManagerChange}
            />
          }
          <LotusPhoneInput
            name="caseManagerPhoneNumber"
            label="Case Manager Phone Number"
            disabled={true}
          />
          <LotusTextInput
            name="caseManagerEmailAddress"
            label="Case Manager Email Address"
            disabled={true}
          />
        </LotusFormItems>
      </LotusFormSection>
    </LotusForm>
  );
};

export function buildStaticForm({...props}) {
  return (
      <ClientCareTeamStaticForm {...props}/>
  );
}

export default function ClientCareTeamForm ({clientId, disabled, userAgency}) {

  const { clientCareTeam, loadClientCareTeam, upsertClientCareTeam } = useClient();
  const [initialValues, setInitialValues] = useState();
  const {agenciesForGrantee, loadAgenciesForGrantee} = useAgencySearch();
  const [childAgenciesList, setChildAgenciesList] = useState();

  useEffect(() => {
    if (userAgency && userAgency.isGranteeAgency && userAgency.granteeAgencyId) {
      if (!agenciesForGrantee) {
        loadAgenciesForGrantee(userAgency.granteeAgencyId);
      }
    } else {
      setChildAgenciesList([{label: userAgency.name, value: userAgency.id}]);
    }
  }, [userAgency]);

  useEffect(() => {
    if(agenciesForGrantee && agenciesForGrantee.length > 0) {
      const childAgencies = agenciesForGrantee.filter(a => a.id !== userAgency.granteeAgencyId).map(c => {return {label: c.name, value: c.id}});
      setChildAgenciesList(childAgencies || []);
    }
  }, [agenciesForGrantee]);

  useEffect(() => {
    if (clientId) {
      loadClientCareTeam(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientCareTeam) {
      setInitialValues({
        leadAgency: clientCareTeam.careTeam?.leadAgencyId || '',
        leadCaseManager: clientCareTeam.careTeam?.leadCaseManagerId || '',
        caseManagerPhoneNumber: clientCareTeam.careTeam?.caseManager?.phoneNumber || '',
        caseManagerEmailAddress: clientCareTeam.careTeam?.caseManager?.email || ''
      });
    }
  }, [clientCareTeam]);

  const saveForm = async (values) => {
    await upsertClientCareTeam(clientId, values);
  }

  return initialValues && (
    <HybridForm 
      title="Care Team"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled, childAgencies: childAgenciesList, isGranteeAgency: userAgency?.isGranteeAgency})}
      staticFormSectionNameBuilder={() => buildSectionNames()}
      staticFormValues={initialValues}
      clientId={clientId}
      disabled={disabled}
      onSaveStaticFormValues={saveForm}
    />
  );
}