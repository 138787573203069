import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ProgramContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  currentProgram: undefined,
  currentProgramActivityLogStatuses: undefined,
  currentProgramActivityLogTypes: undefined,
  currentProgramActivityLogSubjects: undefined,
  currentProgramActivityLogWithOptions: undefined,
  currentProgramAdditionalClientData: undefined,
  currentProgramAssessments: undefined,
  currentProgramCarePlanLifeAreaIds: undefined,
  currentProgramCarePlanCategoryIds: undefined,
  currentProgramCarePlanGoalCloseReasons: undefined,
  currentProgramCarePlanGoalTypes: undefined,
  currentProgramCarePlanInterventionTypes: undefined,
  currentProgramConsentTypeIds: undefined,
  currentProgramContactTypes: undefined,
  currentProgramContactMethods: undefined,
  currentProgramContactMadeAttemptedOptions: undefined,
  currentProgramContactCategories: undefined,
  currentProgramContactSiteVisitTypes: undefined,
  currentProgramContactDelayReasons: undefined,
  currentProgramContactMissedReasons: undefined,
  currentProgramContactServiceSites: undefined,
  currentProgramContactReportOptions: undefined,
  currentProgramDocumentTypeIds: undefined,
  currentProgramEnrollmentStatuses: undefined,
  currentProgramEnrollmentCloseReasons: undefined,
  currentProgramEnrollmentAssignees: undefined,
  currentProgramInterpretationServices: undefined,
  currentProgramMeetingTypes: undefined,
  currentProgramMeetingInviteeTypes: undefined,
  currentProgramUsers: undefined,
  loadedPrograms: {},
  loadedProgramActivityLogStatuses: {},
  loadedProgramActivityLogTypes: {},
  loadedProgramActivityLogSubjects: {},
  loadedProgramActivityLogWithOptions: {},
  loadedProgramAdditionalClientData: {},
  loadedProgramAssessments: {},
  loadedProgramCarePlanLifeAreaIds: {},
  loadedProgramCarePlanCategoryIds: {},
  loadedProgramCarePlanGoalCloseReasons: {},
  loadedProgramCarePlanGoalTypes: {},
  loadedProgramCarePlanInterventionTypes: {},
  loadedProgramConsentTypeIds: {},
  loadedProgramContactTypes: {},
  loadedProgramContactMethods: {},
  loadedProgramContactMadeAttemptedOptions: {},
  loadedProgramContactCategories: {},
  loadedProgramContactSiteVisitTypes: {},
  loadedProgramContactDelayReasons: {},
  loadedProgramContactMissedReasons: {},
  loadedProgramContactServiceSites: {},
  loadedProgramContactReportOptions: {},
  loadedProgramDocumentTypeIds: {},
  loadedProgramEnrollmentStatuses: {},
  loadedProgramEnrollmentCloseReasons: {},
  loadedProgramEnrollmentAssignees: {},
  loadedProgramInterpretationServices: {},
  loadedProgramMeetingTypes: {},
  loadedProgramMeetingInviteeTypes: {},
  loadedProgramUsers: {}
};

const ProgramContext = createContext();

export const ProgramProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProgramContext.Provider value={{ state, dispatch }}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgram = () => {
  const { state, dispatch } = useContext(ProgramContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const setProgram = async (programId) => {
    try{
      addBusyBee('setProgram');

      let program = null;
      if (programId in state.loadedPrograms) {
        program = state.loadedPrograms[programId];
      } else {
        const url = `/api/program`;
        let result = await axios.post(url, {operationName: 'getProgramDetails', programId});
        program = parseApiResult(result.data).body;
      }

      dispatch({
        type: messageTypes.LOADING_PROGRAM_SUCCESS,
        payload: {
          program: program,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('setProgram');
    }
  };

  const loadProgramActivityLogPicklists = async () => {
    try {
      addBusyBee('loadProgramActivityLogPicklists');
      const toPost = { operationName: 'getProgramActivityLogPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let { 
        activityLogTypes,
        activityLogStatuses,
        activityLogSubjects,
        activityLogWithOptions
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS,
        payload: {
          activityLogTypes,
          activityLogStatuses,
          activityLogSubjects,
          activityLogWithOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramActivityLogPicklists');
    }
  };

  const loadProgramAgencies = async () => {
    try {
      addBusyBee('loadProgramAgencies');
      const toPost = { operationName: 'getProgramAgencies', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);
      let agencyIds = parseApiResult(data).body;    
      
      dispatch({
        type: messageTypes.LOADING_PROGRAM_AGENCIES_SUCCESS,
        payload: {
          agencyIds,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramAgencies');
    }
  };

  const loadProgramAssessments = async () => {
    try {
      addBusyBee('loadProgramAssessments');
      const toPost = {
        operationName: 'getProgramAssessments',
        programId: state.currentProgram.id
      };
      const { data } = await axios.post(`/api/program`, toPost);

      let assessments = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ASSESSMENTS_SUCCESS,
        payload: {
          assessments,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramAssessments');
    }
  };

  const loadProgramCarePlanPicklists = async () => {
    try {
      addBusyBee('loadProgramLifeAreas');
      const toPost = { operationName: 'getProgramCarePlanPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);
      let {
        carePlanLifeAreaIds,
        carePlanCategoryIds,
        carePlanGoalCloseReasons,
        carePlanGoalTypes,
        carePlanInterventionTypes
      } = parseApiResult(data).body; 

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS,
        payload: {
          carePlanLifeAreaIds,
          carePlanCategoryIds,
          carePlanGoalCloseReasons,
          carePlanGoalTypes,
          carePlanInterventionTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramLifeAreas');
    }
  };

  const loadProgramConsentTypes = async () => {
    try {
      addBusyBee('loadProgramConsentTypes');
      const url = `/api/program`;
      const result = await axios.post(url, {
        operationName: 'getProgramConsentTypes',
        programId: state.currentProgram.id
      });

      const consentTypeIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CONSENT_TYPES_SUCCESS,
        payload: {
          consentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramConsentTypes');
    }
  }

  const loadProgramContactPicklists = async () => {
    try {
      addBusyBee('loadProgramContactPicklists');
      const toPost = { operationName: 'getProgramContactPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {
        contactTypes,
        contactMethods,
        contactMadeAttemptedOptions,
        contactCategories,
        contactSiteVisitTypes,
        contactWithOptions,
        contactDelayReasons,
        contactMissedReasons,
        contactServiceSites,
        contactReportOptions
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS,
        payload: {
          contactTypes,
          contactMethods,
          contactMadeAttemptedOptions,
          contactCategories,
          contactSiteVisitTypes,
          contactWithOptions,
          contactDelayReasons,
          contactMissedReasons,
          contactServiceSites,
          contactReportOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramContactPicklists');
    }
  }

  const loadProgramDocumentTypes = async () => {
    try {
      addBusyBee('loadProgramDocumentTypes');
      const toPost = { operationName: 'getProgramDocumentTypes', programId: state.currentProgram.id };
      const url = `/api/program`;
      const result = await axios.post(url, toPost);

      const documentTypeIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS,
        payload: {
          documentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramDocumentTypes');
    }
  };

  const loadProgramEnrollmentPicklists = async () => {
    try {
      addBusyBee('loadProgramEnrollmentPicklists');
      const toPost = { operationName: 'getProgramEnrollmentPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {enrollmentStatuses, enrollmentCloseReasons, enrollmentAssignees } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS,
        payload: {
          enrollmentStatuses,
          enrollmentCloseReasons,
          enrollmentAssignees
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramEnrollmentPicklists');
    }
  };

  const loadProgramInterpretationServices = async () => {
    try {
      addBusyBee('loadProgramInterpretationServices');
      const toPost = { operationName: 'getProgramInterpretationServices', programId: state.currentProgram.id};
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let interpretationServices = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS,
        payload: {
          interpretationServices,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramInterpretationServices');
    }
  };

  const loadProgramMeetingPicklists = async () => {
    try {
      addBusyBee('loadProgramMeetingTypes');
      const toPost = { operationName: 'getProgramMeetingPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {
        meetingTypes,
        meetingInviteeTypes
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS,
        payload: {
          meetingTypes,
          meetingInviteeTypes
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramMeetingTypes');
    }
  };

  const loadProgramUsers = async () => {
    try {
      addBusyBee('loadProgramUsers');
      const toPost = { operationName: 'getProgramUsers', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let users = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_USERS_SUCCESS,
        payload: {
          users
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramUsers');
    }
  };

  return {
    ...state,
    setProgram,
    loadProgramActivityLogPicklists,
    loadProgramAgencies,
    loadProgramAssessments,
    loadProgramCarePlanPicklists,
    loadProgramConsentTypes,
    loadProgramContactPicklists,
    loadProgramDocumentTypes,
    loadProgramEnrollmentPicklists,
    loadProgramInterpretationServices,
    loadProgramMeetingPicklists,
    loadProgramUsers
  };
};
