import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';
import { Error } from '@mui/icons-material';
import { Stack } from '@mui/material';

// Use this as a last resort if the pre-built input field types don't apply for some reason
// But this is intended to be a common building block for the other input fields
export default function LotusInputField({
  name,
  label,
  helperText,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  maxLength,
  integer,
  floatingPoint,
  maxDecimalPlaces,
  minValue,
  maxValue,
  time,
  militaryTime,
  startAdornment,
  endAdornment,
  lettersOnly,
  multiline,
  rows,
  handleChange,
  regexToMatch,
  regexValidationMessage
}) {
  const [field, meta, utils] = useField({name, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && (val === undefined || val === null || val === '')) {
      return `${lbl} is required`;
    }
    if (time && val && !moment(val, "h:mm", true).isValid()) {
      return `${lbl} must be a time (h:mm)`;
    }
    if (militaryTime && val && !moment(val, "H:mm", true).isValid()) {
      return `${lbl} must be a time (H:mm)`;
    }
    if (integer && val) {
      const regex = RegExp(`^[0-9]+$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be an integer`;
      }
      if (!isNaN(minValue) && Number(val) < Number(minValue)) {
        return `${lbl} must be >= ${minValue}`;
      }
      if (!isNaN(maxValue) && Number(val) > Number(maxValue)) {
        return `${lbl} must be <= ${maxValue}`;
      }
    }
    if (lettersOnly && val) {
      const regex = RegExp(`^[a-zA-Z]+$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must only contain letters`;
      }
    }
    if (floatingPoint && val) {
      const regex = RegExp(`^([0-9]+([.][0-9]${maxDecimalPlaces !== undefined ? `{0,${maxDecimalPlaces}}` : '*'})?|[.][0-9]+)$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be a number ${maxDecimalPlaces !== undefined ? `with ${maxDecimalPlaces} decimal places` : ''}`;
      }
      if (!isNaN(minValue) && Number(val) < Number(minValue)) {
        return `${lbl} must be >= ${minValue}`;
      }
      if (!isNaN(maxValue) && Number(val) > Number(maxValue)) {
        return `${lbl} must be <= ${maxValue}`;
      }
    }
    if (regexToMatch && val) {
      const regex = RegExp(regexToMatch,"g");
      if (!regex.test(val)) {
        return regexValidationMessage || `${lbl} must match regex`;
      }
    }
  }});

  // Stupid react has inputProps and InputProps
  const labelText = label;
  const inputProps = {};
  if (maxLength) {
    inputProps.maxLength = maxLength;
  }
  const InputProps = {};
  if (startAdornment) {
    InputProps.startAdornment = <InputAdornment disableTypography position="start">{startAdornment}</InputAdornment>;
  }
  if (endAdornment) {
    InputProps.endAdornment = <InputAdornment disableTypography position="end">{endAdornment}</InputAdornment>;
  }
  if (meta.touched && meta.error) {
    InputProps.endAdornment = <InputAdornment disableTypography position="end"><Error color='error'/></InputAdornment>;
  }

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    if (handleChange) {
      handleChange(newValue);
    }
    field.onChange(e);
  };

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly &&
        <TextField
          fullWidth
          variant="outlined"
          margin="dense"
          size="small"
          required={required}
          style={{width: '100%', marginTop: 0, marginBottom: 0}} // Mui-TextField has margins which make things look inconsistent with other controls
          onBlur={(e) => {utils.setValue(e.target.value.trim()); utils.setTouched(true, true);}}
          {...field}
          label={labelText}
          disabled={disabled}
          inputProps={inputProps}
          InputProps={InputProps}
          multiline={multiline}
          rows={multiline && rows}
          error={meta.touched && Boolean(meta.error)} 
          onChange={handleLocalChange}
        />
      }
      {readOnly &&
        <LotusReadOnlyFieldView startAdornment={startAdornment} endAdornment={endAdornment} label={labelText} value={field.value} multiline={multiline} rows={rows} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
