import { createTheme } from '@mui/material/styles';

const palette = {
  primary: {
    main: '#294055',
    light: '#546B82',
    dark: '#001a2c',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#2699A8',
    light: '#63cada',
    dark: '#006b79',
    contrastText: '#ffffff'
  },
  error: {
    main: '#D32F2F',
    dark: '#B01A1A',
    light: '#FBEAEA',
    contrastText: '#ffffff'
  },
  success: {
    main: '#2E7D32',
    light: '#EAF2EA',
    dark: '#1B5E20',
    contrastText: '#ffffff'
  },
  warning: {
    main: '#ed6c02',
    light: '#ffe0b2',
    dark: '#e65100',
    contrastText: '#ffffff'
  },
  info: {
    main: '#0288d1',
    dark: '#01579b',
    light: '#e1f1f9',
    contrastText: '#ffffff'
  },
  text: {
     primary: '#000000',
     secondary: '#666666',
     disabled: '#00000061',
  //   selected: '#B4FFFF',
  //   completed: 'green',
  //   tabs: '#7A7A7A',
  //   outlinedButtonText: '#4A97A6',
  },
  other: {
    card: '#d9d9d9',
    tabs: '#666666',
    divider: '#0000001f',
    iconButton: '#999999'
  //   outlinedBorder: '#0000003b',
  //   backdropOverlay: '#00000080',
  //   filledInputBackground: '#0000000f',
  //   standardInputLine: '#0000006b',
  //   snackbar: '#323232',
  //   ratingActive: '#ffb400',
  //   focusRingColor: '#005fcc'
  }
};

const theme = createTheme({
  typography: {
    h1: {
      color: 'black',
      fontSize: '34px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '0.25px',
      wordWrap: 'break-word'
    },
    h2: {
      color: 'black',
      fontSize: '24px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: 0,
      wordWrap: 'break-word'
    },
    h3: {
      color: 'black',
      fontSize: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    h4: {
      color: 'black',
      fontSize: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    h5: {
      color: 'black',
      fontSize: '20px',
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    subtitle1: {
      color: 'black',
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    subtitle2: {
      color: 'black',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 0.10,
      wordWrap: 'break-word'
    },
    body1: {
      color: 'black',
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    body2: {
      color: 'black',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.17,
      wordWrap: 'break-word'
    },
    buttonLarge: {
      color: 'black',
      fontSize: '15px',
      fontFamily: 'Inter',
      fontWeight: 500,
      textTransform: 'uppercase',
      lineHeight: '26px',
      letterSpacing: 0.46,
      wordWrap: 'break-word'
    },
    buttonMedium: {
      color: 'black',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 500,
      textTransform: 'uppercase',
      lineHeight: '24px',
      letterSpacing: 0.40,
      wordWrap: 'break-word'
    },
    caption: {
      color: 'black',
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.40,
      wordWrap: 'break-word'
    },
    overline: {
      color: 'black',
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: 400,
      textTransform: 'uppercase',
      lineHeight: '32px',
      letterSpacing: 1,
      wordWrap: 'break-word'
    },
    avatarLetter: {
      color: 'black',
      fontSize: '20px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.14,
      wordWrap: 'break-word'
    },
    inputLabel: {
      color: 'black',
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    helperText: {
      color: 'black',
      fontSize: '12px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0.40,
      wordWrap: 'break-word'
    },
    inputText: {
      color: 'black',
      fontSize: '16px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.15,
      wordWrap: 'break-word'
    },
    tooltip: {
      color: 'black',
      fontSize: '10px',
      fontFamily: 'Inter',
      fontWeight: 500,
      lineHeight: '14px',
      wordWrap: 'break-word'
    },
    version: {
      color: 'black',
      fontSize: '10px',
      fontFamily: 'Inter',
      fontWeight: 400,
      lineHeight: '10px',
      wordWrap: 'break-word'
    },
  },
  palette: palette,
  components: {
    // This makes labels for select input fields stay black instead of turning red when there's an error
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root.Mui-error': {
            color: '#000000',
          },
        },
      },
    },
    // This makes asterisk for text input fields stay black instead of turning red when there's an error
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          '&.Mui-error': {
            color: '#000000',
          }
        },
      },
    },
    MuiDataGrid : {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'rgb(202, 202, 202, .20)'
          }
        }
      }
    },
    MuiSwitch: {
      // Put different icons and colors on the switch based on whether checked or not
      styleOverrides: {
        root: {
          width: 50,
          height: 26,
          padding: 7,
          marginRight: 16, // more space between toggle and label
          '& .MuiSwitch-switchBase': {
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
              color: palette.success.dark,
              transform: 'translateX(22px)',
              '& .MuiSwitch-thumb:before': {
                left: -1,
                top: -2,
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                  '#ffffff',
                )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>')`,
              },
              '& + .MuiSwitch-track': {
                backgroundColor: palette.success.main,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            width: 24,
            height: 24,
            '&::before': {
              content: "''",
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: -1,
              top: -1,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="15" width="15" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#B0B0B0',
              )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>')`,
            },
          },
          '& .MuiSwitch-track': {
            borderRadius: 20 / 2,
          },
        }
      }
    }
  }
});

export default theme;