import { useEffect, useState } from "react";
import { Button, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";

// MultiFieldSearch for client list
export const MultiFieldSearch = ({doSearch}) => {
  const [dob, setDob] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [clientIdentifier, setClientIdentifier] = useState('');
  const [disableMultiSearch, setDisableMultiSearch] = useState(true);

  useEffect(() => {
    if (clientIdentifier || ((firstName || lastName) && dob)) {
      setDisableMultiSearch(false);
    } else {
      setDisableMultiSearch(true);
    }

  }, [firstName, lastName, dob, clientIdentifier]);

  return (
    <Grid container spacing={1} style={{ marginTop: '20px', marginBottom: '20px' }}>
      `<Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          label='First Name'
          value={firstName}
          onChange={(e) => { setFirstName(e.target.value.replace(/[^a-zA-ZÀ-ÿ0-9-' ]/g, '')); }}
          onKeyDown={e => e.key === 'Enter' && !disableMultiSearch ?
            doSearch({ first_name: firstName, last_name: lastName, birth_date: dob, client_id: clientIdentifier }) : ''}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          label='Last Name'
          value={lastName}
          onChange={(e) => { setLastName(e.target.value.replace(/[^a-zA-ZÀ-ÿ0-9-' ]/g, '')); }}
          onKeyDown={e => e.key === 'Enter' && !disableMultiSearch ?
            doSearch({ first_name: firstName, last_name: lastName, birth_date: dob, client_id: clientIdentifier }) : ''}
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: 8 }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            slotProps={{
              textField: {
                variant: "outlined",
                size: "small"
              },
            }}
            label='Birth Date'
            onChange={(newValue) => setDob(moment(newValue, 'MM/DD/YYYY', true).isValid() ? moment(newValue).format('MM/DD/YYYY') : '')}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="dense"
          size="small"
          label="Client Identifier"
          value={clientIdentifier}
          onChange={(e) => { setClientIdentifier(e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')) }}
          onKeyDown={e => e.key === 'Enter' && !disableMultiSearch ?
            doSearch({ first_name: firstName, last_name: lastName, birth_date: dob, client_id: clientIdentifier }) : ''}
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: 10 }}>
        <Button variant="contained" color="secondary" disabled={disableMultiSearch} startIcon={<SearchIcon />}
          onClick={() => {
            doSearch({ first_name: firstName, last_name: lastName, birth_date: dob, client_id: clientIdentifier })
          }}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
}

export default MultiFieldSearch;