export const calculateMonthlyValue = (periodId, values, periods) => {
  if (!periodId || !values || values.length == 0) {
    return 0;
  }

  let numVals = 0;
  let total = 0;
  let periodKey = periods.find(p => p.id === periodId)?.itemKey;
  if (periodKey) {
    values.forEach(sample => {
      if (sample.amount) {
        let num = Number(sample.amount);
        if (!isNaN(num)) {
          if (periodKey === 'weekly') {
            total += num * 4.33;
          } else if (periodKey === 'biweekly') {
            total += num * 2.17;
          } else if (periodKey === 'monthly') {
            total += num;
          } else if (periodKey === 'quarterly') {
            total += num / 3;
          } else if (periodKey === 'annually') {
            total += num / 12;
          }
          numVals++;
        }
      }
    });
  }

  if (numVals > 0) {
    return total / numVals;
  }
  return 0;
}

export const calculateIncomeFields = (values, incomeSourceTypes, periods) => {
  const monthlyHouseholdGrossIncomeComponents = [];
  if (values.clientHasIncome && values.clientIncomeSources) {
    values.clientIncomeSources.forEach(src => {
      monthlyHouseholdGrossIncomeComponents.push({typeId: src.incomeSourceTypeId, income: calculateMonthlyValue(src.incomePeriodId, src.samples, periods)});
    });
  }

  let totalHouseholdSize = 1;
  if (values.hasOtherHouseholdMembers && values.otherHouseholdMembers) {
    if (values.otherHouseholdMembersHaveIncome && values.otherHouseholdIncomeSources) {
      values.otherHouseholdIncomeSources.forEach(src => {
        monthlyHouseholdGrossIncomeComponents.push({typeId: src.incomeSourceTypeId, income: calculateMonthlyValue(src.incomePeriodId, src.samples, periods)});
      });
    }
    totalHouseholdSize += values.otherHouseholdMembers.length;
  }

  let totalIncomeAdjustments = 0;
  if (values.hasIncomeAdjustments && values.incomeAdjustments) {
    values.incomeAdjustments.forEach(adj => {
      totalIncomeAdjustments += calculateMonthlyValue(adj.incomePeriodId, adj.samples, periods);
    });
  }

  let monthlyHouseholdGrossIncome = monthlyHouseholdGrossIncomeComponents.reduce((total, component) => total + component.income, 0);
  let monthlyHouseholdAdjustedGrossIncome = monthlyHouseholdGrossIncome - totalIncomeAdjustments;

  // Now that income source types are custom, we cant do this logic unless we allow configuring them further, which not doing for now
  let incomeModificationAmount = 0;
  // let incomeModificationAmount = monthlyHouseholdGrossIncomeComponents.reduce((total, component) => {
  //   let incomeSourceTypeKey = incomeSourceTypes.find(ist => ist.id === component.typeId)?.itemKey;
  //   if (incomeSourceTypeKey === 'ssi' || incomeSourceTypeKey === 'tax_child_sup') {
  //     return total + component.income;
  //   } else {
  //     return total;
  //   }
  // }, 0);

  let monthlyHouseholdModifiedAdjustedGrossIncome = monthlyHouseholdAdjustedGrossIncome - incomeModificationAmount;

  let householdFederalPovertyLevelPct = null;
  let magiHouseholdFederalPovertyLevelPct = null;

  if (values.federalPovertyLineBaseAmount && values.federalPovertyLineIncrementalAmount) {
    const povertyLevelThresholdForHouseholdSize = Number(values.federalPovertyLineBaseAmount) + ((totalHouseholdSize-1) * Number(values.federalPovertyLineIncrementalAmount));
    householdFederalPovertyLevelPct = monthlyHouseholdGrossIncome / povertyLevelThresholdForHouseholdSize * 12 * 100;
    magiHouseholdFederalPovertyLevelPct = Math.max(monthlyHouseholdModifiedAdjustedGrossIncome,0) / povertyLevelThresholdForHouseholdSize * 12 * 100;
  }

  const result = {
    totalHouseholdSize: `${totalHouseholdSize}`,
    monthlyHouseholdGrossIncome: Number(monthlyHouseholdGrossIncome).toFixed(2),
    householdFederalPovertyLevelPct: householdFederalPovertyLevelPct ? Number(householdFederalPovertyLevelPct)?.toFixed(2) : '',
    totalIncomeAdjustments: Number(totalIncomeAdjustments).toFixed(2),
    monthlyHouseholdAdjustedGrossIncome: Number(monthlyHouseholdAdjustedGrossIncome).toFixed(2),
    monthlyHouseholdModifiedAdjustedGrossIncome: Number(monthlyHouseholdModifiedAdjustedGrossIncome).toFixed(2),
    magiHouseholdFederalPovertyLevelPct: magiHouseholdFederalPovertyLevelPct ? Number(magiHouseholdFederalPovertyLevelPct).toFixed(2) : ''
  };

  return result;
}