import { useFormDefinitions } from 'contexts/DynamicFormContext';
import { useEffect, useState } from 'react';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import Stack from '@mui/material/Stack';

export default function FormKeys({
  values,
  touched,
  errors,
  formTypesRequiringProgram,
  handleChange,
  setFieldValue
}) {
  const { formTypes, loadFormTypes, assessmentTypes, loadAssessmentTypes, agencyPrograms, loadAgencyPrograms } = useFormDefinitions();

  const [selectedFormTypeId, setSelectedFormTypeId] = useState();

  const [programList, setProgramList] = useState();
  const [assessmentTypeList, setAssessmentTypeList] = useState();

  useEffect(() => {
    if (!formTypes) {
      loadFormTypes();
    }
  }, []);

  useEffect(() => {
    if (selectedFormTypeId && formTypes && formTypesRequiringProgram.includes(formTypes.find((ft) => ft.id === selectedFormTypeId).key)) {
      loadAgencyPrograms();
    }
  }, [selectedFormTypeId]);

  useEffect(() => {
    if (agencyPrograms) {
      setProgramList(agencyPrograms.map((p) => {return { label: p.name, value: p.id };}));
    }
  }, [agencyPrograms]);

  useEffect(() => {
    if (!assessmentTypes && selectedFormTypeId && formTypes && formTypes.find((ft) => ft.id === selectedFormTypeId).key === 'assessment') {
      loadAssessmentTypes();
    }
  }, [selectedFormTypeId]);

  useEffect(() => {
    if (assessmentTypes) {
      setAssessmentTypeList(assessmentTypes.map((p) => {return { label: p.name, value: p.id };}));
    }
  }, [assessmentTypes]);

  const onFormTypeChange = (newValue) => {
    if (newValue) {
      setSelectedFormTypeId(newValue);
      setFieldValue('formSubtypeId', '');
      setFieldValue('formSubtypeCustomName', '');
      setFieldValue('formSubtypeCustomSequence', '');
    }
  };

  return (
    <>
      {formTypes && (
        <LotusFormItem>
          <LotusSelect
            label="Form Type"
            id="formTypeId"
            name="formTypeId"
            items={formTypes
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((ft) => { return { label: ft.name, value: ft.id };})}
            required
            value={values.formTypeId}
            handleChange={onFormTypeChange}
            style={{ width: '400px' }}
            error={touched && touched.formTypeId && errors && Boolean(errors.formTypeId)}
          />
        </LotusFormItem>
      )}
      {formTypes && selectedFormTypeId && (
        <LotusFormItem>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LotusSelect
              label="Form Subtype"
              id="formSubtypeId"
              name="formSubtypeId"
              items={(formTypes.find(t => t.id === selectedFormTypeId).subtypes || [])
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((ft) => { return { label: ft.name, value: ft.id };})}
              style={{ width: 200 }}
              error={touched && touched.formSubtypeId && errors && Boolean(errors.formSubtypeId)}
            />
            <div>
              or
            </div>
            <LotusTextInput
              id="formSubtypeCustomName"
              name="formSubtypeCustomName"
              label="Custom Form Subtype Name"
              style={{ width: 300}}
            />
            <LotusTextInput
              id="formSubtypeCustomSequence"
              name="formSubtypeCustomSequence"
              label="Custom Form Subtype Sequence"
              style={{width: 300}}
            />
          </Stack>
        </LotusFormItem>
      )}
      {programList && formTypes && selectedFormTypeId &&
        formTypesRequiringProgram.includes(formTypes.find((ft) => ft.id === values.formTypeId).key) && (
          <LotusFormItem>
            <LotusSelect
              label="Program"
              id="programId"
              name="programId"
              items={programList}
              value={values.programId}
              onChange={handleChange}
              style={{ width: '400px' }}
              error={touched && touched.programId && errors && Boolean(errors.programId)}
            />
          </LotusFormItem>
        )}
      {assessmentTypeList && selectedFormTypeId && formTypes &&
        formTypes.find((ft) => ft.id === selectedFormTypeId).key ==='assessment' && (
          <LotusFormItem>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LotusSelect
                label="Assessment Type"
                id="assessmentTypeId"
                name="assessmentTypeId"
                items={assessmentTypeList}
                value={values.assessmentTypeId}
                onChange={handleChange}
                style={{ width: 200 }}
                error={touched && touched.assessmentTypeId && errors && Boolean(errors.assessmentTypeId)}
              />
              <div>
                or
              </div>
              <LotusTextInput
                id="newAssessmentTypeName"
                name="newAssessmentTypeName"
                label="New Assessment Type Name"
                style={{ width: 300 }}
              />
            </Stack>
          </LotusFormItem>
        )}
    </>
  );
}
