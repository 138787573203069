import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusSelect from "components/form/select/LotusSelect";
import { useFormikContext } from "formik";
import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import { v4 as uuidv4 } from 'uuid';
import LotusItemList from "components/form/list/LotusItemList";
import { cloneDeep } from "lodash";
import LotusSingleAgencyFileUploader from "components/form/uploader/LotusSingleAgencyFileUploader";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

function ConsentTypeViewImpl({
  agency,
  agencyLanguages
}) {

  const { values, setFieldValue } = useFormikContext();

  const languageDocumentTemplate = {id: uuidv4(), languageId: '', document: {}};

  return (
    <>
      <LotusSubsectionContent excludeTopPadding>
        <LotusStackedFormItems>
          <LotusSwitch
            name="discontinued"
            label="Discontinue"
          />
          <LotusTextInput
            required
            name="name"
            label="Name of consent"
          />
          <LotusSwitch
            name="isAgencyWide"
            label="Mandatory agency-wide"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Written signature and/or verbal consent collected" />
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="supportsWritten"
            label="Written signature"
          />
          <LotusSwitch
            name="supportsVerbal"
            label="Verbal consent"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Consent expiration" />
      <LotusSubsectionContent>
        <LotusSelect
          name="expirationMonths"
          label="Expiration Months"
          items={[
            { label: 'No expiration', value: 0 },
            { label: '6 months', value: 6 },
            { label: '12 months', value: 12 },
            { label: '18 months', value: 18 },
            { label: '24 months', value: 24 },
            { label: '36 months', value: 36 }
          ]}
          autoSort={false}
          required
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title="Upload PDF of default consent document" />
      <LotusSingleAgencyFileUploader
        name="defaultConsentDocument"
        label="Default Consent Document"
        granteeId={agency.id}
        agencyId={agency.id}
        subfolderName="consents"
        generatePreview
        required
      />
      <LotusSubsectionHeader title="Languages" />
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="supportMultipleLanguages"
            label='Support Multiple Languages'
            handleChange={(isChecked) => {
              if (isChecked && values.languageDocuments.length === 0) {
                setFieldValue("languageDocuments", [cloneDeep(languageDocumentTemplate)]);
              }
            }}
          />
          {values.supportMultipleLanguages &&
            <LotusItemList
              name="languageDocuments"
              label="Upload Additional Language Versions"
              itemLabel="Another Language"
              onRemove={(index) => {
                if (values.languageDocuments.length === 1) {
                  setFieldValue("supportMultipleLanguages", false);
                }
              }}
              buildItemTemplate={() => {return cloneDeep(languageDocumentTemplate)}}
              buildItemDetail={(item, index) => { return (   
                <LotusStackedFormItems key={index}>
                  {agencyLanguages &&
                    <LotusSelect
                      required
                      name={`languageDocuments.${index}.languageId`}
                      label="Select Language Version"
                      items={agencyLanguages.map(al => {return {label: al.name, value: al.languageId}})}
                    />
                  }
                  <LotusSingleAgencyFileUploader
                    name={`languageDocuments.${index}.document`}
                    label="Language Consent Document"
                    granteeId={agency.id}
                    agencyId={agency.id}
                    subfolderName="consents"
                    required
                  />
                </LotusStackedFormItems>
              )}}
            />
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
    </>
  );
}

export default function ConsentTypeView({ 
  consentType,
  agency,
  agencyLanguages,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {

    let initialValue = consentType ? {
      id: consentType.id,
      name: consentType.name,
      isAgencyWide: consentType.isAgencyWide,
      supportsWritten: consentType.supportsWritten,
      supportsVerbal: consentType.supportsVerbal,
      expirationMonths: consentType.expirationMonths !== undefined && consentType.expirationMonths !== null ? consentType.expirationMonths : '',
      discontinued: !consentType.isActive,
      defaultConsentDocument:
        consentType.defaultConsentDocumentUniqueFileName ? {
          displayName: consentType.defaultConsentDocumentFileName,
          uniqueName: consentType.defaultConsentDocumentUniqueFileName,
          type: consentType.defaultConsentDocumentFileType,
          thumbnail: consentType.defaultConsentDocumentThumbnail,
          preview: consentType.defaultConsentDocumentPreview,
          file: {
            name: consentType.defaultConsentDocumentUniqueFileName,
          },
        } : {},
      languageDocuments: consentType.languageDocuments.map((ld) => {
        return {
          id: ld.id,
          languageId: ld.languageId,
          languageName: ld.languageName,
          document: {
            displayName: ld.consentDocumentFileName,
            uniqueName: ld.consentDocumentUniqueFileName,
            type: ld.consentDocumentFileType,
            thumbnail: ld.consentDocumentThumbnail,
            file: {
              name: ld.consentDocumentUniqueFileName,
            },
          },
        };
      }),
      supportMultipleLanguages: consentType.languageDocuments.length > 0,
    } : {
      name: '',
      isAgencyWide: false,
      supportsWritten: false,
      supportsVerbal: false,
      expirationMonths: '',
      discontinued: false,
      defaultConsentDocument: {},
      languageDocuments: [],
      defaultConsentDocumentError: '',
      supportMultipleLanguages: false,
    };

    setInitialValues(initialValue);

  }, [consentType]);

  return initialValues && (
    <LotusStaticForm
      title={`${consentType ? 'Edit' : 'Add'} consent${consentType ? `: ${consentType.name}` : ''}`}
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <ConsentTypeViewImpl agency={agency} agencyLanguages={agencyLanguages} />
    </LotusStaticForm>
  );
  
}