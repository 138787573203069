import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

const PREFIX = 'ActionDialog';

const classes = {
  title: `${PREFIX}-title`,
  backdrop: `${PREFIX}-backdrop`,
  closeButton: `${PREFIX}-closeButton`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.backdrop}`]: {
    zIndex: 1000,
  },
  [`& .${classes.title}`]: {
    fontSize: 22,
    fontWeight: 600,
  },
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.buttonContainer}`]: {
    paddingTop: 20,
    marginRight: 20,
    button: {
      float: 'right',
      marginLeft: 10,
    },
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle classnames={classes.title} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = MuiDialogContent;

const DialogActions = MuiDialogActions;

export default function ActionDialog({
  maxWidth = 'sm',
  actions,
  content,
  title,
  open,
  handleClose,
  showLoading = false,
  scroll="paper"
}) {
  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      scroll={scroll}
    >
      <Backdrop className={classes.backdrop} open={showLoading}>
        <CircularProgress size={40} />
      </Backdrop>

      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>

      <DialogContent>{content}</DialogContent>

      {actions && (
        <DialogActions>
          <StyledGrid className={classes.buttonContainer}>{actions}</StyledGrid>
        </DialogActions>
      )}
    </StyledDialog>
  );
}
