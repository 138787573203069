import React from 'react';
import { useParams } from 'react-router-dom';
import ImportsExportsPage from './ImportsExportsPage';
import { DataImportProvider } from 'contexts/DataImportContext';
import { DataExportProvider } from 'contexts/DataExportContext';

export default function ImportExportManagement() {

  const { page } = useParams();

  return (
    <div>
      {page === 'list' &&
        <DataImportProvider>
          <DataExportProvider>
            <ImportsExportsPage />
          </DataExportProvider>
        </DataImportProvider>
      }
    </div >
  );
}
