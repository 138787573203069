import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './AgencyManagementContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';


const AgencyManagementContext = createContext();

const initialState = {
  agency: undefined,
  assessments: undefined,
  assessmentTypes: undefined,
  documentTypes: undefined,
  consentTypes: undefined,
  languages: undefined,
  providerTypes: undefined,
  programs: undefined,
  clientInfoCustomLists: undefined,
  metrics: undefined,
  agencyMetrics: undefined,
  granteePermissionTemplates: undefined
};

export const AgencyManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgencyManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </AgencyManagementContext.Provider>
  );
};

export const useAgencyManagement = () => {
  const { state, dispatch } = useContext(AgencyManagementContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadAgencyAssessments = async () => {

    if (state.agency) {
      try {
        addBusyBee('loadAgencyAssessments');
        
        const toPost = {
          operationName: 'getAgencyAssessments',
          agencyId: state.agency.id
        }

        const url = `/api/agency`;
        const { data } = await axios.post(url, toPost);
        const assessments = parseApiResult(data).body;

        dispatch({
          type: messageTypes.LOADING_AGENCY_ASSESSMENTS_SUCCESS,
          payload: {
            assessments
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('loadAgencyAssessments');
      }
    }
  };

  const loadAgencyAssessmentTypes = async () => {

    if (state.agency) {
      try {
        addBusyBee('loadAgencyAssessmentTypes');
        
        const toPost = {
          operationName: 'getAgencyAssessmentTypes',
          agencyId: state.agency.id
        }

        const url = `/api/agency`;
        const { data } = await axios.post(url, toPost);
        const assessmentTypes = parseApiResult(data).body;

        dispatch({
          type: messageTypes.LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS,
          payload: {
            assessmentTypes
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('loadAgencyAssessmentTypes');
      }
    }
  };

  const createAgencyAssessment = async (assessmentData) => {
    try {
      addBusyBee('createAgencyAssessment');

      const toPost = {
        operationName: 'addAgencyAssessment',
        assessment: {
          agencyId: state.agency.id,
          name: assessmentData.name,
          assessmentTypeId: assessmentData.assessmentTypeId,
          expirationReminderDays: parseInt(assessmentData.expirationReminderDays, 10),
          assessmentTimeCycleDays: parseInt(assessmentData.assessmentTimeCycleDays, 10),
          waitTimeToReassessmentDays: parseInt(assessmentData.waitTimeToReassessmentDays, 10),
          startedAssessmentExpirationHours: parseInt(assessmentData .startedAssessmentExpirationHours, 10),
        }
      };
      
      const url = `/api/agency`;
      const { data }  = await axios.post(url, toPost);
      const { assessment } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.CREATING_AGENCY_ASSESSMENT_SUCCESS,
        payload: {
          assessment
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createAgencyAssessment');
    }
  };

  const updateAgencyAssessment = async (assessmentData) => {
    try {
      addBusyBee('updateAgencyAssessment');

      const toPost = {
        operationName: 'updateAgencyAssessment',
        agencyId: state.agency.id,
        assessment: assessmentData
      };

      const url = `/api/agency`;
      const { data }  = await axios.post(url, toPost);
      const { assessment } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_ASSESSMENT_SUCCESS,
        payload: {
          assessment: assessment
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyAssessment');
    }
  };

  const loadAgencyProviderTypes = async () => {
    try {
      addBusyBee('loadAgencyProviderTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyProviderTypes', agencyId: state.agency.id});
      const providerTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_PROVIDER_TYPES_SUCCESS,
        payload: {
          providerTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyProviderTypes');
    }
  };

  const updateAgencyProviderTypes = async (selectedProviderTypes) => {
    try {
      addBusyBee('updateAgencyProviderTypes');

      const toPost = {
        operationName: 'updateAgencyProviderTypes',
        selectedProviderTypes: selectedProviderTypes,
        agencyId: state.agency.id
      }

      const url = `/api/agency/`;
      const { data } = await axios.post(url, toPost);
      const { providerTypes } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_PROVIDER_TYPES_SUCCESS,
        payload: {
          providerTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyProviderTypes');
    }
  };

  const updateAgencyOptionalFeatures = async (agencyOptionalFeatures) => {
    try {
      addBusyBee('updateAgencyOptionalFeatures');

      const toPost = {
        operationName: 'updateAgencyOptionalFeatures',
        optionalFeatures: agencyOptionalFeatures,
        agencyId: state.agency.id
      }

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const { optionalFeatures } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_OPTIONAL_FEATURES_SUCCESS,
        payload: {
          optionalFeatures,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyOptionalFeatures');
    }
  };

  const loadAgencyLanguages = async () => {
    try {
      addBusyBee('loadAgencyLanguages');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyLanguages', agencyId: state.agency.id});
      const languages = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_LANGUAGES_SUCCESS,
        payload: {
          languages,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyLanguages');
    }
  };
  
  const loadAgencyMetrics = async (categories) => {
    try {
      addBusyBee('loadAgencyMetrics');

      const url = `/api/agency`;
      const result = await axios.post(url, {
        operationName: 'getAgencyMetrics',
        agencyId: state.agency.id,
        categories: categories
      });
      let data = parseApiResult(result.data).body;

      let agencyMetrics = data.map(
        (node) => {
          return {
            id: node.id,
            metricId: node.metricId,
            title: node.title,
            data: node.data,
          };
        }
      );

      dispatch({
        type: messageTypes.LOADING_AGENCY_METRICS_SUCCES,
        payload: {
          agencyMetrics,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyMetrics');
    }
  };
  
  const upsertAgencyMetric = async (
    id,
    agencyId,
    metricId,
    title,
    data
  ) => {
    try {
      addBusyBee('upsertAgencyMetric');

      const variables = {
        operationName: 'upsertAgencyMetric',
        id: id,
        title: title,
        metricId: metricId,
        agencyId: agencyId,
        data: data,
      };

      const url = `/api/agency`;
      const result = await axios.post(url, variables);
      let response = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPSERTING_AGENCY_METRIC_SUCCESS,
        payload: {
          agencyMetric: {
            id: id,
            title: title,
            metricId: metricId,
            agencyId: agencyId,
            data: data,
          },
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertAgencyMetric');
    }
  };  
  
  const deleteAgencyMetric = async (
    agencyId,
    id,
  ) => {
    try {
      addBusyBee('deleteAgencyMetric');

      const variables = {
        operationName: 'deleteAgencyMetric',
        id: id,
        agencyId: agencyId,
      };

      const url = `/api/agency`;
      const result = await axios.post(url, variables);
      
      dispatch({
        type: messageTypes.DELETING_AGENCY_METRIC_SUCCESS,
        payload: {
          agencyMetric: {
            id: id
          },
        },
      });

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('deleteAgencyMetric');
    }
  };

  const updateAgencyLanguages = async (selectedLanguageIds) => {
    try {
      addBusyBee('updateAgencyLanguages');

      const toPost = {
        operationName: 'updateAgencyLanguages',
        selectedLanguageIds: selectedLanguageIds,
        agencyId: state.agency.id
      }

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const languages = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_LANGUAGES_SUCCESS,
        payload: {
          languages,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyLanguages');
    }
  };

  const loadAgencyDocumentTypes = async () => {
    try {
      addBusyBee('loadAgencyDocumentTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyDocumentTypes', agencyId: state.agency.id});
      const documentTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS,
        payload: {
          documentTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyDocumentTypes');
    }
  };

  const updateAgencyDocumentTypes = async (selectedDocumentTypes) => {
    try {
      addBusyBee('updateAgencyDocumentTypes');

      const toPost = {
        operationName: 'updateAgencyDocumentTypes',
        selectedDocumentTypes: selectedDocumentTypes,
        agencyId: state.agency.id
      };

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const { documentTypes } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_DOCUMENT_TYPES_SUCCESS,
        payload: {
          documentTypes
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyDocumentTypes');
    }
  };

  const loadAgencyConsentTypes = async () => {
    try {
      addBusyBee('loadAgencyConsentTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyConsentTypes', agencyId: state.agency.id});
      const consentTypes = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.LOADING_AGENCY_CONSENT_TYPES_SUCCESS,
        payload: {
          consentTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyConsentTypes');
    }
  };

  const upsertAgencyConsentType = async (consentType) => {
    try {
      addBusyBee('upsertAgencyConsentType');

      const toPost = {
        operationName: 'upsertAgencyConsentType',
        consentType,
        agencyId: state.agency.id
      }
       
      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const { consentType: updInfo } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPSERTING_AGENCY_CONSENT_TYPE_SUCCESS,
        payload: {
          consentType: updInfo
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertAgencyConsentType');
    }
  };

  const loadAgency = async (id) => {

    try {
      addBusyBee('loadAgency');

      const url = `/api/agency`;
      const { data } = await axios.post(url, { operationName: 'getAgency', agencyId: id });
      const agency = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_SUCCESS,
        payload: {
          agency: agency
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgency');
    }
  };

  const updateAgencyClientInfoConfig = async (values) => {
    addBusyBee('updateAgencyClientInfoConfig');

    try {
      const toPost = {
        operationName: 'updateClientInfoConfig',
        clientInfoConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      await axios.post(url, toPost);

      dispatch({
        type: messageTypes.UPDATING_AGENCY_CLIENT_INFO_CONFIG_SUCCESS,
        payload: {
          clientInfoConfig: toPost.clientInfoConfig,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyClientInfoConfig');
    }
  };

  const updateAgencyDashboardConfig = async (values) => {
    addBusyBee('updateAgencyDashboardConfig');

    try {
      const toPost = {
        operationName: 'updateDashboardConfig',
        dashboardConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      await axios.post(url, toPost);

      dispatch({
        type: messageTypes.UPDATING_AGENCY_DASHBOARD_CONFIG_SUCCESS,
        payload: {
          dashboardConfig: toPost.dashboardConfig,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyDashboardConfig');
    }
  };

  const updateAgencyEligibilityConfig = async (values) => {
    addBusyBee('updateAgencyEligibilityConfig');

    try {
      const toPost = {
        operationName: 'updateEligibilityConfig',
        eligibilityConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      let {eligibilityConfig} = parseApiResult(data).body

      dispatch({
        type: messageTypes.UPDATING_AGENCY_ELIGIBILITY_CONFIG_SUCCESS,
        payload: {
          eligibilityConfig: eligibilityConfig,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyEligibilityConfig');
    }
  };

  const loadAgencyPrograms = async () => {
    try {
      addBusyBee('loadAgencyPrograms');

      let { data } = await axios.post(`/api/agency`, {operationName: 'getAllAgencyPrograms',agencyId: state.agency.id});
      const programs = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS,
        payload: {
          programs,
        },
      });
    } catch (error) {
      console.log(error);

      if (error.message === 'Access Denied') {
        dispatch({
          type: messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS,
          payload: {
            programs: [],
          },
        });
      }
      else {
        setError(error);
      }
    } finally {
      removeBusyBee('loadAgencyPrograms');
    }
  };

  const updateAgencyPrograms = async (selectedProgramIds) => {
    try {
      addBusyBee('updateAgencyPrograms');

      const toPost = {
        operationName: 'updateAgencyPrograms',
        selectedProgramIds: selectedProgramIds,
        agencyId: state.agency.id
      };

      const url = `/api/agency`;
      let { data } = await axios.post(url, toPost);
      const programs = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_PROGRAMS_SUCCESS,
        payload: {
          programs
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyPrograms');
    }
  };

  const updateAgencyTestResultConfig = async (values) => {
    addBusyBee('updateAgencyTestResultConfig');

    try {
      const toPost = {
        operationName: 'updateTestResultConfig',
        testResultConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      let { data } = await axios.post(url, toPost);
      let { testResultConfig } = parseApiResult(data).body

      dispatch({
        type: messageTypes.UPDATING_AGENCY_TEST_RESULT_CONFIG_SUCCESS,
        payload: {
          testResultConfig: testResultConfig
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyTestResultConfig');
    }
  };

  const updateAgencyPremiumsPaidConfig = async (values) => {
    addBusyBee('updateAgencyPremiumsPaidConfig');

    try {
      const toPost = {
        operationName: 'updatePremiumsPaidConfig',
        premiumsPaidConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      let { data } = await axios.post(url, toPost);
      let { premiumsPaidConfig } = parseApiResult(data).body

      dispatch({
        type: messageTypes.UPDATING_AGENCY_PREMIUMS_PAID_CONFIG_SUCCESS,
        payload: {
          premiumsPaidConfig: premiumsPaidConfig
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyPremiumsPaidConfig');
    }
  };
  
  const updateAgencyMedicationDispensesConfig = async (values) => {
    addBusyBee('updateAgencyMedicationDispensesConfig');

    try {
      const toPost = {
        operationName: 'updateMedicationDispensesConfig',
        medicationDispensesConfig: values,
        agencyId: state.agency.id
      };
      const url = `/api/agency`;
      let { data } = await axios.post(url, toPost);
      let { medicationDispensesConfig } = parseApiResult(data).body

      dispatch({
        type: messageTypes.UPDATING_AGENCY_MEDICATION_DISPENSES_CONFIG_SUCCESS,
        payload: {
          medicationDispensesConfig: medicationDispensesConfig
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyMedicationDispensesConfig');
    }
  };

  const loadAgencyClientInfoCustomLists = async () => {

    if (state.agency) {
      try {
        addBusyBee('loadAgencyClientInfoCustomLists');
        
        const toPost = {
          operationName: 'getAgencyClientInfoCustomLists',
          agencyId: state.agency.id
        }

        const url = `/api/agency`;
        const { data } = await axios.post(url, toPost);
        const clientInfoCustomLists = parseApiResult(data).body;

        dispatch({
          type: messageTypes.LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS,
          payload: {
            clientInfoCustomLists
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('loadAgencyClientInfoCustomLists');
      }
    }
  };

  const updateAgencyClientInfoCustomLists = async (customLists) => {
    try {
      addBusyBee('updateAgencyClientInfoCustomLists');

      const toPost = {
        operationName: 'updateAgencyClientInfoCustomLists',
        customLists,
        agencyId: state.agency.id
      }

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const updatedLists = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS,
        payload: {
          clientInfoCustomLists: updatedLists
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyClientInfoCustomLists');
    }
  };

  const updateAgencyPermissions = async (selectedPermissionTemplateIds) => {
    try {
      addBusyBee('updateAgencyPermissions');

      const toPost = {
        operationName: 'updateAgencyPermissions',
        selectedPermissionTemplateIds: selectedPermissionTemplateIds,
        agencyId: state.agency.id
      };

      const url = `/api/agency`;
      let { data } = await axios.post(url, toPost);
      const permissionTemplateResult = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_AGENCY_PERMISSIONS_SUCCESS,
        payload: {
          granteePermissionTemplates: permissionTemplateResult.permissionTemplates
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateAgencyPermissions');
    }
  };

  const loadGranteePermissionTemplates = async () => {

    try {
      addBusyBee('loadGranteePermissionTemplates');

      const url = `/api/permissionTemplates`;
      const result = await axios.post(url, { operationName: 'getPermissionTemplates' });
      const permissionTemplateResult = parseApiResult(result.data).body;         

      dispatch({
        type: messageTypes.LOADING_GRANTEE_PERMISSION_TEMPLATES_SUCCESS,
        payload: {
          granteePermissionTemplates: permissionTemplateResult.permissionTemplates,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadGranteePermissionTemplates');
    }
  };

  if (state) {
    return {
      ...state,
      loadAgencyAssessmentTypes,
      loadAgencyAssessments,
      createAgencyAssessment,
      updateAgencyAssessment,
      loadAgencyLanguages,
      updateAgencyLanguages,
      loadAgencyDocumentTypes,
      updateAgencyDocumentTypes,
      loadAgencyConsentTypes,
      upsertAgencyConsentType,
      loadAgencyProviderTypes,
      updateAgencyProviderTypes,
      updateAgencyOptionalFeatures,
      loadAgency,
      updateAgencyDashboardConfig,
      updateAgencyClientInfoConfig,
      updateAgencyEligibilityConfig,
      loadAgencyPrograms,
      updateAgencyPrograms,
      updateAgencyTestResultConfig,
      updateAgencyPremiumsPaidConfig,
      updateAgencyMedicationDispensesConfig,
      loadAgencyClientInfoCustomLists,
      updateAgencyClientInfoCustomLists,
      loadAgencyMetrics,
      upsertAgencyMetric,
      deleteAgencyMetric,
      updateAgencyPermissions,
      loadGranteePermissionTemplates
    };
  }

  return {};
};
