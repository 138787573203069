import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import Grid from '@mui/material/Grid';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useClient } from 'contexts/ClientContext';
import { useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { ClientDocumentUploader } from './components/ClientDocumentUploader';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import { Button, Table } from '@lotus/components';
import LotusFileViewer  from 'components/widgets/LotusFileViewer';


export default function Documents ({ documents, documentTypeIds, onDocumentAdded, disabled}) {
  const { userAgency, userAgencyDocumentTypes, loadUserAgencyDocumentTypes } = useUserAgency();
  const { client, loadClient } = useClient();
  const { user } = useAuthState();

  const [showAddDocument, setShowAddDocument] = useState(false);
  const [allDocuments, setAllDocuments] = useState();
  const [documentToView, setDocumentToView] = useState();
  const [metaData, setMetaData] = useState();
  const [documentTypesToUse, setDocumentTypesToUse] = useState();
  const [allAgencyDocumentTypes, setAllAgencyDocumentTypes] = useState();
  const { clientId } = useParams();

  useEffect(() => {
    if (userAgency && !userAgencyDocumentTypes) {
      loadUserAgencyDocumentTypes();
    }
  }, [userAgency]);

  useEffect(() => {
    if (documentTypeIds && userAgencyDocumentTypes) {
      const documentTypes = documentTypeIds.map((id) => userAgencyDocumentTypes.find((dt) => dt.id === id));
      setDocumentTypesToUse(documentTypes);
      setAllAgencyDocumentTypes(userAgencyDocumentTypes);
    } else {
      setDocumentTypesToUse([]);
      setAllAgencyDocumentTypes([]);
    }
  }, [documentTypeIds, userAgencyDocumentTypes]);

  useEffect(() => {
    if (clientId && !client) {
      loadClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (documentTypesToUse && allAgencyDocumentTypes && documents) {
      const clientDocuments = documents;

      const documentList = clientDocuments.map((cd) => {
        let newcd = { ...cd };
        newcd.dateUploadedDt = moment(newcd.dateUploaded,'MM/DD/YYYY');
        newcd.dateUploadedSortStr = moment(newcd.dateUploaded,'MM/DD/YYYY').format('YYYYMMDD');
        newcd.documentTypeName = allAgencyDocumentTypes.find(d => d.id === newcd.documentTypeId)?.name;
        newcd.documentDate = newcd.documentDate ? moment(newcd.documentDate).format('MM/DD/YYYY') : 'N/A';
        newcd.documentDateSortStr = newcd.documentDate ? moment(newcd.documentDate).format('YYYYMMDD') : 'N/A';
        return newcd;
      });
      documentList.sort((a, b) => (a.dateUploadedDt > b.dateUploadedDt ? -1 : 1));
      setAllDocuments(documentList);
    }
  }, [documentTypesToUse, documents]);

  const columns = [
    {
      label: 'Document type',
      name: 'documentTypeName',
    },
    {
      label: 'Description',
      name: 'comments'
    },
    {
      label: 'Uploaded By',
      name: 'uploadedBy.name',
    },
    {
      label: 'Date uploaded',
      name: 'dateUploadedSortStr',
      options: {
        customBodyRender: (value, tableMeta) => { return (<div>{tableMeta.rowData[6]}</div>); }
      }
    },
    {
      label: 'Document Date',
      name: 'documentDateSortStr',
      options: {
        customBodyRender: (value, tableMeta) => { return (<div>{tableMeta.rowData[5]}</div>); }
      }
    },
    {
      name: 'documentDate',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'dateUploaded',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
  ];

  const addDocument = async (document) => {
    const newDocument = {
      ...document,
      uploadedBy: {id: user.id, name: `${user.firstName} ${user.lastName}`},
    };

    setShowAddDocument(false);

    if (onDocumentAdded) {
      onDocumentAdded(newDocument);
    }
  };

  const viewClientDocumentForRow = async (rowData, rowMeta) => {
    const clientDocument = allDocuments[rowMeta.dataIndex];
    setDocumentToView(clientDocument);
    
    const metaData = {field1:{label:'Uploaded By:', value:clientDocument?.uploadedBy?.name},
                      field2:{label:'Date Uploaded:', value:clientDocument?.dateUploaded},
                      field3:{label:'Document Date:', value:clientDocument?.documentDate},
                      field5:{label:'Description:', value:clientDocument?.comments}
                      };
    setMetaData(metaData);                  
                      
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  return (
    <Grid item xs={12}>
      <PageSection
        header="Documents" 
        actions={
          <>
          {!disabled &&
          <Button onClick={() => {setShowAddDocument(true);}} plusIcon>
            Add Document
          </Button>
          }
          </>
        }
      >
        {allDocuments && (
            <Table
              columns={columns}
              data={allDocuments}
              options={{
                print: false,
                download: false,
                filter: false,
              }}
              handleRowClicked={viewClientDocumentForRow}
            />
        )}

        <ActionDialog
          fullWidth={true}
          maxWidth="lg"
          open={Boolean(documentToView)}
          content={<LotusFileViewer clientId={clientId} subfolderName="documents" title={documentToView?.documentTypeName || ''} metaData={metaData} file={documentToView?.documentFiles} handleClose={closeDocumentView}/>}
        />

        <ActionDialog
          maxWidth="sm"
          open={showAddDocument}
          content={
            <ClientDocumentUploader
              documentTypes={documentTypesToUse}
              clientId={clientId}
              handleCancel={() => {setShowAddDocument(false);}}
              handleSave={addDocument}
            />
          }
        />
      </PageSection>
    </Grid>
  );
};