import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useClient } from 'contexts/ClientContext';
import { useProgram } from 'contexts/ProgramContext';
import TabbedMultiformPanel from 'components/Forms/MultiTab/MultiForm/TabbedMultiformPanel';
import { useProgramSubforms } from 'components/Forms/utils/useProgramSubforms';
import { useClientProgramSubforms } from 'components/Forms/utils/useClientProgramSubforms';
import { ClientFormContextProvider } from 'contexts/ClientFormContext';
import { DynamicFormProvider } from 'contexts/DynamicFormContext';
import LotusMainSubTabs from 'components/widgets/Layout/LotusMainSubTabs';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { getProgramsForDisplay } from './common';
import EnrollmentGeneralForm from './EnrollmentGeneralForm';
import { useEnrollments } from 'contexts/EnrollmentsContext';

function CustomProgramEnrollmentEditorPanel() {
  const formTypeKey = 'enrollment';
  const navigate = useNavigate();

  const { client } = useClient();
  const { currentProgram } = useProgram();

  const disabled = false; // seems like there should be a permission here

  const {clientId, lvl3: programId, lvl4: formTab} = useParams();
  const subformDefinitions = useProgramSubforms(formTypeKey, programId);
  const clientForms = useClientProgramSubforms(clientId, formTypeKey, programId);
  const { userAgencyPrograms, loadUserAgencyPrograms } = useUserAgency();
  const {loadEnrollmentsForClient, enrolledPrograms} = useEnrollments();

  const [programTabs, setProgramTabs] = useState();
  const [tabDefinitions, setTabDefinitions] = useState();
  const [dynamicSubforms, setDynamicSubforms] = useState();
  const [programsForDisplay, setProgramsForDisplay] = useState();

  useEffect(() => {
    if (!userAgencyPrograms) {
      loadUserAgencyPrograms();
    }
  }, [userAgencyPrograms]);

  useEffect(() => {
    if (!enrolledPrograms) {
      loadEnrollmentsForClient(clientId, client.agencyId);
    }
  }, []);

  useEffect(() => {
    if (userAgencyPrograms && enrolledPrograms && client) {
      setProgramsForDisplay(getProgramsForDisplay(userAgencyPrograms.filter(p => p.programTypeKey === 'custom'), enrolledPrograms, false));
    }
  }, [userAgencyPrograms, enrolledPrograms, client]);

  useEffect(() => {
    if (!formTab) {
      navigate(`/client/${clientId}/enrollment/edit/${programId}/General`);
    }
  }, [formTab]);

  useEffect(() => {
    if (programId && programsForDisplay) {
      const tabList = programsForDisplay.map((p) => {
        return {label: p.name, link: `/client/${clientId}/enrollment/edit/${p.id}/General`, id: p.id};
      });
      tabList.unshift({label: 'All Programs', link: `/client/${clientId}/enrollment/list`});
      setProgramTabs(tabList);
    }
  }, [programId, programsForDisplay]);

  useEffect(() => {
    if (programId && enrolledPrograms && subformDefinitions) {
      const enrollmentExists = enrolledPrograms.find(e => e.programId === programId);
      const staticTab = {
        'General' : {
          component: 
            <EnrollmentGeneralForm
              titleOverride={enrollmentExists ? (subformDefinitions.length > 0 ? '' : `Edit Enrollment for ${currentProgram?.name}`) : `Create Enrollment for ${currentProgram?.name}`}
              client={client}
              disabled={disabled} 
              programId={programId}
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'General'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['General'])} />
        }
      };
      setTabDefinitions(staticTab);

      if (enrollmentExists) {
        setDynamicSubforms(subformDefinitions);
      } else {
        // if its a new enrollment, dont show all the custom enrollment forms yet
        setDynamicSubforms(subformDefinitions.filter(d => d.formSubtypeName === 'General'));
      }
    }
  }, [programId, enrolledPrograms, subformDefinitions, clientForms]);

  return (
      programTabs && enrolledPrograms && (
      <Grid container>
        <Grid item xs={12}>
          <LotusMainSubTabs tabs={programTabs} selectedTabName={programId} tabPropertyToMatch="id" />
        </Grid>
        <Grid item xs={12}>
          <TabbedMultiformPanel 
            selectedTab={formTab}
            dynamicSubformDefinitions={dynamicSubforms} 
            tabDefinitions={tabDefinitions}
            clientForms={clientForms} 
            clientId={clientId}
            disabled={disabled}
            headerText={`Edit Enrollment for ${currentProgram?.name}`} 
            linkPathPrefix={`/client/${clientId}/enrollment/edit/${programId}`} />
        </Grid>
      </Grid>
      )
  );
}

export default function CustomProgramEnrollmentEditor({ ...props }) {
  return (
    <DynamicFormProvider>
      <ClientFormContextProvider>
        <CustomProgramEnrollmentEditorPanel {...props} />
      </ClientFormContextProvider>
    </DynamicFormProvider>
  );
}