import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LotusMultilineTextInput from 'components/form/inputField/common/LotusMultilineTextInput';
import { Stack } from '@mui/material';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';
import { ArrowBack, Close } from '@mui/icons-material';

const validationSchema = yup.object({
  denyReason: yup.string('Denial Reason must be filled in').required('Denial Reason is required'),
});

const initialValues = {
  denyReason: '',
};

export default function DenyTemplateRequestForm({ onSave, onCancel }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await onSave(values.denyReason);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{ marginTop: 1 }}>
              <LotusMultilineTextInput name="denyReason" label="Denial Reason" rows={4} required />
              <Stack direction="row-reverse">
                <LotusButtonList>
                  <LotusSecondaryActionButton onClick={onCancel} startIcon={<ArrowBack />}>
                    Back
                  </LotusSecondaryActionButton>
                  <LotusPrimaryActionButton isDanger onClick={handleSubmit} startIcon={<Close />}>
                    Deny
                  </LotusPrimaryActionButton>
                </LotusButtonList>
              </Stack>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
