import { Stack, Typography } from '@mui/material';
import React from 'react';


export default function LotusPageHeader({
  title,
  subtitle,
  actionButtonList
}) {
  return (
    <Stack direction="column" spacing={1} style={{padding: "32px 24px"}}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h1">{title}</Typography>
        {actionButtonList}
      </Stack>
      {subtitle &&
        <Typography variant="subtitle1">{subtitle}</Typography>
      }
    </Stack>
  );
}