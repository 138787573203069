import { buildClientFormKey } from './ClientFormContext';

export const LOADING_CLIENT_FORMS_SUCCESS = 'LOADING_CLIENT_FORMS_SUCCESS';
export const SAVING_CLIENT_FORM_SUCCESS = 'SAVING_CLIENT_FORM_SUCCESS';

export const reducer = (state, action) => {
  const { type, payload } = action;

  const deepCopyForms = (formsHash) => {
    let newCache = {};
    Object.entries(formsHash || {}).forEach(([clientFormKey, subformHash]) => {
      let newSubformHash = {}
      Object.entries(subformHash || {}).forEach(([formSubtypeName, form]) => {
        newSubformHash[formSubtypeName] = form;
      });
      newCache[clientFormKey] = newSubformHash;
    });
    return newCache;
  }
  switch (type) {
    case LOADING_CLIENT_FORMS_SUCCESS:
      let newCache2 = deepCopyForms(state.clientForms);
      let payloadFormHash = {};
      payload.formList.forEach(form => {
        payloadFormHash[form.formSubtypeName || form.formSubtypeCustomName] = {
          ...form, 
          clientId: payload.clientId, 
          formTypeKey: 
          payload.formTypeKey, 
          programId: payload.programId,
          assessmentTypeId: payload.assessmentTypeId,
          assessmentId: payload.assessmentId,
          formSubtypeName: form.formSubtypeName || form.formSubtypeCustomName
        };
      });
      newCache2[buildClientFormKey(payload.clientId, payload.formTypeKey, payload.programId, payload.assessmentTypeId, payload.assessmentId)] = payloadFormHash;
      return {
        ...state,
        clientForms: newCache2,
      };
    case SAVING_CLIENT_FORM_SUCCESS:
      let newCache3 = deepCopyForms(state.clientForms);
      let key = buildClientFormKey(payload.clientId, payload.formTypeKey, payload.programId, payload.assessmentTypeId, payload.assessmentId);
      if (key in newCache3 === false) {
        newCache3[key] = {};
      }
      if (payload.formSubtypeName in newCache3[key]) {
        newCache3[key][payload.formSubtypeName].data = payload.formData;
      } else {
        newCache3[key][payload.formSubtypeName] = {
          clientFormId: payload.clientFormId,
          clientId: payload.clientId,
          formTypeKey: payload.formTypeKey,
          programId: payload.programId,
          assessmentTypeId: payload.assessmentTypeId,
          assssmentId: payload.assessmentId,
          formSubtypeName: payload.formSubtypeName,
          data: payload.formData
        }
      }
      return {
        ...state,
        clientForms: newCache3,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
