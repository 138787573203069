import React, { useEffect, useRef, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { List, ListItem, Typography } from '@mui/material';


export default function LotusStickyNav({ linkData, children }) {
  const [scrolled, setScrolled] = useState(false);
  const [savedLocation, setSavedLocation] = useState(-1);
  const [links, setLinks] = useState([]);
  const elementRef = useRef();

  useEffect(() => {
    if (linkData) {
      const newLinks = linkData.map((data, index) => {
        return (
          <ListItem key={`${data.title}${index}`} style={{paddingLeft: 0, boxSizing: "content-box"}}>
            <HashLink smooth to={data.url} style={{textDecoration: 'none'}}>
              <Typography variant="body1">{data.title}</Typography>
            </HashLink>
          </ListItem>
        );
      });

      setLinks(newLinks);
    }
  }, [linkData]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const shouldBeScrolled = currPos.y <= 0;

      if (scrolled !== shouldBeScrolled) {
        setScrolled(shouldBeScrolled);
      }
    },
    [],
    elementRef
  );

  useScrollPosition(({ prevPos, currPos }) => {
    if (scrolled) {
      if (savedLocation === -1) {
        setSavedLocation(currPos.y);
      } else {
        const shouldBeScrolled = currPos.y <= savedLocation;

        if (scrolled !== shouldBeScrolled) {
          setScrolled(shouldBeScrolled);
          setSavedLocation(-1);
        }
      }
    }
  });

  return (
    <div ref={elementRef} style={{position: 'sticky', top: 40, paddingRight: 24}}>
      <nav>
        <div>
          {children}
        </div>
        {links && links.length > 0 &&
          <List style={{paddingTop: 24}}>
            {links}
          </List>
        }
      </nav>
    </div>
  );
}