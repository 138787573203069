import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { usePermissionTemplateSearch } from 'contexts/PermissionTemplateSearchContext';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';
import LotusSelect from 'components/form/select/LotusSelect';

export default function StartingTemplatePanel() {
  const {
    values: { startingTemplateId },
    setFieldValue,
  } = useFormikContext();
  const { permissionTemplates, loadPermissionTemplates } = usePermissionTemplateSearch();
  const { startingPermissionTemplate, loadStartingPermissionTemplate } = usePermissionTemplateManagement();
  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    if (!permissionTemplates) {
      loadPermissionTemplates();
    }
  }, [permissionTemplates]);

  useEffect(() => {
    if (permissionTemplates) {
      let tmpList = [...permissionTemplates];
      setTemplateList(
        tmpList
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((template) => {
            return { label: template.name, value: template.id };
          })
      );
    }
  }, [permissionTemplates]);

  useEffect(() => {
    if (startingTemplateId && (!startingPermissionTemplate || startingPermissionTemplate.id !== startingTemplateId)) {
      loadStartingPermissionTemplate(startingTemplateId);
    }
  }, [startingTemplateId]);

  useEffect(() => {
    if (startingTemplateId && startingPermissionTemplate && startingPermissionTemplate.id === startingTemplateId) {
      setFieldValue('startingTemplate', startingPermissionTemplate);
    }
  }, [startingTemplateId, startingPermissionTemplate]);

  useEffect(() => {
    return () => {
      setFieldValue('startingTemplate', undefined);
    };
  }, []);
  return templateList && <LotusSelect name="startingTemplateId" label="Starting Template" items={templateList} />;
}
