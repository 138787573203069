import React, { useEffect } from 'react';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useLists } from 'contexts/ListsContext';

export default function LifeAreaLinker() {
  const { lifeAreas, loadLifeAreas } = useLists();

  useEffect(() => {
    if (!lifeAreas) {
      loadLifeAreas();
    }
  }, []);

  return lifeAreas && (
    <LotusCheckboxList
      name="selectedCareLifeAreas"
      items={lifeAreas.map(pt => {return {label: pt.name, value: pt.id}})}
    />
  );
}
