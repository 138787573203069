import React from 'react';
import LotusInputField from '../LotusInputField';


export default function LotusMultilineTextInput({
  name,
  label,
  helperText,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  lettersOnly,
  maxLength,
  rows
}) {
  return (
    <LotusInputField
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
      readOnly={readOnly}
      lettersOnly={lettersOnly}
      maxLength={maxLength}
      multiline={true}
      rows={rows || 2}
    />
  );
}