import React, { useEffect, useState } from 'react';
import LotusAutocomplete from './LotusAutocomplete';


export default function LotusStateSelector({
  name, 
  label, 
  helperText, 
  required, 
  dontCheckRequiredFields,
  disabled,
  readOnly
}) {
  
  const [stateList, setStateList] = useState();

  useEffect(() => {
    setStateList([
      "AL","AK","AZ","AR","CA","CO","CT","DE","DC","FL",
      "GA","HI","ID","IL","IN","IA","KS","KY","LA","ME",
      "MD","MA","MI","MN","MS","MO","MT","NE","NV","NH",
      "NJ","NM","NY","NC","ND","OH","OK","OR","PA","RI",
      "SC","SD","TN","TX","UT","VT","VA","WA","WV","WI","WY"]);
  }, []);

  return stateList && (
    <LotusAutocomplete
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
      readOnly={readOnly}
      options={stateList}
    />
  );
}
