import React from 'react';
import LotusSecondaryActionButton from '../LotusSecondaryActionButton';
import Delete from '@mui/icons-material/Delete';


export default function LotusRemoveButton({
  onClick,
  isDanger,
  isSuccess,
  disabled,
}) {
  return (
    <LotusSecondaryActionButton
      startIcon={<Delete/>}
      onClick={onClick}
      isDanger={true}
      isSuccess={isSuccess}
      disabled={disabled}
    >
      Remove
    </LotusSecondaryActionButton>
  );
}