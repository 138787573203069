import React, { useEffect, useState } from 'react';
import { FieldArray, useField } from 'formik';
import Grid from '@mui/material/Grid';
import { Stack, Typography } from '@mui/material';
import LotusCard from 'components/dataDisplay/card/LotusCard';
import LotusRemoveButton from 'components/button/common/LotusRemoveButton';
import LotusAddButton from 'components/button/common/LotusAddButton';
import LotusCardList from 'components/dataDisplay/card/LotusCardList';
import LotusStickyNav from 'components/navigation/LotusStickyNav';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LotusToggleButton from 'components/button/common/LotusToggleButton';
import { useNavigate } from 'react-router-dom';
import { Error } from '@mui/icons-material';

export default function LotusExpandableItemList({
  name,
  label,
  itemLabel,
  disabled,
  readOnly,
  dontCheckRequiredFields,
  buildItemTitle,
  buildItemSummary,
  buildItemDetail,
  buildItemSectionName,
  buildItemTemplate,
  allowRemove=true,
  showNav=true,
}) {
  const [field, meta] = useField({name});
  const [expandedItems, setExpandedItems] = useState([]);
  const [sections, setSections] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const sectionList = [];
    if (field.value && buildItemSectionName) {
      sectionList.push(...field.value.map((itm, idx) => {
        return {title: buildItemSectionName(itm, disabled), url: `#${`${name}_${idx}`}`}
      }));
    }
    setSections(sectionList);
  }, [field.value, disabled]);

  return (
    <FieldArray 
      name={name}
      render={arrayHelpers => {
        
        const addItem = () => {
          const newItem = buildItemTemplate();
          arrayHelpers.unshift(newItem);
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.map(i => i + 1);
          newExpandedItems.unshift(0);
          setExpandedItems(newExpandedItems);
          // Scroll to the first item in the list (which will be the new one);
          setTimeout(() => navigate(`#${name}_0`));
        };

        const removeItem = (index) => {
          arrayHelpers.remove(index);
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.filter(i => i !== index).map(i => i > index ? i - 1 : i);
          setExpandedItems(newExpandedItems);
        };

        const expandItem = (index) => {
          const newExpandedItems = [...expandedItems];
          newExpandedItems.push(index);
          setExpandedItems(newExpandedItems);
        }

        const collapseItem = (index) => {
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.filter(i => i !== index);
          setExpandedItems(newExpandedItems);
        }

        return (
          <Stack direction="column" spacing={2}>
            {label &&
              <Typography variant="body1">{label}</Typography>
            }
            {label && field.value && field.value.length === 0 &&
              <Typography variant="body1">None</Typography>
            }
            <Grid container>
              <Grid item xs={3}>
                <LotusStickyNav linkData={showNav ? sections || [] : []}>
                  {!readOnly &&
                    <Stack direction="row" justifyContent="flex-start">
                      <LotusAddButton disabled={disabled} onClick={addItem} itemLabel={itemLabel}/>      
                    </Stack> 
                  }
                </LotusStickyNav>
              </Grid>
              <Grid item xs={9}>
                <Stack direction="column" spacing={2}>
                  {field.value && field.value.length > 0 &&
                    <LotusCardList>
                      {field.value.map((item, index) => {
                        return (
                          <div id={`${name}_${index}`} key={index}>
                            <LotusCard>
                              <Stack direction="column" spacing={2}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                  <Stack direction="row" spacing={2} alignItems="center">
                                    { /* If there's an error within the item detail, display error icon */ }
                                    {meta.error !== undefined && meta.error[index] !== undefined &&
                                      <Error color='error'/>
                                    }
                                    <Typography variant="h3">{buildItemTitle(item)}</Typography>
                                  </Stack>
                                  <LotusToggleButton
                                    isOn={expandedItems.includes(index)}
                                    isOnContent={<Stack direction="row" spacing={1}><div>Hide Details</div><ExpandLessIcon/></Stack>}
                                    isOffContent={<Stack direction="row" spacing={1}><div>Show Details</div><ExpandMoreIcon/></Stack>}
                                    onClick={() => expandedItems.includes(index) ? collapseItem(index) : expandItem(index)}
                                  />
                                </Stack>
                                {!expandedItems.includes(index) &&
                                  <div>{buildItemSummary(item, index)}</div>
                                }
                                { /* Note: Using display none if not expanded so it's still in the DOM and validation still happens on the components */ }
                                <Stack direction="column" spacing={2} style={{display: expandedItems.includes(index) ? 'flex' : 'none'}}>
                                  <div>
                                    {buildItemDetail(item, index, disabled, readOnly, dontCheckRequiredFields)}
                                  </div>
                                  {!disabled && !readOnly && allowRemove &&
                                    <Stack direction="horizontal" justifyContent="flex-end">
                                      <LotusRemoveButton onClick={() => removeItem(index)}/>
                                    </Stack>
                                  }
                                </Stack>
                              </Stack>
                            </LotusCard>
                          </div>
                        );
                      })}
                    </LotusCardList>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        );
      }}
    />
  );
}
