import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// Leaving inline styling here as not sure this is a standard thing
export default function ProgressNoteList({ progressNotes }) {
  return (
    <Grid item xs={12}>
      <Grid container style={{borderRadius: 15, backgroundColor: '#E4E4E45C'}}>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ padding: 10, paddingLeft: 20 }}>
            Progress Notes
          </Typography>
          {progressNotes && progressNotes.map((progressNote) => (
            <Grid container style={{ padding: 10, marginLeft: 10, color: '#7a7a7a'}} key={progressNote.id}>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {progressNote.writtenByName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {progressNote.recordedDate}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <Typography style={{ marginBottom: 10 }} variant="body1">
                  {progressNote.notes}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
