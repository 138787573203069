import React, { useState } from 'react';
import {Stack} from '@mui/material';
import ActionDialog from 'components/Dialogs/ActionDialog';
import DocumentationUploader from './DocumentationUploader';
import DocumentationList from './DocumentationList';
import { Button } from '@lotus/components';
import { useField, useFormikContext } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

export default function ClientServicesDocumentation ({ clientId, documentationListFieldName, disabled}) {
  
  const [documentationField, meta] = useField(documentationListFieldName);
  const { setFieldValue } = useFormikContext();

  const [showAddDocumentation, setShowAddDocumentation] = useState(false);

  const addDocumentation = async (documentation) => {
    setShowAddDocumentation(false);
    if(Object.keys(documentation).length !== 0) {
      setFieldValue(documentationListFieldName, [documentation, ...documentationField.value]);
    }
  };

  return (
    <div>
      <LotusFormItem>
        {documentationField.value && (
          <DocumentationList clientId={clientId} data={documentationField.value}/>
        )}
      </LotusFormItem>
      <LotusFormItem>
        <Stack justifyContent="flex-start" direction="row">
          <Button onClick={() => {setShowAddDocumentation(true);}} plusIcon disabled={disabled}>
            Add Documentation
          </Button>
        </Stack>
      </LotusFormItem>

      <ActionDialog
        maxWidth="sm"
        open={showAddDocumentation}
        content={
          <DocumentationUploader
            clientId={clientId}
            handleCancel={() => {setShowAddDocumentation(false);}}
            handleSave={addDocumentation}
          />
        }
      />
    </div>
  );
};