import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import * as yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LifeAreaLinker from '../linkers/LifeAreaLinker';
import CategoryLinker from '../linkers/CategoryLinker';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

const validationSchema = yup.object({
  carePlanExpirationDays: yup
    .number('Care Plan Expiration Days must be an integer')
    .integer('Care Plan Expiration Days must be an integer')
    .nullable(),
});

export default function ProgramCarePlanForm({ handleSubmit }) {

  const [initialValues, setInitialValues] = useState();

  const { 
    currentProgram, 
    carePlanLifeAreaIds,
    carePlanCategoryIds,
    carePlanGoalCloseReasons,
    carePlanGoalTypes,
    carePlanInterventionTypes, 
    loadProgramCarePlanPicklists,
  } = useProgramManagement();

  useEffect(() => {
    if (currentProgram && !carePlanLifeAreaIds) {
      loadProgramCarePlanPicklists();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (currentProgram && carePlanLifeAreaIds && carePlanCategoryIds && carePlanGoalCloseReasons && carePlanGoalTypes && carePlanInterventionTypes) {
      setInitialValues({
        id: currentProgram.id,
        carePlanExpirationDays: currentProgram.carePlanExpirationDays || 0,
        interventionType: currentProgram.interventionType || '',
        selectedCareLifeAreas: carePlanLifeAreaIds,
        selectedCareCategories: carePlanCategoryIds,
        currentGoalCloseReasons: carePlanGoalCloseReasons.map((s) => {return {name: s.name, active: true} || [];}),
        addedGoalCloseReason: '',
        barrierCareCategoryEnabled: currentProgram.barrierCareCategoryEnabled || false,
        barrierLifeAreaEnabled: currentProgram.barrierLifeAreaEnabled || false,
        goalPriorityEnabled: currentProgram.goalPriorityEnabled || false,
        goalMemberAgreesEnabled: currentProgram.goalMemberAgreesEnabled || false,
        goalAssignedToEnabled: currentProgram.goalAssignedToEnabled || false,
        barrierDisplayName: currentProgram.barrierDisplayName || '',
        goalTypeEnabled: currentProgram.goalTypeEnabled || false,
        interventionTypeEnabled: currentProgram.interventionTypeEnabled || false,
        addedGoalType: '',
        addedInterventionType: '',
        currentGoalTypes: carePlanGoalTypes.map((s) => {return {name: s.name, active: true} || [];}),
        currentInterventionTypes: carePlanInterventionTypes.map((s) => {return {name: s.name, active: true} || [];})
      });
    }
  }, [currentProgram, carePlanLifeAreaIds, carePlanCategoryIds, carePlanGoalCloseReasons, carePlanGoalTypes, carePlanInterventionTypes]);

  return (
    <LotusPageSection header="Care Plan">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormSection name="General">
                  <LotusFormItem>
                    <LotusSelect
                      name="carePlanExpirationDays"
                      label="Care Plan Expiration Days"
                      items={[
                        { label: 'No Expiration', value: 0 },
                        { label: '1 Month', value: 30 },
                        { label: '3 Months', value: 90 },
                        { label: '6 Months', value: 180 },
                        { label: '12 Months', value: 365 },
                        { label: '18 Months', value: 545 },
                        { label: '24 Months', value: 730 },
                      ]}
                    />
                  </LotusFormItem>
                </LotusFormSection>
                <LotusFormSection name="Barriers">
                  <LotusFormItem>
                    <LotusTextInput
                      name="barrierDisplayName"
                      label="Barrier To Care Display Name"
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusSwitch
                      name="barrierLifeAreaEnabled"
                      label="Barrier Life Area Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  {values.barrierLifeAreaEnabled && (
                    <LotusFormItem indent={1}>
                      <div>Life Areas</div>
                      <LifeAreaLinker/>
                    </LotusFormItem>
                  )}
                  <LotusFormItem>
                    <LotusSwitch
                      name="barrierCareCategoryEnabled"
                      label="Barrier Care Category Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  {values.barrierCareCategoryEnabled && (
                    <LotusFormItem indent={1}>
                      <div>Categories</div>
                      <CategoryLinker/>
                    </LotusFormItem>
                  )}
                </LotusFormSection>
                <LotusFormSection name="Goals">
                  <GenericListLinker
                    name="currentGoalCloseReasons"
                    title="Goal Close Reasons for Program"
                    itemName="Close Reason"
                  />
                  <LotusFormItem>
                    <LotusSwitch
                      name="goalTypeEnabled"
                      label="Goal Type Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  {values.goalTypeEnabled && (
                    <LotusFormItem indent={1}>
                      <GenericListLinker
                        name="currentGoalTypes"
                        title="Goal Types for Program"
                        itemName="Type"
                      />
                    </LotusFormItem>
                  )}
                  <LotusFormItem>
                    <LotusSwitch
                      name="goalPriorityEnabled"
                      label="Goal Priority Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusSwitch
                      name="goalMemberAgreesEnabled"
                      label="Goal Member Agrees Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusSwitch
                      name="goalAssignedToEnabled"
                      label="Goal Assigned To Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                </LotusFormSection>
                <LotusFormSection name="Interventions">
                  <LotusFormItem>
                    <LotusRadioGroup
                      name="interventionType"
                      label="Intervention Type"
                      items={[
                        {
                          label: 'Open Text',
                          value: 'OpenText',
                        },
                        {
                          label: 'Line Item',
                          value: 'LineItem',
                        },
                      ]}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusSwitch
                      name="interventionTypeEnabled"
                      label="Intervention Type Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  {values.interventionTypeEnabled && (
                    <LotusFormItem indent={1}>
                    <GenericListLinker
                      name="currentInterventionTypes"
                      title="Intervention Types for Program"
                      itemName="Type"
                    />
                    </LotusFormItem>
                  )}
                </LotusFormSection>
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
