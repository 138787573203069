import React, {useState, useEffect} from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import * as yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

const validationSchema = yup.object({
  timeUnitsMinutes: yup
    .number('Time Units must be an integer')
    .integer('Time Units must be an integer')
    .nullable('Time Units must be an integer'),
});

export default function ProgramProgressLogForm({ program, handleSubmit }) {

  const [initialValues, setInitialValues] = useState();
  const { currentProgram } = useProgramManagement();

  useEffect(() => {
    if (currentProgram) {
      const newInitialValues = {
        enabled: currentProgram.progressLogConfig.enabled || false,
        timeSpentEnabled: currentProgram.progressLogConfig.timeSpentEnabled || false,
        startEndTimeEnabled: currentProgram.progressLogConfig.startEndTimeEnabled || false,
        timeUnitsMinutes: currentProgram.progressLogConfig.timeUnitsMinutes || '',
        linkage: currentProgram.progressLogConfig.linkage || '',
      };
      setInitialValues(newInitialValues);
    }
  }, [currentProgram]);

  return (
      <LotusPageSection header="Progress Logs">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleSubmit(values);
            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
          }) => {
            return values && (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  <LotusFormItem>
                    <LotusSwitch
                      name="enabled"
                      label="Enabled"
                      labelPlacement="start"
                    />
                  </LotusFormItem>
                  {values.enabled && (
                    <>
                      <LotusFormItem>
                        <LotusSwitch
                          name="timeSpentEnabled"
                          label="Time Spent Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="startEndTimeEnabled"
                          label="Show Start Time and End Time"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                       <LotusTextInput
                          name="timeUnitsMinutes"
                          label="Time Units (Minutes)"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusRadioGroup
                          name="linkage"
                          label="Linkage"
                          items={[
                            {
                              label: `${values.barrierDisplayName || 'Barrier'}s`,
                              value: 'Barrier',
                            },
                            {
                              label: 'Goals',
                              value: 'Goal',
                            },
                            {
                              label: 'Interventions',
                              value: 'Intervention',
                            },
                          ]}
                        />
                      </LotusFormItem>
                    </>
                  )}
                  <LotusFormItem>
                    <LotusButton color="primary" variant="contained" type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
      </LotusPageSection>
  );
}
