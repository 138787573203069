import React from 'react';
import { Card, useTheme } from '@mui/material';

export default function LotusCard({ children }) {

  const theme = useTheme();

  return (
    <Card 
      variant= 'outlined'
      square={false}
      style={{
        padding: 24, // consistent between accordion, card, paper
        border: `1px solid ${theme.palette.other.card}`  // consistent between accordion, card
      }} 
    >
      {children}
    </Card>
  );
}
