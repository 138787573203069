import React from 'react';
import Typography from '@mui/material/Typography';

export default function MFAResetErrorPanel() {
  return (
    <>
      <Typography color='#b53f3f' variant="h6">Error Resetting MFA Configuration</Typography>

      <br />

      <p>
        <Typography variant="body">
          The user's MFA Configuration could not be reset.
        </Typography>
      </p>
    </>
  );
}
