import React from 'react';
import { Badge } from '@mui/material';


export default function LotusBadge({ content, color, children }) {

  return (
    <Badge
      badgeContent={content}
      color={color}
    >
      {children}
    </Badge>
  );
}
