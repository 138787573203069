import React from 'react';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const PREFIX = 'LotusTabPanel';

const classes = {
  noCaps: `${PREFIX}-noCaps`,
  nonactiveText: `${PREFIX}-nonactiveText`,
};

const StyledBox = styled(Box)(({ theme }) => {
  return {
    [`& .${classes.noCaps}`]: {
      textTransform: 'none'
    },
    [`& .${classes.nonactiveText}`]: {
      color: theme.palette.text.tabs,
    },
  };
});

export default function LotusTabPanel({
  dontCapitalize=false,
  useIndicatorIcon=false,
  useColorForSelectedItem=false,
  handleTabChanged,
  value,
  tabs,
  ...props
}) {

  return (
    <StyledBox>
      <Tabs
        value={value === -1 ? tabs.length - 1 : value}
        TabIndicatorProps={
            useIndicatorIcon
              ? { style: { backgroundColor: 'white' } }
              : { style: { } }
        }
        textColor={useColorForSelectedItem ? "secondary" : "primary"}
        indicatorColor={useColorForSelectedItem ? "secondary" : "primary"}
        onChange={handleTabChanged}
        sx={{ minHeight: 50, height: 50 }} 
        {...props}
      >
        {tabs &&
          tabs.map((tab, index) => {
            const icon =
              (useIndicatorIcon && value === index) ? (
                <FiberManualRecordIcon style={{fontSize: 10}} />
              ) : undefined;
            const errorIcon = tab.hasError === true ? <ErrorIcon style={{fontSize: 20, color: '#FF0000'}}  /> : undefined;
            const alertIcon = tab.hasAlert === true ? <ErrorOutlineIcon color='error'/> : undefined;
            return (
              <Tab
                id={tab.label}
                key={tab.label}
                tabIndex={index}
                className={classNames({
                  [classes.nonactiveText]: value !== index,
                  [classes.noCaps]: dontCapitalize
                })}
                label={tab.label}
                icon={icon || errorIcon || alertIcon}
                iconPosition="start"
                sx={{ minHeight: 50, height: 50 }} 
              />
            );
          })}
      </Tabs>
    </StyledBox>
  );
}
