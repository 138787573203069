import LotusIntegerInput from "components/form/inputField/common/LotusIntegerInput";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusExpandableItemList from "components/form/list/LotusExpandableItemList";
import LotusReadOnlyFieldView from "components/form/LotusReadOnlyFieldView";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSelect from "components/form/select/LotusSelect";

export default function GranteeAgencyAssessmentsView({
  assessmentTypes
}) {
  return (
    <LotusExpandableItemList
      name='assessments'
      allowRemove={false}
      buildItemDetail={(item, index, disabled, readOnly, dontCheckRequiredFields) => {
        return (
          <LotusStackedFormItems>
            <LotusTextInput
              name={`assessments.${index}.name`}
              label="Assessment Name"
              disabled={disabled}
              required
            />
            {assessmentTypes && (
              <LotusSelect
                label="Assessment Type"
                name={`assessments.${index}.assessmentTypeId`}
                items={assessmentTypes}
                disabled={disabled}
                required
              />
            )}
            <LotusIntegerInput
              name={`assessments.${index}.expirationReminderDays`}
              label="Expiration Reminder (days)"
              disabled={disabled}
              required
            />
            <LotusIntegerInput
              name={`assessments.${index}.assessmentTimeCycleDays`}
              label="Time Cycle (days)"
              disabled={disabled}
              required
            />
            <LotusIntegerInput
              name={`assessments.${index}.waitTimeToReassessmentDays`}
              label="Wait Time To Reassessment (days)"
              disabled={disabled}
              required
            />
            <LotusIntegerInput
              name={`assessments.${index}.startedAssessmentExpirationHours`}
              label="Started Assessment Expiration (hours)"
              disabled={disabled}
              required
            />
          </LotusStackedFormItems>
        );
      }}
      buildItemTemplate={() => {return {
        name: '',
        assessmentTypeId: '',
        expirationReminderDays: 0,
        assessmentTimeCycleDays: 0,
        waitTimeToReassessmentDays: 0,
        startedAssessmentExpirationHours: 0,
      }}}
      buildItemTitle={(item) => {
        return `${item.name || '[Assessment Name]'}`
      }}
      buildItemSummary={(item, index) => {
        return (
          <div>
            {assessmentTypes && (
              <LotusReadOnlyFieldView
                value={assessmentTypes.find(a => a.value === item.assessmentTypeId)?.label} 
                label='Assessment Type' 
              />
            )}
          </div>
        );
      }}
    />
  );
}

