import { styled } from '@mui/material/styles';
import { NavigationContextProvider } from 'contexts/NavigationContext';

import Header from './Header';
import Grid from '@mui/material/Grid';
import { useAuthState } from 'contexts/AuthProvider';

const PREFIX = 'LayoutWithoutNav';

const classes = {
  container: `${PREFIX}-container`
};

const StyledNavigationContextProvider = styled(NavigationContextProvider)(
  () => ({
    [`& .${classes.container}`]: {
      minHeight: 'calc(100vh - 64px)',
    }
  })
);

export default function LayoutWithoutNav({ children }) {

  const { user } = useAuthState();

  return (
    <StyledNavigationContextProvider>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Header user={user} />
        </Grid>
        <Grid item xs={11}>
            <Grid container>
              <Grid item xs={3} sm={2} lg={1}/>
              <Grid item xs={9} sm={10} lg={11}>
                {children}
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </StyledNavigationContextProvider>
  );
}
