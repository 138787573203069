import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ProgressNoteList from './ProgressNoteList';
import { useAuthState } from 'contexts/AuthProvider';
import { useProgram } from 'contexts/ProgramContext';
import { useClient } from 'contexts/ClientContext';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusDivider from 'components/widgets/Layout/LotusDivider';
import LotusExpandableItemList from 'components/widgets/Forms/LotusExpandableItemList';
import InlineEditableStaticForm from 'components/Forms/InlineEditableStaticForm';
import LotusSideBySide from 'components/widgets/Forms/LotusSideBySide';
import { useLists } from 'contexts/ListsContext';

export default function GoalForm({
  readOnly,
  goal,
  barrierId,
  afterSave,
  handleCancel
}) {
  const { user: loggedInUser } = useAuthState();
  const [emptyIntervention, setEmptyIntervention] = useState();
  const [initialValues, setInitialValues] = useState();

  const { upsertGoal } = useClientCarePlan();
  const { goalStatuses, interventionStatuses } = useLists();
  
  const {
    currentProgram,
    currentProgramUsers,
    currentProgramCarePlanGoalCloseReasons,
    currentProgramCarePlanGoalTypes,
    currentProgramCarePlanInterventionTypes,
  } = useProgram();

  const { client } = useClient();

  useEffect(() => {
    if (interventionStatuses && goalStatuses && client && currentProgram) {
      setEmptyIntervention({
        description: '',
        interventionTypeId: '',
        authorName: loggedInUser.firstName + ' ' + loggedInUser.lastName || '',
        statusId: interventionStatuses.find((g) => g.name === 'Open')?.id,
        establishedDate: moment().format('MM/DD/YYYY'),
        targetDate: null,
      });

      if (goal) {
        setInitialValues({
          id: goal.id,
          clientId: client.id, 
          programId: currentProgram.id,
          barrierId: goal.barrierId,
          description: goal.description || '',
          comments: goal.comments || '',
          barrierToReaching: goal.barrierToReaching || '',
          goalTypeId: goal.goalTypeId || '',
          statusId: goal.statusId || '',
          establishedDate: goal.establishedDate || null,
          targetDate: goal.targetDate || null,
          closeReasonId: goal.closeReasonId || '',
          interventionText: goal.interventionText || '',
          lastStatusChangeDate: goal.lastStatusChangeDate || '',
          priority: goal.priority || '',
          memberAgrees: goal.memberAgrees || false,
          assignedTo: goal.assignedToAccountId || '',
          interventions: goal.interventions.map((intervention) => {
            return {
              id: intervention.id,
              description: intervention.description || '',
              interventionTypeId: intervention.interventionTypeId || '',
              authorId: intervention.authorId || null,
              authorName: intervention.authorName,
              statusId: intervention.statusId || '',
              establishedDate: intervention.establishedDate || null,
              targetDate: intervention.targetDate || null,
              progressNotes: intervention.progressNotes,
            };
          })
        });
      } else {
        setInitialValues({
          clientId: client.id, 
          programId: currentProgram.id,
          description: '',
          comments: '',
          barrierToReaching: '',
          goalTypeId: '',
          statusId: goalStatuses.find((g) => g.name === 'Open').id,
          establishedDate: moment().format('MM/DD/YYYY'),
          targetDate: null,
          closeReasonId: '',
          interventionText: '',
          lastStatusChangeDate: moment().format('MM/DD/YYYY'),
          interventions: [],
          priority: '',
          memberAgrees: false,
          assignedTo: '',
          barrierId: barrierId
        });
      }
    }
  }, [goal, interventionStatuses, goalStatuses, client, currentProgram]);

  const onSubmit = async (newValues) => {
    upsertGoal(newValues, );
    await afterSave();
  }

  function buildInterventionPanel(intervention, index, disabled) {
    return (
      <LotusFormItems key={index}>
        <LotusTextInput
          disabled={readOnly}
          name={`interventions.${index}.description`}
          label="Description"
          required
        />
        {interventionStatuses && (                       
          <LotusSelect
            disabled={readOnly}
            name={`interventions.${index}.statusId`}
            label="Intervention Status"
            items={interventionStatuses.map((s) => {return {label: s.name, value: s.id};})}
          />
        )}
        {currentProgram && currentProgram.interventionTypeEnabled && currentProgramCarePlanInterventionTypes && (                                
          <LotusSelect
            disabled={readOnly}
            name={`interventions.${index}.interventionTypeId`}
            label="Intervention Type"
            items={currentProgramCarePlanInterventionTypes.map((s) => {return {label: s.name, value: s.id};})}
          />
        )}
        <LotusTextInput
          disabled={true}
          label="Author"
          name={`interventions.${index}.authorName`}
        />
        <LotusDatePicker
          name={`interventions.${index}.establishedDate`}
          label="Intervention Established"
          disabled={readOnly}
          required
        />
        <LotusDatePicker
          disabled={readOnly}
          name={`interventions.${index}.targetDate`}
          label="Intervention Target Date"
          required
        />
        {intervention.progressNotes && intervention.progressNotes.length > 0 && (
          <ProgressNoteList progressNotes={intervention.progressNotes}/>
        )}
      </LotusFormItems>
    );
  }

  function buildFormContent(readOnly, formValues, formHelpers) {
    return (
      <LotusForm>
        <LotusFormItems>
          <LotusTextInput
            disabled={readOnly}
            name="description"
            label="Goal Statement"
            multiline
            rows={3}
            required
          />
          {currentProgram && currentProgram.interventionType === 'OpenText' && (
            <LotusTextInput
              disabled={readOnly}
              name="interventionText"
              multiline
              rows={10}
              label="Interventions"
            />
          )}
          {currentProgram && currentProgram.goalTypeEnabled && currentProgramCarePlanGoalTypes && (
            <LotusSelect
              disabled={readOnly}
              name="goalTypeId"
              label="Goal Type"
              items={currentProgramCarePlanGoalTypes.map((s) => {return { label: s.name, value: s.id };})}
            />
          )}
          <LotusTextInput
            disabled={readOnly}
            name="barrierToReaching"
            multiline
            rows={10}
            label="Barrier to Meeting Goal"
          />
          {goalStatuses && (
            <LotusSelect
              disabled={readOnly}
              name="statusId"
              label="Goal Status"
              items={goalStatuses.filter((s) => goal || s.name !== 'Closed').map((s) => {return { label: s.name, value: s.id };})}
            />
          )}
          {goal && goalStatuses && goalStatuses.find((g) => g.id === formValues.statusId)?.name === 'Closed' && currentProgramCarePlanGoalCloseReasons && (
            <LotusSelect
              disabled={readOnly}
              name="closeReasonId"
              label="Goal Close Reason"
              items={currentProgramCarePlanGoalCloseReasons.map((s) => {return { label: s.name, value: s.id };})}
            />
          )}
          {currentProgram && currentProgram.goalPriorityEnabled && (
            <LotusSelect
              disabled={readOnly}
              name="priority"
              label="Goal Priority"
              items={[
                { label: 'Low', value: 'Low' },
                { label: 'Medium', value: 'Medium' },
                { label: 'High', value: 'High' },
              ]}
            />
          )}
          {currentProgram && currentProgram.goalMemberAgreesEnabled && (
            <LotusSwitch
              name="memberAgrees"
              label="Member Agrees to Goal?"
              labelPlacement="start"
              disabled={readOnly}
            />
          )}
          {currentProgram && currentProgram.goalAssignedToEnabled && currentProgramUsers && (
            <LotusSelect
              name="assignedTo"
              label="Assigned To"
              disabled={readOnly}
              items={currentProgramUsers.map((s) => {return { label: s.name, value: s.id };})}
            />
          )}
          <LotusSideBySide
            leftControl={<LotusDatePicker
              name="establishedDate"
              label="Goal Established"
              disabled={readOnly}
              required
            />}
            rightControl={<LotusDatePicker
              disabled={readOnly}
              name="targetDate"
              label="Goal Target Date"
              required
            />}
          />
          <LotusTextInput
            disabled={readOnly}
            name="comments"
            multiline
            rows={5}
            label="Comments"
          />
        </LotusFormItems>
        {goal && goal.progressNotes && goal.progressNotes.length > 0 && (
          <ProgressNoteList
            progressNotes={goal.progressNotes}
          />
        )}
        <LotusDivider />
        {currentProgram && currentProgram.interventionType === 'LineItem' && (
          <LotusExpandableItemList
            disabled={readOnly} 
            name="interventions" 
            itemName="Intervention"
            label="Interventions"
            buildItemSectionName={(item) => item.description}
            buildItemTitle={(item) => item.description || '[Description]'}
            buildItemSummary={(item, index) => {
              return (<div></div>);
            }}
            buildItemDetail={(item, index, isDisabled) => buildInterventionPanel(item, index, isDisabled)}
            buildItemTemplate={() => {return {...emptyIntervention}}}
            allowRemove={false}
            showNav={false}
          />
        )}
      </LotusForm>
    );
  }

  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(goal ? 'Edit' : 'Add')} Goal`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      disabled={readOnly}
      buildFormContent={buildFormContent}
    />
  );
}
