import React from 'react'
import { Formik, Form } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';


const ResetMFAForm = ({
  values,
  handleCancel,
}) => {
  return (
    <>
      <LotusForm>
        <Typography variant="body">Reset your password and MFA configuration. If you click the reset button, you will be immediately logged out. Your current password and MFA configuration will no longer be valid. You will receive an email to reset your password and configure your MFA application with a new QR code and secret key.
        </Typography>
        <LotusFormItem>
          <Typography variant="body" color='#b53f3f'>{values.errorMsg}</Typography>
        </LotusFormItem>
        <LotusFormItem>

          <Grid container direction="row" justifyContent="left" spacing={2} columns={2}>
            <Grid item>
              <LotusFormItem>
                <LotusButton variant="outlined" onClick={() => { handleCancel(); }} >
                  Cancel
                </LotusButton>
              </LotusFormItem>
            </Grid>
            <Grid item>
              <LotusFormItem>
                <LotusButton
                  type="submit"
                >
                  Reset
                </LotusButton>
              </LotusFormItem>
            </Grid>
          </Grid>
        </LotusFormItem>
      </LotusForm>

    </>
  );
};

export default function ResetMFADialog({ handleCancel, submitResetMFA }) {

  const initialValues = {
    errorMsg: '',
  };

  const handleSubmit = async (data) => {
    try {
      await submitResetMFA();
      handleCancel();
    }
    catch (err) {
      data.errorMsg = err;
    }
  };

  return (

    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        submitResetMFA,
        values
      }) => {
        return (

          <Grid container columns={1} alignItems="center" direction="column" justify="flex-end">

            <Form onSubmit={handleSubmit}>
              <Grid item xs={6} >
                <LotusPageTitle title={'Reset Multi-Factor Authentication'} />
              </Grid>
              <ResetMFAForm values={values} handleCancel={handleCancel} submitResetMFA={submitResetMFA} />
            </Form>
          </Grid>

        );
      }}
    </Formik>
  );
}
