
import { Stack, Typography } from "@mui/material";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusRemoveButton from "components/button/common/LotusRemoveButton";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSwitch from "components/form/switch/LotusSwitch";
import { useFormikContext } from "formik";

export default function GranteeAgencyDocumentTypesView() {
  const {values, setFieldError, setFieldValue} = useFormikContext();

  const addDocumentType = () => {
    if (values.newDocumentType.length > 0) {
      const found = [
        ...values.newDocumentTypes,
        ...values.documentTypes
      ].find( type => type.name.toLowerCase() === values.newDocumentType.toLowerCase() );

      if (found) {
        setFieldError( 'newDocumentType', 'Document Type already exists.');
      }
      else {
        setFieldValue('newDocumentTypes', [
          ...values.newDocumentTypes,
          {name: values.newDocumentType, is_active: true},
        ]);

        setFieldValue('newDocumentType', '');
      }
    }
  };

  const removeDocumentType = (doc) => {
    const newValues = values.newDocumentTypes.filter(
      (type) => type.name !== doc.name
    );
    setFieldValue('newDocumentTypes', newValues);
  };

  return (
    <LotusStackedFormItems>
      {values.documentTypes.length > 0 && values.documentTypes.map((value, index) => {
        return (
          <LotusSwitch key={index}
            name={`documentTypes.${index}.is_active`}
            label={value.name}
          />
        );
      })}
      {values.newDocumentTypes?.map((doc, index) => {
        return (
          <Stack direction="row" spacing={2} key={index}>
            <Typography>{doc.name}</Typography>
            <LotusRemoveButton onClick={() => removeDocumentType(doc)} />
          </Stack>
        );
      })}
      <Stack direction="row" spacing={2}>
        <div style={{width: 300}}>
          <LotusTextInput
            label='New Document Type'
            name="newDocumentType"
          />
        </div>
        <LotusAddButton onClick={addDocumentType}/> 
      </Stack>
    </LotusStackedFormItems>
  );
}
