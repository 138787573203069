import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useLists } from "contexts/ListsContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";
import { GranteeAgencyClientInfoView } from "./GranteeAgencyClientInfoView";

export default function GranteeAgencyClientInfo() {

  const { loadUserAgency } = useUserAgency();

  const {
    clientRelationships, hispanicEthnicities, loadClientInfoPicklists,
    languages, loadLanguages } = useLists();

  const {
    agency, updateAgencyClientInfoConfig, 
    clientInfoCustomLists, loadAgencyClientInfoCustomLists, updateAgencyClientInfoCustomLists,
    languages: agencyLanguages, loadAgencyLanguages, updateAgencyLanguages } = useAgencyManagement();

  const [allLanguages, setAllLanguages] = useState();

  useEffect(() => {
    if (!clientRelationships || !hispanicEthnicities) {
      loadClientInfoPicklists();
    }
    if (!clientInfoCustomLists) {
      loadAgencyClientInfoCustomLists();
    }
    if (!agencyLanguages) {
      loadAgencyLanguages();
    }
    if (!languages) {
      loadLanguages();
    }
  }, []);

  useEffect(() => {
    if (languages) {
      let lst = [...languages];
      lst = lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setAllLanguages(lst.map(l => {return {label: l.name, value: l.id}}));
    }
  }, [languages]);

  const saveClientInfoConfig = async (values) => {

    await updateAgencyClientInfoConfig({
      registration: values.registration, 
      existingClients: values.existingClients
    });

    await updateAgencyClientInfoCustomLists({
      clientInfoDetailsDocumentationTypes: values.clientInfoDetailsDocumentationTypes,
      clientInfoAddressDocumentationTypes: values.clientInfoAddressDocumentationTypes,
      clientInfoHivDocumentationTypes: values.clientInfoHivDocumentationTypes,
      clientInfoIncomeDocumentationTypes: values.clientInfoIncomeDocumentationTypes,
      clientInfoInsuranceDocumentationTypes: values.clientInfoInsuranceDocumentationTypes,
      clientInfoIncomeSourceTypes: values.clientInfoIncomeSourceTypes
    });

    await updateAgencyLanguages(values.selectedLanguages);

    await loadUserAgency();
  };

  return (
    agency && clientInfoCustomLists && clientRelationships && hispanicEthnicities && (
      <GranteeAgencyClientInfoView
        clientInfoConfig={agency.clientInfoConfig}
        clientInfoCustomLists={clientInfoCustomLists}
        clientRelationships={clientRelationships}
        hispanicEthnicities={hispanicEthnicities}
        agencyLanguages={agencyLanguages}
        allLanguages={allLanguages}
        handleSave={saveClientInfoConfig}
        handleCancel={() => {}} // staying on page, just reverting changes
      />
    )
  );
}
