import React, { Component } from 'react';
import ActionDialog from 'components/Dialogs/ActionDialog';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ActionDialog
          title="An Error Occurred while processing the last request"
          content={
            <div>
              <div style={{ width: '100%' }}>{this.state.error.message}</div>
              <div style={{ marginTop: 30 }}>
                Please reload the page to continue
              </div>
            </div>
          }
          open={this.state.hasError === true}
        />
      );
    }

    return this.props.children;
  }
}
