import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusDateOfBirth from 'components/widgets/Forms/LotusDateOfBirth';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import { useLists } from 'contexts/ListsContext';
import SocialSecurityNumberFieldPanel from 'components/widgets/Composites/SocialSecurityNumberFieldPanel';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import RaceFieldPanel from 'components/widgets/Composites/RaceFieldPanel';
import EthnicityFieldPanel from 'components/widgets/Composites/EthnicityFieldPanel';
import AgencyLanguageSelector from 'components/widgets/Composites/AgencyLanguageSelector';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import DeceasedFieldPanel from 'components/widgets/Composites/DeceasedFieldPanel';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { useClient } from 'contexts/ClientContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { useFormikContext } from 'formik';
import Alert from '@mui/material/Alert';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import LotusFormSubSubSection from 'components/widgets/Forms/LotusFormSubSubSection';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';
import LotusReadOnlyFieldView from 'components/widgets/Forms/LotusReadOnlyFieldView';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';

const validationSchema = Yup.object().shape({});

const buildSectionNames = (existingClientConfig) => {
  const lst = [
    "Basic Information",
  ];
  if (existingClientConfig?.showGenderSection) {
    lst.push("Gender");
  }
  if (existingClientConfig?.showClientPhoneAndEmailSection) {
    lst.push("Contact Phone & Email");
  }
  if (existingClientConfig?.showRaceSection) {
    lst.push("Race");
  }
  if (existingClientConfig?.showEthnicitySection) {
    lst.push("Ethnicity");
  }
  if (existingClientConfig?.showLanguageSection) {
    lst.push("Language");
  }
  if (existingClientConfig?.showOtherDemographicsSection) {
    lst.push("Other Demographics");
  };
  if (existingClientConfig?.showClientDetailAdditionalInformation) {
    lst.push("Additional Information");
  }
  if (existingClientConfig?.showDeceasedSection) {
    lst.push("Deceased");
  }
  return lst
}

const ClientDetailsStaticForm = ({existingClientConfig, disabled, updateRestrictions, clientId}) => {

  const {birthGenders, currentGenderIdentities, phoneTypes, preferredProgramNotificationMethods, 
    maritalStatuses, citizenshipStatuses } = useLists();

  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [phoneTypeList, setPhoneTypeList] = useState();
  const [birthGenderList, setBirthGenderList] = useState();
  const [currentGenderIdentityList, setCurrentGenderIdentityList] = useState();
  const [preferredProgramNotificationMethodList, setPreferredProgramNotificationMethodList] = useState();
  const [maritalStatusList, setMaritalStatusList] = useState();
  const [citizenshipStatusList, setCitizenshipStatusList] = useState();
  const [additionalAssistanceInterestList, setAdditionalAssistanceInterestList] = useState();
  const { verifyPermission } = useAuthState();
  const hasManageUriSuffixPermission = !verifyPermission(PERMISSIONS.MANAGE_URN_SUFFIX);
  const hasUpdateGrandFatheredPermission = !verifyPermission(PERMISSIONS.UPDATE_GRANDFATHERED_CLIENTS);


  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (birthGenders) {
      setBirthGenderList(birthGenders.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (currentGenderIdentities) {
      setCurrentGenderIdentityList(currentGenderIdentities.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (phoneTypes) {
      setPhoneTypeList(phoneTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (preferredProgramNotificationMethods) {
      setPreferredProgramNotificationMethodList(preferredProgramNotificationMethods.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (maritalStatuses) {
      setMaritalStatusList(maritalStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (citizenshipStatuses) {
      setCitizenshipStatusList(citizenshipStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [birthGenders, currentGenderIdentities, phoneTypes, preferredProgramNotificationMethods, maritalStatuses, citizenshipStatuses]);

  useEffect(() => {
    if (existingClientConfig) {
      const interests = existingClientConfig.additionalAssistanceInterests?.filter(c => c.active || (values.additionalAssistanceInterests && values.additionalAssistanceInterests.includes(c.id)))
        .map(c => {return {label: c.name, value: c.id}});
      setAdditionalAssistanceInterestList(interests || []);
    }
  }, [existingClientConfig, values.additionalAssistanceInterests]);

  useEffect(() => {
    if(values.hasSocialSecurityNumber) {
      setFieldValue('missingSsnReasonId', '');
    } else {
      setFieldValue('socialSecurityNumber', '');
    }
    if(!values.isHispanic) {
      setFieldValue('hispanicEthnicities', []);
    }
    if(!values.isDeceased) {
      setFieldValue('deathDate', null);
      setFieldValue('causeOfDeath', '');
    }
  }, [values.hasSocialSecurityNumber, values.isHispanic, values.isDeceased]);

  return values && existingClientConfig && (
    <LotusForm>
      {updateRestrictions && (updateRestrictions.all || updateRestrictions.clientDetails) &&
      <LotusFormSection>
        <LotusFormItem>
          <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
        </LotusFormItem>
      </LotusFormSection>
      }
      <LotusFormSection name="Basic Information" disabled={disabled}>
        <LotusFormItems>
          <LotusTextInput
            name="lastName"
            label="Legal Last Name"
            required
            disabled={disabled}
            maxLength={35}
          />
          <LotusTextInput
            name="firstName"
            label="Legal First Name"
            required
            disabled={disabled}
            maxLength={35}
          />
          {existingClientConfig.showMiddleName &&
            <LotusTextInput
              name="middleName"
              label="Legal Middle Name/Initial"
              disabled={disabled}
              maxLength={35}
            />
          }
          {existingClientConfig.showNameSuffix &&
          <LotusTextInput
              name="nameSuffix"
              label="Name Suffix"
              disabled={disabled}
              maxLength={10}
            />
          }
          <LotusDateOfBirth
            name="birthDate"
            label="Date of Birth"
            required
            disabled={disabled}
          />
          {existingClientConfig.showPreferredName &&
            <LotusTextInput
              name="preferredName"
              label="Preferred Name"
              disabled={disabled}
              maxLength={70}
            />
          }
          {existingClientConfig.showSocialSecurityNumber &&
            <SocialSecurityNumberFieldPanel
              hasSocialSecurityNumberFieldName="hasSocialSecurityNumber"
              socialSecurityNumberFieldName="socialSecurityNumber"
              missingSsnReasonIdFieldName="missingSsnReasonId"
              disabled={disabled} />
          }
          {existingClientConfig.showExternalId &&
            <LotusFormSubSubSection name="External System IDs">
              <LotusFormItem>
                <LotusTextInput
                  name="urn"
                  label="URN"
                  disabled={true}
                />
              </LotusFormItem>
              <LotusFormItem>
                <LotusTextInput
                  name="urnSuffix"
                  label="UCI Suffix"
                  disabled={disabled || hasManageUriSuffixPermission}
                  maxLength={1}
                  letter
                />
              </LotusFormItem>
              {values.externalSystemIds.map(systemId =>
                <LotusFormItem key={systemId.id}>
                  <LotusReadOnlyFieldView label={systemId.name} value={systemId.externalIds} />
                </LotusFormItem>
              )}
            </LotusFormSubSubSection>
          }
          {existingClientConfig.showGrandfathered &&
            <LotusTrueFalseRadioGroup
            name='isGrandfathered'
            label="GrandFathered Status"
            disabled={disabled || hasUpdateGrandFatheredPermission}
          />
          }
        </LotusFormItems>
      </LotusFormSection>
      {existingClientConfig.showGenderSection &&
        <LotusFormSection name="Gender" disabled={disabled}>
          <LotusFormItems>
            {birthGenderList && existingClientConfig.showBirthGender &&
              <LotusRadioGroup
                name="birthGenderId"
                label="Gender Assigned at Birth"
                items={birthGenderList}
                disabled={disabled}
              />
            }
            {existingClientConfig.showCurrentGenderIdentity &&
              <LotusRadioGroup
                name="currentGenderIdentityId"
                label="Current Gender"
                items={currentGenderIdentityList}
                disabled={disabled}
              />
            }
            {existingClientConfig.showGenderPronouns &&
              <LotusTextInput
                name="genderPronouns"
                label="Pronouns"
                disabled={disabled}
                maxLength={35}
              />
            }
          </LotusFormItems>
        </LotusFormSection>
      }
      {existingClientConfig.showClientPhoneAndEmailSection &&
        <LotusFormSection name="Contact Phone & Email" disabled={disabled}>
          <LotusFormItems>
            {phoneTypeList && existingClientConfig.showPrimaryPhoneType &&
              <LotusSelect
                name='primaryPhoneTypeId' 
                label='Primary Phone Type' 
                items={phoneTypeList}
                disabled={disabled}
                autoSort={false}
                />
            }
            {existingClientConfig.showConfidentialityRequested &&
              <LotusTrueFalseSelect
                name='confidentialityRequested' 
                label='Confidentiality Requested' 
                disabled={disabled}
                />
            }
            {existingClientConfig.showCellPhone &&
              <LotusPhoneInput
                name='cellPhone' 
                label='Cell Phone Number' 
                disabled={disabled}
              />
            }
            {existingClientConfig.showCommunicationByText &&
              <LotusTrueFalseSelect
                name='communicationByText' 
                label='Communication by Text' 
                disabled={disabled}
                />
            }
            {existingClientConfig.showHomePhone &&
              <LotusPhoneInput
                name='homePhone' 
                label='Home Phone Number' 
                disabled={disabled}
              />
            }
            {existingClientConfig.showWorkPhone &&
              <LotusPhoneInput
                name='workPhone' 
                label='Work Phone Number' 
                disabled={disabled}
              />
            }
            {existingClientConfig.showOtherPhone &&
              <LotusPhoneInput
                name='otherPhone' 
                label='Other Phone Number' 
                disabled={disabled}
              />
            }
            {existingClientConfig.showPhoneCallConsent &&
              <LotusTrueFalseSelect
                name='phoneCallConsent' 
                label='Phone Call Consent' 
                disabled={disabled}
                />
            }
            {existingClientConfig.showVoiceMailConsent &&
              <LotusTrueFalseSelect
                name='voiceMailConsent' 
                label='Voice Mail Consent' 
                disabled={disabled}
                />
            }
            {existingClientConfig.showEmailAddress &&
              <LotusTextInput
                name="emailAddress"
                label="Email Address"
                disabled={disabled}
                maxLength={254}
              />
            }
            {existingClientConfig.showAdditionalEmailAddresses &&
              <LotusTextInput
                name="additionalEmailAddresses"
                label="Additional Email Addresses"
                disabled={disabled}
                maxLength={254}
                multiline
                rows={5}
              />
            }
            {existingClientConfig.showEmailConsent &&
              <LotusTrueFalseSelect
                name='emailConsent' 
                label='Email Consent' 
                disabled={disabled}
                />
            }
            {preferredProgramNotificationMethodList && existingClientConfig.showPrefProgNotifMethod &&
            <LotusSelect
                name="prefProgNotifMethodId"
                label="Preferred Method of Receiving Program Notification"
                items={preferredProgramNotificationMethodList}
                disabled={disabled}
                autoSort={false}
              />
            }
            {existingClientConfig.showCommunicationNotes &&
              <LotusTextInput
                name="communicationNotes"
                label="Communication Notes"
                disabled={disabled}
                maxLength={500}
                multiline
                rows={5}
              />
            }
          </LotusFormItems>
        </LotusFormSection>
      }
      {existingClientConfig.showRaceSection &&
        <LotusFormSection name="Race" disabled={disabled}>
          <LotusFormItem>
            <RaceFieldPanel
              raceFieldName="races" 
              asianRaceFieldName="asianRaces" 
              nhpiRaceFieldName="nhpiRaces" 
              showAsianPicklist={existingClientConfig.showAsianPicklist}
              showNhpiPicklist={existingClientConfig.showNhpiPicklist}
              disabled={disabled}/>
          </LotusFormItem>
        </LotusFormSection>
      }
      {existingClientConfig.showEthnicitySection &&
        <LotusFormSection name="Ethnicity" disabled={disabled}>
          <LotusFormItem>
            <EthnicityFieldPanel
              isHispanicFieldName="isHispanic" 
              hispanicEthnicityFieldName="hispanicEthnicities" 
              agencyHispanicEthnicities={existingClientConfig.selectedHispanicEthnicities}
              showHispanicEthnicities={existingClientConfig.showHispanicEthnicityPicklist}
              disabled={disabled}
              />
          </LotusFormItem>
        </LotusFormSection>
      }
      {existingClientConfig.showLanguageSection &&
        <LotusFormSection name="Language" disabled={disabled}>
          <LotusFormItems>
            <AgencyLanguageSelector
              name="primaryLanguageId" 
              label="Primary Language"
              disabled={disabled}/>
            {existingClientConfig.showSecondaryLanguage &&
              <AgencyLanguageSelector
                name="secondaryLanguageId" 
                label="Secondary Language"
                disabled={disabled}/>
            }
            {existingClientConfig.showPreferredWrittenCommLanguageId &&
              <AgencyLanguageSelector
                name="preferredWrittenCommLanguageId" 
                label="Preferred Written Communications Language"
                disabled={disabled}/>
            }
          </LotusFormItems>
        </LotusFormSection>
      }
      {existingClientConfig.showOtherDemographicsSection &&
        <LotusFormSection name="Other Demographics" disabled={disabled}>
          <LotusFormItems>
            {maritalStatusList && existingClientConfig.showMaritalStatus &&
              <LotusSelect
                name='maritalStatusId' 
                label='Marital Status' 
                items={maritalStatusList}
                disabled={disabled}
                autoSort={false}
                />
            }
            {existingClientConfig.showVeteranStatus &&
              <LotusTrueFalseSelect
                name='isVeteran' 
                label='Veteran Status' 
                disabled={disabled}
                />
            }
            {existingClientConfig.showLawfulResidentDate &&
              <LotusDatePicker
                name="lawfulResidentDate"
                label="Date became Lawfully Present Resident"
                disabled={disabled}
              />
            }
            {citizenshipStatusList && existingClientConfig.showCitizenshipStatus &&
              <LotusSelect
                name='citizenshipStatusId' 
                label='Citizenship Status' 
                items={citizenshipStatusList}
                disabled={disabled}
                autoSort={false}
                />
            }
            {existingClientConfig.showIsTobaccoUser &&
              <LotusTrueFalseSelect
                name='isTobaccoUser' 
                label='Does Client Use Tobacco?' 
                disabled={disabled}
                />
            }
          </LotusFormItems>
        </LotusFormSection>
      }
      {existingClientConfig.showClientDetailAdditionalInformation &&
        <LotusFormSection name="Additional Information" disabled={disabled}>
          {existingClientConfig.showAdditionalAssistanceInterests && additionalAssistanceInterestList &&
          <LotusFormItem>
            <LotusMultiSelect
              name="additionalAssistanceInterests"
              label="Additional client assistance?"
              items={additionalAssistanceInterestList}
              disabled={disabled}
              readOnlyRows={2}
            />
          </LotusFormItem>
          }
          <LotusFormItem>
            <LotusTextInput
              name="comments"
              label="Comments"
              disabled={disabled}
              maxLength={500}
              multiline
              rows={5}
            />
          </LotusFormItem>
        </LotusFormSection>
      }
      {existingClientConfig.showDeceasedSection &&
        <LotusFormSection name="Deceased" disabled={disabled}>
          <LotusFormItem>
            <DeceasedFieldPanel
              isDeceasedFieldName='isDeceased' 
              deathDateFieldName='deathDate'
              causeOfDeathFieldName='causeOfDeath'
              showDeathDateField={existingClientConfig.showDeathDate}
              showCauseOfDeathField={existingClientConfig.showCauseOfDeath}
              disabled={disabled}
            />
          </LotusFormItem>
        </LotusFormSection>
      }
      <LotusFormSection name={"Documentation"} disabled={disabled}>
        <LotusFormItem>
          <ClientInfoDocumentation 
            documentationListFieldName="clientDetailsDocumentation"
            allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoDetailsDocumentationTypes || []}
            clientId={clientId}
            disabled={disabled}
            label="Client Details Documentation"
          />
        </LotusFormItem>
      </LotusFormSection>
    </LotusForm>
  );
};

const buildStaticForm = ({...props}) => {
  return <ClientDetailsStaticForm {...props} />
}

export default function ClientDetailsForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions}) {

  const { clientDetails, loadClientDetails, upsertClientDetails } = useClient();
  const [initialValues, setInitialValues] = useState();


  useEffect(() => {
    if (client) {
      loadClientDetails(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (clientDetails) {
      setInitialValues( {
        lastName: clientDetails.lastName || '', 
        firstName: clientDetails.firstName || '', 
        middleName: clientDetails.middleName || '', 
        nameSuffix: clientDetails.nameSuffix || '', 
        preferredName: clientDetails.preferredName || '', 
        birthDate: clientDetails.birthDate || null, 
        birthGenderId: clientDetails.birthGenderId || '', 
        currentGenderIdentityId: clientDetails.currentGenderIdentityId || '', 
        genderPronouns: clientDetails.genderPronouns || '', 
        socialSecurityNumber: clientDetails.socialSecurityNumber || '',
        primaryPhoneTypeId: clientDetails.primaryPhoneTypeId || '', 
        cellPhone: clientDetails.cellPhone || '', 
        workPhone: clientDetails.workPhone || '', 
        homePhone: clientDetails.homePhone || '', 
        otherPhone: clientDetails.otherPhone || '', 
        emailAddress: clientDetails.emailAddress || '', 
        missingSsnReasonId: clientDetails.missingSsnReasonId || '', 
        confidentialityRequested: clientDetails.confidentialityRequested !== null ? clientDetails.confidentialityRequested : '', 
        communicationByText: clientDetails.communicationByText !== null ? clientDetails.communicationByText : '',  
        phoneCallConsent: clientDetails.phoneCallConsent !== null ? clientDetails.phoneCallConsent : '',  
        voiceMailConsent: clientDetails.voiceMailConsent !== null ? clientDetails.voiceMailConsent : '',  
        additionalEmailAddresses: clientDetails.additionalEmailAddresses || '', 
        emailConsent: clientDetails.emailConsent !== null ? clientDetails.emailConsent : '',  
        prefProgNotifMethodId: clientDetails.prefProgNotifMethodId || '', 
        communicationNotes: clientDetails.communicationNotes || '',
        races: clientDetails.races || [], 
        asianRaces: clientDetails.asianRaces || [], 
        nhpiRaces: clientDetails.nhpiRaces || [], 
        isHispanic: clientDetails.isHispanic !== null ? clientDetails.isHispanic : '', 
        hispanicEthnicities: clientDetails.hispanicEthnicities || [],
        primaryLanguageId: clientDetails.primaryLanguageId || '', 
        secondaryLanguageId: clientDetails.secondaryLanguageId || '', 
        preferredWrittenCommLanguageId: clientDetails.preferredWrittenCommLanguageId || '', 
        maritalStatusId: clientDetails.maritalStatusId || '', 
        isVeteran: clientDetails.isVeteran !== null ? clientDetails.isVeteran : '', 
        lawfulResidentDate: clientDetails.lawfulResidentDate || null, 
        citizenshipStatusId: clientDetails.citizenshipStatusId || '', 
        isTobaccoUser: clientDetails.isTobaccoUser !== null ? clientDetails.isTobaccoUser : '', 
        isDeceased: clientDetails.isDeceased !== null ? clientDetails.isDeceased : '', 
        deathDate: clientDetails.deathDate || null, 
        causeOfDeath: clientDetails.causeOfDeath || '', 
        hasSocialSecurityNumber: clientDetails.hasSocialSecurityNumber !== null ? clientDetails.hasSocialSecurityNumber : '', 
        comments: clientDetails.comments || '',
        additionalAssistanceInterests: clientDetails.additionalAssistanceInterests || [],
        urn: clientDetails.urn || '',
        urnSuffix: clientDetails.urnSuffix || '',
        externalSystemIds: clientDetails.externalSystemIds || [],
        clientDetailsDocumentation: clientDetails.clientDetailsDocumentation || [],
        isGrandfathered: clientDetails.isGrandfathered !== null && clientDetails.isGrandfathered !== '' ? clientDetails.isGrandfathered : false
      });
    }
  }, [clientDetails]);
  
  const splitFormData = (formData) => {
    
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      name, primaryPhone, // need to strip these ones out that are calculated on server side
      lastName, firstName, middleName, nameSuffix, preferredName, 
      birthDate, birthGenderId, currentGenderIdentityId, genderPronouns, socialSecurityNumber,
      primaryPhoneTypeId, cellPhone, workPhone, homePhone, otherPhone, 
      emailAddress, missingSsnReasonId, confidentialityRequested, communicationByText, phoneCallConsent, 
      voiceMailConsent, additionalEmailAddresses, emailConsent, prefProgNotifMethodId, communicationNotes,
      races, asianRaces, nhpiRaces, isHispanic, hispanicEthnicities,
      primaryLanguageId, secondaryLanguageId, preferredWrittenCommLanguageId, maritalStatusId, isVeteran,
      lawfulResidentDate, citizenshipStatusId, isTobaccoUser, isDeceased, deathDate, 
      causeOfDeath, hasSocialSecurityNumber, comments, additionalAssistanceInterests, urnSuffix, isGrandfathered, clientDetailsDocumentation,
      ...dynamicFormData} = formData;
  
    const clientDetails = {
      lastName, firstName, middleName, nameSuffix, preferredName, 
      birthDate, birthGenderId, currentGenderIdentityId, genderPronouns, socialSecurityNumber,
      primaryPhoneTypeId, cellPhone, workPhone, homePhone, otherPhone, 
      emailAddress, missingSsnReasonId, confidentialityRequested, communicationByText, phoneCallConsent, 
      voiceMailConsent, additionalEmailAddresses, emailConsent, prefProgNotifMethodId, communicationNotes,
      races, asianRaces, nhpiRaces, isHispanic, hispanicEthnicities,
      primaryLanguageId, secondaryLanguageId, preferredWrittenCommLanguageId, maritalStatusId, isVeteran,
      lawfulResidentDate, citizenshipStatusId, isTobaccoUser, isDeceased, deathDate, 
      causeOfDeath, hasSocialSecurityNumber, comments, additionalAssistanceInterests, urnSuffix, isGrandfathered, clientDetailsDocumentation
    };
    return [clientDetails, dynamicFormData];
  }

  const saveForm = async (values) => {
    await upsertClientDetails(client.id, values);
  }

  return initialValues && (
    <HybridForm 
      title='Client Details'
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled || updateRestrictions?.all || updateRestrictions?.clientDetails, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.clientDetails}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}
