import React from 'react';
import LotusButton from './LotusButton';


export default function LotusSecondaryActionButton({
  onClick,
  startIcon,
  endIcon,
  isDanger,
  isSuccess,
  disabled,
  children
}) {
  return (
    <LotusButton
      onClick={onClick}
      variant="outlined"
      isDanger={isDanger}
      isSuccess={isSuccess}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </LotusButton>
  );
}