import { Form, Formik, yupToFormErrors } from 'formik';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { Button } from "@lotus/components";
import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useState } from 'react';

export default function StaticForm({
  headerName,
  formValues,
  allowEdit,
  onSubmitForm,
  validationSchema,
  buildFormContent
}) {
  
  const [editMode, setEditMode] = useState(false);

  return (
    <Grid item xs={12}>
      <Formik 
        enableReinitialize
        initialValues={formValues}
        validate={(values) => {
          let result = {};
          try {
            const schema = validationSchema || Yup.object().shape({});
            schema.validateSync(values, { abortEarly: false });
          } catch (err) {
            result = yupToFormErrors(err);
          }
          return result;
        }}
        onSubmit={async (newValues, actions) => {
          await onSubmitForm(newValues);
          setEditMode(false);
          actions.setSubmitting(false);
        }}
      >
        {({values, handleSubmit, resetForm}) => {
          const handleCancel = () => {
            setEditMode(false);
            resetForm();
          }
          return values && (
            <Form className="form" onSubmit={handleSubmit} autoComplete="off" subscription={{ submitting: true, pristine: true }}>
              <LotusPageSection 
                header={headerName}
                actions={
                  <>
                  {!editMode &&
                  <Button onClick={() => {setEditMode(true);}} disabled={!allowEdit}>
                    <EditIcon />&nbsp;&nbsp;<Typography variant="h6">Edit</Typography>
                  </Button>
                  }
                  {editMode && 
                    <>
                      <Button variant="text" onClick={() => {handleCancel();}}>
                        <Typography variant="h6">Cancel</Typography>
                      </Button>
                      <Button onClick={() => {handleSubmit(values);}}>
                        <Typography variant="h6">Save</Typography>
                      </Button>
                    </>
                  }
                  </>
                }
              >
                {buildFormContent(!editMode)}
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );
}