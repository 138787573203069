import React from 'react';
import LotusFormItem from './LotusFormItem';

export default function LotusFormItems({ children }) {
  return (
    children && React.Children.map(children, itm => {
      return (itm && 
        <LotusFormItem>
          {itm}
        </LotusFormItem>
      );
      })
  );
}