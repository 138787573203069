import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusMaskedInput({
  label,
  mask,
  disabled,
  style,
  required,
  dontCheckRequiredFields,
  ...props
}) {

  // This works for numeric stuff only.....
  const maskToRegex = (msk) => {
    let str = '';
    for (const c of msk) {
      if (isNaN(c)) {
        str = str + c;
      } else {
        str = str + '\\d'; 
      }
    }
    return new RegExp(`^${str}$`,"g");
  }

  const [field, meta] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && !val) {
      return `${label} is required`;
    }
    const regex = maskToRegex(mask);
    if (val && val !== mask.replaceAll('9', '_') && !regex.test(val)) {
      return `${label} must be in format ${mask}`;
    }
  }});

  return (
    <div>
      {!disabled ? 
      <div>
        <InputMask {...field} {...props} required={required} disabled={disabled} mask={mask}>
          {(inputProps) => (
            <TextField
              fullWidth
              variant= "outlined"
              margin="dense"
              size="small"
              style={{width: '100%', ...style}}
              {...inputProps}
              label={label}
            />
          )}
        </InputMask>
        {meta.touched && meta.error && (
          <Typography variant="body2" color="error">
            {meta.error}
          </Typography>
        )}
      </div> :
      <LotusReadOnlyFieldView label={label} value={field.value} />
      }
    </div>
  );
}
