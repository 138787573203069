import React from 'react';
import LotusTextButton from '../LotusTextButton';

// This button is similar to the LotusShowHideButton, but the state is kept externally rather than internal to the component
// This allows us to programmatically set the state when we need to do that
// For now its just a LotusTextButton, but we could do differently if it is useful
export default function LotusToggleButton({
  isOn,
  isOnContent,
  isOffContent,
  onClick,
  disabled,
}) {
  return (
    <LotusTextButton
      onClick={onClick}
      disabled={disabled}
    >
      {isOn ? isOnContent : isOffContent}
    </LotusTextButton>
  );
}