import React from 'react'
import Typography from '@mui/material/Typography';
import { Box } from "@mui/material";

export default function ErrorMessageForm({
  values,
}) {

  return (
    <>
      {values.operation === 'error' && (
        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >

          <Typography variant="body" color='#b53f3f' style={{ width: 400, fontSize: 16 }}>{values.errorMsg}</Typography>
        </Box>


      )}
    </>
  );
};