import DenyTemplateRequestForm from '../forms/DenyTemplateRequestForm';
import LotusDialog from 'components/feedback/dialog/LotusDialog';

export default function TemplateActionRequestDenyDialog({ open, handleClose, handleSubmit }) {
  return (
    <LotusDialog
      title={`Deny Permission Request`}
      open={open}
      staticWidth="sm"
      handleClose={handleClose}
      content={<DenyTemplateRequestForm onSave={handleSubmit} onCancel={handleClose} />}
    />
  );
}
