import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { GranteeAgencyTestResultsView } from "./GranteeAgencyTestResultsView";

export default function GranteeAgencyTestResults() {

  const {  agency, updateAgencyTestResultConfig } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  const saveTestResultConfig = async (values) => {
    await updateAgencyTestResultConfig(values);
    await loadUserAgency();
  };

  return agency && (
    <GranteeAgencyTestResultsView
      testResultConfig={agency.testResultConfig}
      handleSave={saveTestResultConfig}
      handleCancel={() => {}} // staying on page, just reverting changes
    />
  );
}