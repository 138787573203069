import React, { useEffect } from 'react';
import moment from 'moment';
import { FieldArray } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useClientDocumentation } from 'contexts/ClientDocumentationContext';
import { useProgram } from 'contexts/ProgramContext';
import { useClient } from 'contexts/ClientContext';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import { useState } from 'react';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import InlineEditableStaticForm from 'components/Forms/InlineEditableStaticForm';
import LotusSideBySide from 'components/widgets/Forms/LotusSideBySide';
import { useLists } from 'contexts/ListsContext';

export default function ProgressNoteForm({
  progressNote,
  readOnly = false,
  afterSave,
  handleCancel,
  progressNoteDisplayName
}) {
  const [initialValues, setInitialValues] = useState();
  const { client } = useClient();
  const { currentProgram } = useProgram();
  const {
    barrierSummaries, loadBarrierSummariesForClientAndProgram,
    goalAndInterventionSummaries, loadGoalsAndInterventionSummariesForClientAndProgram,
  } = useClientCarePlan();
  const { upsertProgressNote } = useClientDocumentation();
  const { contactTypes, loadContactTypes } = useLists();

  useEffect(() => {
    if (!contactTypes) {
      loadContactTypes();
    }
  }, []);

  useEffect(() => {
    if (client && currentProgram) {
      if (currentProgram.progressLogConfig.linkage === 'Barrier' &&
          (currentProgram.id in barrierSummaries === false)) {
        loadBarrierSummariesForClientAndProgram(client.id, currentProgram.id);
      }
      if (currentProgram.progressLogConfig.linkage !== 'Barrier' &&
          (currentProgram.id in goalAndInterventionSummaries === false)) {
        loadGoalsAndInterventionSummariesForClientAndProgram(client.id, currentProgram.id);
      }
    }
  }, [client, currentProgram, barrierSummaries]);

  const buildBarrierNoteList = () => {
    const barriersWithExistingNotes = !progressNote ? [] : progressNote.barrierNotes.map((barrierNote) => {
      return {
        id: barrierNote.id,
        barrierId: barrierNote.barrierId,
        barrierName: barrierSummaries[currentProgram.id].find((barrier) => barrier.id === barrierNote.barrierId).name,
        notes: barrierNote.notes,
      };
    });

    let barriersWithoutNotes = barrierSummaries[currentProgram.id].filter((barrier) => {
      return !barriersWithExistingNotes.find((barrierWithNotes) => barrierWithNotes.barrierId === barrier.id);
    });
    barriersWithoutNotes = barriersWithoutNotes.map((barrier) => {
      return {
        barrierId: barrier.id,
        barrierName: barrier.name,
        notes: '',
      };
    });

    return [...barriersWithExistingNotes, ...barriersWithoutNotes];
  };

  const buildGoalNoteList = () => {
    const goalsWithExistingNotes = !progressNote ? [] : progressNote.goalNotes.map((goalNote) => {
      return {
        id: goalNote.id,
        goalId: goalNote.goalId,
        goalDescription: goalAndInterventionSummaries[currentProgram.id].find((goal) => goal.id === goalNote.goalId).description,
        notes: goalNote.notes,
      };
    });

    let goalsWithoutNotes = goalAndInterventionSummaries[currentProgram.id].filter((goal) => {
      return !goalsWithExistingNotes.find((goalWithNotes) => goalWithNotes.goalId === goal.id);
    });
    goalsWithoutNotes = goalsWithoutNotes.map((goal) => {
      return {
        goalId: goal.id,
        goalDescription: goal.description,
        notes: '',
      };
    });

    return [...goalsWithExistingNotes, ...goalsWithoutNotes];
  };

  const buildInterventionNoteList = () => {
    let goalList = [];
    for (const goal of goalAndInterventionSummaries[currentProgram.id]) {
      if (goal.interventions && goal.interventions.length > 0) {
        const goalContainer = {
          goalId: goal.id,
          goalDescription: goal.description,
          selected: false,
          interventionNotes: [],
        };
        for (const intervention of goal.interventions) {
          const existingNote = !progressNote ? null : progressNote.interventionNotes.find((interventionNote) => interventionNote.interventionId === intervention.id);
          if (existingNote) {
            goalContainer.selected = true;
            goalContainer.interventionNotes.push({
              id: existingNote.id,
              interventionId: intervention.id,
              interventionDescription: intervention.description,
              notes: existingNote.notes,
            });
          } else {
            goalContainer.interventionNotes.push({
              interventionId: intervention.id,
              interventionDescription: intervention.description,
              notes: '',
              visible: goalContainer.selected,
            });
          }
        }
        for (const interventionNote of goalContainer.interventionNotes) {
          interventionNote.visible = goalContainer.selected;
        }

        goalList.push(goalContainer);
      }
    }
    return goalList;
  };

  useEffect(() => {
    if (client && currentProgram && 
        ((currentProgram.progressLogConfig.linkage === 'Barrier' && currentProgram.id in barrierSummaries) ||
         (currentProgram.progressLogConfig.linkage !== 'Barrier' && currentProgram.id in goalAndInterventionSummaries))) {
      let initialValue = progressNote
        ? {
            id: progressNote.id,
            clientId: progressNote.clientId,
            programId: progressNote.programId,
            notes: progressNote.notes,
            recordedDate: progressNote.recordedDate,
            startTime: progressNote.startTime ? moment(progressNote.startTime, 'HH:mm:ss').format('H:mm') : '',
            endTime: progressNote.endTime ? moment(progressNote.endTime, 'HH:mm:ss').format('H:mm') : '',
            timeSpentMinutes: progressNote.timeSpentMinutes || '',
            contactTypeId: progressNote.contactTypeId,
            writtenByName: progressNote.writtenByName
          }
        : {
            clientId: client.id,
            programId: currentProgram.id,
            notes: '',
            recordedDate: moment().format('MM/DD/YYYY'),
            startTime: '',
            endTime: '',
            timeSpentMinutes: '',
            contactTypeId: '',
          };

      if (currentProgram.progressLogConfig.linkage === 'Goal') {
        initialValue.goalNotes = buildGoalNoteList();
      } else if (currentProgram.progressLogConfig.linkage === 'Barrier') {
        initialValue.barrierNotes = buildBarrierNoteList();
      } else if (currentProgram.progressLogConfig.linkage === 'Intervention') {
        initialValue.interventionNoteGoalContainers = buildInterventionNoteList();
      }

      setInitialValues(initialValue);
    }
  }, [progressNote, currentProgram, barrierSummaries, goalAndInterventionSummaries]);

  const buildTimeSpentItems = () => {
    const items = [];
    for (let minutes = currentProgram.progressLogConfig.timeUnitsMinutes; minutes <= 120; minutes += currentProgram.progressLogConfig.timeUnitsMinutes) {
      items.push({ label: `${minutes} minutes`, value: minutes });
    }
    return items;
  };

  const onSubmit = async (newValues) => {
    // Only push new or non-blank notes to api
    const toPost = {...newValues};
    if (newValues.barrierNotes) {
      toPost.barrierNotes = [];
      for (const barrierNote of newValues.barrierNotes) {
        if (barrierNote.notes || barrierNote.id) {
          toPost.barrierNotes.push(barrierNote);
        }
      }
    }

    if (newValues.goalNotes) {
      toPost.goalNotes = [];
      for (const goalNote of newValues.goalNotes) {
        if (goalNote.notes || goalNote.id) {
          toPost.goalNotes.push(goalNote);
        }
      }
    }

    if (newValues.interventionNoteGoalContainers) {
      delete toPost.interventionNoteGoalContainers;
      toPost.interventionNotes = [];
      for (const goalContainer of newValues.interventionNoteGoalContainers) {
        if (goalContainer.selected) {
          for (const interventionNote of goalContainer.interventionNotes) {
            if (interventionNote.notes || interventionNote.id) {
              toPost.interventionNotes.push(interventionNote);
            }
          }
        }
      }
    }

    await upsertProgressNote(toPost);

    if (afterSave) {
      await afterSave(toPost);
    }
  }

  function buildFormContent(disallowEdit, formValues, formHelpers) {
    const handleCheckboxChange = async (event, values) => {
      const goalId = event.target.value;
      const goal = values.interventionNoteGoalContainers.find((goalContainer) => goalContainer.goalId === goalId);
      goal.selected = !goal.selected;
  
      for (const interventionNote of goal.interventionNotes) {
        interventionNote.visible = goal.selected;
      }
      formHelpers.setFieldValue('interventionNoteGoalContainers', values.interventionNoteGoalContainers);
    };

    return (
      <LotusForm>
        <LotusFormItems>
          <LotusDatePicker
            name="recordedDate"
            label="Date"
            disabled={disallowEdit}
          />
          {currentProgram.progressLogConfig.startEndTimeEnabled &&
            <LotusSideBySide
              leftControl={<LotusTextInput
                disabled={disallowEdit}
                name="startTime"
                label="Start Time"
                militaryTime
              />}
              rightControl={<LotusTextInput
                disabled={disallowEdit}
                name="endTime"
                label="End Time"
                militaryTime
              />}
            />
          }
          {currentProgram.progressLogConfig.timeSpentEnabled && currentProgram.progressLogConfig.timeUnitsMinutes &&
            <LotusSelect
              disabled={disallowEdit}
              name="timeSpentMinutes"
              label="Time Spent"
              items={buildTimeSpentItems()}
            />
          }
          {contactTypes && (
            <LotusSelect
              disabled={disallowEdit}
              name="contactTypeId"
              label="Contact Type"
              items={contactTypes && contactTypes.map((s) => {return { label: s.name, value: s.id };})}
              required
            />
          )}
          {progressNote && (
            <LotusTextInput
              disabled={true}
              name="writtenByName"
              label="Written By"
            />
          )}
          <LotusTextInput
            disabled={disallowEdit}
            name="notes"
            multiline
            rows={5}
            label="Description"
            required
          />
          {currentProgram && currentProgram.progressLogConfig.linkage === 'Barrier' &&
            <FieldArray name="barrierNotes">
              {() => (
                <>
                <LotusFormItem>
                  <div>Barriers</div>
                </LotusFormItem>
                  {formValues.barrierNotes.length > 0 && formValues.barrierNotes.map((barrier, index) => (
                    <LotusFormItem key={index}>
                      <div style={{ marginBottom: '15px' }}>
                        {barrier.barrierName}
                      </div>
                      <div>
                        <LotusTextInput
                          disabled={readOnly}
                          name={`barrierNotes.${index}.notes`}
                          multiline
                          rows={5}
                          label="Description"
                        />
                      </div>
                    </LotusFormItem>
                  ))}
                </>
              )}
            </FieldArray>
          }
          {currentProgram && currentProgram.progressLogConfig.linkage === 'Goal' &&
            <FieldArray name="goalNotes">
              {() => (
                <>
                <LotusFormItem>
                <div>Goals</div>
                </LotusFormItem>
                  {formValues.goalNotes.length > 0 && formValues.goalNotes.map((goal, index) => (
                    <LotusFormItem key={index}>
                      <div style={{ marginBottom: '15px' }}>
                        {goal.goalDescription}
                      </div>
                      <div>
                        <LotusTextInput
                          disabled={readOnly}
                          name={`goalNotes.${index}.notes`}
                          multiline
                          rows={5}
                          label="Description"
                        />
                      </div>
                    </LotusFormItem>
                  ))}
                </>
              )}
            </FieldArray>
          }
          {currentProgram && currentProgram.progressLogConfig.linkage === 'Intervention' && (
            <FieldArray name="interventionNoteGoalContainers">
              {() => (
                <>
                  <LotusFormItem>
                    <div>Select Which Goals Were Addressed</div>
                  </LotusFormItem>
                  {formValues.interventionNoteGoalContainers.length > 0 && formValues.interventionNoteGoalContainers.map((goalContainer, index) => (
                    <LotusFormItem key={`intervention-goal-${goalContainer.goalId}`}>
                      <LotusCheckbox
                        name={`interventionNoteGoalContainers.${index}.selected`}
                        disabled={readOnly}
                        label={goalContainer.goalDescription}
                        value={goalContainer.goalId}
                        checked={goalContainer.selected}
                        onChange={(event) => handleCheckboxChange(event, formValues)}
                      />
                    </LotusFormItem>
                  ))}
                  <LotusFormItem>
                    <div style={{ marginBottom: '15px' }}>Interventions for Selected Goals</div>
                  </LotusFormItem>
                  {formValues.interventionNoteGoalContainers.length > 0 && formValues.interventionNoteGoalContainers.map((goalContainer, goalIndex) =>
                    goalContainer.interventionNotes.length > 0 && goalContainer.interventionNotes.filter((interventionNote) => interventionNote.visible).map((interventionNote, interventionIndex) => (
                      <LotusFormItem key={goalIndex + '-' + interventionIndex}>
                        <div style={{ marginBottom: '15px' }}>
                          {interventionNote.interventionDescription}
                        </div>
                        <div>
                          <LotusTextInput
                            disabled={readOnly}
                            name={`interventionNoteGoalContainers.${goalIndex}.interventionNotes.${interventionIndex}.notes`}
                            multiline
                            rows={5}
                            label="Description"
                          />
                        </div>
                      </LotusFormItem>
                    ))
                  )}
                </>
              )}
            </FieldArray>
          )}
        </LotusFormItems>
      </LotusForm>
    );
  }
                
  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(progressNote ? 'Edit' : 'Add')} ${progressNoteDisplayName}`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      buildFormContent={buildFormContent}
    />
  );
}