import React from 'react';
import { Table } from '@lotus/components';
import ProgressNoteForm from './ProgressNoteForm';
import ActivityLogForm from './ActivityLogForm';
import ContactForm from './ContactForm';
import MeetingForm from './MeetingForm';
import { useClientDocumentation } from 'contexts/ClientDocumentationContext';
import { useProgram } from 'contexts/ProgramContext';
import moment from 'moment';

export default function DocumentList({
  readOnly,
  documents,
  afterEdit,
  afterCancel,
  meetingDisplayName,
  activityLogDisplayName,
  contactDisplayName,
  progressNoteDisplayName
}) {
  const { contacts, activityLogs, progressNotes, meetings } = useClientDocumentation();

  const { currentProgram } = useProgram();

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
    {
      label: 'Date Recorded',
      name: 'recordedDate',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = moment(obj1,'MM/DD/YYYY').format('YYYYMMDD');
            let val2 = moment(obj2,'MM/DD/YYYY').format('YYYYMMDD');
            const result = (val1 < val2 ? 1 : -1) * (order === 'asc' ? 1 : -1);
            return result;
          };
        }
      }
    },
    {
      label: 'Written by',
      name: 'writtenByName',
    },
    {
      label: 'Notes',
      name: 'noteStart',
    },
    {
      label: 'Document Type',
      name: 'documentType',
    },
    {
      name: 'tag',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    }
  ];

  return documents && (
    <Table
      columns={columns}
      data={documents}
      options={{
        selectableRows: 'none',
        selectableRowsHideCheckboxes: false,
        selectableRowsHeader: false,
        customSearch: (searchQuery, currentRow, columns) => {
          let isFound = false;
          currentRow.forEach((col, idx) => {
            if (columns[idx].searchable && col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
              isFound = true;
            }
          });
          return isFound;
        },
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
          const doc = documents.find((doc) => doc.id === rowData[0]);
          let typedForm = null;
          if (doc.documentType === progressNoteDisplayName) {
            let note = progressNotes[currentProgram.id].find((note) => note.id === doc.id);
            typedForm = (
              <div style={{paddingLeft: 16, paddingRight: 16}}>
                <ProgressNoteForm
                  readOnly={readOnly}
                  progressNote={note}
                  afterSave={afterEdit}
                  handleCancel={afterCancel}
                  progressNoteDisplayName={progressNoteDisplayName}
                />
              </div>
            );
          } 
          else if (doc.documentType === contactDisplayName) {
            let contact = contacts[currentProgram.id].find((enc) => enc.id === doc.id);
            typedForm = (
              <div style={{paddingLeft: 16, paddingRight: 16}}>
                <ContactForm
                  readOnly={readOnly}
                  contact={contact}
                  afterSave={afterEdit}
                  handleCancel={afterCancel}
                  contactDisplayName={contactDisplayName}
                />
              </div>
            );
          } 
          else if (doc.documentType === meetingDisplayName) {
            let meeting = meetings[currentProgram.id].find((m) => m.id === doc.id);
            typedForm = (
              <div style={{paddingLeft: 16, paddingRight: 16}}>
                <MeetingForm
                  readOnly={readOnly}
                  meeting={meeting}
                  afterSave={afterEdit}
                  handleCancel={afterCancel}
                  meetingDisplayName={meetingDisplayName}
                />
              </div>
            );
          } else if (doc.documentType === activityLogDisplayName) {
            let log = activityLogs[currentProgram.id].find((lg) => lg.id === doc.id);
            typedForm = (
              <div style={{paddingLeft: 16, paddingRight: 16}}>
                <ActivityLogForm
                  readOnly={readOnly}
                  activityLog={log}
                  afterSave={afterEdit}
                  handleCancel={afterCancel}
                  activityLogDisplayName={activityLogDisplayName}
                />
              </div>
            );
          }
          return (
            <tr>
              <td colSpan="5">
                <div>{typedForm}</div>
              </td>
            </tr>
          );
        },
      }}
    />
  );
}
