import React, {createContext, useContext, useReducer} from "react";
import axios from '../axiosInterceptor';
import {
  reducer,
  messageTypes
} from "./EligibilityApplicationContextReducer";
import { useAppStatus } from "./AppStatusContext";
import { parseApiResult } from "lib/utils";

const initialState = {
  meta: undefined
};

const EligibilityApplicationContext = createContext();

export const EligibilityApplicationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EligibilityApplicationContext.Provider value={{ state, dispatch }}>
      {children}
    </EligibilityApplicationContext.Provider>
  );
};

export const useEligibilityApplication = () => {
  const { state, dispatch } = useContext(EligibilityApplicationContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  
  const loadEligibilityApplicationMetadata = async (clientId, appId) => {

    addBusyBee('loadEligibilityApplicationMetadata');

    try {

      const {data} = await axios.post(`/api/clientEligibilityApp`, {operationName: 'getApplicationMetadata', appId, clientId });
      let applicationMeta = parseApiResult(data).body.applicationMetadata;

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_METADATA_SUCCESS,
        payload: {
          meta: applicationMeta,
        },
      });

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationMetadata');
    }
  };

  const loadEligibilityApplicationApplicant = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationApplicant');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationApplicant', appId, clientId });
      let {applicant} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS,
        payload: {
          applicant: applicant,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationApplicant');
    }
  };

  const upsertEligibilityApplicationApplicant = async (clientId, appId, applicant) => {
    try {
      addBusyBee('upsertEligibilityApplicationApplicant');

      const toPost = {
        operationName: 'upsertApplicationApplicant',
        appId,
        applicant,
        clientId,
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicant: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_APPLICANT_SUCCESS,
        payload: {
          applicant: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationApplicant');
    }
  };

  const loadEligibilityApplicationAddresses = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationAddresses');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationAddresses', appId, clientId });
      let {addresses} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS,
        payload: {
          addresses: addresses,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationAddresses');
    }
  };

  const upsertEligibilityApplicationAddresses = async (clientId, appId, addresses) => {
    try {
      addBusyBee('upsertEligibilityApplicationAddresses');

      const toPost = {
        operationName: 'upsertApplicationAddresses',
        appId,
        addresses,
        clientId,
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {addresses: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_ADDRESSES_SUCCESS,
        payload: {
          addresses: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationAddresses');
    }
  };

  const loadEligibilityApplicationIncome = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationIncome');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationIncome', appId, clientId });
      let {income} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS,
        payload: {
          income: income,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationIncome');
    }
  };

  const upsertEligibilityApplicationIncome = async (clientId, appId, income) => {
    try {
      addBusyBee('upsertEligibilityApplicationIncome');

      const toPost = {
        operationName: 'upsertApplicationIncome',
        appId,
        income,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {income: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_INCOME_SUCCESS,
        payload: {
          income: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationIncome');
    }
  };

  const loadEligibilityApplicationHealth = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationHealth');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationHealth', appId, clientId });
      let {health} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS,
        payload: {
          health: health,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationHealth');
    }
  };

  const upsertEligibilityApplicationHealth = async (clientId, appId, health) => {
    try {
      addBusyBee('upsertEligibilityApplicationHealth');

      const toPost = {
        operationName: 'upsertApplicationHealth',
        appId,
        health,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {health: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_HEALTH_SUCCESS,
        payload: {
          health: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationHealth');
    }
  };

  const loadEligibilityApplicationInsurance = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationInsurance');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationInsurance', appId, clientId });
      let {insurance} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS,
        payload: {
          insurance: insurance,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationInsurance');
    }
  };

  const upsertEligibilityApplicationInsurance = async (clientId, appId, insurance) => {
    try {
      addBusyBee('upsertEligibilityApplicationInsurance');

      const toPost = {
        operationName: 'upsertApplicationInsurance',
        appId,
        insurance,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {insurance: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_INSURANCE_SUCCESS,
        payload: {
          insurance: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationInsurance');
    }
  };

  const loadEligibilityApplicationPersonalContacts = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationPersonalContacts');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationPersonalContacts', appId, clientId });
      let {personalContacts} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS,
        payload: {
          personalContacts: personalContacts,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationPersonalContacts');
    }
  };

  const upsertEligibilityApplicationPersonalContacts = async (clientId, appId, personalContacts) => {
    try {
      addBusyBee('upsertEligibilityApplicationPersonalContacts');

      const toPost = {
        operationName: 'upsertApplicationPersonalContacts',
        appId,
        personalContacts,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {personalContacts: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_PERSONAL_CONTACTS_SUCCESS,
        payload: {
          personalContacts: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationPersonalContacts');
    }
  };

  const loadEligibilityApplicationConsents = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationConsents');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationConsents', appId, clientId });
      let {consents} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS,
        payload: {
          consents: consents,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationConsents');
    }
  };

  const upsertEligibilityApplicationConsents = async (clientId, appId, consents) => {
    try {
      addBusyBee('upsertEligibilityApplicationConsents');

      const toPost = {
        operationName: 'upsertApplicationConsents',
        appId,
        consents,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {consents: updConsents} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_CONSENTS_SUCCESS,
        payload: {
          consents: updConsents
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationConsents');
    }
  };

  const loadEligibilityApplicationSubprograms = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationSubprograms');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationSubprograms', appId, clientId });
      let {subprograms} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS,
        payload: {
          subprograms: subprograms,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationSubprograms');
    }
  };

  const upsertEligibilityApplicationSubprograms = async (clientId, appId, subprograms) => {
    try {
      addBusyBee('upsertEligibilityApplicationSubprograms');

      const toPost = {
        operationName: 'upsertApplicationSubprograms',
        appId,
        subprograms,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {subprograms: updSubprograms, applicationMetadata: updMeta} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_SUBPROGRAMS_SUCCESS,
        payload: {
          subprograms: updSubprograms,
          meta: updMeta
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationSubprograms');
    }
  };
  const loadEligibilityApplicationServices = async (clientId, appId) => {
    addBusyBee('loadEligibilityApplicationServices');

    try {

      const { data } = await axios.post(`/api/clientEligibilityApp`, { operationName: 'getApplicationServices', appId, clientId });
      let {services} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS,
        payload: {
          services: services,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityApplicationServices');
    }
  };

  const upsertEligibilityApplicationServices = async (clientId, appId, services) => {
    try {
      addBusyBee('upsertEligibilityApplicationServices');

      const toPost = {
        operationName: 'upsertApplicationServices',
        appId,
        services,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {services: updServices} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_ELIGIBILITY_APPLICATION_SERVICES_SUCCESS,
        payload: {
          services: updServices
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertEligibilityApplicationServices');
    }
  };

  const startEligibilityApplication = async (clientId, appId) => {
    try {
      addBusyBee('startEligibilityApplication');

      const toPost = {
        operationName: 'startApplication',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.STARTING_ELIGIBILITY_APPLICATION_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('startEligibilityApplication');
    }
  };

  const submitEligibilityApplication = async (clientId, appId) => {
    try {
      addBusyBee('submitEligibilityApplication');

      const toPost = {
        operationName: 'submitApplication',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.SUBMITTING_ELIGIBILITY_APPLICATION_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('submitEligibilityApplication');
    }
  };

  const completeEligibilityApplicationReview = async (clientId, appId) => {
    try {
      addBusyBee('completeEligibilityApplication');

      const toPost = {
        operationName: 'completeApplicationReview',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.COMPLETING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('completeEligibilityApplication');
    }
  };

  const pendEligibilityApplication = async (clientId, appId) => {
    try {
      addBusyBee('pendEligibilityApplication');

      const toPost = {
        operationName: 'pendApplication',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.PENDING_ELIGIBILITY_APPLICATION_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('pendEligibilityApplication');
    }
  };

  const startEligibilityApplicationReview = async (clientId, appId) => {
    try {
      addBusyBee('startEligibilityApplicationReview');

      const toPost = {
        operationName: 'startApplicationReview',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.STARTING_ELIGIBILITY_APPLICATION_REVIEW_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('startEligibilityApplicationReview');
    }
  };

  const continueEligibilityApplication = async (clientId, appId) => {
    try {
      addBusyBee('continueEligibilityApplication');

      const toPost = {
        operationName: 'continueApplication',
        appId,
        clientId
      };

      const { data } = await axios.post(`/api/clientEligibilityApp`, toPost);
      const {applicationMetadata: updApp} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.CONTINUING_ELIGIBILITY_APPLICATION_SUCCESS,
        payload: {
          meta: updApp,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('continueEligibilityApplication');
    }
  };

  const createEligibilityApplication = async (clientId, isInitialApp, isRecert, isAdapOnly) => {

    addBusyBee('createEligibilityApplication');

    try {
      const toPost = {
        operationName: 'createApplication',
        isInitialApp,
        isRecert,
        isAdapOnly,
        clientId
      }
      
      const {data} = await axios.post(`/api/clientEligibilityApp`, toPost);
      let id = parseApiResult(data).body;

      return id;

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createEligibilityApplication');
    }
  };

  return {
    ...state,
    loadEligibilityApplicationMetadata,
    loadEligibilityApplicationApplicant,
    upsertEligibilityApplicationApplicant,
    loadEligibilityApplicationAddresses,
    upsertEligibilityApplicationAddresses,
    loadEligibilityApplicationIncome,
    upsertEligibilityApplicationIncome,
    loadEligibilityApplicationHealth,
    upsertEligibilityApplicationHealth,
    loadEligibilityApplicationInsurance,
    upsertEligibilityApplicationInsurance,
    loadEligibilityApplicationPersonalContacts,
    upsertEligibilityApplicationPersonalContacts,
    loadEligibilityApplicationSubprograms,
    upsertEligibilityApplicationSubprograms,
    loadEligibilityApplicationServices,
    upsertEligibilityApplicationServices,
    loadEligibilityApplicationConsents,
    upsertEligibilityApplicationConsents,
    startEligibilityApplication,
    submitEligibilityApplication,
    completeEligibilityApplicationReview,
    pendEligibilityApplication,
    startEligibilityApplicationReview,
    continueEligibilityApplication,
    createEligibilityApplication
  };
};
