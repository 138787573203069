import React from 'react';
import LotusInputField from '../LotusInputField';


export default function LotusFloatingPointInput({
  name,
  label,
  helperText,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  startAdornment,
  endAdornment,
  maxDecimalPlaces,
  minValue,
  maxValue
}) {
  return (
    <LotusInputField
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
      readOnly={readOnly}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      floatingPoint
      maxDecimalPlaces={maxDecimalPlaces}
      minValue={minValue}
      maxValue={maxValue}
    />
  );
}