export const messageTypes = {
  LOADING_PROPERTIES_SUCCESS: 'LOADING_PROPERTIES_SUCCESS',
  LOADING_AWS_SECRETS_SUCCESS: 'LOADING_AWS_SECRETS_SUCCESS'
}

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_PROPERTIES_SUCCESS:
    case messageTypes.LOADING_AWS_SECRETS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
