import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';
import LotusCard from 'components/dataDisplay/card/LotusCard';
import TemplateForm from '../components/forms/TemplateForm';

export default function EditTemplate() {
  const { id } = useParams();
  const { permissionTemplateToEdit, loadPermissionTemplateToEdit } = usePermissionTemplateManagement();
  const [currentTemplate, setCurrentTemplate] = useState();

  useEffect(() => {
    if (id) {
      loadPermissionTemplateToEdit(id);
    }
  }, [id]);

  useEffect(() => {
    if (!currentTemplate || (permissionTemplateToEdit && permissionTemplateToEdit.id === id)) {
      setCurrentTemplate(permissionTemplateToEdit);
    }
  }, [permissionTemplateToEdit]);

  return (
    <>
      {currentTemplate && (
        <LotusCard>
          <TemplateForm template={currentTemplate} />
        </LotusCard>
      )}
    </>
  );
}
