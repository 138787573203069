import { useEffect, useState } from 'react';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';
import { useParams } from 'react-router-dom';
import LotusCard from 'components/dataDisplay/card/LotusCard';
import TemplateForm from '../components/forms/TemplateForm';

export default function ReviewTemplate() {
  const { id } = useParams();
  const { permissionTemplateForReview, loadPermissionTemplateForReview } = usePermissionTemplateManagement();
  const [currentTemplate, setCurrentTemplate] = useState();

  useEffect(() => {
    if (id) {
      loadPermissionTemplateForReview(id);
    }
  }, [id]);

  useEffect(() => {
    if (!currentTemplate || (permissionTemplateForReview && permissionTemplateForReview.requestId === id)) {
      setCurrentTemplate(permissionTemplateForReview);
    }
  }, [permissionTemplateForReview]);

  return (
    <>
      {currentTemplate && (
        <LotusCard>
          <TemplateForm readOnly={true} template={currentTemplate} />
        </LotusCard>
      )}
    </>
  );
}
