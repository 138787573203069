import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { usePermissionTemplateRequests } from 'contexts/PermissionTemplateRequestsContext';
import LotusDataGrid from 'components/dataDisplay/datagrid/LotusDataGrid';
import ApprovedIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PERMISSIONS } from 'lib/permissionEnums';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';
import moment from 'moment';

export default function RequestsList({ requests }) {
  const [approvalRequests, setApprovalRequests] = useState([]);
  const navigate = useNavigate();

  const { user: loggedInUser, verifyPermission } = useAuthState();

  const { recentApprovals, loadRecentPermissionTemplateApprovals } = usePermissionTemplateRequests();

  useEffect(() => {
    if (!recentApprovals) {
      loadRecentPermissionTemplateApprovals();
    }
  }, []);

  useEffect(() => {
    setApprovalRequests([...(requests || []), ...(recentApprovals || [])]);
  }, [recentApprovals, requests]);

  const handleReviewClicked = (selectedRow) => {
    navigate(`/templates/review/${selectedRow?.id}`);
  };

  const RenderReviewCellView = (props) => {
    const { row } = props;
    const approvalDate = row?.approvalDate;
    const deniedReason = row?.deniedReason;
    const requestedById = row?.requestedById;

    if (approvalDate) {
      return (
        <div>
          <ApprovedIcon style={{ color: 'green' }} />
          <span style={{ marginLeft: 10, verticalAlign: 'top' }}>Approved</span>
        </div>
      );
    } else if (deniedReason) {
      return (
        <div>
          <div>
            <CloseIcon style={{ color: 'red' }} />
            <span style={{ marginLeft: 10, verticalAlign: 'top' }}>Denied</span>
          </div>
          <div>
            <div>
              <b>Reason:</b>
              {row?.deniedReason}
            </div>
          </div>
        </div>
      );
    } else {
      let reviewDisabled = false;
      reviewDisabled = requestedById === loggedInUser.id || !verifyPermission(PERMISSIONS.APPROVE_TEMPLATE);
      return (
        <LotusPrimaryActionButton disabled={reviewDisabled} onClick={() => !reviewDisabled && handleReviewClicked(row)}>
          Start Review
        </LotusPrimaryActionButton>
      );
    }
  };
  return (
    approvalRequests && <RequestsListView approvals={approvalRequests} RenderReviewCellView={RenderReviewCellView} />
  );
}

export function RequestsListView({ approvals, RenderReviewCellView }) {
  const columns = [
    {
      headerName: 'Approve/Deny',
      field: 'approvalDate',
      renderCell: RenderReviewCellView,
      minWidth: 180,
    },
    {
      headerName: 'Request',
      field: 'actionRequestTypeName',
      flex: 1,
    },
    {
      headerName: 'Template Name',
      field: 'permissionTemplateName',
      flex: 1,
    },
    {
      headerName: 'Submitted Date',
      field: 'submittedDate',
      flex: 1,
      valueFormatter: (value) => {
        // customize sortable date
        return value ? moment(value).format('MM/DD/YYYY') : '';
      },
    },
    {
      headerName: 'Requested By',
      field: 'requestedByEmail',
      flex: 1,
    },
    {
      headerName: 'Approve/Deny Date',
      field: 'approveDenyDate',
      flex: 1,
      valueGetter: (value, row) => {
        return row?.approvalDate
          ? moment(row.approvalDate).format('MM/DD/YYYY')
          : row?.deniedDate
            ? moment(row.deniedDate).format('MM/DD/YYYY')
            : '';
      },
    },
  ];
  return <>{approvals && <LotusDataGrid columns={columns} rows={approvals} disableColumnFilter />}</>;
}
