import LotusSwitch from 'components/form/switch/LotusSwitch';

export default function PermissionsPanel({ permissionsContainer, name, categoriesIndex, readOnly }) {
  return (
    permissionsContainer &&
    permissionsContainer.permissions
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((permission, index) => {
        return (
          <LotusSwitch
            key={`${name}-${permission.id}`}
            name={`${name}.${categoriesIndex}.permissions.${index}.enabled`}
            label={permission.name}
            readOnly={readOnly}
          />
        );
      })
  );
}
