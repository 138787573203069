import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import LotusSelect from "components/widgets/Forms/LotusSelect";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import LotusSwitch from "components/widgets/Forms/LotusSwitch";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import { useClientDocumentation } from "contexts/ClientDocumentationContext";
import { useProgram } from "contexts/ProgramContext";
import { useClient } from "contexts/ClientContext";
import { useAuthState } from "contexts/AuthProvider";
import { useEnrollments } from "contexts/EnrollmentsContext";
import moment from "moment";
import InlineEditableStaticForm from "components/Forms/InlineEditableStaticForm";
import LotusForm from "components/widgets/Forms/LotusForm";
import LotusFormItems from "components/widgets/Forms/LotusFormItems";
import { Stack } from "@mui/material";
import LotusSideBySide from "components/widgets/Forms/LotusSideBySide";

export default function ContactForm({
  contact,
  readOnly = true,
  afterSave,
  handleCancel,
  contactDisplayName
}) {
  const { user: loggedInUser } = useAuthState();
  const { client } = useClient();
  const {
    currentProgram,
    currentProgramContactTypes,
    currentProgramContactMethods,
    currentProgramContactMadeAttemptedOptions,
    currentProgramContactCategories,
    currentProgramContactSiteVisitTypes,
    currentProgramContactDelayReasons,
    currentProgramContactMissedReasons,
    currentProgramContactServiceSites,
    currentProgramContactReportOptions,
    currentProgramInterpretationServices,
    loadProgramContactPicklists,
    loadProgramInterpretationServices,
    loadProgramUsers, currentProgramUsers
  } = useProgram();
  const { upsertContact } = useClientDocumentation();
  const [initialValues, setInitialValues] = useState();
  const { enrolledPrograms, findEnrollment, loadEnrollmentsForClient } = useEnrollments();
  const [enrollment, setEnrollment] = useState();
  const [disallowEdit, setDisallowEdit] = useState();
  const [madeAttemptedEnabledForType, setMadeAttemptedEnabledForType] = useState(false);
  const [siteVisitTypeOptionsForMethod, setSiteVisitTypeOptionsForMethod] = useState(false);

  useEffect(() => {
    if (contact) {
      setDisallowEdit(contact.isComplete || readOnly);
    }
  }, [readOnly, contact]);

  useEffect(() => {
    if (client) {
      loadEnrollmentsForClient(client.id, client.agencyId); 
    }
  }, [client, currentProgram]);

  useEffect(() => {
    if (enrolledPrograms && client) {
      const enrollment = findEnrollment(currentProgram.id);
      setEnrollment(enrollment);
    }
  }, [enrolledPrograms, client, currentProgram]);


  useEffect(() => {
    if (currentProgram && !currentProgramContactMethods) {
      loadProgramContactPicklists();
    }
    if (currentProgram && !currentProgramUsers) {
      loadProgramUsers();
    }
    if (currentProgram && !currentProgramInterpretationServices) {
      loadProgramInterpretationServices();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (contact && currentProgramContactSiteVisitTypes && currentProgramContactMethods) {
      const method = currentProgramContactMethods && currentProgramContactMethods.find(t => t.id === contact.contactMethodId);
      setSiteVisitTypeOptionsForMethod((method && currentProgramContactSiteVisitTypes && currentProgramContactSiteVisitTypes.filter(s => s.methodId === method.id)) || []);
    }
  }, [contact, currentProgramContactSiteVisitTypes, currentProgramContactMethods]);

  useEffect(() => {
    if (contact && currentProgramContactTypes) {
      const typ = currentProgramContactTypes && currentProgramContactTypes.find(t => t.id === contact.contactTypeId);
      setMadeAttemptedEnabledForType(typ?.isMadeAttemptedEnabled);
    }
  }, [contact, currentProgramContactTypes]);

  useEffect(() => {
    if (loggedInUser && enrollment && client && currentProgram) {
      let initialValue = contact ?
      {
        id: contact.id,
        clientId: contact.clientId,
        programId: contact.programId,
        contactTypeId: contact.contactTypeId || '',
        contactMethodId: contact.contactMethodId || '',
        contactMadeAttemptedOptionId: contact.contactMadeAttemptedOptionId || '',
        contactCategoryId: contact.contactCategoryId || '',
        contactSiteVisitTypeId: contact.contactSiteVisitTypeId || '',
        recordedDate: contact.recordedDate || null,
        isMissed: contact.isMissed || false,
        isDelayed: contact.isDelayed || false,
        contactMissedReasonId: contact.contactMissedReasonId || '',
        contactDelayReasonId: contact.contactDelayReasonId || '',
        durationMinutes: contact.durationMinutes || '',
        contactTimeMinutes: contact.contactTimeMinutes || '',
        travelTimeMinutes: contact.travelTimeMinutes || '',
        documentationTimeMinutes: contact.documentationTimeMinutes || '',
        conductorAccountId: contact.conductorAccountId || '',
        coordinatorAccountId: contact.coordinatorAccountId || '',
        coordinatorName: contact.coordinatorName || '',
        contactServiceSiteId: contact.contactServiceSiteId || '',
        interviewerAccountId: contact.interviewerAccountId || '',
        interviewerName: contact.interviewerName || '',
        respondentType: contact.respondentType || '',
        respondentName: contact.respondentName || '',
        isInterpretationServiceUsed: contact.isInterpretationServiceUsed || false,
        programInterpretationServiceId: contact.programInterpretationServiceId || '',
        otherInterpretationServiceUsed: contact.otherInterpretationServiceUsed || '',
        interpreterInformation: contact.interpreterInformation || '',
        contactReportOptionId: contact.contactReportOptionId || '',
        briefDescription: contact.briefDescription || '',
        fullDescription: contact.fullDescription || '',
        isComplete: contact.isComplete || false
      } :
      {
        clientId: client.id,
        programId: currentProgram.id,
        contactTypeId: '',
        contactMethodId: '',
        contactMadeAttemptedOptionId: '',
        contactCategoryId: '',
        contactSiteVisitTypeId: '',
        recordedDate: moment().format('MM/DD/YYYY'),
        isMissed: false,
        isDelayed: false,
        contactMissedReasonId: '',
        contactDelayReasonId: '',
        durationMinutes: '',
        contactTimeMinutes: '',
        travelTimeMinutes: '',
        documentationTimeMinutes: '',
        conductorAccountId: loggedInUser.id,
        coordinatorAccountId: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserId : '',
        coordinatorName: enrollment?.assignees?.length > 0 ? enrollment?.assignees[0].assigneeUserName : '',
        contactServiceSiteId: '',
        interviewerAccountId: loggedInUser.id,
        interviewerName: loggedInUser.firstName + ' ' + loggedInUser.lastName,
        respondentType: '',
        respondentName: '',
        isInterpretationServiceUsed: false,
        programInterpretationServiceId: '',
        otherInterpretationServiceUsed: '',
        interpreterInformation: '',
        contactReportOptionId: '',
        briefDescription: '',
        fullDescription: '',
        isComplete: false
      };
      setInitialValues(initialValue);
    }
  }, [contact, loggedInUser, enrollment, client, currentProgram]);

  const onSubmit = async (newValues) => {
    if (!disallowEdit) {
      await upsertContact(newValues);
    }

    if (afterSave) {
      await afterSave(newValues);
    }
  }

  function buildFormContent(disallowEdit, formValues, formHelpers) {
    const handleTypeChange = (newValue) => {
      const typ = currentProgramContactTypes && currentProgramContactTypes.find(t => t.id === newValue);
      setMadeAttemptedEnabledForType(typ?.isMadeAttemptedEnabled);
    }
    const handleMethodChange = (newValue) => {
      formHelpers.setFieldValue('contactSiteVisitTypeId', '');
      const method = currentProgramContactMethods && currentProgramContactMethods.find(t => t.id === newValue);
      setSiteVisitTypeOptionsForMethod((method && currentProgramContactSiteVisitTypes && currentProgramContactSiteVisitTypes.filter(s => s.methodId === method.id)) || []);
    }
    const handleMinutesChange = (e, fld) => {
      let min = Number(e.target.value);
      const interval = currentProgram?.contactConfig.minutesRoundingInterval;
      if (min && interval) {
        const rounded = Math.ceil(min/interval)*interval;
        formHelpers.setFieldValue(fld, rounded);
      }
    }

    return (
      <LotusForm>
        <LotusFormItems>
          {currentProgram.contactConfig.completeEnabled &&
            <LotusSwitch
              disabled={disallowEdit}
              name="isComplete"
              trueText="Complete"
              falseText="In Process"
            />
          }
          {currentProgram.contactConfig.typeEnabled && currentProgramContactTypes &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactTypeId'
              required
              label='Type'
              items={currentProgramContactTypes && currentProgramContactTypes.map(s => {return {label: s.name, value: s.id}})}
              handleChange={handleTypeChange}
            />     
          } 
          {currentProgramContactMethods &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactMethodId'
              required
              label='Method'
              items={currentProgramContactMethods && currentProgramContactMethods.map(s => {return {label: s.name, value: s.id}})}
              handleChange={handleMethodChange}
            />    
          } 
          {currentProgram.contactConfig.madeAttemptedEnabled && madeAttemptedEnabledForType && currentProgramContactMadeAttemptedOptions &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactMadeAttemptedOptionId'
              required
              label='Made / Attempted'
              items={currentProgramContactMadeAttemptedOptions && currentProgramContactMadeAttemptedOptions.map(s => {return {label: s.name, value: s.id}})}
            />   
          }
          {currentProgram.contactConfig.categoryEnabled && currentProgramContactCategories &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactCategoryId'
              required
              label='Category'
              items={currentProgramContactCategories && currentProgramContactCategories.map(s => {return {label: s.name, value: s.id}})}
            />   
          } 
          {currentProgram.contactConfig.siteVisitTypeEnabled && siteVisitTypeOptionsForMethod && siteVisitTypeOptionsForMethod.length > 0 &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactSiteVisitTypeId'
              required
              label='Site Visit Type'
              items={siteVisitTypeOptionsForMethod && siteVisitTypeOptionsForMethod.map(s => {return {label: s.name, value: s.id}})}
            />   
          }
          <Grid item xs={12}>
            <LotusDatePicker
              disabled={disallowEdit}
              name="recordedDate"
              label="Date"
              required
              minDate={moment().add(-18, 'months').format('MM/DD/YYYY')}
              maxDate={moment().format('MM/DD/YYYY')}
            />
          </Grid> 
          {currentProgram.contactConfig.missedEnabled &&
            <LotusSwitch
              disabled={disallowEdit}
              name="isMissed"
              label="Missed ?"
              labelPlacement="start"
            />
          }
          {currentProgram.contactConfig.missedEnabled && currentProgram.contactConfig.missedReasonEnabled && currentProgramContactMissedReasons && formValues.isMissed &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactMissedReasonId'
              required
              label='Missed Reason'
              items={currentProgramContactMissedReasons && currentProgramContactMissedReasons.map(s => {return {label: s.name, value: s.id}})}
            />    
          }
          {currentProgram.contactConfig.delayedEnabled &&
            <LotusSwitch
              disabled={disallowEdit}
              name="isDelayed"
              label="Delayed ?"
              labelPlacement="start"
            />
          }
          {currentProgram.contactConfig.delayedEnabled && currentProgram.contactConfig.delayReasonEnabled && currentProgramContactDelayReasons && formValues.isDelayed &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactDelayReasonId'
              required
              label='Delayed Reason'
              items={currentProgramContactDelayReasons && currentProgramContactDelayReasons.map(s => {return {label: s.name, value: s.id}})}
            />    
          }
          {currentProgram.contactConfig.minutesEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='durationMinutes'
              label='Minutes'
              onBlur={(e) => handleMinutesChange(e, 'durationMinutes')}
              integer
            />
          }
          {currentProgram.contactConfig.contactTimeMinutesEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='contactTimeMinutes'
              label='Contact Time (minutes)'
              onBlur={(e) => handleMinutesChange(e, 'contactTimeMinutes')}
              integer
            />
          }
          {currentProgram.contactConfig.travelTimeMinutesEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='travelTimeMinutes'
              label='Travel Time (minutes)'
              onBlur={(e) => handleMinutesChange(e, 'travelTimeMinutes')}
              integer
            />
          }
          {currentProgram.contactConfig.documentationTimeMinutesEnabled &&
          <Grid item xs={12}>
            <LotusTextInput
              disabled={disallowEdit}
              name='documentationTimeMinutes'
              label='Documentation Time'
              onBlur={(e) => handleMinutesChange(e, 'documentationTimeMinutes')}
              integer
            />
          </Grid> 
          }
          {currentProgram.contactConfig.conductedByEnabled && currentProgramUsers &&
            <LotusSelect
              disabled={disallowEdit}
              name='conductorAccountId'
              required
              label='Conducted By'
              items={currentProgramUsers.map(s => {return {label: s.name, value: s.id}})}
            /> 
          } 
          {currentProgram.contactConfig.coordinatorEnabled && 
          <Grid item xs={12}>
            <LotusTextInput
              disabled={true}
              name='coordinatorName'
              label='Coordinator'
            />
          </Grid>  
          }
          {currentProgram.contactConfig.serviceSiteEnabled && currentProgramContactServiceSites &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactServiceSiteId'
              required
              label='Service Site'
              items={currentProgramContactServiceSites && currentProgramContactServiceSites.map(s => {return {label: s.name, value: s.id}})}
            />  
          }
          {currentProgram.contactConfig.interviewerEnabled && 
          <Grid item xs={12}>
            <LotusTextInput
              disabled={true}
              name='interviewerName'
              label='Interviewer'
            />
          </Grid>
          }
          {currentProgram.contactConfig.respondentEnabled &&
            <LotusSideBySide
              leftControl={<LotusSelect
                disabled={disallowEdit}
                name='respondentType'
                required
                label='Respondent'
                items={['Client', 'Client Present', 'Personal Contact'].map(s => {return {label: s, value: s}})}
              />}
              rightControl={
                (formValues.respondentType === 'Personal Contact' &&    
                  <LotusTextInput
                    disabled={disallowEdit}
                    name='respondentName'
                    label='Respondent Name'
                  />) || <span></span>
              }
            />  
          }
          {currentProgram.contactConfig.isInterpretationServiceUsedEnabled && 
            <LotusSwitch
              disabled={disallowEdit}
              name="isInterpretationServiceUsed"
              label="Interpretation Service Used?"
              labelPlacement="start"
            />
          }
          {formValues.isInterpretationServiceUsed && currentProgram.contactConfig.interpretationServiceEnabled && currentProgramInterpretationServices &&
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <LotusSelect
                disabled={disallowEdit}
                name='programInterpretationServiceId'
                label='Interpretation Service'
                items={currentProgramInterpretationServices.map(s => {return {label: s.name, value: s.id}})}
                style={{width:245}}
              />
              <div>
                or
              </div>
              <LotusTextInput
                disabled={disallowEdit}
                name='otherInterpretationServiceUsed'
                label='Other Interpretation Service Used'
                style={{width:220}}
              />
            </Stack>
          }
          {formValues.isInterpretationServiceUsed && currentProgram.contactConfig.interpretationServiceEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name='interpreterInformation'
              label='Interpreter Information'
            />    
          } 
          {currentProgram.contactConfig.reportOptionEnabled && currentProgramContactReportOptions &&
            <LotusSelect
              disabled={disallowEdit}
              name='contactReportOptionId'
              label='Send report to client / family'
              items={currentProgramContactReportOptions && currentProgramContactReportOptions.map(s => {return {label: s.name, value: s.id}})}
            />   
          }
          {currentProgram.contactConfig.briefDescriptionEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name="briefDescription"
              label="Brief Description"
            />
          }
          {currentProgram.contactConfig.fullDescriptionEnabled &&
            <LotusTextInput
              disabled={disallowEdit}
              name="fullDescription"
              multiline
              rows={5}
              label="Update"
            />
          }
        </LotusFormItems>
      </LotusForm>
    );
  }

  return initialValues && (
    <InlineEditableStaticForm
      headerName={`${(contact ? 'Edit' : 'Add')} ${contactDisplayName}`}
      formValues={initialValues}
      onSubmitForm={onSubmit}
      onCancel={handleCancel}
      buildFormContent={buildFormContent}
      disabled={readOnly || initialValues.isComplete}
    />
  );

}
