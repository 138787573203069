import moment from "moment";

export const messageTypes = {
  LOADING_ENROLLMENTS_FOR_CLIENT_SUCCESS: 'LOADING_ENROLLMENTS_FOR_CLIENT_SUCCESS',
  LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS: 'LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS',
  LOADING_RYAN_WHITE_ENROLLMENTS_FOR_CLIENT_SUCCESS: 'LOADING_RYAN_WHITE_ENROLLMENTS_FOR_CLIENT_SUCCESS',
  UPSERTING_CUSTOM_PROGRAM_ENROLLMENT_SUCCESS: 'UPSERTING_CUSTOM_PROGRAM_ENROLLMENT_SUCCESS',
  TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS: 'TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  const buildEnrolledPrograms = (enrollments) => {
    const summaries = [];
    let now = moment();
    enrollments.forEach(e => {
      if (e.programTypeKey === 'custom' ||
          (e.programTypeKey === 'ryan_white' && moment(e.startDate,'MM/DD/YYYY').startOf('day') < now && (!e.endDate || (moment(e.endDate,'MM/DD/YYYY').endOf('day') > now))) ||
          (e.programTypeKey === 'adap' && moment(e.startDate,'MM/DD/YYYY').startOf('day') < now && (!e.endDate || (moment(e.endDate,'MM/DD/YYYY').endOf('day') > now)) && e.isActive)
        ) {
        if (!summaries.find(s => s.programId === e.programId)) {
          summaries.push({
            programId: e.programId,
            programName: e.programName,
            programTypeKey: e.programTypeKey
          });
        }
      }
    });
    return summaries;
  }

  switch (type) {
    case messageTypes.LOADING_ENROLLMENTS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        customProgramEnrollments: payload.customProgramEnrollments,
        ryanWhiteProgramEnrollments: payload.ryanWhiteProgramEnrollments,
        adapProgramEnrollments: payload.adapProgramEnrollments,
        enrolledPrograms: buildEnrolledPrograms([...payload.customProgramEnrollments, ...payload.ryanWhiteProgramEnrollments,  ...payload.adapProgramEnrollments])
    };
    case messageTypes.LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        adapProgramEnrollments: payload.adapProgramEnrollments,
      };
    case messageTypes.LOADING_RYAN_WHITE_ENROLLMENTS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        ryanWhiteProgramEnrollments: payload.ryanWhiteProgramEnrollments,
      };
    case messageTypes.UPSERTING_CUSTOM_PROGRAM_ENROLLMENT_SUCCESS:
      let customEnrollments = state.customProgramEnrollments || [];
      let enrollmentsWithoutUpdatedOne = customEnrollments.filter(e => e.programId !== payload.enrollment.programId);
      enrollmentsWithoutUpdatedOne.push(payload.enrollment);
      return {
        ...state,
        customProgramEnrollments: enrollmentsWithoutUpdatedOne,
        enrolledPrograms: buildEnrolledPrograms([...enrollmentsWithoutUpdatedOne, ...state.ryanWhiteProgramEnrollments,  ...state.adapProgramEnrollments])
      };
    case messageTypes.TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS:
      let adapEnrollments = state.adapProgramEnrollments || [];
      let enrollmentsWithoutTerminatedOne = adapEnrollments.filter(e => e.id !== payload.enrollment.id);
      enrollmentsWithoutTerminatedOne.push(payload.enrollment);
      return {
        ...state,
        adapProgramEnrollments: enrollmentsWithoutTerminatedOne,
        enrolledPrograms: buildEnrolledPrograms([...enrollmentsWithoutTerminatedOne, ...state.ryanWhiteProgramEnrollments,  ...state.customProgramEnrollments])
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
