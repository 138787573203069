import React from 'react';
import { useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Stack, Typography } from '@mui/material';

export default function LotusCheckbox({
  name,
  label,
  helperText,
  disabled,
  readOnly,
  handleChange
}) {

  const [field] = useField(name);

  const handleLocalChange = (e) => {
    const newValue = e.target.checked;
    if (handleChange) {
      handleChange(newValue);
    }
    field.onChange(e);
  };

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && label &&
        <FormControlLabel 
          control={
            <Checkbox 
              {...field} 
              onChange={handleLocalChange}
              disabled={disabled}
              checked={field.value === true}
            />
          } 
          label={label}
        />
      }
      {!readOnly && !label &&
        <Checkbox 
          {...field} 
          onChange={handleLocalChange}
          disabled={disabled}
          checked={field.value === true}
        />
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={field.value ? 'Yes' : 'No'} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 32}}>
            {helperText}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
