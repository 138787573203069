export const messageTypes = {
  LOADING_PROGRAM_SUCCESS: 'LOADING_PROGRAM_SUCCESS',
  LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_AGENCIES_SUCCESS: 'LOADING_PROGRAM_AGENCIES_SUCCESS',
  LOADING_PROGRAM_ASSESSMENTS_SUCCESS: 'LOADING_PROGRAM_ASSESSMENTS_SUCCESS',
  LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_CONSENT_TYPES_SUCCESS: 'LOADING_PROGRAM_CONSENT_TYPES_SUCCESS',
  LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS: 'LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS',
  LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS: 'LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS',
  LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_USERS_SUCCESS: 'LOADING_PROGRAM_USERS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  function copyObject(curObj) {
    let newItems = {};
    Object.entries(curObj || {}).forEach(([k, v]) => {
      newItems[k] = v;
    });
    return newItems;
  }
  
  switch (type) {
    case messageTypes.LOADING_PROGRAM_SUCCESS: {
      let newState = { 
        ...state,
        currentProgram: payload.program,
        loadedPrograms: copyObject(state.loadedPrograms)
      };

      newState.loadedPrograms[payload.program.id] = payload.program;

      if (!state.currentProgram || state.currentProgram.id !== payload.program.id) {
        // If we've already loaded stuff for the newly loaded program, set those as current
        newState.currentProgramActivityLogStatuses = state.loadedProgramActivityLogStatuses[payload.program.id];
        newState.currentProgramActivityLogTypes = state.loadedProgramActivityLogTypes[payload.program.id];
        newState.currentProgramActivityLogSubjects = state.loadedProgramActivityLogSubjects[payload.program.id];
        newState.currentProgramActivityLogWithOptions = state.loadedProgramActivityLogWithOptions[payload.program.id];
        newState.currentProgramAdditionalClientData = state.loadedProgramAdditionalClientData[payload.program.id];
        newState.currentProgramAssessments = state.loadedProgramAssessments[payload.program.id];
        newState.currentProgramCarePlanLifeAreaIds = state.loadedProgramCarePlanLifeAreaIds[payload.program.id];
        newState.currentProgramCarePlanCategoryIds = state.loadedProgramCarePlanCategoryIds[payload.program.id];
        newState.currentProgramCarePlanGoalCloseReasons = state.loadedProgramCarePlanGoalCloseReasons[payload.program.id];
        newState.currentProgramCarePlanGoalTypes = state.loadedProgramCarePlanGoalTypes[payload.program.id];
        newState.currentProgramCarePlanInterventionTypes = state.loadedProgramCarePlanInterventionTypes[payload.program.id];
        newState.currentProgramConsentTypeIds = state.loadedProgramConsentTypeIds[payload.program.id];
        newState.currentProgramContactTypes = state.loadedProgramContactTypes[payload.program.id];
        newState.currentProgramContactMethods = state.loadedProgramContactMethods[payload.program.id];
        newState.currentProgramContactMadeAttemptedOptions = state.loadedProgramContactMadeAttemptedOptions[payload.program.id];
        newState.currentProgramContactCategories = state.loadedProgramContactCategories[payload.program.id];
        newState.currentProgramContactSiteVisitTypes = state.loadedProgramContactSiteVisitTypes[payload.program.id];
        newState.currentProgramContactDelayReasons = state.loadedProgramContactDelayReasons[payload.program.id];
        newState.currentProgramContactMissedReasons = state.loadedProgramContactMissedReasons[payload.program.id];
        newState.currentProgramContactServiceSites = state.loadedProgramContactServiceSites[payload.program.id];
        newState.currentProgramContactReportOptions = state.loadedProgramContactReportOptions[payload.program.id];
        newState.currentProgramDocumentTypeIds = state.loadedProgramDocumentTypeIds[payload.program.id];
        newState.currentProgramEnrollmentStatuses = state.loadedProgramEnrollmentStatuses[payload.program.id];
        newState.currentProgramEnrollmentCloseReasons = state.loadedProgramEnrollmentCloseReasons[payload.program.id];
        newState.currentProgramEnrollmentAssignees = state.loadedProgramEnrollmentAssignees[payload.program.id];
        newState.currentProgramInterpretationServices = state.loadedProgramInterpretationServices[payload.program.id];
        newState.currentProgramMeetingTypes = state.loadedProgramMeetingTypes[payload.program.id];
        newState.currentProgramMeetingInviteeTypes = state.loadedProgramMeetingInviteeTypes[payload.program.id];
      }
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramActivityLogStatuses: payload.activityLogStatuses,
        currentProgramActivityLogTypes: payload.activityLogTypes,
        currentProgramActivityLogSubjects: payload.activityLogSubjects,
        currentProgramActivityLogWithOptions: payload.activityLogWithOptions,
        loadedProgramActivityLogStatuses: copyObject(state.loadedProgramActivityLogStatuses),
        loadedProgramActivityLogTypes: copyObject(state.loadedProgramActivityLogTypes),
        loadedProgramActivityLogSubjects: copyObject(state.loadedProgramActivityLogSubjects),
        loadedProgramActivityLogWithOptions: copyObject(state.loadedProgramActivityLogWithOptions),
      };
      newState.loadedProgramActivityLogStatuses[state.currentProgram.id] = payload.activityLogStatuses;
      newState.loadedProgramActivityLogTypes[state.currentProgram.id] = payload.activityLogTypes;
      newState.loadedProgramActivityLogSubjects[state.currentProgram.id] = payload.activityLogSubjects;
      newState.loadedProgramActivityLogWithOptions[state.currentProgram.id] = payload.activityLogWithOptions;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_ASSESSMENTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramAssessments: payload.assessments,
        loadedProgramAssessments: copyObject(state.loadedProgramAssessments),
      };
      newState.loadedProgramAssessments[state.currentProgram.id] = payload.assessments;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramCarePlanLifeAreaIds: payload.carePlanLifeAreaIds,
        currentProgramCarePlanCategoryIds: payload.carePlanCategoryIds,
        currentProgramCarePlanGoalCloseReasons: payload.carePlanGoalCloseReasons,
        currentProgramCarePlanGoalTypes: payload.carePlanGoalTypes,
        currentProgramCarePlanInterventionTypes: payload.carePlanInterventionTypes,
        loadedProgramCarePlanLifeAreaIds: copyObject(state.loadedProgramCarePlanLifeAreaIds),
        loadedProgramCarePlanCategoryIds: copyObject(state.loadedProgramCarePlanCategoryIds),
        loadedProgramCarePlanGoalCloseReasons: copyObject(state.loadedProgramCarePlanGoalCloseReasons),
        loadedProgramCarePlanGoalTypes: copyObject(state.loadedProgramCarePlanGoalTypes),
        loadedProgramCarePlanInterventionTypes: copyObject(state.loadedProgramCarePlanInterventionTypes),
      };
      newState.loadedProgramCarePlanLifeAreaIds[state.currentProgram.id] = payload.carePlanLifeAreaIds;
      newState.loadedProgramCarePlanCategoryIds[state.currentProgram.id] = payload.carePlanCategoryIds;
      newState.loadedProgramCarePlanGoalCloseReasons[state.currentProgram.id] = payload.carePlanGoalCloseReasons;
      newState.loadedProgramCarePlanGoalTypes[state.currentProgram.id] = payload.carePlanGoalTypes;
      newState.loadedProgramCarePlanInterventionTypes[state.currentProgram.id] = payload.carePlanInterventionTypes;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_CONSENT_TYPES_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramConsentTypeIds: payload.consentTypeIds,
        loadedProgramConsentTypeIds: copyObject(state.loadedProgramConsentTypeIds),
      };
      newState.loadedProgramConsentTypeIds[state.currentProgram.id] = payload.consentTypeIds;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramContactTypes: payload.contactTypes,
        currentProgramContactMethods: payload.contactMethods,
        currentProgramContactMadeAttemptedOptions: payload.contactMadeAttemptedOptions,
        currentProgramContactCategories: payload.contactCategories,
        currentProgramContactSiteVisitTypes: payload.contactSiteVisitTypes,
        currentProgramContactDelayReasons: payload.contactDelayReasons,
        currentProgramContactMissedReasons: payload.contactMissedReasons,
        currentProgramContactServiceSites: payload.contactServiceSites,
        currentProgramContactReportOptions: payload.contactReportOptions,
        loadedProgramContactTypes: copyObject(state.loadedProgramContactTypes),
        loadedProgramContactMethods: copyObject(state.loadedProgramContactMethods),
        loadedProgramContactMadeAttemptedOptions: copyObject(state.loadedProgramContactMadeAttemptedOptions),
        loadedProgramContactCategories: copyObject(state.loadedProgramContactCategories),
        loadedProgramContactSiteVisitTypes: copyObject(state.loadedProgramContactSiteVisitTypes),
        loadedProgramContactDelayReasons: copyObject(state.loadedProgramContactDelayReasons),
        loadedProgramContactMissedReasons: copyObject(state.loadedProgramContactMissedReasons),
        loadedProgramContactServiceSites: copyObject(state.loadedProgramContactServiceSites),
        loadedProgramContactReportOptions: copyObject(state.loadedProgramContactReportOptions),
      };
      newState.loadedProgramContactTypes[state.currentProgram.id] = payload.contactTypes;
      newState.loadedProgramContactMethods[state.currentProgram.id] = payload.contactMethods;
      newState.loadedProgramContactMadeAttemptedOptions[state.currentProgram.id] = payload.contactMadeAttemptedOptions;
      newState.loadedProgramContactCategories[state.currentProgram.id] = payload.contactCategories;
      newState.loadedProgramContactSiteVisitTypes[state.currentProgram.id] = payload.contactSiteVisitTypes;
      newState.loadedProgramContactDelayReasons[state.currentProgram.id] = payload.contactDelayReasons;
      newState.loadedProgramContactMissedReasons[state.currentProgram.id] = payload.contactMissedReasons;
      newState.loadedProgramContactServiceSites[state.currentProgram.id] = payload.contactServiceSites;
      newState.loadedProgramContactReportOptions[state.currentProgram.id] = payload.contactReportOptions;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramDocumentTypeIds: payload.documentTypeIds,
        loadedProgramDocumentTypeIds: copyObject(state.loadedProgramDocumentTypeIds),
      };
      newState.loadedProgramDocumentTypeIds[state.currentProgram.id] = payload.documentTypeIds;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramEnrollmentStatuses: payload.enrollmentStatuses,
        currentProgramEnrollmentCloseReasons: payload.enrollmentCloseReasons,
        currentProgramEnrollmentAssignees: payload.enrollmentAssignees,
        loadedProgramEnrollmentStatuses: copyObject(state.loadedProgramEnrollmentStatuses),
        loadedProgramEnrollmentCloseReasons: copyObject(state.loadedProgramEnrollmentCloseReasons),
        loadedProgramEnrollmentAssignees: copyObject(state.loadedProgramEnrollmentAssignees),
      };
      newState.loadedProgramEnrollmentStatuses[state.currentProgram.id] = payload.enrollmentStatuses;
      newState.loadedProgramEnrollmentCloseReasons[state.currentProgram.id] = payload.enrollmentCloseReasons;
      newState.loadedProgramEnrollmentAssignees[state.currentProgram.id] = payload.enrollmentAssignees;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramInterpretationServices: payload.interpretationServices,
        loadedProgramInterpretationServices: copyObject(state.loadedProgramInterpretationServices),
      };
      newState.loadedProgramInterpretationServices[state.currentProgram.id] = payload.interpretationServices;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramMeetingTypes: payload.meetingTypes,
        currentProgramMeetingInviteeTypes: payload.meetingInviteeTypes,
        loadedProgramMeetingTypes: copyObject(state.loadedProgramMeetingTypes),
        loadedProgramMeetingInviteeTypes: copyObject(state.loadedProgramMeetingInviteeTypes),
      };
      newState.loadedProgramMeetingTypes[state.currentProgram.id] = payload.meetingTypes;
      newState.loadedProgramMeetingInviteeTypes[state.currentProgram.id] = payload.meetingInviteeTypes;
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_USERS_SUCCESS: {
      let newState = { 
        ...state,
        currentProgramUsers: payload.users,
        loadedProgramUsers: copyObject(state.loadedProgramUsers),
      };
      newState.loadedProgramUsers[state.currentProgram.id] = payload.users;
      return newState;
    }
    default:
      break;
  }
};
