import React, { useEffect, useState } from 'react';
import ApprovedIcon from '@mui/icons-material/Check';
import DeniedIcon from '@mui/icons-material/Close';
import { useAuthState } from 'contexts/AuthProvider';
import { useAgencyRequests } from 'contexts/AgencyRequestsContext';
import { PERMISSIONS } from 'lib/permissionEnums';
import Stack from '@mui/material/Stack';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';
import { Typography } from '@mui/material';
import LotusDataGrid from 'components/dataDisplay/datagrid/LotusDataGrid';
import moment from 'moment';
import AgencyDenialDialog from './dialogs/AgencyDenialDialog';
import _ from 'lodash';

export default function RequestsList({ requests }) {
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const hasApproveAgencyPermission = verifyPermission(PERMISSIONS.APPROVE_SUB_AGENCY);
  const [approvals, setApprovals] = useState();

  const {
    recentApprovals,
    loadRecentAgencyApprovals,
    approveAgencyRequest,
    denyAgencyRequest
  } = useAgencyRequests();

  useEffect(() => {
    if (!recentApprovals) {
      loadRecentAgencyApprovals();
    }
  }, []);

  const denyRequest = async (actionRequest, denyReason) => {
    await denyAgencyRequest(actionRequest.id, denyReason);
  };

  const approveRequest = async (actionRequest) => {
    await approveAgencyRequest(actionRequest.id);
  };

  useEffect(() => {
    let x = _.cloneDeep(recentApprovals);
    setApprovals(x);
  }, [recentApprovals]);

  return requests && approvals && (
    <RequestsListView
      recentApprovals={approvals}
      requests={requests}
      handleApproveRequest={approveRequest}
      handleDenyRequest={denyRequest}
      currentUser={loggedInUser}
      hasApproveAgencyPermission={hasApproveAgencyPermission}
    />
  );
}

export function RequestsListView({ 
  requests, 
  recentApprovals, 
  handleApproveRequest, 
  handleDenyRequest,
  currentUser,
  hasApproveAgencyPermission
}) {
  const [allApprovals, setAllApprovals] = useState();
  const [showDenyRequest, setShowDenyRequest] = useState(false);
  const [requestToProcess, setRequestToProcess] = useState();

  const approveClicked = async (actionRequest) => {
    handleApproveRequest(actionRequest);
  };

  const denyClicked = async (actionRequest) => {
    setShowDenyRequest(true);
    setRequestToProcess(actionRequest);
  };

  const getApprovalCellView = (params) => {
    const row = params.row;
    if (row.approvalDate) {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <ApprovedIcon color='success' />
          <Typography variant="body1" color='success'>Approved</Typography>
        </Stack>
      );
    } else if (row.deniedDate) {
      return (
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <DeniedIcon color='error' />
            <Typography variant="body1" color='success'>Denied</Typography>
          </Stack>
          <Typography variant="body2">Reason: {row.deniedReason}</Typography>
        </Stack>
      );
    } else {
      return (
        <LotusButtonList>
          <LotusSecondaryActionButton 
            isDanger
            startIcon={<DeniedIcon/>} 
            disabled={row.requestedById === currentUser.id || !hasApproveAgencyPermission}
            onClick={() => hasApproveAgencyPermission && denyClicked(row)}
          >
            Deny
          </LotusSecondaryActionButton>
          <LotusSecondaryActionButton 
            isSuccess
            startIcon={<ApprovedIcon/>} 
            disabled={row.requestedById === currentUser.id || !hasApproveAgencyPermission}
            onClick={() => hasApproveAgencyPermission && approveClicked(row)}
          >
            Approve
          </LotusSecondaryActionButton>
        </LotusButtonList>
      );
    }
  };

  useEffect(() => {
    // Merge data into a common structure, sort by request date
    const all = [];
    if (requests) {
      all.push(...requests);
    }
    if (recentApprovals) {
      recentApprovals.forEach(ra => {
        all.push({
          ...ra,
          approvedDeniedBy: ra.approverEmail || ra.denierEmail,
          approveDenyDate: ra.approvalDate || ra.deniedDate
        });
      });
    }
    all.sort((a,b) => moment(a.requestDate,'MM/DD/YYYY') < moment(b.requestDate,'MM/DD/YYYY') ? 1 : -1);
    setAllApprovals(all);
  }, [recentApprovals, requests]);

  const columns = [
    {
      headerName: 'Approve/Deny',
      field: 'approveDeny',
      display: 'flex',
      renderCell: getApprovalCellView,
      width: 260
    },
    {
      headerName: 'Request',
      field: 'actionRequestTypeName',
      width: 80
    },
    {
      headerName: 'Agency Name',
      field: 'agencyName',
      width: 150
    },
    {
      headerName: 'Agency Status',
      field: 'agencyStatusName',
      width: 150
    },
    {
      headerName: 'Requested By',
      field: 'requestedByEmail',
      width: 200
    },
    {
      field: 'requestDate',
      headerName: 'Request Date',
      width: 120
    },
    {
      field: 'approvedDeniedBy',
      headerName: 'Approved/Denied By',
      width: 200
    },
    {
      field: 'approveDenyDate',
      headerName: 'Approve/Deny Date',
      width: 180
    },
  ];

  return allApprovals && (
    <>
      <LotusDataGrid
        columns={columns}
        rows={allApprovals}
        disableColumnFilter
      />
      {requestToProcess && (
        <AgencyDenialDialog
          open={showDenyRequest}
          handleClose={() => setShowDenyRequest(false)}
          actionRequest={requestToProcess}
          handleSubmit={(ar, reason) => {
            setShowDenyRequest(false);
            handleDenyRequest(ar, reason);
          }}
        />
      )}
    </>
  );
}
