import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './UserRequestsContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';
import moment from 'moment';

const UserRequestsContext = createContext();

const initialState = {
  pendingRequests: undefined,
  recentApprovals: undefined,
};

export const UserRequestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserRequestsContext.Provider value={{ state, dispatch }}>
      {children}
    </UserRequestsContext.Provider>
  );
};

export const useUserRequests = () => {
  const { state, dispatch } = useContext(UserRequestsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const createRequestToCreateUser = async (request) => {
    try {
      addBusyBee('createRequestToCreateUser');

      const toPost = {
        operationName: 'createRequestToCreateUser',
        request
      };

      const url = `/api/users`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.REQUEST_CREATION_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createRequestToCreateUser');
    }
  };

  const createRequestToUpdateUser = async (request) => {
    try {
      addBusyBee('createRequestToUpdateUser');

      const toPost = {
        operationName: 'createRequestToUpdateUser',
        request
      };

      const url = `/api/users`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.REQUEST_CREATION_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createRequestToUpdateUser');
    }
  };

  const approveRequestToCreateUser = async (requestId, email) => {
    try {
      addBusyBee('approveRequestToCreateUser');
      const url = `/api/users`;
      const { data } = await axios.post(url,{operationName:'approveRequestToCreateUser', requestId: requestId, email: email});
      const { approval } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.APPROVING_USER_REQUEST_SUCCESS,
        payload: {
          requestId,
          approval: approval
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('approveRequestToCreateUser');
    }
  };

  const approveRequestToUpdateUser = async (requestId) => {
    try {
      addBusyBee('approveRequestToUpdateUser');
      
      const url = `/api/users`;
      const { data } = await axios.post(url,{operationName:'approveRequestToUpdateUser', requestId: requestId});
      const { approval } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.APPROVING_USER_REQUEST_SUCCESS,
        payload: {
          requestId,
          approval: approval
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
      return false;
    } finally {
      removeBusyBee('approveRequestToUpdateUser');
    }
    return true;
  };

  const denyRequestToCreateUser = async (requestId, denyReason) => {
    try {
      addBusyBee('denyRequestToCreateUser');

      const url = `/api/users`;
      const { data } = await axios.post(url, {
        operationName: 'denyRequestToCreateUser',
        requestId: requestId, 
        reason: denyReason
      });
      const { denial } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.DENYING_USER_REQUEST_SUCCESS,
        payload: {
          denial: denial
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('denyRequestToCreateUser');
    }
  };

  const denyRequestToUpdateUser = async (requestId, denyReason) => {
    try {
      addBusyBee('denyRequestToUpdateUser');

      const url = `/api/users`;
      const { data } = await axios.post(url, {
        operationName: 'denyRequestToUpdateUser',
        requestId: requestId, 
        reason: denyReason
      });
      const { denial } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.DENYING_USER_REQUEST_SUCCESS,
        payload: {
          denial: denial
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('denyRequestToUpdateUser');
    }
  };

  const loadPendingUserRequests = async () => {
    try {
      addBusyBee('loadPendingUserRequests');

      const url = `/api/users`;
      const { data } = await axios.post(url, { operationName: 'getPendingUserRequestsForUser'});
      let requests = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PENDING_REQUESTS_SUCCESS,
        payload: {
          pendingRequests: requests,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPendingUserRequests');
    }
  };

  const loadRecentUserApprovals = async () => {
    try {
      addBusyBee('loadRecentUserApprovals');

      const filterDate = moment().add(-30, 'days');
      const url = `/api/users`;
      const { data } = await axios.post(url, { operationName: 'getRecentUserApprovalsForUser', earliestDate: `${filterDate.format('MM/DD/YYYY')}` });
      let approvals = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_RECENT_APPROVALS_SUCCESS,
        payload: {
          recentApprovals: approvals,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadRecentUserApprovals');
    }
  };

  const loadAgencyPrograms = async (agencyId) => {
    try {
      addBusyBee('loadAgencyPrograms');

      let { data } = await axios.post(`/api/agency`, {operationName: 'getAllAgencyPrograms', agencyId});
      const programs = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS,
        payload: {
          agencyId,
          programs,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyPrograms');
    }
  };

  const loadAgencyPermissionTemplates = async (agencyId) => {

    try {
      addBusyBee('loadAgencyPermissionTemplates');

      const url = `/api/permissionTemplates`;
      const result = await axios.post(url, { operationName: 'getPermissionTemplates', agencyId: agencyId });
      const permissionTemplateResult = parseApiResult(result.data).body;         

      dispatch({
        type: messageTypes.LOADING_AGENCY_PERMISSION_TEMPLATES_SUCCESS,
        payload: {
          agencyId,
          templates: permissionTemplateResult.permissionTemplates,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyPermissionTemplates');
    }
  };

  const createRequestToReactivateUser = async (request) => {
    try {
      addBusyBee('createRequestToReactivateUser');

      const toPost = {
        operationName: 'createRequestToReactivateUser',
        request
      };

      const url = `/api/users`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.REQUEST_CREATION_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createRequestToReactivateUser');
    }
  };
  

  if (state) {
    return {
      ...state,
      createRequestToCreateUser,
      createRequestToUpdateUser,
      approveRequestToCreateUser,
      approveRequestToUpdateUser,
      denyRequestToCreateUser,
      denyRequestToUpdateUser,
      loadPendingUserRequests,
      loadRecentUserApprovals,
      loadAgencyPrograms,
      loadAgencyPermissionTemplates,
      createRequestToReactivateUser
    };
  }

  return {};
};
