import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import { useApplicationSearch } from 'contexts/EligibilityApplicationSearchContext';
import { Table } from '@lotus/components';
import Chip from '@mui/material/Chip';
import { ELIGIBILITY_APPLICATION_STATUS } from 'lib/eligibilityEnums';
import { useLists } from 'contexts/ListsContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import EligibilityApplicationViewerButton from 'components/widgets/Composites/EligibilityApplicationViewerButton';
import ApplicationCustomSearch from './ApplicationCustomSearch';
import { useAuthState } from 'contexts/AuthProvider';

export default function ApplicationTable({ tableName, permissions, loggedInUser }) {
  const { tablePreferences, updateTablePreferences } = useAuthState();
  const { userAgencyEligibilityConfig, userAgencyAdapSubprograms, loadUserAgencyAdapSubprograms } = useUserAgency();
  const { eligibilityApplicationStatuses, loadEligibilityApplicationStatuses } = useLists();

  const [rows, setRows] = useState();
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [subprogramOptionList, setSubprogramOptionList] = useState();
  const [agencyOptionList, setAgencyOptionList] = useState();
  const [selectedFilters, setSelectedFilters] = useState();
  const [agencyGroups, setAgencyGroups] = useState();

  const [currentTableData, setCurrentTableData] = useState();

  const { applications, totalApplicationPages, loadApplications, loadApplicationsSync } = useApplicationSearch();

  const defaultSortOrder = { name: "urgency_name", direction: 'asc' };
  // Agency sub program grouping configuration
  const adapApplicationType = userAgencyEligibilityConfig?.application?.selectedADAPApplicationType === 'group_by_subprogram' || false;
  const careApplicationType = userAgencyEligibilityConfig?.application?.selectedCareApplicationType === 'group_by_agency' || false;
  // checking the subprogram filter is enabled
  const subprogramFilterOn = (permissions.adapProcessor || permissions.rwProcessorMultiAgency || permissions.rwProcessorSingleAgency) && 'assignedToMeTable' !== tableName && adapApplicationType;
  // checking the subagencies filter is enabled
  const subagencyFilterOn = !permissions.adapProcessor && permissions.rwProcessorMultiAgency && 'assignedToMeTable' !== tableName && careApplicationType;

  useEffect(() => {
    if (!eligibilityApplicationStatuses) {
      loadEligibilityApplicationStatuses();
    }
  }, []);
  
  useEffect(() => {
    if (subprogramFilterOn) {
      if(!userAgencyAdapSubprograms) {
        loadUserAgencyAdapSubprograms();
      }
    }
    if(subagencyFilterOn) {
      const agencyGroups = userAgencyEligibilityConfig?.application.agencyGroups;
      setAgencyGroups(agencyGroups);
      let agencyItems = agencyGroups.map(c => {return {key: c.name, value: c.name}});
      agencyItems.unshift({key: 'All Agencies', value: 'all'});
      setAgencyOptionList(agencyItems);
    }
  }, []);
  
  useEffect(() => {
    if(userAgencyAdapSubprograms && userAgencyAdapSubprograms.length > 0) {
      let adapSubprograms = userAgencyAdapSubprograms.filter(s => s.active).map(c => {return {key: c.name, value: c.name}});
      adapSubprograms.unshift({key: 'All Programs', value: 'all'});
      adapSubprograms.push({key: 'Care Only', value: 'null'});
      setSubprogramOptionList(adapSubprograms);
    }
  }, [userAgencyAdapSubprograms])
  
  useEffect(() => {
    if (tableName && tablePreferences && tablePreferences[tableName]) {
      setDisplayedColumns(tablePreferences[tableName].columns);
    }
  }, [tableName, tablePreferences]);

  useEffect(() => {
    if (!currentTableData && tableName && userAgencyEligibilityConfig?.application && (!subagencyFilterOn || agencyGroups)) {
      let prefs = tablePreferences || {};
      let savedFilters = [];
      if (subprogramFilterOn) {
        if (tableName in prefs) {
          savedFilters.push({"name": "subprograms", "value": prefs[tableName]?.subprograms || null});
        } else {
          savedFilters.push({"name": "subprograms", "value": null});
        }
      }
      if (subagencyFilterOn) {
        if (tableName in prefs) {
          savedFilters.push({"name": "agencyName", "value": prefs[tableName]?.agencyName || null});
        } else {
          savedFilters.push({"name": "agencyName", "value": null});
        }
      }

      handleDataChange({
        pageNumber: 0,
        rowsPerPage: 10,
        tableName: tableName,
        sortOrder: defaultSortOrder,
        filters: savedFilters
      });

      setCurrentTableData({
        sortOrder: defaultSortOrder,
        filters: savedFilters
      });
      setSelectedFilters(savedFilters);
    }
  }, [tableName, tablePreferences, userAgencyEligibilityConfig, agencyGroups]);

  const handleDataChange = async ({
    tableName,
    pageNumber,
    rowsPerPage,
    searchText,
    searchTextColumns,
    sortOrder,
    filters
  }) => {
    if (tableName) {
      setSelectedFilters(filters);

      setCurrentTableData({
        searchText,
        searchTextColumns,
        sortOrder,
        filters
      });

      const newPageNumber = currentTableData?.searchText === searchText ? pageNumber : 0;
      await loadApplications({
        assignedToUserOnly: 'assignedToMeTable' === tableName,
        offset: newPageNumber * rowsPerPage,
        limit: rowsPerPage,
        searchText,
        sortOrder,
        filters
      });
    }
  };



  useEffect(() => {
    if (selectedFilters) {
      let prefs = tablePreferences;
      prefs[tableName].subprograms = selectedFilters.find(x => x.name === 'subprograms')?.value;
      prefs[tableName].agencyName = selectedFilters.find(x => x.name === 'agencyName')?.value;
      updatePreferences(prefs);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (applications) {
      setRows(applications);
    }
  }, [applications]);

  const handleApplicationDataDownload = async (
    buildHead,
    buildBody,
    columns,
    options
  ) => {
    const downloadData = await loadApplicationsSync({
      assignedToUserOnly: 'assignedToMeTable' === tableName,
      offset: 0,
      limit: -1,
      ...options,
      ...currentTableData,
    });

    const formattedData = downloadData.applications.map((data, index) => {
      const elements = columns.map((column) =>
        column.download ? data[column.name] : 'excluded'
      );
      let filteredElements = elements.filter((elem) => elem !== 'excluded');
      filteredElements = filteredElements.map((elem) => elem && typeof elem === 'object'  ? JSON.stringify(elem) : elem);
      return {
        index,
        data: filteredElements,
      };
    });

    const downloadText = `${buildHead(headerNames)}${buildBody(
      formattedData
    )}`.trim();
    const file = new Blob([downloadText], { type: 'text/csv' });

    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = 'applications-' + Date.now() + '.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // Map status keys to user readable names
  const getStatusName = (statusName) => {
    switch(statusName?.replace(/ /g, "_").toLowerCase()) {
      case ELIGIBILITY_APPLICATION_STATUS.STARTED:
        return 'In Progress';
      case ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED:
        return 'Submitted';
      default:
        return statusName;
    }
  };

  let columns = [
    {
      label: 'Application Id',
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      label: 'Client ID',
      name: 'clientId',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      label: 'Client Name',
      name: 'client_name',
      options: {
        display: displayedColumns.includes('client_name'),
        filter: false,
        sort: true,
        downloadHeader: 'Client Name',
        includeOnSearch: displayedColumns.includes('client_name')
      },
    },
    {
      label: 'Date Submitted',
      name: 'original_submission_date',
      options: {
        filter: false,
        sort: true,
        display: displayedColumns.includes('original_submission_date'),
        downloadHeader: 'Original Submission Date',
        customBodyRender: (value) => {
          return <div>{value ? <div>{value}<div style={{fontSize: 14, color: 'grey'}}>{Math.abs((calculateExpirationDays(value)))} days</div></div> : '--'}</div>;
        },
      },
    },
    {
      label: 'Expiration',
      name: 'expirationDate',
      options: {
        filter: false,
        sort: true,
        display: displayedColumns.includes('expirationDate'),
        downloadHeader: 'Previous Eligibility Expiration Date',
        customBodyRender: (value) => {
          return <div>{value ? <div>{value}<div style={{fontSize: 14, color: 'grey'}}>{calculateExpirationDays(value)} days</div></div> : '--'}</div>;
        },
      },
    },
    {
      label: 'Type', 
      name: 'isInitialApplication',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('isInitialApplication'),
        downloadHeader: 'Type',
        customBodyRender: (value, tableMeta) => {
          const interimUpdateIdx = columns.findIndex(c => c.name === 'isInterimUpdate');
          const recertIdx = columns.findIndex(c => c.name === 'isRecertificationApplication');
          const adapOnlyIdx = columns.findIndex(c => c.name === 'isAdapOnlyApplication');
          let lbl = '?';
          if (tableMeta.rowData[adapOnlyIdx]) {
            lbl = 'Recertification'; // not at all confusing
          } else if (tableMeta.rowData[interimUpdateIdx]) {
            lbl = 'Update';
          } else if (tableMeta.rowData[recertIdx]) {
            lbl = 'Recertification';
          } else if (value) {
            lbl = 'New';
          }
          return <Chip label={lbl} size="medium" />;
        },
      },
    },
    {
      label: 'Status', 
      name: 'statusName',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('statusName'),
        downloadHeader: 'Status',
        customBodyRender: (value) => {
          return <Chip label={getStatusName(value)} size="medium" />;
        },
      },
    },
    {
      label: 'Urgent Flag',
      name: 'urgency_name',
      options: {
        filter: false,
        sort: true,
        display: displayedColumns.includes('urgency_name'),
        downloadHeader: 'Urgency',
        customBodyRender: (value) => {
          return value && <Chip label={value} size="medium" color="warning" />;
        },
      },
    },
    {
       name: 'processorId',
       options: {
         display: 'excluded',
         filter: false
       }
    },
    {
      name: 'agencyName',
      options: {
        display: 'excluded',
        filter: false
      }
   },
    {
      label: 'Processor',
      name: 'processorName',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('processorName'),
        downloadHeader: 'Processor',
        customBodyRender: (value) => {
          return value && <div>{value}</div>;
        },
      },
    },
    {
      label: 'Application',
      name: 'statusKey',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('statusKey'),
        downloadHeader: 'Application Status',
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          return (<EligibilityApplicationViewerButton 
                    appId={tableMeta?.rowData[0]}
                    appStatusKey={value}
                    appClientId={tableMeta?.rowData[1]}
                    appProcessorId={tableName !== "assignedToMeTable" ? tableMeta?.rowData[9] : tableMeta?.rowData[8]}
                    userId={loggedInUser.id}
                   />);
        },
      },
    },
    {
      name: 'isInterimUpdate',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'isRecertificationApplication',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'isAdapOnlyApplication',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      label: 'Sub-Programs',
      name: 'subprograms',
      options: {
        filter: false,
        sort: true,
        display: displayedColumns.includes('subprograms'),
        downloadHeader: 'subprograms'
      },
    },
    {
      name: 'caseManagerAgencyName',
      options: {
        display: 'excluded',
        filter: false
      }
   },
  ];

  if (tableName !== "assignedToMeTable") {
    columns = [
      ...columns.slice(0, 8),
      {
        label: 'Submitter',
        name: 'caseManagerName',
        options: {
          filter: false,
          sort: false,
          display: displayedColumns.includes('caseManagerName'),
          downloadHeader: 'Submitter',
          customBodyRender: (value, tableMeta) => {
            return value && tableMeta && <div>
              {tableMeta.rowData[17]}
              <div style={{fontSize: 15, color: 'grey'}}>Case Manager: {value}</div>
              </div>;
          },
        },
      },
      ...columns.slice(8)
    ];
  }

  const headerNames = columns
    .map((column) => {
      return {
        name: column.options.downloadHeader,
        download: column.options.display,
      };
    })
    .filter((item) => item.name !== undefined);

  const updatePreferences = debounce((prefs) => {
    updateTablePreferences(prefs);
  }, 2000);

  const handleViewColumnsChanged = (changedColumn, action) => {
    let newColumns = displayedColumns;
    if (action === 'add') {
      newColumns.push(changedColumn);
    } else {
      let idx = newColumns.indexOf(changedColumn);
      if (idx > -1) {
        newColumns.splice(idx, 1);
      }
    }
    setDisplayedColumns(newColumns);
    let prefs = tablePreferences;
    if (prefs) {
      prefs[tableName].columns = newColumns;
      updatePreferences(prefs);
    }
  };

  const calculateExpirationDays = (expireDate) => {
    if (!expireDate) return '--';
    let today = moment().startOf('day');
    return Math.round((moment(expireDate) - today) / 86400000);
  }
  
  return (
    <>
    <Table
      tableName={tableName}
      enabled={Boolean(rows)}
      options={{
        serverSide: true, 
        customSearch: undefined, 
        filter: false
      }}
      columns={columns}
      data={rows}
      totalRows={totalApplicationPages}
      defaultSortOrder={defaultSortOrder}
      headerNames={headerNames}
      handleDataChange={handleDataChange}
      handleDownload={handleApplicationDataDownload}
      handleViewColumnsChanged={handleViewColumnsChanged}
      customSearchFilterComponent={ApplicationCustomSearch}
      customSearchFilterComponentProps={{subprogramFilterOn, subprogramOptionList, subagencyFilterOn, agencyOptionList, currentFilterValues: selectedFilters}}
    />
    </>
  );
}
