import React from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import EnrollmentList from './EnrollmentList';
import EnrollmentEditor from './EnrollmentEditor';

export default function EnrollmentPanel() {

  const {lvl2: view} = useParams();

  return (
    <Grid container>
      <Grid item xs={12}>
        {view === 'list' && <EnrollmentList/>}
        {view === 'edit' && <EnrollmentEditor/>}
      </Grid>
    </Grid>
  );
}
