import React from 'react';

export default function LotusSubsectionContent({ 
  excludeTopPadding = false,
  indentLevel = 0,
  children 
}) {

  return (
    <div style={{paddingTop: excludeTopPadding ? 0 : 24, paddingBottom: 24, paddingLeft: indentLevel * 16}}>
      {children}
    </div>
  );
}
