import Diagnoses from 'components/Diagnoses';
import { useEffect, useState } from 'react';
import { useClient } from 'contexts/ClientContext';

export const ProgramDiagnosesTab = ({ clientId, programId, disabled }) => {

  const [programDiagnosisRecords, setProgramDiagnosisRecords] = useState();

  const {clientProgramDiagnosisRecords, loadClientProgramDiagnosisRecords, upsertClientProgramDiagnosisRecords} = useClient();
  
  useEffect(() => {
    if (clientId && !clientProgramDiagnosisRecords) {
      loadClientProgramDiagnosisRecords(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (clientProgramDiagnosisRecords) {
      const recs = clientProgramDiagnosisRecords.find((r) => r.programId === programId && r.clientId === clientId)?.data;
      setProgramDiagnosisRecords(recs);
    }
  }, [programId, clientProgramDiagnosisRecords]);
  
  const onDiagnosisAdded = async (diagnosis) => {
    await upsertClientProgramDiagnosisRecords(clientId, programId, programDiagnosisRecords ? [...programDiagnosisRecords, diagnosis] : [diagnosis]);
  };

  const onDiagnosisEdited = async (diagnosis, idx) => {
    const toUpdate = programDiagnosisRecords ? [...programDiagnosisRecords] : [];
    toUpdate[idx] = diagnosis;
    await upsertClientProgramDiagnosisRecords(clientId, programId, toUpdate);
  };

  return (
    <Diagnoses
      diagnoses={programDiagnosisRecords || []}
      onDiagnosisAdded={onDiagnosisAdded}
      onDiagnosisEdited={onDiagnosisEdited}
      disabled={disabled}
    />
  );
};
