import { HashRouter as Router } from 'react-router-dom';
import React from 'react';
import PageRouter from './pageRouter';
import { AuthProvider } from './contexts/AuthProvider';
import { UserAgencyProvider } from './contexts/UserAgencyContext';
import ErrorBoundary from './ErrorBoundry';
import { ProgramProvider } from 'contexts/ProgramContext';
import { ListsProvider } from 'contexts/ListsContext';
import InactivityMonitor from "./InactivityMonitor";
import { UserManagementProvider } from 'contexts/UserManagementContext';

const AppRouter = () => {

  return (
    <Router>
      <ErrorBoundary>
        <AuthProvider>
          <InactivityMonitor />
          <UserAgencyProvider>
            <UserManagementProvider>
              <ProgramProvider>
                <ListsProvider>
                  <PageRouter />
                </ListsProvider>
              </ProgramProvider>
            </UserManagementProvider>
          </UserAgencyProvider>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
