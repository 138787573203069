import React from 'react';
import ProgramPropertiesForm from './forms/ProgramProperties';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function AddProgramPanel({ afterCreateProgram }) {
  const { createNewProgram } = useProgramManagement();

  const handleCreateProgram = async (values) => {
    const newProgramId = await createNewProgram(values);
    afterCreateProgram && afterCreateProgram(newProgramId);
  };

  return (
    <LotusPageSection header='Create Program'>
      <ProgramPropertiesForm handleSubmit={handleCreateProgram} />
    </LotusPageSection>
  );
}
