import React, {useState, useEffect} from 'react';
import LotusTabPanel from 'components/widgets/Layout/LotusTabPanel';
import { useNavigate } from 'react-router-dom';
import { linkifyTabName } from 'lib/formatting';

// Each tab in tabs is a {label, link}
export default function LotusMainSubTabs({ selectedTabName, tabs, tabPropertyToMatch }) {

  const [tabIndex, setTabIndex] = useState();
  const navigate = useNavigate();

  const handleTabChange = async (event, tIdx) => {
    navigate(tabs[tIdx].link);
    setTabIndex(tIdx);
  };

  useEffect(() => {
    if (selectedTabName && tabs) {
      const idx = tabs.findIndex(t => (tabPropertyToMatch ? t[tabPropertyToMatch]?.toLowerCase() : linkifyTabName(t.label).toLowerCase()) === selectedTabName.toLowerCase());
      if (idx >= 0) {
        setTabIndex(idx);
      } else {
        navigate(tabs[0].link);
      }
    }
  }, [selectedTabName, tabs]);

  return tabs && tabs.length > 0 && tabIndex !== undefined && (
    <LotusTabPanel
      dontCapitalize
      useIndicatorIcon
      value={tabIndex}
      handleTabChanged={handleTabChange}
      style={{borderBottom: '2px solid #C0C0C0', marginBottom: 0}}
      tabs={tabs}
    />
  );
}