import React from 'react';
import Typography from '@mui/material/Typography';

export default function PasswordResetConfirmPanel() {
  return (
    <>
      <Typography variant="h6">Password Reset</Typography>
      <br />
      <p>
        <Typography variant="body">
          This user's password has been reset. The user will receive an email with a link to log in. The user will be required to set a new password during their next login attempt.
        </Typography>
      </p>
    </>
  );
}
