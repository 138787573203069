const { useUserAgency } = require("contexts/UserAgencyContext");
const { useFormDefinitions } = require("contexts/DynamicFormContext");
const { useState, useEffect } = require("react");

export function useAssessmentSubforms(formTypeKey, assessmentTypeId) {
  const { userAgency } = useUserAgency();
  const {formDefinitions, loadFormDefinitionsForFormType, buildFormDefinitionKey} = useFormDefinitions();
  const [subformDefinitions, setSubformDefinitions] = useState();
  const [currAssessmentTypeId, setCurrAssessmentTypeId] = useState();

  useEffect(() => {
    if (userAgency && currAssessmentTypeId && formTypeKey) {
      const key = buildFormDefinitionKey(formTypeKey, null, currAssessmentTypeId);
      if (!formDefinitions || key in formDefinitions === false) {
        loadFormDefinitionsForFormType({programId: null, assessmentTypeId: currAssessmentTypeId, formTypeKey: formTypeKey});
      }
    }
  }, [userAgency, currAssessmentTypeId, formTypeKey]);

  useEffect(() => {
    if (userAgency && currAssessmentTypeId && formTypeKey) {
      const key = buildFormDefinitionKey(formTypeKey, null, currAssessmentTypeId);
      if (formDefinitions && key in formDefinitions) {
        const formDefs = formDefinitions[key];
        formDefs.sort((a, b) => {return (a.formSubtypeCustomSequence || 0) - (b.formSubtypeCustomSequence || 0)});
        setSubformDefinitions(formDefs);
      }
    }
  }, [userAgency, currAssessmentTypeId, formDefinitions, formTypeKey]);

  if (currAssessmentTypeId !== assessmentTypeId) {
    setSubformDefinitions(undefined);
    setCurrAssessmentTypeId(assessmentTypeId);
  }

  return subformDefinitions;
}
