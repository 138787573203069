import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const LotusReadOnlyTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInput-input.Mui-disabled': {
    color: '#000000',
    WebkitTextFillColor: '#000000',
    paddingBottom: 0,
    paddingTop: 0
  },
  '& .MuiInput-root.Mui-disabled:before': {
    border: '0px',
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: theme.palette.text.secondary
  },
}));

export default function LotusReadOnlyFieldView({label, value, multiline, rows, style, startAdornment, endAdornment}) {

  return (
    <LotusReadOnlyTextField
      fullWidth
      variant="standard"
      label={label}
      disabled={true}
      value={value !== null && value !== undefined && value !== '' ? `${value}${(endAdornment && typeof endAdornment === 'string') ? ` ${endAdornment}` : ''}` : ''}
      multiline={multiline}
      rows={multiline && rows ? rows : 1}
      style={{maxWidth: '100%', ...style}}
      InputLabelProps={{ 
        shrink: true, // stupid react
      }} 
      InputProps={{
        startAdornment: value && startAdornment ? <InputAdornment disableTypography position="start">{startAdornment}</InputAdornment> : undefined,
        endAdornment: value && endAdornment && typeof endAdornment !== 'string' ? <InputAdornment disableTypography position="end">{endAdornment}</InputAdornment> : undefined  
      }}
    />
  );
}
