import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './AgencyRequestsContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';
import moment from 'moment';

const AgencyRequestsContext = createContext();

const initialState = {
  pendingRequests: undefined,
  recentApprovals: undefined,
};

export const AgencyRequestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgencyRequestsContext.Provider value={{ state, dispatch }}>
      {children}
    </AgencyRequestsContext.Provider>
  );
};

export const useAgencyRequests = () => {
  const { state, dispatch } = useContext(AgencyRequestsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const createAgencyRequest = async (request) => {
    try {
      addBusyBee('createAgencyRequest');

      const toPost = {
        operationName: 'createAgencyRequest',
        request
      };

      const url = `/api/agencies`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.CREATING_AGENCY_REQUEST_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createAgencyRequest');
    }
  };

  const approveAgencyRequest = async (requestId) => {
    try {
      addBusyBee('approveAgencyRequest');
      
      const url = `/api/agencies`;
      const { data } = await axios.post(url,{operationName:'approveAgencyRequest', requestId: requestId});
      const { approval } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.APPROVING_AGENCY_REQUEST_SUCCESS,
        payload: {
          requestId,
          approval: approval
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('approveAgencyRequest');
    }
  };

  const denyAgencyRequest = async (requestId, denyReason) => {
    try {
      addBusyBee('denyAgencyRequest');

      const url = `/api/agencies`;
      const { data } = await axios.post(url, {
        operationName: 'denyAgencyRequest',
        requestId: requestId, 
        reason: denyReason
      });
      const { denial } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.DENYING_AGENCY_REQUEST_SUCCESS,
        payload: {
          denial: denial
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('denyAgencyRequest');
    }
  };

  const loadPendingAgencyRequests = async () => {
    try {
      addBusyBee('loadPendingAgencyRequests');

      const url = `/api/agencies`;
      const { data } = await axios.post(url, { operationName: 'getPendingAgencyRequestsForUser'});
      let requests = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PENDING_REQUESTS_SUCCESS,
        payload: {
          pendingRequests: requests,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPendingAgencyRequests');
    }
  };

  const loadRecentAgencyApprovals = async () => {
    try {
      addBusyBee('loadRecentAgencyApprovals');

      const filterDate = moment().add(-30, 'days');
      const url = `/api/agencies`;
      const { data } = await axios.post(url, { operationName: 'getRecentAgencyApprovalsForUser', earliestDate: `${filterDate.format('MM/DD/YYYY')}` });
      let approvals = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_RECENT_APPROVALS_SUCCESS,
        payload: {
          recentApprovals: approvals,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadRecentAgencyApprovals');
    }
  };



  if (state) {
    return {
      ...state,
      createAgencyRequest,
      approveAgencyRequest,
      denyAgencyRequest,
      loadPendingAgencyRequests,
      loadRecentAgencyApprovals
    };
  }

  return {};
};
