import React from 'react';
import axios from '../../../../axiosInterceptor';
import * as yup from 'yup';
import { debounce } from 'lodash';
import { Formik, Form, yupToFormErrors } from 'formik';
import AgencyAccessPanel from '../AgencyAccessPanel';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import { useUserRequests } from 'contexts/UserRequestsContext';
import { parseApiResult } from 'lib/utils';
import { PERMISSIONS } from 'lib/permissionEnums';

const addValidationSchema = yup.object({
  firstName: yup
    .string('Enter your first name')
    .required('First Name is required')
    .min(1, 'At least one character must be entered')
    .max(25, 'Maximum of 25 characters')
    .matches(/^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/, {
      message: 'Must be a valid name',
      excludeEmptyString: true,
    }),
  lastName: yup
    .string('Enter your last name')
    .required('Last Name is required')
    .min(2, 'At least two character must be entered')
    .max(25, 'Maximum of 25 characters')
    .matches(/^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/, {
      message: 'Must be a valid name',
      excludeEmptyString: true,
    }),
  email: yup
    .string('Enter your email')
    .email('Invalid Email Address')
    .required('Email is required'),
  permissionTemplateIds: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one permission template must be selected'),
});

const editValidationSchema = yup.object({
  permissionTemplateIds: yup
    .array()
    .of(yup.string())
    .min(1, 'At least one permission template must be selected'),
});

export default function UserForm({
  afterSave,
  userDetail, 
  userPermissions,
  importedUser
}) {
  const { createRequestToCreateUser, createRequestToUpdateUser } = useUserRequests();

  const initialValues = userDetail ? 
    {
      firstName: userDetail.firstName,
      lastName: userDetail.lastName,
      email: userDetail.email,
      agencyId: userDetail.agencyId,
      permissionTemplateIds: userDetail.permissionTemplates.map(p => p.id),
      programPermissions: userPermissions.permissionList
        .filter((pp) => pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA ||  pp.permissionKey === PERMISSIONS.EDIT_CLIENT_PROGRAM_DATA)
        .map((pp) => {
          return { 
            programId: pp.programId, 
            access: pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA ? 'read' : 'edit'
          }
        }),
    } : 
    {
      firstName: importedUser?.firstName || '',
      lastName: importedUser?.lastName ||'',
      middleName: importedUser?.middleName ||'',
      email: importedUser?.email || '',
      agencyId: importedUser?.agencyId ||'',
      importedUserAccountId: importedUser?.id,
      secondaryPhone: importedUser?.secondaryPhone,
      primaryPhone: importedUser?.primaryPhone,
      permissionTemplateIds: [],
      programPermissions: [],
    };

  const handleInviteUser = async (user) => {
    if (userDetail) {
      await createRequestToUpdateUser({
        userId: userDetail.id,
        permissionTemplateIds: user.permissionTemplateIds,
        programPermissions: user.programPermissions,
      });
    } else {
      await createRequestToCreateUser({
        agencyId: user.agencyId,
        firstName: user.firstName,
        lastName: user.lastName,
        middleName: user.middleName,
        email: user.email,
        accountId: user.importedUserAccountId,
        primaryPhone: user.primaryPhone,
        secondaryPhone: user.secondaryPhone,
        permissionTemplateIds: user.permissionTemplateIds,
        programPermissions: user.programPermissions,
      });
    }

    if (afterSave) {
      await afterSave(user);
    }
  };

  const emailChecker = debounce(async (value) => {
    if (value) {
      try {
        const { data } = await axios.post('/api/users', {operationName: 'checkEmail', email: value});
        return parseApiResult(data).body.exists === false;
      } catch (error) {
        console.log({ error });
        return false;
      }
    } else {
      return true;
    }
  }, 500);

  return (
        <Formik
          initialValues={initialValues}
          validate={async (values) => {
            let result = {};
            const validationSchema = userDetail
              ? editValidationSchema
              : addValidationSchema;
            try {
              validationSchema.validateSync(values, { abortEarly: false });
            } catch (err) {
              result = yupToFormErrors(err);
            }
            if (!userDetail && !result.email  ) {
              if (!importedUser || (importedUser && importedUser?.email !== values.email)) {
                const ok = await emailChecker(values.email);
                if (ok === false) {
                  // this seems to be undefined the first time
                  result.email = 'User with given email address already exists';
                }
              }
            }
            return result;
          }}
          onSubmit={async (values, actions) => {
            await handleInviteUser(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  {!userDetail && (
                    <LotusFormItem>
                      <LotusTextInput
                        name="firstName"
                        label="First Name"
                        disabled={Boolean(userDetail)}
                        required
                      />
                    </LotusFormItem>
                  )}
                  {!userDetail && (
                    <LotusFormItem>
                      <LotusTextInput
                        name="lastName"
                        label="Last Name"
                        disabled={Boolean(userDetail)}
                        required
                      />
                    </LotusFormItem>
                  )}
                  {!userDetail && (
                    <LotusFormItem>
                      <LotusTextInput
                        name="email"
                        label="Email"
                        disabled={Boolean(userDetail)}
                        required
                      />
                    </LotusFormItem>
                  )}
                  <AgencyAccessPanel
                    disableEditAgency={Boolean(userDetail)}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                  <LotusFormItem>
                    <LotusButton type="submit">Submit Request</LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
  );
}
