import * as React from 'react';
import { useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useProperties } from 'contexts/PropertiesContext';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { IconButton, ListItemIcon, Tooltip } from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useAuthState } from 'contexts/AuthProvider';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  header: `${PREFIX}-header`,
  link: `${PREFIX}-link`,
  userInformation: `${PREFIX}-userInformation`,
  help: `${PREFIX}-help`,
  helpButton: `${PREFIX}-helpButton`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  [`&.${classes.header}`]: {
    padding: '10px 30px',
    background: theme.palette.primary.dark,
    borderRadius: 0,
  },

  [`& .${classes.link}`]: {
    paddingTop: 30,
  },

  [`& .${classes.userInformation}`]: {
    float: 'right',
    color: '#fff',
    paddingLeft: 10,
    borderLeft: '1px solid lightgray',
  },
  [`& .${classes.help}`]: {
    float: 'right',
    color: '#fff',
    paddingRight: 20
  },
  [`& .${classes.helpButton}`]: {
    float: 'right',
    color: theme.palette.grey[500],
  },
}));

function Header({ user }) {
  const navigate = useNavigate();
  const { loadProperties, properties } = useProperties();
  const { signOut } = useAuthState();

  useEffect(() => {
    if (!properties) {
      loadProperties();
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [helpEl, setHelpEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const helpOpen = Boolean(helpEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHelpClick = (event) => {
    setHelpEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHelpClose = () => {
    setHelpEl(null);
  };

  const handleProfile = async () => {
    await navigate('/myaccount');
  };
  
  const handleTraining = async () => {
    await navigate('/training');
  };

  const handleLogout = async () => {
    await signOut();
  };
  
  const handleSupport = async () => {
    window.open('https://ncreedssupport.provideconnect.com/support/tickets/new', '_blank');
  };

  const initials =
    user && user.email ? `${user.email.charAt(0).toUpperCase()}` : '';

  return (
    <StyledPaper elevation={4} className={classes.header}>
      <Grid container>
        <Grid item xs={3}>
          <Link to="/">
            <Typography variant="h4" style={{ color: '#fff', float: 'left' }}>
              Provide Enterprise
            </Typography>
          </Link>
          <span style={{ color: '#fff', fontSize: '8px', paddingLeft: '10px' }}>
            {properties
              ? `v ${properties.PACKAGE_VERSION}.${properties.PACKAGE_MINOR}.${properties.PACKAGE_BUILD}`
              : ''}
          </span>
          <Typography variant="body1" style={{ color: '#fff' }}>
            by Groupware Technologies
          </Typography>
        </Grid>
        <Grid style={{ paddingRight: 10 }} item xs={9}>
          {!user && (
            <div
              className={classes.root}
              style={{ float: 'right', paddingLeft: 10, paddingTop: 5 }}
            >
            </div>
          )}
          {user && (
            <>
            <Grid item xs={3} className={classes.userInformation}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 42, height: 42 }}>{initials}</Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleProfile}>
                  <ManageAccountsIcon sx={{ color: '#546B82' }} />
                  <Typography
                    variant="h6"
                    style={{ fontWeight: '500', paddingLeft: '10px' }}
                    color="primary.light"
                  >
                    My Account
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon sx={{ color: '#546B82' }} />
                    <Typography
                      variant="h6"
                      style={{ fontWeight: '500', paddingLeft: '10px' }}
                      color="primary.light"
                    >
                      Logout
                    </Typography>
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={3} className={classes.help}>
              <Tooltip title="Help">
                <IconButton
                  className={classes.helpButton}
                  onClick={handleHelpClick}                  
                  sx={{ ml: 2 }}
                  aria-controls={helpOpen ? 'help-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={helpOpen ? 'true' : undefined}
                >
                  <HelpOutlineIcon fontSize='large' />
                </IconButton>
              </Tooltip>
              <Menu
                id="help-menu"
                anchorEl={helpEl}
                open={helpOpen}
                onClose={handleHelpClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleTraining}>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: '500', paddingLeft: '10px' }}
                    color="primary.light"
                  >
                    Training
                  </Typography>
                </MenuItem>
                <MenuItem onClick={handleSupport}>
                  <ListItemIcon>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: '500', paddingLeft: '10px' }}
                      color="primary.light"
                    >
                      Contact Support
                    </Typography>
                  </ListItemIcon>
                </MenuItem>
              </Menu>
            </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

export default Header;
