import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useState } from 'react';
import LotusDataGrid from 'components/dataDisplay/datagrid/LotusDataGrid';


export default function AgencyList() {
  const { userAgency } = useUserAgency();
  const { granteeAgencies, loadGranteeAgencies, agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();

  const { verifyPermission } = useAuthState();
  const hasModifyAgencyPermission = verifyPermission(PERMISSIONS.MODIFY_AGENCY);
  const [agenciesAvailableToEdit, setAgenciesAvailableToEdit] = useState();

  useEffect(() => {
    if (userAgency.isRoot) {
      loadGranteeAgencies();
    } else {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, []);

  useEffect(() => {
    if (userAgency.isRoot && granteeAgencies) {
      setAgenciesAvailableToEdit(granteeAgencies);
    }
  }, [userAgency, granteeAgencies]);

  useEffect(() => {
    if (!userAgency.isRoot && agenciesForGrantee) {
      setAgenciesAvailableToEdit([...agenciesForGrantee]);
    }
  }, [userAgency, agenciesForGrantee]);

  return agenciesAvailableToEdit && (
    <AgencyListView
      agencies={agenciesAvailableToEdit}
      hasModifyAgencyPermission={hasModifyAgencyPermission}
    />
  );
}

export function AgencyListView({
  agencies,
  hasModifyAgencyPermission
}) {

  const columns = [
    {
      headerName: 'Name',
      field: 'name',
      renderCell: (params) => {
        const id = params.row.id;
        return (
          hasModifyAgencyPermission ?
            <Link to={`/agencies/edit/${id}/details`}>{params.value}</Link> :
            params.value
        );
      },
      flex: 1,
    },
    {
      headerName: 'State',
      field: 'state',
      valueFormatter: (value) => {
        return value || '-';
      },
      flex: 1
    },
  ];

  return (
    agencies && (
      <LotusDataGrid
        columns={columns}
        rows={agencies}
        disableColumnFilter
      />
    )
  );
}
