import { useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  gridClasses,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Stack, styled } from '@mui/material';

const quickFilterStyles = {
  padding: '2px 2px',
};
const gridToolbarStackStyles = {
  margin: 'auto 0 auto auto',
};
const dataGridStyles = {
  boxShadow: 4,
  borderRadius: 2,
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.cell}`]: {
    py: 2,
  },
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .lotus-grid-row-styles--active': {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  '& .MuiDataGrid-toolbarContainer > .MuiStack-root': {
    alignItems: 'center'
  },
  '& .MuiDataGrid-row > .MuiDataGrid-cell': {
    whiteSpace: "normal !important",
    wordWrap: "anywhere !important"
  }
}));

export default function LotusDataGrid({
  columns,
  rows,
  initialSort = [],
  handleRowClicked,
  handleViewColumnsChanged = () => {},
  handleFilterModelChange, // handle to server side filter change
  noResultsText = 'Sorry, no matching records found.',
  noRowsText = 'No rows.',
  totalRows = -1,
  paginationMode = 'client',
  sortingMode = 'client',
  pageSizeOptions = [10, 20, 100],
  paginationModel = { page: 0, pageSize: 10 },
  disableColumnFilter = false,
  disableColumnSorting = false,
  disableToolbar = false,
  hideFooter = false,
  disablePrintButton = false,
  disableExportButton = false,
  disableViewColumns = false,
  disableQuickSearch = false,
  disabled = false,
  handlePaginationModelChange, // handle to server side pagination
  handleRowStyles,
  extraStyles,
  getRowIdFunc
}) {
  const [disablePrintBtn, setDisablePrintBtn] = useState();
  const [disableExportBtn, setDisableExportBtn] = useState();
  const [disableViewCol, setDisableViewCol] = useState();
  const [disableQkSearch, setDisableQkSearch] = useState();

  useEffect(() => {
    setDisablePrintBtn((disablePrintButton) => disablePrintButton);
  }, [disablePrintButton]);

  useEffect(() => {
    setDisableExportBtn((disableExportButton) => disableExportButton);
  }, [disableExportButton]);

  useEffect(() => {
    setDisableViewCol((disableViewColumns) => disableViewColumns);
  }, [disableViewColumns]);

  useEffect(() => {
    setDisableQkSearch((disableQuickSearch) => disableQuickSearch);
  }, [disableQuickSearch]);

  const CustomToolbar = ({ buttonRef }) => {
    return (
      <div style={{ margin: 15 }}>
        <GridToolbarContainer>
          {!disableQkSearch && <GridToolbarQuickFilter sx={quickFilterStyles} />}
          <Stack sx={gridToolbarStackStyles} alignItems="flex-end" direction="row">
            {!disableViewCol && <GridToolbarColumnsButton ref={buttonRef} slotProps={{button: {style: {minWidth: 16}}}} />}
            <GridToolbarFilterButton ref={buttonRef}  slotProps={{button: {style: {minWidth: 16}}}}/>
            {!disableExportBtn && (
              <GridToolbarExport printOptions={{ disableToolbarButton: disablePrintBtn }} ref={buttonRef} slotProps={{button: {style: {minWidth: 16}}}}/>
            )}
          </Stack>
        </GridToolbarContainer>
      </div>
    );
  };

  const getTogglableColumns = (columns) => {
    return columns.filter((col) => !col.hide).map((col) => col.field);
  };

  const [pageModel, setPageModel] = useState(paginationModel);

  const handlePaginationChange = (model) => {
    // comes with next paginationModel object {"page":1,"pageSize":10}
    setPageModel(model);
    if (typeof handlePaginationModelChange === 'function') {
      handlePaginationModelChange(model);
    }
  };

  const handleFilterChange = (filterModel) => {
    // comes with next filterModel object with filter values {"items":[{"field":"sortable_date","operator":"contains","id":50198,"value":"12","fromInput":":r72:"}],"logicOperator":"and","quickFilterValues":[],"quickFilterLogicOperator":"and"}
    if (typeof handleFilterModelChange === 'function') {
      handleFilterModelChange(filterModel);
    }
  };

  const buttonRef = useRef(null);

  const minHeight = 300;

  // Update fluid width and min width for all visible columns
  columns.forEach((element) => {
    element.display = element.display ? element.display : 'flex';
  });

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight }}>
      <StyledDataGrid
        getRowHeight={() => 'auto'}
        disableColumnMenu
        disableVirtualization
        disableRowSelectionOnClick={disabled}
        hideFooterSelectedRowCount
        rowCount={paginationMode === 'server' ? totalRows : undefined}
        pagination={true}
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        paginationModel={pageModel || null}
        onPaginationModelChange={(paginationMode === 'server' && handlePaginationChange) || setPageModel}
        pageSizeOptions={pageSizeOptions}
        rows={rows}
        columns={columns}
        onRowClick={(row) => !disabled && handleRowClicked && handleRowClicked(row)}
        sortingOrder={['desc', 'asc']}
        disableColumnSorting={disableColumnSorting}
        disableColumnFilter={disableColumnFilter}
        hideFooter={hideFooter}
        initialState={{
          sorting: {
            sortModel: initialSort,
          },
          pagination: { paginationModel: pageModel || null, rowCount: -1 },
        }}
        slots={{
          toolbar: !disableToolbar && CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            buttonRef,
            csvOptions: { allColumns: true },
          },
          columnsManagement: {
            getTogglableColumns,
          },
          basePopper: {
            anchorEl: () => buttonRef.current,
            placement: 'bottom-end',
          },
        }}
        onColumnVisibilityModelChange={(newModel) => handleViewColumnsChanged(newModel)}
        sx={{...dataGridStyles, ...(extraStyles || {})}}
        onFilterModelChange={(newFilterModel) => paginationMode === 'server' && handleFilterChange(newFilterModel)}
        localeText={{ noResultsOverlayLabel: noResultsText, noRowsLabel: noRowsText, toolbarExport: '', toolbarColumns: '', toolbarFilters: '' }}
        getRowId={getRowIdFunc || undefined}
        getRowClassName={(params) => handleRowStyles && handleRowStyles(params)}
      />
    </div>
  );
}
