const DOC_TYPES = {
  TXT: 'text/plain',
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF: 'application/pdf',
  JPG: 'image/jpeg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  TIF: 'image/tif',
  TIFF: 'image/tiff',  
};

export const { TXT, CSV, XLS, XLSX, DOC, DOCX, PDF, JPG, JPEG, PNG, TIF, TIFF } = DOC_TYPES;
export const IMAGE_TYPES = [
  DOC_TYPES.JPG,
  DOC_TYPES.JPEG,
  DOC_TYPES.PNG,
];
export const NO_PREVIEW_TYPES = [
  DOC_TYPES.TXT,
  DOC_TYPES.CSV,
  DOC_TYPES.XLS,
  DOC_TYPES.XLSX,
  DOC_TYPES.DOC,
  DOC_TYPES.DOCX,
  DOC_TYPES.TIF,
  DOC_TYPES.TIFF,
];
