import moment from 'moment';

export const formatPermissionApprovalDate = (date) => {
  return moment(date).format('MMM YYYY');
};

export const formatDate = (date) => {
  return date.format('MM/DD/YYYY');
};

export const formatUsDate = (dateString) => {
  const date = moment(dateString);
  return date.format('MM/DD/YYYY');
};

export const formatUsDateLongFormat = (dateString) => {
  const date = moment(dateString);
  return date.format('MMMM DD, YYYY');
};

export const formatUsDateTime = (dateString) => {
  const date = moment(dateString);
  return date.format('MM/DD/YYYY hh:mm a');
};

export const formatIso8601 = (dateString) => {
  const date = moment(dateString);
  return date.format('YYYY-MM-DDTHH:mm:ss+00:00');
};

export const formatPermissions = (privileges) => {
  return privileges
    .map((privilege) => privilege.node.permissionTemplate.name)
    .join(', ');
};

export const formatActionForAccountActionRequest = (action, actionRequest) => {
  return `${action} ${
    actionRequest.userEmail
  } for request ${formatAccountActionRequest(actionRequest)}`;
};

export const formatAccountActionRequest = (actionRequest) => {
  let result = actionRequest.actionRequestTypeName;
  if (
    actionRequest.requestedPermissionTemplates &&
    actionRequest.requestedPermissionTemplates.length > 0
  ) {
    result += `(${actionRequest.requestedPermissionTemplates
      .map((template) => template.name)
      .join(',')})`;
  }
  return (result += ` at ${actionRequest.agencyName}`);
};

export const buildTemplateRoleAccessString = (role) => {
  let accessStrings = [];

  if (role.mayApprove) {
    accessStrings.push('Approval');
  }

  if (role.mayCreate) {
    accessStrings.push('Create');
  }

  if (role.mayDelete) {
    accessStrings.push('Delete');
  }

  if (role.mayEdit) {
    accessStrings.push('Edit');
  }

  return accessStrings.join(', ');
};

export const linkifyTabName = (tabName) => {
  return tabName?.toLowerCase().replaceAll(' ','');
}