import React from 'react';
import Paper from '@mui/material/Paper';

export default function LotusCard({ onClick, borderStyleOverrides, sxStyles, ...props }) {

  return (
    <Paper 
        elevation={4} 
        sx={sxStyles}
        style={{
          borderRadius: 11, 
          padding: '25px 20px 25px 45px', 
          cursor: onClick ? 'pointer' : 'default', 
          ...borderStyleOverrides}} 
        onClick={onClick}
        className={onClick ? 'lotusDisabledCard' : ''}
    >
      {props.children}
    </Paper>
  );
}
