import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useProgram } from 'contexts/ProgramContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { cloneDeep } from 'lodash';
const { v4: uuidv4 } = require('uuid');

export const validationSchema = Yup.object().shape({});

export const buildSectionNames = () => {return [];}

const EnrollmentGeneralStaticForm = ({disabled}) => {

  const {
    currentProgram,
    currentProgramEnrollmentStatuses,
    currentProgramEnrollmentCloseReasons, 
    currentProgramUsers, loadProgramUsers,
  } = useProgram();

  const { handleChange, values, setFieldValue } = useFormikContext();
  const [statusesForSelection, setStatusesForSelection] = useState();
  const [showCloseReasonPicklist, setShowCloseReasonPicklist] = useState();
  const [showOtherCloseReason, setShowOtherCloseReason] = useState();
  const [showAssignedTo, setShowAssignedTo] = useState();
  const [showStatusEffectiveDate, setShowStatusEffectiveDate] = useState();
  const [originalStatusId, setOriginalStatusId] = useState();
  const [originalLastUpdateDttm, setOriginalLastUpdateDttm] = useState();
  const [allowStatusBackdating, setAllowStatusBackdating] = useState();

  useEffect(() => {
    if (currentProgram) {
      if (!currentProgramUsers) {
        loadProgramUsers();
      }
    }
  }, [currentProgram]);

  const updateViewForSelectedStatus = (statusId) => {
    let currentStatus = currentProgramEnrollmentStatuses.find(s => s.id === statusId);
    const trStatuses = currentProgramEnrollmentStatuses.filter(
      s => currentStatus.allowedTransitionStatusIds?.includes(s.id) || (s.id === currentStatus.id));
    trStatuses.sort((a,b) => a.seqNumber - b.seqNumber)
    setStatusesForSelection(trStatuses);

    setShowCloseReasonPicklist(
      !currentStatus.isActiveStatusType && currentStatus.requireCloseReason && currentStatus.closeReasonUsesPicklist);
    setShowOtherCloseReason(
      !currentStatus.isActiveStatusType && currentStatus.requireCloseReason && !currentStatus.closeReasonUsesPicklist);
    setShowAssignedTo(!currentProgram?.enrollmentConfig.assignmentOnlyWhenActive || currentStatus.isActiveStatusType);
  }

  const onStatusChange = (newStatusId) => {
    updateViewForSelectedStatus(newStatusId);
    setShowStatusEffectiveDate(newStatusId !== originalStatusId);
    if (originalStatusId && newStatusId !== originalStatusId && !values.newStatusEffectiveDate) {
      setFieldValue('newStatusEffectiveDate', moment().format('MM/DD/YYYY'));
    }
  }

  useEffect(() => {
    if (values.lastUpdateDttm) {
      if (values.lastUpdateDttm !== originalLastUpdateDttm) {
        setOriginalStatusId(values.programEnrollmentStatusId);
        setOriginalLastUpdateDttm(values.lastUpdateDttm);
        setShowStatusEffectiveDate(false);
      }
    } else {
      setOriginalStatusId(values.programEnrollmentStatusId);
      setFieldValue('newStatusEffectiveDate', moment().format('MM/DD/YYYY'));
    }
    if (currentProgramEnrollmentStatuses) {
      updateViewForSelectedStatus(values.programEnrollmentStatusId);
    }
  }, [values.lastUpdateDttm, currentProgramEnrollmentStatuses]);

  useEffect(() => {
    if (currentProgram) {
      setAllowStatusBackdating(currentProgram?.enrollmentConfig.allowStatusBackdating); 
    }
  }, [currentProgram]);

  return values && (
    <LotusForm>
      <LotusFormItems>
        {statusesForSelection && (
          <LotusSelect
            name="programEnrollmentStatusId"
            label="Status"
            required
            disabled={disabled}
            handleChange={onStatusChange}
            autoSort={false}
            items={statusesForSelection.map((s) => {return { label: s.name, value: s.id };})}
          />
        )}
        {values.lastStatusDate && !showStatusEffectiveDate && (
          <LotusTextInput
            name="lastStatusDate"
            label="Status Effective Date"
            disabled={true}
          />
        )}
        {(showStatusEffectiveDate || !values.id) && (
          <LotusDatePicker
            name="newStatusEffectiveDate"
            label="New Status Effective Date"
            disabled={disabled || !allowStatusBackdating}
            required
          />
        )}
        {values.initialActiveStatusDate && (
          <LotusTextInput
            name="initialActiveStatusDate"
            label="Active Date"
            disabled={true}
          />
        )}
      </LotusFormItems>
      {showAssignedTo && currentProgramUsers && values.assignees && values.assignees.map((a, idx) =>
        <LotusFormItem key={a.id || idx}>
          <LotusSelect
            name={`assignees.${idx}.assigneeUserId`}
            label={values.assignees[idx].programEnrollmentAssigneeName}
            items={currentProgramUsers.map((s) => {return { label: s.name, value: s.id };})}
            disabled={disabled}
          />
        </LotusFormItem>
      )}
      <LotusFormItems>
      {showCloseReasonPicklist && currentProgramEnrollmentCloseReasons && (
        <LotusSelect
          name="closeReasonId"
          label="Close Reason"
          items={currentProgramEnrollmentCloseReasons.map((s) => {return { label: s.name, value: s.id };})}
          disabled={disabled}
        />
      )}
      {showOtherCloseReason && (
        <LotusTextInput
          name="otherCloseReasonText"
          label="Close Reason"
          onChange={handleChange}
          disabled={disabled}
        />
      )}
      </LotusFormItems>
    </LotusForm>
  );
};

const buildStaticForm = ({...props}) => {
  return (
      <EnrollmentGeneralStaticForm {...props}/>
  );
}

export default function EnrollmentGeneralForm ({titleOverride, client, programId, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues}) {

  const [initialValues, setInitialValues] = useState();
  const {upsertCustomProgramEnrollment, customProgramEnrollments} = useEnrollments();

  const { currentProgramEnrollmentStatuses, currentProgramEnrollmentAssignees } = useProgram();

  useEffect(() => {
    if (client && customProgramEnrollments && programId && currentProgramEnrollmentStatuses && currentProgramEnrollmentStatuses.length > 0 && currentProgramEnrollmentAssignees) {
      const programEnrollment = customProgramEnrollments.find(e => e.programId === programId);
      
      if (programEnrollment) {
        const enrollmentCopy = cloneDeep(programEnrollment);
        // Make sure current status is still a valid option
        let currentStatus = currentProgramEnrollmentStatuses.find(s => s.id === enrollmentCopy.programEnrollmentStatusId);
        if (!currentStatus) {
          currentStatus = currentProgramEnrollmentStatuses.find(s => s.isDefaultInitialStatus) || currentProgramEnrollmentStatuses[0];
        }
        enrollmentCopy.programEnrollmentStatusId = currentStatus.id;

        // Sort and add empty assignment of any newly added program assignees
        const sortedAssignees = currentProgramEnrollmentAssignees.map(pea => { 
          const assignment = enrollmentCopy.assignees.find(a => a.programEnrollmentAssigneeId === pea.id)
          return {
            id: assignment?.id || uuidv4(),
            programEnrollmentAssigneeId: pea.id,
            programEnrollmentAssigneeName: pea.name,
            seqNumber: pea.seqNumber,
            assigneeUserId: assignment?.assigneeUserId || '',
            effectiveDate: assignment?.effectiveDate || ''
          }
        });
        sortedAssignees.sort((a,b) => (a.seqNumber > b.seqNumber ? 1 : -1));
        enrollmentCopy.assignees = sortedAssignees;
        enrollmentCopy.newStatusEffectiveDate = '';
        setInitialValues(enrollmentCopy);
      } else {
        let currentStatus = currentProgramEnrollmentStatuses.find(s => s.isDefaultInitialStatus) || currentProgramEnrollmentStatuses[0];
        let newEnrollment = {
          clientId: client.id,
          programId: programId,
          programEnrollmentStatusId: currentStatus.id,
          assignees: currentProgramEnrollmentAssignees.map(pea => { return { 
            id: uuidv4(),
            programEnrollmentAssigneeId: pea.id,
            programEnrollmentAssigneeName: pea.name,
            seqNumber: pea.seqNumber,
            assigneeUserId: '',
            effectiveDate: ''
          }}).sort((a,b) => (a.seqNumber > b.seqNumber ? 1 : -1)),
          closeReasonId: null,
          otherCloseReasonText: '',
          newStatusEffectiveDate: moment().format('MM/DD/YYYY')
        }
        setInitialValues(newEnrollment);
      }
    }
  }, [client, customProgramEnrollments, programId, currentProgramEnrollmentStatuses, currentProgramEnrollmentAssignees]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      id, programEnrollmentStatusId, assignees, closeReasonId, otherCloseReasonText, newStatusEffectiveDate,
      statusName, programName, closeReasonName, lastStatusDate, programId, programTypeKey, clientId,
      ...dynamicFormData} = formData;
    
    const enrollment = {
      id, programEnrollmentStatusId, assignees, closeReasonId, otherCloseReasonText, newStatusEffectiveDate, programId, clientId
    };

    return [enrollment, dynamicFormData];
  }

  const saveForm = async (values) => {
    await upsertCustomProgramEnrollment(values);
  }

  return initialValues && (
    <HybridForm
      title={titleOverride || "General"}
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled})}
      staticFormSectionNameBuilder={() => buildSectionNames()}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
      programId={programId}
    />
  );
}