import React, { useEffect, useState } from 'react';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function DocumentTypeLinker({ agencyId, values, handleChange }) {
  const { userAgencyDocumentTypes, loadUserAgencyDocumentTypes } = useUserAgency();
  const [documentTypesForAgency, setDocumentTypesForAgency] = useState();

  useEffect(() => {
    if (!userAgencyDocumentTypes) {
      loadUserAgencyDocumentTypes(agencyId);
    }
  }, [userAgencyDocumentTypes]);

  useEffect(() => {
    if (userAgencyDocumentTypes) {
      const types = [
        ...userAgencyDocumentTypes.filter((doc) => doc.is_active),
      ];
      types.sort((a, b) => (a.name > b.name ? 1 : -1));
      setDocumentTypesForAgency(types);
    }
  }, [userAgencyDocumentTypes]);

  return documentTypesForAgency && (
    <LotusCheckboxList
      name="selectedDocumentTypes"
      items={documentTypesForAgency.map(pt => {return {label: pt.name, value: pt.id}})}
    />
  );
}
