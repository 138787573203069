import React, { useEffect, useState } from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import EnrollmentStatusLinker from '../linkers/EnrollmentStatusLinker';
import EnrollmentAssigneeLinker from '../linkers/EnrollmentAssigneeLinker';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

const validationSchema = yup.object({
  enrollmentStatuses: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required'),
    })),
  enrollmentAssignees: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Name is required').test("Unique", "Name needs te be unique", (value, ctx) => {
        return ctx.from[1].value.enrollmentAssignees.filter(ea => ea.name === value).length === 1
      }),
    }))
});

export default function ProgramEnrollmentForm({ program, handleSubmit }) {

  const [initialValues, setInitialValues] = useState();

  const { 
    currentProgram, 
    enrollmentCloseReasons,
    enrollmentStatuses,
    enrollmentAssignees,
    loadProgramEnrollmentPicklists,
  } = useProgramManagement();

  useEffect(() => {
    if (currentProgram && !enrollmentCloseReasons) {
      loadProgramEnrollmentPicklists();
    }
  }, [currentProgram]);

  const newStatusTemplate = {
    name: '',
    isActiveStatusType: true,
    isDefaultInitialStatus: false,
    allowedTransitionStatusIds: [],
    allowDocumentationUpdates: false,
    requireCloseReason: false,
    closeReasonUsesPicklist: false
  };

  const newAssigneeTemplate = {
    name: ''
  };

  useEffect(() => {
    if (currentProgram && enrollmentCloseReasons && enrollmentStatuses && enrollmentAssignees) {
      const newInitialValues = {
        enrollmentStatuses: enrollmentStatuses.map(s => {return {...newStatusTemplate, ...s}}),
        addedEnrollmentStatus: {...newStatusTemplate},
        enrollmentAssignees: enrollmentAssignees.map(s => {return {...newAssigneeTemplate, ...s}}),
        addedEnrollmentAssignee: {...newAssigneeTemplate},
        currentEnrollmentCloseReasons: enrollmentCloseReasons.map(t => {return {name: t.name, active: true}}) || [],
        addedEnrollmentCloseReason: '',
        isEvergreen: currentProgram.enrollmentConfig.isEvergreen || false,
        assignmentOnlyWhenActive: currentProgram.enrollmentConfig.assignmentOnlyWhenActive || false,
        allowStatusBackdating: currentProgram.enrollmentConfig.allowStatusBackdating || false,
        enrollmentStatusErrors: ''
      };
      setInitialValues(newInitialValues);
    }
  }, [currentProgram, enrollmentCloseReasons, enrollmentStatuses, enrollmentAssignees]);

  return (
    <LotusPageSection header="Enrollment">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validate={async (values) => {
          let result = {};
          try {
            validationSchema.validateSync(values, { abortEarly: false });
          } catch (err) {
            result = yupToFormErrors(err);
          }
          const initialStatuses = values.enrollmentStatuses.filter(es => es.isDefaultInitialStatus);
          if (initialStatuses.length !== 1) {
            result.enrollmentStatusErrors = 'One and only one status should be set as Default Initial Status';
          }
          const activeStatuses = values.enrollmentStatuses.filter(es => es.isActiveStatusType);
          if (activeStatuses.length < 1) {
            result.enrollmentStatusErrors = 'There must be at least 1 status set as Active Status Type';
          }
          const inactiveStatuses = values.enrollmentStatuses.filter(es => !es.isActiveStatusType);
          if (inactiveStatuses.length < 1) {
            result.enrollmentStatusErrors = 'There must be at least 1 status not set as Active Status Type';
          }
          return result;
        }}
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue, touched, errors, setFieldTouched }) => {
          const localHandleEnrollmentStatusChange = (e) => {
            handleChange(e);
            setFieldTouched('enrollmentStatusErrors', true);
          };
          return values && (
            <Form onSubmit={e => {setFieldTouched('enrollmentStatusErrors', true); handleSubmit(e);}}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="isEvergreen"
                    label="Is Evergreen"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="assignmentOnlyWhenActive"
                    label="Assignment Only When Active"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="allowStatusBackdating"
                    label="Allow Status Backdating"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <EnrollmentStatusLinker
                    values={values}
                    touched={touched}
                    errors={errors}
                    newStatusTemplate={newStatusTemplate}
                    handleChange={localHandleEnrollmentStatusChange}
                    setFieldValue={setFieldValue}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <GenericListLinker
                    name="currentEnrollmentCloseReasons"
                    title="Enrollment Close Reasons for Program"
                    itemName="Close Reason"
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <EnrollmentAssigneeLinker
                    values={values}
                    touched={touched}
                    errors={errors}
                    newAssigneeTemplate={newAssigneeTemplate}
                    handleChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
