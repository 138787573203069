

import { Stack, Typography } from "@mui/material";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusRemoveButton from "components/button/common/LotusRemoveButton";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSwitch from "components/form/switch/LotusSwitch";
import { useField, useFormikContext } from "formik";
import { useEffect } from "react";


export default function CustomListLinker({ listFieldName, newItemFieldName, fieldDescription, listHeader, allListItems }) {
  const [listField, listFieldMeta, listFieldUtils] = useField(listFieldName);
  const [newItemField, newItemFieldMeta, newItemFieldUtils] = useField(newItemFieldName);
  const {setFieldError} = useFormikContext();

  useEffect(() => {
    if (allListItems) {
      const localValues = allListItems.filter(d => !d.isDeleted).map(d => {
        return {
          id: d.id,
          name: d.name,
          isActive: d.isActive
        }
      });
      listFieldUtils.setValue(localValues);
    }
  }, [allListItems]);

  const addListItem = () => {
    if (newItemField.value) {
      const found = listField.value.find(itm => itm.name.toLowerCase() === newItemField.value.toLowerCase() );
      if (found) {
        setFieldError(newItemField.name, `${fieldDescription} already exists.`);
      }
      else {
        const newName = newItemField.value;
        const newLocalList = [...listField.value, {name: newName, isActive: true}]
        listFieldUtils.setValue(newLocalList);
        newItemFieldUtils.setValue('');
      }
    }
  };

  const removeListItem = (itm) => {
    const newListItems = listField.value.filter((localItm) => itm.name !== localItm.name);
    listFieldUtils.setValue(newListItems);
  };

  return listField.value && (
    <LotusStackedFormItems>
      <div>
        <Typography variant='body1'>{listHeader}</Typography>
      </div>
      {listField.value.length > 0 && listField.value.map((itm, index) => {
        return (
          <Stack direction="row" spacing={2} alignItems="center" key={index} style={{width: 500}}>
            <LotusSwitch
              name={`${listFieldName}.${index}.isActive`}
              label={itm.name}
            />
            <LotusRemoveButton onClick={() => removeListItem(itm)} />
          </Stack>
        );
      })}
      <Stack direction="row" spacing={2} style={{width: 500}}>
        <LotusTextInput
          name={newItemFieldName}
          label={`New ${fieldDescription}`}
        />
        <LotusAddButton onClick={addListItem} />
      </Stack>
    </LotusStackedFormItems>
  );
}
