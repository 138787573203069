import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Icon } from '@mui/material';
import { TIMELINE_STEP, TIMELINE_COLOR } from 'lib/eligibilityEnums';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const iconStyles = {
  fontSize: 12
};
const tickStyles = (iconColor) => {
  return {
    fontSize: '1.5rem',
    color: iconColor
  }
};
const dotStyles = (iconColor) => {
  return {
    backgroundColor: iconColor
  }
}
const dotCheckStyles = {
  backgroundColor: TIMELINE_COLOR.WHITE,
  boxShadow: "none",
  marginTop: '10%',
  marginRight: '-10px'
}
const dotTextStyles = {
  color: TIMELINE_COLOR.WHITE,
  marginTop: -5,
  fontSize: 14
}
const labelStyles = {
  color: TIMELINE_COLOR.GREY
}
const headerStyles = () => {
  return {
    wordBreak: 'break-word',
    width: 95,
    color: TIMELINE_COLOR.GREY
  }
}

export default function EligibilityReviewStepTimeline({timelineConfig}) {
  return (
    <Timeline>
      {timelineConfig.map((reviewCfg, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineSeparator>
              {
                reviewCfg.activeIconStyle ?
                  <TimelineDot sx={dotCheckStyles}><CheckCircleIcon viewBox="2 2 21 21" sx={tickStyles(reviewCfg.iconColor)} /></TimelineDot> :
                  <TimelineDot sx={dotStyles(reviewCfg.iconColor)}>
                    <Icon sx={iconStyles}>
                      <Typography style={dotTextStyles}>{reviewCfg.key === TIMELINE_STEP.RW_REVIEW_STEP ? '2' : (reviewCfg.adapOptedOut ? '-' : '2')}</Typography>
                    </Icon>
                  </TimelineDot>
              }
            </TimelineSeparator>
            <TimelineContent>
              <Typography style={headerStyles()} variant="body2">
                {reviewCfg.header}
              </Typography>
              <Typography variant="body2" style={labelStyles}>
                {reviewCfg.label}
              </Typography>
              {reviewCfg.dateVisible && <Typography variant="body2" style={{ color: 'grey' }}>{reviewCfg.date}</Typography>}
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  );
}