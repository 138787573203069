import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import IncomeSourcesPanel from 'components/widgets/Composites/IncomeSourcesPanel';
import IncomeAdjustmentsPanel from 'components/widgets/Composites/IncomeAdjustmentsPanel';
import {calculateIncomeFields} from 'lib/incomeCalculator';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import HouseholdMembersPanel from 'components/widgets/Composites/HouseholdMembersPanel';
import { useClient } from 'contexts/ClientContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import Alert from '@mui/material/Alert';
import { useUserAgency } from 'contexts/UserAgencyContext';
import moment from 'moment';

const validationSchema = Yup.object().shape({});

const buildSectionNames = (existingClientConfig) => {
  let lst = [
    "Client's Personal Income",
    "Client's Household",
  ];
  if (existingClientConfig.useMagi) {
    lst.push("Income Adjustments");
  }
  lst.push(
    "Employment Status",
    "Taxes",
    "Income Totals",
    "Documentation"
  );
  return lst;
}

const ClientIncomeStaticForm = ({clientId, existingClientConfig, disabled, updateRestrictions}) => {

  const { employmentStatuses, taxFilingStatuses, clientRelationships, incomePeriods,
    federalPovertyGuidelines, loadFederalPovertyGuidelines} = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [employmentStatusList, setEmploymentStatusList] = useState();
  const [taxFilingStatusList, setTaxFilingStatusList] = useState();
  const [clientRelationshipList, setClientRelationshipList] = useState();

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (employmentStatuses) {
      setEmploymentStatusList(employmentStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (taxFilingStatuses) {
      setTaxFilingStatusList(taxFilingStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (clientRelationships && existingClientConfig && existingClientConfig.selectedClientRelationships) {
      let rels = clientRelationships.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}});
      const relationshipList = rels.filter(
        t => existingClientConfig.selectedClientRelationships.includes(t.value));
      setClientRelationshipList(relationshipList);
    }
  }, [employmentStatuses, taxFilingStatuses, clientRelationships, existingClientConfig]);

  useEffect(() => {
    if (!federalPovertyGuidelines) {
      loadFederalPovertyGuidelines();
    }
  }, [values]);

  useEffect(() => {
    if (values && userAgencyClientInfoCustomLists && incomePeriods) {
      let computedValues = calculateIncomeFields(values, userAgencyClientInfoCustomLists.clientInfoIncomeSourceTypes, incomePeriods);

      if (computedValues.totalHouseholdSize !== values.totalHouseholdSize) {
        setFieldValue("totalHouseholdSize", computedValues.totalHouseholdSize);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.monthlyHouseholdGrossIncome !== values.monthlyHouseholdGrossIncome) {
        setFieldValue("monthlyHouseholdGrossIncome", computedValues.monthlyHouseholdGrossIncome);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.householdFederalPovertyLevelPct !== values.householdFederalPovertyLevelPct) {
        setFieldValue("householdFederalPovertyLevelPct", computedValues.householdFederalPovertyLevelPct);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.totalIncomeAdjustments !== values.totalIncomeAdjustments) {
        setFieldValue("totalIncomeAdjustments", computedValues.totalIncomeAdjustments);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.monthlyHouseholdAdjustedGrossIncome !== values.monthlyHouseholdAdjustedGrossIncome) {
        setFieldValue("monthlyHouseholdAdjustedGrossIncome", computedValues.monthlyHouseholdAdjustedGrossIncome);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.monthlyHouseholdModifiedAdjustedGrossIncome !== values.monthlyHouseholdModifiedAdjustedGrossIncome) {
        setFieldValue("monthlyHouseholdModifiedAdjustedGrossIncome", computedValues.monthlyHouseholdModifiedAdjustedGrossIncome);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
      if (computedValues.magiHouseholdFederalPovertyLevelPct !== values.magiHouseholdFederalPovertyLevelPct) {
        setFieldValue("magiHouseholdFederalPovertyLevelPct", computedValues.magiHouseholdFederalPovertyLevelPct);
        setFieldValue("effectiveDate", moment().format('MM/DD/YYYY'));
      }
    }
  }, [values, userAgencyClientInfoCustomLists, incomePeriods, values.federalPovertyLineBaseAmount]);

  useEffect(() => {
    if (federalPovertyGuidelines && !values.federalPovertyLineBaseAmount) {
      // get most recent
      let mostRecent = federalPovertyGuidelines.reduce((prev, curr) => prev.year < curr.year ? curr : prev);
      if (values) {
        setFieldValue("federalPovertyLineBaseAmount", mostRecent.baseAmount);
        setFieldValue("federalPovertyLineIncrementalAmount", mostRecent.incrementalAmount);
      }
    }
  }, [federalPovertyGuidelines, values]);

  useEffect(() => {
    if (federalPovertyGuidelines && values.effectiveDate) {
      let toUse = federalPovertyGuidelines.find(g => g.year === moment(values.effectiveDate,'MM/DD/YYYY').year());
      if (!toUse) {
        toUse = federalPovertyGuidelines.reduce((prev, curr) => prev.year < curr.year ? curr : prev);
      }

      if (!values.federalPovertyLineBaseAmount || Number(toUse.baseAmount).toFixed(2) !== Number(values.federalPovertyLineBaseAmount).toFixed(2)) {
        setFieldValue("federalPovertyLineBaseAmount", toUse.baseAmount);
      }
      if (!values.federalPovertyLineIncrementalAmount || Number(toUse.incrementalAmount).toFixed(2) !== Number(values.federalPovertyLineIncrementalAmount).toFixed(2)) {
        setFieldValue("federalPovertyLineIncrementalAmount", toUse.incrementalAmount);
      }
    }
  }, [federalPovertyGuidelines, values.effectiveDate]);

  useEffect(() => {
    if(!values.clientHasIncome) {
      setFieldValue('clientIncomeSources', []);
    }
    if(!values.hasOtherHouseholdMembers) {
      setFieldValue('otherHouseholdMembers', []);
      setFieldValue('otherHouseholdMembersHaveIncome', '');
      setFieldValue('otherHouseholdIncomeSources', []);
    }
    if(!values.otherHouseholdMembersHaveIncome) {
      setFieldValue('otherHouseholdIncomeSources', []);
    }
    if (!values.hasIncomeAdjustments) {
      setFieldValue('incomeAdjustments', []);
    }
    if(!values.clientFiledTaxes) {
      setFieldValue('taxFilingStatusId', '');
    }
  }, [values.clientHasIncome, values.hasOtherHouseholdMembers, values.otherHouseholdMembersHaveIncome, values.hasIncomeAdjustments, values.clientFiledTaxes]);

  return values && existingClientConfig && (
    <LotusForm>
      {updateRestrictions && (updateRestrictions.all || updateRestrictions.income) &&
      <LotusFormSection>
        <LotusFormItem>
          <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
        </LotusFormItem>
      </LotusFormSection>
      }
      <LotusFormSection name="Client's Personal Income" disabled={disabled}>
        <LotusFormItems>
          <LotusProgressiveDisclosure
            name='clientHasIncome'
            label="Does the client have personal income?"
            disabled={disabled}
            required
          >
            <LotusProgressiveDisclosureItem
              itemValue='true'
              label='Yes'
            >
              <IncomeSourcesPanel
                incomeSourcesFieldName='clientIncomeSources'
                clientIsRecipient={true}
                clientRecipientLabel="Client"
                disabled={disabled}
                required
              />
            </LotusProgressiveDisclosureItem>
            <LotusProgressiveDisclosureItem
              itemValue='false'
              label='No'
            />
          </LotusProgressiveDisclosure>
        </LotusFormItems>
      </LotusFormSection>
      <LotusFormSection name="Client's Household" disabled={disabled}>
        <LotusFormItems>
          <LotusProgressiveDisclosure
            name='hasOtherHouseholdMembers'
            label="Does the client have other household members?"
            disabled={disabled}
            required
          >
            <LotusProgressiveDisclosureItem
              itemValue='true'
              label='Yes'
            >
              {clientRelationshipList &&
                <LotusFormItem>
                  <HouseholdMembersPanel
                    otherHouseholdMembersFieldName='otherHouseholdMembers'
                    relationships={clientRelationshipList}
                    disabled={disabled}
                    required
                  />
                </LotusFormItem>
              }
              <LotusProgressiveDisclosure
                name='otherHouseholdMembersHaveIncome'
                label="Do other members of the client's household have income?"
                disabled={disabled}
                required
              >
                <LotusProgressiveDisclosureItem
                  itemValue='true'
                  label='Yes'
                >
                  {clientRelationshipList &&
                    <IncomeSourcesPanel
                      incomeSourcesFieldName='otherHouseholdIncomeSources'
                      clientIsRecipient={false}
                      relationships={clientRelationshipList}
                      disabled={disabled}
                      required
                    />
                  }
                </LotusProgressiveDisclosureItem>
                <LotusProgressiveDisclosureItem
                  itemValue='false'
                  label='No'
                />
              </LotusProgressiveDisclosure>
            </LotusProgressiveDisclosureItem>
            <LotusProgressiveDisclosureItem
              itemValue='false'
              label='No'
            />
          </LotusProgressiveDisclosure>
        </LotusFormItems>
      </LotusFormSection>
      {existingClientConfig.useMagi &&
        <LotusFormSection name="Income Adjustments" disabled={disabled}>
          <LotusFormItems>
            <LotusProgressiveDisclosure
              name='hasIncomeAdjustments'
              label="Does the client or their household have income adjustments?"
              disabled={disabled}
              required
            >
              <LotusProgressiveDisclosureItem
                itemValue='true'
                label='Yes'
              >
                {clientRelationshipList &&
                  <IncomeAdjustmentsPanel
                    incomeAdjustmentsFieldName='incomeAdjustments'
                    relationships={[{value: 'client', label: 'Client'}, ...clientRelationshipList]}
                    disabled={disabled}
                    required
                  />
                }
              </LotusProgressiveDisclosureItem>
              <LotusProgressiveDisclosureItem
                itemValue='false'
                label='No'
              />
            </LotusProgressiveDisclosure>
          </LotusFormItems>
        </LotusFormSection>
      }
      <LotusFormSection name="Employment Status" disabled={disabled}>
        <LotusFormItems>
          {employmentStatusList &&
            <LotusSelect
              name="employmentStatusId"
              label="Employment Status"
              items={employmentStatusList}
              disabled={disabled}
              autoSort={false}
              required
            />
          }
        </LotusFormItems>
      </LotusFormSection>
      <LotusFormSection name="Taxes" disabled={disabled}>
        <LotusFormItems>
          <LotusProgressiveDisclosure
            name='clientFiledTaxes'
            label="Did the client file a tax return for the most recent tax year?"
            disabled={disabled}
            required
          >
            <LotusProgressiveDisclosureItem
              itemValue='true'
              label='Yes'
            >
              {taxFilingStatusList &&
                <LotusRadioGroup
                  name="taxFilingStatusId"
                  label="Tax Filing Status"
                  items={taxFilingStatusList}
                  disabled={disabled}
                  required
                />
              }
            </LotusProgressiveDisclosureItem>
            <LotusProgressiveDisclosureItem
              itemValue='false'
              label='No'
            />
          </LotusProgressiveDisclosure>
        </LotusFormItems>
      </LotusFormSection>
      <LotusFormSection name="Income Totals" disabled={disabled}>
        <LotusFormItem>
          <LotusTextInput
            name="totalHouseholdSize"
            label="Total Household Size"
            disabled={true}
          />
        </LotusFormItem>
        {!existingClientConfig.useMagi &&
          <LotusFormItem>
            <LotusTextInput
              name="monthlyHouseholdGrossIncome"
              label="Monthly Household Gross Income"
              disabled={true}
              adornment="$"
            />
          </LotusFormItem>
        }
        {!existingClientConfig.useMagi &&
          <LotusFormItem>
            <LotusTextInput
              name="householdFederalPovertyLevelPct"
              label="Household Poverty Level %"
              disabled={true}
              adornment="%"
            />
          </LotusFormItem>
        }
        {existingClientConfig.useMagi &&
          <LotusFormItem>
            <LotusTextInput
              name="monthlyHouseholdModifiedAdjustedGrossIncome"
              label="Monthly Household Modified Adjusted Gross Income (MAGI)"
              disabled={true}
              adornment="$"
            />
          </LotusFormItem>
        }
        {existingClientConfig.useMagi &&
          <LotusFormItem>
            <LotusTextInput
              name="magiHouseholdFederalPovertyLevelPct"
              label="MAGI Household Poverty Level %"
              disabled={true}
              adornment="%"
            />
          </LotusFormItem>
        }
      </LotusFormSection>
      <LotusFormSection name="Documentation" disabled={disabled}>
        <LotusFormItem>
          <ClientInfoDocumentation 
            documentationListFieldName="incomeDocumentation"
            allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoIncomeDocumentationTypes || []}
            clientId={clientId}
            disabled={disabled}
            required
            label="Proof of Income Documentation"
          />
        </LotusFormItem>
      </LotusFormSection>
    </LotusForm>
  );
};

export function buildStaticForm({...props}) {
  return <ClientIncomeStaticForm {...props}/>
}

export default function ClientIncomeForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions}) {

  const { clientIncome, loadClientIncome, upsertClientIncome } = useClient();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (client) {
      loadClientIncome(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (clientIncome) {
      setInitialValues( {
        clientHasIncome: clientIncome.clientHasIncome !== null ? clientIncome.clientHasIncome : '', 
        employmentStatusId: clientIncome.employmentStatusId || '', 
        clientIncomeSources: clientIncome.clientIncomeSources ? clientIncome.clientIncomeSources.map(ais => { return {
          id: ais.id,
          recipientRelationshipId: ais.recipientRelationshipId || '',
          incomeSourceTypeId: ais.incomeSourceTypeId || '',
          incomePeriodId: ais.incomePeriodId || '',
          samples: ais.samples ? ais.samples.map(aiss => { return {
            id: aiss.id,
            amount: aiss.amount || '',
            sampleDate: aiss.sampleDate || null
          }}) : []
        }}) : [],
        hasOtherHouseholdMembers: clientIncome.hasOtherHouseholdMembers !== null ? clientIncome.hasOtherHouseholdMembers : '',
        otherHouseholdMembers: clientIncome.otherHouseholdMembers ? clientIncome.otherHouseholdMembers.map(hm => { return {
          id: hm.id,
          clientRelationshipId: hm.clientRelationshipId || '',
          firstName: hm.firstName || '',
          lastName: hm.lastName || '',
        }}) : [],
        otherHouseholdMembersHaveIncome: clientIncome.otherHouseholdMembersHaveIncome !== null ? clientIncome.otherHouseholdMembersHaveIncome : '',
        otherHouseholdIncomeSources: clientIncome.otherHouseholdIncomeSources ? clientIncome.otherHouseholdIncomeSources.map(ohis => { return {
          id: ohis.id,
          recipientRelationshipId: ohis.recipientRelationshipId || '',
          incomeSourceTypeId: ohis.incomeSourceTypeId || '',
          incomePeriodId: ohis.incomePeriodId || '',
          samples: ohis.samples ? ohis.samples.map(ohis => { return {
            id: ohis.id,
            amount: ohis.amount || '',
            sampleDate: ohis.sampleDate || null
          }}) : []
        }}) : [],
        hasIncomeAdjustments: clientIncome.hasIncomeAdjustments !== null ? clientIncome.hasIncomeAdjustments : '', 
        incomeAdjustments: clientIncome.incomeAdjustments ? clientIncome.incomeAdjustments.map(ohis => { return {
          id: ohis.id,
          recipientRelationshipId: ohis.recipientRelationshipId || (ohis.clientIsRecipient ? 'client' : ''),
          incomeAdjustmentTypeId: ohis.incomeAdjustmentTypeId || '',
          incomePeriodId: ohis.incomePeriodId || '',
          samples: ohis.samples ? ohis.samples.map(ohis => { return {
            id: ohis.id,
            amount: ohis.amount || '',
            sampleDate: ohis.sampleDate || null
          }}) : []
        }}) : [],
        clientFiledTaxes: clientIncome.clientFiledTaxes !== null ? clientIncome.clientFiledTaxes : '', 
        taxFilingStatusId: clientIncome.taxFilingStatusId || '',
        federalPovertyLineBaseAmount: clientIncome.federalPovertyLineBaseAmount, // will populate these if empty
        federalPovertyLineIncrementalAmount: clientIncome.federalPovertyLineIncrementalAmount, // will populate these if empty
        totalHouseholdSize: `${clientIncome.totalHouseholdSize}` || '1',
        monthlyHouseholdGrossIncome: clientIncome.monthlyHouseholdGrossIncome || '',
        householdFederalPovertyLevelPct: clientIncome.householdFederalPovertyLevelPct || '', 
        monthlyHouseholdAdjustedGrossIncome: clientIncome.monthlyHouseholdAdjustedGrossIncome || '',
        totalIncomeAdjustments: clientIncome.totalIncomeAdjustments || '',
        monthlyHouseholdModifiedAdjustedGrossIncome: clientIncome.monthlyHouseholdModifiedAdjustedGrossIncome || '',
        magiHouseholdFederalPovertyLevelPct: clientIncome.magiHouseholdFederalPovertyLevelPct || '',
        incomeDocumentation: clientIncome.incomeDocumentation || [],
        effectiveDate: clientIncome.effectiveDate || ''
      });
    }
  }, [clientIncome]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      clientHasIncome, 
      employmentStatusId, 
      clientIncomeSources,
      hasOtherHouseholdMembers,
      otherHouseholdMembers,
      otherHouseholdMembersHaveIncome, 
      otherHouseholdIncomeSources,
      hasIncomeAdjustments, 
      incomeAdjustments,
      clientFiledTaxes, 
      taxFilingStatusId,
      federalPovertyLineBaseAmount,
      federalPovertyLineIncrementalAmount,
      totalHouseholdSize,
      monthlyHouseholdGrossIncome,
      householdFederalPovertyLevelPct, 
      monthlyHouseholdAdjustedGrossIncome,
      totalIncomeAdjustments,
      monthlyHouseholdModifiedAdjustedGrossIncome,
      magiHouseholdFederalPovertyLevelPct,
      incomeDocumentation,
      effectiveDate,
      ...dynamicFormData} = formData;

    const clientIncome = {
      clientHasIncome, 
      employmentStatusId, 
      clientIncomeSources,
      hasOtherHouseholdMembers,
      otherHouseholdMembers,
      otherHouseholdMembersHaveIncome, 
      otherHouseholdIncomeSources,
      hasIncomeAdjustments, 
      incomeAdjustments,
      clientFiledTaxes, 
      taxFilingStatusId,
      federalPovertyLineBaseAmount,
      federalPovertyLineIncrementalAmount,
      totalHouseholdSize,
      monthlyHouseholdGrossIncome,
      householdFederalPovertyLevelPct, 
      monthlyHouseholdAdjustedGrossIncome,
      totalIncomeAdjustments,
      monthlyHouseholdModifiedAdjustedGrossIncome,
      magiHouseholdFederalPovertyLevelPct,
      effectiveDate,
      incomeDocumentation
    };
    return [clientIncome, dynamicFormData];
  }
  
  const saveForm = async (values) => {
    values.incomeAdjustments.forEach(adj => {
      if (adj.recipientRelationshipId === 'client') {
        adj.clientIsRecipient = true;
        adj.recipientRelationshipId = '';
      } else {
        adj.clientIsRecipient = false;
      }
    });
    await upsertClientIncome(client.id, values);
  }

  return initialValues && (
    <HybridForm 
      title="Income"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled || updateRestrictions?.all || updateRestrictions?.income, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.income}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}