import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdapEnrollmentViewer from './AdapEnrollmentViewer';
import CustomProgramEnrollmentEditor from './CustomProgramEnrollmentEditor';
import { useProgram } from 'contexts/ProgramContext';


export default function EnrollmentEditor() {

  const {currentProgram, setProgram, loadProgramEnrollmentPicklists} = useProgram();

  const {lvl3: programId} = useParams();

  useEffect(() => {
    if (programId && (!currentProgram || currentProgram.id !== programId)) {
      setProgram(programId);
    }
  }, [programId]);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId) {
      loadProgramEnrollmentPicklists();
    }
  }, [currentProgram]);
  
  return currentProgram && currentProgram.id === programId && (
    <>
    {currentProgram.programTypeKey === 'adap' && <AdapEnrollmentViewer/>}
    {currentProgram.programTypeKey === 'custom' && <CustomProgramEnrollmentEditor/>}
    </>
  );
}