
export const messageTypes = {
  LOADING_PROGRAM_SUCCESS: 'LOADING_PROGRAM_SUCCESS',
  LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_AGENCIES_SUCCESS: 'LOADING_PROGRAM_AGENCIES_SUCCESS',
  LOADING_PROGRAM_ASSESSMENTS_SUCCESS: 'LOADING_PROGRAM_ASSESSMENTS_SUCCESS',
  LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_CONSENT_TYPES_SUCCESS: 'LOADING_PROGRAM_CONSENT_TYPES_SUCCESS',
  LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS: 'LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS',
  LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS',
  LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS: 'LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS',
  LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS: 'LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS',
  UPDATING_PROGRAM_PROPERTIES_SUCCESS: 'UPDATING_PROGRAM_PROPERTIES_SUCCESS',
  UPDATING_PROGRAM_ACTIVITY_LOG_CONFIG_SUCCESS: 'UPDATING_PROGRAM_ACTIVITY_LOG_CONFIG_SUCCESS',
  UPDATING_PROGRAM_ADDITIONAL_CLIENT_DATA_CONFIG_SUCCESS: 'UPDATING_PROGRAM_ADDITIONAL_CLIENT_DATA_CONFIG_SUCCESS',
  UPDATING_PROGRAM_AGENCIES_SUCCESS: 'UPDATING_PROGRAM_AGENCIES_SUCCESS',
  UPDATING_PROGRAM_ASSESSMENTS_SUCCESS: 'UPDATING_PROGRAM_ASSESSMENTS_SUCCESS',
  UPDATING_PROGRAM_CARE_MANAGEMENT_OPTIONS_SUCCESS: 'UPDATING_PROGRAM_CARE_MANAGEMENT_OPTIONS_SUCCESS',
  UPDATING_PROGRAM_CARE_PLAN_CONFIG_SUCCESS: 'UPDATING_PROGRAM_CARE_PLAN_CONFIG_SUCCESS',
  UPDATING_PROGRAM_CONSENT_TYPES_SUCCESS: 'UPDATING_PROGRAM_CONSENT_TYPES_SUCCESS',
  UPDATING_PROGRAM_CONTACT_CONFIG_SUCCESS: 'UPDATING_PROGRAM_CONTACT_CONFIG_SUCCESS',
  UPDATING_PROGRAM_DOCUMENT_TYPES_SUCCESS: 'UPDATING_PROGRAM_DOCUMENT_TYPES_SUCCESS',
  UPDATING_PROGRAM_ENROLLMENT_CONFIG_SUCCESS: 'UPDATING_PROGRAM_ENROLLMENT_CONFIG_SUCCESS',
  UPDATING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS: 'UPDATING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS',
  UPDATING_PROGRAM_MEETING_CONFIG_SUCCESS: 'UPDATING_PROGRAM_MEETING_CONFIG_SUCCESS',
  UPDATING_PROGRAM_PROGRESS_LOG_CONFIG_SUCCESS: 'UPDATING_PROGRAM_PROGRESS_LOG_CONFIG_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_PROGRAM_SUCCESS: {
      let newState = { 
        currentProgram: payload.currentProgram,
        // Remove any previously loaded associated info by not including in new state
      };
      return newState;
    }
    case messageTypes.LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS:
    case messageTypes.LOADING_PROGRAM_AGENCIES_SUCCESS:
    case messageTypes.LOADING_PROGRAM_ASSESSMENTS_SUCCESS:
    case messageTypes.LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS:
    case messageTypes.LOADING_PROGRAM_CONSENT_TYPES_SUCCESS:
    case messageTypes.LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS:
    case messageTypes.LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS:
    case messageTypes.LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS:
    case messageTypes.LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS:
    case messageTypes.LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_PROPERTIES_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_ACTIVITY_LOG_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_ADDITIONAL_CLIENT_DATA_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_AGENCIES_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_ASSESSMENTS_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_CARE_MANAGEMENT_OPTIONS_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_CARE_PLAN_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_CONSENT_TYPES_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_CONTACT_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_DOCUMENT_TYPES_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_ENROLLMENT_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_MEETING_CONFIG_SUCCESS:
    case messageTypes.UPDATING_PROGRAM_PROGRESS_LOG_CONFIG_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    default:
      break;
  }
};
