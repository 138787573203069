import React from 'react';
import LotusSpacer from './LotusSpacer';
import { Grid, Stack } from '@mui/material';

export default function LotusPageTitle({ title, subTitle, subTitleSmall, ...props }) {

  return (
    <>
    <LotusSpacer/>
    <Grid container id={props.id} alignItems="center" style={{paddingBottom: 8, borderBottom: props.children ? "1px solid grey" : "", marginBottom: props.children ?  16 : 0}}>
      <Grid item xs={8}>
        <div style={{fontSize: 34, fontWeight: 400}}>{title}</div>
        <div style={{fontSize: 16, fontWeight: 400}}>{subTitle}</div>
       { subTitleSmall && (<div style={{fontSize: 14, fontWeight: 300}}>&nbsp;{subTitleSmall}</div>)}
      </Grid>
      <Grid item xs={4}>
        <Stack justifyContent='flex-end' direction="row" spacing={1}>
          {props.children}
        </Stack>
      </Grid>
    </Grid>
    <LotusSpacer/>
    </>
  );
}
