import React, { useEffect, useState } from 'react';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { useParams } from 'react-router-dom';
import ClientProgramInfoEditor from './ClientProgramInfoEditor';
import ClientInfoEditor from './ClientInfoEditor';
import LotusMainSubTabs from 'components/widgets/Layout/LotusMainSubTabs';

export default function ClientInfo({client}) {
  const { enrolledPrograms, loadEnrollmentsForClient } = useEnrollments();
  
  const { clientId, lvl2: programId } = useParams();

  const [tabs, setTabs] = useState();

  useEffect(() => {
    if (client && client.id === clientId && !enrolledPrograms) {
      loadEnrollmentsForClient(client.id, client.agencyId);
    }
  }, [client, clientId]);

  useEffect(() => {
    if (enrolledPrograms) {
      const tabList = [{label: 'General', link: `/client/${clientId}/clientinformation/General`, programId: 'general'}];
      enrolledPrograms.filter(e => e.programTypeKey === 'custom').map((e) => {
        tabList.push({label: e.programName, link: `/client/${clientId}/clientinformation/${e.programId}`, programId: e.programId});
      });
      setTabs(tabList);
    }
  }, [enrolledPrograms]);

  return tabs && programId && (
    <div>
      <LotusMainSubTabs tabs={tabs} selectedTabName={programId} tabPropertyToMatch="programId" />
      {programId && programId.toLowerCase() === 'general' && (
        <ClientInfoEditor clientId={clientId} client={client}/>
      )}
      {programId && programId.toLowerCase() !== 'general' && enrolledPrograms && (
        <ClientProgramInfoEditor
          clientId={clientId}
          client={client}
          programId={programId}
          programName={enrolledPrograms.find((e) => e.programId === programId).programName}
        />
      )}
    </div>
  );
};