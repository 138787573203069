import React from 'react';
import LotusPrimaryActionButton from '../LotusPrimaryActionButton';


export default function LotusSaveButton({
  onClick,
  isDanger,
  isSuccess,
  disabled,
}) {
  return (
    <LotusPrimaryActionButton
      onClick={onClick}
      isDanger={isDanger}
      isSuccess={isSuccess}
      disabled={disabled}
    >
      Save
    </LotusPrimaryActionButton>
  );
}