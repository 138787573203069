const { useUserAgency } = require("contexts/UserAgencyContext");
const { useClientForms } = require("contexts/ClientFormContext");
const { useState, useEffect } = require("react");

export function useClientSubforms(clientId, formTypeKey) {
  const { userAgency } = useUserAgency();
  const {clientForms, loadClientForms, buildClientFormKey} = useClientForms();
  const [clientSubformData, setClientSubformData] = useState();
  
  useEffect(() => {
    if (userAgency && clientId) {
      const key = buildClientFormKey(userAgency.granteeAgencyId, formTypeKey);
      if (!clientForms || key in clientForms === false) {
        loadClientForms({clientId: clientId, formTypeKey: formTypeKey});
      }
    }
  }, [userAgency, clientId]);

  useEffect(() => {
    if (userAgency && clientId) {
      const key = buildClientFormKey(clientId, formTypeKey);
      if (clientForms && key in clientForms) {
        setClientSubformData(clientForms[key]);
      }
    }
  }, [userAgency, clientId, clientForms]);

  return clientSubformData;
}
