import GoalPanel from './GoalPanel';
import GoalForm from './GoalForm';
import React, { useState } from 'react';
import {Button} from '@lotus/components';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function GoalList({ readOnly, barrierId, goals }) {
  const [addingGoal, setAddingGoal] = useState(false);

  const afterSave = async () => {
    setAddingGoal(false);
  };

  return (
    <>
    <LotusSpacedBlock>
    {goals && goals.map((goal) => (
      <GoalPanel
        key={goal.id}
        readOnly={readOnly}
        barrierId={barrierId}
        goal={goal}
      />
    ))}
    </LotusSpacedBlock>
    {!addingGoal && !readOnly && (
      <LotusSpacedBlock>
        <Button plusIcon variant='outlined' color='secondary' onClick={() => setAddingGoal(true)}>
          Goal
        </Button>
      </LotusSpacedBlock>
    )}
    {addingGoal && (
      <GoalForm
        readOnly={readOnly}
        barrierId={barrierId}
        afterSave={afterSave}
        handleCancel={() => setAddingGoal(false)}
      />
    )}
    </>
  );
}
