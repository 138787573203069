import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './UserAgencyContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';


const UserAgencyContext = createContext();

const initialState = {
  userAgency: null,
  // Note: These pull from the grantee or subagency, as appropriate
  userAgencyAssessments: undefined,
  userAgencyConsentTypes: undefined,
  userAgencyDocumentTypes: undefined,
  userAgencyLanguages: undefined,
  userAgencyPrograms: undefined,
  userAgencyProviderTypes: undefined,
  userAgencyOptionalFeatures: undefined,
  userAgencyClientInfoConfig: undefined,
  userAgencyEligibilityConfig: undefined,
  userAgencyAdapSubprograms: undefined,
  userAgencyClientInfoCustomLists: undefined,
  userAgencyMetrics: undefined,
  userGranteeAgencyPrograms: undefined,
};

export const UserAgencyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserAgencyContext.Provider value={{ state, dispatch }}>
      {children}
    </UserAgencyContext.Provider>
  );
};

export const useUserAgency = () => {
  const { state, dispatch } = useContext(UserAgencyContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadUserAgency = async () => {
    try {
      addBusyBee('loadUserAgency');

      const { data } = await axios.post('/api/agencies', {operationName: 'getUserAgencies'});
      const { userGranteeAgency, userAgency} = parseApiResult(data).body;

      // userGranteeAgency is null for root users
      dispatch({
        type: messageTypes.LOADING_USER_AGENCY_SUCCESS,
        payload: {
          userAgency,
          userAgencyClientInfoConfig: userGranteeAgency?.clientInfoConfig,
          userAgencyEligibilityConfig: userGranteeAgency?.eligibilityConfig,
          userAgencyDashboardConfig: userGranteeAgency?.dashboardConfig,
          userAgencyOptionalFeatures: userAgency.optionalFeatures,
          // Clear out any previously loaded stuff so it gets reloaded
          userAgencyAssessments: undefined,
          userAgencyConsentTypes: undefined,
          userAgencyDocumentTypes: undefined,
          userAgencyLanguages: undefined,
          userAgencyPrograms: undefined,
          userAgencyProviderTypes: undefined,
          userAgencyAdapSubprograms: undefined,
          userAgencyClientInfoCustomLists: undefined
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgency');
    }
  };

  const loadUserAgencyAssessments = async () => {
    try {
      addBusyBee('loadUserAgencyAssessments');
      
      const toPost = {
        operationName: 'getAgencyAssessments',
        agencyId: state.userAgency.granteeAgencyId
      }

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const userAgencyAssessments = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_ASSESSMENTS_SUCCESS,
        payload: {
          userAgencyAssessments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyAssessments');
    }
  };

  const loadUserAgencyMetrics = async (categories) => {
    try {
      addBusyBee('loadUserAgencyMetrics');
      
      const toPost = {
        operationName: 'getAgencyMetrics',
        agencyId: state.userAgency.granteeAgencyId,
        categories: categories
      }

      const url = `/api/agency`;
      const { data } = await axios.post(url, toPost);
      const userAgencyMetrics = parseApiResult(data).body;

      const mappedCategories = categories.map((cat) => {
        return {
          category: cat,
          metrics: userAgencyMetrics.filter(m => m.category === cat)
        }
      });

      dispatch({
        type: messageTypes.LOADING_AGENCY_METRICS_SUCCESS,
        payload: {
          metricsByCategory: mappedCategories
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyMetrics');
    }
  };

  const loadUserAgencyConsentTypes = async () => {
    try {
      addBusyBee('loadUserAgencyConsentTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyConsentTypes', agencyId: state.userAgency.granteeAgencyId});

      let userAgencyConsentTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_CONSENT_TYPES_SUCCESS,
        payload: {
          userAgencyConsentTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyConsentTypes');
    }
  };

  const loadUserAgencyClientInfoCustomLists = async () => {
    try {
      addBusyBee('loadUserAgencyClientInfoCustomLists');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyClientInfoCustomLists', agencyId: state.userAgency.granteeAgencyId});

      let userAgencyClientInfoCustomLists = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS,
        payload: {
          userAgencyClientInfoCustomLists,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyClientInfoCustomLists');
    }
  };

  const loadUserAgencyDocumentTypes = async () => {
    try {
      addBusyBee('loadUserAgencyDocumentTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyDocumentTypes', agencyId: state.userAgency.granteeAgencyId});

      let userAgencyDocumentTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS,
        payload: {
          userAgencyDocumentTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyDocumentTypes');
    }
  };

  const loadUserAgencyLanguages = async () => {
    try {
      addBusyBee('loadUserAgencyLanguages');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyLanguages', agencyId: state.userAgency.granteeAgencyId});

      let userAgencyLanguages = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_LANGUAGES_SUCCESS,
        payload: {
          userAgencyLanguages,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyLanguages');
    }
  };

  const loadUserAgencyPrograms = async () => {
    try {
      addBusyBee('loadUserAgencyPrograms');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyProgramsAccessibleForUser', agencyId: state.userAgency.id});
      const userAgencyPrograms = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS,
        payload: {
          userAgencyPrograms,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyPrograms');
    }
  };

  const loadUserGranteeAgencyPrograms = async () => {
    try {
      addBusyBee('loadUserGranteeAgencyPrograms');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAllAgencyPrograms', agencyId: state.userAgency.granteeAgencyId});
      const userGranteeAgencyPrograms = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_GRANTEE_AGENCY_PROGRAMS_SUCCESS,
        payload: {
          userGranteeAgencyPrograms,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserGranteeAgencyPrograms');
    }
  };

  const loadUserAgencyProviderTypes = async () => {
    try {
      addBusyBee('loadUserAgencyProviderTypes');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyProviderTypes', agencyId: state.userAgency.granteeAgencyId});
      const userAgencyProviderTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_PROVIDER_TYPES_SUCCESS,
        payload: {
          userAgencyProviderTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyProviderTypes');
    }
  };

  const loadUserAgencyAdapSubprograms = async () => {
    try {
      addBusyBee('loadUserAgencyAdapSubprograms');

      const url = `/api/agency`;
      const { data } = await axios.post(url, {operationName: 'getAgencyAdapSubprograms', agencyId: state.userAgency.granteeAgencyId});
      const userAgencyAdapSubprograms = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCY_ADAP_SUBPROGRAMS_SUCCESS,
        payload: {
          userAgencyAdapSubprograms,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserAgencyAdapSubprograms');
    }
  };

  if (state) {
    return {
      ...state,
      loadUserAgency,
      loadUserAgencyAssessments,
      loadUserAgencyConsentTypes,
      loadUserAgencyDocumentTypes,
      loadUserAgencyLanguages,
      loadUserAgencyPrograms,
      loadUserAgencyProviderTypes,
      loadUserAgencyAdapSubprograms,
      loadUserAgencyClientInfoCustomLists,
      loadUserAgencyMetrics,
      loadUserGranteeAgencyPrograms
    };
  }

  return {};
};
