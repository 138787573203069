import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

export default function FormKeys({ formDefinition }) {
  return (
    <>
      <LotusFormItem>
        Form Type: {formDefinition.formTypeName}
      </LotusFormItem>
      <LotusFormItem>
        Form Subtype: {formDefinition.formSubtypeName || formDefinition.formSubtypeCustomName || '(None)'} {formDefinition.formSubtypeCustomSequence ? `(Seq: ${formDefinition.formSubtypeCustomSequence})` : ''}
      </LotusFormItem>
      {formDefinition.programName && (
        <LotusFormItem>
          Program: {formDefinition.programName}
        </LotusFormItem>
      )}
      {formDefinition.assessmentTypeName && (
        <LotusFormItem>
          Assessment Type: {formDefinition.assessmentTypeName}
        </LotusFormItem>
      )}
    </>
  );
}
