import React, { useEffect, useState } from 'react'
import { Formik, Form, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import { useAuthState } from 'contexts/AuthProvider';
import { useProperties } from 'contexts/PropertiesContext';
import { useAppStatus } from 'contexts/AppStatusContext';
import { Grid, Box } from "@mui/material";
import Typography from '@mui/material/Typography';
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';
import gwlogo from '../../GTI-Logo-Good.png'
import nclogo from '../../NCDHHS-DPH-hor-CMYK.jpg'
import poweredBy from '../../PoweredBy.jpg'
import LoginForm from './forms/LoginForm';
import EnterNewPasswordForm from './forms/EnterNewPasswordForm';
import RequestPasswordResetForm from './forms/RequestPasswordResetForm';
import VerifyTempCodeForm from './forms/VerifyTempCodeForm';
import SetupMFAForm from './forms/SetupMFAForm';
import EnterMFACodeForm from './forms/EnterMFACodeForm';
import ErrorMessageForm from './forms/ErrorMessageForm';
import { CognitoUser, CognitoUserSession, CognitoIdToken, CognitoAccessToken, CognitoRefreshToken, CognitoUserPool } from 'amazon-cognito-identity-js';
import './LoginPage.css'

const MESSAGES = {
  'Password attempts exceeded': 'Invalid username or password. This account has been locked for a short period of time. Please wait and then try again.',
  'MFA Locked': 'Invalid MFA code. This account has been locked for a short period of time. Please wait and then try again.',
  'Invalid login': 'Invalid username or password. After too many unsuccessful attempts, the account will be temporarily locked. Try again or click the reset password link.',
  'Code mismatch': 'Invalid MFA Code.',
  'Invalid session for the user, session is expired.': 'Your session has timed out. Please refresh the page to try again.',
  'Invalid Code.': 'Invalid MFA code. After too many unsuccessful attempts, the account will be temporarily locked.',
  'Password does not conform to policy: Password not long enough': 'Password is not long enough',
  'Password does not conform to policy: Password must have uppercase characters': 'Password must have uppercase characters',
  'Password does not conform to policy: Password must have lowercase characters': 'Password must have lowercase characters',
  'Password does not conform to policy: Password must have numeric characters': 'Password must have numeric characters',
  'Password does not conform to policy: Password must have symbol characters': 'Password must have symbol characters',

};


const validationSchema = yup.object(
  {
    email: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('login')) {
        return schema.email("Email address is not valid")
          .required('Email address is required');
      }
      return schema;
    }),
    password: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('login')) {
        return schema.required('Password is required');
      }
      return schema;
    }),
    resetEmail: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('resetPassword')) {
        return schema.email("Email address is not valid").required('Email address is required');
      }
      return schema;
    }),
    resetCode: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('createNewPassword')) {
        return schema.required('Reset Code is required');
      }
      return schema;
    }),
    newPassword: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('createNewPassword')) {
        return schema.required('New Password is required');
      }
      return schema;
    }),
    confirmPassword: yup.string().when('$operation', (operation, schema) => {
      if (operation.includes('createNewPassword')) {
        return schema.required('Confirm Password is required');
      }
      return schema;
    }),
  }
);

export default function LoginPage() {

  const { signIn, handleNewPassword, associateSoftwareToken, verifySoftwareToken, sendMFACode, sendCode, forgotPassword, updatePasswordAndLoginHistory, exchangeToken } = useAuthState();
  const { properties } = useProperties();
  const [tokenExchange, setTokenExchange] = useState();
  const [initialOperation, setInitialOperation] = useState('login');
  const { addBusyBee, removeBusyBee } = useAppStatus();

  useEffect(() => {
    const checkTempTokenExchange = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has('temptoken')) {
        return;
      }
      if (!tokenExchange) {
        try {
          addBusyBee('tokenExchange');
          setTokenExchange({ loading: true });
          const result = await exchangeToken(urlParams.get('temptoken'));
          setTokenExchange(result);
          if (result.err) {
            setInitialOperation('error');
          } else {
            setInitialOperation('enterNewPassword');
          }
        } catch (err) {
          console.log(err);
        }
        finally {
          removeBusyBee('tokenExchange');
        }
      }
    }
    const checkCodeExchange = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has('code')) {
        return;
      }

      try {
        const domain = properties['REACT_APP_POOL_DOMAIN'];
        const region = properties['REACT_APP_REGION'];
        const tokenEndpoint = `https://${domain}.auth.${region}.amazoncognito.com/oauth2/token`;
        const clientId = properties['REACT_APP_CLIENT_ID'];
        const redirectUri = window.location.href.split('?')[0];
        const userPoolId = properties['REACT_APP_USER_POOL_ID'];


        const bodyParams = new URLSearchParams();
        bodyParams.append('grant_type', 'authorization_code');
        bodyParams.append('client_id', clientId);
        bodyParams.append('code', urlParams.get('code'));
        bodyParams.append('redirect_uri', redirectUri);

        addBusyBee('codeExchange');

        const response = await fetch(tokenEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept-Encoding': 'gzip, deflate'
          },
          body: bodyParams.toString(),
        });

        const data = await response.json();
        if (data.error) {
          throw new Error('Error during code exchange');
        } else {
          const refreshToken = new CognitoRefreshToken({ RefreshToken: data.refresh_token });
          const idToken = new CognitoIdToken({ IdToken: data.id_token });
          const accessToken = new CognitoAccessToken({ AccessToken: 'accessToken' });
          const email = idToken?.decodePayload()?.email;

          const cognitoSession = new CognitoUserSession({
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken
          });

          const poolData = {
            UserPoolId: userPoolId,
            ClientId: clientId,
            Storage: window.sessionStorage
          };
          const userPool = new CognitoUserPool(poolData);;
          const user = new CognitoUser({ Username: email, Pool: userPool, Storage: window.sessionStorage });
          user.setSignInUserSession(cognitoSession);

          await updatePasswordAndLoginHistory("", email);
          window.location.href = redirectUri;
        }
      } catch (err) {
        console.log(err);
      }
      finally {
        removeBusyBee('codeExchange');
      }
    };

    // Properties will be loaded by header
    if (properties) {
      checkCodeExchange();
      checkTempTokenExchange();
    }
  }, [properties]);

  const getDisplayMessage = (originalMessage) => {
    if (MESSAGES[originalMessage]) {
      return MESSAGES[originalMessage];
    } else {
      return originalMessage;
    }
  }

  const handleError = (data, result) => {
    data.otp = '';
    if (result.errorCode === 'UserNotFoundException' || result.errorCode === 'NotAuthorizedException') {
      if (data.operation === 'login') {
        if (result.error === 'Password attempts exceeded') {
          data.errorMsg = getDisplayMessage(result.error);
        } else {
          data.errorMsg = getDisplayMessage('Invalid login');
        }
        data.email = '';
        data.password = '';
      } else {

        if (data.operation === 'resetPassword') {
          data.errorMsg = '';
          data.operation = 'createNewPassword';
        } else {

          if (data.operation === 'enterMFACode') {
            if (result.error === 'Invalid session for the user, session is expired.') {
              data.errorMsg = getDisplayMessage(result.error);
            } else {
              data.errorMsg = getDisplayMessage('MFA Locked');
            }
          } else {
            data.errorMsg = getDisplayMessage(result.error);
          }
        }
      }
    } else if (result.errorCode === 'CodeMismatchException') {
      if (data.operation === 'createNewPassword') {
        data.errorMsg = getDisplayMessage(result.error);
      } else {
        data.errorMsg = getDisplayMessage('Invalid Code.');
      }
    } else if (result.errorCode === 'InvalidParameterException') {
      if (data.operation === 'createNewPassword') {
        data.errorMsg = 'Password does not conform to policy.';
      } else if (data.operation === 'setupMFA') {
        data.errorMsg = getDisplayMessage('Code mismatch');
      }
      else {
        data.errorMsg = getDisplayMessage(result.error);
      }
    } else {
      data.errorMsg = getDisplayMessage(result.error);
    }
  }

  const initialValues = {
    pageTitle: 'Welcome',
    operation: initialOperation,
    email: tokenExchange?.email || '',
    resetEmail: '',
    password: tokenExchange?.pwd || '',
    errorMsg: tokenExchange?.err || '',
    newPassword: undefined,
    confirmPassword: '',
    otp: '',
    secretKey: '',
    userAttributes: undefined,
    showDisclaimer: true,
    showWelcome: true,
    emailDestination: ''
  };

  const handleSubmit = async (data) => {
    let result;
    data.errorMsg = '';
    if (data.operation === 'enterNewPassword') {
      result = await signIn(data.email, data.password);
      if (!result.error) {
        data.userAttributes = result.userAttributes;
        result = await handleNewPassword(data.userAttributes, data.newPassword);
      }
    } else if (data.operation === 'enterMFACode') {
      result = await sendMFACode(data.otp);
    } else if (data.operation === 'resetPassword') {
      result = await sendCode(data.resetEmail);
    } else if (data.operation === 'setupMFA') {
      result = await verifySoftwareToken(data.otp);
    } else if (data.operation === 'createNewPassword') {
      result = await forgotPassword(data.resetEmail, data.resetCode, data.newPassword);
    } else {
      result = await signIn(data.email, data.password);
    }

    if (result.error) {
      handleError(data, result);
    } else {
      data.errorMsg = '';
      if (result.newPasswordRequired) {
        data.operation = 'enterNewPassword'
        data.userAttributes = result.userAttributes;
      } else if (result.totpRequired) {
        data.operation = 'enterMFACode'
      } else if (result.mfaSetupRequired) {
        data.operation = 'setupMFA'
        const token = await associateSoftwareToken();
        data.token = 'otpauth://totp/ProvideEnterprise:' + data.email + '?secret=' + token + '&issuer=ProvideEnterprise';
        data.secretKey = token;
        data.pageTitle = 'Multi-Factor Authentication (MFA) Setup';
        data.showWelcome = false;
        data.showDisclaimer = false;

      } else if (result.CodeDeliveryDetails) {
        data.errorMsg = '';
        data.operation = 'createNewPassword';
        data.emailDestination = result.CodeDeliveryDetails.Destination;
      }
      else {
        updatePasswordAndLoginHistory(data.newPassword || data.password, data.email);
        const redirectUri = window.location.href.split('?')[0];
        if (window.location.href === redirectUri) {
          window.location.reload();
        } else {
          window.location.href = redirectUri;
        }
      }
    }

  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validate={async (values) => {
          let result = {};
          try {
            validationSchema.validateSync(values,
              { abortEarly: false, context: { operation: values.operation } });
          } catch (err) {
            result = yupToFormErrors(err);
          }
          return result;
        }}
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
        }}

      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setErrors
        }) => {

          return (
            <>
              <div className="content-container">
                <div style={{ padding: "20px" }} />
                <Form onSubmit={handleSubmit} style={{ width: "100" }} >

                  <Box
                    sx={{ flexDirection: 'column' }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {properties && (
                      <img src={properties['REACT_APP_LOGIN_PAGE_STYLE'] ? nclogo : gwlogo} alt={'Provide Logo'} loading="lazy" />
                    )}
                    <LotusSpacer />
                    <Typography style={{ fontWeight: 400, lineHeight: "42px", fontSize: 34 }} >{values.pageTitle}</Typography>
                    <div style={{ padding: "10px" }} />

                    {values.showWelcome && (properties && properties['REACT_APP_LOGIN_PAGE_STYLE']) && (
                      <Box
                        sx={{ flexDirection: 'column' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography style={{ fontWeight: 400, lineHeight: "28px", fontSize: 16 }} >North Carolina’s Ryan White Part B Eligibility and</Typography>
                        <Typography style={{ fontWeight: 400, lineHeight: "28px", fontSize: 16 }}>Enrollment Determination Solution (NC REEDS)</Typography>
                        <div style={{ padding: "10px" }} />
                      </Box>
                    )}


                    <LoginForm values={values} handleChange={handleChange} touched={touched} errors={errors} setErrors={setErrors} props={properties} />
                    <EnterNewPasswordForm values={values} handleChange={handleChange} touched={touched} errors={errors} props={properties} />
                    <SetupMFAForm values={values} handleChange={handleChange} touched={touched} errors={errors} />
                    <EnterMFACodeForm values={values} handleChange={handleChange} touched={touched} errors={errors} />
                    <RequestPasswordResetForm values={values} handleChange={handleChange} touched={touched} errors={errors} />
                    <VerifyTempCodeForm values={values} handleChange={handleChange} touched={touched} errors={errors} props={properties} />
                    <ErrorMessageForm values={values} />

                    {values.showDisclaimer && (properties && properties['REACT_APP_LOGIN_PAGE_STYLE']) && (
                      <Box
                        sx={{ flexDirection: 'column' }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <LotusSpacer /><LotusSpacer /><LotusSpacer />

                        <Typography style={{ fontWeight: 400, lineHeight: "20px", fontSize: 12, fontStyle: 'italic' }}>
                          This system is the property of the State of North Carolina & is for
                        </Typography>
                        <Typography style={{ fontWeight: 400, lineHeight: "20px", fontSize: 12, fontStyle: 'italic' }}>
                          authorized users ONLY. Unauthorized access may result in disciplinary
                        </Typography>
                        <Typography style={{ fontWeight: 400, lineHeight: "20px", fontSize: 12, fontStyle: 'italic' }}>
                          action, civil & criminal penalties. Users have no expectation of privacy.
                        </Typography>
                        <Typography style={{ fontWeight: 400, lineHeight: "20px", fontSize: 12, fontStyle: 'italic' }}>
                          USER EXPRESSLY CONSENTS TO MONITORING.
                        </Typography>
                        <LotusSpacer /><LotusSpacer /><LotusSpacer />
                      </Box>

                    )}
                  </Box>
                </Form>
              </div>
            </>
          );
        }}
      </Formik>
      <footer className="footer--pin">
        <Box sx={{ bottom: 0, left: 0, right: 0, borderTop: '1px solid #0000001F' }} >
          <Grid bottom="0" container direction="column" alignItems="center" justifyContent="center" >
            <LotusSpacer /><LotusSpacer /><LotusSpacer />

            <Typography style={{ fontWeight: 400, lineHeight: "20px", fontSize: 12, color: "#7F7F7F" }}>Powered by:</Typography>
            <img src={poweredBy} alt={'Powered By'} style={{ height: 50, width: 173 }} loading="lazy" />
            <LotusSpacer /><LotusSpacer /><LotusSpacer />
          </Grid>
        </Box>
      </footer>
    </>
  );
}
