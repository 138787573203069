import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './EligibilityApplicationSearchContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";

const initialState = {
  applications: undefined
};

const EligibilityApplicationSearchContext = createContext();

export const EligibilityApplicationSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EligibilityApplicationSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </EligibilityApplicationSearchContext.Provider>
  );
};

export const useApplicationSearch = () => {
  const { state, dispatch } = useContext(EligibilityApplicationSearchContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadApplicationsSync = async ({
    assignedToUserOnly,
    offset,
    limit,
    searchText,
    sortOrder,
    filters
  }) => {
    try {
      addBusyBee('loadApplications');
      const url = `/api/eligibilityApps`;
      const activeFilters = filters ? filters.filter((f) => f.value) : [];
      const { data } = await axios.post(url, {
        operationName: 'searchApplications',
        assignedToUserOnly,
        offset,
        limit,
        searchText,
        sortBy: sortOrder ? [sortOrder] : [],
        filters: activeFilters
      });
      return parseApiResult(data).body;
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadApplications');
    }
  };

  const loadApplications = async ({
    assignedToUserOnly,
    offset,
    limit,
    searchText,
    sortOrder,
    filters
  }) => {
    const searchResult = await loadApplicationsSync({
      assignedToUserOnly,
      offset,
      limit,
      searchText,
      sortOrder,
      filters
    });
    
    dispatch({
      type: messageTypes.LOADING_APPLICATIONS_SUCCESS,
      payload: {
        applications: searchResult.applications,
        offset,
        limit
      },
    });
  };

  const loadPendingApplicationsCount = async () => {
    try {
      addBusyBee('loadPendingApplicationsCount');
      const url = `/api/eligibilityApps`;
        
      const { data } = await axios.post(url, { operationName: 'getPendingApplicationsCount' });
      const count = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PENDING_APPLICATIONS_COUNT_SUCCESS,
        payload: {
          pendingApplicationsCount: count
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPendingApplicationsCount');
    }
  };

  return {
    ...state,
    loadApplicationsSync,
    loadApplications,
    loadPendingApplicationsCount
  };
};
