import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientList from './ClientList';
import AddClientPanel from './AddClientPanel';
import { ClientRegistrationProvider } from 'contexts/ClientRegistrationContext';
import { ClientSearchProvider } from 'contexts/ClientSearchContext';

export default function ClientManagement() {

  const navigate = useNavigate();
  const { page } = useParams();

  const goToList = async () => {
    navigate('/clients/list/myclients');
  };

  return (
    <div>
      {page === 'list' && 
        <ClientSearchProvider>
          <ClientList />
        </ClientSearchProvider>
      }
      {page === 'create' && (
        <ClientRegistrationProvider>
          <AddClientPanel handleBackClicked={goToList}/>
        </ClientRegistrationProvider>
      )}
    </div>
  );
}
