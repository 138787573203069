import { useState } from "react";
import LotusDialog from "components/feedback/dialog/LotusDialog";
import LotusEditButton from "components/button/common/LotusEditButton";
import LotusDataGrid from "components/dataDisplay/datagrid/LotusDataGrid";
import IntegrationView from "./IntegrationView";


export default function IntegrationListView({
  integrations,
  agencyConnectors,
  integrationDetailLoaderFunc,
  handleSave
}) {
  
  const [integrationToEdit, setIntegrationToEdit] = useState(false);

  const handleCancel = () => {
    setIntegrationToEdit(null);
  }

  const localHandleSave = async (values) => {
    await handleSave(values);
    setIntegrationToEdit(null);
  }

  const columns = [
    {
      headerName: 'Integration Name',
      field: 'name',
      type: 'string',
      flex: 3
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      display: 'flex',
      renderCell: (params) => {
        return (
          <LotusEditButton
            onClick={() => setIntegrationToEdit(params.row)}
          />
        );
      },
      flex: 1,
      hideSortIcons: true
    },
  ];

  return integrations && (
    <>
      <LotusDataGrid
        columns={columns}
        rows={integrations}
        disableColumnFilter
        getRowIdFunc={(row) => {return row.name}}
      />
      <LotusDialog
        staticWidth='lg'
        open={integrationToEdit}
        handleClose={handleCancel}
        content={
          integrationToEdit && 
            <IntegrationView 
              integration={integrationToEdit} 
              agencyConnectors={agencyConnectors}
              integrationDetailLoaderFunc={integrationDetailLoaderFunc}
              handleSave={localHandleSave}
              handleCancel={handleCancel} />
        }
      />
    </>
  );
}