import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import ContactTypeLinker from '../linkers/ContactTypeLinker';
import ContactSiteVisitTypeLinker from '../linkers/ContactSiteVisitTypeLinker';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';
import { v4 as uuidv4 } from 'uuid';

const validationSchema = yup.object({
  minutesRoundingInterval: yup
    .number().integer().nullable().typeError('Minutes Rounding Interval must be an integer'),
});

export default function ProgramContactForm({ handleSubmit }) {

  const [initialValues, setInitialValues] = useState();

  const { 
    currentProgram, 
    contactTypes,
    contactMethods,
    contactMadeAttemptedOptions,
    contactCategories,
    contactSiteVisitTypes,
    contactDelayReasons,
    contactMissedReasons,
    contactServiceSites,
    contactReportOptions,
    loadProgramContactPicklists,
  } = useProgramManagement();

  useEffect(() => {
    if (currentProgram && !contactTypes) {
      loadProgramContactPicklists();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (currentProgram && contactTypes && contactMethods && contactMadeAttemptedOptions && contactCategories && contactSiteVisitTypes &&
          contactDelayReasons && contactMissedReasons && contactServiceSites && contactReportOptions) {
      setInitialValues({
        displayName: currentProgram.contactConfig.displayName || '',
        displayNamePlural: currentProgram.contactConfig.displayNamePlural || '',
        minutesRoundingInterval: currentProgram.contactConfig.minutesRoundingInterval || '',
        enabled: currentProgram.contactConfig.enabled || false,
        categoryEnabled: currentProgram.contactConfig.categoryEnabled || false,
        typeEnabled: currentProgram.contactConfig.typeEnabled || false,
        madeAttemptedEnabled: currentProgram.contactConfig.madeAttemptedEnabled || false,
        siteVisitTypeEnabled: currentProgram.contactConfig.siteVisitTypeEnabled || false,
        withEnabled: currentProgram.contactConfig.withEnabled || false,
        missedEnabled: currentProgram.contactConfig.missedEnabled || false,
        missedReasonEnabled: currentProgram.contactConfig.missedReasonEnabled || false,
        delayedEnabled: currentProgram.contactConfig.delayedEnabled || false,
        delayReasonEnabled: currentProgram.contactConfig.delayReasonEnabled || false,
        minutesEnabled: currentProgram.contactConfig.minutesEnabled || false,
        contactTimeMinutesEnabled: currentProgram.contactConfig.contactTimeMinutesEnabled || false,
        travelTimeMinutesEnabled: currentProgram.contactConfig.travelTimeMinutesEnabled || false,
        documentationTimeMinutesEnabled: currentProgram.contactConfig.documentationTimeMinutesEnabled || false,
        conductedByEnabled: currentProgram.contactConfig.conductedByEnabled || false,
        coordinatorEnabled: currentProgram.contactConfig.coordinatorEnabled || false,
        serviceSiteEnabled: currentProgram.contactConfig.serviceSiteEnabled || false,
        interviewerEnabled: currentProgram.contactConfig.interviewerEnabled || false,
        respondentEnabled: currentProgram.contactConfig.respondentEnabled || false,
        isInterpretationServiceUsedEnabled: currentProgram.contactConfig.isInterpretationServiceUsedEnabled || false,
        interpretationServiceEnabled: currentProgram.contactConfig.interpretationServiceEnabled || false,
        reportOptionEnabled: currentProgram.contactConfig.reportOptionEnabled || false,
        briefDescriptionEnabled: currentProgram.contactConfig.briefDescriptionEnabled || false,
        fullDescriptionEnabled: currentProgram.contactConfig.fullDescriptionEnabled || false,
        completeEnabled: currentProgram.contactConfig.completeEnabled || false,
        currentContactTypes: contactTypes.map(t => {return {id: t.id, name: t.name, isMadeAttemptedEnabled: t.isMadeAttemptedEnabled}}),
        selectedContactTypes: contactTypes.map(t => {return `${t.name}-${t.id}`}),
        currentContactMethods: contactMethods.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactMadeAttemptedOptionNames: contactMadeAttemptedOptions.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactCategoryNames: contactCategories.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactSiteVisitTypes: contactSiteVisitTypes.map(t => {return {id: t.id, name: t.name, methodId: t.methodId}}),
        selectedContactSiteVisitTypes: contactSiteVisitTypes.map(t => {return `${t.name}-${t.methodId}`}),
        currentContactDelayReasonNames: contactDelayReasons.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactMissedReasonNames: contactMissedReasons.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactServiceSiteNames: contactServiceSites.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        currentContactReportOptionNames: contactReportOptions.map(t => {return {id: t.id, name: t.name, active: true}}) || [],
        addedContactType: {id: uuidv4(), name: '', isMadeAttemptedEnabled: false},
        addedContactMethod: {name: ''},
        addedContactMadeAttemptedOptionName: '',
        addedContactCategoryName: '',
        addedContactSiteVisitType: {name: '', methodId: ''},
        addedContactDelayReasonName: '',
        addedContactMissedReasonName: '',
        addedContactServiceSiteName: '',
        addedContactReportOptionName: ''
      });
    }
  }, [currentProgram, contactTypes, contactMethods, contactMadeAttemptedOptions, contactCategories, contactSiteVisitTypes,
      contactDelayReasons, contactMissedReasons, contactServiceSites, contactReportOptions]);

  return (
    <LotusPageSection header="Contacts">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="enabled"
                    label="Contacts Enabled"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                {values.enabled && (
                  <>
                    <LotusFormItem>
                      <LotusTextInput
                        name="displayName"
                        label="Display Name"
                      />
                    </LotusFormItem> 
                    <LotusFormItem>
                      <LotusTextInput
                        name="displayNamePlural"
                        label="Display Name (Plural)"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusTextInput
                        name='minutesRoundingInterval'
                        label='Minutes Rounding Interval'
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <GenericListLinker
                        name="currentContactMethods"
                        title="Contact Methods for Program"
                        itemName="Method"
                    />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="categoryEnabled"
                        label="Category Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.categoryEnabled && 
                      <LotusFormItem indent={1}>
                        <GenericListLinker
                          name="currentContactCategoryNames"
                          title="Contact Categories for Program"
                          itemName="Category"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="typeEnabled"
                        label="Type Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.typeEnabled &&
                      <LotusFormItem indent={1}>
                        <ContactTypeLinker
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="madeAttemptedEnabled"
                        label="Made/Attempted Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.madeAttemptedEnabled && 
                      <LotusFormItem indent={1}>
                        <GenericListLinker
                          name="currentContactMadeAttemptedOptionNames"
                          title="Contact Made/Attempted Options for Program"
                          itemName="Made/Attempted Option"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="siteVisitTypeEnabled"
                        label="Site Visit Type Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.siteVisitTypeEnabled && 
                      <LotusFormItem indent={1}>
                        <ContactSiteVisitTypeLinker
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="missedEnabled"
                        label="Missed Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.missedEnabled &&
                      <LotusFormItem indent={1}>
                        <LotusSwitch
                          name="missedReasonEnabled"
                          label="Missed Reason Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    }
                    {values.missedEnabled && values.missedReasonEnabled && 
                      <LotusFormItem indent={2}>
                        <GenericListLinker
                          name="currentContactMissedReasonNames"
                          title="Contact Missed Reasons for Program"
                          itemName="Missed Reason"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="delayedEnabled"
                        label="Delayed Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.delayedEnabled && 
                      <LotusFormItem indent={1}>
                        <LotusSwitch
                          name="delayReasonEnabled"
                          label="Delay Reason Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    }
                    {values.delayedEnabled && values.delayReasonEnabled && 
                      <LotusFormItem indent={2}>
                        <GenericListLinker
                          name="currentContactDelayReasonNames"
                          title="Contact Delay Reasons for Program"
                          itemName="Delay Reason"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="minutesEnabled"
                        label="Minutes Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="contactTimeMinutesEnabled"
                        label="Contact Time Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="travelTimeMinutesEnabled"
                        label="Travel Time Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="documentationTimeMinutesEnabled"
                        label="Documentation Time Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="conductedByEnabled"
                        label="Conducted By Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="coordinatorEnabled"
                        label="Coordinator Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="serviceSiteEnabled"
                        label="Service Site Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.serviceSiteEnabled && 
                      <LotusFormItem indent={1}>
                        <GenericListLinker
                          name="currentContactServiceSiteNames"
                          title="Contact Service Sites for Program"
                          itemName="Service Sites"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="interviewerEnabled"
                        label="Interviewer Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="respondentEnabled"
                        label="Respondent Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="isInterpretationServiceUsedEnabled"
                        label="Interpretation Service Indicator Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.isInterpretationServiceUsedEnabled &&
                      <LotusFormItem indent={1}>
                        <LotusSwitch
                          name="interpretationServiceEnabled"
                          label="Interpretation Service Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="reportOptionEnabled"
                        label="Report Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.reportOptionEnabled && 
                      <LotusFormItem indent={1}>
                        <GenericListLinker
                          name="currentContactReportOptionNames"
                          title="Contact Report Options for Program"
                          itemName="Report Option"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="briefDescriptionEnabled"
                        label="Brief Description Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="fullDescriptionEnabled"
                        label="Full Description Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="completeEnabled"
                        label="Complete Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                  </>
                )}
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
