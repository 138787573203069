import React, { useEffect, useState } from 'react'
import { Formik, Form, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useAuthState } from 'contexts/AuthProvider';
import { useProperties } from 'contexts/PropertiesContext';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';

const validationSchema = yup.object(
  {
    currentPassword: yup
      .string('')
      .required('Current Password is required')
  }

);
const ChangePasswordForm = ({
  values,
  handleChange,
  handleCancel,
  touched,
  errors,
}) => {

  const { properties } = useProperties();
  const [props, setProps] = useState();

  useEffect(() => {
    if (properties) {
      setProps(JSON.parse(properties['REACT_APP_PASSWORD_POLICY']));
    }

  }, [properties]);

  return (
    <>
      <LotusForm>
        <LotusFormItem>
          <LotusTextInput
            autoFocus
            autoComplete="off"
            id="currentPassword"
            name="currentPassword"
            label="Current Password"
            type="password"
            value={values.currentPassword}
            style={{ width: 400 }}
            onChange={handleChange}
            error={touched.currentPassword && Boolean(errors.currentPassword)}
          />
        </LotusFormItem>
        <Typography variant="h2" component="h2">
          Create a new password
        </Typography>
        <div >
          <Typography style={{ width: 500 }}>The new password must meet the following requirements:
            <div>Minimum length: {props?.MinimumLength} characters</div>
            {props?.RequireNumbers && (<div>Contains at least 1 number</div>)}
            {props?.RequireSymbols && (<div>Contains at least 1 special character</div>)}
            {props?.RequireUppercase && (<div>Contains at least 1 uppercase letter</div>)}
            {props?.RequireLowercase && (<div>Contains at least 1 lowercase letter</div>)}
            <div>&nbsp;</div>
          </Typography>
        </div>
        <LotusFormItem>
          <LotusTextInput
            autoComplete="off"
            id="newPassword"
            name="newPassword"
            label="New Password"
            required
            type="password"
            value={values.newPassword}
            style={{ width: 400 }}
            onChange={handleChange}
            error={touched.newPassword && Boolean(errors.newPassword)}
          />
        </LotusFormItem>
        <LotusFormItem>
          <LotusTextInput
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            required
            value={values.confirmPassword}
            style={{ width: 400 }}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          />
        </LotusFormItem>
        <LotusFormItem>
          <Typography variant="body" color='#b53f3f'>{values.errorMsg}</Typography>
        </LotusFormItem>
        <LotusFormItem>

          <Grid container direction="row" justifyContent="left" spacing={2} columns={2}>
            <Grid item>
              <LotusFormItem>
                <LotusButton variant="outlined" onClick={() => { handleCancel(); }} >
                  Cancel
                </LotusButton>
              </LotusFormItem>
            </Grid>
            <Grid item>
              <LotusFormItem>
                <LotusButton
                  type="submit"
                  disabled={!Boolean(values.newPassword) || values.newPassword !== values.confirmPassword}
                >
                  Change Password
                </LotusButton>
              </LotusFormItem>
            </Grid>
          </Grid>
        </LotusFormItem>
      </LotusForm>

    </>
  );
};

export default function ChangePasswordDialog({ handleCancel, onSuccess }) {
  const { changePassword, updatePasswordAndLoginHistory } = useAuthState();

  const initialValues = {
    currentPassword: '',
    errorMsg: '',
    newPassword: undefined,
    confirmPassword: ''
  };

  const handleSubmit = async (data) => {
    let result;
    if (data.currentPassword === data.newPassword) {
      data.errorMsg = 'Please enter a password that you have not previously used.';
      data.currentPassword = '';
      data.newPassword = '';
      data.confirmPassword = '';

    } else {
      result = await changePassword(data.currentPassword, data.newPassword);

    }

    if (result.error) {
      data.currentPassword = '';
      data.newPassword = '';
      data.confirmPassword = '';
      data.errorMsg = result.error;

      if (result.errorCode === 'InvalidParameterException') {
        data.errorMsg = 'Password does not conform to policy.';
      }
    } else {
      const err = await updatePasswordAndLoginHistory(data.newPassword);
      if (!err) {
        onSuccess();
        handleCancel();
      } else {
        data.errorMsg = err;

      }
    }
  };

  return (

    <Formik
      initialValues={initialValues}
      validate={async (values) => {
        let result = {};
        try {
          validationSchema.validateSync(values, { abortEarly: false });
        } catch (err) {
          result = yupToFormErrors(err);
        }
        return result;
      }}
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        touched,
        errors,
        values
      }) => {
        return (

          <Grid container columns={1} alignItems="center" direction="column" justify="flex-end">

            <Form onSubmit={handleSubmit}>
              <Grid item xs={6} >
                <LotusPageTitle title={'Change Password'} />
              </Grid>
              <ChangePasswordForm values={values} handleChange={handleChange} handleCancel={handleCancel} touched={touched} errors={errors} />
            </Form>
          </Grid>

        );
      }}
    </Formik>
  );
}
