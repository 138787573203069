export const messageTypes = {
  LOADING_USER_DETAIL_SUCCESS: 'LOADING_USER_DETAIL_SUCCESS',
  UPDATING_USER_DATA_SUCCESS: 'UPDATING_USER_DATA_SUCCESS',
  UPDATING_USER_TABLE_PREFERENCES_SUCCESS: 'UPDATING_USER_TABLE_PREFERENCES_SUCCESS',
  LOADING_USER_TRAINING_DOCS_SUCCESS: 'LOADING_USER_TRAINING_DOCS_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.LOADING_USER_DETAIL_SUCCESS:
    case messageTypes.UPDATING_USER_DATA_SUCCESS:
    case messageTypes.UPDATING_USER_TABLE_PREFERENCES_SUCCESS:
    case messageTypes.LOADING_USER_TRAINING_DOCS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
