import React, { useEffect, useState } from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Button } from '@lotus/components';
import LotusStickyNav from 'components/widgets/Forms/LotusStickyNav';
import PageSection from 'components/widgets/Layout/LotusPageSection';

import { combineStaticAndDynamicFormValues, combineStaticAndDynamicFormValidationSchema, combineFormElementsAndSections } from '../../utils/hybrid';
import { useClientForms } from 'contexts/ClientFormContext';

export default function HybridForm({
  title,
  staticFormDefinitionBuilder,
  staticFormSectionNameBuilder,
  staticFormValues,
  staticFormValidationSchema,
  dynamicFormDefinition,
  dynamicFormValues,
  clientId,
  programId,
  assessmentId,
  splitStaticAndDynamicFormValues,
  onSaveStaticFormValues,
  onClose,
  disabled
}) {

  const [initialValues, setInitialValues] = useState();
  const [fieldElements, setFieldElements] = useState();
  const [formTitle, setFormTitle] = useState();
  const [sections, setSections] = useState();
  const [formValidationSchema, setFormValidationSchema] = useState();
  const {saveClientForm} = useClientForms();

  useEffect(() => {
    setInitialValues(combineStaticAndDynamicFormValues(staticFormValues, dynamicFormValues?.data, dynamicFormDefinition?.data));
  }, [staticFormValues, dynamicFormDefinition, dynamicFormValues]);

  useEffect(() => {

    const {combinedFieldElements: fieldList, combinedSections: sectionList} = combineFormElementsAndSections({staticFormSectionNameBuilder, staticFormDefinitionBuilder, dynamicFormDefinition: dynamicFormDefinition?.data, disabled: disabled});
    
    setFieldElements(fieldList);
    setSections(sectionList);

    setFormValidationSchema(combineStaticAndDynamicFormValidationSchema(staticFormValidationSchema, dynamicFormDefinition?.data));

  }, [staticFormDefinitionBuilder, staticFormValidationSchema, staticFormSectionNameBuilder, dynamicFormDefinition]);

  useEffect(() => {
    if (title) {
      setFormTitle(title);
    }
  }, [title]);

  const doSave = async (newValues) => {
    let staticValues = newValues;
    let dynamicValues = newValues;
    if (splitStaticAndDynamicFormValues) {
      [staticValues, dynamicValues] = splitStaticAndDynamicFormValues(newValues);
    }
    if (dynamicFormDefinition) {
      await saveClientForm({
        clientFormId: dynamicFormValues?.clientFormId,
        clientId: clientId,
        formDefinition: dynamicFormDefinition,
        formData: dynamicValues,
        programId: programId,
        assessmentId: assessmentId
      });
    }
    if (staticFormDefinitionBuilder) {
      await onSaveStaticFormValues(staticValues);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={(values) => {
            let result = {};
            try {
              formValidationSchema.validateSync(values, { abortEarly: false });
            } catch (err) {
              result = yupToFormErrors(err);
            }
            return result;
          }}
          onSubmit={async (newValues, actions) => {
            await doSave(newValues);
            if (onClose) {
              onClose();
            }
          }}
        >
          {({values, handleSubmit, resetForm}) => {
            const handleCancel = () => {
              if (onClose) {
                onClose();
              }
              resetForm();
            }
            return values && (
              <Form className="form" onSubmit={handleSubmit} autoComplete="off" subscription={{ submitting: true, pristine: true }}>
                <PageSection 
                  header={formTitle} 
                  actions={
                    <>
                    {!disabled &&
                    <Button variant="text" onClick={() => {handleCancel();}}>
                      <Typography variant="h6">Cancel</Typography>
                    </Button>
                    }
                    {!disabled &&
                    <Button onClick={() => {handleSubmit(values);}}>
                      <Typography variant="h6">Save</Typography>
                    </Button>
                    }
                    </>
                  }
                >
                  <Grid container>
                    {sections && sections.length > 0 &&
                    <Grid item xs={3} container>
                      <LotusStickyNav linkData={sections} />
                    </Grid>
                    }
                    <Grid item xs={sections && sections.length > 0 ? 9 : 12} container style={{paddingTop: 20}}>
                      {fieldElements && fieldElements.map((element,idx) => {
                        return (
                        <Grid key={idx} item xs={12}>
                          {element}
                        </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </PageSection>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};
