export const messageTypes = {
  LOADING_AGENCY_CONNECTORS_SUCCESS: 'LOADING_AGENCY_CONNECTORS_SUCCESS',
  LOADING_AGENCY_INTEGRATIONS_SUCCESS: 'LOADING_AGENCY_INTEGRATIONS_SUCCESS',
  LOADING_AGENCY_CONNECTOR_DETAILS_SUCCESS: 'LOADING_AGENCY_CONNECTOR_DETAILS_SUCCESS',
  CREATING_AGENCY_INTEGRATION_SUCCESS: 'CREATING_AGENCY_INTEGRATION_SUCCESS',
  UPDATING_AGENCY_INTEGRATION_SUCCESS: 'UPDATING_AGENCY_INTEGRATION_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_AGENCY_INTEGRATIONS_SUCCESS:
    case messageTypes.LOADING_AGENCY_CONNECTORS_SUCCESS:
    case messageTypes.LOADING_AGENCY_CONNECTOR_DETAILS_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.CREATING_AGENCY_INTEGRATION_SUCCESS:
      const newIntegrations = state.integrations ? [...state.integrations, payload.integration] : undefined;
      return {
        ...state,
        integrations: newIntegrations
      };
    case messageTypes.UPDATING_AGENCY_INTEGRATION_SUCCESS:
      const updInts = state.integrations ? [...state.integrations.filter(i => i.arn !== payload.integration.arn), payload.integration] : undefined;
      return {
        ...state,
        integrations: updInts
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
