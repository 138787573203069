import { useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import moment from 'moment/moment';
import { useAppStatus } from 'contexts/AppStatusContext';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Button } from "@lotus/components";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from "lib/documents";

export const ClientDocumentUploader = ({
  documentTypes,
  clientId,
  handleCancel,
  handleSave,
}) => {
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [ thumbnails, setThumbnails ] = useState([]);
  const [ initialValues, setInitialValues ] = useState();

  useEffect(() => {
    setInitialValues({
      documentTypeId: undefined,
      comments: '',
      documentFiles: [],
      dateUploaded: moment().format('MM/DD/YYYY'),
      documentDate: null,
    });
  }, []);

  return (
      <Grid container>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={async (values) => {
            let result = {};
            if (!values.documentTypeId) {
              result.documentTypeId = 'Document type is required.';
            }
            if (values.documentFiles.length === 0) {
              result.documentFiles = 'Program document file(s) are required';
            }
            if (values.documentError) {
              result.documentError = values.documentError;
            }
            return result;
          }}
          onSubmit={async (newValues, actions) => {
            await handleSave(newValues);
            actions.setSubmitting(false);
          }}
        >
          {({ values, setFieldTouched, handleSubmit, setFieldValue }) => {
            return values && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                    <Grid item xs={6}>
                      <Typography variant="h2">Upload Document</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                        <Typography variant="h6">Cancel</Typography>
                      </Button>
                     <Button onClick={() => {handleSubmit(values);}}>
                        <Typography variant="h6">Upload</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: 24}}>
                    <LotusSelect
                      label="Document Type"
                      items={documentTypes.map((dt) => {return { label: dt.name, value: dt.id };})}
                      name="documentTypeId"
                      required
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: 24}}>
                    <LotusTextInput
                      multiline
                      name="comments"
                      rows={10}
                      label="Description"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{paddingBottom: 24}}>
                    <LotusDatePicker
                      label="Document Date"
                      name="documentDate"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LotusFileUploader
                      name="clientDocumentUploader"
                      clientId={clientId}
                      subfolderName="documents"
                      maxFiles={99}
                      onFileAdded={async (newFile, base64Str) => {
                        console.log('Added', newFile);
                        try {
                          addBusyBee('generatingThumbnail');

                          values.documentFiles.push(newFile);
                          setFieldTouched('documentFiles');
                          setFieldTouched('documentFilesError');
                          setFieldValue('documentFilesError', '');

                          const newFileThumbnails = await generateThumbnailsForUrl(base64Str,
                            newFile.type,
                            [100]);

                          const addThumbnails = async ({ thumbnails, newFileThumbnails }) => {
                            thumbnails.push(newFileThumbnails);
                          };


                          await addThumbnails({
                            thumbnails,
                            newFileThumbnails
                          });
                        }
                        catch (err) {
                          console.log(err);
                          setFieldTouched('documentFiles');
                          setFieldTouched('documentFilesError');
                          await setFieldValue('documentFiles', []);
                          setFieldValue(
                            'documentFilesError',
                            'An error occurred while generating thumbnail images for file'
                          );
                        } finally {
                          removeBusyBee('generatingThumbnail');
                        }
                      }}
                      onFileDeleted={(newFile) => {
                        console.log('Deleted', newFile);
                        const removeIndex = values.documentFiles.findIndex((file) => file.displayName === newFile.displayName);
                        if (removeIndex >= 0) {
                          values.documentFiles.splice(removeIndex, 1);
                          thumbnails.splice(removeIndex, 1);
                          setFieldTouched('documentFiles');
                        }
                      }}
                      onError={(err, action, msg) => {
                        console.log(err, msg);
                        setFieldTouched('documentFiles');
                        setFieldTouched('documentFilesError');
                        const errMsg = msg || 'An error occurred while processing the document';
                        if (action !== 'preview') {
                          setFieldValue('documentFiles', {});
                        }
                        setFieldValue('documentFilesError', errMsg);
                      }}
                    />
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="documentFiles" />
                    </div>
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="documentFilesError" />
                    </div>
                  </Grid>
                </Grid>
                <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {thumbnails && thumbnails.map((t,idx) => {
                    return (<img key={idx} alt='Thumbnail of documents' src={t} style={{maxWidth: 100, maxHeight: 100, marginRight: 10}}/>);
                  })}
                </div>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    ) || <div></div>
}