import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramMeetingForm({ handleSubmit }) {

  const [initialValues, setInitialValues] = useState();

  const { 
    currentProgram, 
    meetingTypes,
    meetingInviteeTypes,
    loadProgramMeetingPicklists,
  } = useProgramManagement();

  useEffect(() => {
    if (currentProgram && !meetingTypes) {
      loadProgramMeetingPicklists();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (currentProgram && meetingTypes && meetingInviteeTypes) {
      setInitialValues({
        currentMeetingTypeNames: meetingTypes.map((s) => {return {name: s.name, active: true} || []}),
        addedMeetingTypeName: '',
        currentMeetingInviteeTypeNames: meetingInviteeTypes.map((s) => {return {name: s.name, active: true} || []}),
        addedMeetingInviteeTypeName: '',
        meetingsEnabled: currentProgram.meetingConfig?.meetingsEnabled || false,
        meetingDisplayName: currentProgram.meetingConfig?.meetingDisplayName || '',
        timeSpentSchedulingMeetingEnabled: currentProgram.meetingConfig?.timeSpentSchedulingMeetingEnabled || false
      });
    }
  }, [currentProgram, meetingTypes, meetingInviteeTypes]);

  return (
    <LotusPageSection header="Meetings">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="meetingsEnabled"
                    label="Meetings Enabled"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                {values.meetingsEnabled && (
                  <>
                    <LotusFormItem>
                      <LotusTextInput
                        name="meetingDisplayName"
                        label="Meeting Display Name"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <GenericListLinker
                        name="currentMeetingTypeNames"
                        title="Meeting Types for Program"
                        itemName="Type"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                        <GenericListLinker
                          name="currentMeetingInviteeTypeNames"
                          title="Meeting Invitee Types for Program"
                          itemName="Invitee Type"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="timeSpentSchedulingMeetingEnabled"
                        label="Time Spent Scheduling Meeting Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                  </>
                )}
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
