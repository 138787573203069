export const messageTypes = {
  LOADING_FIELDS_SUCCESS: 'LOADING_FIELDS_SUCCESS',
  LOADING_FIELD_FILTERS_SUCCESS: 'LOADING_FIELD_FILTERS_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_FIELDS_SUCCESS:
      const totalPages =
        payload.fields.length > 0 ? parseInt(payload.fields[0].count) : 0;
      return {
        ...state,
        fields: [...payload.fields],
        fieldOffset: payload.offset,
        fieldLimit: payload.limit,
        totalFieldPages: totalPages
      };
    case messageTypes.LOADING_FIELD_FILTERS_SUCCESS:
      return {
        ...state,
        fieldFilters: payload.fieldFilters
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
