import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";
import LotusCheckboxList from "components/form/checkbox/LotusCheckboxList";
import LotusPhoneInput from "components/form/inputField/common/LotusPhoneInput";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusFormItemsRow from "components/form/LotusFormItemsRow";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSelect from "components/form/select/LotusSelect";
import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useProperties } from "contexts/PropertiesContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";

export default function SubgencyDetails() {

  const [logInProviders, setLogInProviders] = useState();
  const [currentPermissionTemplates, setCurrentPermissionTemplates] = useState();
  const [currentPrograms, setCurrentPrograms] = useState();

  const { properties } = useProperties();
  const {
    agency, updateAgencyOptionalFeatures,
    programs: subagencyPrograms, loadAgencyPrograms, updateAgencyPrograms,
    granteePermissionTemplates, loadGranteePermissionTemplates, updateAgencyPermissions } = useAgencyManagement();

  const { 
    userAgency, loadUserAgency,
    userGranteeAgencyPrograms, loadUserGranteeAgencyPrograms } = useUserAgency();

  useEffect(() => {
    if (!granteePermissionTemplates) {
      loadGranteePermissionTemplates();
    }
  }, []);

  useEffect(() => {
    if (agency && !subagencyPrograms) {
      loadAgencyPrograms();
    }
  }, [agency]);

  useEffect(() => {
    if (userAgency && !userGranteeAgencyPrograms) {
      loadUserGranteeAgencyPrograms();
    }
  }, [userAgency]);

  useEffect(() => {
    if(agency && granteePermissionTemplates) {
      setCurrentPermissionTemplates(
        granteePermissionTemplates.filter(p => p.agencies.find(a => a.id === agency.id)).map(p => p.id)
      );
    }
  }, [agency, granteePermissionTemplates]);

  useEffect(() => {
    if (subagencyPrograms) {
      setCurrentPrograms(subagencyPrograms.map(p => p.id));
    }
  }, [subagencyPrograms]);

  useEffect(() => {

    const providers = [];
    providers.push({ value: 'local', label: 'Application Managed' });
    if (properties) {
      const idps = JSON.parse(properties['REACT_APP_EXTERNAL_IDPS']);
      idps.forEach((idp) => {
        providers.push({ value: idp.ProviderName, label: idp.ProviderName + ' (External)' });
      });
    }
    setLogInProviders(providers);

  }, [properties]);

  const saveSubagencyDetails = async (values) => {
    await updateAgencyOptionalFeatures(values.optionalFeatures);
    await updateAgencyPrograms(values.selectedProgramIds);
    await updateAgencyPermissions(values.selectedPermissionTemplateIds);
    await loadUserAgency();
  };

  return (
    agency && logInProviders && userGranteeAgencyPrograms && subagencyPrograms && currentPermissionTemplates && granteePermissionTemplates && (
      <SubagencyDetailsView
        subagency={agency}
        logInProviders={logInProviders}
        subagencyProgramIds={currentPrograms}
        availablePrograms={userGranteeAgencyPrograms}
        subagencyPermissionTemplateIds={currentPermissionTemplates}
        availablePermissionTemplates={granteePermissionTemplates}
        handleSave={saveSubagencyDetails}
        handleCancel={() => {}} // staying on page, just reverting changes
      />
    )
  );
}

export function SubagencyDetailsView({
  subagency,
  logInProviders,
  subagencyProgramIds,
  availablePrograms,
  subagencyPermissionTemplateIds,
  availablePermissionTemplates,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (subagency && subagencyProgramIds && subagencyPermissionTemplateIds) {
      setInitialValues({
        id: subagency.id,
        name: subagency.name || '-',
        shortName: subagency.shortName || '-',
        mailingAddress: {
          address1: subagency.address.address1 || '-', 
          address2: subagency.address.address2 || '-', 
          city: subagency.address.city || '-', 
          state: subagency.address.state || '-', 
          zip: subagency.address.zip || '-',
          county: subagency.address.county || '-'
        },
        email: subagency.email,
        phoneNumber: subagency.phoneNumber,
        optionalFeatures: {
          summary: subagency.optionalFeatures?.summary || false,
          eligibility: subagency.optionalFeatures?.eligibility || false,
          idp: subagency.optionalFeatures?.idp || 'local',
          dashboard: subagency.optionalFeatures?.dashboard || false,
          subdomain: subagency.optionalFeatures?.subdomain || '',
        },
        selectedProgramIds: [...subagencyProgramIds],
        selectedPermissionTemplateIds: [...subagencyPermissionTemplateIds],
      });
    }
  }, [subagency, subagencyProgramIds, subagencyPermissionTemplateIds]);

  return initialValues && logInProviders && availablePrograms && availablePermissionTemplates && (
    <LotusStaticForm
      title="Agency Details"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 500}}>
        <LotusSubsectionHeader title="Basic Information" divider={false}/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusFormItemsRow>
              <LotusTextInput
                name="name"
                label="Agency Name"
                readOnly
              />
              <LotusTextInput
                name="shortName"
                label="Agency Short Name"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="email"
                label="Email Address"
                readOnly
              />
              <LotusPhoneInput
                name="phoneNumber"
                label="Phone Number"
                readOnly
              />
            </LotusFormItemsRow>
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Mailing Address"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.address1"
                label="Address"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.address2"
                label="Apt / Lot / Floor"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.city"
                label="City"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.state"
                label="State"
                readOnly
              />
            </LotusFormItemsRow>
            <LotusFormItemsRow>
              <LotusTextInput
                name="mailingAddress.zip"
                label="Zip Code"
                readOnly
              />
              <LotusTextInput
                name="mailingAddress.county"
                label="County"
                readOnly
              />
            </LotusFormItemsRow>
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Optional Features"/>
        <LotusSubsectionContent>
          <LotusStackedFormItems>
            <LotusSwitch
              name="optionalFeatures.summary"
              label="Summary Enabled"
            />
            <LotusSwitch
              name="optionalFeatures.eligibility"
              label="Eligibility Enabled"
            />
            <LotusSwitch
              name="optionalFeatures.dashboard"
              label="Dashboard Enabled"
            />
            {logInProviders && (
              <LotusSelect
                name="optionalFeatures.idp"
                label="Log In Provider"
                items={logInProviders}
                disallowNoSelectionIfNotRequired
              />
            )}
          </LotusStackedFormItems>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Programs"/>
        <LotusSubsectionContent>
          <LotusCheckboxList
            name='selectedProgramIds'
            items={availablePrograms.map(p => { return {label: p.name, value: p.id} })}
          />
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Permissions"/>
        <LotusSubsectionContent>
          <LotusCheckboxList
            name='selectedPermissionTemplateIds'
            items={availablePermissionTemplates.map(p => { return {
              label: `${p.name} (${p.agencies.find(a => a.id === subagency.id)?.userCount || 0} users)`, 
              value: p.id, 
              disabled: (parseInt(p.agencies.find(a => a.id === subagency.id)?.userCount || 0,10) > 0)
            }})}
          />
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
  );
}