import { Stack } from '@mui/material';
import React from 'react';

export default function LotusCardList({ children }) {

  return (
    <Stack direction="column" spacing={2}>
      {children && children.map((card, idx) => {
        return (
          <div key={idx}>
            {card}
          </div>
        );
      })}
    </Stack>
  );
}
