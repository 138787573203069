import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import { useFormikContext } from "formik";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

function TestResultsView() {
  
  const { values } = useFormikContext();

  return (
    <>
      <LotusSubsectionContent excludeTopPadding>
        <LotusSwitch
          name="enabled"
          value={values.enabled}
          label="Test Results Enabled"
          labelPlacement="start"
        />
      </LotusSubsectionContent>
      {values.enabled && (
        <>
          <LotusSubsectionHeader title='Basic Configuration Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="basicConfig.testTech"
                label="Test Technology Enabled"
              />
              <LotusSwitch
                name="basicConfig.testCompletedTime"
                label="Test Completed Time Enabled"
              />
              <LotusSwitch
                name="basicConfig.testStatus"
                label="Test Status Enabled"
              />
              <LotusSwitch
                name="basicConfig.testResultType"
                label="Test Result Type Enabled"
              />
              <LotusSwitch
                name="basicConfig.testResultText"
                label="Test Result Text Enabled"
              />
              <LotusSwitch
                name="basicConfig.testResultNumeric"
                label="Test Result Numeric Enabled"
              />
              <LotusSwitch
                name="basicConfig.testResultKeyword"
                label="Test Result Keyword Enabled"
              />
              <LotusSwitch
                name="basicConfig.testReferenceId"
                label="Test Reference ID Enabled"
              />
              <LotusSwitch
                name="basicConfig.testSource"
                label="Test Source Enabled"
              />
              <LotusSwitch
                name="basicConfig.testFacility"
                label="Test Facility Enabled"
              />
              <LotusSwitch
                name="basicConfig.testCompletedBy"
                label="Test Completed By Enabled"
              />
              <LotusSwitch
                name="basicConfig.normalcyStatus"
                label="Normalcy Status Enabled"
              />
              <LotusSwitch
                name="basicConfig.comments"
                label="Comments Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='HL7 Configuration Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="hl7Config.labIdentifiedDob"
                label="Lab Identified Date Of Birth Enabled"
              />
              <LotusSwitch
                name="hl7Config.labIdentifiedGender"
                label="Lab Identified Gender Enabled"
              />
              <LotusSwitch
                name="hl7Config.labIdentifiedLoinc"
                label="Lab Identified LOINC Enabled"
              />
              <LotusSwitch
                name="hl7Config.labIdentifiedLoincDesc"
                label="Lab Identified LOINC Description Enabled"
              />
              <LotusSwitch
                name="hl7Config.testCptCode"
                label="Test CPT Code Enabled"
              />
              <LotusSwitch
                name="hl7Config.referenceRange"
                label="Reference Range"
              />
              <LotusSwitch
                name="hl7Config.labPanelId"
                label="Lab Panel ID Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
        </>
      )}
    </>
  );
}

export function GranteeAgencyTestResultsView({
  testResultConfig,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if (testResultConfig) {
      const newInitialValues = {
        enabled: testResultConfig.enabled || false,
        basicConfig: {
          testTech: testResultConfig.basicConfig.testTech || false,
          testCompletedTime: testResultConfig.basicConfig.testCompletedTime || false,
          testStatus: testResultConfig.basicConfig.testStatus || false,
          testResultType: testResultConfig.basicConfig.testResultType || false,
          testResultText: testResultConfig.basicConfig.testResultText || false,
          testResultNumeric: testResultConfig.basicConfig.testResultNumeric || false,
          testResultKeyword: testResultConfig.basicConfig.testResultKeyword || false,
          testReferenceId: testResultConfig.basicConfig.testReferenceId || false,
          testSource: testResultConfig.basicConfig.testSource || false,
          testFacility: testResultConfig.basicConfig.testFacility || false,
          testCompletedBy: testResultConfig.basicConfig.testCompletedBy || false,
          normalcyStatus: testResultConfig.basicConfig.normalcyStatus || false,
          comments: testResultConfig.basicConfig.comments || false
        },
        hl7Config : {
          labIdentifiedDob: testResultConfig.hl7Config.labIdentifiedDob || false,
          labIdentifiedGender: testResultConfig.hl7Config.labIdentifiedGender || false,
          labIdentifiedLoinc: testResultConfig.hl7Config.labIdentifiedLoinc || false,
          labIdentifiedLoincDesc: testResultConfig.hl7Config.labIdentifiedLoincDesc || false,
          testCptCode: testResultConfig.hl7Config.testCptCode || false,
          referenceRange: testResultConfig.hl7Config.referenceRange || false,
          labPanelId: testResultConfig.hl7Config.labPanelId || false
        }
      };
      setInitialValues(newInitialValues);
    }
  }, [testResultConfig]);

  return initialValues && (
    <LotusStaticForm
      title="Test Result Configuration"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 500}}>
        <TestResultsView />
      </div>
    </LotusStaticForm>
  );
}