import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './DataDictionaryContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  fields: undefined,
};

const DataDictionaryContext = createContext();

export const DataDictionaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <DataDictionaryContext.Provider value={{ state, dispatch }}>{children}</DataDictionaryContext.Provider>;
};

export const useFieldSearch = () => {
  const { state, dispatch } = useContext(DataDictionaryContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadFields = async ({ agencyId, tableName, searchText, searchTextColumns, sortOrder }) => {
    let searchResult;
    try {
      addBusyBee('loadFields');
      const url = `/api/datadictionaries`;

      let operation;
      switch (tableName) {
        case 'testResultsTable':
          operation = 'searchTestResultsFields';
          break;
        case 'paidPremiumsTable':
          operation = 'searchPaidPremiumsFields';
          break;
        case 'dispensesTable':
          operation = 'searchDispensesFields';
          break;
        case 'agenciesTable':
          operation = 'searchAgenciesFields';
          break;
        case 'userAccountsTable':
          operation = 'searchUserAccountsFields';
          break;
        case 'clientDetailsTable':
          operation = 'searchClientDetailsFields';
          break;
        case 'clientAddressesTable':
          operation = 'searchClientAddressesFields';
          break;
        case 'clientContactsTable':
          operation = 'searchClientContactsFields';
          break;
        case 'clientIncomeTable':
          operation = 'searchClientIncomeFields';
          break;
        case 'clientHealthTable':
          operation = 'searchClientHealthFields';
          break;
        case 'clientInsuranceTable':
          operation = 'searchClientInsuranceFields';
          break;
        case 'clientEligibilityTable':
          operation = 'searchClientEligibilityFields';
          break;
        case 'clientEnrollmentTable':
          operation = 'searchClientEnrollmentFields';
          break;
        case 'healthPlanTable':
          operation = 'searchHealthPlanFields';
          break;
        case 'carrierPremiumTable':
          operation = 'searchCarrierPremiumFields';
          break;
        case 'applicationDataTable':
          operation = 'searchApplicatioDataFields';
          break;
        case 'comprehensiveClientDataTable':
          operation = 'searchComprehensiveClientFields';
          break;
        case 'cmsResponseDataTable':
          operation = 'searchCMSResponseFields';
          break;
        case 'cmsResponseExportDataTable':
          operation = 'searchCMSResponseExportFields';
          break;
        default:
          throw new Error(`Table ${tableName} not supported`);
      }

      const { data } = await axios.post(url, {
        operationName: operation,
        agencyId,
        searchText,
        searchFields: searchTextColumns,
        sortBy: sortOrder ? [sortOrder] : [],
      });
      searchResult = parseApiResult(data).body;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadFields');
    }
    
    dispatch({
      type: messageTypes.LOADING_FIELDS_SUCCESS,
      payload: {
        fields: searchResult.fields,
      },
    });
  };

  return {
    ...state,
    loadFields,
  };
};
