import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';
import { useClient } from 'contexts/ClientContext';
import TabbedSingleFormPanel from 'components/Forms/MultiTab/SingleForm/TabbedSingleFormPanel';
import LotusPage from 'components/widgets/Layout/LotusPage';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import maxBy from 'lodash/maxBy';

import { 
  buildApplicantStaticForm, 
  getApplicantStaticFormSchema, 
  getApplicantStaticFormSchemaExcludingRequiredChecks,
  buildApplicantStaticFormSectionNames, 
  initializeApplicantFormData,
  getApplicantFormData } from './EligibilityApplicationApplicantStaticForm';
import { 
  buildAddressesStaticForm, 
  getAddressesStaticFormSchema, 
  getAddressesStaticFormSchemaExcludingRequiredChecks,
  buildAddressesStaticFormSectionNames, 
  initializeAddressesFormData,
  getAddressesFormData } from './EligibilityApplicationAddressesStaticForm';
import { 
  buildIncomeStaticForm, 
  getIncomeStaticFormSchema, 
  getIncomeStaticFormSchemaExcludingRequiredChecks,
  buildIncomeStaticFormSectionNames, 
  initializeIncomeFormData,
  getIncomeFormData,
  adjustIncomeFormDataBeforeSave } from './EligibilityApplicationIncomeStaticForm';
import { 
  buildHealthStaticForm, 
  getHealthStaticFormSchema, 
  getHealthStaticFormSchemaExcludingRequiredChecks,
  buildHealthStaticFormSectionNames, 
  initializeHealthFormData,
  getHealthFormData } from './EligibilityApplicationHealthStaticForm';
import { 
  buildInsuranceStaticForm, 
  getInsuranceStaticFormSchema, 
  getInsuranceStaticFormSchemaExcludingRequiredChecks,
  buildInsuranceStaticFormSectionNames, 
  initializeInsuranceFormData,
  getInsuranceFormData } from './EligibilityApplicationInsuranceStaticForm';
import { 
  buildPersonalContactsStaticForm, 
  getPersonalContactsStaticFormSchema, 
  getPersonalContactsStaticFormSchemaExcludingRequiredChecks,
  buildPersonalContactsStaticFormSectionNames, 
  initializePersonalContactsFormData,
  getPersonalContactsFormData } from './EligibilityApplicationPersonalContactsStaticForm';
import { 
  buildConsentsStaticForm, 
  getConsentsStaticFormSchema, 
  getConsentsStaticFormSchemaExcludingRequiredChecks,
  buildConsentsStaticFormSectionNames, 
  initializeConsentsFormData,
  getConsentsFormData } from './EligibilityApplicationConsentsStaticForm';
import { 
  buildServicesStaticForm, 
  getServicesStaticFormSchema, 
  getServicesStaticFormSchemaExcludingRequiredChecks,
  buildServicesStaticFormSectionNames, 
  initializeServicesFormData,
  getServicesFormData,
  getSubprogramsFormData } from './EligibilityApplicationServicesStaticForm';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useLists } from 'contexts/ListsContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from "lib/permissionEnums";
import { userLeftUnresolvedComment } from './common';
import { determineRwEligibility } from 'lib/rwScorer';
import ActionDialog from 'components/Dialogs/ActionDialog';
import CaseManagerAssignment from './CaseManagerAssignment';

export default function EditEligibilityApplication() {

  const { clientId, id, tab } = useParams();
  const navigate = useNavigate();
  const { client, loadClient, clientConsents, loadClientConsents, clientCareTeam, loadClientCareTeam, upsertClientCareTeam} = useClient();
  const { userAgencyClientInfoConfig, userAgencyEligibilityConfig, userAgencyConsentTypes, loadUserAgencyConsentTypes,
    userAgencyClientInfoCustomLists, loadUserAgencyClientInfoCustomLists } = useUserAgency();
  const { eligibilityApplicationStatuses, loadEligibilityApplicationStatuses } = useLists();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const isAdapProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR);
  const isProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR) || verifyPermission(PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY) || verifyPermission(PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY);
  const isCompleter = verifyPermission(PERMISSIONS.APPLICATION_COMPLETER);
  const isSubmitterOnly = verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) && !verifyPermission(PERMISSIONS.APPLICATION_COMPLETER);

  const { 
    meta, loadEligibilityApplicationMetadata,
    applicant, loadEligibilityApplicationApplicant, upsertEligibilityApplicationApplicant,
    addresses, loadEligibilityApplicationAddresses, upsertEligibilityApplicationAddresses,
    income, loadEligibilityApplicationIncome, upsertEligibilityApplicationIncome,
    health, loadEligibilityApplicationHealth, upsertEligibilityApplicationHealth,
    insurance, loadEligibilityApplicationInsurance, upsertEligibilityApplicationInsurance,
    personalContacts, loadEligibilityApplicationPersonalContacts, upsertEligibilityApplicationPersonalContacts,
    consents, loadEligibilityApplicationConsents, upsertEligibilityApplicationConsents,
    subprograms, loadEligibilityApplicationSubprograms, upsertEligibilityApplicationSubprograms,
    services, loadEligibilityApplicationServices, upsertEligibilityApplicationServices,
    startEligibilityApplication, submitEligibilityApplication, completeEligibilityApplicationReview, pendEligibilityApplication
  } = useEligibilityApplication();

  const {birthGenders, unstableHousingTypes, hivDiseaseStages, loadClientInfoPicklists, eligibilityStartDateTypes, privateInsuranceSources,
    privateInsuranceStatuses, dentalInsuranceStatuses, visionInsuranceStatuses, medicareStatuses, medicarePartDStatuses, medicaidStatuses, medicareCoverageTypes } = useLists();

  const [staticSubforms, setStaticSubforms] = useState();
  const [eligibilityAppConfig, setEligibilityAppConfig] = useState();
  const [rwScoringConfig, setRwScoringConfig] = useState();
  const [existingClientConfig, setExistingClientConfig] = useState();
  const [dynamicSubformDefinitions] = useState([]);
  const [clientForms] = useState([]);
  const [appConsentTypeIds, setAppConsentTypeIds] = useState();
  const [requiredConsentTypes, setRequiredConsentTypes] = useState();
  const [caseManagerPopup, setCaseManagerPopup] = useState(false);
  const [leadAgency, setLeadAgency] = useState();
  const [leadCaseManager, setLeadCaseManager] = useState();
  const [buttonState, setButtonState] = useState();
  const leadCaseManagerPermission = verifyPermission(PERMISSIONS.CAN_BE_A_LEAD_CASE_MANAGER);

  useEffect(() => {
    if (!tab) {
      navigate(`/client/${clientId}/eligibility/application/${id}/applicant`);
    }
  }, []);

  useEffect(() => {
    if (userAgencyEligibilityConfig && userAgencyClientInfoConfig) {
      setEligibilityAppConfig(userAgencyEligibilityConfig.application || {});
      setExistingClientConfig(userAgencyClientInfoConfig.existingClients || {});
      setRwScoringConfig(userAgencyEligibilityConfig.rwScoring || {});
    }
  }, [userAgencyEligibilityConfig, userAgencyClientInfoConfig]);

  useEffect(() => {
    if (clientId && !client) {
      loadClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (!birthGenders) {
      loadClientInfoPicklists(); // Do this here so our child components have access to the lists rather than making their own calls
    }
    if (!eligibilityApplicationStatuses) {
      loadEligibilityApplicationStatuses();
    }
    if (!userAgencyConsentTypes) {
      loadUserAgencyConsentTypes();
    }
    if (!userAgencyClientInfoCustomLists) {
      loadUserAgencyClientInfoCustomLists();
    }
  }, []);

  useEffect(() => {
    if (clientId && id) {
      if (!meta) {
        loadEligibilityApplicationMetadata(clientId, id);
      }
      if (!applicant) {
        loadEligibilityApplicationApplicant(clientId, id);
      }
      if (!addresses) {
        loadEligibilityApplicationAddresses(clientId, id);
      }
      if (!income) {
        loadEligibilityApplicationIncome(clientId, id);
      }
      if (!health) {
        loadEligibilityApplicationHealth(clientId, id);
      }
      if (!insurance) {
        loadEligibilityApplicationInsurance(clientId, id);
      }
      if (!personalContacts) {
        loadEligibilityApplicationPersonalContacts(clientId, id);
      }
      if (!consents) {
        loadEligibilityApplicationConsents(clientId, id);
      }
      if (!subprograms) {
        loadEligibilityApplicationSubprograms(clientId, id);
      }
      if (isAdapProcessor && !services) {
        loadEligibilityApplicationServices(clientId, id);
      }
    }
  }, [clientId, id]);

  useEffect(() => {
    if (meta) {
      if (meta.isInterimUpdate) {
        setAppConsentTypeIds([]);
        setRequiredConsentTypes([]);
      } 
      else if (!clientConsents) {
        loadClientConsents(clientId);
      }
    }
  }, [meta]);

  useEffect(() => {
    if (clientConsents && userAgencyConsentTypes && eligibilityAppConfig && eligibilityStartDateTypes) {
      let eligConsentTypes = userAgencyConsentTypes.filter(ct => eligibilityAppConfig.requiredConsents?.includes(ct.id));
      setAppConsentTypeIds(eligConsentTypes.map(ct => ct.id));

       // NOTE: Similar logic also exists in api/lib/eligibility/update.js to determine when the expiration date of an app
       const getPicklistKey = (lst, id) => {
        return lst.find(v => v.id === id)?.itemKey;
      }

      const getConfiguredExpirationDateMoment = (birthDate, eligConfig, todayMoment) => {
        let expirationDateMoment = null;
        if (getPicklistKey(eligibilityStartDateTypes,eligConfig.selectedAnnualCoveragePeriodType) === 'date_of_processing') {
          expirationDateMoment = todayMoment.add(1, 'year');
        } 
        else if (getPicklistKey(eligibilityStartDateTypes,eligConfig.selectedAnnualCoveragePeriodType) === 'birth_date' || getPicklistKey(eligibilityStartDateTypes,eligConfig.selectedAnnualCoveragePeriodType) === 'pick_a_date') {
          let expMonth = null, expDay = null;
          if (getPicklistKey(eligibilityStartDateTypes,eligConfig.selectedAnnualCoveragePeriodType) === 'birth_date') {
            let birthDateMoment = moment(birthDate, 'MM/DD/YYYY');
            expMonth = birthDateMoment.month() + 1;
            expDay = birthDateMoment.date();
          } else if (getPicklistKey(eligibilityStartDateTypes,eligConfig.selectedAnnualCoveragePeriodType) === 'pick_a_date') {
            expMonth = Number(eligConfig.selectedEligibilityStartDateMonth);
            expDay = Number(eligConfig.selectedEligibilityStartDateDay);
          }
          
          expirationDateMoment = moment(`${expMonth}/${expDay}/${todayMoment.year()}`,'MM/DD/YYYY');
          if (expirationDateMoment <= todayMoment) {
            expirationDateMoment = expirationDateMoment.add(1, 'year');
          }
        }
    
        return expirationDateMoment;
      }

      const reqConsentTypes = []
      let todayMoment = moment();

      eligConsentTypes.forEach(ct => {
        if (!clientConsents.find(c => c.agencyConsentTypeId === ct.id)) {
          // Needs to complete consent, so required
          reqConsentTypes.push(ct);
          return
        }
        if (!ct.expirationMonths && clientConsents.find(c => c.agencyConsentTypeId === ct.id)) {
          // Already completed one-time consent, so not required
          return;
        }
        if (ct.expirationMonths && clientConsents.find(c => c.agencyConsentTypeId === ct.id)) {
          // Already completed a consent that expires, so check if the app will expire before recert
          let expirationDateMoment = getConfiguredExpirationDateMoment(client.birthDate, eligibilityAppConfig, todayMoment);
          if ((getPicklistKey(eligibilityStartDateTypes,eligibilityAppConfig.selectedAnnualCoveragePeriodType) === 'pick_a_date' ||
                getPicklistKey(eligibilityStartDateTypes,eligibilityAppConfig.selectedAnnualCoveragePeriodType) === 'birth_date') && 
                eligibilityAppConfig.selectedInitialExtensionType === 'extension_months') {
            let extMonths = Number(eligibilityAppConfig.selectedExtensionMonths || 0);
            if (todayMoment.add(extMonths,'month') > expirationDateMoment) {
              expirationDateMoment = expirationDateMoment.add(1, 'year');
            }
          }
          expirationDateMoment = expirationDateMoment.add(eligibilityAppConfig.selectedAutomaticCancellationPeriod, 'day');
          
          let completedConsentSigned = moment(maxBy(clientConsents.filter(c => c.agencyConsentTypeId === ct.id), (c) => moment(c.dateSigned,'MM/DD/YYYY')).dateSigned, 'MM/DD/YYYY');
          let completedConsentExpiration = completedConsentSigned.add(ct.expirationMonths, 'month');
          if (completedConsentExpiration < expirationDateMoment) {
            reqConsentTypes.push(ct);
          }
          console.log(`Consent ${ct.name} expires ${completedConsentExpiration.format('MM/DD/YYYY')} and elig app expires ${expirationDateMoment.format('MM/DD/YYYY')}`);
          return;
        }
      });
      setRequiredConsentTypes(reqConsentTypes);
    }
  }, [clientConsents, userAgencyConsentTypes, eligibilityAppConfig, eligibilityStartDateTypes]);


  useEffect(() => {
    // Dont put together the forms until we have all the data
    if (client && eligibilityAppConfig && unstableHousingTypes && meta && appConsentTypeIds && requiredConsentTypes &&
        applicant && addresses && income && health && insurance && personalContacts && consents && subprograms && (services || !isAdapProcessor)) {
      const staticForms = {
        'Applicant' : {
          componentBuilder: (disabled) => buildApplicantStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, rwScoringConfig: rwScoringConfig}),
          schema: getApplicantStaticFormSchema(eligibilityAppConfig),
          schemaExcludingRequiredChecks: getApplicantStaticFormSchemaExcludingRequiredChecks(eligibilityAppConfig),
          sectionNameBuilder: () => buildApplicantStaticFormSectionNames(eligibilityAppConfig),
          values: initializeApplicantFormData(applicant),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasApplicantUpdates)
        },
        'Addresses' : {
          componentBuilder: (disabled) => buildAddressesStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, rwScoringConfig: rwScoringConfig, isInterimUpdate: meta.isInterimUpdate}),
          schema: getAddressesStaticFormSchema(unstableHousingTypes.find(x => x.itemKey === 'jail').id),
          schemaExcludingRequiredChecks: getAddressesStaticFormSchemaExcludingRequiredChecks(),
          sectionNameBuilder: () => buildAddressesStaticFormSectionNames(eligibilityAppConfig),
          values: initializeAddressesFormData(addresses, client?.name),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasAddressesUpdates)
        },
        'Personal Contacts' : {
          componentBuilder: (disabled) => buildPersonalContactsStaticForm({disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig}),
          schema: getPersonalContactsStaticFormSchema(),
          schemaExcludingRequiredChecks: getPersonalContactsStaticFormSchemaExcludingRequiredChecks(),
          sectionNameBuilder: () => buildPersonalContactsStaticFormSectionNames(existingClientConfig),
          values: initializePersonalContactsFormData(personalContacts),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasPersonalContactsUpdates)
        },
        'Income' : {
          componentBuilder: (disabled) => buildIncomeStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, rwScoringConfig: rwScoringConfig}),
          schema: getIncomeStaticFormSchema(existingClientConfig),
          schemaExcludingRequiredChecks: getIncomeStaticFormSchemaExcludingRequiredChecks(existingClientConfig),
          sectionNameBuilder: () => buildIncomeStaticFormSectionNames(eligibilityAppConfig),
          values: initializeIncomeFormData(income),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasIncomeUpdates)
        },
        'Health' : {
          componentBuilder: (disabled) => buildHealthStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, meta: meta, rwScoringConfig: rwScoringConfig}),
          schema: getHealthStaticFormSchema(eligibilityAppConfig, hivDiseaseStages, meta),
          schemaExcludingRequiredChecks: getHealthStaticFormSchemaExcludingRequiredChecks(existingClientConfig),
          sectionNameBuilder: () => buildHealthStaticFormSectionNames(eligibilityAppConfig, meta),
          values: initializeHealthFormData(health),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasHealthUpdates)
        },
        'Insurance' : {
          componentBuilder: (disabled) => buildInsuranceStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig}),
          schema: getInsuranceStaticFormSchema(privateInsuranceStatuses, dentalInsuranceStatuses, visionInsuranceStatuses, medicareStatuses, medicarePartDStatuses, medicaidStatuses, privateInsuranceSources, existingClientConfig, medicareCoverageTypes),
          schemaExcludingRequiredChecks: getInsuranceStaticFormSchemaExcludingRequiredChecks(),
          sectionNameBuilder: () => buildInsuranceStaticFormSectionNames(existingClientConfig),
          values: initializeInsuranceFormData(insurance),
          hide: (meta.isInterimUpdate && !meta.interimUpdateHasInsuranceUpdates)
        },
        'Consents' : {
          componentBuilder: (disabled) => buildConsentsStaticForm({disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, requiredConsentTypes: requiredConsentTypes, appConsentTypeIds: appConsentTypeIds}),
          schema: getConsentsStaticFormSchema(requiredConsentTypes),
          schemaExcludingRequiredChecks: getConsentsStaticFormSchemaExcludingRequiredChecks(),
          sectionNameBuilder: () => buildConsentsStaticFormSectionNames(existingClientConfig),
          values: initializeConsentsFormData(consents),
          hide: ((meta.isInterimUpdate && !meta.interimUpdateHasConsentsUpdates) || appConsentTypeIds.length === 0),
        },
        'Services Authorization' : {
          componentBuilder: (disabled) => buildServicesStaticForm({clientId: clientId, disabled: disabled, eligibilityAppConfig: eligibilityAppConfig, existingClientConfig: existingClientConfig, isInterimUpdate: meta.isInterimUpdate}),
          schema: getServicesStaticFormSchema(eligibilityAppConfig, loggedInUser, isAdapProcessor, client?.isGrandfathered),
          schemaExcludingRequiredChecks: getServicesStaticFormSchemaExcludingRequiredChecks(eligibilityAppConfig),
          sectionNameBuilder: () => buildServicesStaticFormSectionNames(eligibilityAppConfig),
          values: initializeServicesFormData({...subprograms, ...services}, meta)
        },
      };
      setStaticSubforms(staticForms);
    }
  }, [client, meta, applicant, addresses, income, health, insurance, personalContacts, consents, appConsentTypeIds, requiredConsentTypes, subprograms, services, eligibilityAppConfig, unstableHousingTypes, privateInsuranceStatuses, dentalInsuranceStatuses, visionInsuranceStatuses, medicareStatuses, medicarePartDStatuses]);

  useEffect(() => {
    if(!clientCareTeam && client && loggedInUser && client.leadCaseManagerId !== loggedInUser.id) {
      loadClientCareTeam(clientId);
    }
  }, [client, loggedInUser]);

  useEffect(() => {
    if(clientCareTeam) {
      setLeadAgency(clientCareTeam?.careTeam?.agencyName);
      setLeadCaseManager(clientCareTeam?.careTeam?.caseManager?.name);
    }
  }, [clientCareTeam])

  const saveApplication = async (formData) => {
    let applicantData = getApplicantFormData(formData);
    await upsertEligibilityApplicationApplicant(clientId, id, applicantData);

    let addressesData = getAddressesFormData(formData);
    await upsertEligibilityApplicationAddresses(clientId, id, addressesData);

    let incomeData = getIncomeFormData(formData);
    incomeData = adjustIncomeFormDataBeforeSave(incomeData);
    await upsertEligibilityApplicationIncome(clientId, id, incomeData);

    let healthData = getHealthFormData(formData);
    await upsertEligibilityApplicationHealth(clientId, id, healthData);

    let insuranceData = getInsuranceFormData(formData);
    await upsertEligibilityApplicationInsurance(clientId, id, insuranceData);

    let personalContactsData = getPersonalContactsFormData(formData);
    await upsertEligibilityApplicationPersonalContacts(clientId, id, personalContactsData);

    let consentsData = getConsentsFormData(formData);
    await upsertEligibilityApplicationConsents(clientId, id, consentsData);

    let subprogramsData = getSubprogramsFormData(formData, subprograms, eligibilityAppConfig);
    await upsertEligibilityApplicationSubprograms(clientId, id, subprogramsData);

    if (isAdapProcessor) {
      let servicesData = getServicesFormData(formData, services);
      await upsertEligibilityApplicationServices(clientId, id, servicesData);
    }

    if (meta.statusId === eligibilityApplicationStatuses.find(s => s.key === 'not_started').id) {
      await startEligibilityApplication(clientId, id);
    }
  }

  const checkLeadCaseManager = (values, buttonPressed) => {
    setButtonState(buttonPressed);
    // Checking the user is client's lead case manager
    if(client && client.leadCaseManagerId !== loggedInUser.id && clientCareTeam && (buttonPressed === 'completeApplication' || buttonPressed === 'submitApplication') && leadCaseManagerPermission) {
      // prompt to change the lead case manager
      setCaseManagerPopup(true);
    } else {
      if (buttonPressed === 'completeApplication') {
        completeApplicationReview();
      } else if (buttonPressed === 'submitApplication') {
        submitApplication();
      }
    }
  }

  const assignLeadCaseManager = async (userOption) => {
    setCaseManagerPopup(false);
    if (userOption === 'confirm') {
      await upsertClientCareTeam(clientId, {
        leadAgency: loggedInUser?.agencyId,
        leadCaseManager:loggedInUser.id
      });
    }
    if (buttonState === 'completeApplication') {
      completeApplicationReview();
    } else if (buttonState === 'submitApplication') {
      submitApplication();
    }
  }
  
  const submitApplication = async () => {
    await submitEligibilityApplication(clientId, id);

    await navigate(`/client/${clientId}/eligibility`);
  }

  const completeApplicationReview = async () => {
    await completeEligibilityApplicationReview(clientId, id);

    // After review completes, client name may have been changed and synced back to client info
    await loadClient(clientId);

    await navigate(`/client/${clientId}/eligibility`);
  }

  const pendApplication = async () => {
    await pendEligibilityApplication(clientId, id);

    await navigate(`/client/${clientId}/eligibility`);
  }

  const onInitialValuesSet = (values) => {
    values.isGrandfathered = client?.isGrandfathered !== null && client?.isGrandfathered !== '' && client?.isGrandfathered !== undefined ? client?.isGrandfathered : false;
    let result = determineRwEligibility(values, rwScoringConfig, existingClientConfig);
    values.rwIncomeIneligible = !result.incomeEligible;
    values.rwResidencyIneligible = !result.residencyEligible;
    values.rwHivStatusIneligible = !result.hivStatusEligible;
    values.rwEligible = result.rwEligible;
  }

  return meta && client && tab && applicant && addresses && income && health && insurance && personalContacts && subprograms && (services || !isAdapProcessor) && staticSubforms && eligibilityApplicationStatuses && eligibilityAppConfig && (
    <>
      {/* Stuff to disable the rest of the page so user can only interact with the application */}
      <div style={{width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, opacity: 0.4, background: '#000000', zIndex: 2}}></div>
      <div style={{background: '#ffffff', opacity: 1, zIndex: 10, position: 'relative'}}>
        <LotusPage>
          <TabbedSingleFormPanel
            formTitle={client.name}
            formSubTitle={<><span style={{marginRight: 10}}>Status: </span><span style={{fontWeight: 700}}>{meta.statusName}</span></>}
            formTabsHeader={meta.isInterimUpdate ? 'Update' : 'Eligibility Application'}
            selectedTab={tab}
            staticSubforms={staticSubforms}
            dynamicSubformDefinitions={dynamicSubformDefinitions}
            clientForms={clientForms}
            linkPathPrefix={`/client/${clientId}/eligibility/application/${id}`}
            onSave={(values) => saveApplication(values)}
            onClose={() => navigate(`/client/${clientId}/eligibility`)}
            allowEdit={meta.statusId !== eligibilityApplicationStatuses.find(s => s.key === 'submitted').id}
            runInitialFullValidation={!eligibilityApplicationStatuses.filter(s => s.key === 'not_started' || s.key === 'started').map(s => s.id).includes(meta.statusId)}
            buttonConfig={[
              {isCancel: true, label: 'Cancel', name: 'cancel', disableIfNotDirty: false, hide: () => eligibilityApplicationStatuses.filter(s => s.key === 'completed' || s.key === 'terminated').map(s => s.id).includes(meta.statusId)},
              {isSave: true, label: 'Save', name: 'save', validationType: 'saveOnly', disableIfNotDirty: false, hide: () => eligibilityApplicationStatuses.filter(s => s.key === 'completed' || s.key === 'terminated').map(s => s.id).includes(meta.statusId)},
              {isSave: true, label: 'Submit Application', name: 'submitApplication', validationType: 'submit', afterSave: checkLeadCaseManager, hide: () => !isSubmitterOnly || !eligibilityApplicationStatuses.filter(s => s.key === 'not_started' || s.key === 'started' || s.key === 'pending').map(s => s.id).includes(meta.statusId)},
              {isSave: true, label: 'Complete Review', name: 'completeReview', validationType: 'complete', afterSave: completeApplicationReview, hide: (values) => !isProcessor || userLeftUnresolvedComment(values, loggedInUser) || !eligibilityApplicationStatuses.filter(s => s.key === 'in_review').map(s => s.id).includes(meta.statusId)},
              {isSave: true, label: 'Complete Application', name: 'completeApplication', validationType: 'complete', afterSave: checkLeadCaseManager, hide: (values) => !isCompleter || userLeftUnresolvedComment(values, loggedInUser) || !eligibilityApplicationStatuses.filter(s => s.key === 'not_started' || s.key === 'started' || s.key === 'pending').map(s => s.id).includes(meta.statusId)},
              {isSave: true, label: 'Mark Pending', name: 'markPending', validationType: 'pend', afterSave: pendApplication, hide: (values) => !isProcessor || !userLeftUnresolvedComment(values, loggedInUser) || !eligibilityApplicationStatuses.filter(s => s.key === 'in_review').map(s => s.id).includes(meta.statusId)},
            ]}
            onInitialValuesSet={onInitialValuesSet}
          />

          <ActionDialog
            maxWidth="sm"
            open={caseManagerPopup}
            content={
              <CaseManagerAssignment
                leadAgency={leadAgency}
                leadCaseManager={leadCaseManager}
                handleCancel={() => assignLeadCaseManager("skip")}
                handleConfirm={() => assignLeadCaseManager("confirm")}
              />
            }
          />
        </LotusPage>
      </div>
    </>
  );
};