import LotusButton from 'components/widgets/Forms/LotusButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cycleTimeIcon from 'components/icons/cycleTimeIcon.png';
import processorProductivityIcon from 'components/icons/processorProductivityIcon.png';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import { Stack } from '@mui/material';
import { METRIC_CATEGORIES } from 'lib/metricCategoryEnum';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function Index() {

  const navigate = useNavigate();
  const {verifyPermission} = useAuthState();

  return (
    <LotusPage>
      <LotusPageTitle title="Dashboards"/>
      <Stack spacing={5} style={{marginTop: 24}}>
        <Stack direction="row" spacing={5}>
          <LotusButton onClick={() => {navigate(`/dashboard/${METRIC_CATEGORIES.CYCLE_TIME}`)}} variant="text" style={{boxShadow: "1px 1px 1px 1px grey"}}>
            <div>
              <div><img src={cycleTimeIcon}/></div>
              <div>Cycle Time</div>
            </div>
          </LotusButton>
          {verifyPermission(PERMISSIONS.VIEW_PROCESSOR_PRODUCTIVITY_DASHBOARD) &&
            <LotusButton onClick={() => {navigate(`/dashboard/${METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY}`)}} variant="text" style={{boxShadow: "1px 1px 1px 1px grey"}}>
              <div>
                <div><img src={processorProductivityIcon}/></div>
                <div>Processor Productivity</div>
              </div>
            </LotusButton>
          }
        </Stack>
        <Stack direction="row" spacing={5}>
        </Stack>
      </Stack>
    </LotusPage>
  );
}
