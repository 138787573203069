import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Create';
import Typography from '@mui/material/Typography';
import UserForm from './forms/UserForm';
import EditUserConfirmPanel from './EditUserConfirmPanel';
import MFAResetConfirmPanel from './MFAResetConfirmPanel';
import MFAResetErrorPanel from './MFAResetErrorPanel';
import PasswordResetConfirmPanel from './PasswordResetConfirmPanel';
import PasswordResetErrorPanel from './PasswordResetErrorPanel';
import { useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useUserManagement } from 'contexts/UserManagementContext';
import { useProperties } from 'contexts/PropertiesContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import MFAResetPanel from './MFAResetPanel';
import ResetPasswordPanel from './ResetPasswordPanel';
import ActionDialog from 'components/Dialogs/ActionDialog';
import AddUserConfirmPanel from './AddUserConfirmPanel';
import { useUserRequests } from 'contexts/UserRequestsContext';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';

export default function EditUserPanel({ handleBackClicked }) {
  const { id } = useParams();
  const { properties } = useProperties();
  const { userAgency } = useUserAgency();
  const [savedUser, setSavedUser] = useState();
  const { verifyPermission } = useAuthState();
  const [showResetMFA, setShowResetMFA] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetMFAError, setShowResetMFAError] = useState(false);
  const [showResetPasswordError, setShowResetPasswordError] = useState(false);
  const hasEditAccountPermission = verifyPermission(PERMISSIONS.EDIT_ACCOUNT);
  const hasResetMFAPermission = verifyPermission(PERMISSIONS.RESET_MFA);
  const hasRequestAccountPermission = verifyPermission(PERMISSIONS.REQUEST_NEW_ACCOUNT);
  const { userDetail, userPermissions, loadUserDetail, adminResetMFA, adminResetPassword, disableUser, deleteUser } = useUserManagement();
  const [editMode, setEditMode] = useState(false);
  const [mfaReset, setMfaReset] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState();
  const [statusKey, setStatusKey] = useState();
  const [confirmUserAction, setConfirmUserAction] = useState(false);
  const [userAction, setUserAction] = useState();
  const [editEnabled, setEditEnabled] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const { createRequestToReactivateUser } = useUserRequests();
  const { agency, loadAgency } = useAgencyManagement();
  const [idp, setIdp] = useState('local');

  useEffect(() => {
    if (properties) {
      const mfaCfg = properties['REACT_APP_MFA_CONFIGURATION'];
      setMfaEnabled(mfaCfg && mfaCfg === 'ON');
    }
  }, [properties]);


  const showConfirmPanel = (user) => {
    setSavedUser(user);
  };

  const showMFAResetPanel = async (user) => {
    if (await adminResetMFA(userDetail.id, userDetail.email)) {
      setMfaReset(true);
    }
    else {
      setShowResetMFAError(true);
    }
    setSavedUser(user);
    setShowResetMFA(false);
  };

  const showPasswordResetPanel = async (user) => {
    if (await adminResetPassword(userDetail.id, userDetail.email)) {
      setPasswordReset(true);
    }
    else {
      setShowResetPasswordError(true);
    }
    setSavedUser(user);
    setShowResetPassword(false);
  };

  const handleResetMFAClicked = async () => {
    setShowResetMFA(true);
  };

  const handleResetPasswordClicked = async () => {
    setShowResetPassword(true);
  };

  const handleCancelResetMFAClicked = async () => {
    setShowResetMFA(false);
  };

  const handleCancelResetPasswordClicked = async () => {
    setShowResetPassword(false);
  };

  useEffect(() => {
    if (id) {
      loadUserDetail(id);
    }
  }, [id]);

  useEffect(() => {
    setIdp('local');
    if (userDetail) {
      setStatusKey(userDetail?.statusKey);
      loadAgency(userDetail.agencyId);
    }
    if (userDetail?.statusKey === 'active') {
      setEditEnabled(true);
    } else {
      setEditEnabled(false);
    }
  }, [userDetail]);

  useEffect(() => {
    setIdp('local');
    if (agency) {
      if (agency?.optionalFeatures?.idp) {
        setIdp(agency.optionalFeatures.idp);
      }
    }
  }, [agency]);


  const handleDeleteUser = () => {
    setConfirmUserAction(true);
    setUserAction('delete');
  };

  const handleDeactivateUser = () => {
    setConfirmUserAction(true);
    setUserAction('deactivate');
  };

  const handleActivateUser = () => {
    setConfirmUserAction(true);
    setUserAction('reactivate');
  };

  const handleCancelUserAction = () => {
    setConfirmUserAction(false);
    setUserAction(false);
  }

  const handleConfirmUserAction = async () => {
    switch (userAction) {
      case 'reactivate':
        // reactivate the user
        if (userDetail && userPermissions) {
          await createRequestToReactivateUser({
            userId: userDetail.id,
            permissionTemplateIds: userDetail.permissionTemplates.map(p => p.id),
            programPermissions: userPermissions.permissionList
              .filter((pp) => pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA || pp.permissionKey === PERMISSIONS.EDIT_CLIENT_PROGRAM_DATA)
              .map((pp) => {
                return {
                  programId: pp.programId,
                  access: pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA ? 'read' : 'edit'
                }
              })
          });
        }
        setInviteSent(true);
        break;
      case 'deactivate':
        // deactivate the user
        await disableUser(userDetail.id, userDetail.email);
        break;
      case 'delete':
        // delete the user
        await deleteUser(userDetail.id, userDetail.email);
        handleBackClicked();
        break;
      default:
        break;
    }
    setConfirmUserAction(false);
    setUserAction(false);
  }

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton onClick={handleBackClicked} variant='outlined'>Back</LotusButton>
        {!inviteSent && !mfaReset && !showResetMFAError && !passwordReset && !showResetPasswordError && statusKey !== 'pending' && <>
          <LotusButton
            style={{ float: 'right', cursor: hasRequestAccountPermission ? 'pointer' : 'default' }}
            color='error'
            variant='contained'
            disabled={!hasRequestAccountPermission}
            onClick={() => hasRequestAccountPermission && handleDeleteUser()}>Delete
          </LotusButton>
          {statusKey === 'active' &&
            <LotusButton
              style={{ float: 'right', marginRight: 10, cursor: hasRequestAccountPermission ? 'pointer' : 'default' }}
              color='error'
              variant='contained'
              disabled={!hasRequestAccountPermission}
              onClick={() => hasRequestAccountPermission && handleDeactivateUser()}>Deactivate
            </LotusButton>
          }
          {statusKey === 'inactive' &&
            <LotusButton
              style={{ float: 'right', marginRight: 10, cursor: hasRequestAccountPermission ? 'pointer' : 'default' }}
              variant='contained'
              disabled={!hasRequestAccountPermission}
              onClick={() => hasRequestAccountPermission && handleActivateUser()}>Activate
            </LotusButton>
          }
        </>}
      </LotusSpacedBlock>
      {!inviteSent && <>
        {!savedUser && userDetail && (
          <LotusPageSection header={`Edit User: ${userDetail.data?.firstName} ${userDetail.data?.lastName}`}>
            <LotusSpacedBlock>
              <LotusPaper>
                <LotusSpacedBlock>
                  <Typography variant="body`">
                    <b>First Name:</b> {userDetail.data?.firstName}
                  </Typography>
                </LotusSpacedBlock>
                <LotusSpacedBlock>
                  <Typography variant="body`">
                    <b>Last Name:</b> {userDetail.data?.lastName}
                  </Typography>
                </LotusSpacedBlock>
                <div>
                  <Typography variant="body`">
                    <b>Email:</b> {userDetail.email}
                  </Typography>
                </div>
              </LotusPaper>
            </LotusSpacedBlock>
            <LotusPaper>
              {!editMode && (
                <>
                  <LotusSpacedBlock>
                    <Typography variant="h5">
                      Permission Templates:
                      <EditIcon
                        style={{ float: 'right', cursor: (editEnabled && hasEditAccountPermission) ? 'pointer' : 'default' }}
                        color={(editEnabled && hasEditAccountPermission) ? 'primary' : 'disabled'}
                        onClick={() => editEnabled && hasEditAccountPermission && setEditMode(true)}
                      />
                    </Typography>
                  </LotusSpacedBlock>
                  <LotusSpacedBlock>
                    {userDetail.permissionTemplates
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((accountTemplate) => {
                        return (
                          <Typography key={`accountTemplate-${accountTemplate.id}`} variant="h6">
                            {accountTemplate.name}
                          </Typography>
                        );
                      })}
                  </LotusSpacedBlock>
                  <LotusSpacedBlock>
                    <Typography variant="h5">Program Permissions:</Typography>
                  </LotusSpacedBlock>
                  <div>
                    {userPermissions.permissionList
                      .filter((pp) => {
                        if (pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA && pp.enabled) {
                          if (!userPermissions.permissionList.find(ppp => ppp.permissionKey === PERMISSIONS.EDIT_CLIENT_PROGRAM_DATA && ppp.programId === pp.programId && ppp.enabled)) {
                            return true;
                          }
                        }
                        if (pp.permissionKey === PERMISSIONS.EDIT_CLIENT_PROGRAM_DATA && pp.enabled) {
                          return true;
                        }
                        return false;
                      })
                      .map((pp) => {
                        return {
                          programId: pp.programId,
                          programName: pp.programName,
                          access: pp.permissionKey === PERMISSIONS.READ_CLIENT_PROGRAM_DATA ? 'read' : 'edit'
                        }
                      })
                      .sort((a, b) => a.programName > b.programName ? 1 : -1)
                      .map((pp) => {
                        return (
                          <Typography key={`programPermission-${pp.programId}`} variant="h6">
                            {pp.programName}:{' '}
                            {pp.access === 'edit' ? 'Edit' : 'Read'}
                          </Typography>
                        );
                      })}
                  </div>
                </>
              )}
              {editMode && (
                <UserForm
                  afterSave={showConfirmPanel}
                  userDetail={userDetail}
                  userPermissions={userPermissions}
                />
              )}
            </LotusPaper>

            <div>&nbsp;</div>

            <MFAResetPanel hasResetMFAPermission={editEnabled && hasResetMFAPermission} mfaEnabled={mfaEnabled && userDetail.last_login_date && idp === 'local'} userAgency={userAgency} showResetMFA={showResetMFA} resetMFAClicked={handleResetMFAClicked} okClicked={showMFAResetPanel} cancelClicked={handleCancelResetMFAClicked}
            />

            <ResetPasswordPanel hasRequestAccountPermission={editEnabled && hasRequestAccountPermission && idp === 'local'} lastLogin={userDetail.last_login_date} showResetPassword={showResetPassword} resetPasswordClicked={handleResetPasswordClicked} okClicked={showPasswordResetPanel} cancelClicked={handleCancelResetPasswordClicked}
            />

          </LotusPageSection>
        )}

        {(savedUser && !mfaReset && !showResetMFAError && !passwordReset && !showResetPasswordError) && (
          <EditUserConfirmPanel email={savedUser.email} />
        )}
        {mfaReset && (
          <MFAResetConfirmPanel />
        )}
        {showResetMFAError && (
          <MFAResetErrorPanel />
        )}
        {passwordReset && (
          <PasswordResetConfirmPanel />
        )}
        {showResetPasswordError && (
          <PasswordResetErrorPanel />
        )}
        <ActionDialog
          maxWidth="sm"
          open={confirmUserAction}
          content={
            <>
              {userAction !== 'delete' &&
                <Typography variant='body1'>Are you sure you want to {userAction} this user account?</Typography>}
              {userAction === 'delete' &&
                <Typography variant='body1'>Deleting this user will permanently disable the user from logging in with this account. A user can be reinvited using this email after the account has been deleted.</Typography>}
            </>
          }
          actions={
            <div>
              <LotusButton
                onClick={() => handleConfirmUserAction()}>
                {userAction === 'reactivate' ? 'Submit Request' : 'Confirm'}
              </LotusButton>
              <LotusButton onClick={handleCancelUserAction} variant='outlined'>
                Cancel
              </LotusButton>
            </div>
          }
        />
      </>}
      {inviteSent && (
        <AddUserConfirmPanel email={userDetail.email} newUser={false} />
      )}
    </LotusPaper>
  );
}
