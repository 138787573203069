import React, { useEffect, useState } from 'react';
import { useProperties } from 'contexts/PropertiesContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import Typography from '@mui/material/Typography';
import { Box, List, ListItem } from "@mui/material";
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';

export default function EnterNewPasswordForm({
  values,
  handleChange,
  touched,
  errors
}) {
  const { properties } = useProperties();
  const [props, setProps] = useState();

  useEffect(() => {
    if (properties) {
      setProps(JSON.parse(properties['REACT_APP_PASSWORD_POLICY']));
    }

  }, [properties]);

  return (
    <>
      {values.operation === 'enterNewPassword' && (
        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="left"
          alignItems="left"
        >
          <LotusSpacer />
          <Typography style={{ width: 300, fontSize: 20, fontWeight: 500 }}>Create a Password</Typography>
          <div style={{ padding: "10px" }} />
          <Typography style={{ width: 340, fontSize: 16 }}>Your password requires the following: </Typography>
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
            }}
          >
            <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '0px 0px' }}>
              At least {props?.MinimumLength} characters
            </ListItem>

            {props?.RequireNumbers && (
              <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '2px 0px' }}>At least 1 number</ListItem>
            )}

            {props?.RequireSymbols && (
              <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '2px 0px' }}>At least 1 special character</ListItem>
            )}
            {props?.RequireUppercase && (
              <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '2px 0px' }}>At least 1 uppercase letter</ListItem>
            )}
            {props?.RequireLowercase && (
              <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '2px 0px' }}>At least 1 lowercase letter</ListItem>
            )}
          </List>
          <LotusSpacer /><LotusSpacer /><LotusSpacer />
          <LotusTextInput
            autoComplete="off"
            id="newPassword"
            name="newPassword"
            label="New Password"
            required
            type="password"
            value={values.newPassword}
            style={{ width: 340 }}
            onChange={handleChange}
            error={touched.newPassword && Boolean(errors.newPassword)}
          />
          <LotusSpacer /><LotusSpacer /><LotusSpacer />
          <LotusTextInput
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            required
            value={values.confirmPassword}
            style={{ width: 340 }}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
          />
          {(values.operation === 'enterNewPassword' && values.errorMsg) && (
            <Typography variant="body" color='#b53f3f' style={{ width: 340, fontSize: 16 }}>{values.errorMsg}</Typography>
          )}

          <LotusSpacer /><LotusSpacer />
          <LotusButton style={{ width: "340px", height: '42px', fontWeight: 500, fontSize: 15 }} type="submit"
            disabled={!Boolean(values.newPassword) || values.newPassword !== values.confirmPassword}>
            CREATE PASSWORD
          </LotusButton>
          <LotusSpacer />
        </Box>
      )}
    </>
  );
};
