import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProgramsList from './components/ProgramsList';
import AddProgramPanel from './components/AddProgramPanel';
import EditProgramPanel from './components/EditProgramPanel';
import LotusPage from 'components/widgets/Layout/LotusPage';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function ProgramManagement() {
  const [view, setView] = useState('list');
  const { userGranteeAgencyPrograms, loadUserGranteeAgencyPrograms } = useUserAgency();

  const navigate = useNavigate();
  const { page, action } = useParams();

  useEffect(() => {
    if (page) {
      if (page === 'list' && !userGranteeAgencyPrograms) {
        loadUserGranteeAgencyPrograms();
      }
    }
  }, [page]);

  useEffect(() => {
    if (page) {
      switch (page) {
        case 'list': {
          setView('programList');
          break;
        }
        case 'create': {
          setView('addProgram');
          break;
        }
        case 'edit': {
          setView('editProgram');
          break;
        }
        default: {
          setView('programList');
          break;
        }
      }
    }
  }, [page, action]);

  const handleShowCreateProgram = () => {
    navigate('/programs/create');
  };

  const handleAfterCreateProgram = async (newProgramId) => {
    loadUserGranteeAgencyPrograms();
    await navigate(`/programs/edit/${newProgramId}`);
  };

  return (
    <LotusPage>
        {view === 'programList' && (
          <ProgramsList
            programs={userGranteeAgencyPrograms || []}
            handleCreateProgramClicked={handleShowCreateProgram}
          />
        )}
        {view === 'addProgram' && (
          <AddProgramPanel afterCreateProgram={handleAfterCreateProgram} />
        )}
        {view === 'editProgram' && <EditProgramPanel />}
    </LotusPage>
  );
}
