import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useField } from 'formik';
import { InputLabel, Typography } from '@mui/material';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusSelect({
  label,
  items,
  style,
  required,
  dontCheckRequiredFields,
  disabled,
  handleChange,
  autoSort=true,
  disallowNoSelectionIfNotRequired=false,
  excludeInactiveOrDeleted=false,
  ...props
}) {
  const [field, meta] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && (!val)) {
      return `${label} is required`;
    }
  }});
  const labelText = label + (required ? ' * ' : '');

  let selectItems = items ? [...items] : [];
  if (excludeInactiveOrDeleted) {
    // Exclude inactive or delete items unless the item is the current selection
    selectItems = selectItems.filter(itm => (itm.isActive && !itm.isDeleted) || itm.value === field.value);
  }
  if (autoSort) {
    selectItems.sort((a, b) => (a.label > b.label ? 1 : -1));
  }
  if (!required && !disallowNoSelectionIfNotRequired) {
    selectItems.unshift({value: '', label: '- No Selection -'});
  }

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    if (handleChange) {
      handleChange(newValue);
    }
    field.onChange(e);
  };

  return (
    !disabled ? 
    <div>
      <FormControl fullWidth variant= "outlined" style={{width: '100%', ...style}} size="small">
        <InputLabel id={`${field.name}-outlined-label`}>{labelText}</InputLabel>
        <Select
          labelId={`${field.name}-outlined-label`}
          {...field}
          {...props}
          onChange={handleLocalChange}
          required={required}
          label={labelText}
        >
          {selectItems &&
            selectItems.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
        </Select>
        {meta.touched && meta.error && (
          <Typography variant="body2" color="error">
            {meta.error}
          </Typography>
        )}
      </FormControl>
    </div> :
    <div>
      <LotusReadOnlyFieldView label={label} value={(selectItems && selectItems.find(x => x.value === field.value)?.label) || ''} style={style} />
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </div>
  );
}
