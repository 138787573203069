import Autocomplete from '@mui/material/Autocomplete';
import React, {useEffect, useState} from "react";
import { useProviders } from 'contexts/ProvidersContext';

import TextField from '@mui/material/TextField';
import { useDebounce } from 'lib/utils';

export default function IndividualProviderSelector({name, label, value, style, handleChange, setFieldValue, ...props}) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const {searchIndividualProviders, individualProviders, providerDetail, loadIndividualProvider} = useProviders();
  const resultLimit = 100;

  const debouncedFetchOptionsRequest = useDebounce(() => {
    if (inputValue) {
      searchIndividualProviders(inputValue, resultLimit);
    }
  });

  useEffect(() => {
    let newOptions = [];
    if (individualProviders) {
      const results = individualProviders.map(op => {return {name: op.name, id: op.id}});
      newOptions = [...newOptions, ...results];
    }
    setOptions(newOptions);
  }, [individualProviders]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : []);
    } else {
      debouncedFetchOptionsRequest();
    }
  }, [value, inputValue]);

  useEffect(() => {
    if (value) {
      if (!providerDetail || providerDetail.id !== value.id) {
        loadIndividualProvider(value.id);
      }
    }
  }, [value]);

  return (
    <Autocomplete
      name={name}
      options={options}
      value={value}
      getOptionLabel={(option) => option.name || option}
      onChange={(event, newValue) => {
        if (newValue && (!value || value.id !== newValue.id)) {
          setFieldValue(name, newValue);
          handleChange(event);
        }
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} style={{width: '100%', ...style}} name="providerInput" variant="outlined" fullWidth />
      )}
      {...props}
    />
  );
};