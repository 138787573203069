import React, { useEffect, useState } from 'react'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import Typography from '@mui/material/Typography';
import { Box, Grid } from "@mui/material";
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';

export default function LoginForm({
  values,
  handleChange,
  setErrors,
  touched,
  errors,
  props
}) {
  const [idpUrl, setIdpUrl] = useState();

  const setShowResetPassword = (e) => {
    values.operation = 'resetPassword';
    values.pageTitle = 'Reset Password';
    values.showWelcome = false;
    values.resetEmail = '';
    values.errorMsg = '';
    setErrors();
  }

  useEffect(() => {
    let tmpUrl;
    const urlParams = new URLSearchParams(window.location.search);
    if (props && urlParams.has('idp')) {
      const decoded = decodeURIComponent(urlParams.get('idp'));
      const idp = atob(decoded);
      const domain = props['REACT_APP_POOL_DOMAIN'];
      const region = props['REACT_APP_REGION'];
      const clientId = props['REACT_APP_CLIENT_ID'];
      const redirectUri = window.location.href.split('?')[0];
      tmpUrl = `https://${domain}.auth.${region}.amazoncognito.com/oauth2/authorize?identity_provider=${idp}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${clientId}&scope=aws.cognito.signin.user.admin email openid`;
      setIdpUrl(tmpUrl);
    }

  }, [props]);


  return (
    <>
      {(values.operation === 'login' && !idpUrl) && (
        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LotusTextInput
            required
            autoFocus
            autoComplete="off"
            id="email"
            name="email"
            label="Email"
            value={values.email}
            style={{ width: 340, marginTop: 0 }}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
          />
          <div style={{ padding: "10px" }} />
          <LotusTextInput
            required
            id="password"
            name="password"
            label="Password"
            type="password"
            value={values.password}
            style={{ width: 340 }}
            error={touched.password && Boolean(errors.password)}
          />

          {(values.operation === 'login' && values.errorMsg) && (
            <Grid item style={{ width: "600px", border: '10px' }}>
              <div style={{ padding: "10px" }} />
              <Typography variant="body" color='#b53f3f'>{values.errorMsg}</Typography>
            </Grid>

          )}
          <div style={{ padding: "10px" }} />

          <LotusButton style={{ width: "340px", height: '42px', fontWeight: 500, fontSize: 15 }} type="submit">
            LOG IN
          </LotusButton>
          <LotusSpacer /><LotusSpacer />
          <LotusButton type="button" component="button" variant="body2" style={{ height: '42px', fontWeight: 500, fontSize: 15 }}
            onClick={e => { setShowResetPassword(e); }}
            fullWidth
          >
            RESET PASSWORD
          </LotusButton>
        </Box>

      )}

      {(values.operation === 'login' && idpUrl) && (

        <Grid item style={{ width: "400px", border: '10px' }}>
          <Grid container direction="row" justifyContent="center" spacing={1} columns={2}>
            <Grid item>
              <LotusButton style={{ width: "400px", border: '10px' }} href={idpUrl}>
                Log In
              </LotusButton>
            </Grid>
          </Grid>

        </Grid>

      )}

    </>
  );
};