export const messageTypes = {
  LOADING_ICD10_CODES_SUCCESS: 'LOADING_ICD10_CODES_SUCCESS',
  LOADING_LANGUAGES_SUCCESS: 'LOADING_LANGUAGES_SUCCESS',
  LOADING_MEETING_STATUSES_SUCCESS: 'LOADING_MEETING_STATUSES_SUCCESS',
  LOADING_STATES_SUCCESS: 'LOADING_STATES_SUCCESS',
  LOADING_COUNTIES_FOR_STATE_SUCCESS: 'LOADING_COUNTIES_FOR_STATE_SUCCESS',
  LOADING_CLIENT_INFO_PICKLISTS_SUCCESS: 'LOADING_CLIENT_INFO_PICKLISTS_SUCCESS',
  LOADING_ELIGIBILITY_APPLICATION_STATUSES_SUCCESS: 'LOADING_ELIGIBILITY_APPLICATION_STATUSES_SUCCESS',
  LOADING_FEDERAL_POVERTY_GUIDELINES_SUCCESS: 'LOADING_FEDERAL_POVERTY_GUIDELINES_SUCCESS',
  LOADING_ACCOUNT_STATUSES_SUCCESS: 'LOADING_ACCOUNT_STATUSES_SUCCESS',
  LOADING_PROGRAM_TYPES_SUCCESS: 'LOADING_PROGRAM_TYPES_SUCCESS',
  LOADING_BARRIER_STATUSES_SUCCESS: 'LOADING_BARRIER_STATUSES_SUCCESS',
  LOADING_GOAL_STATUSES_SUCCESS: 'LOADING_GOAL_STATUSES_SUCCESS',
  LOADING_INTERVENTION_STATUSES_SUCCESS: 'LOADING_INTERVENTION_STATUSES_SUCCESS',
  LOADING_CARE_CATEGORIES_SUCCESS: 'LOADING_CARE_CATEGORIES_SUCCESS',
  LOADING_LIFE_AREAS_SUCCESS: 'LOADING_LIFE_AREAS_SUCCESS',
  LOADING_CONTACT_TYPES_SUCCESS: 'LOADING_CONTACT_TYPES_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ICD10_CODES_SUCCESS:
    case messageTypes.LOADING_LANGUAGES_SUCCESS:
    case messageTypes.LOADING_PROGRAM_TYPES_SUCCESS:
    case messageTypes.LOADING_MEETING_STATUSES_SUCCESS:
    case messageTypes.LOADING_ACCOUNT_STATUSES_SUCCESS:
    case messageTypes.LOADING_STATES_SUCCESS:
    case messageTypes.LOADING_CLIENT_INFO_PICKLISTS_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_APPLICATION_STATUSES_SUCCESS:
    case messageTypes.LOADING_FEDERAL_POVERTY_GUIDELINES_SUCCESS:
    case messageTypes.LOADING_BARRIER_STATUSES_SUCCESS:
    case messageTypes.LOADING_GOAL_STATUSES_SUCCESS:
    case messageTypes.LOADING_INTERVENTION_STATUSES_SUCCESS:
    case messageTypes.LOADING_CARE_CATEGORIES_SUCCESS:
    case messageTypes.LOADING_LIFE_AREAS_SUCCESS:
    case messageTypes.LOADING_CONTACT_TYPES_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case messageTypes.LOADING_COUNTIES_FOR_STATE_SUCCESS:
      let newCountiesByState = {};
      Object.entries(state.countiesByState || {}).forEach(
        ([k, v]) => {
          newCountiesByState[k] = v;
        }
      );
      newCountiesByState[payload.state] = payload.counties;
      return {
        ...state,
        countiesByState: newCountiesByState
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
