import React, { useEffect, useState } from 'react';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusItemList from '../Forms/LotusItemList';
import LotusTextInput from '../Forms/LotusTextInput';

export default function HouseholdMembersPanel({ otherHouseholdMembersFieldName, relationships, disabled, required, dontCheckRequiredFields }) {

  const emptyHouseholdMember = {
    clientRelationshipId: '',
    firstName: '',
    lastName: '',
  };
  
  function buildHouseholdMemberPanel(householdMember, index, disabled) {
    return (
      <div style={{padding: 8}} key={index}>
        <div style={{fontSize: 18, fontWeight: 400, marginBottom: 16}}>Household Member {index + 1}</div>
        <div style={{marginBottom: 16}}>                    
          <LotusTextInput
            disabled={disabled}
            name={`${otherHouseholdMembersFieldName}.${index}.firstName`}
            label="First Name"
            required={required}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        </div>
        <div style={{marginBottom: 16}}>                     
          <LotusTextInput
            disabled={disabled}
            name={`${otherHouseholdMembersFieldName}.${index}.lastName`}
            label="Last Name"
            required={required}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        </div>
        {relationships && (  
          <div style={{marginBottom: 16}}>                     
            <LotusSelect
              disabled={disabled}
              name={`${otherHouseholdMembersFieldName}.${index}.clientRelationshipId`}
              label="Relationship to Client"
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              items={relationships}
            />
          </div>
        )} 
      </div>
    );
  }

  return (
    <LotusItemList
      disabled={disabled} 
      name={otherHouseholdMembersFieldName}
      itemName="Household Member"
      buildItemDetail={(item, index, isDisabled) => buildHouseholdMemberPanel(item, index, isDisabled)}
      buildItemTemplate={() => {return {...emptyHouseholdMember}}}
    />
  );
}