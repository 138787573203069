import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import LotusMaskedInput from 'components/widgets/Forms/LotusMaskedInput';

import { useLists } from 'contexts/ListsContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from '../Forms/LotusProgressiveDisclosure';

export default function SocialSecurityNumberFieldPanel({ disabled, hasSocialSecurityNumberFieldName, socialSecurityNumberFieldName, missingSsnReasonIdFieldName, useSeedData, required, dontCheckRequiredFields, ...props }) {

  const [hasSsnField] = useField(hasSocialSecurityNumberFieldName);
  const [missingSsnReasonField, missingSsnReasonFieldMeta, missingSsnReasonFieldUtils] = useField(missingSsnReasonIdFieldName);

  const {missingSsnReasons } = useLists();
  const [missingSsnReasonList, setMissingSsnReasonList] = useState();

  useEffect(() => {
    if (useSeedData) {
      setMissingSsnReasonList([{label: "Reason 1", value: 1},{label: "Reason 2", value: 2}]);
    }
  }, [useSeedData]);

  useEffect(() => {
    if (missingSsnReasons) {
      setMissingSsnReasonList(missingSsnReasons.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [missingSsnReasons]);

  useEffect(() => {
    if (hasSsnField.value === false && !missingSsnReasonField.value) {
      missingSsnReasonFieldUtils.setValue('');
    }
  }, [hasSsnField.value, missingSsnReasons]);

  return (
    <LotusProgressiveDisclosure
      name={hasSocialSecurityNumberFieldName}
      label='Does the client have a social security number?'
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
    >
      <LotusProgressiveDisclosureItem
        itemValue='true'
        label='Yes'
      >
        <LotusMaskedInput
          name={socialSecurityNumberFieldName}
          label="Social Security Number"
          mask="999-99-9999"
          required={required && hasSsnField.value === true}
          disabled={disabled}
          dontCheckRequiredFields={dontCheckRequiredFields}
        />
      </LotusProgressiveDisclosureItem>
      <LotusProgressiveDisclosureItem
        itemValue='false'
        label='No'
      >
        {missingSsnReasonList &&
          <LotusSelect
            name={missingSsnReasonIdFieldName}
            label="Reason for No SSN"
            items={missingSsnReasonList}
            disabled={disabled}
            required={required && hasSsnField.value === false}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        }
      </LotusProgressiveDisclosureItem>      
    </LotusProgressiveDisclosure>
  );
}
