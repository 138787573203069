import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import HousingTypeFieldPanel from 'components/widgets/Composites/HousingTypeFieldPanel';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import { useClient } from 'contexts/ClientContext';
import { useFormikContext } from 'formik';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import AddressFieldPanelWithLocationSearch from 'components/widgets/Composites/AddressFieldPanelWithLocationSearch';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { v4 as uuidv4 } from 'uuid';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { useUserAgency } from 'contexts/UserAgencyContext';

const buildSectionNames = (existingClientConfig) => {
  const lst = ["Housing Status","Primary Address"];
  if (existingClientConfig.showSecondaryAddress) {
    lst.push("Secondary Address");
  }
  lst.push("Documentation");
  return lst;
}

const validationSchema = Yup.object().shape({});

const ClientAddressesStaticForm = ({existingClientConfig, disabled, clientId, updateRestrictions, hasEligibilityApp, currentRyanWhiteEnrollment}) => {
 
  const {housingStatuses} = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [housingStatusList, setHousingStatusList] = useState();
  const [isChangingAddresses, setIsChangingAddresses] = useState();
  const { changeClientAddresses, deleteClientAddresses } = useClient();

  const { values, setFieldValue, resetForm, setFieldTouched, validateForm, setTouched, touched } = useFormikContext();

  useEffect(() => {
    if (housingStatuses) {
      setHousingStatusList(housingStatuses.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
  }, [housingStatuses]);

  const saveAddressesChange = async () => {
    let result = await validateForm();
    if (Object.keys(result).length === 0) {
      setTimeout(async () => {
        await changeClientAddresses(clientId, {...values.addressList[0], id: undefined, addressDocumentation: values.addressDocumentation});
        setIsChangingAddresses(false);
      });
    } else {
      setTouched({ ...touched, ...result }); // when doing validateForm manually stuff needs to be touched for errors to display. awful
    }
  }

  const deleteAddresses = async (index) => {
    await deleteClientAddresses(clientId, values.addressList[index].id);
  }

  const handleMailingSameAsPrimary = async (index) => {
    if(values.addressList[index].mailingSameAsPrimary) {
      values.addressList[index].mailingAddress = {
        address1: '', 
        address2: '', 
        city: '', 
        state: '', 
        zip: '', 
        county: ''
      }
    }
  }

  const buildEffectiveAddressesDisplay = (addresses, index, addressesDisabled) => {
    return (
      <>
      {isChangingAddresses &&
        <>
        <LotusFormItem>
          <div style={{fontSize: 24, fontWeight: 500}}>Address Update</div>
        </LotusFormItem>
        <LotusFormItem>
          <LotusDatePicker
            name={`addressList.${index}.addressesUpdateEffectiveDate`}
            label="Address Update Effective Date"
            disabled={addressesDisabled}
            minDate={moment().format('MM/DD/YYYY')}
            maxDate={currentRyanWhiteEnrollment ? currentRyanWhiteEnrollment.endDate : null}
            required
          />
        </LotusFormItem>
        </>
      }
      <LotusFormSection name="Housing Status" disabled={disabled}>
        {housingStatusList &&
        <LotusFormItem>
          <LotusRadioGroup
              name={`addressList.${index}.housingStatusId`}
              label="Housing Status"
              items={housingStatusList}
              disabled={addressesDisabled}
              required={isChangingAddresses}
              handleChange={(val) => {setFieldValue(`addressList.${index}.housingTypeId`, ''); setFieldTouched(`addressList.${index}.housingTypeId`, true);}}
            />
        </LotusFormItem>
        }
        {housingStatuses &&
        <LotusFormItem>
          <HousingTypeFieldPanel
            housingStatusKey={values.addressList[index].housingStatusId ? housingStatuses.find(s => s.id === values.addressList[index].housingStatusId).itemKey : null}
            housingTypeFieldName={`addressList.${index}.housingTypeId`}
            incarcerationDateFieldName={`addressList.${index}.incarcerationDate`}
            projectedIncarcerationReleaseDateFieldName={`addressList.${index}.projectedIncarcerationReleaseDate`}
            disabled={addressesDisabled}
            required={isChangingAddresses}
          />
        </LotusFormItem>
        }
      </LotusFormSection>
      <LotusFormSection name="Primary Address" disabled={disabled}>
        <LotusFormItems>
          <AddressFieldPanelWithLocationSearch
            name={`addressList.${index}.primaryAddress`}
            disabled={addressesDisabled}
            required={isChangingAddresses}
          />
          <LotusTrueFalseRadioGroup
            name={`addressList.${index}.mailingSameAsPrimary`}
            label="Is Mailing Address same as Primary Address?"
            disabled={addressesDisabled}
            required={isChangingAddresses}
            onChange={handleMailingSameAsPrimary(index)}
          />
          <LotusTextInput
            name={`addressList.${index}.mailToName`}
            label="Mail To Name"
            disabled={addressesDisabled}
            maxLength={70}
            required={isChangingAddresses}
          />
          {!values.addressList[index].mailingSameAsPrimary &&
            <AddressFieldPanelWithLocationSearch
              name={`addressList.${index}.mailingAddress`}
              disabled={addressesDisabled}
              required={isChangingAddresses}
            />
          } 
        </LotusFormItems>
      </LotusFormSection>
      {existingClientConfig.showSecondaryAddress &&
        <LotusFormSection name="Secondary Address" disabled={disabled}>
          <LotusFormItem>
            <AddressFieldPanelWithLocationSearch
              name={`addressList.${index}.secondaryAddress`}
              disabled={addressesDisabled}
            />
          </LotusFormItem>
        </LotusFormSection>
      }
      {index !== 0 && !isChangingAddresses && !disabled &&
        <Stack direction="row" justifyContent="flex-end">
          <LotusButton garbageIcon variant="text" onClick={() => {deleteAddresses(index);}}>
            Remove
          </LotusButton>
        </Stack>
      }
      </>
    );
  }

  return values && existingClientConfig && (
    <Stack direction="row" spacing={1}>
      <LotusForm>
        {updateRestrictions && (updateRestrictions.all || updateRestrictions.addresses) &&
        <LotusFormSection>
          <LotusFormItem>
            <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
          </LotusFormItem>
        </LotusFormSection>
        }
        {values.addressList.map((addr, idx) => {
          return (
            <div key={addr.id} style={{backgroundColor: idx !== 0 || isChangingAddresses ? '#E0F1F3' : 'inherit', padding: 10}}>
              {idx !== 0 &&
                <LotusSpacedBlock>
                  <LotusFormItem>
                    <div style={{fontSize: 24, fontWeight: 500}}>Address Update</div>
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`addressList.${idx}.effectiveDate`}
                      label="Address Update Effective Date"
                      disabled={true}
                    />
                  </LotusFormItem>
                </LotusSpacedBlock>
              }
              {buildEffectiveAddressesDisplay(addr, idx, idx !== 0 || disabled || (!isChangingAddresses && (hasEligibilityApp || currentRyanWhiteEnrollment)))}
              {idx === 0 && 
                <LotusFormSection name={"Documentation" + (isChangingAddresses ? ' *' : '')} disabled={disabled}>
                  <LotusFormItem>
                    <ClientInfoDocumentation 
                      documentationListFieldName="addressDocumentation"
                      allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoAddressDocumentationTypes || []}
                      clientId={clientId}
                      disabled={disabled}
                      label="Address Documentation"
                      required={isChangingAddresses}
                    />
                  </LotusFormItem>
                </LotusFormSection>
              }
            </div>
          );
        })}
      </LotusForm>
      <div style={{padding: 10}}>
        {hasEligibilityApp && values.addressList.length === 1 && !disabled && !isChangingAddresses && currentRyanWhiteEnrollment &&
          <LotusButton plusIcon variant="outlined" onClick={() => {
            setIsChangingAddresses(true); 
            setFieldValue('addressDocumentation', []);
            setFieldValue('addressList.0.addressesUpdateEffectiveDate', moment().format('MM/DD/YYYY'));
          }}>
            Change Address
          </LotusButton>
        }
        {isChangingAddresses &&
          <Stack justifyContent='flex-end' direction="row" spacing={1}>
            <LotusButton variant="text" onClick={() => {resetForm(); setIsChangingAddresses(false);}}>
              <Typography variant="h6">Cancel</Typography>
            </LotusButton>
            <LotusButton onClick={() => {saveAddressesChange();}}>
              <Typography variant="h6">Save</Typography>
            </LotusButton>
          </Stack>
        }
      </div>
    </Stack>
  );
};

const buildStaticForm = ({...props}) => {
  return <ClientAddressesStaticForm {...props} />
}

export default function ClientAddressesForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions}) {
  
  const { clientAddresses, loadClientAddresses, upsertClientAddresses, clientHasEligibilityApplication, loadClientHasEligibilityApplication } = useClient();
  const { ryanWhiteProgramEnrollments, loadRyanWhiteEnrollmentsForClient } = useEnrollments();
  const [initialValues, setInitialValues] = useState();
  const [currentRyanWhiteEnrollment, setCurrentRyanWhiteEnrollment] = useState();

  useEffect(() => {
    if (client) {
      loadClientAddresses(client.id);
      loadClientHasEligibilityApplication(client.id);
      loadRyanWhiteEnrollmentsForClient(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (ryanWhiteProgramEnrollments) {
      const todayMoment = moment().startOf('day');
      const curr = ryanWhiteProgramEnrollments.find(e => { return moment(e.startDate,'MM/DD/YYYY') <= todayMoment && moment(e.endDate,'MM/DD/YYYY') >= todayMoment});
      setCurrentRyanWhiteEnrollment(curr);
    }
  }, [ryanWhiteProgramEnrollments]);

  useEffect(() => {
    if (clientAddresses) {
      let lst = [];
      if (clientAddresses.effectiveAddresses.length > 0) {
        // Dont show old records
        const todayMoment = moment().startOf('day');
        let currentAddr = clientAddresses.effectiveAddresses.findLast(c => moment(c.effectiveDate,'MM/DD/YYYY') <= todayMoment);
        let futureAddrList = clientAddresses.effectiveAddresses.filter(c => moment(c.effectiveDate,'MM/DD/YYYY') > todayMoment);
        if (currentAddr) {
          lst.push(currentAddr);
        }
        lst = [...lst, ...futureAddrList];
        lst = lst.map((currentAddress, idx) => { 
          return {
            id: currentAddress.id,
            housingTypeId: currentAddress.housingTypeId || '', 
            housingStatusId: currentAddress.housingStatusId || '',
            effectiveDate: currentAddress.effectiveDate || null,  
            incarcerationDate: currentAddress.incarcerationDate || null,
            projectedIncarcerationReleaseDate: currentAddress.projectedIncarcerationReleaseDate || null,
            primaryAddress: {
              address1: currentAddress.primaryAddress.address1 || '', 
              address2: currentAddress.primaryAddress.address2 || '', 
              city: currentAddress.primaryAddress.city || '', 
              state: currentAddress.primaryAddress.state || '', 
              zip: currentAddress.primaryAddress.zip || '', 
              county: currentAddress.primaryAddress.county || ''
            },
            secondaryAddress: {
              address1: currentAddress.secondaryAddress.address1 || '', 
              address2: currentAddress.secondaryAddress.address2 || '', 
              city: currentAddress.secondaryAddress.city || '', 
              state: currentAddress.secondaryAddress.state || '', 
              zip: currentAddress.secondaryAddress.zip || '', 
              county: currentAddress.secondaryAddress.county || ''
            },
            mailingSameAsPrimary: currentAddress.mailingSameAsPrimary !== null ? currentAddress.mailingSameAsPrimary : false, 
            mailToName: currentAddress.mailToName || client?.name, 
            mailingAddress: {
              address1: currentAddress.mailingAddress.address1 || '', 
              address2: currentAddress.mailingAddress.address2 || '', 
              city: currentAddress.mailingAddress.city || '', 
              state: currentAddress.mailingAddress.state || '', 
              zip: currentAddress.mailingAddress.zip || '', 
              county: currentAddress.mailingAddress.county || ''
            }
          };
        });
      }
      else {
        lst.push({
          id: uuidv4(),
          effectiveDate: moment().format('MM/DD/YYYY'),
          housingTypeId: '', 
          housingStatusId: '',
          incarcerationDate: null,
          projectedIncarcerationReleaseDate: null,
          primaryAddress: {
            address1: '', address2: '', city: '', state: '', zip: '', county: ''
          },
          secondaryAddress: {
            address1: '', address2: '', city: '', state: '', zip: '', county: ''
          },
          mailingSameAsPrimary: false, 
          mailToName: client?.name, 
          mailingAddress: {
            address1: '', address2: '', city: '', state: '', zip: '', county: ''
          }
        });
      }

      setInitialValues( {
        addressList: lst,
        addressDocumentation: clientAddresses.addressDocumentation
      });
    }
  }, [clientAddresses]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      addressList, addressDocumentation,
      ...dynamicFormData} = formData;

    const clientAddresses = {
      addressList, addressDocumentation
    };
    return [clientAddresses, dynamicFormData];
  }

  const saveForm = async (values) => {
    await upsertClientAddresses(client.id, {...values.addressList[0], addressDocumentation: values.addressDocumentation});
  }

  return initialValues && (
    <HybridForm
      title="Addresses"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled  || updateRestrictions?.all || updateRestrictions?.addresses, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions, hasEligibilityApp: clientHasEligibilityApplication, currentRyanWhiteEnrollment: currentRyanWhiteEnrollment})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.addresses || clientHasEligibilityApplication}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}