export const CONSTANTS = Object.freeze({
    VIEW_MODAL_TYPE: 'view',
    ADD_MODAL_TYPE: 'add',
    MANUAL_ENTRY_MODE: 'manual',
    IMPORT_ENTRY_MODE: 'import',
    // Keeping the options hard-coded here, instead of DB. Will move to DB when data dictionary/file import also ready to valid value list for each fields.
    TEST_NAME_OPTIONS: [{
        label: 'CD4 Count', value: 'CD4 Count'
      },{
        label: 'Viral Load', value: 'Viral Load'
      }],
    TEST_RESULT_MODIFIER_OPTIONS: [{
        label: '=', value: '='
      },{
        label: '<', value: '<'
      }],
    TEST_MEASURE_OPTIONS: [{
        label: 'cells/uL', value: 'cells/uL'
      },{
        label: 'copies/mL', value: 'copies/mL'
      }],
    TEST_TECH_OPTIONS: [{
        label: 'UniGold Recombigen HIV 1/2', value: 'UniGold Recombigen HIV 1/2'
      },{
        label: 'Ora-quick Advance HIV 1/2', value: 'Ora-quick Advance HIV 1/2'
      },{
        label: 'Alere Determine HIV 1/2 Ag/Ab Combo', value: 'Alere Determine HIV 1/2 Ag/Ab Combo'
      },{
        label: 'Clearview COMPLETE HIV 1/2', value: 'Clearview COMPLETE HIV 1/2'
      }],
    TEST_STATUS_OPTIONS: [{
        label: 'Correction', value: 'Correction'
      },{
        label: 'Final', value: 'Final'
      },{
        label: 'Preliminary', value: 'Preliminary'
      },{
        label: 'Not Available', value: 'Not Available'
      },{
        label: 'Not Required', value: 'Not Required'
      },{
        label: 'Pending', value: 'Pending'
      },{
        label: 'Clinically Indicated', value: 'Clinically Indicated'
      }],
      PAYMENT_STATUS_OPTIONS: [{
        label: 'Approved to Pay', value: 'Approved to Pay'
      },{
        label: 'Paid', value: 'Paid'
      },{
        label: 'Pending', value: 'Pending'
      },{
        label: 'Rejected', value: 'Rejected'
      },{
        label: 'Cleared', value: 'Cleared'
      },{
        label: 'Void', value: 'Void'
      }],
      PAYMENT_TYPE_OPTIONS: [{
        label: 'Full', value: 'Full'
      },{
        label: 'Partial', value: 'Partial'
      }],
      PAYMENT_METHOD_OPTIONS: [{
        label: 'PCard', value: 'PCard'
      },{
        label: 'Ramsell', value: 'Ramsell'
      }]
});