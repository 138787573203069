import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Chip, Grid, Paper, Stack } from '@mui/material';
import LotusTabPanel from 'components/widgets/Layout/LotusTabPanel';
import { useAuthState } from 'contexts/AuthProvider';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { debounce, isEqual } from 'lodash';
import { useDashboards } from 'contexts/DashboardsContext';
import { Table } from '@lotus/components';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import { Bar, BarChart, Rectangle, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import EligibilityApplicationViewerButton from 'components/widgets/Composites/EligibilityApplicationViewerButton';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import moment from 'moment';
import { Formik } from 'formik';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';

export default function SingleMetricDashboard() {

  const { type: dashboardType } = useParams();
  const { user } = useAuthState();

  const [tabs, setTabs] = useState();
  const [tabIndex, setTabIndex] = useState();
  const [showAgencyInGrid, setShowAgencyInGrid] = useState();
  const [metrics, setMetrics] = useState();
  const [subprogramList, setSubprogramList] = useState();
  const [initialSearchFilters, setInitialSearchFilters] = useState();

  const [selectedFilters, setSelectedFilters] = useState();
  const [selectedMetric, setSelectedMetric] = useState();
  const [selectedQueryParams, setSelectedQueryParams] = useState();
  const [selectedMetricQueryResult, setSelectedMetricQueryResult] = useState();
  const [selectedMetricEntityTypeCount, setSelectedMetricEntityTypeCount] = useState();
  const {loadMetricQueryResult, metricQueryResult, loadMetricEntityTypeTotalCount, metricEntityTypeTotalCounts} = useDashboards();
  const [currentSortOrder, setCurrentSortOrder] = useState();

  const {userAgency, loadUserAgencyMetrics, userAgencyMetrics} = useUserAgency();

  useEffect(() => {
    if (user && dashboardType) {
      // Some props to set here based upon if user is subagency or grantee
      setTabs([{label: 'My Clients'}, {label: user.isGrantee ? user.granteeAgencyName : user.agencyName}]);
      setTabIndex(user.isGrantee ? 1 : 0);
      setShowAgencyInGrid(user.isGrantee);

      // Load dashboard config
      loadUserAgencyMetrics([dashboardType]);
    }
  }, [user, dashboardType]);

  useEffect(() => {
    if (userAgencyMetrics && dashboardType in userAgencyMetrics) {
      let mapped = userAgencyMetrics[dashboardType].map(am => {
        return {
          id: am.metricId,
          key: am.metricKey,
          name: am.title,
          data: am.data,
          entityType: am.entityType,
        }
      });
      setMetrics(mapped);
    }
  }, [userAgencyMetrics]);

  useEffect(() => {
    if (userAgency) {
      let lst = userAgency.eligibilityConfig.application.adapSubprograms.map(s => {return {label: s.name, value: s.id}});
      lst = [{label: 'All Subprograms', value: 'all', disabled: true}, ...lst];
      setSubprogramList(lst);

      let initialFilters = {
        subprograms: ['all']
      };
      setInitialSearchFilters(initialFilters);
      setSelectedFilters(initialFilters);
    }
  }, [userAgency]);

  const handleTabChange = async (event, tIdx) => {
    setTabIndex(tIdx);
    if (selectedMetric) {
      doMetricQuery(selectedMetric, selectedFilters, tIdx);
    }
  };

  const showMetric = async (id) => {
    const newMetrics = [...metrics];
    newMetrics.forEach(m => m.isSelected = false);
    
    const m = newMetrics.find(m => m.id === id);
    m.isSelected = true;
    setMetrics(newMetrics);

    // Based on selected metric determine what table to display
    setSelectedMetric(m);
    
    doMetricQuery(m, selectedFilters, tabIndex);
  }

  useEffect(() => {
    if (selectedMetric) {
      doMetricQuery(selectedMetric, selectedFilters, tabIndex);
    }
  }, [selectedFilters]);

  const doMetricQuery = (metric, filters, userTabIndex) => {
    let restrictToRequestingUser = userTabIndex === 0;
    const params = {
      metricId: metric.id,
      metricKey: metric.key,
      metricParams: metric.data,
      filters: filters,
      restrictToRequestingUser
    };
    loadMetricQueryResult({params, sortOrder: currentSortOrder});
    setSelectedQueryParams(params);

    let getCount = true;
    if (metricEntityTypeTotalCounts) {
      let count = getEntityCount(metric, restrictToRequestingUser, filters);
      if (count !== undefined) {
        setSelectedMetricEntityTypeCount(count);
        getCount = false;
      }
    }
    if (getCount) {
      loadMetricEntityTypeTotalCount(metric.entityType, restrictToRequestingUser, filters);
    }
  }

  const getEntityCount = (metric, restrictToRequestingUser, filters) => {
    if (metricEntityTypeTotalCounts) {
      let countObj = metricEntityTypeTotalCounts.find(c => {
        if (restrictToRequestingUser && c.userId !== user.id) {
          return false;
        }
        if (!restrictToRequestingUser && c.agencyId !== user.agencyId) {
          return false;
        }
        if (!isEqual(c.filters, filters)) {
          return false;
        }
        if (metric.entityType !== c.entityType) {
          return false;
        }
        return true;
      });
      if (countObj) {
        return Number(countObj.count);
      } else {
        return undefined;
      }
    }
  }

  useEffect(() => {
    if (metricQueryResult && isEqual(metricQueryResult.queryParams, selectedQueryParams)) {
      setSelectedMetricQueryResult(metricQueryResult);
    } 
  }, [metricQueryResult]);

  useEffect(() => {
    if (selectedMetric && metricEntityTypeTotalCounts && selectedFilters) {
      let ct = getEntityCount(selectedMetric, tabIndex === 0, selectedFilters);
      setSelectedMetricEntityTypeCount(ct);
    } 
  }, [selectedMetric, selectedFilters, metricEntityTypeTotalCounts]);

  return (
    <>
      {metrics && metrics.length === 0 &&
        <div>No metrics enabled. Please ask your agency admin to configure your dashboard metrics.</div>
      }
      {metrics && metrics.length > 0 &&
        <>
        <Formik
          initialValues={initialSearchFilters}
          enableReinitialize
        >
          {({values, setFieldValue}) => {
            return values && (
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <LotusTabPanel tabs={tabs} useColorForSelectedItem={true} value={tabIndex} handleTabChanged={handleTabChange}/>
                {user.isGrantee &&
                  <LotusMultiSelect
                    name="subprograms"
                    label="Subprogram(s)"
                    items={subprogramList}
                    style={{width: 300}}
                    onChange={(e) => { 
                      let lst = [...e.target.value];
                      if (lst.length > 1 && lst.includes('all')) {
                        lst.splice(lst.indexOf('all'), 1);
                      }
                      if (lst.length === 0) {
                        lst = ['all'];
                      }
                      setFieldValue('subprograms', lst);
                      let newSelectedFilters = {...selectedFilters};
                      newSelectedFilters.subprograms = lst;
                      setSelectedFilters(newSelectedFilters);
                    }}
                  />
                }
              </Stack>
            );
          }}
        </Formik>
        <LotusSpacedBlock/>
        <LotusSpacedBlock/>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Paper elevation={4} style={{padding: "40px 20px", borderRadius: 10}}>
            <Stack spacing={3} alignItems="center">
              <div style={{fontSize: 24}}>Metrics</div>
              {metrics && metrics.map(m => {
                return (
                  <div
                    key={m.id}
                    onClick={() => showMetric(m.id)}
                    style={{cursor: 'pointer', background: m.isSelected ? 'rgba(41 64 85)' : 'inherit', color: m.isSelected ? 'white' : 'inherit', width: '100%', textAlign: 'center', border: "1px solid black", padding: 10, borderRadius: 10}}>{m.name}</div>
                );
              })}
            </Stack>
            </Paper>
          </Grid>
          <Grid item xs={9}>
            <Stack spacing={3}>
              {selectedMetric &&
                <div style={{height: 250}}>
                  <LotusPaper>
                    {selectedMetricEntityTypeCount != undefined && selectedQueryParams && selectedQueryParams.metricId === selectedMetric.id && selectedMetricQueryResult && isEqual(selectedMetricQueryResult.queryParams, selectedQueryParams) &&
                      <>
                      <div>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <span style={{fontSize: 24, fontWeight: 600}}>{selectedMetricQueryResult.keys.length}</span>
                          <span>{selectedMetric.name}</span>
                        </Stack>
                      </div>
                      <ResponsiveContainer width="100%" height={150}>
                        <BarChart
                          layout="vertical"
                          data={[{val: selectedMetricQueryResult.keys.length}]}
                        >
                          <XAxis type="number" domain={[0, selectedMetricEntityTypeCount]} allowDecimals={false} tickCount={10}/>
                          <YAxis type="category" hide />
                          <Bar dataKey="val" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                        </BarChart>
                      </ResponsiveContainer>
                      </>
                    }
                  </LotusPaper>
                </div>
              }
              {selectedMetric && selectedMetric.entityType === 'client_applications' &&
                <ClientApplicationMetricDetailsTable 
                  tableName="clientApplicationMetricDetails" 
                  queryParams={selectedQueryParams} 
                  showAgencyInGrid={showAgencyInGrid} 
                  onSortChange={(order) => setCurrentSortOrder(order)}
                  user={user}
                />
              }
            </Stack>
          </Grid>
        </Grid>
        </>
      }
    </>
  );
}

export function ClientApplicationMetricDetailsTable({ queryParams, showAgencyInGrid, tableName, onSortChange, user }) {

  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [currentSortOrder, setCurrentSortOrder] = useState({});
  
  const { tablePreferences, updateTablePreferences } = useAuthState();
  const { createEligibilityApplication } = useEligibilityApplication();
  const navigate = useNavigate();

  const [clientIds, setClientIds] = useState();
  const {loadMetricQueryResult, loadMetricClientApplicationDetails, metricQueryResult, metricClientApplicationDetailsResult} = useDashboards();

  useEffect(() => {
    if (tablePreferences && tableName && tablePreferences[tableName]) {
      setDisplayedColumns(tablePreferences[tableName].columns);
    } else {
      if (tableName) {
        let prefs = tablePreferences || {};
        if (!prefs[tableName]) {
          prefs[tableName] = {};
        }
        prefs[tableName].columns = ['id', 'clientName', 'originalSubmissionDate', 'caseManagerName', 'eligibilityStartDate', 'eligibilityExpirationDate', 'urgencyName', 'applicationStatusName', 'applicationStatusKey'];
        setDisplayedColumns(prefs[tableName].columns);
        updateTablePreferences(prefs);
      }
    }
  }, [tableName, tablePreferences]);

  const handleDataChange = async ({pageNumber, rowsPerPage, sortOrder}) => {
    if (!isEqual(sortOrder, currentSortOrder)) {
      // Requery
      loadMetricQueryResult({
        params: queryParams,
        sortOrder: sortOrder
      });
      setCurrentSortOrder(sortOrder);
      // Need to tell our container that sort has changed since they are doing the initial query to get the total for chart display
      if (onSortChange) {
        onSortChange(sortOrder);
      }
    } else if (clientIds) {
      // Just load the details for this page of clientIds
      const clientIdsForPage = clientIds.slice( pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage);
      if (!metricClientApplicationDetailsResult || !isEqual(clientIdsForPage, metricClientApplicationDetailsResult.ids)) {
        loadMetricClientApplicationDetails({clientIds: clientIds.slice( pageNumber * rowsPerPage, pageNumber * rowsPerPage + rowsPerPage)});
      }
    }
  };

  const handleRowClicked = (rowData) => {
    const clientId = rowData[10];
    return navigate(`/client/${clientId}/eligibility`);
  };

  useEffect(() => {
    if (metricQueryResult && isEqual(metricQueryResult.queryParams, queryParams)) {
      setClientIds(metricQueryResult.keys);
    }
  }, [metricQueryResult]);

  useEffect(() => {
    // Whenever client ids change, go to first page of table
    if (clientIds) {
      handleDataChange({
        pageNumber: 0,
        rowsPerPage: 10,
        sortOrder: currentSortOrder
      });
    }
  }, [clientIds]);

  useEffect(() => {
    if (metricClientApplicationDetailsResult && metricClientApplicationDetailsResult.details) {
      // shove this result into the table
      setRows(metricClientApplicationDetailsResult.details);
    }
  }, [metricClientApplicationDetailsResult]);

  const startApp = async (clientId, isRecert) => {
    const id = await createEligibilityApplication(clientId, !isRecert, isRecert, false);
    if (id) {
      navigate(`/client/${clientId}/eligibility/application/${id}`);
    }
  }

  const calculateExpirationDays = (expireDate) => {
    if (!expireDate) return '--';
    let today = moment().startOf('day');
    return Math.round((moment(expireDate) - today) / 86400000);
  }
  
  useEffect(() => {
    if (showAgencyInGrid !== undefined && displayedColumns) {
      setColumns([
        {
          name: 'applicationId',
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
          },
        },
        {
          label: 'Client Name',
          name: 'clientName',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('clientName'),
          },
        },
        {
          label: 'Case Manager', 
          name: 'caseManagerName',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('caseManagerName'),
          },
        },
        {
          label: 'Date Submitted',
          name: 'originalSubmissionDate',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('originalSubmissionDate'),
            customBodyRender: (value) => {
              return <div>{value ? <div>{value}<div style={{fontSize: 14, color: 'grey'}}>{Math.abs((calculateExpirationDays(value)))} days</div></div> : '--'}</div>;
            },
          },
        },
        {
          label: 'Eligibility Starts',
          name: 'eligibilityStartDate',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('eligibilityStartDate'),
            customBodyRender: (value) => {
              return <div>{value ? value : 'N/A'}</div>;
            },
          },
        },
        {
          label: 'Eligibility Expires',
          name: 'eligibilityExpirationDate',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('eligibilityExpirationDate'),
            customBodyRender: (value) => {
              return <div>{value ? value : 'N/A'}</div>;
            },
          },
        },
        {
          label: 'Urgent Flag',
          name: 'urgencyName',
          options: {
            filter: false,
            sort: true,
            display: displayedColumns.includes('urgencyName'),
            customBodyRender: (value) => {
              return value && <Chip label={value} size="medium" color="warning" />;
            },
          },
        },
        {
          label: 'Status', 
          name: 'applicationStatusName',
          options: {
            filter: false,
            display: displayedColumns.includes('applicationStatusName'),
            customBodyRender: (value) => {
              return value ? <Chip label={value} size="medium" /> : '';
            },
          },
        },
        {
          label: 'Agency', 
          name: 'agencyName',
          options: {
            filter: false,
            display: displayedColumns.includes('agencyName') && showAgencyInGrid,
          },
        },
        {
          label: 'Application',
          name: 'applicationStatusKey',
          options: {
            filter: false,
            sort: false,
            display: displayedColumns.includes('applicationStatusKey'),
            customBodyRender: (value, tableMeta) => {
              let expDate = tableMeta.rowData[5];
              if (['not_started', 'started', 'submitted', 'in_review', 'pending', 'rw_review_completed'].includes(value)) {
                return (<EligibilityApplicationViewerButton 
                          appId={tableMeta?.rowData[0]}
                          appStatusKey={value}
                          appClientId={tableMeta?.rowData[10]}
                          appProcessorId={tableMeta?.rowData[11]}
                          userId={user.id}
                        />);
              } 
              else {
                let isRecert = (!expDate || (moment(expDate, 'MM/DD/YYYY') > moment().startOf('day')));
                return (
                  <LotusButton onClick={() => startApp(tableMeta.rowData[10], isRecert)}>Start</LotusButton>
                );
              }
            },
          },
        },
        {
          name: 'clientId',
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
          },
        },
        {
          name: 'processorId',
          options: {
            filter: false,
            sort: false,
            display: 'excluded',
          },
        },
      ]);
    }
  }, [showAgencyInGrid, displayedColumns]);

  const updatePreferences = debounce((prefs) => {
    updateTablePreferences(prefs);
  }, 2000);

  const handleViewColumnsChanged = (changedColumn, action) => {
    let newColumns = displayedColumns;
    if (action === 'add') {
      newColumns.push(changedColumn);
    } else {
      let idx = newColumns.indexOf(changedColumn);
      if (idx > -1) {
        newColumns.splice(idx, 1);
      }
    }
    setDisplayedColumns(newColumns);
    let prefs = tablePreferences;
    if (prefs) { 
      if (!prefs[tableName]) {
        prefs[tableName] = {};
      }
      prefs[tableName].columns = newColumns;
      updatePreferences(prefs);
    }
  };

  return columns && clientIds && (
    <Table
      tableName={tableName}
      enabled={Boolean(rows)}
      options={{ 
        serverSide: true, 
        customSearch: undefined, 
        download: false, 
        print: false,
        filter: false,
        search: false,
        searchAlwaysOpen: false
      }}
      columns={columns}
      data={rows}
      totalRows={rows && rows.length > 0 ? clientIds.length : null}
      handleDataChange={handleDataChange}
      handleRowClicked={handleRowClicked}
      defaultSortOrder={{}}
      handleViewColumnsChanged={handleViewColumnsChanged}
    />
  );
}
