import SvgIcon from '@mui/material/SvgIcon';

const GroupwareLogoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g
        id="Component_154"
        data-name="Component 154"
        transform="translate(0.5 0.5)"
      >
        <path
          id="Exclusion_1"
          data-name="Exclusion 1"
          d="M18,36A18,18,0,0,1,10.993,1.414,18,18,0,0,1,25.006,34.586,17.887,17.887,0,0,1,18,36Zm.392-24.286a5.048,5.048,0,0,0-2.789.822,5.332,5.332,0,0,0-1.967,2.416,8.909,8.909,0,0,0-.7,3.594,8.891,8.891,0,0,0,.714,3.632,5.047,5.047,0,0,0,4.839,3.2,9,9,0,0,0,2.5-.4V18.611H18.026V19.9h1.286v4.151c-.27.028-.5.041-.672.041a3.216,3.216,0,0,1-3.013-1.718,7.748,7.748,0,0,1-.954-3.81,8.035,8.035,0,0,1,.5-2.876,4.7,4.7,0,0,1,1.327-2,2.81,2.81,0,0,1,1.823-.681,5.3,5.3,0,0,1,2.656.772V12.253A7.531,7.531,0,0,0,18.392,11.714Z"
          fill="#b4ffff"
          stroke="#546b82"
          strokeWidth="1"
          opacity="0.85"
        />
      </g>
    </svg>
  );
};

export default GroupwareLogoIcon;
