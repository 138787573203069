import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import { useAuthState } from 'contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import LoginPage from './authentication/LoginPage';


export default function Home({ session }) {
  const { user } = useAuthState();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user.startPage === 'ApplicationList') {
        navigate('/applications/list');
      }
      else if (user.startPage === 'ClientList') {
        navigate('/clients/list');
      }
    }
  }, [user]);

  if (user) {
    return (
      <div></div>
    );
  } else {
    return (
      <>
        <LoginPage />
      </>
    );
  }
}
