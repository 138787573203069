import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import LotusDateOfBirth from "components/widgets/Forms/LotusDateOfBirth";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import { Formik, Form, yupToFormErrors } from 'formik';
import Typography from '@mui/material/Typography';
import { Button } from "@lotus/components";
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { useLists } from 'contexts/ListsContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useAgency } from 'contexts/AgencyContext';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useAuthState } from "contexts/AuthProvider";
import SocialSecurityNumberFieldPanel from 'components/widgets/Composites/SocialSecurityNumberFieldPanel';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import AddressFieldPanelWithLocationSearch from 'components/widgets/Composites/AddressFieldPanelWithLocationSearch';
import { PERMISSIONS } from 'lib/permissionEnums';
import LotusReadOnlyFieldView from 'components/widgets/Forms/LotusReadOnlyFieldView';


const RegisterClientForm = ({ handleSubmit, handleCancel }) => {
  const {agencyCaseManagers, loadAgencyCaseManagers} = useAgency();
  const [initialValues, setInitialValues] = useState();
  const [validationSchema, setValidationSchema] = useState();
  const {agenciesForGrantee, loadAgenciesForGrantee} = useAgencySearch();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  
  const {userAgency, userAgencyClientInfoConfig} = useUserAgency();
  const [registrationConfig, setRegistrationConfig] = useState({});

  const {birthGenders, currentGenderIdentities, phoneTypes } = useLists();

  const [birthGenderList, setBirthGenderList] = useState();
  const [currentGenderIdentityList, setCurrentGenderIdentityList] = useState();
  const [phoneTypeList, setPhoneTypeList] = useState();
  const [caseManagersList, setCaseManagersList] = useState();
  const [childAgenciesList, setChildAgenciesList] = useState();

  const hasLeadCaseManagerPermission = userAgency?.isGranteeAgency ? false : verifyPermission(PERMISSIONS.CAN_BE_A_LEAD_CASE_MANAGER);

  useEffect(() => {
    setInitialValues({
      firstName: '',
      lastName: '',
      middleName: '',
      nameSuffix: '',
      preferredName: '',
      birthDate: null,
      birthGenderId: '',
      agencyId: !userAgency.isGranteeAgency ? userAgency.id : null,
      caseManagerId: !userAgency.isGranteeAgency ? loggedInUser.id : null,
      currentGenderIdentityId: '',
      genderPronouns: '',
      hasSocialSecurityNumber: '',
      socialSecurityNumber: '',
      missingSsnReasonId: '',
      primaryAddress: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        county: '',
        zip: ''
      },
      primaryPhoneTypeId: '',
      cellPhone: '',
      workPhone: '',
      homePhone: '',
      otherPhone: '',
      emailAddress: ''
    });
    setValidationSchema(yup.object().shape({
    }));
  }, []);
  useEffect(() => {
    if (userAgency && userAgency.isGranteeAgency && userAgency.granteeAgencyId) {
      if (!agenciesForGrantee) {
        loadAgenciesForGrantee(userAgency.granteeAgencyId);
      }
    } else {
      setChildAgenciesList([{label: userAgency.name, value: userAgency.id}]);
    }
  }, [userAgency]);

  useEffect(() => {
    if(agenciesForGrantee && agenciesForGrantee.length > 0) {
      const childAgencies = agenciesForGrantee.filter(a => a.id !== userAgency.granteeAgencyId).map(c => {return {label: c.name, value: c.id}});
      setChildAgenciesList(childAgencies || []);
    }
  }, [agenciesForGrantee]);

  useEffect(() => {
    if (userAgency && userAgency.id && !userAgency.isGranteeAgency) {
      loadAgencyCaseManagers(userAgency.id);
    }
  }, [userAgency]);

  useEffect(() => {
    if(agencyCaseManagers) {
      const caseManagers = agencyCaseManagers[userAgency.id].map(c => {return {label: c.name, value: c.id}});
      setCaseManagersList(caseManagers);
    }
  }, [agencyCaseManagers]);

  useEffect(() => {
    if (birthGenders) {
      setBirthGenderList(birthGenders.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (currentGenderIdentities) {
      setCurrentGenderIdentityList(currentGenderIdentities.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (phoneTypes) {
      setPhoneTypeList(phoneTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [birthGenders, currentGenderIdentities, phoneTypes]);

  useEffect(() => {
    if (userAgencyClientInfoConfig) {
      setRegistrationConfig(userAgencyClientInfoConfig.registration || {});
    }
  }, [userAgencyClientInfoConfig]);

  return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={(values) => {
          let result = {};
          try {
            validationSchema.validateSync(values, { abortEarly: false });
          } catch (err) {
            result = yupToFormErrors(err);
          }
          return result;
        }}
        onSubmit={async (newValues, actions) => {
          await handleSubmit(newValues);
          actions.setSubmitting(false);
        }}
      >
        {({values, handleSubmit}) => {
          return values && (
            <Form className="form" onSubmit={handleSubmit} autoComplete="off" subscription={{ submitting: true, pristine: true }}>
              <LotusPageTitle title="Register Client">
                <Button variant="text" onClick={() => {handleCancel();}}>
                  <Typography variant="h6">Cancel</Typography>
                </Button>
                <Button onClick={() => {handleSubmit(values);}}>
                  <Typography variant="h6">Save</Typography>
                </Button>
              </LotusPageTitle>
              <LotusForm>
                <LotusFormItems>
                  <LotusTextInput
                    name="firstName"
                    label="First Name"
                    required
                    maxLength={35}
                  />
                  {registrationConfig.showMiddleName &&
                    <LotusTextInput
                      name="middleName"
                      label="Middle Initial"
                      maxLength={35}
                    />
                  }
                  <LotusTextInput
                    name="lastName"
                    label="Last Name"
                    required
                    maxLength={35}
                  />
                  {registrationConfig.showNameSuffix &&
                    <LotusTextInput
                      name="nameSuffix"
                      label="Name Suffix"
                      maxLength={10}
                    />
                  }
                  {registrationConfig.showPreferredName &&
                    <LotusTextInput
                      name="preferredName"
                      label="Preferred Name"
                      maxLength={70}
                    />
                  }
                  <LotusDateOfBirth
                    name="birthDate"
                    label="Date of Birth"
                    required
                  />
                  {registrationConfig.showBirthGender &&
                    <LotusRadioGroup
                      name="birthGenderId"
                      label="Gender at Birth"
                      items={birthGenderList}
                    />
                  }
                  <LotusRadioGroup
                    name="currentGenderIdentityId"
                    label="Current Gender Identity"
                    items={currentGenderIdentityList}
                    required
                  />
                  {registrationConfig.showGenderPronouns &&
                    <LotusTextInput
                      name="genderPronouns"
                      label="Gender Pronouns"
                      maxLength={35}
                    />
                  }
                  <SocialSecurityNumberFieldPanel
                    hasSocialSecurityNumberFieldName="hasSocialSecurityNumber"
                    socialSecurityNumberFieldName="socialSecurityNumber"
                    missingSsnReasonIdFieldName="missingSsnReasonId"
                    required 
                  />
                </LotusFormItems>
                <LotusFormSection name="Agency and Case Manager">
                  <LotusFormItem>
                    <LotusSelect
                      name="agencyId"
                      label="Agency Name"
                      items={childAgenciesList}
                      disabled={!userAgency?.isGranteeAgency}
                      autoSort={false}
                      required={userAgency?.isGranteeAgency}
                    />
                    </LotusFormItem>
                    <LotusFormItem style={{marginBottom: 0}}>
                      {caseManagersList && 
                        <LotusSelect
                          name="caseManagerId"
                          label="Lead Case Manager"
                          items={caseManagersList}
                          disabled={!hasLeadCaseManagerPermission}
                          autoSort={false}
                          required={!userAgency?.isGranteeAgency}
                        />
                      }
                      {!caseManagersList && 
                        <LotusReadOnlyFieldView label="Lead Case Manager" value="Unassigned" />
                      }
                  </LotusFormItem>
                </LotusFormSection>
                {(registrationConfig.showPrimaryAddress || registrationConfig.showEmailAddress || registrationConfig.showPhoneNumbers) &&
                  <LotusFormSection name="Contact Information" disabled={false}>
                    {registrationConfig.showPrimaryAddress &&
                      <LotusFormItem>
                        <AddressFieldPanelWithLocationSearch
                          name='primaryAddress' 
                          title='Primary Address' 
                        />
                      </LotusFormItem>
                    }
                    {registrationConfig.showEmailAddress &&
                      <LotusFormItem>
                        <LotusTextInput
                          name="emailAddress"
                          label="Email Address"
                          maxLength={254}
                        />
                      </LotusFormItem>
                    }
                    {registrationConfig.showPhoneNumbers &&
                      <LotusFormItems>
                        {registrationConfig.showPrimaryPhoneType &&
                          <LotusSelect
                            name='primaryPhoneTypeId' 
                            label='Primary Phone Type' 
                            items={phoneTypeList}/>
                        }
                        {registrationConfig.showCellPhone &&
                          <LotusPhoneInput
                            name='cellPhone' 
                            label='Cell Phone' 
                          />
                        }
                        {registrationConfig.showHomePhone &&
                          <LotusPhoneInput
                            name='homePhone' 
                            label='Home Phone' 
                          />
                        }
                        {registrationConfig.showWorkPhone &&
                          <LotusPhoneInput
                            name='workPhone' 
                            label='Work Phone' 
                          />
                        }
                        {registrationConfig.showOtherPhone &&
                          <LotusPhoneInput
                            name='otherPhone' 
                            label='Other Phone' 
                          />
                        }
                      </LotusFormItems>
                    }
                  </LotusFormSection>
                }
              </LotusForm>
            </Form>
          );
        }}
      </Formik>);
}

export default function AddClientForm({ ...props }) {
  return (
      <RegisterClientForm {...props} />
  );
}
