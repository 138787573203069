import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import { useClient } from 'contexts/ClientContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { useFormikContext } from 'formik';
import Alert from '@mui/material/Alert';
import ClientLabResultsList from './ClientServices/ClientLabResultsList';
import ClientDispensesList from './ClientServices/ClientDispensesList';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';

const validationSchema = Yup.object().shape({});

const buildSectionNames = (existingClientConfig) => {
  const lst = [
    "General", 
  ];
  if (existingClientConfig.showHivStatusSubcategory) {
    lst.push("HIV Status");
    lst.push("Proof of HIV Status");
    lst.push("Lab Results");
    lst.push("Dispenses");
  }
  return lst;
}

const ClientHealthStaticForm = ({clientId, existingClientConfig, disabled, updateRestrictions, testResultConfig, dispensesConfig}) => {

  const { hivDiseaseStages, hivRiskFactors, arvDenialReasons} = useLists();

  const [hivRiskFactorList, setHivRiskFactorList] = useState();
  const [arvDenialReasonList, setArvDenialReasonList] = useState();

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (hivRiskFactors) {
      setHivRiskFactorList(hivRiskFactors.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (arvDenialReasons) {
      setArvDenialReasonList(arvDenialReasons.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [hivDiseaseStages, hivRiskFactors, arvDenialReasons]);

  useEffect(() => {
    if(values.hivStatusId !== hivDiseaseStages.find(s => s.itemKey === 'aids').id) {
      setFieldValue('aidsDiagnosisDate', null);
    }
    if(values.hivStatusId !== hivDiseaseStages.find(s => s.itemKey === 'aids').id &&
      values.hivStatusId !== hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id &&
      values.hivStatusId !== hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) {
        setFieldValue('aidsDiagnosisDate', null);
        setFieldValue('hivDiagnosisDate', null);
        setFieldValue('hivRiskFactors', []);
    }
  }, [values.hivStatusId]);

  useEffect(() => {
    if(values.antiretroviralTherapyStatus !== false) {
      setFieldValue('antiretroviralDenialReasons', []);
      setFieldValue('antiretroviralOtherReason', '');
    }
    if(values.antiretroviralTherapyStatus !== true) {
      setFieldValue('antiretroviralTherapyStartDate', null);
    }
    if(!values.hasAllergies) {
      setFieldValue('currentAllergies', '');
    }
  }, [values.antiretroviralTherapyStatus, values.hasAllergies]);

  return values && existingClientConfig && (
    <LotusForm>
      {updateRestrictions && (updateRestrictions.all || updateRestrictions.health) &&
      <LotusFormSection>
        <LotusFormItem>
          <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
        </LotusFormItem>
      </LotusFormSection>
      }
      <LotusFormSection name="General" disabled={disabled}>
        <LotusFormItems>
          <LotusTrueFalseRadioGroup
            name="pregnancyStatus"
            label="Is client pregnant?"
            disabled={disabled}
          />
          <LotusTrueFalseSelect
            name="hasAllergies"
            label="Does Client have any current allergies?"
            disabled={disabled}
          />
          {values.hasAllergies === true &&
            <LotusTextInput
              name="currentAllergies"
              label="Current Allergies"
              disabled={disabled}
              maxLength={500}
              multiline
              rows={5}
            />
          }
          <LotusTextInput
            name="complicatingMedicalConditions"
            label="Does Client have any current complicating medical conditions?"
            disabled={disabled}
            maxLength={500}
            multiline
            rows={5}
          />
          <LotusTrueFalseSelect
            name="hepatitisCDiagnosis"
            label="Has the applicant received a current diagnosis for Hepatitis C?"
            disabled={disabled}
          />
        </LotusFormItems>
      </LotusFormSection>
      {existingClientConfig.showHivStatusSubcategory && hivDiseaseStages &&
      <LotusFormSection name="HIV Status" disabled={disabled}>
        <LotusFormItem>
          <LotusRadioGroup
            name="hivStatusId"
            label="Current Disease Stage"
            items={hivDiseaseStages.map(s => {return {value: s.id, label: s.itemName}})}
            disabled={disabled}
          />
        </LotusFormItem>
        {values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id &&
          <LotusFormItem>
            <LotusDatePicker
              name="aidsDiagnosisDate"
              label="Estimated Date AIDS Diagnosed"
              disabled={disabled}
            />
          </LotusFormItem>
        }
        {(values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
            values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id ||
            values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) &&
          <LotusFormItem>
            <LotusDatePicker
              name="hivDiagnosisDate"
              label="Estimated Date HIV Diagnosed"
              disabled={disabled}
            />
          </LotusFormItem>
        }
        {hivRiskFactorList &&
            (values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
              values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id ||
              values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) &&
          <LotusFormItem>
            <LotusMultiSelect
              name="hivRiskFactors"
              label="Identified HIV Risk Factor(s)"
              items={hivRiskFactorList}
              disabled={disabled}
              readOnlyRows={5}
            />
          </LotusFormItem>
        }
        <LotusFormItems>
          <LotusProgressiveDisclosure
            name="antiretroviralTherapyStatus"
            label="Are you currently on Antiretroviral Therapy?"
            disabled={disabled}
          >
            <LotusProgressiveDisclosureItem
              itemValue='true'
              label="Yes"
            >
              <LotusDatePicker
                name="antiretroviralTherapyStartDate"
                label="Estimated Date Antiretroviral Therapy Started"
                disabled={disabled}
              />
            </LotusProgressiveDisclosureItem>
            <LotusProgressiveDisclosureItem
              itemValue='false'
              label="No" 
            >
              {arvDenialReasonList && 
                <LotusCheckboxList
                  name="antiretroviralDenialReasons"
                  otherItemName="antiretroviralOtherReason"
                  label="Reason not on ARV"
                  items={arvDenialReasonList}
                  disabled={disabled}
                />
              }
            </LotusProgressiveDisclosureItem>
          </LotusProgressiveDisclosure>
          <LotusTextInput
            name="hivCurrentArvCount"
            label="Current ARV Count"
            disabled={disabled}
            maxLength={4}
            integer
          />
          <LotusTextInput
            name="hivCd4"
            label="CD4"
            disabled={disabled}
            maxLength={4}
            integer
          />
          <LotusTextInput
            name="hivViralLoads"
            label="Viral Loads"
            disabled={disabled}
            maxLength={4}
            integer
          />
        </LotusFormItems>
      </LotusFormSection>
      }
      {existingClientConfig.showHivStatusSubcategory &&
        <LotusFormSection name="Proof of HIV Status" disabled={disabled}>
          <LotusFormItem>
            <div>I certify that the above-named individual is HIV positive. I certify that I have explained (or read) the Terms and Conditions for Application and Interviewer to the client who is requesting Ryan White services.</div>
          </LotusFormItem>
          <LotusFormItem>
            <LotusTextInput
              name="hivCertificationSignature"
              label="Interviewer Signature"
              disabled={true}
            />
          </LotusFormItem>
          <LotusFormItem>
            <LotusDatePicker
              name="hivCertificationDate"
              label="Date Signed"
              disabled={true}
            />
          </LotusFormItem>
        </LotusFormSection>
      }
      <LotusFormSection name="Lab Results" disabled={disabled}>
        <LotusFormItem>
          <ClientLabResultsList
            testResultConfig={testResultConfig}
            labResults="labResults"
            clientId={clientId}
          />
        </LotusFormItem>
      </LotusFormSection>
      <LotusFormSection name="Dispenses" disabled={disabled}>
        <LotusFormItem>
          <ClientDispensesList
            dispensesConfig={dispensesConfig}
            dispenses="dispenses"
          />
        </LotusFormItem>
      </LotusFormSection>
      {/* 
      Not using this for now in favor of the section above, but suspect it will return
      {existingClientConfig.showHivStatusSubcategory &&
        <LotusFormSection name="Proof of HIV Documentation" disabled={disabled}>
          <LotusFormItem>
            <ClientInfoDocumentation 
              documentationListFieldName="hivDocumentation"
              allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoHivDocumentationTypes || []}
              clientId={clientId}
              disabled={disabled}
            />
          </LotusFormItem>
      </LotusFormSection>
      } */}
    </LotusForm>
  );
};

export function buildStaticForm({...props}) {
  return (
      <ClientHealthStaticForm {...props}/>
  );
}

export default function ClientHealthForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions, testResultConfig, dispensesConfig}) {

  const { clientHealth, loadClientHealth, upsertClientHealth } = useClient();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (client) {
      loadClientHealth(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (clientHealth) {
      setInitialValues( {
        hivStatusId: clientHealth.hivStatusId || '', 
        hivUnknownStatusDate: clientHealth.hivUnknownStatusDate || null, 
        hivDiagnosisDate: clientHealth.hivDiagnosisDate || null,
        aidsDiagnosisDate: clientHealth.aidsDiagnosisDate || null, 
        hivRiskFactors: clientHealth.hivRiskFactors || [],  
        hepatitisCDiagnosis: clientHealth.hepatitisCDiagnosis !== null ? clientHealth.hepatitisCDiagnosis : '', 
        pregnancyStatus: clientHealth.pregnancyStatus !== null ? clientHealth.pregnancyStatus : false, 
        antiretroviralTherapyStatus: clientHealth.antiretroviralTherapyStatus !== null ? clientHealth.antiretroviralTherapyStatus : false, 
        antiretroviralTherapyStartDate: clientHealth.antiretroviralTherapyStartDate || null,  
        antiretroviralDenialReasons: clientHealth.antiretroviralDenialReasons || [],
        antiretroviralOtherReason: clientHealth.antiretroviralOtherReason || '',  
        hasAllergies: clientHealth.hasAllergies !== null ? clientHealth.hasAllergies : '',  
        currentAllergies: clientHealth.currentAllergies || '', 
        complicatingMedicalConditions: clientHealth.complicatingMedicalConditions || '',  
        hivCurrentArvCount: clientHealth.hivCurrentArvCount !== null ? clientHealth.hivCurrentArvCount : '', 
        hivCd4: clientHealth.hivCd4 !== null ? clientHealth.hivCd4 : '',
        hivViralLoads: clientHealth.hivViralLoads !== null ? clientHealth.hivViralLoads : '',
        hivCertificationDate: clientHealth.hivCertificationDate || null,
        hivCertificationSignature: clientHealth.hivCertificationSignature || '',
        hivDocumentation: clientHealth.hivDocumentation || [],
        labResults: clientHealth.labResults || [],
        dispenses: clientHealth.dispenses || []
      });
    }
  }, [clientHealth]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      hivStatusId, hivUnknownStatusDate,
      hivDiagnosisDate, aidsDiagnosisDate, hivRiskFactors, hepatitisCDiagnosis,
      pregnancyStatus, antiretroviralTherapyStatus, antiretroviralTherapyStartDate, antiretroviralDenialReasons,
      antiretroviralOtherReason, hasAllergies, currentAllergies, complicatingMedicalConditions,
      hivCurrentArvCount, hivCd4, hivViralLoads, hivDocumentation, hivCertificationDate, hivCertificationSignature,
      ...dynamicFormData} = formData;

    const clientHealth = {
      hivStatusId, hivUnknownStatusDate,
      hivDiagnosisDate, aidsDiagnosisDate, hivRiskFactors, hepatitisCDiagnosis,
      pregnancyStatus, antiretroviralTherapyStatus, antiretroviralTherapyStartDate, antiretroviralDenialReasons,
      antiretroviralOtherReason, hasAllergies, currentAllergies, complicatingMedicalConditions,
      hivCurrentArvCount, hivCd4, hivViralLoads, hivDocumentation, hivCertificationDate, hivCertificationSignature,
    };
    return [clientHealth, dynamicFormData];
  }

  const saveForm = async (values) => {
    await upsertClientHealth(client.id, values);
  }

  return initialValues && (
    <HybridForm 
      title="Health"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled || updateRestrictions?.all || updateRestrictions?.health, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions, testResultConfig: testResultConfig, dispensesConfig: dispensesConfig})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.health}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}