import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import LotusFileViewer  from 'components/widgets/LotusFileViewer';

export default function DocumentationList ({ clientId, data, allDocumentationTypes}) {
  
  const [documentationToView, setDocumentationToView] = useState();
  const [documentationToViewMetaData, setDocumentationToViewMetaData] = useState();
  const [documentationListForTable, setDocumentationListForTable] = useState();

  useEffect(() => {
    if (data && allDocumentationTypes) {
      const documentationList = data.map((doc) => {
        let newDoc = {
          documentationTypeName: allDocumentationTypes.find(d => d.id === doc.documentationTypeId)?.name,
          documentationFileNames: doc.documentationFiles.map(f => f.displayName).join(', '),
          dateUploaded: doc.dateUploaded ? doc.dateUploaded : moment(new Date()).format("MM/DD/YYYY"),
          documentationFiles: doc.documentationFiles
        };
        return newDoc;
      });
      documentationList.sort((a, b) => (a.documentationTypeName > b.documentationTypeName ? -1 : 1));
      setDocumentationListForTable(documentationList);
    }
  }, [data, allDocumentationTypes]);

  const columns = [
    {
      label: 'Documentation type',
      name: 'documentationTypeName',
      options: {
        sort: false
      },
    },
    {
      label: 'Upload date',
      name: 'dateUploaded',
      options: {
        sort: false
      },
    }
  ];

  const viewClientDocumentationForRow = async (rowData, rowMeta) => {
    const clientDocumentation = documentationListForTable[rowMeta.dataIndex];
    clientDocumentation.dateUploaded = clientDocumentation?.dateUploaded ? 
                                      clientDocumentation?.dateUploaded : 
                                      moment(new Date()).format("MM/DD/YYYY");
    setDocumentationToView(clientDocumentation);

    const metaData = {
      field1:{label:'Documentation Type:', value: clientDocumentation?.documentationTypeName},
      field3:{label:'Upload Date:', value: clientDocumentation?.dateUploaded}
    };
    setDocumentationToViewMetaData(metaData);                                
  };

  const closeDocumentationView = () => {
    setDocumentationToView(null);
    setDocumentationToViewMetaData(null);
  };

  return (
    <div>
      {documentationListForTable && (
        <Table
          columns={columns}
          data={documentationListForTable}
          options={{
            print: false,
            download: false,
            filter: false,
            search: false,
            viewColumns: false,
          }}
          handleRowClicked={viewClientDocumentationForRow}
        />
      )}

      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentationToView) && Boolean(documentationToViewMetaData)}
        content={
          <LotusFileViewer 
            clientId={clientId} 
            subfolderName="documents"
            title={documentationToView?.documentationTypeName || ''} 
            metaData={documentationToViewMetaData} 
            file={documentationToView?.documentationFiles} 
            handleClose={closeDocumentationView}
          />
        }
      />
    </div>
  );
};