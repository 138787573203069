import React from 'react';
import ApplicationList from './ApplicationList';
import { EligibilityApplicationSearchProvider } from 'contexts/EligibilityApplicationSearchContext';

export default function Applications() {

  return (
    <EligibilityApplicationSearchProvider>
      <ApplicationList />
    </EligibilityApplicationSearchProvider>
  );
}
