import React, { useEffect, useState } from 'react';
import LotusAutocomplete from 'components/widgets/Forms/LotusAutocomplete';
import { useLists } from 'contexts/ListsContext';

export default function LotusCountySelector({
  state,
  useSeedData,
  ...props
}) {
  
  const { countiesByState, loadCountiesForState } = useLists();
  const [countyList, setCountyList] = useState();

  useEffect(() => {
    if (useSeedData) {
      if (state === 'WI') {
        setCountyList(['Brown','Dane','Milwaukee']);
      }
      if (state === 'IL') {
        setCountyList(['Cook', 'Iroquois']);
      }
      if (state === 'MN') {
        setCountyList(['Goodhue', 'Wabasha']);
      }
    } else if (state && (!countiesByState || state in countiesByState === false)) {
      loadCountiesForState(state);
    }
  }, [useSeedData, state]);

  useEffect(() => {
    if (countiesByState && state in countiesByState) {
      setCountyList(countiesByState[state]);
    } else {
      setCountyList([]);
    }
  }, [state, countiesByState]);

  return (
    <LotusAutocomplete
      {...props}
      options={countyList || []}
    />
  );
}
