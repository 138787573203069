import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusForm from 'components/widgets/Forms/LotusForm';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramAdditionalClientDataForm({handleSubmit}) {

  const { currentProgram } = useProgramManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (currentProgram) {
      let defaultValues = {
        diagnosisRecords: false,
        consents: false,
        documents: false,
      };
      setInitialValues({ ...defaultValues, ...currentProgram.additionalClientData });
    }
  }, [currentProgram]);
  
  return (
    <LotusPageSection header="Additional Client Data">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="diagnosisRecords"
                    value={values.diagnosisRecords}
                    label="Diagnosis Records"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="consents"
                    value={values.consents}
                    label="Consents"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="documents"
                    value={values.documents}
                    label="Documents"
                    labelPlacement="end"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
