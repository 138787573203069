import React from 'react';
import ActionDialog from 'components/Dialogs/ActionDialog';
import ApproveUserRequestForm from '../forms/ApproveUserRequestForm';
import LotusButton from 'components/widgets/Forms/LotusButton';

export default ({ actionRequest, open, handleClose, handleSubmit }) => {
  return (
    <ActionDialog
      title={`Approve User Request`}
      open={open}
      handleClose={handleClose}
      content={<ApproveUserRequestForm actionRequest={actionRequest} />}
      actions={
        <div>
          <LotusButton onClick={handleClose}>
            Cancel
          </LotusButton>

          <LotusButton onClick={() => handleSubmit(actionRequest)}>
            Approve Request
          </LotusButton>
        </div>
      }
    />
  );
};
