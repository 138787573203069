import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import { useField } from 'formik';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';
import LotusSideBySide from './LotusSideBySide';

export default function LotusDateOfBirth({ disabled, required, dontCheckRequiredFields, ...props }) {

  const [field, meta] = useField({...props});

  const [age, setAge] = useState('');
  
  const handleDateChanged = async (dt) => {
    if (dt) {
      updateAge(dt);
    }
  }

  useEffect(() => {
    updateAge(field.value);
  }, [field.value]);

  const updateAge = (dobStr) => {
    var specifiedDate = moment(dobStr, 'MM/DD/YYYY', true);
    if (specifiedDate.isValid()) {
      const diff = moment().diff(specifiedDate, 'years');
      if (diff >= 0) {
        setAge(diff);
      } else {
        setAge('');
      }
    } else {
      setAge('');
    }
  };

  return !disabled ? (
    <LotusSideBySide
      leftControl={
        <LotusDatePicker
          disabled={disabled}
          required={required}
          dontCheckRequiredFields={dontCheckRequiredFields}
          maxDate={moment().format('MM/DD/YYYY')}
          handleDateChange={handleDateChanged}
          {...props}
        />
      }
      rightControl={
        <Typography variant="body2" style={{width: 100}}>
          <span className="readOnlyFormLabel">Age: </span><span className="readOnlyFormValue">{age}</span>
        </Typography>
      }
      alignItems="center"
    />) : (
     <LotusSideBySide
      leftControl={
      <div>
        <LotusReadOnlyFieldView label={props.label} value={field.value || ''} />
        {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
      </div>}
      rightControl={<LotusReadOnlyFieldView label="Age" value={age}/>}
    />
    );
}
