import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusPage from 'components/widgets/Layout/LotusPage';
import ImportsTable from './ImportsTable';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useDataImport } from 'contexts/DataImportContext';
import { useDataExport } from 'contexts/DataExportContext';

import ExportsTable from './ExportsTable';
import DataDictionaryForm from './DataDictionaryForm';
import { Stack } from '@mui/material';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusMainTabSpacer from 'components/widgets/Layout/LotusMainTabSpacer';
import DataImportForm from './forms/DataImportForm';
import DataExportForm from './forms/DataExportForm';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { PERMISSIONS } from 'lib/permissionEnums'
import { useAuthState } from 'contexts/AuthProvider';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default function ImportsExportsPage() {

  const [tabs, setTabs] = useState();
  const { view } = useParams();
  const navigate = useNavigate();
  const [tabName, setTabName] = useState();
  const [showImportForm, setShowImportForm] = useState(false);
  const [showExportForm, setShowExportForm] = useState(false);
  const { userAgency } = useUserAgency();
  const { submitImportRequest, getImportRequests } = useDataImport();
  const { submitExportRequest,  getExportRequests } = useDataExport();

  const { verifyPermission } = useAuthState();
  const hasExport = verifyPermission(PERMISSIONS.EXPORT_CARRIER_PREMIUMS)
    || verifyPermission(PERMISSIONS.EXPORT_APPLICATION_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_AGENCY_CONFIGURATION_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_ACCOUNT_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_CMS)
    || verifyPermission(PERMISSIONS.EXPORT_COMPREHENSIVE_CLIENT_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_CLIENT_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_NC_ECHO)
    || verifyPermission(PERMISSIONS.EXPORT_CMS_RESPONSE_DATA)
    || verifyPermission(PERMISSIONS.EXPORT_SERVICES_DATA);
  const hasImport = verifyPermission(PERMISSIONS.IMPORT_CLIENT_DATA)
    || verifyPermission(PERMISSIONS.IMPORT_SERVICES_DATA)
    || verifyPermission(PERMISSIONS.IMPORT_ACCOUNT_DATA)
    || verifyPermission(PERMISSIONS.IMPORT_CMS_RESPONSE_DATA)
    || verifyPermission(PERMISSIONS.IMPORT_AGENCY_CONFIGURATION_DATA);


  useEffect(() => {
    if (!view) {
      navigate('/imports/list/imports');
    }
    const tabsForPage = [];
    if (hasImport) {
      tabsForPage.push({
        label: 'Imports',
        link: '/imports/list/imports'
      });
    }
    if (hasExport) {
      tabsForPage.push({
        label: 'Exports',
        link: '/imports/list/exports'
      });
    }
    if (hasImport || hasExport) {
      tabsForPage.push({
        label: 'Data Dictionary',
        link: '/imports/list/dataDictionary'
      });
    }
    
    setTabs(tabsForPage);
  }, []);

  useEffect(() => {
    if (view) {
      switch (view.toLowerCase()) {
        case 'datadictionary': {
          setTabName('dataDictionary');
          break;
        }
        case 'exports': {
          setTabName('exportsTable');
          break;
        }
        default: {
          setTabName('imports');
          break;
        }
      }
    }
  }, [view]);

  const handleCreateImportClicked = async () => {
    setShowImportForm(true);
  };

  const handleCreateExportClicked = async () => {
    setShowExportForm(true);
  };


  const handleRefreshClick = async () => {
    if ((hasImport && tabName === 'imports') || (hasExport && tabName === 'exportsTable')) {
      tabName === 'imports' ? getImportRequests(userAgency.id) : getExportRequests(userAgency.id);
    }
  };


  const closeForm = async () => {
    setShowImportForm(false);
    setShowExportForm(false);
  };

  const submitRequest = async (documentName, importTypeId, description,
    displayFileName, delimiter, isTestMode) => {
    await submitImportRequest(
      userAgency.id,
      importTypeId,
      documentName,
      description,
      displayFileName,
      delimiter,
      isTestMode
    );
  };

  const handleSubmitExportRequest = async (values, typeName) => {
    const cleanDesc = values.desc.replace(/[^\w\d_\-\.]+/gi, '');
    let vals = [typeName || values.exportType];
    vals.push(cleanDesc.toLowerCase());
    vals.push(moment().format('YYYYMMDD'));
    const basename = vals.join('_');
    const extension = values.delimiter === 'comma' ? 'csv' : 'txt';

    let displayFileName = basename + '.' + extension;
    let uniqueFileNmae = basename + '_' + uuidv4() + '.' + extension;

    await submitExportRequest(
      userAgency.id,
      values.exportType,
      uniqueFileNmae,
      values.desc,
      displayFileName,
      values.delimiter,
      values.startDate
    );
  };

  return tabs && view && (
    <LotusPage>
      <LotusPageTitle title="Imports & Exports">

        {tabName === 'imports' &&
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <LotusButton onClick={handleRefreshClick}>
              Refresh List
            </LotusButton>
            <LotusButton variant="contained" color="primary" onClick={handleCreateImportClicked}>
              New Import
            </LotusButton>
          </Stack>
        }
        {tabName === 'exportsTable' &&
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <LotusButton onClick={handleRefreshClick}>
              Refresh List
            </LotusButton>
            <LotusButton variant="contained" color="primary" onClick={handleCreateExportClicked}>
              New Export
            </LotusButton>
          </Stack>
        }

      </LotusPageTitle>
      <LotusMainTabs
        selectedTabName={view}
        tabs={tabs}
      />
      <LotusMainTabSpacer />
      {tabName === 'imports' && hasImport &&
        <ImportsTable agencyId={userAgency.id} />
      }
      {tabName === 'exportsTable' &&
        <ExportsTable agencyId={userAgency.id} />
      }
      {tabName === 'dataDictionary' && (hasImport || hasExport) &&
        <DataDictionaryForm tableName={tabName} />
      }

      <ActionDialog
        maxWidth="sm"
        open={showImportForm}
        handleClose={closeForm}
        content={
          <DataImportForm
            agencyId={userAgency.id}
            handleSubmit={submitRequest}
            afterSubmit={closeForm}
          />
        }
      />
      <ActionDialog
        maxWidth="sm"
        open={showExportForm}
        handleClose={closeForm}
        content={
          <DataExportForm
            agencyId={userAgency.id}
            handleSubmit={handleSubmitExportRequest}
            afterSubmit={closeForm}
          />
        }
      />

    </LotusPage>

  );
}
