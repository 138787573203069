import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LotusShowHideButton from 'components/widgets/Forms/LotusShowHideButton';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import LotusFileViewer from 'components/widgets/LotusFileViewer';
import { createTheme, Divider, Stack, ThemeProvider, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserAgency } from 'contexts/UserAgencyContext';
import moment from 'moment';


export function EventDetailsField({label, value}) {
  return (
    <Stack direction="column" justifyContent="flex-start" gap={1} style={{paddingTop: "12px 0"}}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  );
}

export function EventDetailsDocuments({details, showCategory=true}) {
  const { clientId } = useParams();
  const [ allDocuments, setAllDocuments ] = useState();
  const { userAgencyClientInfoCustomLists, loadUserAgencyClientInfoCustomLists } = useUserAgency();
  const [ documentToView, setDocumentToView] = useState();

  useEffect(() => {
    if (!userAgencyClientInfoCustomLists) {
      loadUserAgencyClientInfoCustomLists();
    }
  }, []);

  useEffect(() => {
    const allDocs = []; 
    if (details && userAgencyClientInfoCustomLists) {
      if (details.income?.documentation?.length > 0) {
        details.income.documentation.forEach(d => {
          allDocs.push({
            category: 'Income',
            type: userAgencyClientInfoCustomLists.clientInfoIncomeDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
      if (details.residency?.documentation?.length > 0) {
        details.residency.documentation.forEach(d => {
          allDocs.push({
            category: 'Residency',
            type: userAgencyClientInfoCustomLists.clientInfoAddressDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
      if (details.hivStatus?.documentation?.length > 0) {
        details.hivStatus.documentation.forEach(d => {
          allDocs.push({
            category: 'HIV Status',
            type: userAgencyClientInfoCustomLists.clientInfoHivDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
      if (details.insurance?.documentation?.length > 0) {
        details.insurance.documentation.forEach(d => {
          allDocs.push({
            category: 'Insurance',
            type: userAgencyClientInfoCustomLists.clientInfoInsuranceDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
    }
    setAllDocuments(allDocs);
  }, [details, userAgencyClientInfoCustomLists]);

  const docColumns = [];

  docColumns.push({
    label: 'Type',
    name: 'type',
    options: {
      customBodyRender: (value, tableMeta, ) => {
        let idx = tableMeta.rowIndex;
        return (
          <div
            onClick={() => viewDocumentForRow(idx)}
            style={{ cursor: 'pointer', textDecoration: 'underline', color: '#006B79', width: 'fit-content'}}
          >
            {value}
          </div>
        );
      },
      setCellHeaderProps: value => {
        return {
          style: {
            width: '50%',
          },
        };
      },
    },
  });

  if (showCategory) {
    docColumns.push({
      label: 'Category',
      name: 'category'
    });
  }

  docColumns.push({
    label: 'Date Uploaded',
    name: 'dateUploaded',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let result;
          if (obj1.data === obj2.data) { result = 0; }
          let val1 = moment(obj1.data, 'MM/DD/YYYY');
          let val2 = moment(obj2.data, 'MM/DD/YYYY');
          result = (val1 < val2) ? (order === 'asc' ? -1 : 1) : (order === 'asc' ? 1 : -1);
          return result;
        };
      }
    }
  });

  const viewDocumentForRow = async (idx) => {
    const doc = allDocuments[idx];
    setDocumentToView(doc);                  
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  return allDocuments && (
    <>
      <Table
        columns={docColumns}
        data={allDocuments}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          search: false,
          pagination: false,
          elevation: 0,
          textLabels: {
            body: {
              noMatch: "Document history not available. Please refer to physical documentation on file."
            }
          }
        }}
      />
      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentToView)}
        content={
          (documentToView && 
            <LotusFileViewer
              clientId={clientId} 
              subfolderName="documents"
              title={documentToView.type || ''} 
              file={documentToView.files} 
              handleClose={closeDocumentView}
            />
          )
        }
      />
    </>
  );    
}

export function EventDetailsSection({title, children}) {
  const theme = useTheme();

  return (
    <div style={{marginBottom: 20, paddingTop: 12, paddingBottom: 12, borderRadius: "8px", border: "1px solid rgba(0, 0, 0, 0.23)"}}>
      <div style={{paddingLeft: 16}}>
        <Typography variant="subtitle1">{title}</Typography>
      </div>
      <Divider style={{color: theme.palette.other.divider, margin: "12px 0"}}/>
      <div style={{padding: "0 16px 12px 16px"}}>
        {children}
      </div>
    </div>
  );
}

export function EventDetailsSubSection({title, children}) {
  const theme = useTheme();
  
  return (
    <div>
      <Divider style={{color: theme.palette.other.divider, margin: "12px 0"}}/>
      <div style={{paddingTop: 12, paddingBottom: 12}}>
        <Typography variant="body1" style={{paddingBottom: 12}}>{title}</Typography>
        {children}
      </div>
    </div>
  );
}

export function HistoricalEvents({hist, periodTitleFunc, detailRetriever, EventDetailDisplayer}) {

  const [eventDetailsToShow, setEventDetailsToShow] = useState();

  // Starting to build up a new theme for use going forward
  // For now, only using it in the eligibility history (and overriding existing theme elements)
  const [theTheme, setTheTheme] = useState();

  useEffect(() => {
    const theme = createTheme({
      typography: {
        h3: {
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '160%',
          letterSpacing: 0.15
        },
        body1: {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%',
          letterSpacing: 0.15
        },
        body2: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '143%',
          letterSpacing: 0.17,
          color: 'rgba(0,0,0,0.87)'
        },
        overline: {
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '266%',
          letterSpacing: '1px',
          textTransform: 'uppercase'
        },
        subtitle1: {
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '175%',
          letterSpacing: '0.15px'
        }
      },
      palette: {
        primary: {
          main: '#294055',
          light: '#546B82',
          dark: '#001a2c',
        },
        secondary: {
          main: '#2699A8',
          light: '#E0F1F3',
          dark: '#006b79',
        },
        error: {
          main: '#D32F2F',
          dark: '#B01A1A',
          light: '#FBEAEA'
        },
        background: {
          default: '#fff',
          grey: '#F7F7F7',
        },
        success: {
          main: '#2E7D32',
          light: '#EAF2EA',
          dark: '#1B5E20',
        },
        text: {
          primary: '#000000',
          secondary: '#666666',
          selected: '#B4FFFF',
          completed: 'green',
          tabs: '#7A7A7A',
          outlinedButtonText: '#4A97A6',
        },
        other: {
          divider: 'rgba(0,0,0,0.12)'
        }
      },
      components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
            root: {
              backgroundColor: "rgba(41, 64, 85, 0.04)"
            }
          }
        }
      }
    });

    setTheTheme(theme);
  }, []);

  function hideDetails(appId) {
    let newShowList = (eventDetailsToShow || []).filter(x => x !== appId);
    setEventDetailsToShow(newShowList);
  }

  function showDetails(appId) {
    let newShowList = (eventDetailsToShow || []).filter(x => x !== appId);
    newShowList.push(appId);
    setEventDetailsToShow(newShowList);
  }

  return theTheme && hist && hist.periods && (
    hist.periods.map((period) => (
      <div key={period.startDate}>
        <ThemeProvider theme={theTheme}>
          <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: 'rgba(0, 0, 0, 0.08)' }}>
            <Typography variant="overline" style={{color: 'rgba(0, 0, 0, 0.68)'}}>{periodTitleFunc(period)}</Typography>
          </div>
          {period.events && period.events.length > 0 && period.events.map((evt, evtIdx) => {
            return (
              <Stack key={evt.createDate} direction="row">
                <div style={{ width: 116, paddingTop: 18, paddingRight: 4, textAlign: 'right' }}>
                  <Typography variant="body1">{evt.date}</Typography>
                </div>
                <div style={{ width: 28, paddingLeft: 4, paddingRight: 4, flexDirection: 'column', justifyContent: 'flex-start', display: 'flex' }}>
                  <div style={{ height: 18, justifyContent: 'center', display: 'flex' }}>
                    {(evtIdx !== 0 || period.events.length === 1) &&
                      <div style={{ width: 2, alignSelf: 'stretch', background: theTheme.palette.other.divider }} />
                    }
                  </div>
                  <div>
                    {evt.icon === 'ending' && <WarningAmberIcon style={{width: 20, color: theTheme.palette.warning.main}} />}
                    {evt.icon === 'ended' && <CancelIcon style={{width: 20, color: theTheme.palette.error.dark}} />}
                    {evt.icon === 'started' && <CheckCircleIcon style={{width: 20, color: theTheme.palette.success.main}} />}
                    {evt.icon === 'renewed' && <ChangeCircleIcon style={{width: 20, color: theTheme.palette.success.main}} />}
                    {evt.icon === 'update' && <ChangeHistoryIcon style={{width: 20, color: theTheme.palette.success.main}} />}
                  </div>
                  <div style={{ alignSelf: 'stretch', justifyContent: 'center', display: 'flex', flex: '1 1 0' }}>
                    {(evtIdx !== 0 || period.events.length !== 1) &&
                      <div style={{ width: 2, alignSelf: 'stretch', background: theTheme.palette.other.divider }} />
                    }
                  </div>
                </div>
                <div style={{ borderBottom: '1px rgba(0, 0, 0, 0.12) solid', width: '100%'}}>
                  <Stack direction="row" justifyContent='space-between' alignItems='center'>
                    <div style={{ paddingTop: 18, paddingBottom: 18, paddingLeft: 4 }}>
                      <Typography variant="body1">{evt.label}</Typography>
                      {evt.subtext && 
                        <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.60)'}}>{evt.subtext}</Typography>
                      }
                    </div>
                    {!evt.detailsUnavailable &&
                      <div>
                        <LotusShowHideButton 
                          onShow={() => {showDetails(evt.id); detailRetriever(evt.id);}} 
                          onHide={() => hideDetails(evt.id)}/>
                      </div>
                    }
                  </Stack>
                  {Boolean(eventDetailsToShow) && eventDetailsToShow.includes(evt.id) && evt.details && 
                    <div>
                      <EventDetailDisplayer details={evt.details} />
                    </div>
                  }
                </div>
              </Stack>
          )})}
        </ThemeProvider>
      </div>
    ))
  );
}