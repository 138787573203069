import React, { useEffect, useState } from 'react';
import { useClientCarePlan } from 'contexts/ClientCarePlanContext';
import BarriersToCare from 'components/BarriersToCare';

export const BarriersToCareTab = ({ allowEdit, clientId, programId, assessmentId, barrierDisplayName }) => {

  const [barrierSummariesForAssessment, setBarrierSummariesForAssessment] = useState();

  const {barrierSummaries, loadBarrierSummariesForClientAndProgram} = useClientCarePlan();

  useEffect(() => {
    if (clientId && programId && (!barrierSummaries || programId in barrierSummaries === false)) {
      loadBarrierSummariesForClientAndProgram(clientId, programId);
    }
  }, [clientId, programId]);

  useEffect(() => {
    if (barrierSummaries && programId in barrierSummaries) {
      const lst = barrierSummaries[programId];
      const recs = lst.filter((b) => b.assessmentId === assessmentId);
      setBarrierSummariesForAssessment(recs ? [...recs] : []);
    }
  }, [programId, barrierSummaries]);

  return barrierSummariesForAssessment && (
    <BarriersToCare
      barriers={barrierSummariesForAssessment}
      barrierDisplayName={barrierDisplayName}
      clientId={clientId}
      programId={programId}
      assessmentId={assessmentId}
      allowEdit={allowEdit}
    />
  );
};
