import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useField } from 'formik';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

const PREFIX = 'LotusTrueFalseRadioGroup';

const classes = {
  radioButton: `${PREFIX}-radioButton`,
  radioLabel: `${PREFIX}-radioLabel`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {
    [`& .${classes.radioButton}`]: {
      color: theme.palette.text.primary,
      padding: '5px 5px 5px 9px',
    },
    [`& .${classes.radioLabel}`]: {
      color: theme.palette.text.primary,
      paddingLeft: 10,
      fontSize: 16,
    },
  };
});

export default function LotusTrueFalseRadioGroup({ required, dontCheckRequiredFields, allowNull=false, yesButtonLabel='Yes', noButtonLabel='No', nullButtonLabel='No Selection', label, disabled, onValueChange, ...props }) {

  const [field, fieldMeta, fieldUtils] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && (val === null || val === undefined)) {
      return `${label} is required`;
    }
  }});

  const [buttonSelection, setButtonSelection] = useState(null);
  const [isInitialValueSet, setIsInitialValueSet] = useState(false);

  function buttonValueToFieldValue(buttonVal) {
    return buttonVal === 'yes' ? true : (buttonVal === 'no' ? false : '');
  }

  function fieldValueToButtonValue(fieldVal) {
    return fieldVal === true ? 'yes' : (fieldVal === false ? 'no' : '');
  }

  useEffect(() => {
    if (!isInitialValueSet) {
      let buttonVal = fieldValueToButtonValue(field.value);
      if (!allowNull && buttonVal === '') {
        buttonVal = 'no';
        fieldUtils.setValue(false);
      }
      setButtonSelection(buttonVal);
      setIsInitialValueSet(true);
    }
  }, [field.value]);

  const buttonSelectionChanged = event => {
    let newFieldValue = buttonValueToFieldValue(event.target.value);
    if (newFieldValue !== field.value) {
      fieldUtils.setValue(newFieldValue);
      if (onValueChange) {
        onValueChange(newFieldValue);
      }
    }
    setButtonSelection(event.target.value);
  };

  const labelText = label + (required ? ' * ' : '');

  return !disabled ? (
    <StyledFormControl>
      <FormLabel component="legend">
        <Typography variant="body1" color="textPrimary">
          {labelText}
        </Typography>
      </FormLabel>
      <RadioGroup
        name={field.name}
        value={buttonSelection}
        onChange={buttonSelectionChanged}
      > 
        <FormControlLabel
          control={<Radio checked={field.value === true} className={classes.radioButton}/>}
          label={yesButtonLabel}
          value="yes"
        />
        <FormControlLabel
          control={<Radio checked={field.value === false} className={classes.radioButton}/>}
          label={noButtonLabel}
          value="no"
        />
      </RadioGroup>
    </StyledFormControl>) :
    <LotusReadOnlyFieldView label={label} value={field.value === true ? yesButtonLabel : (field.value === false ? noButtonLabel : nullButtonLabel)} />;
}
