import axios from 'axios';
import { getUserSession } from './lib/userpool'

// axios instance for making requests 
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async config => {
    const session = await getUserSession();
    let idToken;
    if (session && !session.error) {
      idToken = session?.getIdToken();
    }
    if (idToken) {
      config.headers['Authorization'] = idToken.getJwtToken();
    }
    return config
  }
)
export default axiosInstance;

