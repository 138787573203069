import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './PermissionTemplateRequestsContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';
import moment from 'moment';

const PermissionTemplateRequestsContext = createContext();

const initialState = {
  pendingRequests: undefined,
  recentApprovals: undefined,
};

export const PermissionTemplateRequestProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PermissionTemplateRequestsContext.Provider value={{ state, dispatch }}>
      {children}
    </PermissionTemplateRequestsContext.Provider>
  );
};

export const usePermissionTemplateRequests = () => {
  const { state, dispatch } = useContext(PermissionTemplateRequestsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const createRequestToCreatePermissionTemplate = async (request) => {
    try {
      addBusyBee('createRequestToCreatePermissionTemplate');

      const toPost = {
        operationName: 'createRequestToCreatePermissionTemplate',
        request
      };

      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.REQUEST_CREATION_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createRequestToCreatePermissionTemplate');
    }
  };

  const createRequestToUpdatePermissionTemplate = async (request) => {
    try {
      addBusyBee('createRequestToUpdatePermissionTemplate');

      const toPost = {
        operationName: 'createRequestToUpdatePermissionTemplate',
        request
      };

      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, toPost);
      const { createdRequest } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.REQUEST_CREATION_SUCCESS,
        payload: {
          request: createdRequest
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createRequestToUpdatePermissionTemplate');
    }
  };

  const approveRequestToCreatePermissionTemplate = async (requestId) => {
    try {
      addBusyBee('approveRequestToCreatePermissionTemplate');
      
      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url,{operationName:'approveRequestToCreatePermissionTemplate', requestId: requestId});
      const { approval } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.APPROVING_PERMISSION_TEMPLATE_REQUEST_SUCCESS,
        payload: {
          requestId,
          approval: approval
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('approveRequestToCreatePermissionTemplate');
    }
  };

  const approveRequestToUpdatePermissionTemplate = async (requestId) => {
    try {
      addBusyBee('approveRequestToUpdatePermissionTemplate');
      
      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url,{operationName:'approveRequestToUpdatePermissionTemplate', requestId: requestId});
      const { approval } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.APPROVING_PERMISSION_TEMPLATE_REQUEST_SUCCESS,
        payload: {
          requestId,
          approval: approval
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('approveRequestToUpdatePermissionTemplate');
    }
  };

  const denyRequestToCreatePermissionTemplate = async (requestId, denyReason) => {
    try {
      addBusyBee('denyRequestToCreatePermissionTemplate');

      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, {
        operationName: 'denyRequestToCreatePermissionTemplate',
        requestId: requestId, 
        reason: denyReason
      });
      const { denial } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.DENYING_PERMISSION_TEMPLATE_REQUEST_SUCCESS,
        payload: {
          denial: denial
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('denyRequestToCreatePermissionTemplate');
    }
  };

  const denyRequestToUpdatePermissionTemplate = async (requestId, denyReason) => {
    try {
      addBusyBee('denyRequestToUpdatePermissionTemplate');

      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, {
        operationName: 'denyRequestToUpdatePermissionTemplate',
        requestId: requestId, 
        reason: denyReason
      });
      const { denial } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.DENYING_PERMISSION_TEMPLATE_REQUEST_SUCCESS,
        payload: {
          denial: denial
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('denyRequestToUpdatePermissionTemplate');
    }
  };

  const loadPendingPermissionTemplateRequests = async () => {
    try {
      addBusyBee('loadPendingPermissionTemplateRequests');

      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, { operationName: 'getPendingPermissionTemplateRequests'});
      let requests = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PENDING_REQUESTS_SUCCESS,
        payload: {
          pendingRequests: requests,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPendingPermissionTemplateRequests');
    }
  };

  const loadRecentPermissionTemplateApprovals = async () => {
    try {
      addBusyBee('loadRecentPermissionTemplateApprovals');

      const filterDate = moment().add(-30, 'days');
      const url = `/api/permissionTemplates`;
      const { data } = await axios.post(url, { operationName: 'getRecentPermissionTemplateApprovalsForUser', earliestDate: `${filterDate.format('MM/DD/YYYY')}` });
      let approvals = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_RECENT_APPROVALS_SUCCESS,
        payload: {
          recentApprovals: approvals,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadRecentPermissionTemplateApprovals');
    }
  };

  if (state) {
    return {
      ...state,
      createRequestToCreatePermissionTemplate,
      createRequestToUpdatePermissionTemplate,
      approveRequestToCreatePermissionTemplate,
      approveRequestToUpdatePermissionTemplate,
      denyRequestToCreatePermissionTemplate,
      denyRequestToUpdatePermissionTemplate,
      loadPendingPermissionTemplateRequests,
      loadRecentPermissionTemplateApprovals,
    };
  }

  return {};
};
