import { Stack, Typography } from '@mui/material';
import LotusHorizontalDivider from 'components/dataDisplay/divider/LotusHorizontalDivider';
import React from 'react';


export default function LotusSubsectionHeader({
  title,
  divider = true,
  indentLevel = 0,
  id, // for sticky nav,
  actionButtonList
}) {
  return (
    // No left/right padding as expecting this will go in a container that already has left padding applied
    // For now not actually indenting, just using the level as a typography indicator for page hierarchy
    <div id={id} style={{padding: "12px 0"}}>
      {divider && <LotusHorizontalDivider/>}
      <Stack direction="row" justifyContent="space-between" style={{padding: "12px 0"}}>
          <Typography variant={indentLevel === 0 ? 'h3' : (indentLevel === 1 ? 'h4' : 'h5')}>{title}</Typography>
          {actionButtonList}
        </Stack>
      
    </div>
  );
}