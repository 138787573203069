import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import ActivityLogSubjectLinker from '../linkers/ActivityLogSubjectLinker';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

const validationSchema = yup.object({
  minutesRoundingInterval: yup
    .number().integer().nullable().typeError('Minutes Rounding Interval must be an integer'),
});

export default function ProgramActivityLogForm({ program, handleSubmit }) {

  const [initialValues, setInitialValues] = useState();

  const { 
    currentProgram, activityLogTypes, activityLogStatuses, activityLogSubjects, activityLogWithOptions, loadProgramActivityLogPicklists,
  } = useProgramManagement();

  useEffect(() => {
    if (currentProgram && !activityLogTypes) {
      loadProgramActivityLogPicklists();
    }
  }, [program]);

  useEffect(() => {
    if (currentProgram && activityLogTypes && activityLogStatuses && activityLogSubjects && activityLogWithOptions) {
      let currentActivityLogTypes = activityLogTypes.map(t => {
        return {
          id: t.id,
          name: t.name, 
          isMadeAttemptedEnabled: t.isMadeAttemptedEnabled, 
          contactFieldsEnabled: t.contactFieldsEnabled,
          activityWithEnabled: t.activityWithEnabled,
          active: true
        }});
      const phoneType = currentActivityLogTypes.some(t => t.name === 'Phone');
      if (!phoneType) {
        currentActivityLogTypes.push({id: uuidv4(), name: 'Phone', isMadeAttemptedEnabled: true, contactFieldsEnabled: true, activityWithEnabled: false, active: false});
      }
      const correspondenceType = currentActivityLogTypes.some(t => t.name === 'Correspondence');
      if (!correspondenceType) {
        currentActivityLogTypes.push({id: uuidv4(), name: 'Correspondence', isMadeAttemptedEnabled: false, contactFieldsEnabled: false, activityWithEnabled: true, active: false});
      }
      const newInitialValues = {
        id: currentProgram.id,
        displayName: currentProgram.activityLogConfig.displayName || '',
        displayNamePlural: currentProgram.activityLogConfig.displayNamePlural || '',
        minutesRoundingInterval: currentProgram.activityLogConfig.minutesRoundingInterval || '',
        enabled: currentProgram.activityLogConfig.enabled || false,
        statusEnabled: currentProgram.activityLogConfig.statusEnabled || false,
        minutesEnabled: currentProgram.activityLogConfig.minutesEnabled || false,
        subjectEnabled: currentProgram.activityLogConfig.subjectEnabled || false,
        conductedByEnabled: currentProgram.activityLogConfig.conductedByEnabled || false,
        isInterpretationServiceUsedEnabled: currentProgram.activityLogConfig.isInterpretationServiceUsedEnabled || false,
        interpretationServiceEnabled: currentProgram.activityLogConfig.interpretationServiceEnabled || false,
        coordinatorEnabled: currentProgram.activityLogConfig.coordinatorEnabled || false,
        currentActivityLogTypes: currentActivityLogTypes || [],
        currentActivityLogStatusNames: activityLogStatuses.map((s) => {return {name: s.name, active: true}}) || [],
        currentActivityLogWithOptionNames: activityLogWithOptions.map((s) => {return {name: s.name, active: true}}) || [],
        currentActivityLogSubjects: activityLogSubjects.map((s) => {return {id: s.id, name: s.name, typeId: s.typeId}}),
        selectedActivityLogSubjects: activityLogSubjects.map((s) => {return `${s.name}-${s.typeId}`}),
        addedActivityLogType: {name: '', isMadeAttemptedEnabled: false, contactFieldsEnabled: false, activityWithEnabled: false},
        addedActivityLogStatusName: '',
        addedActivityLogSubject: {name: '', typeId: ''},
        addedActivityLogWithOptionName: '',
      };
      setInitialValues(newInitialValues);
    }
  }, [currentProgram, activityLogTypes, activityLogStatuses, activityLogSubjects, activityLogWithOptions]);

  return (
    <LotusPageSection  header='Activity Logs'>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="enabled"
                    value={values.enabled}
                    label="Activity Logs Enabled"
                    labelPlacement="start"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                {values.enabled && (
                  <>
                    <LotusFormItem>
                      <LotusTextInput
                        name="displayName"
                        value={values.displayName}
                        label="Display Name"
                        onChange={handleChange}
                      />
                    </LotusFormItem> 
                    <LotusFormItem>
                      <LotusTextInput
                        name="displayNamePlural"
                        value={values.displayNamePlural}
                        label="Display Name (Plural)"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <GenericListLinker
                        name="currentActivityLogTypes"
                        title="Activity Log Types for Program"
                        itemName="Log Type"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="subjectEnabled"
                        value={values.subjectEnabled}
                        label="Subject Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    {values.subjectEnabled &&
                      <LotusFormItem indent={1}>
                        <ActivityLogSubjectLinker 
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <GenericListLinker
                        name="currentActivityLogWithOptionNames"
                        title="Activity Log With Options for Program"
                        itemName="Log With Option"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="statusEnabled"
                        value={values.statusEnabled}
                        label="Status Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    {values.statusEnabled && 
                      <LotusFormItem indent={1}>
                        <GenericListLinker
                          name="currentActivityLogStatusNames"
                          title="Activity Log Statuses for Program"
                          itemName="Log Status"
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="minutesEnabled"
                        value={values.minutesEnabled}
                        label="Minutes Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    {values.minutesEnabled &&
                      <LotusFormItem indent={1}>
                        <LotusTextInput
                          name='minutesRoundingInterval'
                          label='Minutes Rounding Interval'
                          value={values.minutesRoundingInterval} 
                          error={touched.minutesRoundingInterval && Boolean(errors.minutesRoundingInterval)}
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="conductedByEnabled"
                        value={values.conductedByEnabled}
                        label="Conducted By Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="isInterpretationServiceUsedEnabled"
                        value={values.isInterpretationServiceUsedEnabled}
                        label="Interpretation Service Indicator Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                    {values.isInterpretationServiceUsedEnabled &&
                      <LotusFormItem indent={1}>
                        <LotusSwitch
                          name="interpretationServiceEnabled"
                          value={values.interpretationServiceEnabled}
                          label="Interpretation Service Enabled"
                          labelPlacement="start"
                          onChange={handleChange}
                        />
                      </LotusFormItem>
                    }
                    <LotusFormItem>
                      <LotusSwitch
                        name="coordinatorEnabled"
                        value={values.coordinatorEnabled}
                        label="Coordinator Enabled"
                        labelPlacement="start"
                        onChange={handleChange}
                      />
                    </LotusFormItem>
                  </>
                )}
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
