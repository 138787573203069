import Grid from '@mui/material/Grid';
import { useEffect, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { LocationClient, SearchPlaceIndexForSuggestionsCommand, GetPlaceCommand } from '@aws-sdk/client-location';
import { withAPIKey } from '@aws/amazon-location-utilities-auth-helper';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import { debounce } from '@mui/material/utils';
import { useLists } from 'contexts/ListsContext';
import { useProperties } from 'contexts/PropertiesContext';
import LotusTextInput from 'components/form/inputField/common/LotusTextInput';
import LotusCountySelector from 'components/form/select/LotusCountySelector';
import LotusStateSelector from 'components/form/select/LotusStateSelector';
import LotusReadOnlyFieldView from 'components/form/LotusReadOnlyFieldView';
import LotusStackedFormItems from 'components/form/LotusStackedFormItems';
import { Typography } from '@mui/material';
import { Error } from '@mui/icons-material';

export default function AddressFieldPanelWithLocationSearch({
  disabled,
  readOnly,
  name,
  style,
  title,
  required,
  address1Label,
  useSeedData = false,
  dontCheckRequiredFields,
}) {

  const {values, setFieldTouched} = useFormikContext();
  const [field, meta, utils] = useField(name);
  const [addr1Field, addr1FieldMeta, addr1FieldUtils] = useField({name: `${name}.address1`, validate: (val) => {
    // Need to do our own validation of address 1 field here as its not using our standard components
    const lbl = address1Label || 'Address';
    if (required && !dontCheckRequiredFields && (val === undefined || val === null || val === '')) {
      console.log('no');
      return `${lbl} is required`;
    }
  }});
  const { states, loadStates } = useLists();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [locationClient, setLocationClient] = useState();

  const locationSearchIndex = 'LocationSearchIndex';
  const languageCode = 'en';
  const filterCountries = ['USA'];

  const { awsSecrets, loadAwsSecrets } = useProperties();

  useEffect(() => {
    if (!awsSecrets) {
      loadAwsSecrets();
    }
  }, []);

  useEffect(() => {
    if (awsSecrets) {
      const setLocClient = async () => {
        const apiKey = awsSecrets.secretString;
        const awsRegion = awsSecrets.region;
        const authHelper = await withAPIKey(apiKey);
        const cli = new LocationClient({
          region: awsRegion,
          credentials: () => Promise.resolve({}), // Location client should use the API Key defined above, but its still complaining for credentials
          ...authHelper.getLocationClientConfig(),
        });
        setLocationClient(cli);
      };

      setLocClient().catch(console.log);
    }
  }, [awsSecrets]);

  const getPlaceSuggestions = useMemo(
    () =>
      debounce(async (request, callback) => {
        const input = {
          IndexName: locationSearchIndex,
          Text: request.input,
          FilterCountries: filterCountries,
          Language: languageCode,
          Categories: [],
          MaxResults: 10,
        };
        try {
          const command = new SearchPlaceIndexForSuggestionsCommand(input);
          callback(await locationClient.send(command));
        } catch (err) {
          console.error('Error generating suggestions for an address\n' + err);
        }
      }, 500),
    [locationClient]
  );

  useEffect(() => {
    if (!states) {
      loadStates();
    }
  }, []);

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    getPlaceSuggestions({ input: inputValue }, (results) => {
      results = results?.Results;
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        // to handle an address with no suggestion or suggestion is not selected
        if (inputValue) {
          setValue(inputValue);
          const address = field.value;
          address.address1 = inputValue;
          utils.setValue(address);
          setFieldTouched(`${name}.address1`,true);
          setFieldTouched(`${name}.address2`,true);
          setFieldTouched(`${name}.city`,true);
          setFieldTouched(`${name}.state`,true);
          setFieldTouched(`${name}.zip`,true);
          setFieldTouched(`${name}.county`,true);
        }
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlaceSuggestions]);

  const formatUSZipCode = (zipCode) => {
    if (!zipCode) {
      return zipCode;
    }
    if (zipCode.length === 5) {
      return zipCode.slice(0, 5);
    } else if (zipCode.length >= 9) {
      zipCode = zipCode.replace(/[- ]/g, '');
      return zipCode.slice(0, 5) + '-' + zipCode.slice(5);
    }
    return zipCode;
  };
  // Get the address details by placeId
  const getAddressDetails = async (selectedAddr) => {
    if (selectedAddr && selectedAddr.PlaceId) {
      const input = {
        IndexName: locationSearchIndex,
        PlaceId: selectedAddr.PlaceId,
        Language: languageCode,
      };
      try {
        const command = new GetPlaceCommand(input);
        const response = await locationClient.send(command);
        if (response && response.Place) {
          const address = field.value;
          const address1 =
            (response.Place?.AddressNumber ? response.Place.AddressNumber + ' ' : '') +
            (response.Place?.Street ? response.Place.Street : '');
          address.address1 = address1;
          setValue(address1);
          setInputValue(address1);
          address.address2 =
            (response.Place?.UnitType ? response.Place.UnitType + ' ' : '') +
            (response.Place?.UnitNumber ? response.Place.UnitNumber : '');
          address.city = response.Place?.Municipality;
          const stateCode = states.filter((state) => state.name === response.Place?.Region)[0].abbreviation;
          address.state = stateCode;
          address.zip = formatUSZipCode(response.Place?.PostalCode);
          address.county = response.Place?.SubRegion;
          utils.setValue(address);
          setFieldTouched(`${name}.address1`,true);
          setFieldTouched(`${name}.address2`,true);
          setFieldTouched(`${name}.city`,true);
          setFieldTouched(`${name}.state`,true);
          setFieldTouched(`${name}.zip`,true);
          setFieldTouched(`${name}.county`,true);
        }
      } catch (err) {
        console.error('Error finding an address details using place Id\n' + err);
      }
    }
  };

  return (field.value && (
      <LotusStackedFormItems spacing={2}>
        {title && <span className={disabled ? 'readOnlyFormLabel' : ''}>{title}:</span>}
        {!disabled && !readOnly ? (
          <Stack style={{width: '100%'}}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Autocomplete
                style={{width: '100%'}}
                size="small"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.Text)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                freeSolo
                disabled={disabled}
                includeInputInList
                filterSelectedOptions
                value={field.value.address1}
                onChange={(event, newValue) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  getAddressDetails(newValue);
                }}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'input') {
                    setInputValue(newInputValue);
                  }
                  if (reason === 'reset') {
                    setInputValue('');
                  }
                  if (!newInputValue && reason === 'clear') {
                    setOptions([]);
                    setValue(null);
                    const address = field.value;
                    address.address1 = '';
                    address.address2 = '';
                    address.city = '';
                    address.state = '';
                    address.zip = '';
                    address.county = '';
                    utils.setValue(address);
                    setFieldTouched(`${name}.address1`,true);
                    setFieldTouched(`${name}.address2`,true);
                    setFieldTouched(`${name}.city`,true);
                    setFieldTouched(`${name}.state`,true);
                    setFieldTouched(`${name}.zip`,true);
                    setFieldTouched(`${name}.county`,true);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    label={address1Label || 'Address' + (required ? ' * ' : '')}
                    name={`${name}.address1`}
                    variant="outlined"
                    margin="dense"
                    disabled={disabled}
                    size="small"
                    style={{ width: '100%', ...style, margin: 0 }}
                    maxLength={100}
                    onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
                    {...params}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.PlaceId}>
                      <Grid container alignItems="center" >
                        <Grid item sx={{ display: 'flex', width: 44 }}>
                          <LocationOnIcon />
                        </Grid>
                        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                          <Box component="span">
                            {option && option.Text ? option.Text : option}
                          </Box>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
              {meta.touched && meta.error &&
                <Error color='error'/>
              }
            </Stack>
            {addr1FieldMeta.touched && addr1FieldMeta.error && (
              <div>
                <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
                  {addr1FieldMeta.error}
                </Typography>
              </div>
            )}
          </Stack>
        ) : (
          <LotusReadOnlyFieldView
            label={address1Label || 'Address'}
            value={field.value.address1}
            style={style}
          />
        )}

        <LotusTextInput
          name={`${name}.address2`}
          label="Apt / Lot / Floor"
          disabled={disabled}
          readOnly={readOnly}
          maxLength={100}
          onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
        />

        <LotusTextInput
          name={`${name}.city`}
          label="City"
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          maxLength={100}
          dontCheckRequiredFields={dontCheckRequiredFields}
          onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
        />

        <Stack direction="row" spacing={1}>
          <LotusStateSelector
            name={`${name}.state`}
            disabled={disabled}
            readOnly={readOnly}
            useSeedData={useSeedData}
            label="State"
            required={required}
            dontCheckRequiredFields={dontCheckRequiredFields}
            onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
          />
          <div style={{ width: '100%' }}>
            <LotusTextInput
              name={`${name}.zip`}
              label="Zip Code"
              disabled={disabled}
              required={required}
              readOnly={readOnly}
              maxLength={10}
              dontCheckRequiredFields={dontCheckRequiredFields}
              onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
            />
          </div>
        </Stack>

        <LotusCountySelector
          name={`${name}.county`}
          disabled={disabled}
          readOnly={readOnly}
          useSeedData={useSeedData}
          label="County"
          required={required}
          state={field.value.state}
          dontCheckRequiredFields={dontCheckRequiredFields}
          onFocus={(e) => e.target.setAttribute('autoComplete', 'none')}
        />
      </LotusStackedFormItems>
    )
  );
}
