import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { usePermissionTemplateSearch } from 'contexts/PermissionTemplateSearchContext';
import LotusDataGrid from 'components/dataDisplay/datagrid/LotusDataGrid';

export default function TemplatesList(permissions) {
  const { hasTemplateEditPermission } = permissions;
  const { permissionTemplates, loadPermissionTemplates } = usePermissionTemplateSearch();
  const { userAgency } = useUserAgency();
  const { agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();
  const navigate = useNavigate();

  useEffect(() => {
      loadPermissionTemplates();
  }, []);

  useEffect(() => {
    if (userAgency && !agenciesForGrantee) {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, [userAgency, agenciesForGrantee]);

  const handleRowClicked = (row) => {
    const selectedRow = row?.row;
    if (hasTemplateEditPermission && selectedRow && selectedRow?.statusKey.toLowerCase() === 'active') {
      navigate(`/templates/edit/${selectedRow?.id}`);
    }
  };

  return (
    permissionTemplates && <TemplatesListView permissions={permissionTemplates} handleRowClicked={handleRowClicked} />
  );
}

export function TemplatesListView({ permissions, handleRowClicked }) {
  const columns = [
    {
      headerName: 'Template Name',
      field: 'name',
      flex: 1,
    },
    {
      headerName: 'User Count',
      field: 'userCount',
      flex: 1,
    },
  ];

  return (
    permissions && (
      <LotusDataGrid
        columns={columns}
        rows={permissions}
        totalRows={permissions.length}
        handleRowClicked={handleRowClicked}
        disableColumnFilter
      />
    )
  );
}
