import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import ApproveIcon from '@mui/icons-material/CheckCircleOutline';
import ApprovedIcon from '@mui/icons-material/Check';
import DenyIcon from '@mui/icons-material/HighlightOff';
import DeniedIcon from '@mui/icons-material/HighlightOff';
import { Table } from '@lotus/components';
import { formatAccountActionRequest } from 'lib/formatting';
import { useAuthState } from 'contexts/AuthProvider';
import UserActionRequestApprovalDialog from './dialogs/UserActionRequestApprovalDialog';
import UserActionRequestDenyDialog from './dialogs/UserActionRequestDenyDialog';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { useUserRequests } from 'contexts/UserRequestsContext';
import { useUserManagement } from 'contexts/UserManagementContext';

export default function RequestsList({ requests }) {
  const [showApproveRequest, setShowApproveRequest] = useState(false);
  const [showDenyRequest, setShowDenyRequest] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState('user_request');
  const [requestToProcess, setRequestToProcess] = useState();
  const { enableUser } = useUserManagement();

  const { user: loggedInUser, verifyPermission } = useAuthState();

  const {
    recentApprovals,
    loadRecentUserApprovals,
    approveRequestToCreateUser,
    approveRequestToUpdateUser,
    denyRequestToCreateUser,
    denyRequestToUpdateUser
  } = useUserRequests();

  useEffect(() => {
    if (!recentApprovals) {
      loadRecentUserApprovals();
    }
  }, []);

  const approveClicked = async (actionRequest) => {
    setShowApproveRequest(true);
    setRequestToProcess(actionRequest);
  };

  const denyClicked = async (actionRequest) => {
    setShowDenyRequest(true);
    setRequestToProcess(actionRequest);
  };

  const denyRequest = async (actionRequest, denyReason) => {
    setShowDenyRequest(false);
    if ('new_user' === actionRequest.actionRequestTypeKey) {
      await denyRequestToCreateUser(actionRequest.id, denyReason);
    } else {
      await denyRequestToUpdateUser(actionRequest.id, denyReason);
    }
  };

  const approveRequest = async (actionRequest) => {
    setShowApproveRequest(false);

    if ('new_user' === actionRequest.actionRequestTypeKey) {
      await approveRequestToCreateUser(actionRequest.id, actionRequest.userEmail);
    } else {
      if (await approveRequestToUpdateUser(actionRequest.id)) {
        if ('reactivate_user' === actionRequest.actionRequestTypeKey) {
          await enableUser(actionRequest.userId, actionRequest.userEmail);
        }
      }
    }
  };

  const shapeRequest = (request) => {
    let requestDetail = formatAccountActionRequest(request);
    return {
      ...request,
      requestDetail: requestDetail,
      actionRequest: request,
    };
  };

  const getApprovalCellView = (row) => {
    const request = row[1];

    if (selectedRequestType === 'recent') {
      const approvalDate = row[2];

      if (approvalDate) {
        return (
          <div>
            <ApprovedIcon style={{ color: 'green' }} />
            <span style={{ marginLeft: 10, verticalAlign: 'top' }}>
              Approved
            </span>
          </div>
        );
      } else {
        const deniedReason = row[3];
        return (
          <div>
            <div>
              <DeniedIcon style={{ color: 'red' }} />
              <span style={{ marginLeft: 10, verticalAlign: 'top' }}>
                Denied
              </span>
            </div>
            <div>
              <div style={{ marginTop: 10 }}>
                <b>Reason:</b>
                {deniedReason}
              </div>
            </div>
          </div>
        );
      }
    } else {
      let hasAccountPermission = false;
      if (request.actionRequestTypeKey === 'new_user' || request.actionRequestTypeKey === 'reactivate_user') {
        hasAccountPermission = verifyPermission(PERMISSIONS.APPROVE_NEW_ACCOUNT);
      } else if (request.actionRequestTypeKey === 'update_user_access') {
        hasAccountPermission = verifyPermission(PERMISSIONS.APPROVE_ACCOUNT_CHANGE);
      }
      return (
        <>
          {request.requestedById === loggedInUser.id? (
            <div>
              <ApproveIcon color="disabled" />
              <DenyIcon color="disabled" />
            </div>
          ) : (
            <div>
              <ApproveIcon
                color={hasAccountPermission ? "primary" : "disabled"}
                style={{ cursor: hasAccountPermission ? 'pointer' : 'default' }}
                onClick={() => hasAccountPermission && approveClicked(request)}
              />
              <DenyIcon
                color={hasAccountPermission ? "primary" : "disabled"}
                style={{ cursor: hasAccountPermission ? 'pointer': 'default' }}
                onClick={() => hasAccountPermission && denyClicked(request)}
              />
            </div>
          )}
        </>
      );
    }
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'actionRequest',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'approvalDate',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'deniedReason',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      label: 'Approve / Deny',
      name: 'approveDeny',
      options: {
        customBodyRender: (value, tableMeta) => {
          return getApprovalCellView(tableMeta.rowData);
        },
        filter: false,
        download: false,
      },
    },
    {
      label: 'Requests',
      name: 'requestDetail',
    },
    {
      label: 'User Name',
      name: 'userEmail',
    },
    {
      label: 'Account Status',
      name: 'accountStatusName',
    },
    {
      label: 'Requested By',
      name: 'requestedByEmail',
    },
  ];

  return (
    <>
      <LotusSpacedBlock>
        <Stack justifyContent="flex-start" direction="row" spacing={1}>
          <LotusButton onClick={() => setSelectedRequestType('user_request')}>
            {selectedRequestType === 'user_request' ? (
              <b>User Requests</b>
            ) : (
              'User Requests'
            )}
          </LotusButton>
          <LotusButton onClick={() => setSelectedRequestType('recent')}>
            {selectedRequestType === 'recent' ? (
              <b>Review Recent</b>
            ) : (
              'Review Recent'
            )}
          </LotusButton>
        </Stack>
      </LotusSpacedBlock>
      {((selectedRequestType === 'user_request' && requests) ||
        (selectedRequestType === 'recent' && recentApprovals)) && (
            <Table
              columns={columns}
              data={
                selectedRequestType === 'user_request'
                  ? requests
                  : recentApprovals
              }
              dataShaper={shapeRequest}
              options={{
                selectableRows: 'none',
                selectableRowsHideCheckboxes: false,
                selectableRowsHeader: false,
              }}
            />
      )}

      {requestToProcess && (
        <UserActionRequestApprovalDialog
          open={showApproveRequest}
          handleClose={() => setShowApproveRequest(false)}
          actionRequest={requestToProcess}
          handleSubmit={approveRequest}
        />
      )}
      {requestToProcess && (
        <UserActionRequestDenyDialog
          open={showDenyRequest}
          handleClose={() => setShowDenyRequest(false)}
          actionRequest={requestToProcess}
          handleSubmit={denyRequest}
        />
      )}
    </>
  );
}
