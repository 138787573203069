import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormsList from './components/FormsList';
import AddFormPanel from './components/AddFormPanel';
import EditFormPanel from './components/EditFormPanel';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import Stack from '@mui/material/Stack';
import LotusPage from 'components/widgets/Layout/LotusPage';

export default function FormManagement() {
  const navigate = useNavigate();
  const { page } = useParams();

  const addFormClick = () => {
    navigate('/forms/create');
  };

  const showFormList = () => {
    navigate('/forms/list');
  };

  return (
    <LotusPage>
      {page === 'list' && (
        <>
          <LotusSpacedBlock>
            <Stack justifyContent="flex-end" direction='row'>
              <LotusButton onClick={addFormClick}>
                Add New Form
              </LotusButton>
            </Stack>
          </LotusSpacedBlock>
          <FormsList />
        </>
      )}

      {page === 'create' && (
          <AddFormPanel handleBackClicked={showFormList} />
      )}

      {page === 'edit' && (
          <EditFormPanel handleBackClicked={showFormList} />
      )}
    </LotusPage>
  );
}
