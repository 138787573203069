import React, { useEffect, useState } from 'react';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { DynamicFormProvider } from 'contexts/DynamicFormContext';
import { ProgramConsentsTab } from './ProgramConsentsTab';
import { ProgramDiagnosesTab } from './ProgramDiagnosesTab';
import { ProgramDocumentsTab } from './ProgramDocumentsTab';
import TabbedMultiformPanel from 'components/Forms/MultiTab/MultiForm/TabbedMultiformPanel';
import { ClientFormContextProvider } from 'contexts/ClientFormContext';
import { useProgramSubforms } from 'components/Forms/utils/useProgramSubforms';
import { useClientProgramSubforms } from 'components/Forms/utils/useClientProgramSubforms';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';

const ClientProgramPanel = ({ clientId, client, programId, programName }) => {
  const { userAgency, userAgencyPrograms, loadUserAgencyPrograms } = useUserAgency();
  const formTypeKey = 'client_program';
  const { lvl3: formTab } = useParams();
  const navigate = useNavigate();

  const [programAdditionalClientData, setProgramAdditionalClientData] = useState();
  const subformDefinitions = useProgramSubforms(formTypeKey, programId);
  const clientForms = useClientProgramSubforms(clientId, formTypeKey, programId);
  const [tabDefinitions, setTabDefinitions] = useState();
  const { verifyEditProgramPermission } = useAuthState();
  const disabled = !verifyEditProgramPermission(programId);

  useEffect(() => {
    if (!userAgencyPrograms) {
      loadUserAgencyPrograms();
    }
  }, [userAgency, userAgencyPrograms]);

  useEffect(() => {
    if (!formTab && subformDefinitions && programAdditionalClientData) {
      if (subformDefinitions.length > 0) {
        navigate(`/client/${clientId}/clientinformation/${programId}/${subformDefinitions[0].formSubtypeCustomName}`);
      } else {
        if (programAdditionalClientData.consents) {
          navigate(`/client/${clientId}/clientinformation/${programId}/Consents`);
        } else if (programAdditionalClientData.diagnosisRecords) {
          navigate(`/client/${clientId}/clientinformation/${programId}/Diagnoses`);
        } else if (programAdditionalClientData.documents) {
          navigate(`/client/${clientId}/clientinformation/${programId}/Documents`);
        }
      }
    }
  }, [formTab, subformDefinitions, programAdditionalClientData]);

  useEffect(() => {
    if (userAgencyPrograms && programId) {
      setProgramAdditionalClientData(userAgencyPrograms.find((p) => p.id === programId)?.additionalClientData);
    }
  }, [userAgencyPrograms, programId]);

  useEffect(() => {
    const tabsToDisplay = {};

    if (programAdditionalClientData && programAdditionalClientData.consents) {
      tabsToDisplay['Consents'] = {
        component: <ProgramConsentsTab clientId={clientId} client={client} programId={programId} disabled={disabled} />,
        afterDynamicTabs: true
      }
    }

    if (programAdditionalClientData && programAdditionalClientData.diagnosisRecords) {
      tabsToDisplay['Diagnoses'] = {
        component: <ProgramDiagnosesTab clientId={clientId} programId={programId} disabled={disabled} />,
        afterDynamicTabs: true
      }
    }

    if (programAdditionalClientData && programAdditionalClientData.documents) {
      tabsToDisplay['Documents'] = {
        component: <ProgramDocumentsTab clientId={clientId} programId={programId} disabled={disabled} />,
        afterDynamicTabs: true
      }
    }

    setTabDefinitions(tabsToDisplay);
  }, [programAdditionalClientData]);

  return subformDefinitions && tabDefinitions && (
    <TabbedMultiformPanel 
      selectedTab={formTab}
      dynamicSubformDefinitions={subformDefinitions} 
      tabDefinitions={tabDefinitions}
      clientForms={clientForms} 
      clientId={clientId}
      headerText={programName}
      disabled={disabled}
      linkPathPrefix={`/client/${clientId}/clientinformation/${programId}`} />
  );
};

export default function ClientProgramInfoEditor({ ...props }) {
  return (
    <DynamicFormProvider>
      <ClientFormContextProvider>
        <ClientProgramPanel {...props} />
      </ClientFormContextProvider>
    </DynamicFormProvider>
  );
}
