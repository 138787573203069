import React, { useState, useEffect } from 'react';
import AgencyDynamicFormForm from './forms/AgencyDynamicFormForm';
import { useParams } from 'react-router-dom';
import { useFormDefinitions } from 'contexts/DynamicFormContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function EditFormPanel({ handleBackClicked }) {
  const { id } = useParams();
  const { formDefinitionCacheById, loadFormDefinitionById } = useFormDefinitions();
  const [formDefinition, setFormDefinition] = useState();

  useEffect(() => {
    if (id) {
      if (!formDefinitionCacheById || id in formDefinitionCacheById === false) {
        loadFormDefinitionById(id);
      }
    }
  }, [id]);

  useEffect(() => {
    if (id && formDefinitionCacheById && formDefinitionCacheById[id]) {
      setFormDefinition(formDefinitionCacheById[id]);
    }
  }, [id, formDefinitionCacheById]);

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton variant="outlined" onClick={handleBackClicked}>
          Back
        </LotusButton>
      </LotusSpacedBlock>
      {formDefinition && (
        <LotusPageSection header="Edit Form">
          <AgencyDynamicFormForm
            formDefinition={formDefinition}
            afterSave={handleBackClicked}
          />
        </LotusPageSection>
      )}
    </LotusPaper>
  );
}
