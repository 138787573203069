import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import Grid from '@mui/material/Grid';
import FormGroup from "@mui/material/FormGroup";

const validationSchema = yup.object({
  useFilter: yup.boolean(),
});
const FilterDatePanel = ({
  startDate,
  endDate,
  initialUseFilter,
  headerText,
  handleApply,
}) => {
  const initialValues = {
    useFilter: initialUseFilter,
    startDate,
    endDate,
  };

  return (
    <FormGroup>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, actions) => {
          handleApply(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          errors,
          values,
        }) => {
          const handleCheckChange = (event) => {
            handleChange(event);
            if (Object.keys(errors).length === 0) {
              handleSubmit();
            }
          };

          const handleDateChanged = async (dt) => {
            if (values.useFilter && Object.keys(errors).length === 0) {
              await handleSubmit();
            }
          }
          
          return (
            <Grid container>
              <Grid item xs={12}>
                <LotusCheckbox
                  name="useFilter"
                  checked={values.useFilter}
                  label={headerText}
                  onChange={handleCheckChange}
                />
              </Grid>
              {values.useFilter &&
                <>
                <Grid item xs={12} style={{ paddingLeft: 45 }}>
                  <LotusDatePicker
                    label="Start Date"
                    name="startDate"
                    required
                    handleDateChange={handleDateChanged}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingLeft: 45, marginTop: 10 }}>
                  <LotusDatePicker
                    label="End Date"
                    name="endDate"
                    required
                    handleDateChange={handleDateChanged}
                  />
                </Grid>
                </>
              }
            </Grid>
          );
        }}
      </Formik>
    </FormGroup>
  );
};

export default FilterDatePanel;
