import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Typography from '@mui/material/Typography';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import LotusMaskedInputField from 'components/widgets/Forms/LotusMaskedInput';
import LotusPhoneInputField from 'components/widgets/Forms/LotusPhoneInput';
import LotusRadioControl from 'components/widgets/Forms/LotusRadioGroup';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import AddressFieldPanel from 'components/widgets/Composites/AddressFieldPanel';
import LotusDateOfBirth from 'components/widgets/Forms/LotusDateOfBirth';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import moment from 'moment';
import { Grid } from '@mui/material';
import { getFieldValue } from '../utils/getFieldValue';

// TODO: Clean this up
export const getValueForName = (
  values,
  name,
  parentDefinition,
  parentIndex
) => {
  let value;
  if (parentDefinition) {
    value = getValue(values, parentDefinition.id);
    value = value ? value[parentIndex] : undefined;
    value = value ? value[name] : undefined;
  } else if (name.includes('.') && Array.isArray(values[name.split('.')])) {
    let parts = name.split('.');
    const arrayItem = values[parts[0]][parts[1]];
    return arrayItem ? arrayItem[parts[2]] : null;
  } else {
    value = getValue(values, name);
  }

  return value;
};

export const getValue = (values, name) => {
  const routes = name.split('.');

  let newValue = values;
  routes.forEach((route) => {
    if (newValue) {
      newValue = newValue[route];
    }

    return newValue;
  });

  return newValue;
};
const validateShow = (show, values, parentDefinition, parentIndex) => {
  let shouldShow = true;

  if (show && show.when) {
    const { when } = show;
    if (when) {
      const value = getValueForName(
        values,
        when.field,
        parentDefinition,
        parentIndex
      );

      if (when.eq) {
        shouldShow = value === when.eq;
      }

      if (when.in) {
        shouldShow = when.in.includes(value);
      }

      if (when.notEqual) {
        const eqValue = getValueForName(
          values,
          when.notEqual.field,
          parentDefinition,
          parentIndex
        );
        if (eqValue !== '' && value !== '') {
          shouldShow = value !== eqValue;
        } else {
          shouldShow = false;
        }
      }
    }
  } else {
    shouldShow = show === true;
  }

  return shouldShow;
};

export default function FieldView({disabled, dontCheckRequiredFields, fieldDefinition, fieldName, parentDefinition, parentIndex}) {

  const {values, setFieldTouched, setFieldValue, initialValues,} = useFormikContext();
  const value = fieldName ? getFieldValue(fieldName, values) : undefined;
  const { type: fieldType, items } = fieldDefinition;
  const nonInputFieldTypes = ['label'];
  const [listItems, setListItems] = useState();
  const [showItem, setShowItem] = useState(true);
  const required = fieldDefinition.required ? 
    (fieldDefinition.required.when ? (values[fieldDefinition.required.when] === fieldDefinition.required.is) : true) : false;
        
  useEffect(() => {
    if (!listItems && ['multiselect', 'select', 'radio'].includes(fieldType)) {
      setListItems(items);
    }
  }, [fieldType, values]);

  useEffect(() => {
    if (fieldDefinition.show !== undefined) {
      const { show, id } = fieldDefinition;
      const shouldShow = validateShow(show, values, parentDefinition, parentIndex);
      if (showItem && !shouldShow) {
        setFieldValue(id, initialValues[id]);
        setFieldTouched(id, false);
      }
      setShowItem(shouldShow);
    }
  }, [values, fieldDefinition]);

  if ((showItem && value !== undefined) || nonInputFieldTypes.includes(fieldDefinition.type)) {
    let details = null;

    switch (fieldDefinition.type) {
      case 'text':
        details = (
          <LotusTextInput
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            value={value}
            required={required || false}
          />
        );
        break;
      case 'maskedInput':
        details = (
          <LotusMaskedInputField
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            style={fieldDefinition.style}
            mask={fieldDefinition.mask}
            label={fieldDefinition.label}
            value={value}
            required={required || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'date':
        details = (
          <LotusDatePicker
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
            maxDate={fieldDefinition.disallowFutureDates ? moment().format('MM/DD/YYYY') : undefined}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            value={value}
            required={required || false}
          />
        );
        break;
      case 'monthAndYear':
        details = (
          <LotusDatePicker
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
            maxDate={fieldDefinition.disallowFutureDates ? moment().format('MM/DD/YYYY') : undefined}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            value={value}
            views={['year', 'month']}
            required={required || false}
          />
        );
        break;        
      case 'dateOfBirth':
        details = (
          <LotusDateOfBirth
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            value={value}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
            required={required || false}
          />
        );
        break;
      case 'phoneNumber':
        details = (
          <LotusPhoneInputField
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            label={fieldDefinition.label}
            style={fieldDefinition.style}
            value={value}
            required={required || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'switch':
        details = (
          <LotusSwitch
            name={fieldName}
            value={value === 'true' || value === 'True' || value === 'TRUE' || value === true}
            label={fieldDefinition.label}
            style={fieldDefinition.style}
            disabled={disabled || fieldDefinition.readOnly || false}
            trueText={fieldDefinition.trueText}
            falseText={fieldDefinition.falseText}
            labelPlacement={fieldDefinition.labelPlacement}
          />
        );
        break;
      case 'checkbox':
        details = (
          <LotusCheckbox
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            label={fieldDefinition.label}
            style={fieldDefinition.style}
          />
        );
        break;
      case 'select':
        details = (
          <LotusSelect
            name={fieldName}
            label={fieldDefinition.label}
            style={fieldDefinition.style}
            disabled={disabled || fieldDefinition.readOnly || false}
            required={required || false}
            items={listItems && listItems.map((item) => {return { label: item.label, value: item.value };})}
            value={listItems && listItems.find((li) => li.value === value) ? value : ''}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'radio':
        details = (
          <LotusRadioControl
            name={fieldName}
            disabled={disabled || fieldDefinition.readOnly || false}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            labelStyle={fieldDefinition.labelStyle}
            value={value}
            items={listItems}
            required={required || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'multiselect':
        details = (
          <LotusMultiSelect
            name={fieldName}
            label={fieldDefinition.label}
            style={fieldDefinition.style}
            disabled={disabled || fieldDefinition.readOnly || false}
            items={listItems && listItems.map((item) => {return { label: item.label, value: item.value };})}
            value={value || []}
            required={required || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      // case 'uploader':
      //   details = (
      //     <FileUploadPanel
      //       id={fieldName}
      //       style={
      //         fieldDefinition.style
      //           ? { ...fieldDefinition.style, width: '50%' }
      //           : { width: '50%' }
      //       }
      //       name={fieldName}
      //       disabled={disabled}
      //       fieldDefinition={fieldDefinition}
      //       classes={classes}
      //       currentAgency={currentAgency}
      //       currentUser={currentUser}
      //       formName={formDefinition.name}
      //       handleSubmitFormDocumentReview={handleSubmitFormDocumentReview}
      //       client={client}
      //       allowAddAnother={fieldDefinition.allowAddAnother}
      //       allowMetadataEntry={fieldDefinition.allowMetadataEntry}
      //     />
      //   );
      //   break;
      case 'address':
        details = (
          <AddressFieldPanel
            name={fieldName}
            title={fieldDefinition.title}
            address1Label={fieldDefinition.address1Label}
            style={fieldDefinition.style}
            disabled={disabled || fieldDefinition.readOnly || false}
            required={required || false}
            useSeedData={fieldDefinition.useSeedData || false} // for testing
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'textarea':
        details = (
          <LotusTextInput
            name={fieldName}
            multiline
            rows={10}
            disabled={disabled || fieldDefinition.readOnly || false}
            style={fieldDefinition.style}
            label={fieldDefinition.label}
            value={value}
            required={required || false}
            dontCheckRequiredFields={dontCheckRequiredFields || false}
          />
        );
        break;
      case 'label':
        details = (
          <Typography style={fieldDefinition.style} variant={fieldDefinition.variant} color={fieldDefinition.color}>
            {fieldDefinition.label}
          </Typography>
        );
        break;
      default:
        break;
    }

    return (
      <Grid key={fieldName} item xs={12}>
        {details}
      </Grid>
    );
  }

  return <div></div>;
}
