import generatePdfThumbnails from 'pdf-thumbnails-generator';
import {
  DOC, DOCX, TXT, CSV, XLS, XLSX, TIF, TIFF,
  PDF, IMAGE_TYPES, NO_PREVIEW_TYPES
} from './docTypes';
import txtPng from '../components/icons/txt.png';
import csvPng from '../components/icons/csv.png';
import excelPng from '../components/icons/excel.png';
import wordPng from '../components/icons/word.png';
import tifPng from '../components/icons/tif.png';

const loadImage = (base64Image, type) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = 'data:' + type + ';base64,' + base64Image;
});

const loadImageFromFile = (file, type) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => reject(err));
  img.src = file;
});

const drawImg = async (img, targetSize) => {
  const width = img.width,
    height = img.height,
    canvas = document.createElement('canvas'),
    ctx = canvas.getContext("2d");

  canvas.width = canvas.height = targetSize;

  ctx.drawImage(
    img,
    width > height ? (width - height) / 2 : 0,
    height > width ? (height - width) / 2 : 0,
    width > height ? height : width,
    width > height ? height : width,
    0, 0,
    targetSize, targetSize
  );

  return canvas.toDataURL();
  }
  
const createImageThumbnailFromFile = async (file, targetSize, type) => {
  let img = await loadImageFromFile(file, type);
  return await drawImg(img, targetSize);
}

const createImageThumbnail = async (base64Image, targetSize, type) => {
  let img = await loadImage(base64Image, type);
  return await drawImg(img, targetSize);
};

// returns base64
export const generateThumbnailsForUrl = async (
  base64Str,
  type,
  widths = [],
  allPages = false
) => {
  if (base64Str) {
    let generatedThumbnails;

    if (type === PDF) {
      var url = "data:image/png;base64," + base64Str;

      generatedThumbnails = await Promise.all(
        widths.map(async (w) => {
          const thumbnails = await generatePdfThumbnails(url, w);

          if (thumbnails && thumbnails.length > 0) {
            if (allPages) {
              return thumbnails.map((t) => t.thumbnail);
            } else {
              return thumbnails[0].thumbnail;
            }
          }
          return [];
        })
      );
      generatedThumbnails = generatedThumbnails.flat();
    } else if (IMAGE_TYPES.includes(type)) {
      generatedThumbnails = await Promise.all(
        widths.map(async (w) => {
          try {
            const thumbnail = await createImageThumbnail(base64Str, w, type);
            return thumbnail;
          }
          catch (error) {
            console.log('Error processing image', error.message);
            return undefined;
          }
        })
      );
    } else if (NO_PREVIEW_TYPES.includes(type)) {
      const temp =
        type === TXT ? [txtPng] :
          type === CSV ? [csvPng] :
            [XLS, XLSX].includes(type) ? [excelPng] :
              [DOC, DOCX].includes(type) ? [wordPng] :
                [TIF, TIFF].includes(type) ? [tifPng] :
                  [];

      generatedThumbnails = await Promise.all(
        widths.map(async (w) => {
          try {
            const thumbnail = await createImageThumbnailFromFile(temp[0], w, 'image/png');
            return thumbnail;
          }
          catch (error) {
            console.log('Error processing image', error.message);
            return undefined;
          }
        })
      );
    }

    return generatedThumbnails;
  }
};
