import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from 'contexts/NavigationContext';
import { useClient } from 'contexts/ClientContext';
import CareManagement from './components/CareManagement';
import EligibilityTimeline from './components/Eligibility/EligibilityTimeline';
import EnrollmentPage from './components/Enrollment/EnrollmentPage';
import ClientInfo from './components/ClientInfo';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums'
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import { useProperties } from 'contexts/PropertiesContext';

export default function ClientManagement() {
  const { clientId, mainTab } = useParams();
  const { setState: setNavState } = useNavigation();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadClient, client } = useClient();
  const [tabs, setTabs] = useState();
  const { verifyPermission } = useAuthState();
  const { userAgencyOptionalFeatures } = useUserAgency();

  // One time load of the stuff needed for address lookup
  const {awsSecrets, loadAwsSecrets} = useProperties();

  useEffect(() => {
    if (!awsSecrets) {
      loadAwsSecrets();
    }
  }, []);

  useEffect(() => {
    if (clientId && !client) {
      loadClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (client) {
      setNavState({
        selectedClient: client.name,
      });
    }
  }, [client]);

  useEffect(() => {
    if (userAgencyOptionalFeatures && mainTab) {
      let options = userAgencyOptionalFeatures;
      const tabList = [];
      let summaryEnabled = false;
      let eligibilityEnabled = false;
      
      if (options && options.summary) {
        summaryEnabled = true;
        tabList.push({label: 'Summary', link: `/client/${clientId}/summary/summary`});
      }

      if (options && options.eligibility) {
        eligibilityEnabled = true;
        tabList.push({label: 'Eligibility', link: `/client/${clientId}/eligibility/`});
      }

      tabList.push({label: 'Enrollment', link: `/client/${clientId}/enrollment/list`});

      tabList.push({label: 'Client Information', link: `/client/${clientId}/clientinformation/General`});
      
      if (verifyPermission(PERMISSIONS.READ_CARE_MANAGEMENT) || verifyPermission(PERMISSIONS.EDIT_CARE_MANAGEMENT)) {
        tabList.push({label: 'Care Management', link: `/client/${clientId}/caremanagement`});
      }

      setTabs(tabList);
      
      if ((!summaryEnabled && location.pathname.includes('summary/summary')) || (!eligibilityEnabled && location.pathname.includes('eligibility'))) {
        navigate(`/client/${clientId}/clientinformation/General`);
      }
    }
  }, [userAgencyOptionalFeatures, mainTab]);

  return client && tabs && mainTab && (
    <div>
      <LotusPage>
        <LotusPageTitle title={client.name} subTitleSmall={'Client ID: ' + client.clientIdentifier}/>
        <LotusMainTabs tabs={tabs} selectedTabName={mainTab}/>
        <Grid item xs={12}>
           {'eligibility' === mainTab.toLowerCase() && <div></div>}
           {'enrollment' === mainTab.toLowerCase() && <EnrollmentPage />}
           {'caremanagement' === mainTab.toLowerCase() && <CareManagement />}
           {'eligibility' === mainTab.toLowerCase() && <EligibilityTimeline />}
           {'clientinformation' === mainTab.toLowerCase() && <ClientInfo client={client} />}
         </Grid>
      </LotusPage>
    </div>
  );
}
