import { useEffect, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import { useAppStatus } from 'contexts/AppStatusContext';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Button } from "@lotus/components";
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from "lib/documents";

export default function DocumentationUploader ({
  clientId,
  handleCancel,
  handleSave,
}) {
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [ thumbnails, setThumbnails ] = useState([]);
  const [ initialValues, setInitialValues ] = useState();
  

  useEffect(() => {
    setInitialValues({
      document: {}
    });
  }, []);

  return (
  <Grid container>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (newValues, actions) => {
        await handleSave(newValues.document);
        actions.setSubmitting(false);
      }}
    >
      {({ values, setFieldTouched, handleSubmit, setFieldValue }) => {
        return values && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                <Grid item xs={6}>
                  <Typography variant="h2">Upload Documentation</Typography>
                </Grid>
                <Grid item xs={6} container justifyContent='flex-end'>
                  <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                    <Typography variant="h6">Cancel</Typography>
                  </Button>
                  <Button onClick={() => {handleSubmit(values);}}>
                    <Typography variant="h6">Save</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <LotusFileUploader
                  name="clientPremiumPaymentDocUploader"
                  clientId={clientId}
                  subfolderName="documents"
                  maxFiles={1}
                  onFileAdded={async (newFile, base64Str) => {
                    try {
                      addBusyBee('generatingThumbnail');
                      const thumbnails = await generateThumbnailsForUrl(base64Str, newFile.type, [100]);

                      setThumbnails(thumbnails);
                      setFieldTouched('docErr');
                      setFieldValue('docErr', '');
                      setFieldTouched('document');
                      setFieldValue('document', newFile);
                    }
                    catch (err) {
                      console.log(err);
                      setFieldTouched('document');
                      setFieldTouched('docErr');
                      setFieldValue('document', {});
                      setFieldValue('docErr', 'An error occurred while generating thumbnail images for file');
                    } finally {
                      removeBusyBee('generatingThumbnail');
                    }
                  }}
                  onFileDeleted={(newFile) => {
                    console.log('Deleted', newFile);
                    setFieldTouched('document');
                    setFieldValue('document', {});
                    setThumbnails(null);
                  }}
                  onError={(err, action, msg) => {
                    console.log(err, msg);
                    setFieldTouched('document');
                    setFieldTouched('docErr');
                    const errMsg = msg || 'An error occurred while processing the document';
                    if (action !== 'preview') {
                      setFieldValue('document', {});
                    }
                    setFieldValue('docErr', errMsg);
                  }}
                />
                <div style={{color:'#b53f3f', marginTop: 10}}>
                  <ErrorMessage name="document" />
                </div>
                <div style={{color:'#b53f3f', marginTop: 10}}>
                  <ErrorMessage name="docErr" />
                </div>
              </Grid>
            </Grid>
            <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
              {thumbnails && thumbnails.map((t,idx) => {
                return (<img key={idx} src={t} style={{marginRight: 10}}/>);
              })}
            </div>
          </Grid>
        );
      }}
    </Formik>
  </Grid>) || <div></div>
}