import { useEffect, useState } from 'react';
import RequestsList from './list/RequestsList';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionTemplateRequests } from 'contexts/PermissionTemplateRequestsContext';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';
import LotusPageHeader from 'components/headers/LotusPageHeader';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';
import LotusPageContent from 'components/dataDisplay/content/LotusPageContent';
import { Stack } from '@mui/material';
import LotusTabs from 'components/navigation/LotusTabs';
import TemplatesList from './list/TemplatesList';
import AddTemplate from './add/AddTemplate';
import EditTemplate from './edit/EditTemplate';
import ReviewTemplate from './review/ReviewTemplate';
import { ArrowBack } from '@mui/icons-material';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';

export default function PermissionManagement() {
  const { page } = useParams();
  const navigate = useNavigate();

  const [tabList, setTabList] = useState();
  const [pageHeading, setPageHeading] = useState('Permission Management');

  const { pendingRequests, loadPendingPermissionTemplateRequests } = usePermissionTemplateRequests();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const hasApprovePermission = loggedInUser.isRoot || verifyPermission(PERMISSIONS.APPROVE_TEMPLATE);
  const hasViewTemplatePermission = loggedInUser.isRoot || verifyPermission(PERMISSIONS.VIEW_TEMPLATES);
  const hasRequestNewTmplPermission = verifyPermission(PERMISSIONS.REQUEST_NEW_TEMPLATE);

  useEffect(() => {
    let tabs;
    if (hasViewTemplatePermission) {
      tabs = [{ label: 'Permissions', link: '/templates/list', cleanName: 'list' }];
    }

    if (hasApprovePermission) {
      loadPendingPermissionTemplateRequests();
      tabs.push({ label: 'Approval Requests', link: '/templates/requests', cleanName: 'requests' });
    }

    setTabList(tabs);
  }, []);

  // sigh, always need to get requests right away to display the badge
  useEffect(() => {
    if (pendingRequests && tabList && hasApprovePermission) {
      let tab = tabList.find((t) => t.label === 'Approval Requests');
      if (tab) {
        const newBadge = pendingRequests.length || undefined;
        if (tab.badge !== newBadge) {
          tab.badge = newBadge;
          setTabList([...tabList]); // have to set to new list instance so component updates with new badge
        }
      }
    }
  }, [pendingRequests, tabList]);

  useEffect(() => {
    switch (page) {
      case 'create':
        setPageHeading('New Permission Template Request');
        break;
      case 'edit':
        setPageHeading('Edit Permission Template');
        break;
      case 'review':
        setPageHeading('Update Template Request');
        break;
      default:
        setPageHeading('Permission Management');
    }
  }, [page]);

  return (
    <>
      <LotusPageHeader
        title={pageHeading}
        actionButtonList={
          <LotusButtonList>
            {(page === 'list' || page === 'requests') && (
              <LotusPrimaryActionButton
                onClick={() => hasRequestNewTmplPermission && navigate('/templates/create')}
                disabled={!hasRequestNewTmplPermission}
              >
                Request New Template
              </LotusPrimaryActionButton>
            )}
            {page !== 'list' && page !== 'requests' && page !== 'create' && (
              <LotusSecondaryActionButton onClick={() => navigate('/templates/list')} startIcon={<ArrowBack />}>
                All Permissions
              </LotusSecondaryActionButton>
            )}
          </LotusButtonList>
        }
      />
      {tabList && (
        <LotusPageContent>
          <Stack direction="column" spacing={2}>
            {(page === 'list' || page === 'requests') && (
              <LotusTabs selectedTabName={page} tabs={tabList} tabPropertyToMatch="cleanName" />
            )}
            {page === 'list' && <TemplatesList hasTemplateEditPermission={hasRequestNewTmplPermission} />}
            {page === 'requests' && <RequestsList requests={pendingRequests} />}
            {page === 'create' && <AddTemplate />}
            {page === 'edit' && <EditTemplate />}
            {page === 'review' && <ReviewTemplate />}
          </Stack>
        </LotusPageContent>
      )}
    </>
  );
}
