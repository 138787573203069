import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Accordion, useTheme } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';

export default function LotusAccordion({ isExpanded=false, collapsedContent, expandedContent, onExpandedChanged, showBottomBorder=false }) {
  const [accordionExpanded, setAccordionExpanded] = useState(isExpanded);
  const theme = useTheme();

  useEffect(() => {
    if (isExpanded !== undefined) {
      setAccordionExpanded(isExpanded);
    }
  }, [isExpanded]);

  return (
    <Accordion
      onChange={(e, expanded) => {
        if (expanded !== accordionExpanded) {
          setAccordionExpanded(expanded);
          onExpandedChanged(expanded);
        }
      }}
      expanded={accordionExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{fontSize: 35}} />}
        style={{
          padding: '12px 24px',  // consistent between accordion, card, paper (Reacts accordion component has 12px of top/bottom padding already
          border: `1px solid ${theme.palette.other.card}`,  // consistent between accordion, card
          borderBottom: accordionExpanded && showBottomBorder ? `1px solid ${theme.palette.other.card}`: 'none' 
        }}
      >
        {collapsedContent}
      </AccordionSummary>
      <AccordionDetails 
        style={{
          padding: 24, // consistent between accordion, card, paper
          border: `1px solid ${theme.palette.other.card}`, // consistent between accordion, card
          borderTop: 'none'
          }}
      >
        <div>
          {expandedContent}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
