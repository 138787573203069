import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLists } from 'contexts/ListsContext';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { Grid } from '@mui/material';

export default function RaceFieldPanel({ required, raceFieldName, asianRaceFieldName, nhpiRaceFieldName, disabled, useSeedData, showAsianPicklist=true, showNhpiPicklist=true, dontCheckRequiredFields, ...props }) {

  const [raceField] = useField(raceFieldName);
  const [asianRaceField, asianRaceFieldMeta, asianRaceFieldUtils] = useField(asianRaceFieldName);
  const [nhpiRaceField, nhpiRaceFieldMeta, nhpiRaceFieldUtils] = useField(nhpiRaceFieldName);

  const {races, asianRaces, nhpiRaces, loadClientInfoPicklists } = useLists();
  const [raceList, setRaceList] = useState();
  const [asianRaceList, setAsianRaceList] = useState();
  const [nhpiRaceList, setNhpiRaceList] = useState(); 
  const [asianRaceSelected, setAsianRaceSelected] = useState();
  const [nhpiRaceSelected, setNhpiRaceSelected] = useState();

  useEffect(() => {
    if (useSeedData) {
      setRaceList([{label: "White", value: 1, itemKey:'white'},{label: "Asian", value: 2, itemKey: 'asian'},{label: "Hawaiian", value: 3, itemKey: 'native_hawaiian'}]);
      setAsianRaceList([{label: "Asian Race 1", value: 1},{label: "Asian Race 2", value: 2}]);
      setNhpiRaceList([{label: "Nhpi Race 1", value: 1},{label: "Nhpi Race 2", value: 2}]);
    } else if (!races || !asianRaces || !nhpiRaces) {
      loadClientInfoPicklists();
    }
  }, [useSeedData]);

  useEffect(() => {
    if (races) {
      setRaceList(races.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
    if (asianRaces) {
      setAsianRaceList(asianRaces.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
    if (nhpiRaces) {
      setNhpiRaceList(nhpiRaces.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
  }, [races, asianRaces, nhpiRaces]);

  useEffect(() => {
    if (raceField.value && raceList) {
      let asian = false;
      let nhpi = false;
      raceField.value.forEach(raceId => {
        let race_key = raceList.find(x => x.value === raceId).itemKey;
        if (race_key === 'asian') {
          asian = true;
        }
        if (race_key === 'native_hawaiian' || race_key === 'pacific_islander') {
          nhpi = true;
        }
      });

      setAsianRaceSelected(asian);
      setNhpiRaceSelected(nhpi);
      if (!asian) {
        asianRaceFieldUtils.setValue([]);
      }
      if (!nhpi) {
        nhpiRaceFieldUtils.setValue([]);
      }
    }
  }, [raceField.value, raceList]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <LotusMultiSelect
          name={raceFieldName}
          label="Race"
          disabled={disabled}
          items={raceList}
          required={required}
          dontCheckRequiredFields={dontCheckRequiredFields}
          readOnlyRows={2}
        />
      </Grid>
      {asianRaceSelected && showAsianPicklist &&
        <Grid item xs={12} style={{marginTop: 24}}>
          <LotusMultiSelect
            name={asianRaceFieldName}
            label="Asian"
            disabled={disabled}
            items={asianRaceList}
            readOnlyRows={2}
          />
        </Grid>
      }
      {nhpiRaceSelected && showNhpiPicklist &&
        <Grid item xs={12} style={{marginTop: 24}}>
          <LotusMultiSelect
            name={nhpiRaceFieldName}
            label="Native Hawaiian Pacific Islander"
            disabled={disabled}
            items={nhpiRaceList}
            readOnlyRows={2}
          />
        </Grid>
      }
    </Grid>
  );
}
