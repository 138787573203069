import React, {createContext, useContext, useReducer} from "react";
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";
import {
  reducer,
  LOADING_CLIENT_FORMS_SUCCESS,
  SAVING_CLIENT_FORM_SUCCESS
} from './ClientFormContextReducer';
import { useAppStatus } from './AppStatusContext';
const { v4: uuidv4 } = require('uuid');

const initialState = {
  clientForms: undefined
};

export const buildClientFormKey = (clientId, formTypeKey, programId, assessmentTypeId, assessmentId) => {
  return `${clientId}-${formTypeKey}-${programId || 'none'}-${assessmentTypeId || 'none'}-${assessmentId || 'none'}`;
}

const ClientFormContext = createContext();

export const ClientFormContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientFormContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientFormContext.Provider>
  );
};

export const useClientForms = () => {
  const { state, dispatch } = useContext(ClientFormContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const saveClientForm = async ({clientFormId, clientId, formDefinition, formData, programId, assessmentId}) => {
    try {
      addBusyBee('saveClientForm');

      const formSubtypeName = formDefinition.formSubtypeName || formDefinition.formSubtypeCustomName;
      let postData;
      if (clientFormId) {
        postData = {
          operationName: 'updateClientForm',
          clientFormId: clientFormId,
          formTypeKey: formDefinition.formTypeKey,
          formData,
          clientId: clientId,
          programId: programId
        };
      }
      else {
        clientFormId = clientFormId || uuidv4();
        postData = {
          operationName: 'insertClientForm',
          clientFormId: clientFormId,
          formTypeKey: formDefinition.formTypeKey,
          formSubtypeName: formSubtypeName,
          programId: formDefinition.programId || programId,
          assessmentTypeId: formDefinition.assessmentTypeId,
          assessmentId: assessmentId,
          formData,
          clientId: clientId,
        };
      }

      await axios.post(`/api/clientForms`, postData, {headers: {'Cache-Control': 'no-cache'}});
      
      dispatch({
        type: SAVING_CLIENT_FORM_SUCCESS,
        payload: {
          clientFormId: clientFormId,
          clientId: clientId,
          formTypeKey: formDefinition.formTypeKey, 
          programId: formDefinition.programId, 
          assessmentTypeId: formDefinition.assessmentTypeId,
          assessmentId: assessmentId,
          formSubtypeName: formSubtypeName,
          formData: formData,
          programId: formDefinition.programId || programId,
        },
      });

    } catch (error) {
      console.log({ message: 'Error saving form data', error });
      setError(error);
    } finally {
      removeBusyBee('saveClientForm');
    }
  };

  const loadClientForms = async ({clientId, formTypeKey, programId, assessmentTypeId, assessmentId}) => {
    try {
      addBusyBee('loadClientForm');

      const postData = {
        operationName: 'getClientForms',
        formTypeKey: formTypeKey,
        programId: programId,
        assessmentTypeId: assessmentTypeId,
        assessmentId: assessmentId,
        clientId: clientId
      };

      const { data } = await axios.post(`/api/clientForms`, postData, {headers: {'Cache-Control': 'no-cache'}});
      const formList = parseApiResult(data).body.forms;

      dispatch({
        type: LOADING_CLIENT_FORMS_SUCCESS,
        payload: {
          clientId: clientId,
          formTypeKey: formTypeKey,
          programId: programId,
          assessmentTypeId: assessmentTypeId,
          assessmentId: assessmentId,
          formList: formList,
        },
      });
    } catch (error) {
      console.log({ message: 'Error loading form data', error });
      setError(error);
    } finally {
      removeBusyBee('loadClientForm');
    }
  };

  return {
    ...state,
    loadClientForms,
    saveClientForm,
    buildClientFormKey
  };
};
