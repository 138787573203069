import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { SESSION_ACTIVE, SESSION_INACTIVE } from 'lib/eventEnums';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function InactivityDialog({isOpen, handleClose}) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="inactivity-dialog-title"
        open={isOpen}>
        <BootstrapDialogTitle id="inactivity-dialog-title" onClose={handleClose}>
          Inactive Session Automated Logout
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Your session will be logged out soon from inactivity.
          </Typography>
          <Typography gutterBottom>
            The automated log out can be avoided by dismissing this dialog in time
            or instead simply log back in when prompted on the next screen.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            DISMISS
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}