import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik, Form, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import SubFormTabSelector from "../../SubformTabSelector";
import HybridFormTabContent from "./HybridFormTabContent";
import { linkifyTabName } from "lib/formatting";
import LotusButton from "components/widgets/Forms/LotusButton";
import { useFormikContext } from 'formik';
import { Dialog } from '@mui/material';
import { combineStaticAndDynamicFormValidationSchema, combineStaticAndDynamicFormValues } from "components/Forms/utils/hybrid";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LotusPageTitle from "components/widgets/Layout/LotusPageTitle";
import { useAppStatus } from "contexts/AppStatusContext";
import { useAuthState } from "contexts/AuthProvider";


function FormDisplay({
  staticSubforms,
  dynamicSubformDefinitions, 
  additionalTabs, 
  linkPathPrefix, 
  formTabsHeader, 
  buttonConfig, 
  buttons, 
  formSubmitted, 
  saveButtonPressed, 
  allowEdit, 
  selectedTab, 
  runInitialFullValidation}) 
{

  const [allTabs, setAllTabs] = useState();
  const {values, errors, touched, submitForm, validateForm, setTouched, setErrors} = useFormikContext();
  const [theSelectedTab, setTheSelectedTab] = useState();

  const getErrorsByTab = (errs) => {
    const errorTabs = [];
    if (errs && staticSubforms && dynamicSubformDefinitions && (saveButtonPressed || runInitialFullValidation)) {
      Object.keys(errs).forEach(errKey => {
        Object.keys(staticSubforms).forEach(subformName => {
          if (staticSubforms[subformName].values.hasOwnProperty(errKey) && !errorTabs.includes(subformName)) {
            errorTabs.push(subformName);
          }
        });
        dynamicSubformDefinitions.forEach(subform => {
          let fields = subform.data.fields;
          if (subform.sections) {
            fields = subform.data.sections.map(section => section.fields).flat();
          }
          let subformName = subform.formSubtypeName || subform.formSubtypeCustomName;
          if (fields.find(f => f.id === errKey) && !errorTabs.includes(subformName)) {
            errorTabs.push(subformName);
          }
        });
      });
    }
    return errorTabs;
  }

  useEffect(() => {
    if (runInitialFullValidation) {
      validateForm(values).then(errors => {
        setTouched({ ...touched, ...errors });
        setErrors(errors);
      });
    }
  }, []);
  
  useEffect(() => {
    setTheSelectedTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (staticSubforms && dynamicSubformDefinitions && linkPathPrefix) {
      const subformTabsToDisplay = [];
      let staticSubformNames = staticSubforms ? Object.keys(staticSubforms) : [];
      let dynamicSubformNames = dynamicSubformDefinitions ? dynamicSubformDefinitions.map(
        dynamicSubformDef => {return dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName}) : [];
      let allSubformNames = new Set([...staticSubformNames, ...dynamicSubformNames]);
      const numSubforms = allSubformNames.size;
      const tabsWithErrors = getErrorsByTab(errors);

      if (staticSubforms) {
        staticSubformNames = Object.keys(staticSubforms);
        staticSubformNames.forEach(subformName => {
          const dynamicSubformDef = dynamicSubformDefinitions && dynamicSubformDefinitions.find(d => d.formSubtypeName === subformName || d.formSubtypeCustomName === subformName);
          const staticSubform = staticSubforms[subformName];
          if (!staticSubform.hide) {
            subformTabsToDisplay.push({
              label: subformName,
              hasError: ((saveButtonPressed || runInitialFullValidation) && tabsWithErrors.includes(subformName)) || undefined,
              component: 
                <HybridFormTabContent
                  key={subformName} 
                  title={numSubforms > 1 ? subformName : formTabsHeader} 
                  staticFormDefinitionBuilder={staticSubform.componentBuilder}
                  staticFormSectionNameBuilder={staticSubform.sectionNameBuilder}
                  dynamicFormDefinition={dynamicSubformDef && dynamicSubformDef.data} 
                  allowEdit={allowEdit}
                  dontCheckRequiredFields={true}
                />,
              link: `${linkPathPrefix}/${linkifyTabName(subformName)}`
            });
          }
        });
      }
      if (dynamicSubformDefinitions) {
        dynamicSubformDefinitions.forEach(dynamicSubformDef => {
          const dynSubformName = dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName;
          if (!staticSubformNames || !staticSubformNames.includes(dynSubformName)) {
            subformTabsToDisplay.push({
              label: dynSubformName,
              hasError: ((saveButtonPressed || runInitialFullValidation) && tabsWithErrors.includes(dynSubformName)) || undefined,
              component: 
                <HybridFormTabContent 
                  key={dynSubformName} 
                  title={numSubforms > 1 ? dynSubformName : formTabsHeader} 
                  dynamicFormDefinition={dynamicSubformDef.data}  
                  allowEdit={allowEdit}
                  dontCheckRequiredFields={true}
                />,
              link: `${linkPathPrefix}/${linkifyTabName(dynSubformName)}`
            });
           }
        });
      }

      const allTabsToDisplay = [...subformTabsToDisplay];
      if (additionalTabs) {
        allTabsToDisplay.push(...additionalTabs);
      }

      setAllTabs(allTabsToDisplay);
    }
  }, [staticSubforms, dynamicSubformDefinitions, additionalTabs, linkPathPrefix, errors, saveButtonPressed]);

  useEffect(() => {
    if (formSubmitted && saveButtonPressed) {
      const btn = buttonConfig.find(b => b.name === saveButtonPressed);
      if (btn.afterSave) {
        btn.afterSave(values, saveButtonPressed);
      }
    }
  }, [formSubmitted, saveButtonPressed]);

  return allTabs && selectedTab &&(
    <>
    <SubFormTabSelector headerText={formTabsHeader} selectedTabName={selectedTab} tabs={allTabs} actions={buttons} onTabChange={(tabLabel) => setTheSelectedTab(tabLabel)}/>
    <Form className="form" onSubmit={submitForm} autoComplete="off" subscription={{ submitting: true, pristine: true }}>
      {allTabs.find(t => linkifyTabName(t.label) === linkifyTabName(theSelectedTab))?.component}
    </Form>
    </>
  );
}

// NOTE: Formik field level validation only works if the fields are mounted.
// Since we are using tabs here, only one tab is mounted at a time.  On save, field validation will not run on the other tabs
// So need to use Yup schemas for validation purposes when using this component
export default function TabbedSingleFormPanel({
  selectedTab,
  formTitle,
  formSubTitle,
  formTabsHeader,
  linkPathPrefix, 
  staticSubforms, 
  dynamicSubformDefinitions, 
  clientForms, 
  buttonConfig, 
  onSave, 
  onClose, 
  additionalTabs, 
  allowEdit,
  runInitialFullValidation,
  onInitialValuesSet
}) {

  const [formValues, setFormValues] = useState();
  const [formValidationSchema, setFormValidationSchema] = useState();
  const [formValidationSchemaExcludingRequiredChecks, setFormValidationSchemaExcludingRequiredChecks] = useState();
  const [showDiscardChangesPopup, setShowDiscardChangesPopup] = useState(false);
  
  const [validationType, setValidationType] = useState('initial');
  const [saveButtonPressed, setSaveButtonPressed] = useState();
  const [formSubmitted, setFormSubmitted] = useState();
  const { user: loggedInUser } = useAuthState();

  const { setError } = useAppStatus();

  useEffect(() => {
    if (staticSubforms && dynamicSubformDefinitions && clientForms) {
      let staticSubformNames = staticSubforms ? Object.keys(staticSubforms) : [];
      let allFormValues = {};

      if (staticSubforms) {
        staticSubformNames = Object.keys(staticSubforms);
        staticSubformNames.forEach(subformName => {
          const dynamicSubformDef = dynamicSubformDefinitions && dynamicSubformDefinitions.find(d => d.formSubtypeName === subformName || d.formSubtypeCustomName === subformName);
          const staticSubform = staticSubforms[subformName];

          let staticFormValues = staticSubform.values;
          let dynamicFormValues = clientForms && clientForms[subformName]?.data;
          let thisFormValues = combineStaticAndDynamicFormValues(staticFormValues, dynamicFormValues, dynamicSubformDef && dynamicSubformDef.data);
          allFormValues = {...allFormValues, ...thisFormValues};
        });
      }
      if (dynamicSubformDefinitions && clientForms) {
        dynamicSubformDefinitions.forEach(dynamicSubformDef => {
          const dynSubformName = dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName;
          if (!staticSubformNames || !staticSubformNames.includes(dynSubformName)) {
            let dynamicFormValues = clientForms && clientForms[dynSubformName]?.data;
            let thisFormValues = combineStaticAndDynamicFormValues(null, dynamicFormValues, dynamicSubformDef.data);
            allFormValues = {...allFormValues, ...thisFormValues};
          }
        });
      }

      if (onInitialValuesSet) {
        onInitialValuesSet(allFormValues);
      }

      setFormValues(allFormValues);
    }
  }, [staticSubforms, dynamicSubformDefinitions, clientForms]);

  useEffect(() => {
    if (staticSubforms && dynamicSubformDefinitions && !formValidationSchema && !formValidationSchemaExcludingRequiredChecks) {
      let staticSubformNames = staticSubforms ? Object.keys(staticSubforms) : [];

      let allValidationSchema = Yup.object().shape({});
      let allValidationSchemaExcludingRequiredChecks = Yup.object().shape({});

      if (staticSubforms) {
        staticSubformNames = Object.keys(staticSubforms);
        staticSubformNames.forEach(subformName => {
          const dynamicSubformDef = dynamicSubformDefinitions && dynamicSubformDefinitions.find(d => d.formSubtypeName === subformName || d.formSubtypeCustomName === subformName);
          const staticSubform = staticSubforms[subformName];
          
          if (!staticSubform.hide) {
            let staticFormValidationSchema = staticSubform.schema;
            let thisFormValidationSchema = combineStaticAndDynamicFormValidationSchema(staticFormValidationSchema, dynamicSubformDef && dynamicSubformDef.data);
            allValidationSchema = thisFormValidationSchema ? allValidationSchema.concat(thisFormValidationSchema) : allValidationSchema;

            let staticFormValidationSchemaExcludingRequiredChecks = staticSubform.schemaExcludingRequiredChecks;
            let thisFormValidationSchemaExcludingRequiredChecks = combineStaticAndDynamicFormValidationSchema(staticFormValidationSchemaExcludingRequiredChecks, dynamicSubformDef && dynamicSubformDef.data, true);
            allValidationSchemaExcludingRequiredChecks = thisFormValidationSchemaExcludingRequiredChecks ? allValidationSchemaExcludingRequiredChecks.concat(thisFormValidationSchemaExcludingRequiredChecks) : allValidationSchemaExcludingRequiredChecks;
          }
        });
      }
      if (dynamicSubformDefinitions) {
        dynamicSubformDefinitions.forEach(dynamicSubformDef => {
          const dynSubformName = dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName;
          if (!staticSubformNames || !staticSubformNames.includes(dynSubformName)) {
            let thisFormValidationSchema = combineStaticAndDynamicFormValidationSchema(null, dynamicSubformDef.data);
            allValidationSchema = thisFormValidationSchema ? allValidationSchema.concat(thisFormValidationSchema) : allValidationSchema;

            let thisFormValidationSchemaExcludingRequiredChecks = combineStaticAndDynamicFormValidationSchema(null, dynamicSubformDef.data, true);
            allValidationSchemaExcludingRequiredChecks = thisFormValidationSchemaExcludingRequiredChecks ? allValidationSchemaExcludingRequiredChecks.concat(thisFormValidationSchemaExcludingRequiredChecks) : allValidationSchemaExcludingRequiredChecks;
          }
        });
      }

      setFormValidationSchema(allValidationSchema);
      setFormValidationSchemaExcludingRequiredChecks(allValidationSchemaExcludingRequiredChecks);
    }
  }, [staticSubforms, dynamicSubformDefinitions]);

  const handleCancel = (dirty, resetForm, dontSave) => {
    let closeIt = true;
    if (dirty && !showDiscardChangesPopup) {
      setShowDiscardChangesPopup(true);
      closeIt = false;
    }
    if (dirty && showDiscardChangesPopup && dontSave) {
      setShowDiscardChangesPopup(false);
      resetForm();
    }
    if (closeIt) {
      if (onClose) {
        onClose();
      }
    }
  }

  return (
    <Grid container>
      {formValues &&
        <Grid item xs={12}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            validate={(values) => {
              let result = {};
              try {
                if (validationType === 'saveOnly' || validationType === 'pend') {
                  formValidationSchemaExcludingRequiredChecks.validateSync(values, {abortEarly: false, context: {userId: loggedInUser.id }});
                } else {
                  formValidationSchema.validateSync(values, { abortEarly: false, context: {userId: loggedInUser.id, validationType: validationType } });
                }
              } catch (err) {
                result = yupToFormErrors(err);
              }
              return result;
            }}
            onSubmit={async (newValues, actions) => {
              try {
                if (onSave) {
                  delete newValues['asOfDate'];
                  await onSave(newValues);
                } else {
                  alert(JSON.stringify({ newValues }));
                }
                actions.setSubmitting(false);
                setFormSubmitted(true);
                setFormValues(newValues);
              } catch(e) {
                setError(e);
              }
            }}
          >
            {({values, resetForm, dirty, submitForm}) => {
              let cfg = buttonConfig || [];
              let buttons = [];
              cfg.forEach(b => {
                if (!b.hide || !b.hide(values)) {
                  if (b.isSave) {
                    buttons.push(
                      <LotusButton key={b.name} variant='contained' disabled={!allowEdit || (b.disableIfNotDirty && !dirty)} onClick={async () => {
                        setFormSubmitted(false);
                        setSaveButtonPressed(b.name);
                        setValidationType(b.validationType);
                        // Need to let that state change happen before doing form submission, so validation
                        // knows which validation needs to be done, because we cant pass parameters to it
                        setTimeout(async () => {
                          await submitForm();
                        });
                      }}>{b.label}</LotusButton>
                    );
                  }
                  if (b.isCancel) {
                    buttons.push(
                      <LotusButton key={b.name} variant='text' disabled={!allowEdit || (b.disableIfNotDirty && !dirty)} onClick={async () => {
                        await handleCancel(dirty, resetForm);
                      }}>{b.label}</LotusButton>
                    );
                  }
                }
              });
              return values && (
                <>
                <LotusPageTitle title={formTitle} subTitle={formSubTitle} id="topOfForm">
                  <LotusButton closeIcon variant="outlined" onClick={() => {handleCancel(dirty, resetForm);}}>
                    <Typography variant="h6">Close</Typography>
                  </LotusButton>
                </LotusPageTitle>
                <FormDisplay 
                  staticSubforms={staticSubforms}
                  dynamicSubformDefinitions={dynamicSubformDefinitions}
                  additionalTabs={additionalTabs}
                  linkPathPrefix={linkPathPrefix}
                  formTabsHeader={formTabsHeader}
                  buttonConfig={buttonConfig}
                  buttons={buttons}
                  formSubmitted={formSubmitted}
                  saveButtonPressed={saveButtonPressed}
                  allowEdit={allowEdit}
                  selectedTab={selectedTab}
                  runInitialFullValidation={runInitialFullValidation}
                />
                <Dialog maxWidth='md' open={showDiscardChangesPopup} >
                  <div style={{padding: 20, border: '2px solid #ED6C02', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <span style={{marginRight: 20}}><WarningAmberIcon style={{color: '#ED6C02'}}/></span>
                    <span style={{marginRight: 20}}>Cancelling will discard all changes made since last save.</span>
                    <LotusButton onClick={() => handleCancel(dirty, resetForm, true)}>Discard Changes</LotusButton>
                    <IconButton style={{position: 'relative', right: -25, top: -25}} onClick={() => setShowDiscardChangesPopup(false)} size="small">
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Dialog>
                </>
              );
            }}
          </Formik>
        </Grid>
      }
    </Grid>
  )
}
