import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramPropertiesForm({ handleSubmit }) {

  const {programTypes, loadProgramTypes} = useLists();
  const [programTypeList, setProgramTypeList] = useState();
  const [initialValues, setInitialValues] = useState();
  const { userAgencyEligibilityConfig } = useUserAgency();
  const { userGranteeAgencyPrograms, loadUserGranteeAgencyPrograms } = useUserAgency();
  const { currentProgram } = useProgramManagement();

  useEffect(() => {
    if (!programTypes) {
      loadProgramTypes();
    }
    if (!userGranteeAgencyPrograms) {
      loadUserGranteeAgencyPrograms();
    }
  }, []);

  useEffect(() => {
    if (currentProgram) {
      setInitialValues({
        name: currentProgram.name,
        programTypeId: currentProgram.programTypeId
      });
    } else {
      setInitialValues({
        name: '',
        programTypeId: ''
      });
    }
  }, [currentProgram]);

  useEffect(() => {
    if (programTypes && userGranteeAgencyPrograms) {
      let types = programTypes.map(g => { return {label: g.name, value: g.id, key: g.key}});
      // only 1 ryan white and 1 adap program are allowed
      const ryanWhiteProgramId = userGranteeAgencyPrograms.find(p => p.programTypeId === programTypes.find(pt => pt.key === 'ryan_white').id)?.id;
      if (ryanWhiteProgramId && (!currentProgram || ryanWhiteProgramId !== currentProgram.id)) {
        types = types.filter(t => t.key !== 'ryan_white');
      }
      const adapProgramId = userGranteeAgencyPrograms.find(p => p.programTypeId === programTypes.find(pt => pt.key === 'adap').id)?.id;
      if (adapProgramId && (!currentProgram || adapProgramId !== currentProgram.id)) {
        types = types.filter(t => t.key !== 'adap');
      }
      setProgramTypeList(types);
    }
  }, [programTypes, userGranteeAgencyPrograms, currentProgram]);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          // Potentially setting program name given type
          if (values.programTypeId === programTypeList.find(p => p.key === 'ryan_white')?.value) {
            values.name = 'Ryan White';
          }
          if (values.programTypeId === programTypeList.find(p => p.key === 'adap')?.value) {
            values.name = userAgencyEligibilityConfig?.application?.selectedAssistanceProgramName || 'ADAP';
          }
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                {programTypeList &&
                  <>
                  <LotusFormItem>
                    <LotusRadioGroup
                      name="programTypeId"
                      label="Program Type"
                      items={programTypeList}
                      required
                    />
                  </LotusFormItem>
                  {values.programTypeId === programTypeList.find(p => p.key === 'custom').value &&
                    <LotusFormItem indent={1}>
                      <LotusTextInput
                        name="name"
                        label="Program Name"
                        required
                      />
                    </LotusFormItem>
                  }
                  </>
                }
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
  );
}
