import React from 'react';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { FormGroup } from '@mui/material';
import { Stack, Grid } from '@mui/material';
const { v4: uuidv4 } = require('uuid');

export default function ContactTypeLinker({
  values,
  handleChange,
  setFieldValue,
}) {
  const addContactType = () => {
    if (values.addedContactType.name) {
      values.currentContactTypes.push({...values.addedContactType});
      setFieldValue('currentContactTypes', values.currentContactTypes);
      values.selectedContactTypes.push(`${values.addedContactType.name}-${values.addedContactType.id}`);
      setFieldValue('selectedContactTypes', values.selectedContactTypes);
      setFieldValue('addedContactType', {id: uuidv4(), name: '', isMadeAttemptedEnabled: false});
    }
  };

  return (
    <>
      <LotusFormItem>
        <FormGroup>
          {values.currentContactTypes.map((node, index) => {
            return (
              <Grid key={`contacttype-${index}`} container>
                <Grid item xs={8}>
                  <LotusCheckbox
                    name={'selectedContactTypes'}
                    label={node.name}
                    value={`${node.name}-${node.id}`}
                    checked={values.selectedContactTypes.find((n) => n === `${node.name}-${node.id}`) !== undefined}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LotusSwitch
                    name={`currentContactTypes.${index}.isMadeAttemptedEnabled`}
                    disabled={true}
                    value={node.isMadeAttemptedEnabled}
                    label="Is Made/Attempted Enabled"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            );
          })}
        </FormGroup>
      </LotusFormItem>
      <Stack direction="row" spacing={1} alignItems="center">
        <LotusTextInput
          name="addedContactType.name"
          label="Name"
        />
        <LotusSwitch
          name="addedContactType.isMadeAttemptedEnabled"
          label="Is Made/Attempted Enabled"
          labelPlacement="start"
        />
        <LotusButton onClick={addContactType}>
          Add
        </LotusButton>
      </Stack>
    </>
  );
}
