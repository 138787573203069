import { Paper, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import LotusSpacedBlock from "components/widgets/Layout/LotusSpacedBlock";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from "@mui/material";

export default function EligibilityApplicationRyanWhiteStatus({eligibilityAppConfig}) {

  const { values } = useFormikContext();

  return values && (
    <Paper style={{padding: 20, paddingTop: 5, maxWidth: 400}}>
      <LotusSpacedBlock>
        <div>Based on this application, the client is currently <span style={{fontWeight: 700}}>{values.rwEligible ? "eligible" : "ineligible"}</span> for Ryan White services. {values.rwEligible ? `Please complete the section below to authorize ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"} Services.` : ''}</div>
      </LotusSpacedBlock>
      <Paper style={{paddingTop: 5, maxWidth: 400}}>
        <Stack divider={<Divider orientation="horizontal" flexItem />} >
          <Stack direction="row" spacing={1} alignItems="center" style={{padding: 10}}>
            {values.rwIncomeIneligible ? <CancelIcon /> : <CheckCircleIcon />}
            <span style={{paddingLeft: 20}}>Income</span>
          </Stack>
        
          <Stack direction="row" spacing={1} alignItems="center" style={{padding: 10}}>
            {values.rwResidencyIneligible ? <CancelIcon /> : <CheckCircleIcon />}
            <span style={{paddingLeft: 20}}>Residency</span>
          </Stack>
        
          <Stack direction="row" spacing={1} alignItems="center" style={{padding: 10}}>
            {values.rwHivStatusIneligible ? <CancelIcon /> : <CheckCircleIcon />}
            <span style={{paddingLeft: 20}}>HIV Status</span>
          </Stack>
        </Stack>
      </Paper>
    </Paper>
  );
}
