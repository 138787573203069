import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddClientForm from './AddClientForm';
import { v4 as uuidv4 } from 'uuid';
import { useClientRegistration } from 'contexts/ClientRegistrationContext';
import DuplicateClientHandler from './DuplicateClientHandler';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useLists } from 'contexts/ListsContext';
import LotusPage from 'components/widgets/Layout/LotusPage';

export default function AddClientPanel({ handleBackClicked }) {
  const navigate = useNavigate();
  const [newClientId] = useState(uuidv4());
  const { userAgency } = useUserAgency();
  const [adding, setAdding] = useState(false);
  const [duplicates, setDuplicates] = useState(false);
  const { client, clientDuplicates, registerClient } = useClientRegistration();
  const {birthGenders, loadClientInfoPicklists } = useLists();

  useEffect(() => {
    setAdding(true);
    if (!birthGenders) {
      loadClientInfoPicklists(); // Do this here so our child components have access to the lists rather than making their own calls
    }
  }, []);

  useEffect(() => {
    if (client) {
      setAdding(false);
      setDuplicates(false);
      navigate(`/client/${client.id}/eligibility`);
    }
  }, [client]);

  useEffect(() => {
    if (clientDuplicates) {
      setAdding(false);
      setDuplicates(true);
    }
  }, [clientDuplicates]);

  const registerTheClient = async (values) => {
    await registerClient(newClientId, values, values.agencyId, false);
  };

  return userAgency && (
    <LotusPage>
      {adding && (
        <AddClientForm
          agencyId={userAgency.id}
          handleSubmit={registerTheClient}
          handleCancel={handleBackClicked}
        />
      )}
      {duplicates && (
        <DuplicateClientHandler duplicateInfo={clientDuplicates} agencyId={userAgency.id} registerClient={registerClient} />
      )}
    </LotusPage>
  );
}