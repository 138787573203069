import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-material-ui';
import React from 'react';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';
import { Typography } from '@mui/material';

export default function LotusPhoneInput({
  label,
  style,
  disabled,
  required,
  dontCheckRequiredFields,
  ...props
}) {
  const [field, meta, util] = useField({...props, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    if (val) {
      const regex = RegExp(`^[0-9]{10}$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be 10 digits`;
      }
    }
  }});

  let lblStyle = { ...style };
  lblStyle.paddingBottom = 10;

  const formatPhoneNumber = (val) => {
    if (!val) {
      return '';
    }
    if (val.startsWith('+')) {
      val = val.slice(1);
    }
    if (isNaN(val[0])) {
      return val;
    }
    
    const areaCode = val.slice(0,3);
    const prefix = val.slice(3,6);
    const last4 = val.slice(6);

    return `(${areaCode}) ${val.length > 3 ? prefix : ''}${val.length > 6 ? `-${last4}` : ''}`;
  }

  return (
      !disabled ? 
        <div>
          <PhoneInput
            {...field}
            {...props}
            onChange={(value) => {
              util.setValue(value);
            }}
            onBlur={() => {
              if (
                field.value &&
                !field.value.match(/^\+/) &&
                field.value.length > 0
              ) {
                util.setValue(`${field.value}`);
              }
              util.setTouched(true, true);
            }}
            specialLabel=""
            disableCountryCode={true}
            disableDropdown={true}
            enableAreaCodes={true}
            country={'us'}
            placeholder=''
            onlyCountries={['us']}
            inputProps={{
              name: field.name,
              style: {width: '100%', ...style},
              size: 'small',
              fullWidth: true,
              variant: "outlined",
              label: label,
            }}
            component={TextField}
          />
          {meta.touched && meta.error && (
            <Typography variant="body2" color="error">
              {meta.error}
            </Typography>
          )}
        </div> :
      <LotusReadOnlyFieldView label={label} value={formatPhoneNumber(field.value)} />
  );
}
