export const messageTypes = {
  LOADING_AGENCY_CASE_MANAGERS_SUCCESS: 'LOADING_AGENCY_CASE_MANAGERS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.LOADING_AGENCY_CASE_MANAGERS_SUCCESS:
      let newAgencyCaseManagers = {};
      Object.entries(state.agencyCaseManagers || {}).forEach(([k, v]) => {
        newAgencyCaseManagers[k] = v;
      });
      newAgencyCaseManagers[payload.agencyId] = payload.agencyCaseManagers;
      return {
        ...state,
        agencyCaseManagers: newAgencyCaseManagers,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
