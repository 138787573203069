import React from 'react';
import LotusSpacer from './LotusSpacer';

export default function LotusSpacedBlock({ ...props }) {

  return (
    <>
    {props.children}
    <LotusSpacer/><LotusSpacer/>
    </>
  );
}
