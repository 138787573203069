export const messageTypes = {
  LOADING_CLIENTS_SUCCESS: 'LOADING_CLIENTS_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_CLIENTS_SUCCESS:
      const totalPages =
        payload.clients.length > 0 ? parseInt(payload.clients[0].count) : 0;
      return {
        ...state,
        clients: [...payload.clients],
        clientFilters: payload.clientFilters,
        clientOffset: payload.offset,
        clientLimit: payload.limit,
        totalClientPages: totalPages
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
