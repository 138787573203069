
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import LotusMainTabSpacer from 'components/widgets/Layout/LotusMainTabSpacer';
import DataDictionaryTable from './DataDictionaryTable';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function DataDictionaryList() {

  const [tabs, setTabs] = useState();
  const { subview } = useParams();
  const navigate = useNavigate();
  const [tableName, setTableName] = useState();
  const { verifyPermission } = useAuthState();
  const hasImportAccountPermission = verifyPermission(PERMISSIONS.IMPORT_ACCOUNT_DATA);
  const hasImportClientPermission = verifyPermission(PERMISSIONS.IMPORT_CLIENT_DATA);
  const hasImportServicesPermission = verifyPermission(PERMISSIONS.IMPORT_SERVICES_DATA);
  const hasImportAgencyConfigurationPermission = verifyPermission(PERMISSIONS.IMPORT_AGENCY_CONFIGURATION_DATA);
  const hasImportCmsResponsePermission = verifyPermission(PERMISSIONS.IMPORT_CMS_RESPONSE_DATA);

  const hasExportAccountPermission = verifyPermission(PERMISSIONS.EXPORT_ACCOUNT_DATA);
  const hasExportClientPermission = verifyPermission(PERMISSIONS.EXPORT_CLIENT_DATA);
  const hasExportServicesPermission = verifyPermission(PERMISSIONS.EXPORT_SERVICES_DATA);
  const hasExportAgencyConfigurationPermission = verifyPermission(PERMISSIONS.EXPORT_AGENCY_CONFIGURATION_DATA);
  const hasExportCarrierPremiumsPermission = verifyPermission(PERMISSIONS.EXPORT_CARRIER_PREMIUMS);
  const hasExportApplicationDataPermission = verifyPermission(PERMISSIONS.EXPORT_APPLICATION_DATA);
  const hasExportCompClientDataPermission = verifyPermission(PERMISSIONS.EXPORT_COMPREHENSIVE_CLIENT_DATA);
  const hasExportCmsResponsePermission = verifyPermission(PERMISSIONS.EXPORT_CMS_RESPONSE_DATA);


  let permittedTabs = [];
  if (hasImportServicesPermission || hasExportServicesPermission) {
    permittedTabs.push({
      label: 'Test Results',
      name: 'TestResultFields',
      link: '/imports/list/dataDictionary/TestResultFields'
    });
    permittedTabs.push({
      label: 'Paid Premiums',
      name: 'PaidPremiumFields',
      link: '/imports/list/dataDictionary/PaidPremiumFields'
    });
    permittedTabs.push({
      label: 'Dispenses',
      name: 'DispenseFields',
      link: '/imports/list/dataDictionary/DispenseFields'
    });
  }

  if (hasImportAccountPermission || hasExportAccountPermission) {
    permittedTabs.push({
      label: 'User Accounts',
      name: 'UserAccountFields',
      link: '/imports/list/dataDictionary/UserAccountFields'
    });
  }

  if (hasImportClientPermission || hasExportClientPermission) {
    permittedTabs.push({
      label: 'Client Details',
      name: 'ClientDetailFields',
      link: '/imports/list/dataDictionary/ClientDetailFields'
    });
    
    permittedTabs.push({
      label: 'Client Addresses',
      name: 'ClientAddressFields',
      link: '/imports/list/dataDictionary/ClientAddressFields'
    });
    permittedTabs.push({
      label: 'Client Contacts',
      name: 'ClientContactFields',
      link: '/imports/list/dataDictionary/ClientContactFields'
    });
    permittedTabs.push({
      label: 'Client Income',
      name: 'ClientIncomeFields',
      link: '/imports/list/dataDictionary/ClientIncomeFields'
    });
    permittedTabs.push({
      label: 'Client Health',
      name: 'ClientHealthFields',
      link: '/imports/list/dataDictionary/ClientHealthFields'
    });
    permittedTabs.push({
      label: 'Client Insurance',
      name: 'ClientInsuranceFields',
      link: '/imports/list/dataDictionary/ClientInsuranceFields'
    });
    permittedTabs.push({
      label: 'Client Eligibility',
      name: 'ClientEligibilityFields',
      link: '/imports/list/dataDictionary/ClientEligibilityFields'
    });
    permittedTabs.push({
      label: 'Client Enrollment',
      name: 'ClientEnrollmentFields',
      link: '/imports/list/dataDictionary/ClientEnrollmentFields'
    });
  }
  if (hasImportAgencyConfigurationPermission || hasExportAgencyConfigurationPermission) {
    permittedTabs.push({
      label: 'Agencies',
      name: 'AgencyFields',
      link: '/imports/list/dataDictionary/AgencyFields'
    });

    permittedTabs.push({
      label: 'Health Plan Db',
      name: 'HealthPlanFields',
      link: '/imports/list/dataDictionary/HealthPlanFields'
    });
  }
  if (hasExportCarrierPremiumsPermission) {
    permittedTabs.push({
      label: 'Carrier Premiums',
      name: 'CarrierPremiumFields',
      link: '/imports/list/dataDictionary/CarrierPremiumFields'
    });
  }

  if (hasExportApplicationDataPermission) {
    permittedTabs.push({
      label: 'Application Data',
      name: 'ApplicationDataFields',
      link: '/imports/list/dataDictionary/ApplicationDataFields'
    });
  }

  if (hasExportCompClientDataPermission) {
    permittedTabs.push({
      label: 'Comprehensive Client Data',
      name: 'ComprehensiveClientDataFields',
      link: '/imports/list/dataDictionary/ComprehensiveClientDataFields'
    });
  }
  
  
  if (hasImportCmsResponsePermission) {
    permittedTabs.push({
      label: 'CMS Response Import Data',
      name: 'CMSResponseDataFields',
      link: '/imports/list/dataDictionary/CMSResponseDataFields'
    });
  }

  if (hasExportCmsResponsePermission) {
    permittedTabs.push({
      label: 'CMS Response Export Data',
      name: 'CMSResponseExportDataFields',
      link: '/imports/list/dataDictionary/CMSResponseExportDataFields'
    });
  }


  useEffect(() => {
    if (!subview) {
      if (hasImportServicesPermission || hasExportServicesPermission) {
        navigate('/imports/list/dataDictionary/TestResultFields');
      } else if (hasImportAccountPermission || hasExportAccountPermission) {
        navigate('/imports/list/dataDictionary/UserAccountFields');
      } else if (hasImportClientPermission || hasExportClientPermission) {
        navigate('/imports/list/dataDictionary/ClientDetailFields');
      } else if (hasImportCmsResponsePermission) {
        navigate('/imports/list/dataDictionary/CMSResponseDataFields');
      } else if (hasImportAgencyConfigurationPermission || hasExportAgencyConfigurationPermission) {
        navigate('/imports/list/dataDictionary/AgencyFields');
      } else if (hasExportCarrierPremiumsPermission) {
        navigate('/imports/list/dataDictionary/CarrierPremiumFields');
      } else if (hasExportApplicationDataPermission) {
        navigate('/imports/list/dataDictionary/ApplicationDataFields');
      } else if (hasExportCompClientDataPermission) {
        navigate('/imports/list/dataDictionary/ComprehensiveClientDataFields');
      } else if (hasExportCmsResponsePermission) {
        navigate('/imports/list/dataDictionary/CMSResponseExportDataFields');
      }
      
    }
    setTabs(permittedTabs);
  }, []);

  useEffect(() => {
    if (subview) {
      if (subview.toLowerCase() === 'testresultfields') {
        setTableName('testResultsTable');
      }
      else if (subview.toLowerCase() === 'paidpremiumfields') {
        setTableName('paidPremiumsTable');
      }
      else if (subview.toLowerCase() === 'dispensefields') {
        setTableName('dispensesTable');
      }
      else if (subview.toLowerCase() === 'agencyfields') {
        setTableName('agenciesTable');
      }
      else if (subview.toLowerCase() === 'useraccountfields') {
        setTableName('userAccountsTable');
      }
      else if (subview.toLowerCase() === 'clientdetailfields') {
        setTableName('clientDetailsTable');
      }
      else if (subview.toLowerCase() === 'clientaddressfields') {
        setTableName('clientAddressesTable');
      }
      else if (subview.toLowerCase() === 'clientcontactfields') {
        setTableName('clientContactsTable');
      }
      else if (subview.toLowerCase() === 'clientincomefields') {
        setTableName('clientIncomeTable');
      }
      else if (subview.toLowerCase() === 'clienthealthfields') {
        setTableName('clientHealthTable');
      }
      else if (subview.toLowerCase() === 'clientinsurancefields') {
        setTableName('clientInsuranceTable');
      }
      else if (subview.toLowerCase() === 'clienteligibilityfields') {
        setTableName('clientEligibilityTable');
      }
      else if (subview.toLowerCase() === 'clientenrollmentfields') {
        setTableName('clientEnrollmentTable');
      }
      else if (subview.toLowerCase() === 'healthplanfields') {
        setTableName('healthPlanTable');
      }
      else if (subview.toLowerCase() === 'carrierpremiumfields') {
        setTableName('carrierPremiumTable');
      }
      else if (subview.toLowerCase() === 'applicationdatafields') {
        setTableName('applicationDataTable');
      }
      else if (subview.toLowerCase() === 'comprehensiveclientdatafields') {
        setTableName('comprehensiveClientDataTable');
      }
      else if (subview.toLowerCase() === 'cmsresponsedatafields') {
        setTableName('cmsResponseDataTable');
      }
      else if (subview.toLowerCase() === 'cmsresponseexportdatafields') {
        setTableName('cmsResponseExportDataTable');
      }
      
    }
  }, [subview]);

  return tabs && subview && (
    <div>
      <LotusMainTabs
        selectedTabName={subview}
        tabs={tabs}
        tabPropertyToMatch={'name'}
        isScrollable={true}
      />
      <LotusMainTabSpacer />
      {tableName === 'testResultsTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'paidPremiumsTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'dispensesTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'agenciesTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'userAccountsTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientDetailsTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientAddressesTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientContactsTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientIncomeTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientHealthTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientInsuranceTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientEligibilityTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'clientEnrollmentTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'healthPlanTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'carrierPremiumTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'applicationDataTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'comprehensiveClientDataTable' &&
        <DataDictionaryTable tableName={tableName} />
      }
      {tableName === 'cmsResponseDataTable' &&
        <DataDictionaryTable tableName={tableName} />
      }                
      {tableName === 'cmsResponseExportDataTable' &&
        <DataDictionaryTable tableName={tableName} />
      }                

      
    </div>
  );
}
