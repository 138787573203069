import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import AgencyLinker from '../linkers/AgencyLinker';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramAgenciesForm({ handleSubmit }) {

  const { currentProgram, agencyIds, loadProgramAgencies } = useProgramManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (currentProgram && !agencyIds) {
      loadProgramAgencies();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (agencyIds) {
      setInitialValues({
        selectedAgencies: agencyIds || []
      });
    }
  }, [agencyIds]);

  return (
      <LotusPageSection header="Agencies">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={async (values, actions) => {
            await handleSubmit(values);
            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          {({ handleSubmit, handleChange, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  <LotusFormItem>
                    <AgencyLinker
                      values={values}
                      handleChange={handleChange}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusButton color="primary" variant="contained" type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
      </LotusPageSection>
  );
}
