import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import moment from 'moment';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusDatePicker({
  minDate,
  maxDate,
  views,
  label,
  handleDateChange,
  required,
  dontCheckRequiredFields,
  disabled,
  style,
  ...props
}) {
  const DATE_FORMAT = 'MM/DD/YYYY';

  const [momentDate, setMomentDate] = useState();

  const [field, meta, utils] = useField({...props, validate: (val) => {
    const lbl = label || 'Date';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    if (val) {
      let toValidate = val;
      if (typeof(val) === 'string') {
        toValidate = moment(val, DATE_FORMAT);
      }
      if (!toValidate.isValid()){ 
        return `${lbl} must be a valid date`;
      }
      if (maxDate) {
        const today = moment().format(DATE_FORMAT);
        if (moment(maxDate,DATE_FORMAT) < toValidate) {
          if (maxDate === today) {
            return `${lbl} cannot be in the future`;
          } else {
            return `${lbl} exceeds maximum`;
          }
        }
      }
      if (minDate) {
        if (moment(minDate,DATE_FORMAT) > toValidate) {
          return `${lbl} is too old`;
        }
      }
      if (toValidate.year() < 1900) { // this datepicker gives an error border if the date is before 1900 but no message
        return `${lbl} is too old`;
      }
    }
    return null;
  }});

  useEffect(() => {
    if (field.value !== undefined) {
      const newMomentDate = field.value ? moment(field.value,DATE_FORMAT) : null;
      if (momentDate?.format(DATE_FORMAT) !== newMomentDate?.format(DATE_FORMAT)) {
        setMomentDate(field.value ? moment(field.value,DATE_FORMAT) : null);
      }
      else if (handleDateChange) {
        handleDateChange(field.value);
      }
    }
  }, [field.value]); // field.value is a string

  useEffect(() => {
    if (momentDate !== undefined) {
      const curMomentDateStr = momentDate ? momentDate.format(DATE_FORMAT) : null;
      if (field.value !== curMomentDateStr) {
        utils.setTouched(true);
        utils.setValue(curMomentDateStr, true);
      }
    }
  }, [momentDate]); // momentDate is a moment

  return (
    !disabled ? 
    // This is the dumbest thing ever.  Picking a date should be unrelated to time zones
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          slotProps={{
            textField: {
              variant: "outlined",
              size: "small",
              required: required
            },
          }}
          sx={{width: '100%', ...style}}
          views={views || ['year', 'day']}
          minDate={minDate ? moment(minDate) : null}
          maxDate={maxDate ? moment(maxDate) : null}
          value={momentDate || null}
          onAccept={(newValue) => setMomentDate(newValue)}
          onChange={(newValue) => setMomentDate(newValue)}
          label={label}
        />
        {meta.touched && meta.error && (
          <Typography variant="body2" color="error" style={style}>
            {meta.error}
          </Typography>
        )}
      </LocalizationProvider>
    </div>
    : 
    <div>
      <LotusReadOnlyFieldView label={label} value={field.value || ''} />
      {meta.touched && meta.error && (
          <Typography variant="body2" color="error">
            {meta.error}
          </Typography>
        )}
    </div>
  );
}
