export const messageTypes = {
  LOADING_CONTACTS_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_CONTACTS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  LOADING_MEETINGS_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_MEETINGS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  LOADING_ACTIVITY_LOGS_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_ACTIVITY_LOGS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  LOADING_PROGRESS_NOTES_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_PROGRESS_NOTES_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  UPSERTING_CLIENT_ACTIVITY_LOG_SUCCESS: 'UPSERTING_CLIENT_ACTIVITY_LOG_SUCCESS',
  UPSERTING_CLIENT_CONTACT_SUCCESS: 'UPSERTING_CLIENT_CONTACT_SUCCESS',
  UPSERTING_CLIENT_MEETING_SUCCESS: 'UPSERTING_CLIENT_MEETING_SUCCESS',
  UPSERTING_CLIENT_PROGRESS_NOTE_SUCCESS: 'UPSERTING_CLIENT_PROGRESS_NOTE_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  function copyObject(curObj) {
    let newItems = {};
    Object.entries(curObj || {}).forEach(([k, v]) => {
      newItems[k] = v;
    });
    return newItems;
  }

  switch (type) {
    case messageTypes.LOADING_CONTACTS_FOR_CLIENT_AND_PROGRAM_SUCCESS: {
      const newContacts = copyObject(state.contacts);
      newContacts[payload.programId] = payload.docs;
      return {
        ...state,
        contacts: newContacts,
      };
    }
    case messageTypes.LOADING_MEETINGS_FOR_CLIENT_AND_PROGRAM_SUCCESS: {
      const newMeetings = copyObject(state.meetings);
      newMeetings[payload.programId] = payload.docs;
      return {
        ...state,
        meetings: newMeetings,
      };
    }
    case messageTypes.LOADING_ACTIVITY_LOGS_FOR_CLIENT_AND_PROGRAM_SUCCESS: {
      const newActivityLogs = copyObject(state.activityLogs);
      newActivityLogs[payload.programId] = payload.docs;
      return {
        ...state,
        activityLogs: newActivityLogs,
      };
    }
    case messageTypes.LOADING_PROGRESS_NOTES_FOR_CLIENT_AND_PROGRAM_SUCCESS: {
      const newProgressNotes = copyObject(state.progressNotes);
      newProgressNotes[payload.programId] = payload.docs;
      return {
        ...state,
        progressNotes: newProgressNotes,
      };
    }
    case messageTypes.UPSERTING_CLIENT_MEETING_SUCCESS:
      const newMeetings = copyObject(state.meetings);
      if (payload.programId in newMeetings) {
        const newProgramMeetings = newMeetings[payload.programId].filter(b => b.id !== payload.doc.id);
        newProgramMeetings.push(payload.doc);
        newMeetings[payload.programId] = newProgramMeetings;
      }
      return {
        ...state,
        meetings: newMeetings,
      };
    case messageTypes.UPSERTING_CLIENT_ACTIVITY_LOG_SUCCESS:
      const newActivityLogs = copyObject(state.activityLogs);
      if (payload.programId in newActivityLogs) {
        const newProgramActivityLogs = newActivityLogs[payload.programId].filter(b => b.id !== payload.doc.id);
        newProgramActivityLogs.push(payload.doc);
        newActivityLogs[payload.programId] = newProgramActivityLogs;
      }
      return {
        ...state,
        activityLogs: newActivityLogs,
      };
    case messageTypes.UPSERTING_CLIENT_CONTACT_SUCCESS:
      const newContacts = copyObject(state.contacts);
      if (payload.programId in newContacts) {
        const newProgramContacts = newContacts[payload.programId].filter(b => b.id !== payload.doc.id);
        newProgramContacts.push(payload.doc);
        newContacts[payload.programId] = newProgramContacts;
      }
      return {
        ...state,
        contacts: newContacts,
      };
    case messageTypes.UPSERTING_CLIENT_PROGRESS_NOTE_SUCCESS:
      const newProgressNotes = copyObject(state.progressNotes);
      if (payload.programId in newProgressNotes) {
        const newProgramProgressNotes = newProgressNotes[payload.programId].filter(b => b.id !== payload.doc.id);
        newProgramProgressNotes.push(payload.doc);
        newProgressNotes[payload.programId] = newProgramProgressNotes;
      }
      console.log(newProgressNotes);
      return {
        ...state,
        progressNotes: newProgressNotes,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
