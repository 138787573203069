import React from 'react';
import { useField } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusCheckbox({ label, ...props }) {
  const [field] = useField(props);

  const getControl = () => {
    return <Checkbox {...field} {...props} />;
  };

  return (
    !props.disabled ? 
      <FormControlLabel control={getControl()} label={label} /> :
      <LotusReadOnlyFieldView label={label} value={field.value ? 'Yes' : 'No'} />
  );
}
