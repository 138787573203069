const { useUserAgency } = require("contexts/UserAgencyContext");
const { useClientForms } = require("contexts/ClientFormContext");
const { useState, useEffect } = require("react");

export function useClientProgramAssessmentSubforms(clientId, formTypeKey, programId, assessmentTypeId, assessmentId) {
  const { userAgency } = useUserAgency();
  const {clientForms, loadClientForms, buildClientFormKey} = useClientForms();
  const [clientSubformData, setClientSubformData] = useState();
  const [currProgramId, setCurrProgramId] = useState();
  const [currAssessmentTypeId, setCurrAssessmentTypeId] = useState();
  const [currAssessmentId, setCurrAssessmentId] = useState();

  useEffect(() => {
    if (userAgency && currProgramId && currAssessmentTypeId && currAssessmentId && clientId) {
      const key = buildClientFormKey(clientId, formTypeKey, currProgramId, assessmentTypeId, currAssessmentId);
      if (!clientForms || key in clientForms === false) {
        loadClientForms({clientId: clientId, formTypeKey: formTypeKey, programId: currProgramId, assessmentTypeId: currAssessmentTypeId, assessmentId: assessmentId});
      }
    }
  }, [userAgency, currProgramId, currAssessmentTypeId, currAssessmentId, clientId]);

  useEffect(() => {
    if (userAgency && currProgramId && currAssessmentTypeId && currAssessmentId && clientId) {
      const key = buildClientFormKey(clientId, formTypeKey, currProgramId, currAssessmentTypeId, currAssessmentId);
      if (clientForms && key in clientForms) {
        setClientSubformData(clientForms[key]);
      }
    }
  }, [userAgency, currProgramId, currAssessmentTypeId, currAssessmentId, clientId, clientForms]);

  if (currProgramId !== programId) {
    setClientSubformData(undefined);
    setCurrProgramId(programId);
  }

  if (currAssessmentId !== assessmentId) {
    setClientSubformData(undefined);
    setCurrAssessmentId(assessmentId);
  }

  if (currAssessmentTypeId !== assessmentTypeId) {
    setClientSubformData(undefined);
    setCurrAssessmentTypeId(assessmentTypeId);
  }

  return clientSubformData;
}
