import React, { useState } from 'react';
import { Table } from '@lotus/components';
import { useField } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import {Stack} from '@mui/material';
import { Button } from '@lotus/components';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useClient } from 'contexts/ClientContext';
import { CONSTANTS } from './ClientServicesEnums';
import ClientPremiumPaymentViewer from './ClientPremiumPaymentViewer';
import moment from 'moment';

export default function ClientPremiumPaymentsList({ premiumPaymentConfig, premiumPayments, clientId }) {

    const { verifyPermission } = useAuthState();
    const { upsertClientPremiumPayment } = useClient();

    const [premiumPaymentsField] = useField(premiumPayments);
    const [premiumPaymentView, setPremiumPaymentView] = useState();
    const [modalType, setModalType] = useState(CONSTANTS.VIEW_MODAL_TYPE);
    const [rowId, setRowId] = useState();
    const editDisabled = !verifyPermission(PERMISSIONS.ADD_EDIT_DELETE_PREMIUM_PAYMENTS);

    const closePremiumPaymentViewer = () => {
        setPremiumPaymentView(null);
        setRowId(null);
    };
    
    const updateClientPremiumPayment = (values, type) => {
        upsertClientPremiumPayment(clientId, rowId, type, values);
        setPremiumPaymentView(null);
        setRowId(null);
    };

    const handleRowClicked = (rowData) => {
        const rowId = rowData[0];
        // get premium payment result using the row id
        setModalType(CONSTANTS.VIEW_MODAL_TYPE);
        setRowId(rowId);
        setPremiumPaymentView(true);
    };

    const getPremiumPaymentById = (rowId) => {
        return premiumPaymentsField && premiumPaymentsField.value ? premiumPaymentsField.value.filter(result => result.id === rowId)[0] : null;
    }

    const getEntryMode = (rowId) => {
        const rowObj = getPremiumPaymentById(rowId);
        return rowObj && rowObj?.entryMode.toLowerCase();
    }

    // Standard fields
    let columns = [
        {
            name: 'id',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'clientId',
            options: {
                display: 'excluded'
            }
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Paid Date',
            name: 'sortable_paid_date',
            options: {
                customBodyRender: (value) => {
                    return <div>{value ? moment(value).format("MM/DD/YYYY") : 'N/A'}</div>;
                }
            }
        },
        {
            label: 'Coverage Start Date',
            name: 'datePremiumCoverageFrom',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Coverage End Date',
            name: 'datePremiumCoverageTo',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Carrier',
            name: 'carrier',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Premium Amount',
            name: 'premiumAmount',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Admin Fee Amount',
            name: 'adminFeeAmount',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Total Amount',
            name: 'totalAmount',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Payment Type',
            name: 'paymentType',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Entry Mode',
            name: 'entryMode',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        }
    ];

    if(premiumPaymentConfig && premiumPaymentConfig?.enabled) {
        // Configured basic fields
        const configuredFields = [
            {
                label: 'AdjustmentFlag',
                name: 'adjustmentFlag',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.typeCategory?.adjustmentFlag ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Refunded',
                name: 'refunded',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.typeCategory?.refunded ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Voucher Date',
                name: 'voucherDate',
                options: {
                    display: premiumPaymentConfig.paymentCategory?.voucherDate ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Voucher ID',
                name: 'voucherId',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.paymentCategory?.voucherId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Authorization Number',
                name: 'authNum',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.paymentCategory?.authNum ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Payment Method',
                name: 'paymentMethod',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.paymentCategory?.paymentMethod ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Payment ID',
                name: 'paymentId',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.paymentCategory?.paymentId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Payment Refund Unique ID',
                name: 'paymentRefundUniqueId',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.paymentCategory?.paymentRefundUniqueId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Plan Name',
                name: 'planName',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.premiumCoverageCategory?.planName ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'PlanMember ID',
                name: 'planMemberId',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.premiumCoverageCategory?.planMemberId ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            },
            {
                label: 'Plan Type',
                name: 'planType',
                options: {
                    sort: false,
                    display: premiumPaymentConfig.premiumCoverageCategory?.planType ? false : 'excluded',
                    customBodyRender: (value) => {
                        return value ? value : 'N/A'
                    }
                }
            }
        ];
        columns = columns.concat(configuredFields);
    }

    return (
    <>
        <LotusFormItem extraWide style={{ marginTop: -50 }}>
            <Stack justifyContent="flex-end" direction="row">
                <Button onClick={() => {setModalType(CONSTANTS.ADD_MODAL_TYPE); setPremiumPaymentView(true);}} plusIcon disabled={editDisabled}>
                Add Record
                </Button>
            </Stack>
        </LotusFormItem>
        <LotusFormItem extraWide>
            <Table
            columns={columns}
            data={premiumPaymentsField.value}
            options={{
                pagination: true,
                filter: false,
                search: false,
                searchOpen: false,
                download: false,
                print: false,
                viewColumns: true,
                searchAlwaysOpen: false,
                rowsPerPageOptions: [10, 20, 50],
                textLabels: {
                    body: {
                      noMatch: "Sorry, no premium payments found for this client."
                    }
                }
            }}
            handleRowClicked={handleRowClicked}
            />
        </LotusFormItem>

        <ActionDialog
            maxWidth="sm"
            open={Boolean(premiumPaymentView)}
            handleClose={(modalType === CONSTANTS.VIEW_MODAL_TYPE && getEntryMode(rowId) !== CONSTANTS.MANUAL_ENTRY_MODE) ? closePremiumPaymentViewer : null}
            scroll="body"
            content={
                <ClientPremiumPaymentViewer
                    premiumPaymentId={rowId}
                    modalType={modalType}
                    editDisabled={editDisabled}
                    entryMode={getEntryMode(rowId)}
                    clientId={clientId}
                    premiumPaymentObj={modalType === CONSTANTS.VIEW_MODAL_TYPE ? getPremiumPaymentById(rowId) : []}
                    premiumPaymentConfig={premiumPaymentConfig}
                    handleCancel={closePremiumPaymentViewer}
                    handleSave={updateClientPremiumPayment}
              />
            }
        />
    </>
    );
}