export const messageTypes = {
  LOADING_PERMISSION_TEMPLATE_TO_EDIT_SUCCESS: 'LOADING_PERMISSION_TEMPLATE_TO_EDIT_SUCCESS',
  LOADING_STARTING_PERMISSION_TEMPLATE_SUCCESS: 'LOADING_STARTING_PERMISSION_TEMPLATE_SUCCESS',
  LOADING_PERMISSION_CATEGORIES_SUCCESS: 'LOADING_PERMISSION_CATEGORIES_SUCCESS',
  LOADING_PERMISSION_TEMPLATE_TO_REVIEW_SUCCESS: 'LOADING_PERMISSION_TEMPLATE_TO_REVIEW_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.LOADING_PERMISSION_TEMPLATE_TO_EDIT_SUCCESS:
    case messageTypes.LOADING_STARTING_PERMISSION_TEMPLATE_SUCCESS:
    case messageTypes.LOADING_PERMISSION_CATEGORIES_SUCCESS:
    case messageTypes.LOADING_PERMISSION_TEMPLATE_TO_REVIEW_SUCCESS:
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
