import React from 'react';
import { Table } from '@lotus/components';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function ProgramsList({ programs, handleCreateProgramClicked }) {
  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Program Name',
      name: 'name',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <Link to={`/programs/edit/${id}`}>
              {value}
            </Link>
          );
        },
      },
    },
  ];

  return (
    <>
      <LotusSpacedBlock>
        <Stack justifyContent="flex-end" direction='row'>
          <LotusButton color="primary" onClick={handleCreateProgramClicked}>
            Add New Program
          </LotusButton>
        </Stack>
      </LotusSpacedBlock>
      <Table
        columns={columns}
        data={programs}
        options={{
          selectableRows: 'none',
          selectableRowsHideCheckboxes: false,
          selectableRowsHeader: false,
        }}
      />
    </>
  );
}
