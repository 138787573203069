import React from 'react'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/material";
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';

export default function RequestPasswordResetForm({
  values,
  handleChange,
  touched,
  errors,
}) {
  const handleCancel = () => {
    window.location.reload();
  }
  return (
    <>
      {values.operation === 'resetPassword' && (
        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="left"
          alignItems="left"
        >
          <LotusSpacer /><LotusSpacer />
          <Typography style={{ fontWeight: 400, width: 340, fontSize: 16 }}>
            Enter your email address and we will send instructions on how to reset your password.
          </Typography>
          <LotusSpacer /><LotusSpacer />

          <LotusTextInput
            required
            autoFocus
            autoComplete="off"
            id="resetEmail"
            name="resetEmail"
            label="Email Address"
            value={values.resetEmail}
            style={{ width: 340 }}
            onChange={handleChange}
            error={touched?.resetEmail && Boolean(errors?.resetEmail)}
          />

          {(values.operation === 'resetPassword' && values.errorMsg) && (
            <Typography variant="body" color='#b53f3f' style={{ width: 340, fontSize: 16 }}>{values.errorMsg}</Typography>
          )}

          <LotusSpacer /><LotusSpacer /><LotusSpacer />
          <LotusButton style={{ width: "340px", height: '42px', fontWeight: 500, fontSize: 15 }} type="submit">
            CONTINUE
          </LotusButton>

          <LotusSpacer /><LotusSpacer />


          <LotusButton variant="outlined" style={{ width: "340px", height: '42px', fontWeight: 500, fontSize: 15 }}
            onClick={e => { handleCancel(e); }}
          >
            CANCEL
          </LotusButton>

        </Box>
      )}
    </>
  );
};


