import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useField } from 'formik';
import { Box, Checkbox, Chip, Typography, InputLabel, OutlinedInput } from '@mui/material';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusMultiSelect({
  label,
  items,
  style,
  required,
  dontCheckRequiredFields,
  readOnlyRows,
  ...props
}) {
  const [field, meta] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && (!val || val.length === 0)) {
      return `${label} is required`;
    }
  }});

  const labelText = label + (required ? ' * ' : '');

  return (
    !props.disabled ? 
    <FormControl fullWidth variant="outlined" size="small" style={{width: '100%', ...style}}>
      <InputLabel id={`${field.name}-outlined-label`}>{labelText}</InputLabel>
      <Select
        required={required}
        multiple
        labelId={`${field.name}-outlined-label`}
        input={<OutlinedInput label={labelText} />}
        value={field.value}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected &&
              selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    (items &&
                      items.find((itm) => itm.value === value) &&
                      items.find((itm) => itm.value === value).label) ||
                    value
                  }
                />
              ))}
          </Box>
        )}
        {...field}
        {...props}
      >
        {field.value && items &&
          items.map((item) => {
            return (
              <MenuItem key={item.value} value={item.value}>
                <Checkbox checked={field.value.indexOf(item.value) > -1} disabled={item.disabled} />
                <Typography variant="body2">{item.label}</Typography>
              </MenuItem>
            );
          })}
      </Select>
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </FormControl> :
    <LotusReadOnlyFieldView 
      label={label} 
      value={(items && (field.value || []).map(fv => items.find(x => x.value === fv)?.label).join(', ')) || ''}
      multiline={readOnlyRows ? true : false}
      rows={readOnlyRows}
    />
  );
}
