import React, { useEffect, useState } from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';
import * as yup from 'yup';
import DynamicFormPreviewer from './DynamicFormPreviewer';
import FormKeyEditor from './FormKeyEditor';
import FormKeyDisplay from './FormKeyDisplay';
import { formatUsDate } from 'lib/formatting';
import { useFormDefinitions } from 'contexts/DynamicFormContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

const validationSchema = yup.object({
  formTypeId: yup.string().required('Form Type is required'),
  formData: yup.string().required('Form Definition is required'),
  formSubtypeCustomSequence: yup.string().nullable().matches(/^[0-9]+$/, {message: "Sequence must be numeric", excludeEmptyString: true})
});

const formTypesRequiringProgram = [
  'enrollment',
  'client_program',
];

// sorry, this is a form to add/edit an agency dynamic form, so FormForm seems appropriate
// and i think its funny
export default function AgencyDyamicFormForm({ formDefinition, afterSave }) {
  const [initialFormValues, setInitialFormValues] = useState();
  const { formTypes, createAssessmentType, createDynamicForm } = useFormDefinitions();

  useEffect(() => {
    const initialValues = formDefinition
      ? {
          programId: formDefinition.programId,
          assessmentTypeId: formDefinition.assessmentTypeId,
          formData: JSON.stringify(formDefinition.data, null, 2),
          formTypeId: formDefinition.formTypeId,
          formSubtypeId: formDefinition.formSubtypeId,
          formSubtypeCustomName: formDefinition.formSubtypeCustomName,
          formSubtypeCustomSequence: formDefinition.formSubtypeCustomSequence,
        }
      : {
          programId: '',
          assessmentTypeId: '',
          formData: '',
          formTypeId: '',
          formSubtypeId: '',
          formSubtypeCustomName: '',
          formSubtypeCustomSequence: '',
          newAssessmentTypeName: '',
        };
    setInitialFormValues(initialValues);
  }, [formDefinition]);

  const saveForm = async (values) => {
    let toSubmit = {...values};
    if (!formDefinition && !values.assessmentTypeId && values.newAssessmentTypeName) {
      const key = values.newAssessmentTypeName.replace(/ /g, '_').toLowerCase();
      const newId = await createAssessmentType(key, values.newAssessmentTypeName);
      toSubmit.assessmentTypeId = newId;
    }
    toSubmit.formData = JSON.parse(values.formData);
    await createDynamicForm(toSubmit);

    if (afterSave) {
      await afterSave();
    }
  };

  return (
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        enableReinitialize
        validate={async (values) => {
          let result = {};
          try {
            validationSchema.validateSync(values, { abortEarly: false });
          } catch (err) {
            result = yupToFormErrors(err);
          }
          if (!formDefinition) {
            if (formTypes && values.formTypeId && formTypesRequiringProgram.includes(formTypes.find((ft) => ft.id === values.formTypeId).key) && !values.programId
            ) {
              result.programId = 'Program is required for this form type';
            }
            if (formTypes && values.formTypeId && formTypes.find((ft) => ft.id === values.formTypeId).key === 'assessment') {
              if (!values.assessmentTypeId && !values.newAssessmentTypeName) {
                result.assessmentTypeId = 'Assessment Type is required for the Assessment form type';
              }
              if (values.assessmentTypeId && values.newAssessmentTypeName) {
                result.assessmentTypeId = 'Either Assessment Type or New Assessment Type Name is required, but not both';
              }
            }
            if (!values.formSubtypeId && !values.formSubtypeCustomName) {
              result.formSubtypeId = 'Either Form Subtype or Custom Form Subtype Name is required';
            }
          }
          if (values.formData) {
            try {
              JSON.parse(values.formData);
            }
            catch (err) {
              result.formData = 'Form Definition is not valid JSON';
            }
          }
          return result;
        }}
        onSubmit={async (values, actions) => {
          await saveForm(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          setFieldValue,
          setFieldTouched,
        }) => {
          const doSave = async (values, actions) => {
            setFieldTouched('formDataError', true);
            await handleSubmit(values, actions);
          };
          return (
            <div>
              {values && (
                <>
                  <Form onSubmit={doSave}>
                    <LotusForm>
                      {!formDefinition && (
                        <FormKeyEditor
                          values={values}
                          touched={touched}
                          errors={errors}
                          formTypesRequiringProgram={
                            formTypesRequiringProgram
                          }
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                      {formDefinition && (
                        <>
                          <FormKeyDisplay formDefinition={formDefinition} />
                          <LotusFormItem>
                            Active Date: {formatUsDate(values.dateActive)}
                          </LotusFormItem>
                        </>
                      )}
                      <LotusFormItem>
                        <LotusTextInput
                          name="formData"
                          label="Form Definition"
                          multiline
                          minRows={20}
                          required />
                      </LotusFormItem>
                    </LotusForm>
                  </Form>
                  <LotusSpacedBlock>
                    <DynamicFormPreviewer formData={values.formData} />
                  </LotusSpacedBlock>
                  <LotusSpacedBlock>
                    <LotusButton onClick={handleSubmit}>
                      {formDefinition ? 'Save Form Changes' : 'Create Form'}
                    </LotusButton>
                  </LotusSpacedBlock>
                </>
              )}
            </div>
          );
        }}
      </Formik>
  );
}
