import React, { useState } from 'react';
import {Stack, Typography} from '@mui/material';
import ActionDialog from 'components/Dialogs/ActionDialog';
import DocumentationUploader from './DocumentationUploader';
import DocumentationList from './DocumentationList';
import { Button } from '@lotus/components';
import { useField, useFormikContext } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';

export default function ClientInfoDocumentation ({ clientId, documentationListFieldName, allDocumentationTypes, disabled, label, required}) {
  
  const [documentationField, meta] = useField({name: documentationListFieldName, validate: (val) => {
    const lbl = label || 'Field';
    if (required && val.length === 0) {
      return `${lbl} is required`;
    }
    return null;
  }});
  const { setFieldValue } = useFormikContext();

  const [showAddDocumentation, setShowAddDocumentation] = useState(false);

  const addDocumentation = async (documentation) => {
    setShowAddDocumentation(false);
    setFieldValue(documentationListFieldName, [documentation, ...documentationField.value]);
  };

  return allDocumentationTypes && (
    <div>
      {meta.touched && meta.error && (
        <LotusFormItem>
          <Typography variant="body2" color="error">
            {meta.error}
          </Typography>
        </LotusFormItem>
      )}
      <LotusFormItem>
        {documentationField.value && (
          <DocumentationList clientId={clientId} data={documentationField.value} allDocumentationTypes={allDocumentationTypes}/>
        )}
      </LotusFormItem>
      <LotusFormItem>
        <Stack justifyContent="flex-start" direction="row">
          <Button onClick={() => {setShowAddDocumentation(true);}} plusIcon disabled={disabled}>
            Add Documentation
          </Button>
        </Stack>
      </LotusFormItem>

      <ActionDialog
        maxWidth="sm"
        open={showAddDocumentation}
        content={
          <DocumentationUploader
            documentationTypes={allDocumentationTypes.filter(dt => dt.isActive && !dt.isDeleted)}
            clientId={clientId}
            handleCancel={() => {setShowAddDocumentation(false);}}
            handleSave={addDocumentation}
          />
        }
      />
    </div>
  );
};