import React from 'react';
import { Stack } from '@mui/material';

// This is for a group of side by side buttons, ensuring consistent spacing
export default function LotusButtonList({
  children
}) {
  return (
    <Stack direction="row" spacing={2}>
      {children && React.Children.map(children, itm => {
        return itm
      })}
    </Stack>
  );
}