import React, { useEffect, useState } from 'react';
import Documents from 'components/Documents';
import { useProgram } from 'contexts/ProgramContext';
import { useClient } from 'contexts/ClientContext';
import { FilesProvider } from 'contexts/FilesContext';

export const ProgramDocumentsTab = ({ clientId, programId, disabled }) => {

  const [programDocumentTypes, setProgramDocumentTypes] = useState();
  const [programDocuments, setProgramDocuments] = useState();

  const {currentProgram, setProgram, currentProgramDocumentTypeIds, loadProgramDocumentTypes} = useProgram();
  const {clientProgramDocuments, loadClientProgramDocuments, insertClientProgramDocuments} = useClient();

  useEffect(() => {
    if ((clientId && programId) && !clientProgramDocuments) {
      loadClientProgramDocuments(clientId, programId);
    }
  }, [clientId, programId]);

  useEffect(() => {
    if (!currentProgram || currentProgram.id !== programId) {
      setProgram(programId);
    }
  }, [programId]);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId && !currentProgramDocumentTypeIds) {
      loadProgramDocumentTypes();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (clientProgramDocuments) {
      if (clientProgramDocuments.length === 1) {
        const doc = clientProgramDocuments[0].data;
        setProgramDocuments(doc);
      } else {
        setProgramDocuments([]);
      }
    }
  }, [programId, clientProgramDocuments]);

  useEffect(() => {
    if (currentProgramDocumentTypeIds) {
      setProgramDocumentTypes(currentProgramDocumentTypeIds);
    }
  }, [programId, currentProgramDocumentTypeIds]);

  const onDocumentAdded = async (doc) => {
    await insertClientProgramDocuments(clientId, programId, [doc]);
  };

  return (
    <FilesProvider>
      <Documents
        documents={programDocuments || []}
        documentTypeIds={programDocumentTypes}
        onDocumentAdded={onDocumentAdded}
        disabled={disabled}
      />
    </FilesProvider>
  );
};
