import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './AgencySearchContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';


const initialState = {
  granteeAgencies: undefined,
  agenciesForGrantee: undefined,
  agencyCaseManagers: undefined
};

const AgencySearchContext = createContext({defaultValue: initialState});

export const AgencySearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgencySearchContext.Provider value={{ state, dispatch }}>
      {children}
    </AgencySearchContext.Provider>
  );
};

export const useAgencySearch = () => {
  const { state, dispatch } = useContext(AgencySearchContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadGranteeAgencies = async () => {
    try {
      addBusyBee('loadGranteeAgencies');

      const toPost = {
        operationName: 'getGranteeAgencies',
      }

      const url = `/api/agencies`;
      const result = await axios.post(url, toPost);

      let agencies = parseApiResult(result.data).body;
      
      dispatch({
        type: messageTypes.LOADING_GRANTEE_AGENCIES_SUCCESS,
        payload: {
          granteeAgencies: agencies,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadGranteeAgencies');
    }
  };

  const loadAgenciesForGrantee = async (granteeId) => {
    try {
      addBusyBee('loadAgenciesForGrantee');

      const toPost = {
        operationName: 'getAgenciesForGrantee',
        granteeId: granteeId
      }

      const url = `/api/agencies`;
      const result = await axios.post(url, toPost);

      let agenciesForGrantee = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_AGENCIES_FOR_GRANTEE_SUCCESS,
        payload: {
          agenciesForGrantee
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgenciesForGrantee');
    }
  };

  const loadAgencyCaseManagers = async (agencyId) => {
    try {
      addBusyBee('loadAgencyCaseManagers');

      const toPost = {
        operationName: 'getAgencyCaseManagers',
        agencyId: agencyId
      }

      const url = `/api/agencies`;
      const result = await axios.post(url, toPost);

      let agencyCaseManagers = parseApiResult(result.data).body;
      
      dispatch({
        type: messageTypes.LOADING_AGENCY_CASE_MANAGERS_SUCCESS,
        payload: {
          agencyCaseManagers
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyCaseManagers');
    }
  };

  if (state) {
    return {
      ...state,
      loadGranteeAgencies,
      loadAgenciesForGrantee,
      loadAgencyCaseManagers
    };
  }

  return {};
};
