import React, { useState } from 'react';
import UserForm from './forms/UserForm';
import AddUserConfirmPanel from './AddUserConfirmPanel';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function AddUserPanel({ handleBackClicked }) {
  const [savedUser, setSavedUser] = useState();

  const showConfirmPanel = (user) => {
    setSavedUser(user);
  };

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton onClick={handleBackClicked} variant="outlined">Back</LotusButton>
      </LotusSpacedBlock>
      {!savedUser && (
        <LotusPageSection header='Request New User'>
          <UserForm afterSave={showConfirmPanel} />
        </LotusPageSection>
      )}
      {savedUser && (
          <AddUserConfirmPanel email={savedUser.email} />
      )}
    </LotusPaper>
  );
}
