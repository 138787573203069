import React from 'react';
import LotusInputField from '../LotusInputField';


export default function LotusTextInput({
  name,
  label,
  helperText,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly,
  handleChange,
  startAdornment,
  endAdornment,
  lettersOnly,
  maxLength,
  regexToMatch,
  regexValidationMessage
}) {
  return (
    <LotusInputField
      name={name}
      label={label}
      helperText={helperText}
      required={required}
      dontCheckRequiredFields={dontCheckRequiredFields}
      disabled={disabled}
      readOnly={readOnly}
      handleChange={handleChange}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      lettersOnly={lettersOnly}
      maxLength={maxLength}
      regexToMatch={regexToMatch}
      regexValidationMessage={regexValidationMessage}
    />
  );
}