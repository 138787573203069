import React, { useEffect } from 'react';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useLists } from 'contexts/ListsContext';

export default function CategoryLinker() {
  const { careCategories, loadCareCategories } = useLists();

  useEffect(() => {
    if (!careCategories) {
      loadCareCategories();
    }
  }, []);

  return careCategories && (
    <LotusCheckboxList
      name="selectedCareCategories"
      items={careCategories.map(pt => {return {label: pt.name, value: pt.id}})}
    />
  );
}
