import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import LotusPageSubSection from 'components/widgets/Layout/LotusPageSubSection';
import { useEligibilityTimeline } from 'contexts/EligibilityTimelineContext';
import { ELIGIBILITY_APPLICATION_STATUS, TIMELINE_STEP, TIMELINE_COLOR } from 'lib/eligibilityEnums';
import { useUserAgency } from 'contexts/UserAgencyContext';
import EligibiityStepper from './EligibilityStepper';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { useLists } from 'contexts/ListsContext';
import { EventDetailsDocuments, EventDetailsField, EventDetailsSection, HistoricalEvents } from 'components/widgets/Composites/HistoricalEvents';


function EligibilityIndicator({eligible}) {
  const theme = useTheme();

  return (
    <Stack direction="column" justifyContent="flex-start" gap={1} style={{paddingTop: "12px 0"}}>
      <Typography variant="body2">Ryan White Eligibility</Typography>
      {eligible ?
        <div style={{display: 'inline-flex', padding: '2px 4px', background: theme.palette.success.light,  alignItems: 'center', gap: 4, width: 'fit-content' }}>
          <CheckCircleIcon style={{width: '16px', color: theme.palette.success.dark}} />
          <Typography variant="body2">ELIGIBLE</Typography>
        </div>
        :
        <div style={{display: 'inline-flex', padding: '2px 4px', background: theme.palette.error.light,  alignItems: 'center', gap: 4, width: 'fit-content' }}>
          <CancelIcon style={{width: '16px', color: theme.palette.error.dark}} />
          <Typography variant="body2">INELIGIBLE</Typography>
        </div>
      }
    </Stack>
  );
}

function EligibilityDetails({details}) {
  const { userAgencyClientInfoConfig } = useUserAgency();
  const { hivDiseaseStages, loadClientInfoPicklists } = useLists();
  
  useEffect(() => {
    if (!hivDiseaseStages) {
      loadClientInfoPicklists();
    }
  }, []);

  return (
    <div style={{paddingLeft: 16, paddingRight: 16}}>
      <EventDetailsSection title="Documents">
        <EventDetailsDocuments details={details}/>
      </EventDetailsSection>
      {details.income &&
        <EventDetailsSection title="Income">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.income.rwIneligible}/>
            <EventDetailsField label="FPL" value={(userAgencyClientInfoConfig.existingClients.useMagi ? details.income.magiHouseholdFederalPovertyLevelPct : details.income.householdFederalPovertyLevelPct) || '--'} />
            <EventDetailsField label="Monthly Income" value={userAgencyClientInfoConfig.existingClients.useMagi ? details.income.monthlyHouseholdModifiedAdjustedGrossIncome : details.income.monthlyHouseholdGrossIncome} />
            <EventDetailsField label="Household" value={details.income.totalHouseholdSize} />
          </Stack>
        </EventDetailsSection>
      }
      {details.residency &&
        <EventDetailsSection title="Residency">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.residency.rwIneligible}/>
            <EventDetailsField label="Address" value={details.residency.primaryAddress} />
            <EventDetailsField label="City" value={details.residency.primaryAddressCity} />
            <EventDetailsField label="State" value={details.residency.primaryAddressState} />
            <EventDetailsField label="County" value={details.residency.primaryAddressCounty} />
          </Stack>
        </EventDetailsSection>
      }
      {details.hivStatus &&
        <EventDetailsSection title="HIV Status">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.hivStatus.rwIneligible}/>
            <EventDetailsField label="Current Disease State" value={hivDiseaseStages && details.hivStatus.hivStatusId ? hivDiseaseStages.find(s => s.id === details.hivStatus.hivStatusId)?.itemName : '--'} />
            <EventDetailsField label="Interviewer Signature" value={details.hivStatus.hivCertificationSignature} />
            <EventDetailsField label="Date Signed" value={details.hivStatus.hivCertificationDate} />
          </Stack>
        </EventDetailsSection>
      }
    </div>
  );
}

function EligibilityHistory({clientId}) {
  const { applications, eligibilityPeriods, appEligDetails, loadEligibilityPeriodsForClient, loadEligibilityDetailsForApplication, loadApplicationsForClient } = useEligibilityTimeline();
  const [ eligibilityHistory, setEligibilityHistory ] = useState();

  useEffect(() => {
    if (clientId) {
      loadApplicationsForClient(clientId);
      loadEligibilityPeriodsForClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (applications && eligibilityPeriods) {

      const getUpdateSubtext = (income, residency, hiv) => {
        const changes = [];
        if (income) { changes.push('Income'); }
        if (residency) { changes.push('Residency'); }
        if (hiv) { changes.push('HIV Status') }
        if (changes.length === 1) {
          return `${changes[0]} Change`;
        }
        if (changes.length === 2) {
          return `${changes[0]} and ${changes[1]} Change`;
        }
        if (changes.length === 3) {
          return `${changes[0]}, ${changes[1]} and ${changes[2]} Change`;
        }
        return '';
      }

      let history = {periods: []};
      let sortedPeriods = [...eligibilityPeriods.sort((a,b) => moment(a.endDate,'MM/DD/YYYY') < moment(b.endDate,'MM/DD/YYYY') ? 1 : -1)];
      sortedPeriods.forEach(p => history.periods.push({...p}));

      let sortedApps = applications.sort((a,b) => moment(a.createDate,'MM/DD/YYYY HH:mm:ss') < moment(b.createDate,'MM/DD/YYYY HH:mm:ss') ? 1 : -1);
      sortedApps.forEach(a => {
        if (a.statusKey === 'completed' || a.statusKey === 'terminated') {
          let period = history.periods.find(p => moment(p.startDate,'MM/DD/YYYY') <= moment(a.statusEffectiveDate, 'MM/DD/YYYY') && moment(p.endDate,'MM/DD/YYYY') >= moment(a.statusEffectiveDate, 'MM/DD/YYYY'));
          if (period) {
            let label = null, subtext = null, icon = null, detailsUnavailable = undefined, overrideDate = null;
            if (a.isDeletingAddressesUpdate ) {
              label = 'Eligibility Data Update';
              icon = 'update';
              subtext = 'Address Update Removed';
              detailsUnavailable = true;
            }
            else if (a.isAdapOnlyApplication && a.rwEligible) { 
              // Ignore this as there's no eligibility impact. 
              // If not eligible for some reason it will be caught in another case below
            }
            else if (a.isDeceased) {
              label = 'Eligibility Ended';
              icon = 'ended';
              subtext = 'Client Deceased';
              detailsUnavailable = true;
            }
            else if (a.isInitialApplication && !a.isInterimUpdate && a.rwEligible) {
              label = 'Eligibility Started';
              icon = 'started';
            }
            else if (a.isInitialApplication && !a.isInterimUpdate && !a.rwEligible) {
              label = 'Ryan White Ineligible';
              icon = 'ended';
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && a.rwEligible) {
              label = 'Eligibility Renewed';
              icon = 'renewed';
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && !a.rwEligible) {
              label = 'Eligibility Ended';
              subtext = 'Client is no longer Ryan White Eligible';
              icon = 'ended';
            }
            else if (a.isInterimUpdate && a.rwEligible && (a.hasIncomeUpdates || a.hasResidencyUpdates || a.hasHealthUpdates)) {
              label = 'Eligibility Data Update';
              icon = 'update';
              subtext = getUpdateSubtext(a.hasIncomeUpdates, a.hasResidencyUpdates, a.hasHealthUpdates);
            }
            else if (a.isInterimUpdate && !a.rwEligible && a.eligibilityEndDate) {
              label = 'Eligibility Ended';
              icon = 'ended';
              subtext = getUpdateSubtext(a.hasIncomeUpdates, a.hasResidencyUpdates, a.hasHealthUpdates);
              overrideDate = a.eligibilityEndDate;
            }

            if (label) {
              if (!period.events) {
                period.events = [];
              }
              period.events.push({
                label,
                icon,
                subtext,
                date: overrideDate || a.statusEffectiveDate,
                createDate: a.createDate,
                id: a.id,
                detailsUnavailable
              });
            }
          }
        }
      });

      // Deal with address changes that cause end of eligibility that then get removed, as ultimately eligibility was not affected
      history.periods.forEach(period => {
        if (!period.events) {
          period.events = [];
        }
        if (period.events.length > 0) {
          let newEvents = []
          for (let i = 0; i < period.events.length; ) {
            if (period.events[i].subtext === 'Address Update Removed' && (i+1) < period.events.length) {
              // Leave this one and the next one out
              i = i + 2;
            } else {
              newEvents.push(period.events[i]);
              i++;
            }
          }
          period.events = newEvents;
        }
      });

      // Deal with address changes that haven't reached the effective date yet
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.events[0].label === 'Eligibility Ended' && moment(period.endDate,'MM/DD/YYYY') > moment().startOf('day')) {
          period.events.shift();
        }
      });

      // For each eligibility period, if it's in the past without an ended event being the last one, add that
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.events[0].label !== 'Eligibility Ended' && moment(period.endDate,'MM/DD/YYYY') < moment().startOf('day')) {
          period.events.unshift({
            label: 'Eligibility Ended',
            icon: 'ended',
            subtext: 'Ryan White Eligibility Expired',
            detailsUnavailable: true,
            date: period.endDate,
            createDate: moment().format('MM/DD/YYYY HH:mm:ss')
          });
        }
      });
      setEligibilityHistory(history);
    }
  }, [applications, eligibilityPeriods]);

  const retrieveAppDetailsIfNecessary = async (appId) => {
    let found = false;
    eligibilityHistory.periods.forEach(p => {
      if (p.events) {
        let evt = p.events.find(e => e.id === appId);
        if (evt && evt.details) {
          found = true;
        }
      }
    });
    if (!found) {
      await loadEligibilityDetailsForApplication(appId);
    }
  }

  useEffect(() => {
    if (appEligDetails && eligibilityHistory) {
      let newHist = {...eligibilityHistory};
      appEligDetails.forEach(ad => {
        newHist.periods.forEach(p => {
          if (p.events) {
            let evt = p.events.find(e => e.id === ad.appId);
            if (evt) {
              evt.details = ad.details;
            }
          }
        });
      });
      setEligibilityHistory(newHist);
    }
  }, [appEligDetails]);

  return eligibilityHistory && eligibilityHistory.periods.length > 0 && (
    <LotusPageSubSection header="Ryan White Eligibility History">
      <HistoricalEvents
        hist={eligibilityHistory} 
        periodTitleFunc={(p) => `Eligibility Period: ${p.startDate} - ${p.endDate || 'Current'}`}
        detailRetriever={retrieveAppDetailsIfNecessary} 
        EventDetailDisplayer={EligibilityDetails}/>
    </LotusPageSubSection>
  );
}

export default function EligibilityTimeline() {
  const { clientId } = useParams();
  const { verifyPermission } = useAuthState();
  const { userAgencyEligibilityConfig } = useUserAgency();
  const { timeline, loadEligibilityTimeline } = useEligibilityTimeline();
  const [stepperConfig, setStepperConfig] = useState();
  const [reviewDetailConfig, setReviewDetailConfig] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [appId, setAppId] = useState();
  const [showRequestAdap, setShowRequestAdap] = useState();
  const navigate = useNavigate();
  const { createEligibilityApplication } = useEligibilityApplication();

  useEffect(() => {
    if (clientId) {
      loadEligibilityTimeline(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    buildStepperConfigs();
    setShowRequestAdap(
      (verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) || verifyPermission(PERMISSIONS.APPLICATION_COMPLETER)) && 
       timeline && timeline.canRequestAdap);
  }, [timeline]);

  const disableTimelineButtons = (configs) => {
    configs.forEach((cfg) => {
      cfg.buttonEnabled = false;
    });
  }

  const getStatusDate = (statusKey) => {
    if (timeline.statusHistory && timeline.statusHistory.length > 0) {
      let status = timeline.statusHistory.find((statusHistory) => statusHistory.statusKey === statusKey);
      return status?.createdDate;
    }
  };

  const getApplicationStepConfig = (timeline) => {

    // By default, this is the NOT_STARTED config
    let cfg = {
      key: TIMELINE_STEP.APPLICATION_STEP,
      header: 'Application',
      label: '',
      dateVisible: false,
      date: '--/--/--',
      buttonVisible: true,
      buttonEnabled: true,
      buttonText: 'START',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    const getHeaderText = (statusName = "Started") => {
      let lbl = 'Application';
      if (timeline.isDeceased) {
        lbl = 'Deceased';
      } else if (timeline.isAdapOnlyApplication) {
          lbl = 'Recertification'; // not at all confusing
      } else if (timeline.isDeletingAddressesUpdate) {
        lbl = 'Deleting Address';
      } else if (timeline.isInterimUpdate) {
        lbl = "Update";
      } else if (timeline.isRecertificationApplication) {
        lbl = "Recertification";
      }
      return `${lbl}: ${statusName}`;
    }
    
    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING ||
        timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW ||
        timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.header = getHeaderText();
      cfg.dateVisible = true;
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.STARTED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
      cfg.buttonVisible = false;
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.STARTED) {
      cfg.header = getHeaderText();
      cfg.buttonText = 'CONTINUE';
      cfg.dateVisible = true;
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.STARTED);
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED || 
             timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.EXPIRED) {
      const expired = (timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ||
                      (!timeline.expirationDate); // initial app terminated immediately
      cfg.header = getHeaderText(timeline.statusName);
      cfg.buttonText = 'START NEW';
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_RED;
      cfg.buttonVisible = expired;
      cfg.dateVisible = true;
      cfg.date = getStatusDate(timeline.statusKey);
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
      const expired = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
      if (expired) {
        cfg.header = getHeaderText(timeline.statusName);
        cfg.buttonText = 'START NEW';
        cfg.dateVisible = true;
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
        cfg.date = getStatusDate(timeline.statusKey);
      } else {
        cfg.buttonVisible = false;
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
        cfg.header = getHeaderText(timeline.statusName);
        cfg.dateVisible = true;
        cfg.date = getStatusDate(timeline.statusKey);
      }
      setAppId(null);
    }
    return cfg;
  }

  const getReviewStepConfig = (timeline) => {
    // The cfg we see for the other steps is irrelevant here because we're injecting a different timeline for the content
    let cfg = {
      key: TIMELINE_STEP.REVIEW_STEP,
      header: '',
      iconColor: TIMELINE_COLOR.GREY,
      buttonVisible: false,
      buttonEnabled: false,
      buttonText: 'MAKE UPDATES',
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.buttonEnabled = true;
      cfg.buttonVisible = true;
    }
    return cfg;
  }

  const getEligibilityStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.ELIGIBILITY_STEP,
      header: 'Eligibility Start Date',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.eligibilityStartDate) {
      cfg.date = timeline.eligibilityStartDate;
    }

    return cfg;
  }

  const getRecertificationStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.RECERT_STEP,
      header: 'Recertification',
      label:  '--/--/--',
      dateVisible: false,
      buttonVisible: true,
      buttonEnabled: false,
      buttonText: 'START',
      iconColor: TIMELINE_COLOR.GREY
    };
    
    if (!timeline) {
      return cfg;
    }

    let recertActive = false;

    if (timeline.recertificationDate) {
      cfg.label = 'Available ' + timeline.recertificationDate;
      recertActive = 
          (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED  || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED) && // dont allow recert if an app is in progress
          timeline.recertificationDate && new Date(timeline.recertificationDate).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0) &&
          timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0);
    }

    if (recertActive) {
      cfg.buttonEnabled = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
      cfg.labelColor = TIMELINE_COLOR.BLACK;
    } else if (timeline.recertificationDate) {
      cfg.iconColor = TIMELINE_COLOR.LIGHT_GREY;
    }

    return cfg;
  }

  const getExpirationStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.EXPIRE_STEP,
      header: 'Expiration Date',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.expirationDate) {
      cfg.date = timeline.expirationDate;
      const eligibilityValid = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0);
      if (!eligibilityValid) {
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_RED;
      }
      cfg.headerColor = eligibilityValid ? TIMELINE_COLOR.BLUE_TEXT : TIMELINE_COLOR.ACTIVE_RED;
      cfg.dateColor = eligibilityValid ? TIMELINE_COLOR.BLUE_TEXT : TIMELINE_COLOR.ACTIVE_RED;
      cfg.hideIcon = eligibilityValid;
    } else {
      cfg.hideIcon = true;
    }

    return cfg;
  }

  const getRwReviewDetailConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.RW_REVIEW_STEP,
      header: 'RW Review',
      label: '',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY,
      activeIconStyle: false
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.label = 'Completed';
      cfg.date = timeline.rwEligibilityDeterminationDate;
      cfg.activeIconStyle = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW) {
      cfg.label = 'Submitted';
      cfg.date = timeline.statusEffectiveDate;
      cfg.activeIconStyle = false;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.label = 'Pending';
      cfg.date = getStatusDate(timeline.statusKey);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_ORANGE;
    }

    return cfg;
  }

  const getAdapReviewDetailConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.ADAP_REVIEW_STEP,
      header: (userAgencyEligibilityConfig?.application?.selectedAssistanceProgramName || 'ADAP') + ' Review',
      label: '',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY,
      activeIconStyle: false,
      adapOptedOut: timeline && !timeline.requestedSubprogramsCount
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? (timeline.adapAuthorized ? 'Authorized' : 'Denied') : 'Opted Out';
      cfg.dateVisible = timeline.requestedSubprogramsCount > 0;
      cfg.date = timeline.adapAuthorizationDecisionDate;
      cfg.activeIconStyle = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? 'Submitted' : 'Opted Out';
      cfg.dateVisible = timeline.requestedSubprogramsCount > 0;
      cfg.date = timeline.statusEffectiveDate;
      cfg.activeIconStyle = false;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? 'Pending' : 'Opted Out';
      cfg.date = timeline.requestedSubprogramsCount > 0 ? getStatusDate(timeline.statusKey) : getStatusDate(ELIGIBILITY_APPLICATION_STATUS.SUBMITTED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.label = 'Submitted';
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_ORANGE;
    }
    
    return cfg;
  }

  const buildStepperConfigs = () => {

    let applicationStepConfig = getApplicationStepConfig(timeline);
    let reviewStepConfig = getReviewStepConfig(timeline);
    let eligibilityStepConfig = getEligibilityStepConfig(timeline);
    let recertificationStepConfig = getRecertificationStepConfig(timeline);
    let expirationStepConfig = getExpirationStepConfig(timeline);

    let rwReviewDetailConfig = getRwReviewDetailConfig(timeline);
    let adapReviewDetailConfig = getAdapReviewDetailConfig(timeline);
    
    if (timeline) {
      if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW ||
          timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING ||
          timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.NOT_STARTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.STARTED) {
        setActiveStep(1);
        setAppId(timeline.id);
      } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.EXPIRED ||
                 timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED) {
        setAppId(null);
        const canStart = (timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)) ||
                          (!timeline.expirationDate && timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED); // initial app terminated immediately
        if (canStart) {
          setActiveStep(1);
        } else {
          setActiveStep(5);
        }
      } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
        const expired = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
        if (expired) {
          setActiveStep(1);
        } else {
          setActiveStep(3);
        }
        setAppId(null);
      }
    }

    let stepConfig = [applicationStepConfig, reviewStepConfig, eligibilityStepConfig, recertificationStepConfig, expirationStepConfig];
    let rvwDetailConfig = [rwReviewDetailConfig, adapReviewDetailConfig];

    if (!(verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) || verifyPermission(PERMISSIONS.APPLICATION_COMPLETER))) {
      disableTimelineButtons(stepConfig);
      disableTimelineButtons(rvwDetailConfig);
    }

    setStepperConfig(stepConfig);
    setReviewDetailConfig(rvwDetailConfig);
  }

  const requestAdap = async () => {
    const id = await createEligibilityApplication(clientId, false, false, true);
    if (id) {
      navigate(`/client/${clientId}/eligibility/application/${id}`);
    }
  }

  return clientId && stepperConfig && reviewDetailConfig && (
    <PageSection header="Eligibility">
      <LotusPageSubSection 
        header="Timeline"
        actions={
          <>
          {showRequestAdap &&
            (<Button variant="contained" onClick={() => {requestAdap();}}>
              <Typography variant="h6">Request {(userAgencyEligibilityConfig?.application?.selectedAssistanceProgramName || 'ADAP')}</Typography>
            </Button>)
          }
          </>
        }
      >
        <EligibiityStepper
          activeStep={activeStep}
          clientId={clientId}
          appId={appId}
          stepperConfig={stepperConfig}
          reviewTimelineConfig={reviewDetailConfig}
        />
      </LotusPageSubSection>
      <EligibilityHistory clientId={clientId}/>
    </PageSection>
  );
};