import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './FilesContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from 'axios';
import axiosInterceptor from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';
import { v4 as uuidv4 } from 'uuid';

const FilesContext = createContext();

const initialState = {
  lastReqestId: null,
  fileContents: null,
  mimeType: null,
};

export const FilesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FilesContext.Provider value={{ state, dispatch }}>
      {children}
    </FilesContext.Provider>
  );
};

export const useFiles = () => {
  const { state, dispatch } = useContext(FilesContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();


  const uploadFile = async (
    granteeId,
    fileName,
    fileType,
    binary,
    clientId,
    agencyId,
    subfolderName,
    successHandler,
    errorHandler

  ) => {
    if (fileName && fileType) {
      try {
        addBusyBee('uploadFile');

        const { data } = await axiosInterceptor.post('/api/files', {
          operationName: 'getUploadLink',
          granteeId,
          clientId,
          agencyId,
          subfolderName,
          fileName,
          fileType,
        });
        const resultObj = parseApiResult(data).body;
        const signedUrl = resultObj['url'];


        await axios.put(signedUrl, binary,
          {
            headers: {
              'content-type': fileType,
            }
          });

        //callback the success handler with a unique Id
        const uniqueId = uuidv4();
        if (successHandler) successHandler(uniqueId);

        dispatch({
          type: messageTypes.UPLOAD_FILE_SUCCESS,
          payload: {
            lastReqestId: uniqueId
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);

      } finally {
        removeBusyBee('uploadFile');
      }
    }
  };

  const getSignedURLForDownload = async (
    granteeId,
    clientId,
    agencyId,
    subfolderName,
    fileName,
    fileType
  ) => {
    if (fileName) {
      try {
        addBusyBee('downloadFile');
        const { data } = await axiosInterceptor.post('/api/files', {
          operationName: 'getDownloadLink',
          granteeId,
          clientId,
          agencyId,
          subfolderName,
          fileName,
          fileType,
        });
        const resultObj = parseApiResult(data).body;
        const signedUrl = resultObj['url'];

        return signedUrl;
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('downloadFile');
      }
    }
  };

  const downloadFileSync = async (
    granteeId,
    clientId,
    agencyId,
    subfolderName,
    fileName
  ) => {
    if (fileName) {
      try {
        let partNumber = 1;
        let contents = '';
        let moreToDownload = true;
        addBusyBee('downloadFile');
        while (moreToDownload) {
          const { data } = await axiosInterceptor.post('/api/files', {
            operationName: 'downloadFile', 
            granteeId,
            clientId,
            agencyId,
            subfolderName,
            fileName,
            partNumber} );
          const resultObj = parseApiResult(data).body;
          contents = contents + resultObj.content;
          partNumber++;
          moreToDownload = resultObj.moreToRead;
          //prevent against infinite loop. The API method should split files into 4MB chunks. We should
          //not have files more than 100MB, so there should never be more than 25 parts.
          if (partNumber > 25){
            throw new Error('An error has occurred downloading file');
          }
        }
        return contents;
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('downloadFile');
      }
    }
  };


  return {
    ...state,
    uploadFile,
    getSignedURLForDownload,
    downloadFileSync
  };
};
