import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Stack, Box } from "@mui/material";
import LotusTabPanel from 'components/widgets/Layout/LotusTabPanel';
import { useNavigate } from 'react-router-dom';
import { linkifyTabName } from 'lib/formatting';
const PREFIX = 'SubFormTabSelector';

const classes = {
  headerBar: `${PREFIX}-headerBar`,
};

// Note that we're making this component extend thru the page margins so its 
// consuming the page width (hence the -24 margins in the styling)
const StyledBox = styled(Box)(({ theme }) => {
  return {
    [`& .${classes.headerBar}`]: {
      background: theme.palette.secondary.light,
      paddingTop: 16,
      marginLeft: -24,
      marginRight: -24,
      paddingLeft: 24
    }
  }
});

export default function SubFormTabSelector({headerText, selectedTabName, tabs, tabPropertyToMatch, onTabChange, actions}) {

  const [tabIndex, setTabIndex] = useState();
  const navigate = useNavigate();

  const handleTabChange = async (event, tIdx) => {
    navigate(tabs[tIdx].link);
    setTabIndex(tIdx);
    if (onTabChange) {
      onTabChange(tabs[tIdx].label);
    }
  };

  useEffect(() => {
    if (selectedTabName && tabs) {
      const idx = tabs.findIndex(t => (tabPropertyToMatch ? t[tabPropertyToMatch]?.toLowerCase() : linkifyTabName(t.label)) === linkifyTabName(selectedTabName));
      if (idx >= 0) {
        setTabIndex(idx);
      } else {
        navigate(tabs[0].link);
      }
    }
  }, [selectedTabName, tabs]);
  
  return tabs && tabIndex !== undefined && (
    <StyledBox>
      <div className={classes.headerBar} style={{paddingLeft: 24}}>
        {(headerText || actions) &&
        <Grid container alignItems="center" style={{paddingBottom: 8}}>
          <Grid item xs={5}>
            <div style={{fontSize: 24, fontWeight: 400}}>{headerText}</div>
          </Grid>
          <Grid item xs={7}>
            <Stack justifyContent='flex-end' direction="row" spacing={1}>
              {actions}
            </Stack>
          </Grid>
        </Grid>
        }
        <LotusTabPanel
          value={tabIndex}
          handleTabChanged={handleTabChange}
          tabs={tabs}
          useColorForSelectedItem={true}
        />
      </div>
    </StyledBox>
  );
}
