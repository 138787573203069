import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";

const initialState = {
  client: undefined,
  clientProgramDiagnosisRecords: undefined,
  clientProgramConsents: undefined,
  clientProgramDocuments: undefined,
  clientDetails: undefined,
  clientAddresses: undefined,
  clientPersonalContacts: undefined,
  clientIncome: undefined,
  clientHealth: undefined,
  clientInsurance : undefined,
  currentClientInfoApprovalStatus: undefined,
  clientCareTeam: undefined,
  clientLabResultDocuments: undefined,
  clientPremiumPaymentDocuments: undefined
};

const ClientContext = createContext({defaultValue: initialState});

export const ClientContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClient = () => {
  const { state, dispatch } = useContext(ClientContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadClient = async (clientId) => {
    addBusyBee('loadClient');

    try {
      const { data } = await axios.post(`/api/client`, {operationName: 'getClientById', clientId});
      const { clientInfo } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CLIENT_SUCCESS,
        payload: {
          client: clientInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClient');
    }
  };

  const loadClientDetails = async (clientId) => {
    addBusyBee('loadClientDetails');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientDetails', clientId});
      let {clientDetails} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_DETAILS_SUCCESS,
        payload: {
          clientDetails: clientDetails,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientDetails');
    }
  };

  const upsertClientDetails = async (clientId, clientDetails) => {
    try {
      addBusyBee('upsertClientDetails');

      const toPost = {
        operationName: 'upsertClientDetails',
        clientId: clientId,
        clientDetails: clientDetails
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {clientDetails: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_DETAILS_SUCCESS,
        payload: {
          clientDetails: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientDetails');
    }
  };

  const loadClientAddresses = async (clientId) => {
    addBusyBee('loadClientAddresses');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientAddresses', clientId});
      let {addresses} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_ADDRESSES_SUCCESS,
        payload: {
          clientAddresses: addresses,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientAddresses');
    }
  };

  const upsertClientAddresses = async (clientId, addresses) => {
    try {
      addBusyBee('upsertClientAddresses');

      const toPost = {
        operationName: 'upsertClientAddresses',
        clientId: clientId,
        addresses: addresses
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {addresses: updAddreses, personalContacts: updPersonalContacts, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_ADDRESSES_SUCCESS,
        payload: {
          clientAddresses: updAddreses,
          clientPersonalContacts: updPersonalContacts,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientAddresses');
    }
  };

  const changeClientAddresses = async (clientId, addresses) => {
    try {
      addBusyBee('changeClientAddresses');

      const toPost = {
        operationName: 'changeClientAddresses',
        clientId: clientId,
        addresses: addresses
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {addresses: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.CHANGING_CLIENT_ADDRESSES_SUCCESS,
        payload: {
          clientAddresses: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('changeClientAddresses');
    }
  };

  const deleteClientAddresses = async (clientId, addressesId) => {
    try {
      addBusyBee('deleteClientAddresses');

      const toPost = {
        operationName: 'deleteClientAddresses',
        clientId: clientId,
        addressesId: addressesId
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {addresses: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.DELETING_CLIENT_ADDRESSES_SUCCESS,
        payload: {
          clientAddresses: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('deleteClientAddresses');
    }
  };

  const loadClientPersonalContacts = async (clientId) => {
    addBusyBee('loadClientPersonalContacts');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientPersonalContacts', clientId});
      let {personalContacts} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_PERSONAL_CONTACTS_SUCCESS,
        payload: {
          clientPersonalContacts: personalContacts,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientPersonalContacts');
    }
  };

  const upsertClientPersonalContacts = async (clientId, personalContacts) => {
    try {
      addBusyBee('upsertClientPersonalContacts');

      const toPost = {
        operationName: 'upsertClientPersonalContacts',
        clientId: clientId,
        personalContacts: personalContacts
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {personalContacts: updInfo} = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_PERSONAL_CONTACTS_SUCCESS,
        payload: {
          clientPersonalContacts: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientPersonalContacts');
    }
  };

  const loadClientIncome = async (clientId) => {
    addBusyBee('loadClientIncome');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientIncome', clientId});
      let {income} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_INCOME_SUCCESS,
        payload: {
          clientIncome: income,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientIncome');
    }
  };

  const upsertClientIncome = async (clientId, income) => {
    try {
      addBusyBee('upsertClientIncome');

      const toPost = {
        operationName: 'upsertClientIncome',
        clientId: clientId,
        income: income
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {income: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_INCOME_SUCCESS,
        payload: {
          clientIncome: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientIncome');
    }
  };

  const loadClientHealth = async (clientId) => {
    addBusyBee('loadClientHealth');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientHealth', clientId});
      let {health} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_HEALTH_SUCCESS,
        payload: {
          clientHealth: health,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientHealth');
    }
  };

  const upsertClientHealth = async (clientId, health) => {
    try {
      addBusyBee('upsertClientHealth');

      const toPost = {
        operationName: 'upsertClientHealth',
        clientId: clientId,
        health: health
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {health: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_HEALTH_SUCCESS,
        payload: {
          clientHealth: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientHealth');
    }
  };

  const upsertClientLabResult = async (clientId, labTestId, type, labTestObj) => {
    try {
      addBusyBee('upsertClientLabResult');

      const toPost = {
        operationName: 'upsertClientLabResult',
        clientId,
        type: type,
        labTestId: labTestId,
        labTestObj: labTestObj
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {health: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_LAB_RESULT_SUCCESS,
        payload: {
          clientHealth: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientLabResult');
    }
  };

  const loadClientInsurance = async (clientId) => {
    addBusyBee('loadClientInsurance');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientInsurance', clientId });
      let {insurance} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_INSURANCE_SUCCESS,
        payload: {
          clientInsurance: insurance,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientInsurance');
    }
  };

  const upsertClientInsurance = async (clientId, insurance) => {
    try {
      addBusyBee('upsertClientInsurance');

      const toPost = {
        operationName: 'upsertClientInsurance',
        clientId: clientId,
        insurance: insurance
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {insurance: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_INSURANCE_SUCCESS,
        payload: {
          clientInsurance: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientInsurance');
    }
  };
  
  const upsertClientPremiumPayment = async (clientId, premiumPaymentId, type, premiumPaymentObj) => {
    try {
      addBusyBee('upsertClientPremiumPayment');

      const toPost = {
        operationName: 'upsertClientPremiumPayment',
        clientId,
        type: type,
        premiumPaymentId: premiumPaymentId,
        premiumPaymentObj: premiumPaymentObj
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {insurance: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_PREMIUM_PAYMENT_SUCCESS,
        payload: {
          clientInsurance: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientPremiumPayment');
    }
  };

  const changeClientInsurance = async (clientId, insurance, adapSubprograms) => {
    try {
      addBusyBee('changeClientInsurance');

      const toPost = {
        operationName: 'changeClientInsurance',
        clientId: clientId,
        insurance: insurance,
        adapSubprograms: adapSubprograms
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {insurance: updInfo, restrictions} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.CHANGING_CLIENT_INSURANCE_SUCCESS,
        payload: {
          clientInsurance: updInfo,
          updateRestrictions: restrictions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('changeClientInsurance');
    }
  };

  const deleteClientInsurance = async (clientId, insuranceId) => {
    try {
      addBusyBee('deleteClientInsurance');

      const toPost = {
        operationName: 'deleteClientInsurance',
        clientId: clientId,
        insuranceId: insuranceId
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const {insurance: updInfo} = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.DELETING_CLIENT_INSURANCE_SUCCESS,
        payload: {
          clientInsurance: updInfo,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('deleteClientInsurance');
    }
  };

  const loadClientProgramDiagnosisRecords = async (clientId) => {
    addBusyBee('loadClientProgramDiagnosisRecords');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientProgramDiagnosisRecords', clientId });
      const clientData = parseApiResult(data).body;  
      
      dispatch({
        type: messageTypes.LOADING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS,
        payload: {
          clientProgramDiagnosisRecords: clientData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientProgramDiagnosisRecords');
    }
  };

  const upsertClientProgramDiagnosisRecords = async (
    clientId,
    programId,
    records
  ) => {
    try {
      addBusyBee('upsertClientProgramDiagnosisRecords');

      const toPost = {
        operationName: 'upsertClientProgramDiagnosisRecords',
        clientId: clientId,
        programId: programId,
        records: records
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const result = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS,
        payload: {
          clientId: clientId,
          programId: programId,
          data: records,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientProgramDiagnosisRecords');
    }
  };

  const loadClientProgramConsents = async (clientId, programId) => {
    addBusyBee('loadClientProgramConsents');

    try {
      const toPost = {
        operationName: 'getClientProgramConsents', 
        programId, 
        clientId 
      };
      const { data } = await axios.post(`/api/client`, toPost); 
      
      const records = parseApiResult(data).body;

      const mappedRecs = records.map(r => {
        return {
          clientId: r.client_id,
          programId: r.program_id,
          data: r.data
        };
      });

      dispatch({
        type: messageTypes.LOADING_CLIENT_PROGRAM_CONSENTS_SUCCESS,
        payload: {
          clientProgramConsents: mappedRecs,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientProgramConsents');
    }
  };

  const loadClientProgramDocuments = async (clientId, programId) => {
    addBusyBee('loadClientProgramDocuments');

    try {
      const { data } = await axios.post(`/api/client`, { operationName: 'getClientProgramDocuments', programId, clientId });
      const records = parseApiResult(data).body;

      const mappedRecs = records.map(r => {
        return {
          clientId: r.client_id,
          programId: r.program_id,
          data: r.data
        };
      });

      dispatch({
        type: messageTypes.LOADING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS,
        payload: {
          clientProgramDocuments: mappedRecs,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientProgramDocuments');
    }
  };

  const insertClientProgramConsents = async (clientId, programId, records) => {
    try {
      addBusyBee('insertClientProgramConsents');

      const toPost = { 
        operationName: 'addClientProgramConsents',
        clientId,
        programId: programId,
        consents: records
      };
      await axios.post(`/api/client`, toPost);
      await loadClientProgramConsents(clientId, programId);

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('insertClientProgramConsents');
    }
  };

  const insertClientProgramDocuments = async (clientId, programId, records) => {
    try {
      addBusyBee('insertClientProgramDocuments');

      const toPost = { 
        operationName: 'addClientProgramDocuments',
        programId,
        clientId,
        documents: records
      };
      await axios.post(`/api/client`, toPost);
      await loadClientProgramDocuments(clientId, programId);

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('insertClientProgramDocuments');
    }
  };

  const clearClientData = async () => {
    dispatch({
      type: messageTypes.INITIALIZE_CLIENT_DATA,
    });
  };

  const loadClientConsents = async (clientId) => {
    addBusyBee('loadClientConsents');

    try {
      const { data } = await axios.post(`/api/client`, { operationName: 'getClientConsents', clientId });
      const records = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CLIENT_CONSENTS_SUCCESS,
        payload: {
          clientConsents: records,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientConsents');
    }
  };

  const insertClientConsents = async (clientId, records) => {
    try {
      addBusyBee('insertClientConsents');

      const toPost = { 
        operationName: 'addClientConsents',
        clientId,
        consents: records
      };
      const { data } = await axios.post(`/api/client`, toPost);
      const latestConsents = parseApiResult(data).body;
      await loadClientConsents(clientId);
 
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('insertClientConsents');
    }
  };

  const loadClientInfoUpdateRestrictions = async (clientId) => {
    try {
      addBusyBee('loadClientInfoUpdateRestrictions');

      const toPost = { 
        operationName: 'getClientInfoUpdateRestrictions',
        clientId
      };
      const { data } = await axios.post(`/api/client`, toPost);
      const { restrictions } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CLIENT_INFO_UPDATE_RESTRICTIONS_SUCCESS,
        payload: {
          updateRestrictions: restrictions,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientInfoUpdateRestrictions');
    }
  };

  const loadClientCareTeam = async (clientId) => {
    addBusyBee('loadClientCareTeam');

    try {

      const { data } = await axios.post(`/api/client`, { operationName: 'getClientCareTeam', clientId });
      let {careTeam} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_CARE_TEAM_SUCCESS,
        payload: {
          clientCareTeam: careTeam
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientCareTeam');
    }
  };

  const upsertClientCareTeam = async (clientId, careInfo) => {
    try {
      addBusyBee('upsertClientCareTeam');

      const toPost = {
        operationName: 'upsertClientCareTeam',
        clientId: clientId,
        leadAgency: careInfo && careInfo.leadAgency ? careInfo.leadAgency : null,
        leadCaseManager: careInfo && careInfo.leadCaseManager ? careInfo.leadCaseManager : null
      };

      const { data } = await axios.post(`/api/client`, toPost);
      const { careTeam } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_CARE_TEAM_SUCCESS,
        payload: {
          clientCareTeam: careTeam
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertClientCareTeam');
    }
  };

  const loadClientLabResultDocuments = async (clientId, labResultId) => {
    addBusyBee('loadClientLabResultDocuments');

    try {
      const toPost = { 
        operationName: 'getClientLabResultDocuments',
        clientId,
        labResultId: labResultId
      };
      const { data } = await axios.post(`/api/client`, toPost);
      let {clientLabResultDocuments} = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CLIENT_LAB_RESULT_DOCUMENTS_SUCCESS,
        payload: {
          clientLabResultDocuments: clientLabResultDocuments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientLabResultDocuments');
    }
  };
  
  const loadClientPremiumPaymentDocuments = async (clientId, premiumPaymentId) => {
    addBusyBee('loadClientPremiumPaymentDocuments');

    try {
      const toPost = { 
        operationName: 'getClientPremiumPaymentDocuments',
        clientId,
        premiumPaymentId: premiumPaymentId
      };
      const { data } = await axios.post(`/api/client`, toPost);
      let {clientPremiumPaymentDocuments} = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CLIENT_PREMIUM_PAYMENT_DOCUMENTS_SUCCESS,
        payload: {
          clientPremiumPaymentDocuments: clientPremiumPaymentDocuments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientPremiumPaymentDocuments');
    }
  };

  const loadClientHasEligibilityApplication = async (clientId) => {
    addBusyBee('loadClientHasEligibilityApplication');

    try {

      const { data } = await axios.post(`/api/eligibilityApps`, { operationName: 'doesClientHaveApplication', clientId });
      let {hasApplication} = parseApiResult(data).body;  

      dispatch({
        type: messageTypes.LOADING_CLIENT_HAS_ELIGIBILITY_APPLICATION_SUCCESS,
        payload: {
          clientHasEligibilityApplication: hasApplication
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClientHasEligibilityApplication');
    }
  };

  return {
    ...state,
    loadClient,
    clearClientData,
    loadClientDetails,
    upsertClientDetails,
    loadClientAddresses,
    upsertClientAddresses,
    changeClientAddresses,
    deleteClientAddresses,
    loadClientPersonalContacts,
    upsertClientPersonalContacts,
    loadClientIncome,
    upsertClientIncome,
    loadClientHealth,
    upsertClientHealth,
    upsertClientLabResult,
    loadClientInsurance,
    upsertClientInsurance,
    upsertClientPremiumPayment,
    changeClientInsurance,
    deleteClientInsurance,
    loadClientProgramDiagnosisRecords,
    upsertClientProgramDiagnosisRecords,
    loadClientProgramConsents,
    insertClientProgramConsents,
    loadClientProgramDocuments,
    insertClientProgramDocuments,
    loadClientConsents,
    insertClientConsents,
    loadClientInfoUpdateRestrictions,
    loadClientCareTeam,
    upsertClientCareTeam,
    loadClientLabResultDocuments,
    loadClientPremiumPaymentDocuments,
    loadClientHasEligibilityApplication
  };
};
