import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useClient } from 'contexts/ClientContext';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { getProgramsForDisplay } from './common';
import LotusCardList from 'components/widgets/Layout/LotusCardList';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import LotusCard from 'components/widgets/Layout/LotusCard';
import InfoIcon from '@mui/icons-material/Info';
import { Stack } from '@mui/material';
import moment from "moment";

export default function EnrollmentList() {
  const navigate = useNavigate();

  const { client } = useClient();
  const { userAgencyPrograms, loadUserAgencyPrograms } = useUserAgency();
  const {loadEnrollmentsForClient, enrolledPrograms, ryanWhiteProgramEnrollments, adapProgramEnrollments, findEnrollment} = useEnrollments();

  const [programsForDisplay, setProgramsForDisplay] = useState();

  useEffect(() => {
    if (!userAgencyPrograms) {
      loadUserAgencyPrograms();
    }
  }, [userAgencyPrograms]);

  useEffect(() => {
    loadEnrollmentsForClient(client.id);
  }, []);

  useEffect(() => {
    setProgramsForDisplay(getProgramsForDisplay(userAgencyPrograms, enrolledPrograms, true));
  }, [userAgencyPrograms, enrolledPrograms]);

  const editEnrollment = async (program) => {
    await navigate(`/client/${client.id}/enrollment/edit/${program.id}`);
  };

  const viewEligibility = async () => {
    await navigate(`/client/${client.id}/eligibility`);
  };

  return (
    <PageSection header="Program Enrollments">
      <LotusCardList>
        {programsForDisplay && programsForDisplay.map((program) => {
          let enrollment = findEnrollment(program.id);
          if (enrollment && enrollment.programTypeKey === 'custom') {
            return (
              <LotusCard key={program.id} onClick={() => editEnrollment(program)} borderStyleOverrides={{border: '2px solid #2699A8'}}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Status: {enrollment.statusName}{' '}
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Status Date:{' '}{(enrollment.lastStatusDate)}
                    </Typography>
                    {enrollment.assignees?.map(a => {
                      return (
                        <Typography key={a.programEnrollmentAssigneeName} style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          {a.programEnrollmentAssigneeName}: {a.assigneeUserName}{' '}
                        </Typography>
                      );
                    })}
                  </Grid>
                </Grid>
              </LotusCard>
            );
          }
          else if (enrollment && enrollment.programTypeKey === 'ryan_white') {
            return (
              <LotusCard key={program.id} onClick={() => viewEligibility()} borderStyleOverrides={{border: '2px solid #2699A8'}}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Eligibility Start Date: {enrollment.startDate}
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Eligibility End Date: {enrollment.endDate}
                    </Typography>
                  </Grid>
                </Grid>
              </LotusCard>
            );
          } 
          else if (enrollment && enrollment.programTypeKey === 'adap') {
            return (
              <LotusCard key={program.id} onClick={() => editEnrollment(program)} borderStyleOverrides={{border: '2px solid #2699A8'}}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Enrolled
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Subprogram: {enrollment.subprograms.map(s => s.name).join(', ')}
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Enrollment Start Date: {enrollment.startDate}
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Enrollment End Date: {enrollment.endDate}
                    </Typography>
                  </Grid>
                </Grid>
              </LotusCard>
            );
          } 
          else if (!enrollment && program.programTypeKey === 'ryan_white') {
            // If there was a previous enrollment we are going to display the most recent eligibility dates
            let prevEnrollment = null;
            let now = moment();
            ryanWhiteProgramEnrollments?.forEach(e => {
              let endDateMoment = moment(e.endDate,'MM/DD/YYYY').endOf('day');
              if (e.programId === program.id && endDateMoment < now && (!prevEnrollment || endDateMoment > moment(prevEnrollment.endDate,'MM/DD/YYYY'))) {
                prevEnrollment = e;
              }
            });

            return (
              <LotusCard key={program.id} onClick={() => viewEligibility()}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Typography style={{paddingBottom: 10}} color="#DC4D01" variant="body2"><InfoIcon/></Typography>
                        <Typography style={{paddingBottom: 10}} color="#DC4D01" variant="body2"><span>INELIGIBLE</span></Typography>
                      </Stack>
                      {prevEnrollment && 
                        <>
                        <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          Eligibility Start Date: {prevEnrollment.startDate}
                        </Typography>
                        <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          Eligibility End Date: {prevEnrollment.endDate}
                        </Typography>
                        </>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </LotusCard>
            );
          } 
          else if (!enrollment && program.programTypeKey === 'adap') {
            // If there is a future enrollment we are going to display those enrollment dates
            let futureEnrollment = null;
            let now = moment();
            adapProgramEnrollments?.forEach(e => {
              let startDateMoment = moment(e.endDate,'MM/DD/YYYY');
              if (e.programId === program.id && startDateMoment > now && (!futureEnrollment || startDateMoment < moment(futureEnrollment.endDate,'MM/DD/YYYY').endOf('day'))) {
                futureEnrollment = e;
              }
            });
            // If no future enrollment but there was a previous enrollment we are going to display the most recent enrollment dates
            let prevEnrollment = null;
            if (!futureEnrollment) {
              adapProgramEnrollments?.forEach(e => {
                let endDateMoment = moment(e.endDate,'MM/DD/YYYY').endOf('day');
                if (e.programId === program.id && endDateMoment < now && (!prevEnrollment || endDateMoment > moment(prevEnrollment.endDate,'MM/DD/YYYY').endOf('day'))) {
                  prevEnrollment = e;
                }
              });
            }

            return (
              <LotusCard key={program.id} onClick={futureEnrollment ? null : (prevEnrollment ? () => {editEnrollment(program)} : null)}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                        <Typography style={{paddingBottom: 10}} color={prevEnrollment ? "#FF0000" : "#DC4D01"} variant="body2"><InfoIcon/></Typography>
                        <Typography style={{paddingBottom: 10}} color={prevEnrollment ? "#FF0000" : "#DC4D01"} variant="body2"><span>{prevEnrollment ? 'Terminated' : 'Not Enrolled'}</span></Typography>
                      </Stack>
                      {(prevEnrollment || futureEnrollment) && 
                        <>
                        <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          Subprogram: {futureEnrollment?.subprograms.map(s => s.name).join(', ') || prevEnrollment?.subprograms.map(s => s.name).join(', ')}
                        </Typography>
                        <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          Enrollment Start Date: {futureEnrollment?.startDate || prevEnrollment?.startDate}
                        </Typography>
                        <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                          Enrollment End Date: {futureEnrollment?.endDate || prevEnrollment?.endDate}
                        </Typography>
                        </>
                      }
                    </Stack>
                  </Grid>
                </Grid>
              </LotusCard>
            );
          } 
          else {
            return (
              <LotusCard key={program.id} onClick={program.active ? () => {editEnrollment(program)} : null}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="h2" color="primary.dark">
                      {program.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Status: {program.active ? 'Not Enrolled' : 'Inactive'}{' '}
                    </Typography>
                    <Typography style={{paddingBottom: 10}} color="primary.dark" variant="body2">
                      Status Changed: N/A
                    </Typography>
                  </Grid>
                </Grid>
              </LotusCard>
            );
          }})
        }
        </LotusCardList>
    </PageSection>
  );
}
