import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import { Autocomplete, Stack } from '@mui/material';
import { isEqual } from 'lodash';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Error } from '@mui/icons-material';

export default function LotusAutocomplete({
  name,
  label,
  helperText,
  options, // can be list of strings, or list of {id, name} objects
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly
}) {

  const [field, meta, helpers] = useField({name, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
  }});

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && 
        <Stack direction="row" alignItems="center" spacing={1}>
          <Autocomplete
            {...field}
            size="small"
            autoHighlight
            options={options}
            disabled={disabled}
            getOptionLabel={(option) => option.name || option}
            onInputChange={(event, newSelectionText, reason) => {
              if (newSelectionText === '' && reason === 'clear') {
                // if sets null value for a reset ends up with infinite loop
                helpers.setValue(null);
              }
              else if (options && options.length > 0) {
                const newVal = options.find(o => o.name === newSelectionText || o === newSelectionText);
                if (newVal !== undefined && (!field.value || !isEqual(field.value, newVal))) {
                  // Weird inf loop happens without the check when adding one of these in an array panel
                  helpers.setValue(newVal);
                }
              }
            }}
            isOptionEqualToValue={(option, value) => option === value || option?.id === value?.id}
            renderInput={(params) => (
              <TextField 
                label={label + (required ? ' * ' : '')}
                variant="outlined"
                margin="dense"
                size="small"
                style={{width: '100%', margin: 0}}
                disabled={disabled}
                {...params} 
              />
            )}
            required={required}
            style={{width: '100%'}}
          />
          {meta.touched && meta.error &&
            <Error color='error'/>
          }
        </Stack>
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={typeof field.value === 'object' ? field.value?.name : field.value} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
