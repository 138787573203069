import React from 'react';
import Button from '@mui/material/Button';

// Use this as a last resort if the pre-built button types don't apply for some reason
// But this is intended to be a common building block for the other buttons
export default function LotusButton({
  variant,
  onClick,
  startIcon,
  endIcon,
  isDanger,
  isSuccess,
  disabled,
  children
}) {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={(isSuccess ? "success" : (isDanger ? "error" : "primary"))}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}