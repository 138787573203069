import React, { useEffect, useState } from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { Card, Typography } from '@mui/material';
import LotusStickyNav from 'components/widgets/Forms/LotusStickyNav';

export default function LotusExpandableItemList({
  name,
  label,
  itemName,
  disabled,
  buildItemTitle,
  buildItemSummary,
  buildItemDetail,
  buildItemSectionName,
  buildItemTemplate,
  allowRemove=true,
  showNav=true,
  dontCheckRequiredFields
}) {
  const [field] = useField({name});
  const [expandedItems, setExpandedItems] = useState([]);
  const [sections, setSections] = useState();
  const [lastUpdate, setLastUpdate] = useState();

  const {values} = useFormikContext();

  // This is sooooo bad
  useEffect(() => {
    if (values.asOfDate !== lastUpdate) {
      setLastUpdate(values.asOfDate);
    }
    if (disabled) {
      // want to collapse everything in readonly mode after edit/cancel happens
      setExpandedItems([]);
    }
    if (!disabled) {
      // want to expand everything in edit mode upon edit start
      setExpandedItems(field.value.map((i,idx) => idx));
    }
  }, [values.asOfDate]);

  useEffect(() => {
    const sectionList = [];
    if (!disabled) {
      sectionList.push({title: `Add ${itemName}`, url: `#Add${name}Item`});
    }
    if (field.value && buildItemSectionName) {
      sectionList.push(...field.value.map((itm, idx) => {
        return {title: buildItemSectionName(itm, disabled), url: `#${`${name}${idx}`}`}
      }));
    }
    setSections(sectionList);
  }, [field.value, disabled]);

  return (
    <FieldArray 
      name={name}
      render={arrayHelpers => {
        
        const addItem = () => {
          const newItem = buildItemTemplate();
          arrayHelpers.unshift(newItem);
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.map(i => i + 1);
          newExpandedItems.unshift(0);
          setExpandedItems(newExpandedItems);
        };

        const removeItem = (index) => {
          arrayHelpers.remove(index);
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.filter(i => i !== index).map(i => i > index ? i - 1 : i);
          setExpandedItems(newExpandedItems);
        };

        const expandItem = (index) => {
          const newExpandedItems = [...expandedItems];
          newExpandedItems.push(index);
          setExpandedItems(newExpandedItems);
        }

        const collapseItem = (index) => {
          let newExpandedItems = [...expandedItems];
          newExpandedItems = newExpandedItems.filter(i => i !== index);
          setExpandedItems(newExpandedItems);
        }

        const cardClicked = (index) => {
          if (disabled) {
            if (expandedItems.includes(index)) {
              collapseItem(index); 
            } else {
              expandItem(index);
            }
          }
        }

        return (
          <Grid item xs={12} container>
            {label &&
            <Grid item xs={12} style={{marginBottom: 20}}>
              <div style={{fontSize: 20, fontWeight: 400}}>{label}</div>
            </Grid>
            }
            <Grid item xs={12} >
            {field.value && field.value.length === 0 &&
              <Typography variant="body2" style={{marginBottom: 20}}>None</Typography>
            }
            </Grid>
            <Grid item container>
              {sections && sections.length > 1 && showNav &&
              <Grid item xs={3} container>
                <LotusStickyNav linkData={sections} />
              </Grid>
              }
              <Grid item xs={showNav && sections && sections.length > 1 ? 9 : 12} container>
                <Grid item xs={12} style={{ marginBottom: 10 }} id='AddItem'>
                  <Button variant="outlined" disabled={disabled} onClick={addItem}>
                    <AddIcon/> Add {itemName}
                  </Button>        
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  {field.value && field.value.length > 0 &&
                    field.value.map((item, index) => {
                      return (
                        <Card 
                          elevation={3} 
                          style={{paddingLeft: 10, marginBottom: 20, paddingBottom: 10, paddingRight: 10, cursor: disabled ? 'pointer' : 'auto'}} 
                          key={index} 
                          onClick={() => cardClicked(index)}
                          id={`${name}${index}`}
                          className={disabled ? 'lotusDisabledCard' : ""}
                        >
                          <Grid container>
                            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <div style={{fontWeight: 'bold', fontSize: 20, paddingTop: 10, paddingBottom: 5}}>{buildItemTitle(item)}</div>
                              </Grid>
                              <Grid item>
                                {!expandedItems.includes(index) &&
                                  <Button style={{ backgroundColor: '#fff', color: 'black' }} onClick={() => expandItem(index)}>
                                    <ExpandMoreIcon />
                                  </Button>
                                }
                                {expandedItems.includes(index) &&
                                  <Button style={{ backgroundColor: '#fff', color: 'black' }} onClick={() => collapseItem(index)}>
                                    <ExpandLessIcon />
                                  </Button>
                                }
                              </Grid>
                            </Grid>
                            {!expandedItems.includes(index) &&
                              <Grid item xs={12}>
                                <div>{buildItemSummary(item, index)}</div>
                              </Grid>
                            }
                            {expandedItems.includes(index) &&
                              <>
                              <Grid item xs={12}>
                                <div style={{paddingTop: 10}}>{buildItemDetail(item, index, disabled, dontCheckRequiredFields)}</div>
                              </Grid>
                              {!disabled && allowRemove &&
                                <Grid item xs={12}>
                                  <Button onClick={() => removeItem(index)} style={{ color: 'grey', float: 'right'}}>
                                    <DeleteIcon /> Remove
                                  </Button>
                                </Grid>
                              }
                              </>
                            }
                          </Grid>
                        </Card>
                      );
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
