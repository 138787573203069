import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import ActionDialog from 'components/Dialogs/ActionDialog';

export default function MFAResetPanel({ hasResetMFAPermission, mfaEnabled, userAgency, resetMFAClicked, okClicked, cancelClicked, showResetMFA }) {

  return (
    <div>
      {hasResetMFAPermission && (mfaEnabled || userAgency.isRoot) && (
        <LotusSpacedBlock>
          <LotusPaper>
            <LotusSpacedBlock>
              <Typography variant="body`">
                <b>Authentication:</b>
              </Typography>
            </LotusSpacedBlock>
            <LotusSpacedBlock>
              <Typography variant="body">Reset this user's password and MFA configuration. The current password and MFA configuration will no longer be valid. The user will receive an email to set a new password and will be prompted to configure their MFA application.
              </Typography>
            </LotusSpacedBlock>
            <div>
              <LotusButton onClick={resetMFAClicked} variant="contained">
                Reset
              </LotusButton>
            </div>
            <ActionDialog
              title="Reset Password and MFA Configuration"
              content={
                <div>
                  <Grid container direction="column" justifyContent="left" spacing={2} columns={1}>
                    <Grid item>
                      <div style={{ width: '100%' }}>Resetting the user's password and MFA configuration will invalidate their current password and MFA configuration. The user will receive an email to set a new password and will be prompted to configure their MFA application.</div>
                    </Grid>
                    <Grid container direction="row" justifyContent="right" spacing={2} columns={2}>
                      <Grid item>
                        <LotusButton onClick={cancelClicked} variant="outlined">
                          Cancel
                        </LotusButton>
                      </Grid>
                      <Grid item>
                        <LotusButton onClick={okClicked} variant="contained">
                          OK
                        </LotusButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              open={showResetMFA}
            />
          </LotusPaper>
        </LotusSpacedBlock>
      )}
    </div>
  );
}
