export const messageTypes = {
  LOADING_APPLICATIONS_SUCCESS: 'LOADING_APPLICATIONS_SUCCESS',
  LOADING_PENDING_APPLICATIONS_COUNT_SUCCESS: 'LOADING_PENDING_APPLICATIONS_COUNT_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_APPLICATIONS_SUCCESS:
      const totalPages =
        payload.applications.length > 0 ? parseInt(payload.applications[0].count) : 0;
      return {
        ...state,
        applications: [...payload.applications],
        applicationOffset: payload.offset,
        applicationLimit: payload.limit,
        totalApplicationPages: totalPages
      };
    case messageTypes.LOADING_PENDING_APPLICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        pendingApplicationsCount: payload.pendingApplicationsCount
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
