import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { useProgram } from 'contexts/ProgramContext';
import LotusCardList from 'components/widgets/Layout/LotusCardList';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import LotusCard from 'components/widgets/Layout/LotusCard';
import moment from 'moment';
import { useClientAssessments } from 'contexts/ClientAssessmentsContext';
import { PERMISSIONS } from 'lib/permissionEnums';
import { formatUsDate } from 'lib/formatting';
import LotusListFilterTabs from 'components/widgets/Layout/LotusListFilterTabs';
import { Button } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Divider } from '@mui/material';
import { Table } from "@lotus/components";

export default function AssessmentsList() {
  const navigate = useNavigate();

  const [currentAssessments, setCurrentAssessments] = useState();
  const [lastAssessments, setLastAssessments] = useState();
  const [historicalAssessments, setHistoricalAssessments] = useState();
  const { currentProgram, currentProgramAssessments, loadProgramAssessments } = useProgram();
  const {loadAssessmentsForClientAndProgram, clientProgramAssessments, createClientProgramAssessment} = useClientAssessments();
  const { verifyPermission } = useAuthState();
  const hasEditCarePermission = verifyPermission(PERMISSIONS.EDIT_CARE_MANAGEMENT);
  const { clientId, lvl2: programId, lvl5: view } = useParams();

  const [filterTabs, setFilterTabs] = useState();

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId && !currentProgramAssessments) {
      loadProgramAssessments();
    }
  }, [currentProgram, programId]);

  useEffect(() => {
    if (clientId && programId) {
      if (!view) {
        navigate(`/client/${clientId}/caremanagement/${programId}/assessments/list/current`);
      }

      const tabList = ([
        {label: 'Current', link: `/client/${clientId}/caremanagement/${programId}/assessments/list/current`},
        {label: 'Last', link: `/client/${clientId}/caremanagement/${programId}/assessments/list/last`},
        {label: 'History', link: `/client/${clientId}/caremanagement/${programId}/assessments/list/history`},
      ]);
      setFilterTabs(tabList);
    }
  }, [clientId, programId, view]);

  useEffect(() => {
    if (clientId && programId && (!clientProgramAssessments || programId in clientProgramAssessments === false)) {
      loadAssessmentsForClientAndProgram(clientId, programId);
    }
  }, [clientId, programId]);

  //Take the program assessments and client assessments and build display records
  useEffect(() => {
    if (currentProgramAssessments && clientProgramAssessments && programId && clientProgramAssessments[programId]) {
      // current assessments are ones client never did, has started, or can do again now
      const clientProgramAssessmentsForProgram = clientProgramAssessments[programId];
      const now = moment(Date.now());
      const neverDoneProgramAssessments = currentProgramAssessments.reduce((result, pa) => {
        if (!clientProgramAssessmentsForProgram.some((ca) => ca.programAssessmentId === pa.id)) {
          result.push(buildCurrentDisplayItem(pa, null, null));
        }
        return result;
      }, []);
      const startedNotFinishedClientAssessments = clientProgramAssessmentsForProgram.reduce((result, ca) => {
        if (ca.startedAt && !ca.completedAt) {
          const pa = currentProgramAssessments.find((pa) => pa.id === ca.programAssessmentId);
          const lastCompletedClientAssessment = clientProgramAssessmentsForProgram.find((lca) => lca.programAssessmentId === ca.programAssessmentId && lca.lastCompleted);
          if (pa) {
            result.push(buildCurrentDisplayItem(pa, ca, lastCompletedClientAssessment));
          }
        }
        return result;
      }, []);
      const eligibleProgramReassessments = currentProgramAssessments.reduce((result, pa) => {
        const lastClientAssessment = clientProgramAssessmentsForProgram.find((ca) => ca.programAssessmentId === pa.id && ca.lastCompleted);
        if (lastClientAssessment && 
            !clientProgramAssessmentsForProgram.find((ca) => ca.programAssessmentId === pa.id && ca.startedAt && !ca.completedAt) &&
            (!pa.waitTimeToReassessmentDays || moment(lastClientAssessment.completedAt).add(pa.waitTimeToReassessmentDays, 'days') <= now)) {
          result.push(buildCurrentDisplayItem(pa, null, lastClientAssessment));
        }
        return result;
      }, []);

      setCurrentAssessments([
        ...neverDoneProgramAssessments,
        ...startedNotFinishedClientAssessments,
        ...eligibleProgramReassessments,
      ].sort(function (left, right) {
        if (left.nextDue && right.nextDue) {
          return moment.utc(left.nextDue).diff(moment.utc(right.nextDue));
        } else if (left.nextDue) {
          return -1;
        } else if (right.nextDue) {
          return 1;
        } else if (left.startedAt && right.startedAt) {
          return moment.utc(left.startedAt).diff(moment.utc(right.startedAt));
        } else if (left.startedAt) {
          return -1;
        } else if (right.startedAt) {
          return 1;
        } else {
          return 0;
        }
      }));

      const lastCompletedClientAssessments = clientProgramAssessmentsForProgram.reduce((result, ca) => {
        if (ca.lastCompleted) {
          const pa = currentProgramAssessments.find(
            (pa) => pa.id === ca.programAssessmentId
          );
          if (pa) {
            result.push(buildLastDisplayItem(pa, ca));
          }
        }
        return result;
      }, []);

      setLastAssessments(
        lastCompletedClientAssessments.sort(function (left, right) {
          return -moment.utc(left.completedAt).diff(moment.utc(right.completedAt));
        })
      );

      const historicalClientAssessments = clientProgramAssessmentsForProgram.reduce((result, ca) => {
        if (ca.completedAt) {
          const pa = currentProgramAssessments.find(
            (pa) => pa.id === ca.programAssessmentId
          );
          if (pa) {
            result.push(buildHistoricalDisplayItem(pa, ca));
          }
        }
        return result;
      }, []);

      setHistoricalAssessments(
        historicalClientAssessments.sort(function (left, right) {
          return -moment.utc(left.completedAt).diff(moment.utc(right.completedAt));
        })
      );

      
    }
  }, [currentProgramAssessments, clientProgramAssessments, programId, clientId]);
  
  const buildCurrentDisplayItem = (programAssessment, currentClientAssessment, lastCompletedClientAssessment) => {
    const now = moment.utc();
    let nextDue, timeToCompleteMsg, action;
    if (lastCompletedClientAssessment && programAssessment.assessmentTimeCycleDays) {
      nextDue = moment(lastCompletedClientAssessment.completedAt).add(programAssessment.assessmentTimeCycleDays, 'days');
    }

    if (currentClientAssessment && currentClientAssessment.startedAt && !currentClientAssessment.completedAt && programAssessment.startedAssessmentExpirationHours) {
      const expiresAt = moment(currentClientAssessment.startedAt);
      expiresAt.add(programAssessment.startedAssessmentExpirationHours, 'hours');

      let timeToComplete = expiresAt.diff(now, 'minutes');
      if (timeToComplete > 0) {
        timeToCompleteMsg = `${Math.round(timeToComplete / 60)} hours to complete`;
      } else {
        timeToCompleteMsg = 'EXPIRED';
      }
    } else if (nextDue && programAssessment.expirationReminderDays) {
      const minutesUntilDue = nextDue.diff(now, 'minutes');
      if (minutesUntilDue <= programAssessment.expirationReminderDays * 24 * 60) {
        timeToCompleteMsg = minutesUntilDue > 1440 ? `${Math.round(minutesUntilDue / 1440)} days to complete` : `${Math.round(minutesUntilDue / 60)} hours to complete`;
      } else if (minutesUntilDue < 0) {
        timeToCompleteMsg = 'EXPIRED';
      }
    }

    let startingReassessment = false;
    if (lastCompletedClientAssessment?.completedAt && currentClientAssessment?.startedAt) {
      action = 'Continue Re-Assessment';
    } else if (lastCompletedClientAssessment?.completedAt) {
      startingReassessment = true;
      action = 'Start Re-Assessment';
    } else if (currentClientAssessment?.startedAt) {
      action = 'Continue Assessment';
    } else {
      action = 'Start Assessment';
    }

    return {
      name: programAssessment.name,
      lastCompletedAt: lastCompletedClientAssessment && lastCompletedClientAssessment.completedAt ? formatUsDate(lastCompletedClientAssessment.completedAt) : null,
      lastCompletedAssessmentId: lastCompletedClientAssessment?.id,
      startedAt: currentClientAssessment && currentClientAssessment.startedAt ? formatUsDate(currentClientAssessment.startedAt) : null,
      nextDueStr: nextDue ? formatUsDate(nextDue) : null,
      nextDue: nextDue,
      programId: programAssessment.programId,
      assessmentId: currentClientAssessment && currentClientAssessment.id,
      typeId: programAssessment.assessmentTypeId,
      programAssessmentId: programAssessment.id,
      timeToCompleteMsg: timeToCompleteMsg,
      action: action,
      startingReassessment: startingReassessment,
    };
  };

  const buildLastDisplayItem = (programAssessment, completedClientAssessment) => {
    let nextDue, frequency;
    if (programAssessment.assessmentTimeCycleDays) {
      nextDue = moment(completedClientAssessment.completedAt).add(programAssessment.assessmentTimeCycleDays, 'days');
      if ([30, 31].includes(programAssessment.assessmentTimeCycleDays)) {
        frequency = 'Monthly';
      } else if (programAssessment.assessmentTimeCycleDays === 180) {
        frequency = 'Every 6 Months';
      } else if (programAssessment.assessmentTimeCycleDays === 365) {
        frequency = 'Every Year';
      } else if (programAssessment.assessmentTimeCycleDays === 730) {
        frequency = 'Every 2 Years';
      } else {
        frequency = `Every ${programAssessment.assessmentTimeCycleDays} days`;
      }
    }

    return {
      name: programAssessment.name,
      completedAt: completedClientAssessment.completedAt,
      completedAtStr: formatUsDate(completedClientAssessment.completedAt),
      nextDue: nextDue ? formatUsDate(nextDue) : '--',
      completedByName: completedClientAssessment.completedBy,
      programId: programAssessment.programId,
      assessmentId: completedClientAssessment.id,
      typeId: programAssessment.assessmentTypeId,
      programAssessmentId: programAssessment.id,
      frequency: frequency,
      completedClientFormDataId: completedClientAssessment.completedClientFormDataId,
    };
  };

  const buildHistoricalDisplayItem = (programAssessment, completedClientAssessment) => {
    let nextDue;
    if (completedClientAssessment.lastCompleted && programAssessment.assessmentTimeCycleDays) {
      nextDue = moment(completedClientAssessment.completedAt).add(programAssessment.assessmentTimeCycleDays, 'days');
    }

    return {
      name: programAssessment.name,
      completedAt: completedClientAssessment.completedAt,
      completedAtStr: formatUsDate(completedClientAssessment.completedAt),
      nextDue: nextDue ? formatUsDate(nextDue) : '--',
      completedByName: completedClientAssessment.completedBy,
      programId: programAssessment.programId,
      assessmentId: completedClientAssessment.id,
      typeId: programAssessment.assessmentTypeId,
      programAssessmentId: programAssessment.id,
      completedClientFormDataId: completedClientAssessment.completedClientFormDataId,
    };
  };
  
  const lastColumns = [
    {
      name: 'assessmentId',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Frequency',
      name: 'frequency',
    },
    {
      label: 'Assessment',
      name: 'name',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div
              onClick={() => hasEditCarePermission && viewAssessmentById(id)}
              style={{ cursor: hasEditCarePermission ? 'pointer': 'default' }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      label: 'Last Completed On',
      name: 'completedAtStr',
    },
    {
      label: 'Next Due',
      name: 'nextDue',
    },
    {
      label: 'Completed By',
      name: 'completedByName',
    },
  ];

  const historicalColumns = [
    {
      name: 'assessmentId',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Assessment',
      name: 'name',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <div
              onClick={() => hasEditCarePermission && viewAssessmentById(id)}
              style={{ cursor: hasEditCarePermission ? 'pointer' : 'default' }}
            >
              {value}
            </div>
          );
        },
      },
    },
    {
      label: 'Completed On',
      name: 'completedAtStr',
    },
    {
      label: 'Next Due',
      name: 'nextDue',
    },
    {
      label: 'Completed By',
      name: 'completedByName',
    },
  ];

  const viewAssessment = async (assessment) => {
    if (!assessment.assessmentId) {
      assessment.assessmentId = await createClientProgramAssessment(assessment.programAssessmentId, assessment.lastCompletedAssessmentId, clientId, programId);
    }
    navigate(`/client/${clientId}/caremanagement/${programId}/assessments/edit/${assessment.assessmentId}`);
  };

  const viewAssessmentById = async (id) => {
    navigate(`/client/${clientId}/caremanagement/${programId}/assessments/edit/${id}`);
  };

  return filterTabs && view && (
    <PageSection header="Assessments">
      <LotusListFilterTabs tabs={filterTabs} selectedTabName={view} />
      {view === 'current' &&
        <LotusCardList>
          {currentAssessments && currentAssessments.map((assessment,index) => { return (
            <LotusCard key={index}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant="h5" color="primary.dark">
                    {assessment.name}
                  </Typography>
                  {assessment.lastCompletedAt && (
                    <Typography variant="body2" color="#7a7a7a" style={{paddingTop: 5, paddingBottom: 15}}>
                      Last completed {assessment.lastCompletedAt}
                    </Typography>
                  )}
                  <Button style={{paddingLeft: 0}} variant="text" color="secondary" disabled={!hasEditCarePermission} onClick={() => hasEditCarePermission && viewAssessment(assessment)}>
                    {assessment.action}
                    <ArrowRightIcon />
                  </Button>
                </Grid>
                <Divider orientation="vertical" flexItem style={assessment.lastCompletedAt ? {marginRight: -1, marginTop: -35, marginBottom: -25} : {display: 'none'}}/>
                <Grid item xs={4} style={{justifyContent: 'center', alignItems: 'center'}}>
                  <Typography style={{paddingBottom: 10, textAlign: 'center'}} color="#7a7a7a" variant="body2">
                    {assessment.nextDue ? 'Next due' : ' '}
                  </Typography>
                  <Typography style={{paddingBottom: 10, textAlign: 'center'}} color="primary" variant="h5">
                    {assessment.nextDue ? assessment.nextDueStr : ' '}
                  </Typography>
                  <Typography style={{paddingBottom: 10, textAlign: 'center'}} color="primary" variant="body2">
                    {assessment.timeToCompleteMsg ? assessment.timeToCompleteMsg : ' '}
                  </Typography>
                </Grid>
              </Grid>
            </LotusCard>
          )})}
        </LotusCardList>
      }
      {view === 'last' && lastAssessments &&
        <Table
          columns={lastColumns}
          data={lastAssessments}
          options={{
            selectableRows: 'none',
            selectableRowsHideCheckboxes: false,
            selectableRowsHeader: false,
          }}
        />
      }
      {view === 'history' && historicalAssessments &&
        <Table
          columns={historicalColumns}
          data={historicalAssessments}
          options={{
            selectableRows: 'none',
            selectableRowsHideCheckboxes: false,
            selectableRowsHeader: false,
          }}
        />
      }
    </PageSection>
  );
}
