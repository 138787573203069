import React from 'react';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppStatus } from 'contexts/AppStatusContext';
import ActionDialog from './Dialogs/ActionDialog';

const PREFIX = 'AppStatus';

const classes = {
  backdrop: `${PREFIX}-backdrop`,
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  [`&.${classes.backdrop}`]: {
    zIndex: 2000,
    color: '#fff',
  },
}));

export default function AppStatus() {
  const { busy, error } = useAppStatus();
  return (
    <div>
      {busy && (
        <StyledBackdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
      )}
      {error && (
        <ActionDialog
          title="An error occurred while processing the last request"
          content={
            <div>
              <div style={{ width: '100%' }}>{error.message}</div>
              <div style={{ marginTop: 30 }}>
                Please reload the page to continue
              </div>
            </div>
          }
          open={Boolean(error)}
        />
      )}
    </div>
  );
}
