import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ProgramManagementContextReducer';
import { useAppStatus } from './AppStatusContext';
import { useUserAgency } from './UserAgencyContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  currentProgram: undefined,
  activityLogStatuses: undefined,
  activityLogTypes: undefined,
  activityLogSubjects: undefined,
  activityLogWithOptions: undefined,
  additionalClientData: undefined,
  agencyIds: undefined,
  assessments: undefined,
  carePlanLifeAreaIds: undefined,
  carePlanCategoryIds: undefined,
  carePlanGoalCloseReasons: undefined,
  carePlanGoalTypes: undefined,
  carePlanInterventionTypes: undefined,
  consentTypeIds: undefined,
  contactTypes: undefined,
  contactMethods: undefined,
  contactMadeAttemptedOptions: undefined,
  contactCategories: undefined,
  contactSiteVisitTypes: undefined,
  contactDelayReasons: undefined,
  contactMissedReasons: undefined,
  contactServiceSites: undefined,
  contactReportOptions: undefined,
  documentTypeIds: undefined,
  enrollmentStatuses: undefined,
  enrollmentCloseReasons: undefined,
  enrollmentAssignees: undefined,
  interpretationServices: undefined,
  meetingTypes: undefined,
  meetingInviteeTypes: undefined,
};

const ProgramManagementContext = createContext();

export const ProgramManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProgramManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </ProgramManagementContext.Provider>
  );
};

export const useProgramManagement = () => {
  const { state, dispatch } = useContext(ProgramManagementContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  const { userAgency } = useUserAgency();

  const setProgram = async (programId) => {
    try{
      addBusyBee('setProgram');
      const url = `/api/program`;
      let result = await axios.post(url, {operationName: 'getProgramDetails', programId});
      let program = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_SUCCESS,
        payload: {
          currentProgram: program,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('setProgram');
    }
  };

  const loadProgramActivityLogPicklists = async () => {
    try {
      addBusyBee('loadProgramActivityLogPicklists');
      const toPost = { operationName: 'getProgramActivityLogPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let { 
        activityLogTypes,
        activityLogStatuses,
        activityLogSubjects,
        activityLogWithOptions
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ACTIVITY_LOG_PICKLISTS_SUCCESS,
        payload: {
          activityLogTypes,
          activityLogStatuses,
          activityLogSubjects,
          activityLogWithOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramActivityLogPicklists');
    }
  };

  const loadProgramAgencies = async () => {
    try {
      addBusyBee('loadProgramAgencies');
      const toPost = { operationName: 'getProgramAgencies', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);
      let agencyIds = parseApiResult(data).body;    
      
      dispatch({
        type: messageTypes.LOADING_PROGRAM_AGENCIES_SUCCESS,
        payload: {
          agencyIds,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramAgencies');
    }
  };

  const loadProgramAssessments = async () => {
    try {
      addBusyBee('loadProgramAssessments');
      const toPost = {
        operationName: 'getProgramAssessments',
        programId: state.currentProgram.id
      };
      const { data } = await axios.post(`/api/program`, toPost);

      let assessments = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ASSESSMENTS_SUCCESS,
        payload: {
          assessments,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramAssessments');
    }
  };

  const loadProgramCarePlanPicklists = async () => {
    try {
      addBusyBee('loadProgramLifeAreas');
      const toPost = { operationName: 'getProgramCarePlanPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);
      let {
        carePlanLifeAreaIds,
        carePlanCategoryIds,
        carePlanGoalCloseReasons,
        carePlanGoalTypes,
        carePlanInterventionTypes
      } = parseApiResult(data).body; 

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CARE_PLAN_PICKLISTS_SUCCESS,
        payload: {
          carePlanLifeAreaIds,
          carePlanCategoryIds,
          carePlanGoalCloseReasons,
          carePlanGoalTypes,
          carePlanInterventionTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramLifeAreas');
    }
  };

  const loadProgramConsentTypes = async () => {
    try {
      addBusyBee('loadProgramConsentTypes');
      const url = `/api/program`;
      const result = await axios.post(url, {
        operationName: 'getProgramConsentTypes',
        programId: state.currentProgram.id
      });

      const consentTypeIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CONSENT_TYPES_SUCCESS,
        payload: {
          consentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramConsentTypes');
    }
  }

  const loadProgramContactPicklists = async () => {
    try {
      addBusyBee('loadProgramContactPicklists');
      const toPost = { operationName: 'getProgramContactPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {
        contactTypes,
        contactMethods,
        contactMadeAttemptedOptions,
        contactCategories,
        contactSiteVisitTypes,
        contactWithOptions,
        contactDelayReasons,
        contactMissedReasons,
        contactServiceSites,
        contactReportOptions
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_CONTACT_PICKLISTS_SUCCESS,
        payload: {
          contactTypes,
          contactMethods,
          contactMadeAttemptedOptions,
          contactCategories,
          contactSiteVisitTypes,
          contactWithOptions,
          contactDelayReasons,
          contactMissedReasons,
          contactServiceSites,
          contactReportOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramContactPicklists');
    }
  }

  const loadProgramDocumentTypes = async () => {
    try {
      addBusyBee('loadProgramDocumentTypes');
      const toPost = { operationName: 'getProgramDocumentTypes', programId: state.currentProgram.id };
      const url = `/api/program`;
      const result = await axios.post(url, toPost);

      const documentTypeIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_DOCUMENT_TYPES_SUCCESS,
        payload: {
          documentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramDocumentTypes');
    }
  };

  const loadProgramEnrollmentPicklists = async () => {
    try {
      addBusyBee('loadProgramEnrollmentPicklists');
      const toPost = { operationName: 'getProgramEnrollmentPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {enrollmentStatuses, enrollmentCloseReasons, enrollmentAssignees } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_ENROLLMENT_PICKLISTS_SUCCESS,
        payload: {
          enrollmentStatuses,
          enrollmentCloseReasons,
          enrollmentAssignees
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramEnrollmentPicklists');
    }
  };

  const loadProgramInterpretationServices = async () => {
    try {
      addBusyBee('loadProgramInterpretationServices');
      const toPost = { operationName: 'getProgramInterpretationServices', programId: state.currentProgram.id};
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let interpretationServices = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS,
        payload: {
          interpretationServices,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramInterpretationServices');
    }
  };

  const loadProgramMeetingPicklists = async () => {
    try {
      addBusyBee('loadProgramMeetingTypes');
      const toPost = { operationName: 'getProgramMeetingPicklists', programId: state.currentProgram.id };
      const url = `/api/program`;
      const { data } = await axios.post(url, toPost);

      let {
        meetingTypes,
        meetingInviteeTypes
      } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRAM_MEETING_PICKLISTS_SUCCESS,
        payload: {
          meetingTypes,
          meetingInviteeTypes
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgramMeetingTypes');
    }
  };

  const createNewProgram = async (program) => {
    try {
      addBusyBee('createNewProgram');
      const toPost = {
        operationName: 'createProgram',
        name: program.name,
        programTypeId: program.programTypeId,
        agencyId: userAgency.granteeAgencyId,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let data = parseApiResult(result.data).body;
      let programId = data.programId;

      return programId;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createNewProgram');
    }
  };

  const updateProgramProperties = async (program) => {
    try {
      addBusyBee('updateProgramProperties');

      const toPost = {
        operationName: 'updateProgramProperties',
        name: program.name,
        id: state.currentProgram.id,
        programTypeId: program.programTypeId,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let updProgram = parseApiResult(result.data).body;
      console.log(updProgram);
      dispatch({
        type: messageTypes.UPDATING_PROGRAM_PROPERTIES_SUCCESS,
        payload: {
          currentProgram: updProgram,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramProperties');
    }
  };

  const updateProgramActivityLogConfig = async (values) => {
    try {
      addBusyBee('updateProgramActivityLogConfig');

      const toPost = {
        operationName: 'updateProgramActivityLogConfig',
        programId: state.currentProgram.id,
        activityLogConfig: {
          enabled: values.enabled,
          displayName: values.displayName,
          displayNamePlural: values.displayNamePlural,
          minutesRoundingInterval: values.minutesRoundingInterval,
          statusEnabled: values.statusEnabled,
          minutesEnabled: values.minutesEnabled,
          subjectEnabled: values.subjectEnabled,
          conductedByEnabled: values.conductedByEnabled,
          isInterpretationServiceUsedEnabled: values.isInterpretationServiceUsedEnabled,
          interpretationServiceEnabled: values.interpretationServiceEnabled,
          coordinatorEnabled: values.coordinatorEnabled,
        },
        selectedActivityLogTypes: values.currentActivityLogTypes?.filter(t => t.active).map(({active, ...rest}) => ({...rest})),
        selectedActivityLogStatusNames: values.currentActivityLogStatusNames?.filter(s => s.active === true).map(s => { return s.name}),
        selectedActivityLogSubjects: values.currentActivityLogSubjects?.filter(cs => values.selectedActivityLogSubjects.find(ss => ss === `${cs.name}-${cs.typeId}`)),
        selectedActivityLogWithOptionNames: values.currentActivityLogWithOptionNames?.filter(s => s.active === true).map(s => { return s.name})
      };
      const url = `/api/program`;
      const result = await axios.post(url, toPost);
      let updInfo = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_ACTIVITY_LOG_CONFIG_SUCCESS,
        payload: {
          currentProgram: updInfo.program,
          activityLogTypes: updInfo.activityLogTypes,
          activityLogStatuses: updInfo.activityLogStatuses,
          activityLogSubjects: updInfo.activityLogSubjects,
          activityLogWithOptions: updInfo.activityLogWithOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramActivityLogConfig');
    }
  };

  const updateProgramAdditionalClientDataConfig = async (values) => {
    try {
      addBusyBee('updateProgramAdditionalClientDataConfig');

      const toPost = {
        operationName: 'updateProgramAdditionalClientDataConfig',
        additionalClientData: values || null,
        programId: state.currentProgram.id,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let program = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_ADDITIONAL_CLIENT_DATA_CONFIG_SUCCESS,
        payload: {
          currentProgram: program
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramAdditionalClientDataConfig');
    }
  };

  const updateProgramAgencies = async (selectedAgencyIds) => {
    try {
      addBusyBee('updateProgramAgencies');

      const toPost = {
        operationName: 'updateProgramAgencies',
        selectedAgencyIds: selectedAgencyIds,
        programId: state.currentProgram.id,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let agencyIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_AGENCIES_SUCCESS,
        payload: {
          agencyIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramAgencies');
    }
  };

  const updateProgramAssessments = async (selectedAssessmentIds) => {
    try {
      addBusyBee('updateProgramAssessments');

      const toPost = {
        operationName: 'updateProgramAssessments',
        selectedAssessmentIds: selectedAssessmentIds,
        programId: state.currentProgram.id,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let assessments = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_ASSESSMENTS_SUCCESS,
        payload: {
          assessments
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramAssessments');
    }
  };
  
  const updateProgramCareManagementOptions = async (values) => {
    try {
      addBusyBee('updateProgramCareManagementOptions');

      const toPost = {
        operationName: 'updateProgramCareManagementOptions',
        programId: state.currentProgram.id,
        careManagementOptions: {
          assessments: values.assessments,
          carePlan: values.carePlan,
          referrals: values.referrals,
          services: values.services,
        }
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let program = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_CARE_MANAGEMENT_OPTIONS_SUCCESS,
        payload: {
          currentProgram: program
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramCareManagementOptions');
    }
  };

  // TODO: Convert care plan config to a single column json with the options like the others
  const updateProgramCarePlanConfig = async (values) => {
    try {
      addBusyBee('updateProgramCarePlanConfig');

      const toPost = {
        operationName: 'updateProgramCarePlanConfig',
        programId: state.currentProgram.id,
        carePlanExpirationDays: values.carePlanExpirationDays || null,

        barrierDisplayName: values.barrierDisplayName,
        barrierLifeAreaEnabled: values.barrierLifeAreaEnabled,
        selectedCareLifeAreaIds: values.selectedCareLifeAreas,
        barrierCareCategoryEnabled: values.barrierCareCategoryEnabled,
        selectedCareCategoryIds: values.selectedCareCategories,

        selectedGoalCloseReasons: values.currentGoalCloseReasons?.filter(t => t.active).map(s => { return s.name}),
        goalTypeEnabled: values.goalTypeEnabled,
        selectedGoalTypes: values.currentGoalTypes?.filter(t => t.active).map(s => { return s.name}),
        goalPriorityEnabled: values.goalPriorityEnabled,
        goalMemberAgreesEnabled: values.goalMemberAgreesEnabled,
        goalAssignedToEnabled: values.goalAssignedToEnabled,
        interventionTypeEnabled: values.interventionTypeEnabled,
        interventionType: values.interventionType,
        selectedInterventionTypes: values.currentInterventionTypes?.filter(t => t.active).map(s => { return s.name}),
      };
      
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let updInfo = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_CARE_PLAN_CONFIG_SUCCESS,
        payload: {
          currentProgram: updInfo.program,
          carePlanLifeAreaIds: updInfo.carePlanLifeAreaIds,
          carePlanCategoryIds: updInfo.carePlanCategoryIds,
          carePlanGoalCloseReasons: updInfo.carePlanGoalCloseReasons,
          carePlanGoalTypes: updInfo.carePlanGoalTypes,
          carePlanInterventionTypes: updInfo.carePlanInterventionTypes
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramCarePlanConfig');
    }
  };

  const updateProgramConsentTypes = async (selectedConsentTypeIds) => {
    try {
      addBusyBee('updateProgramConsentTypes');
      
      const programVariables = {
        operationName: 'updateProgramConsentTypes',
        selectedConsentTypeIds: selectedConsentTypeIds,
        programId: state.currentProgram.id,
      };

      const url = `/api/program`;
      let result = await axios.post(url, programVariables);
      let consentTypeIds = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_CONSENT_TYPES_SUCCESS,
        payload: {
          consentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramConsentTypes');
    }
  };

  const updateProgramContactConfig = async (values) => {
    try {
      addBusyBee('updateProgramContactConfig');

      const toPost = {
        operationName: 'updateProgramContactConfig',
        programId: state.currentProgram.id,
        contactConfig: {
          enabled: values.enabled,
          displayName: values.displayName,
          displayNamePlural: values.displayNamePlural,
          minutesRoundingInterval: values.minutesRoundingInterval,
          typeEnabled: values.typeEnabled,
          categoryEnabled: values.categoryEnabled,
          madeAttemptedEnabled: values.madeAttemptedEnabled,
          siteVisitTypeEnabled: values.siteVisitTypeEnabled,
          withEnabled: values.withEnabled,
          missedEnabled: values.missedEnabled,
          missedReasonEnabled: values.missedReasonEnabled,
          delayedEnabled: values.delayedEnabled,
          delayReasonEnabled: values.delayReasonEnabled,
          minutesEnabled: values.minutesEnabled,
          contactTimeMinutesEnabled: values.contactTimeMinutesEnabled,
          travelTimeMinutesEnabled: values.travelTimeMinutesEnabled,
          documentationTimeMinutesEnabled: values.documentationTimeMinutesEnabled,
          conductedByEnabled: values.conductedByEnabled,
          coordinatorEnabled: values.coordinatorEnabled,
          serviceSiteEnabled: values.serviceSiteEnabled,
          interviewerEnabled: values.interviewerEnabled,
          respondentEnabled: values.respondentEnabled,
          isInterpretationServiceUsedEnabled: values.isInterpretationServiceUsedEnabled,
          interpretationServiceEnabled: values.interpretationServiceEnabled,
          reportOptionEnabled: values.reportOptionEnabled,
          briefDescriptionEnabled: values.briefDescriptionEnabled,
          fullDescriptionEnabled: values.fullDescriptionEnabled,
          completeEnabled: values.completeEnabled
        },
        selectedContactTypes: values.currentContactTypes?.filter(t => values.selectedContactTypes.find(ss => ss === `${t.name}-${t.id}`)),
        selectedContactMethods: values.currentContactMethods?.filter(t => t.active).map(t => {return {name: t.name, id: t.id}}),
        selectedContactMadeAttemptedOptionNames: values.currentContactMadeAttemptedOptionNames?.filter(t => t.active).map(t => {return t.name}),
        selectedContactCategoryNames: values.currentContactCategoryNames?.filter(t => t.active).map(t => t.name),
        selectedContactSiteVisitTypes: values.currentContactSiteVisitTypes?.filter(cs => values.selectedContactSiteVisitTypes.find(ss => ss === `${cs.name}-${cs.methodId}`)),
        selectedContactDelayReasonNames: values.currentContactDelayReasonNames?.filter(t => t.active).map(t => {return t.name}),
        selectedContactMissedReasonNames: values.currentContactMissedReasonNames?.filter(t => t.active).map(t => {return t.name}),
        selectedContactServiceSiteNames: values.currentContactServiceSiteNames?.filter(t => t.active).map(t => {return t.name}),
        selectedContactReportOptionNames: values.currentContactReportOptionNames?.filter(t => t.active).map(t => {return t.name})
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let updInfo = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_CONTACT_CONFIG_SUCCESS,
        payload: {
          currentProgram: updInfo.program,
          contactTypes: updInfo.contactTypes,
          contactMethods: updInfo.contactMethods,
          contactMadeAttemptedOptions: updInfo.contactMadeAttemptedOptions,
          contactCategories: updInfo.contactCategories,
          contactSiteVisitTypes: updInfo.contactSiteVisitTypes,
          contactDelayReasons: updInfo.contactDelayReasons,
          contactMissedReasons: updInfo.contactMissedReasons,
          contactServiceSites: updInfo.contactServiceSites,
          contactReportOptions: updInfo.contactReportOptions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramContactConfig');
    }
  };

  const updateProgramDocumentTypes = async (selectedDocumentTypeIds) => {
    try {
      addBusyBee('updateProgramDocumentTypes');
      
      const toPost = {
        operationName: 'updateProgramDocumentTypes',
        selectedDocumentTypeIds: selectedDocumentTypeIds,
        programId: state.currentProgram.id,
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let documentTypeIds = parseApiResult(result.data).body;
      
      dispatch({
        type: messageTypes.UPDATING_PROGRAM_DOCUMENT_TYPES_SUCCESS,
        payload: {
          documentTypeIds
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramDocumentTypes');
    }
  };

  const updateProgramEnrollmentConfig = async (values) => {
    addBusyBee('updateProgramEnrollmentConfig');

    try {
      values.enrollmentStatuses.forEach((es, idx) => {
        es.isActiveStatusType = es.isActiveStatusType || false;
        es.isDefaultInitialStatus = es.isDefaultInitialStatus || false;
        es.allowedTransitionStatusIds = es.allowedTransitionStatusIds || [];
        es.allowDocumentationUpdates = es.allowDocumentationUpdates || false;
        es.requireCloseReason = es.requireCloseReason || false;
        es.closeReasonUsesPicklist = es.closeReasonUsesPicklist || false;
        es.seqNumber = idx;
        if (es.isActiveStatusType) {
          es.requireCloseReason = false;
          es.closeReasonUsesPicklist = false;
        }
      });

      values.enrollmentAssignees.forEach((es, idx) => {
        es.seqNumber = idx;
      });

      const toPost = {
        operationName: 'updateProgramEnrollmentConfig',
        programId: state.currentProgram.id,
        enrollmentConfig: {
          isEvergreen: values.isEvergreen,
          assignmentOnlyWhenActive: values.assignmentOnlyWhenActive,
          allowStatusBackdating: values.allowStatusBackdating
        },
        enrollmentStatuses: values.enrollmentStatuses,
        enrollmentCloseReasons: values.currentEnrollmentCloseReasons?.filter(t => t.active).map(t => {return t.name}),
        enrollmentAssignees: values.enrollmentAssignees
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let updInfo = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_ENROLLMENT_CONFIG_SUCCESS,
        payload: {
          currentProgram: updInfo.program,
          enrollmentStatuses: updInfo.enrollmentStatuses,
          enrollmentCloseReasons: updInfo.enrollmentCloseReasons,
          enrollmentAssignees: updInfo.enrollmentAssignees
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramEnrollmentConfig');
    }
  };

  const updateProgramInterpretationServices = async (currentInterpretationServices) => {
    try {
      addBusyBee('updateProgramInterpretationServices');

      const selectedInterpretationServiceNames = currentInterpretationServices?.filter(t => t.active).map(t => {return t.name});
      const toPost = {
        operationName: 'updateProgramInterpretationServices',
        selectedInterpretationServiceNames,
        programId: state.currentProgram.id
      };
      const url = `/api/program`;
      const {data} = await axios.post(url, toPost);
      const interpretationServices = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_INTERPRETATION_SERVICES_SUCCESS,
        payload: {
          interpretationServices
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramInterpretationServices');
    }
  };

  const updateProgramMeetingConfig = async (values) => {
    try {
      addBusyBee('updateProgramMeetingConfig');

      const toPost = {
        operationName: 'updateProgramMeetingConfig',
        programId: state.currentProgram.id,
        meetingConfig: {
          meetingsEnabled: values.meetingsEnabled,
          meetingDisplayName: values.meetingDisplayName,
          timeSpentSchedulingMeetingEnabled: values.timeSpentSchedulingMeetingEnabled
        },
        selectedMeetingTypeNames: values.currentMeetingTypeNames?.filter(t => t.active).map(s => { return s.name}),
        selectedMeetingInviteeTypeNames: values.currentMeetingInviteeTypeNames?.filter(t => t.active).map(s => { return s.name})
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let updInfo = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_MEETING_CONFIG_SUCCESS,
        payload: {
          currentProgram: updInfo.program,
          meetingTypes: updInfo.meetingTypes,
          meetingInviteeTypes: updInfo.meetingInviteeTypes
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramMeetingConfig');
    }
  };

  const updateProgramProgressLogConfig = async (values, programId) => {
    try {
      addBusyBee('updateProgramProgressLogConfig');

      const toPost = {
        operationName: 'updateProgramProgressLogConfig',
        programId: state.currentProgram.id,
        progressLogConfig: {
          enabled: values.enabled,
          timeSpentEnabled: values.timeSpentEnabled,
          startEndTimeEnabled: values.startEndTimeEnabled,
          timeUnitsMinutes: values.timeUnitsMinutes ? parseInt(values.timeUnitsMinutes, 10) : null,
          linkage: values.linkage
        },
      };
      const url = `/api/program`;
      let result = await axios.post(url, toPost);
      let program = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_PROGRAM_PROGRESS_LOG_CONFIG_SUCCESS,
        payload: {
          currentProgram: program
        },    
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateProgramProgressLogConfig');
    }
  }

  return {
    ...state,
    setProgram,
    loadProgramActivityLogPicklists,
    loadProgramAgencies,
    loadProgramAssessments,
    loadProgramCarePlanPicklists,
    loadProgramConsentTypes,
    loadProgramContactPicklists,
    loadProgramDocumentTypes,
    loadProgramEnrollmentPicklists,
    loadProgramInterpretationServices,
    loadProgramMeetingPicklists,
    createNewProgram,
    updateProgramProperties,
    updateProgramActivityLogConfig,
    updateProgramAdditionalClientDataConfig,
    updateProgramAgencies,
    updateProgramAssessments,
    updateProgramCareManagementOptions,
    updateProgramCarePlanConfig,
    updateProgramConsentTypes,
    updateProgramContactConfig,
    updateProgramDocumentTypes,
    updateProgramEnrollmentConfig,
    updateProgramInterpretationServices,
    updateProgramMeetingConfig,
    updateProgramProgressLogConfig
  };
};
