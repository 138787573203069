export const messageTypes = {
  LOADING_ASSESSMENT_TYPES_SUCCESS: 'LOADING_ASSESSMENT_TYPES_SUCCESS',
  LOADING_FORM_TYPES_SUCCESS: 'LOADING_FORM_TYPES_SUCCESS',
  LOADING_FORM_DEFINITION_SUCCESS: 'LOADING_FORM_DEFINITION_SUCCESS',
  LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS: 'LOAD_AGENCY_ASSESSMENT_TYPES_SUCCESS',
  LOADING_CURRENT_AGENCY_FORM_DEFINITIONS_SUCCESS: 'LOADING_ALL_AGENCY_FORM_DEFINITIONS_SUCCESS',
  LOADING_FORM_DEFINITION_BY_ID_SUCCESS: 'LOADING_FORM_DEFINITION_BY_ID_SUCCESS',
  CREATING_DYNAMIC_FORM_SUCCESS: 'CREATING_DYNAMIC_FORM_SUCCESS',
  CREATING_ASSESSMENT_TYPE_SUCCESS: 'CREATING_ASSESSMENT_TYPE_SUCCESS',
  LOADING_FORM_DEFINITIONS_FOR_FORM_TYPE_SUCCESS: 'LOADING_FORM_DEFINITIONS_FOR_FORM_TYPE_SUCCESS',
  LOADING_AGENCY_PROGRAMS_SUCCESS: 'LOADING_AGENCY_PROGRAMS_SUCCESS'
};
export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ASSESSMENT_TYPES_SUCCESS:
    case messageTypes.LOADING_FORM_TYPES_SUCCESS:
    case messageTypes.LOADING_FORM_DEFINITION_SUCCESS:
    case messageTypes.CREATING_DYNAMIC_FORM_SUCCESS:
    case messageTypes.CREATING_ASSESSMENT_TYPE_SUCCESS:
    case messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case messageTypes.LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS:
      return {
        ...state,
        agencyAssessmentTypes: payload.agencyAssessmentTypes,
      };
    case messageTypes.LOADING_ALL_AGENCY_FORM_DEFINITIONS_SUCCESS:
      return {
        ...state,
        formDefinitions: payload.formDefinitions,
      };
    case messageTypes.LOADING_FORM_DEFINITION_BY_ID_SUCCESS:
      let newCache = {};
      Object.entries(state.formDefinitionCacheById || {}).forEach(([k, v]) => {
        newCache[k] = v;
      });
      newCache[payload.definition.formId] = payload.definition;
      return {
        ...state,
        formDefinitionCacheById: newCache,
      };
    case messageTypes.LOADING_FORM_DEFINITIONS_FOR_FORM_TYPE_SUCCESS:
      let newFormDefinitions = {};
      Object.entries(state.formDefinitions || {}).forEach(([k, v]) => {
        newFormDefinitions[k] = v;
      });
      newFormDefinitions[payload.key] = payload.formDefinitions;
      return {
        ...state,
        formDefinitions: newFormDefinitions,
      };
    default:
      break;
  }
};
