import { useClientProgramAssessmentSubforms } from 'components/Forms/utils/useClientProgramAssessmentSubforms';
import { useAssessmentSubforms } from 'components/Forms/utils/useAssessmentSubforms';
import { useAppStatus } from 'contexts/AppStatusContext';
import { useClientAssessments } from 'contexts/ClientAssessmentsContext';
import { ClientFormContextProvider } from 'contexts/ClientFormContext';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarriersToCareTab } from './BarriersToCareTab';
import TabbedMultiformPanel from 'components/Forms/MultiTab/MultiForm/TabbedMultiformPanel';
import { DynamicFormProvider } from 'contexts/DynamicFormContext';
import { useProgram } from 'contexts/ProgramContext';
import { AssessmentSummaryTab } from './AssessmentSummaryTab';
import { Button } from "@lotus/components";
import { Typography } from '@mui/material';

const AssessmentEditor = () => {
  const [tabDefinitions, setTabDefinitions] = useState();
  const formTypeKey = 'assessment';
  const { clientId, lvl2: programId, lvl5: assessmentId, lvl6: formTab } = useParams();
  const navigate = useNavigate();
  const {setError} = useAppStatus();
  const { currentProgram, currentProgramAssessments, loadProgramAssessments } = useProgram();
  const {loadAssessmentsForClientAndProgram, clientProgramAssessments} = useClientAssessments();
  const [assessmentTypeId, setAssessmentTypeId] = useState();
  const [assessmentTypeName, setAssessmentTypeName] = useState();
  const [barrierDisplayName, setBarrierDisplayName] = useState();
  const [allowEdit, setAllowEdit] = useState();

  const subformDefinitions = useAssessmentSubforms(formTypeKey, assessmentTypeId);
  const clientForms = useClientProgramAssessmentSubforms(clientId, formTypeKey, programId, assessmentTypeId, assessmentId);

  useEffect(() => {
    if (clientId && programId && (!clientProgramAssessments || programId in clientProgramAssessments === false)) {
      loadAssessmentsForClientAndProgram(clientId, programId);
    }
  }, [clientId, programId]);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId) {
      setBarrierDisplayName(currentProgram.barrierDisplayName || 'Barriers');
      if (!currentProgramAssessments) {
        loadProgramAssessments();
      }
    }
  }, [currentProgram, programId]);

  useEffect(() => {
    if (currentProgramAssessments && clientProgramAssessments && programId in clientProgramAssessments) {
      const clientProgAssessments = clientProgramAssessments[programId];
      const assmt = clientProgAssessments.find(a => a.id === assessmentId);
      if (!assmt) {
        setError({message: 'Client assessment not found'});
      }
      setAllowEdit(!assmt.completedAt);

      const programAssessment = currentProgramAssessments.find(pa => pa.id === assmt.programAssessmentId);
      if (!programAssessment) {
        setError({message: 'Program assessment not found'});
      }
      const assmtTypeId = programAssessment.assessmentTypeId;
      const assmtTypeName = programAssessment.name;

      setAssessmentTypeId(assmtTypeId);
      setAssessmentTypeName(assmtTypeName);
    }
  }, [clientProgramAssessments, currentProgramAssessments]);

  useEffect(() => {
    if (!formTab && subformDefinitions) {
      if (subformDefinitions.length > 0) {
        navigate(`/client/${clientId}/caremanagement/${programId}/assessments/edit/${assessmentId}/${subformDefinitions[0].formSubtypeCustomName}`);
      } else {
        setError({message: 'No assessment forms configured for display'});
      }
    }
  }, [formTab, subformDefinitions]);

  useEffect(() => {
    const tabsToDisplay = {};

    if (barrierDisplayName && assessmentTypeId) {
      tabsToDisplay[`${barrierDisplayName}s`] = {
        component: <BarriersToCareTab allowEdit={allowEdit} clientId={clientId} programId={programId} assessmentId={assessmentId} barrierDisplayName={barrierDisplayName}/>,
        afterDynamicTabs: true
      };
    }
    if (assessmentTypeId) {
      tabsToDisplay['Submit'] = {
        component: <AssessmentSummaryTab submitted={!allowEdit} clientId={clientId} programId={programId} assessmentTypeId={assessmentTypeId} assessmentId={assessmentId} />,
        afterDynamicTabs: true
      };
    }

    setTabDefinitions(tabsToDisplay);
  }, [barrierDisplayName, assessmentTypeId, allowEdit]);

  const showAssessmentList = async () => {
    navigate(`/client/${clientId}/caremanagement/${programId}/assessments/list/current`);
  };

  return subformDefinitions && clientForms && tabDefinitions && (
    <>
    <Button onClick={showAssessmentList} variant="text">
      <Typography variant="h6">Back to Assessments List</Typography>
    </Button>
    <TabbedMultiformPanel 
      selectedTab={formTab}
      dynamicSubformDefinitions={subformDefinitions} 
      tabDefinitions={tabDefinitions}
      clientForms={clientForms} 
      headerText={assessmentTypeName}
      disabled={!allowEdit}
      clientId={clientId}
      assessmentId={assessmentId}
      programId={programId}
      linkPathPrefix={`/client/${clientId}/caremanagement/${programId}/assessments/edit/${assessmentId}`} />
    </>
  );
};

export default function ClientProgramAssessmentEditor({ ...props }) {
  return (
    <DynamicFormProvider>
      <ClientFormContextProvider>
        <AssessmentEditor {...props} />
      </ClientFormContextProvider>
    </DynamicFormProvider>
  );
}