import { useState } from "react";
import { useAuthState } from "./contexts/AuthProvider";
import InactivityDialog from "./components/Dialogs/InactivityDialog";
import { IdleTimerProvider } from 'react-idle-timer'


// Total of 29 minutes with 1 min of popup window.
let POPUP_IDLE_TIME = 29 * 60 * 1e3;
let LOGOUT_IDLE_TIME_AFTER_POPUP = 60 * 1e3;

const InactivityMonitor = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [myTimer, setMyTimer] = useState(false);
  const { user, signOut } = useAuthState();

  const closeDialog = () => {
    myTimer.activate();
    setShowDialog(false);
  }

  const onPrompt = (event, idleTimer) => {
    setShowDialog(true);
    setMyTimer(idleTimer);
  }

  const onIdle = () => {
    signOut();
  }

  return user && (

    <IdleTimerProvider
      timeout={POPUP_IDLE_TIME}
      onPrompt={onPrompt}
      onIdle={onIdle}
      promptBeforeIdle={LOGOUT_IDLE_TIME_AFTER_POPUP}
    >
      <InactivityDialog isOpen={showDialog} handleClose={closeDialog} />
    </IdleTimerProvider>
  )
}

export default InactivityMonitor;