import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import ActionDialog from 'components/Dialogs/ActionDialog';

export default function ResetPasswordPanel({ hasRequestAccountPermission, lastLogin, resetPasswordClicked, okClicked, cancelClicked, showResetPassword }) {

  return (
    <div>

      {hasRequestAccountPermission && !lastLogin && (
        <LotusSpacedBlock>
          <LotusPaper>
            <LotusSpacedBlock>
              <Typography variant="body`">
                <b>Authentication:</b>
              </Typography>
            </LotusSpacedBlock>
            <LotusSpacedBlock>
              <Typography variant="body">Reset this user's password. Once reset, the user will receive an email with a link to log in. The user will be required to set a new password during their next login attempt.
              </Typography>
            </LotusSpacedBlock>
            <div>
              <LotusButton onClick={resetPasswordClicked} variant="contained">
                Reset
              </LotusButton>
            </div>
            <ActionDialog
              title="Reset Password"
              content={
                <div>
                  <Grid container direction="column" justifyContent="left" spacing={2} columns={1}>
                    <Grid item>
                      <div style={{ width: '100%' }}>Reset this user's password. Once reset, the user will receive an email with a link to log in. The user will be required to set a new password during their next login attempt.</div>
                    </Grid>
                    <Grid container direction="row" justifyContent="right" spacing={2} columns={2}>
                      <Grid item>
                        <LotusButton onClick={cancelClicked} variant="outlined">
                          Cancel
                        </LotusButton>
                      </Grid>
                      <Grid item>
                        <LotusButton onClick={okClicked} variant="contained">
                          OK
                        </LotusButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              open={showResetPassword}
            />
          </LotusPaper>
        </LotusSpacedBlock>
      )}
    </div>
  );
}
