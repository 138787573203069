import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { useLists } from 'contexts/ListsContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusItemList from '../Forms/LotusItemList';
import IncomeSamplesPanel from './IncomeSamplesPanel';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function IncomeSourcesPanel({ incomeSourcesFieldName, clientIsRecipient, clientRecipientLabel, relationships, disabled, required, dontCheckRequiredFields, useSeedData }) {

  const { incomePeriods } = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [incomeSourceTypeList, setIncomeSourceTypeList] = useState();
  const [incomePeriodList, setIncomePeriodList] = useState();

  const [incomeSourcesField] = useField(incomeSourcesFieldName);

  useEffect(() => {
    if (incomePeriods) {
      setIncomePeriodList(incomePeriods.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [incomePeriods]);

  useEffect(() => {
    if (userAgencyClientInfoCustomLists?.clientInfoIncomeSourceTypes) {
      setIncomeSourceTypeList(userAgencyClientInfoCustomLists.clientInfoIncomeSourceTypes.map(g => { return {label: g.name, value: g.id, isDeleted: g.isDeleted, isActive: g.isActive}}));
    }
  }, [userAgencyClientInfoCustomLists]);

  const emptyIncomeSource = {
    recipientRelationshipId: '',
    incomeSourceTypeId: '',
    incomePeriodId: '',
    samples: [{amount: '', sampleDate: null}]
  };
  
  function buildIncomeSourcePanel(incomeSource, index, disabled) {
    return (
      <div style={{padding: 8}} key={index}>
        <div style={{fontSize: 18, fontWeight: 400, marginBottom: 16}}>{clientIsRecipient ? 'Personal' : 'Household'} Income Source {index + 1}</div>
        {clientIsRecipient &&
          <div style={{marginBottom: 16}}>
            <LotusSelect
              disabled={true}
              name={`${incomeSourcesFieldName}.${index}.recipientRelationshipId`}
              label="Income Recipient"
              required={!clientIsRecipient}
              dontCheckRequiredFields={dontCheckRequiredFields}
              disallowNoSelectionIfNotRequired
              items={[{value: '', label: clientRecipientLabel}]}
            />
          </div>
        }
        {!clientIsRecipient && relationships && (  
          <div style={{marginBottom: 16}}>                     
            <LotusSelect
              disabled={disabled}
              name={`${incomeSourcesFieldName}.${index}.recipientRelationshipId`}
              label="Income Recipient"
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              items={relationships}
            />
          </div>
        )}
        {incomeSourceTypeList && (   
          <div style={{marginBottom: 16}}>                    
            <LotusSelect
              disabled={disabled}
              name={`${incomeSourcesFieldName}.${index}.incomeSourceTypeId`}
              label="Income Source Type"
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              items={incomeSourceTypeList}
              autoSort={true}
              excludeInactiveOrDeleted={true}
            />
          </div>
        )}
        {incomePeriodList && (  
          <div style={{marginBottom: 16}}>                     
            <LotusSelect
              disabled={disabled}
              name={`${incomeSourcesFieldName}.${index}.incomePeriodId`}
              label="Income Period"
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              items={incomePeriodList}
              autoSort={false}
            />
          </div>
        )}
        {incomeSourcesField.value[index] && incomeSourcesField.value[index].incomePeriodId &&
          <div style={{marginLeft: 16}}>
            <IncomeSamplesPanel 
              incomeSamplesFieldName={`${incomeSourcesFieldName}.${index}.samples`}
              disabled={disabled}
              required={required}
              dontCheckRequiredFields={dontCheckRequiredFields}
              itemName="Payment"
            />
          </div>
        }
      </div>
    );
  }

  return (
    <LotusItemList
      disabled={disabled} 
      name={incomeSourcesFieldName}
      itemName="Income Source"
      buildItemDetail={(item, index, isDisabled) => buildIncomeSourcePanel(item, index, isDisabled)}
      buildItemTemplate={() => {return {...emptyIncomeSource}}}
    />
  );
}