import React from 'react';
import { Stack } from '@mui/material';

export default function LotusSideBySide({ leftControl, rightControl, alignItems }) {

  // Assuming that our standard control width is 500
  return (
    <Stack direction="row" alignItems={alignItems || "flex-start"} style={{width: '100%'}} justifyContent="flex-start" className="sideBySide">
      {leftControl}
      <div style={{width: 10}}></div>
      {rightControl}
    </Stack>
  );
}
