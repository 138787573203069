import LotusCheckboxList from "components/form/checkbox/LotusCheckboxList";
import { useField } from "formik";
import { useEffect, useState } from "react";


export default function ClientInfoListSubsetLinker({ name, title, masterList }) {
  const [ allMasterItems, setAllMasterItems] = useState();

  const [ configField ] = useField(name);

  useEffect(() => {
    if (masterList) {
      let lst = [...masterList];
      lst = lst.sort((a, b) => {
        return a.itemName > b.itemName ? 1 : -1;
      });
      setAllMasterItems(lst.map(li => { return {label: li.itemName, value: li.id} }));
    }
  }, [masterList]);

  return (
    <LotusCheckboxList
      name={configField.name}
      label={title}
      items={allMasterItems} />
  );
}
