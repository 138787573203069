import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import MetricView from "./MetricView";
import { useFormikContext } from "formik";

export function MetricsView({ metrics }) {

  const { values } = useFormikContext();

  return metrics && (
    metrics.sort((a, b) => {return a.name < b.name ? -1 : 1;}).map((metric, idx) => (
      <MetricView
        key={metric.key}
        metric={metric}
        agencyMetric={values.agencyMetrics.find((am) => am.metricId === metric.id)}
        agencyMetricIndex={values.agencyMetrics.findIndex((am) => am.metricId === metric.id)}
        isFirst={idx === 0}
      />
    ))
  );
}