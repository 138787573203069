import { useField } from 'formik';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import LotusReadOnlyFieldView from '../../LotusReadOnlyFieldView';
import { InputAdornment, Stack, Typography } from '@mui/material';
import { Error } from '@mui/icons-material';
import ReactInputMask from 'react-input-mask';
import LotusTextInput from './LotusTextInput';


// Note: Doing this one similar to how we do the date input, because we historically have 
// saved phones in numeric format (e.g., 1112223333) rather than as a string with the formatting chars in it (e.g., (111) 222-3333) like we do with ssns
export default function LotusPhoneInput({
  name,
  label,
  helperText,
  required,
  dontCheckRequiredFields,
  disabled,
  readOnly
}) {

  const mask = '(999) 999-9999';
  const [dateWithMask, setDateWithMask] = useState();

  const [field, meta, utils] = useField({name, validate: (val) => {
    const lbl = label || 'Field';
    let isPopulated = (val && val !== mask.replaceAll('9', '_'));
    if (required && !dontCheckRequiredFields && !isPopulated) {
      return `${lbl} is required`;
    }
    if (isPopulated) {
      const regex = RegExp(`^[0-9]{10}$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be 10 digits`;
      }
    }
  }});

  useEffect(() => {
    if (field.value !== undefined) {
      const newDateWithMask = field.value ? formatPhoneNumber(field.value) : '';
      if (dateWithMask !== newDateWithMask) {
        setDateWithMask(newDateWithMask);
      }
    }
  }, [field.value]); // field.value is the numeric value (e.g., 1112223333 instead of (111) 222-3333)

  useEffect(() => {
    if (dateWithMask !== undefined) {
      const newDateWithoutMask = dateWithMask ? dateWithMask.replace(/[\(\)\-_ ]/g, "") : '';
      if (field.value !== newDateWithoutMask) {
        utils.setTouched(true);
        utils.setValue(newDateWithoutMask, true);
      }
    }
  }, [dateWithMask]); // dateWithMask is the masked value (e.g.,  (111) 222-3333 instead of 1112223333)

  // val is is the numeric value (e.g., 1112223333) and thid returns the masked value (e.g., (111) 222-3333))
  const formatPhoneNumber = (val) => {
    if (!val) {
      return '';
    }
    if (val.startsWith('+')) {
      val = val.slice(1);
    }
    
    const areaCode = val.slice(0,3);
    const prefix = val.slice(3,6);
    const last4 = val.slice(6);

    return `(${areaCode}) ${val.length > 3 ? prefix : ''}${val.length > 6 ? `-${last4}` : ''}`;
  }

  const InputProps = {};
  if (meta.touched && meta.error) {
    InputProps.endAdornment = <InputAdornment disableTypography position="end"><Error color='error'/></InputAdornment>;
  }

  return dateWithMask !== undefined && (
    <Stack style={{width: '100%'}}>
      {!readOnly && !disabled &&
        <ReactInputMask
          {...field}
          disabled={disabled} 
          mask={mask}
        >
          {(inputProps) => (
            <TextField
              fullWidth
              variant= "outlined"
              margin="dense"
              size="small"
              style={{width: '100%', marginTop: 0, marginBottom: 0}}
              {...inputProps}
              label={label}
              error={meta.touched && Boolean(meta.error)}
              required={required} 
              InputProps={InputProps}
              maxLength={10}
            />
          )}
        </ReactInputMask>
      }
      {disabled &&
        <TextField
          fullWidth
          variant= "outlined"
          margin="dense"
          size="small"
          style={{width: '100%'}}
          label={label}
          required={required} 
          disabled
          value={dateWithMask}
        />
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={formatPhoneNumber(field.value)} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  )
}
