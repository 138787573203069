import React, { createContext, useContext, useReducer } from 'react';

const AppStatusContext = createContext();

const initialState = {
  busy: false,
  error: undefined,
  busyBees: new Set(),
};

const ADDING_BUSYBEE = 'ADDING_BUSYBEE';
const REMOVING_BUSYBEE = 'REMOVING_BUSYBEE';
const SETTING_ERROR = 'SETTING_ERROR';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADDING_BUSYBEE:
      return {
        ...state,
        busyBees: new Set(state.busyBees).add(payload),
        busy: true,
      };
      break;
    case REMOVING_BUSYBEE:
      const newBusyBees = new Set(state.busyBees);
      newBusyBees.delete(payload);
      return {
        ...state,
        busyBees: newBusyBees,
        busy: newBusyBees.size > 0,
      };
      break;
    case SETTING_ERROR:
      return {
        ...state,
        error: payload,
      };
      break;
    default:
      break;
  }
};

export const AppStatusProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppStatusContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStatusContext.Provider>
  );
};

export const useAppStatus = () => {
  const { state, dispatch } = useContext(AppStatusContext);

  const addBusyBee = async (busyBee) => {
    dispatch({
      type: ADDING_BUSYBEE,
      payload: busyBee,
    });
  };

  const removeBusyBee = async (busyBee) => {
    dispatch({
      type: REMOVING_BUSYBEE,
      payload: busyBee,
    });
  };

  const setError = async (error) => {
    if (error?.response?.status === 401) {
      sessionStorage.clear();
      window.location.href = window.location.origin;
      return;
    }

    dispatch({
      type: SETTING_ERROR,
      payload: error,
    });
  };

  return {
    ...state,
    addBusyBee,
    removeBusyBee,
    setError,
  };
};
