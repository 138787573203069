import { Form, Formik, yupToFormErrors } from 'formik';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { Button } from "@lotus/components";
import { Typography } from '@mui/material';
import * as Yup from 'yup';

export default function InlineEditableStaticForm({
  headerName,
  disabled,
  formValues,
  onSubmitForm,
  onCancel,
  validationSchema,
  extraValidation,
  buildFormContent
}) {
  
  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validate={(values) => {
        let result = {};
        try {
          const schema = validationSchema || Yup.object().shape({});
          schema.validateSync(values, { abortEarly: false });
        } catch (err) {
          result = yupToFormErrors(err);
        }
        if (extraValidation) {
          result = extraValidation(values, result);
        }
        return result;
      }}
      onSubmit={async (newValues, actions) => {
        await onSubmitForm(newValues);
        actions.setSubmitting(false);
      }}
    >
      {({values, handleSubmit, handleChange, setFieldValue, validateForm}) => {
        const handleCancel = () => {
          onCancel();
        }
        return values && (
          <Form className="form" onSubmit={handleSubmit} autoComplete="off" subscription={{ submitting: true, pristine: true }}>
            <LotusPageSection 
              header={headerName}
              actions={
                <>
                <Button variant="text" disabled={disabled} onClick={() => {handleCancel();}}>
                  <Typography variant="h6">Cancel</Typography>
                </Button>
                <Button disabled={disabled} onClick={() => {handleSubmit(values);}}>
                  <Typography variant="h6">Save</Typography>
                </Button>
                </>
              }
            >
              {buildFormContent(disabled, values, {handleChange, setFieldValue, validateForm})}
            </LotusPageSection>
          </Form>
        );
      }}
    </Formik>
  );
}