
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import LotusSelect from '../Forms/LotusSelect';
import { useHealthPlans } from 'contexts/HealthPlansContext';
import LotusTrueFalseSelect from '../Forms/LotusTrueFalseSelect';
import LotusTextInput from '../Forms/LotusTextInput';
import moment from 'moment';
import LotusFormItem from '../Forms/LotusFormItem';
import {uniq} from 'lodash';
import LotusDatePicker from '../Forms/LotusDatePicker';
import LotusTrueFalseRadioGroup from '../Forms/LotusTrueFalseRadioGroup';

export default function HealthPlanPanel ({ 
  effectiveDateFieldName, policySourceIdFieldName, policySources, planType, useHealthPlanDb, 
  carrierFieldName, planIdFieldName, medicalCoverageFieldName, mentalHealthCoverageFieldName, pharmacyCoverageFieldName, planNameFieldName, 
  substanceAbuseCoverageFieldName, medicareCompanyPlanFieldStatus = true, medicaidPanel = false, medicaidPlanFieldName,
  disabled, style, effectiveDateRequired, carrierAndPlanRequired, dontCheckRequiredFields, existingClientConfig, ...props }) 
{
  const { values, setFieldValue, setFieldTouched, validateForm } = useFormikContext();
  const [selectedCarrierName, setSelectedCarrierName] = useState();
  const [effectiveDate, setEffectiveDate] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState();
  const [selectedPlanTypeUsesHealthPlanDb, setSelectedPlanTypeUsesHealthPlanDb] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [agencyPlanTypeCarriers, setAgencyPlanTypeCarriers] = useState();
  const [agencyPlanTypeCarrierPlans, setAgencyPlanTypeCarrierPlans] = useState();
  const { healthPlanCarriersByPlanType, healthPlansByPlanTypeAndCarrier, loadCarriersForPlanType, loadPlansForPlanTypeAndCarrier } = useHealthPlans();
  const [companyPlanStatus, setCompanyPlanStatus] = useState(true);

  const getFieldValue = (name, values) => {
    if (name && name.includes('.') && Array.isArray(values[name.split('.')[0]])) {
      let parts = name.split('.');
      const arrayItem = values[parts[0]][parts[1]];
      return arrayItem ? arrayItem[parts[2]] : null;
    } else {
      return values[name];
    }
  };

  useEffect(() => {
    if (planType) {
      setSelectedPlanType(planType);
    } else if (policySourceIdFieldName && policySources) {
      let insKey = policySources.find(s => s.value === getFieldValue(policySourceIdFieldName, values))?.itemKey;
      setSelectedPlanType(getPrivateInsuranceHealthPlanType(insKey));
    }
  }, [planType, policySourceIdFieldName, policySources]);

  useEffect(() => {
    if (useHealthPlanDb !== undefined) {
      setSelectedPlanTypeUsesHealthPlanDb(useHealthPlanDb);
    } else if (policySourceIdFieldName && policySources && existingClientConfig) {
      let insKey = policySources.find(s => s.value === getFieldValue(policySourceIdFieldName, values))?.itemKey;
      setSelectedPlanTypeUsesHealthPlanDb(shouldUseHealthPlanDb(insKey, existingClientConfig));
    }
  }, [useHealthPlanDb, policySourceIdFieldName, policySources, existingClientConfig]);

  useEffect(() => {
    setEffectiveDate(getFieldValue(effectiveDateFieldName, values));
  }, [getFieldValue(effectiveDateFieldName, values)]);

  useEffect(() => {
    setSelectedCarrierName(getFieldValue(carrierFieldName, values));
  }, [getFieldValue(carrierFieldName, values)]);

  // Load carriers for plan type when plan type changes, if not already loaded
  useEffect(() => {
    if (selectedPlanTypeUsesHealthPlanDb && selectedPlanType) {
      if (!healthPlanCarriersByPlanType || !(selectedPlanType in healthPlanCarriersByPlanType)) {
        loadCarriersForPlanType(selectedPlanType);
      }
      setAgencyPlanTypeCarrierPlans([]);
    }
  }, [selectedPlanTypeUsesHealthPlanDb, selectedPlanType]);

  // When carrier retrival finishes or plan type/effective date changes, put relevant picklist in state
  useEffect(() => {
    if (healthPlanCarriersByPlanType && effectiveDate && selectedPlanType && selectedPlanType in healthPlanCarriersByPlanType) {
      let effectiveDateMoment = moment(effectiveDate, 'MM/DD/YYYY');
      let targetCarriers = uniq(healthPlanCarriersByPlanType[selectedPlanType]
        .filter(c => (c.isActive && !c.isDeleted && effectiveDateMoment >= moment(c.effectiveDate,'MM/DD/YYYY')) || c.carrierName === selectedCarrierName)
        .map(c => c.carrierName))
        .map(c => {return {label: c, value: c}});
      setAgencyPlanTypeCarriers(targetCarriers);

      // Do initial load of plans for this carrier
      if (selectedCarrierName && (!healthPlansByPlanTypeAndCarrier || !(selectedPlanType in healthPlansByPlanTypeAndCarrier) || !(selectedCarrierName in healthPlansByPlanTypeAndCarrier[selectedPlanType]))) {
        loadPlansForPlanTypeAndCarrier(selectedPlanType, selectedCarrierName);
      }
    } else {
      setAgencyPlanTypeCarriers([]);
    }
  }, [healthPlanCarriersByPlanType, effectiveDate, selectedPlanType, selectedCarrierName]);

  // When plan retrieval finishes, put picklist in state
  useEffect(() => {
    if (healthPlansByPlanTypeAndCarrier && effectiveDate && selectedPlanType && selectedCarrierName && selectedPlanType in healthPlansByPlanTypeAndCarrier && selectedCarrierName in healthPlansByPlanTypeAndCarrier[selectedPlanType]) {
      let id = getFieldValue(planIdFieldName, values);
      let effectiveDateMoment = moment(effectiveDate, 'MM/DD/YYYY');
      let targetPlans = healthPlansByPlanTypeAndCarrier[selectedPlanType][selectedCarrierName]
          .filter(p => (p.isActive && !p.isDeleted && effectiveDateMoment >= moment(p.effectiveDate,'MM/DD/YYYY')) || p.id === id)
          .map(p => {return {label: p.planName, value: p.id}});
      setAgencyPlanTypeCarrierPlans(targetPlans);
    } else {
      setAgencyPlanTypeCarrierPlans([]);
    }
  }, [healthPlansByPlanTypeAndCarrier, effectiveDate, selectedPlanType, selectedCarrierName]);

  // When plan changes, set the selected plan in state so we can load the Y/N vals for that plan
  const planChanged = (id) => {
    if (id) {
      if (healthPlansByPlanTypeAndCarrier && selectedPlanType in healthPlansByPlanTypeAndCarrier && selectedCarrierName in healthPlansByPlanTypeAndCarrier[selectedPlanType]) {
        setSelectedPlan(healthPlansByPlanTypeAndCarrier[selectedPlanType][selectedCarrierName].find(p => p.id === id));
      }
    }
  }

  useEffect(() => {
    if (selectedPlan) {
      setFieldValue(planNameFieldName, selectedPlan.planName);
      if (medicalCoverageFieldName && mentalHealthCoverageFieldName && pharmacyCoverageFieldName && substanceAbuseCoverageFieldName) {
        setFieldValue(medicalCoverageFieldName, selectedPlan.hasMedicalCoverage !== null ? selectedPlan.hasMedicalCoverage : '');
        setFieldValue(mentalHealthCoverageFieldName, selectedPlan.hasMentalHealthCoverage !== null ? selectedPlan.hasMentalHealthCoverage : '');
        setFieldValue(pharmacyCoverageFieldName, selectedPlan.hasPharmacyCoverage !== null ? selectedPlan.hasPharmacyCoverage : '');
        setFieldValue(substanceAbuseCoverageFieldName, selectedPlan.hasSubstanceAbuseCoverage !== null ? selectedPlan.hasSubstanceAbuseCoverage : '');
      }
    }
  }, [selectedPlan]);
  
  useEffect(() => {
      setCompanyPlanStatus(medicaidPanel ? getFieldValue(medicaidPlanFieldName, values) : true);
  }, [getFieldValue(medicaidPlanFieldName, values)]);

  const getPrivateInsuranceHealthPlanType = (insSourceKey) => {
    if (insSourceKey === 'aca_exchange') {
      return 'ACA';
    } else if (insSourceKey === 'employer') {
      return 'EMP';
    } else if (insSourceKey === 'indiv_market') {
      return 'IND';
    }
    return null;
  }
  
  const shouldUseHealthPlanDb = (insSourceKey) => {
    if (insSourceKey === 'aca_exchange') {
      return existingClientConfig.acaUsesHealthPlanDatabase;
    } else if (insSourceKey === 'employer') {
      return existingClientConfig.employerSponsoredUsesHealthPlanDatabase;
    } else if (insSourceKey === 'indiv_market') {
      return existingClientConfig.individualMarketplaceUsesHealthPlanDatabase;
    }
    return undefined;
  }

  return (
      <>
        <LotusFormItem>
          <LotusDatePicker
            name={effectiveDateFieldName}
            label="Date Effective"
            disabled={disabled}
            required={effectiveDateRequired}
            dontCheckRequiredFields={dontCheckRequiredFields}
            handleDateChange={(dt) => {
              setEffectiveDate(dt); 
              setSelectedCarrierName(''); 
              setSelectedPlan(null);
              setFieldTouched(carrierFieldName, true);
              setFieldTouched(planIdFieldName, true);
              setFieldTouched(planNameFieldName, true);
              setFieldValue(carrierFieldName, ''); 
              setFieldValue(planIdFieldName,''); 
              setFieldValue(planNameFieldName,'');
              setTimeout(() => validateForm());
            }}
          />
        </LotusFormItem>
        {medicaidPanel && medicaidPlanFieldName &&
          <LotusFormItem>
            <LotusTrueFalseRadioGroup
              name={medicaidPlanFieldName}
              label="Is plan an HMO/MCO?"
              disabled={disabled}
            />
        </LotusFormItem>
        }
        {policySourceIdFieldName && policySources &&
          <LotusFormItem>
            <LotusSelect
              name={policySourceIdFieldName}
              label="Policy Source"
              items={policySources}
              disabled={disabled}
              required={carrierAndPlanRequired}
              dontCheckRequiredFields={dontCheckRequiredFields}
              handleChange={(id) => {
                let insKey = policySources.find(s => s.value === id)?.itemKey;
                setSelectedPlanType(getPrivateInsuranceHealthPlanType(insKey));
                setSelectedPlanTypeUsesHealthPlanDb(shouldUseHealthPlanDb(insKey));
                setSelectedCarrierName(''); 
                setSelectedPlan(null);
                setFieldTouched(carrierFieldName, true);
                setFieldTouched(planIdFieldName, true);
                setFieldTouched(planNameFieldName, true);
                setFieldValue(carrierFieldName, ''); 
                setFieldValue(planIdFieldName,''); 
                setFieldValue(planNameFieldName,'');
                setTimeout(() => validateForm());
              }}
            />
          </LotusFormItem>
        }
        {(selectedPlanTypeUsesHealthPlanDb === true || selectedPlanTypeUsesHealthPlanDb === undefined) && companyPlanStatus && medicareCompanyPlanFieldStatus &&
          <>
            <LotusFormItem>
              {agencyPlanTypeCarriers &&
                <LotusSelect
                  name={carrierFieldName}
                  label={(medicaidPlanFieldName ? 'HMO/MCO ' : '' ) + 'Insurance Company Name'}
                  items={agencyPlanTypeCarriers}
                  disabled={disabled}
                  required={carrierAndPlanRequired}
                  dontCheckRequiredFields={dontCheckRequiredFields}
                  handleChange={(c) => {
                    setSelectedCarrierName(c);
                    setSelectedPlan(null);
                    setFieldTouched(planIdFieldName, true);
                    setFieldTouched(planNameFieldName, true);
                    setFieldValue(planIdFieldName,''); 
                    setFieldValue(planNameFieldName,'');
                    setTimeout(() => validateForm());
                  }}
                />
              }
            </LotusFormItem>
            <LotusFormItem>
              {agencyPlanTypeCarrierPlans &&
                <LotusSelect
                  name={planIdFieldName}
                  label={(medicaidPlanFieldName ? 'HMO/MCO ' : '' ) + 'Plan Name'}
                  items={agencyPlanTypeCarrierPlans}
                  disabled={disabled}
                  required={carrierAndPlanRequired}
                  dontCheckRequiredFields={dontCheckRequiredFields}
                  handleChange={planChanged}
                  autoWidth // this is to make the drop down box not extend to the longest option when it is selected
                  style={{maxWidth: 800}}  // this is to make the drop down box not extend to the longest option when it is selected
                />
              }
            </LotusFormItem>
          </>
        }
        {selectedPlanTypeUsesHealthPlanDb === false && companyPlanStatus && medicareCompanyPlanFieldStatus &&
          <>
            <LotusFormItem>
              <LotusTextInput
                name={carrierFieldName}
                label={(medicaidPlanFieldName ? 'HMO/MCO ' : '' ) + "Insurance Company Name"}
                disabled={disabled}
                maxLength={200}
                required={carrierAndPlanRequired}
                dontCheckRequiredFields={dontCheckRequiredFields}
              />
            </LotusFormItem>
            <LotusFormItem>
              <LotusTextInput
                name={planNameFieldName}
                label={(medicaidPlanFieldName ? 'HMO/MCO ' : '' ) + "Policy/Plan Name"}
                disabled={disabled}
                maxLength={200}
                required={carrierAndPlanRequired}
                dontCheckRequiredFields={dontCheckRequiredFields}
              />
            </LotusFormItem>
          </>
        }
        {medicalCoverageFieldName &&
          <LotusFormItem>
            <LotusTrueFalseSelect
              name={medicalCoverageFieldName}
              label="Medical Coverage?"
              disabled={disabled}
            />
          </LotusFormItem>
        }
        {mentalHealthCoverageFieldName &&
          <LotusFormItem>
            <LotusTrueFalseSelect
              name={mentalHealthCoverageFieldName}
              label="Mental Health Coverage?"
              disabled={disabled}
            />
          </LotusFormItem>
        }
        {substanceAbuseCoverageFieldName &&
          <LotusFormItem>
            <LotusTrueFalseSelect
              name={substanceAbuseCoverageFieldName}
              label="Substance Abuse Coverage?"
              disabled={disabled}
            />
          </LotusFormItem>
        }
        {pharmacyCoverageFieldName &&
          <LotusFormItem>
            <LotusTrueFalseSelect
              name={pharmacyCoverageFieldName}
              label="Pharmacy Coverage Included?"
              disabled={disabled}
            />
          </LotusFormItem>
        }
      </>
  );
}
