import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './UserManagementContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';

const UserManagementContext = createContext();

const initialState = {
  userDetail: undefined,
};

export const UserManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </UserManagementContext.Provider>
  );
};

export const useUserManagement = () => {
  const { state, dispatch } = useContext(UserManagementContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadUserDetail = async (userId) => {
    try {
      addBusyBee('loadUserDetail');
      const result = await axios.post(`/api/user`, {
        operationName: 'getUser',
        userId: userId
      });
      const {user, userPermissions} = parseApiResult(result.data).body;
      dispatch({
        type: messageTypes.LOADING_USER_DETAIL_SUCCESS,
        payload: {
          userDetail: user,
          userPermissions: userPermissions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserDetail');
    }
  };

  const updateUserData = async (userId, data) => {
    try {
      addBusyBee('updateUserData');

      const result = await axios.post(`/api/user`, {
        operationName: 'updateUserData',
        userId,
        data: data,
      });
      
      const resultObj = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPDATING_USER_DATA_SUCCESS,
        payload: {
          userDetail: resultObj.user
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('updateUserData');
    }
  };

  const userResetMFA = async (userId, email) => {
    return await resetUser(userId, email, 'resetMFA');
  }

  const adminResetMFA = async (userId, email) => {
    return await resetUser(userId, email, 'adminResetMFA');
  }
  
  const adminResetPassword = async (userId, email) => {
    return await resetUser(userId, email, 'adminResetPassword');
  }
  
  const disableUser = async (userId, email) => {
    await resetUser(userId, email, 'disableUser');
    return await loadUserDetail(userId);
  }

  const enableUser = async (userId, email) => {
    return await resetUser(userId, email, 'enableUser');
  }
  
  const deleteUser = async (userId, email) => {
    return await resetUser(userId, email, 'deleteUser');
  }

  const resetUser = async (userId, email, operation) => {
    try {
      addBusyBee('resetUser');

      const result = await axios.post(`/api/user`, {
        operationName: operation,
        email: email,
        userId: userId,
      });
      const resultObj = parseApiResult(result.data).body;

      return !resultObj.body?.includes('Error');

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('resetUser');
    }
  };

  const loadUserTrainingDocs = async (userId) => {
    try {
      addBusyBee('loadUserTrainingDocs');
      const result = await axios.post(`/api/user`, {
        operationName: 'getUserTrainingDocs',
        userId: userId
      });
      const resultObj = parseApiResult(result.data).body;
      dispatch({
        type: messageTypes.LOADING_USER_TRAINING_DOCS_SUCCESS,
        payload: {
          userTrainingDocs: resultObj
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadUserTrainingDocs');
    }
  };

  if (state) {
    return {
      ...state,
      loadUserDetail,
      updateUserData,
      userResetMFA,
      adminResetMFA,
      adminResetPassword,
      disableUser,
      enableUser,
      deleteUser,
      loadUserTrainingDocs
    };
  }

  return {};
};
