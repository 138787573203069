import React, { useEffect, useState } from 'react';
import { useClientAssessments } from 'contexts/ClientAssessmentsContext';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { Button } from "@lotus/components";
import { useAssessmentSubforms } from 'components/Forms/utils/useAssessmentSubforms';
import { useClientProgramAssessmentSubforms } from 'components/Forms/utils/useClientProgramAssessmentSubforms';

export const AssessmentSummaryTab = ({ submitted, clientId, programId, assessmentTypeId, assessmentId }) => {

  const formTypeKey = 'assessment';
  const subformDefinitions = useAssessmentSubforms(formTypeKey, assessmentTypeId);
  const clientForms = useClientProgramAssessmentSubforms(clientId, formTypeKey, programId, assessmentTypeId, assessmentId);
  const { completeClientProgramAssessment } = useClientAssessments();

  const [missingFormsStr, setMissingFormsStr] = useState();
  const [hasMissingForms, setHasMissingForms] = useState();

  useEffect(() => {
    if (subformDefinitions && clientForms) {
      const missing = [];
      subformDefinitions.forEach(sd => {
        const clientForm = clientForms[sd.formSubtypeCustomName];
        if (!clientForm) {
          missing.push(sd.formSubtypeCustomName);
        }
      });
      setMissingFormsStr(missing.length > 0 ? missing.join(',') : '');
      setHasMissingForms(missing.length > 0);
    }
  }, [subformDefinitions, clientForms]);

  const doCompleteAssessment = async () => {
    await completeClientProgramAssessment(assessmentId, clientId, programId);
  }

  return missingFormsStr !== undefined && (
    <LotusPageSection header="Submit">
      {!submitted && hasMissingForms &&
        <LotusSpacedBlock>
          Tab(s) {missingFormsStr} still need to be filled out.
        </LotusSpacedBlock>
      }
      {!submitted && !hasMissingForms &&
        <>
        <LotusSpacedBlock>
          All tabs for the assessment have been filled out.
        </LotusSpacedBlock>
        <Button onClick={doCompleteAssessment}>Submit Assessment</Button>
        </>
      }
      {submitted &&
        <LotusSpacedBlock>
          This assessment has been submitted.
        </LotusSpacedBlock>
      }
    </LotusPageSection>
  );
};
