export const messageTypes = {
  INITIALIZE_CLIENT_DATA: 'INITIALIZE_CLIENT_DATA',
  LOADING_CLIENT_SUCCESS: 'LOADING_CLIENT_SUCCESS',
  LOADING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS: 'LOADING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS',
  UPSERTING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS: 'UPSERTING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS',
  LOADING_CLIENT_PROGRAM_CONSENTS_SUCCESS: 'LOADING_CLIENT_PROGRAM_CONSENTS_SUCCESS',
  INSERTING_CLIENT_PROGRAM_CONSENTS_SUCCESS: 'INSERTING_CLIENT_PROGRAM_CONSENTS_SUCCESS',
  LOADING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS: 'LOADING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS',
  INSERTING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS: 'INSERTING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS',
  LOADING_CLIENT_DETAILS_SUCCESS: 'LOADING_CLIENT_DETAILS_SUCCESS',
  UPSERTING_CLIENT_DETAILS_SUCCESS: 'UPSERTING_CLIENT_DETAILS_SUCCESS',
  LOADING_CLIENT_ADDRESSES_SUCCESS: 'LOADING_CLIENT_ADDRESSES_SUCCESS',
  UPSERTING_CLIENT_ADDRESSES_SUCCESS: 'UPSERTING_CLIENT_ADDRESSES_SUCCESS',
  CHANGING_CLIENT_ADDRESSES_SUCCESS: 'CHANGING_CLIENT_ADDRESSES_SUCCESS',
  DELETING_CLIENT_ADDRESSES_SUCCESS: 'DELETING_CLIENT_ADDRESSES_SUCCESS',
  LOADING_CLIENT_PERSONAL_CONTACTS_SUCCESS: 'LOADING_CLIENT_PERSONAL_CONTACTS_SUCCESS',
  UPSERTING_CLIENT_PERSONAL_CONTACTS_SUCCESS: 'UPSERTING_CLIENT_PERSONAL_CONTACTS_SUCCESS',
  LOADING_CLIENT_INCOME_SUCCESS: 'LOADING_CLIENT_INCOME_SUCCESS',
  UPSERTING_CLIENT_INCOME_SUCCESS: 'UPSERTING_CLIENT_INCOME_SUCCESS',
  LOADING_CLIENT_HEALTH_SUCCESS: 'LOADING_CLIENT_HEALTH_SUCCESS',
  UPSERTING_CLIENT_HEALTH_SUCCESS: 'UPSERTING_CLIENT_HEALTH_SUCCESS',
  UPSERTING_CLIENT_LAB_RESULT_SUCCESS: 'UPSERTING_CLIENT_LAB_RESULT_SUCCESS',
  LOADING_CLIENT_INSURANCE_SUCCESS: 'LOADING_CLIENT_INSURANCE_SUCCESS',
  UPSERTING_CLIENT_INSURANCE_SUCCESS: 'UPSERTING_CLIENT_INSURANCE_SUCCESS',
  UPSERTING_CLIENT_PREMIUM_PAYMENT_SUCCESS: 'UPSERTING_CLIENT_PREMIUM_PAYMENT_SUCCESS',
  CHANGING_CLIENT_INSURANCE_SUCCESS: 'CHANGING_CLIENT_INSURANCE_SUCCESS',
  DELETING_CLIENT_INSURANCE_SUCCESS: 'DELETING_CLIENT_INSURANCE_SUCCESS',
  LOADING_CLIENT_CONSENTS_SUCCESS: 'LOADING_CLIENT_CONSENTS_SUCCESS',
  INSERTING_CLIENT_CONSENTS_SUCCESS: 'INSERTING_CLIENT_CONSENTS_SUCCESS',
  LOADING_CLIENT_INFO_UPDATE_RESTRICTIONS_SUCCESS: 'LOADING_CLIENT_INFO_UPDATE_RESTRICTIONS_SUCCESS',
  LOADING_CLIENT_CARE_TEAM_SUCCESS: 'LOADING_CLIENT_CARE_TEAM_SUCCESS',
  UPSERTING_CLIENT_CARE_TEAM_SUCCESS: 'UPSERTING_CLIENT_CARE_TEAM_SUCCESS',
  LOADING_CLIENT_LAB_RESULT_DOCUMENTS_SUCCESS: 'LOADING_CLIENT_LAB_RESULT_DOCUMENTS_SUCCESS',
  LOADING_CLIENT_PREMIUM_PAYMENT_DOCUMENTS_SUCCESS: 'LOADING_CLIENT_PREMIUM_PAYMENT_DOCUMENTS_SUCCESS',
  LOADING_CLIENT_HAS_ELIGIBILITY_APPLICATION_SUCCESS: 'LOADING_CLIENT_HAS_ELIGIBILITY_APPLICATION_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.INITIALIZE_CLIENT_DATA:
      return {
        ...state,
        client: undefined,
        clientProgramDiagnosisRecords: undefined,
        clientProgramConsents: undefined,
        clientProgramDocuments: undefined,
        clientDetails: undefined,
        clientAddresses: undefined,
        clientPersonalContacts: undefined,
        clientIncome: undefined,
        clientBenefits: undefined,
        clientHealth: undefined,
        clientInsurance: undefined
      };
    case messageTypes.LOADING_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload.client,
        clientProgramDiagnosisRecords: undefined,
        clientProgramConsents: undefined,
        clientProgramDocuments: undefined,
        clientDetails: undefined,
        clientAddresses: undefined,
        clientPersonalContacts: undefined,
        clientIncome: undefined,
        clientBenefits: undefined,
        clientHealth: undefined,
        clientInsurance: undefined
      };
    case messageTypes.LOADING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS:
    case messageTypes.LOADING_CLIENT_PROGRAM_CONSENTS_SUCCESS:
    case messageTypes.LOADING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS:
    case messageTypes.LOADING_CLIENT_DETAILS_SUCCESS:
    case messageTypes.LOADING_CLIENT_ADDRESSES_SUCCESS:
    case messageTypes.LOADING_CLIENT_PERSONAL_CONTACTS_SUCCESS:
    case messageTypes.LOADING_CLIENT_INCOME_SUCCESS:
    case messageTypes.LOADING_CLIENT_HEALTH_SUCCESS:
    case messageTypes.LOADING_CLIENT_INSURANCE_SUCCESS:
    case messageTypes.LOADING_CLIENT_CONSENTS_SUCCESS:
    case messageTypes.INSERTING_CLIENT_CONSENTS_SUCCESS:
    case messageTypes.LOADING_CLIENT_INFO_UPDATE_RESTRICTIONS_SUCCESS:
    case messageTypes.LOADING_CLIENT_CARE_TEAM_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_CARE_TEAM_SUCCESS:
    case messageTypes.LOADING_CLIENT_HAS_ELIGIBILITY_APPLICATION_SUCCESS:
    case messageTypes.DELETING_CLIENT_INSURANCE_SUCCESS:
    case messageTypes.DELETING_CLIENT_ADDRESSES_SUCCESS:
    case messageTypes.LOADING_CLIENT_LAB_RESULT_DOCUMENTS_SUCCESS:
    case messageTypes.LOADING_CLIENT_PREMIUM_PAYMENT_DOCUMENTS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case messageTypes.UPSERTING_CLIENT_PERSONAL_CONTACTS_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_INCOME_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_HEALTH_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_LAB_RESULT_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_INSURANCE_SUCCESS:
    case messageTypes.UPSERTING_CLIENT_PREMIUM_PAYMENT_SUCCESS:
    case messageTypes.CHANGING_CLIENT_INSURANCE_SUCCESS:
    case messageTypes.CHANGING_CLIENT_ADDRESSES_SUCCESS:
      return {
        ...state,
        ...payload,
        updateRestrictions: payload.updateRestrictions || state.updateRestrictions
      }
    case messageTypes.UPSERTING_CLIENT_DETAILS_SUCCESS:
      const c = {
        ...state.client,
        name: payload.clientDetails.name,
        primaryLanguageId: payload.clientDetails.primaryLanguageId,
      }
      return {
        ...state,
        client: c,
        clientDetails: payload.clientDetails,
        updateRestrictions: payload.updateRestrictions || state.updateRestrictions
      }
    case messageTypes.UPSERTING_CLIENT_ADDRESSES_SUCCESS:
      return {
        ...state,
        clientAddresses: payload.clientAddresses,
        clientPersonalContacts: payload.clientPersonalContacts || state.clientPersonalContacts,
        updateRestrictions: payload.updateRestrictions || state.updateRestrictions
      };
    case messageTypes.UPSERTING_CLIENT_PROGRAM_DIAGNOSIS_RECORDS_SUCCESS:
      const newRecs = state.clientProgramDiagnosisRecords.filter(
        (r) =>
          r.clientId !== payload.clientId || r.programId !== payload.programId
      );
      newRecs.push({ ...payload });
      return {
        ...state,
        clientProgramDiagnosisRecords: newRecs,
      };
    case messageTypes.INSERTING_CLIENT_PROGRAM_CONSENTS_SUCCESS:
      let curClientProgramConsentRecs = state.clientProgramConsents.find((r) =>
          r.clientId === payload.clientId && r.programId === payload.programId
      );
      if (curClientProgramConsentRecs && curClientProgramConsentRecs.data) {
        curClientProgramConsentRecs.data.push(...payload.data);
      } else {
        curClientProgramConsentRecs = payload;
      }
      const newConsentRecs = state.clientProgramConsents.filter((r) =>
        r.clientId !== payload.clientId || r.programId !== payload.programId
      );
      newConsentRecs.push(curClientProgramConsentRecs);
      return {
        ...state,
        clientProgramConsents: newConsentRecs,
      };
    case messageTypes.INSERTING_CLIENT_PROGRAM_DOCUMENTS_SUCCESS:
      let curClientProgramDocRecs = state.clientProgramDocuments.find((r) =>
          r.clientId === payload.clientId && r.programId === payload.programId
      );
      if (curClientProgramDocRecs && curClientProgramDocRecs.data) {
        curClientProgramDocRecs.data.push(...payload.data);
      } else {
        curClientProgramDocRecs = payload;
      }
      const newDocumentRecs = state.clientProgramDocuments.filter((r) =>
        r.clientId !== payload.clientId || r.programId !== payload.programId
      );
      newDocumentRecs.push(curClientProgramDocRecs);
      return {
        ...state,
        clientProgramDocuments: newDocumentRecs,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
