import React, { useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';

export default function LotusAccordion({ isExpanded=false, collapsedContent, expandedContent, onExpandedChanged, showBottomBorder=false }) {
  const [accordionExpanded, setAccordionExpanded] = useState(isExpanded);
  
  useEffect(() => {
    if (isExpanded !== undefined) {
      setAccordionExpanded(isExpanded);
    }
  }, [isExpanded]);

  return (
    <Accordion
      onChange={(e, expanded) => {
        if (expanded !== accordionExpanded) {
          setAccordionExpanded(expanded);
          onExpandedChanged(expanded);
        }
      }}
      expanded={accordionExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{fontSize: 35}} />}
        style={{border: '1px solid #7A7A7A66', borderRadius: 5, borderBottom: showBottomBorder ? '1px solid #7A7A7A66': 'none' }}
      >
        {collapsedContent}
      </AccordionSummary>
      <AccordionDetails style={{border: '1px solid #7A7A7A66', borderRadius: 5, borderTop: 'none'}}>
        <div>
          {expandedContent}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
