import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axiosInterceptor';
import { Formik, Form } from 'formik';
import { debounce } from 'lodash';
import { parseApiResult } from 'lib/utils';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { usePermissionTemplateRequests } from 'contexts/PermissionTemplateRequestsContext';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';
import LotusSectionHeader from 'components/headers/LotusSectionHeader';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusCancelButton from 'components/button/common/LotusCancelButton';
import LotusTextInput from 'components/form/inputField/common/LotusTextInput';
import LotusMultiSelect from 'components/form/select/LotusMultiSelect';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import StartingTemplatePanel from './StartingTemplatePanel';
import CategoriesPanel from './CategoriesPanel';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';
import { Check, Close } from '@mui/icons-material';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';
import LotusSubsectionContent from 'components/dataDisplay/content/LotusSubsectionContent';
import LotusStackedFormItems from 'components/form/LotusStackedFormItems';
import TemplateActionRequestDenyDialog from '../dialogs/TemplateActionRequestDenyDialog';

const duplicateNameChecker = debounce(async (value, templateName) => {
  if (value && (!templateName || value !== templateName)) {
    try {
      const { data } = await axios.post('/api/permissionTemplates', {
        operationName: 'checkTemplateName',
        templateName: value,
      });
      return parseApiResult(data).body.error === undefined;
    } catch (error) {
      console.log({ error });
      return false;
    }
  } else {
    return true;
  }
}, 500);

export default function TemplateForm({ template, readOnly = false, handleCancel }) {
  const navigate = useNavigate();
  const { categories, loadAllPermissionCategories } = usePermissionTemplateManagement();
  const {
    createRequestToCreatePermissionTemplate,
    createRequestToUpdatePermissionTemplate,
    approveRequestToCreatePermissionTemplate,
    approveRequestToUpdatePermissionTemplate,
    denyRequestToCreatePermissionTemplate,
    denyRequestToUpdatePermissionTemplate,
  } = usePermissionTemplateRequests();

  const [startingValues, setStartingValues] = useState();
  const { userAgency } = useUserAgency();
  const { agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();
  const [agenciesList, setAgenciesList] = useState([]);
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    if (!categories) {
      loadAllPermissionCategories();
    }
  }, []);

  useEffect(() => {
    if (userAgency && !agenciesForGrantee) {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, [userAgency]);

  useEffect(() => {
    if (agenciesForGrantee) {
      const tmpAgenciesList = agenciesForGrantee
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((agency) => {
          let userCounts = 0;
          let label = agency.name;
          if (template && template.agencyCounts && template.agencyCounts.length > 0 && !readOnly) {
            userCounts = parseInt(
              template.agencyCounts.find((agencyCount) => agencyCount.agencyId === agency.id)?.userCount || 0,
              10
            );
            label = `${agency.name} (${userCounts} users)`;
          }
          return {
            label: label,
            value: agency.id,
            disabled: userCounts > 0 || agency.id === userAgency.granteeAgencyId,
          };
        });
      setAgenciesList(tmpAgenciesList);
    }
    if (template && readOnly) {
      setSubtitle(
        `Submitted ${template?.submittedDate || ''} By: ${template?.submitterName || ''}, ${template?.agencyName || ''}`
      );
    }
  }, [agenciesForGrantee, template]);

  useEffect(() => {
    if (categories) {
      const newValues = template
        ? {
            name: template.name,
            categories: [],
            selectedSubagencies: template.agencyCounts.map((ag) => {
              return ag.agencyId;
            }),
            userCounts: template.agencyCounts,
            errorIds: [],
          }
        : {
            name: '',
            categories: [],
            selectedSubagencies: [userAgency?.granteeAgencyId],
            startingTemplateId: '',
            userCounts: [],
          };
      newValues.categories = categories.map((cat) => {
        return {
          ...cat,
          permissions: cat.permissions.map((perm) => {
            return {
              ...perm,
              enabled: template?.data.find((p) => perm.key === p.key)?.enabled || false,
            };
          }),
        };
      });
      if (template) {
        newValues.startingTemplate = template;
      }
      setStartingValues(newValues);
    }
  }, [categories, template]);

  const handleSubmitTemplate = async (templateData) => {
    const categoryPermissions = templateData.categories
      .map((category) => {
        return category.permissions.map((perm) => {
          return {
            key: perm.key,
            enabled: perm.enabled,
          };
        });
      })
      .flat();

    if (template) {
      await createRequestToUpdatePermissionTemplate({
        templateId: template.id,
        templateName: templateData.name,
        agencies: templateData.selectedSubagencies,
        permissions: categoryPermissions,
      });
    } else {
      await createRequestToCreatePermissionTemplate({
        templateName: templateData.name,
        agencies: templateData.selectedSubagencies,
        permissions: categoryPermissions,
      });
    }

    navigate('/templates/requests');
  };

  const approveRequest = async (approvalRequest) => {
    if ('new_template' === approvalRequest?.actionRequestTypeKey && approvalRequest?.requestId) {
      await approveRequestToCreatePermissionTemplate(approvalRequest.requestId);
    } else if ('update_template' === approvalRequest?.actionRequestTypeKey && approvalRequest?.requestId) {
      await approveRequestToUpdatePermissionTemplate(approvalRequest?.requestId);
    }
    navigate('/templates/requests');
  };

  const denyRequest = async (approvalRequest, denyReason) => {
    if ('new_template' === approvalRequest?.actionRequestTypeKey && approvalRequest?.requestId) {
      await denyRequestToCreatePermissionTemplate(approvalRequest.requestId, denyReason);
    } else if ('update_template' === approvalRequest?.actionRequestTypeKey && approvalRequest?.requestId) {
      await denyRequestToUpdatePermissionTemplate(approvalRequest.requestId, denyReason);
    }
    navigate('/templates/requests');
  };

  return (
    startingValues && (
      <TemplateFormView
        initialValues={startingValues}
        template={template}
        subtitle={subtitle}
        readOnly={readOnly}
        agenciesList={agenciesList}
        approveRequest={approveRequest}
        denyRequest={denyRequest}
        handleSubmitTemplate={handleSubmitTemplate}
        handleCancel={handleCancel}
      />
    )
  );
}

export function TemplateFormView({
  initialValues,
  template,
  subtitle,
  readOnly,
  agenciesList,
  approveRequest,
  denyRequest,
  handleSubmitTemplate,
  handleCancel,
}) {
  const [showDenyRequest, setShowDenyRequest] = useState(false);
  const [requestToProcess, setRequestToProcess] = useState();

  const denyClicked = async (approvalRequest) => {
    setShowDenyRequest(true);
    setRequestToProcess(approvalRequest);
  };

  return (
    initialValues && (
      <Formik
        initialValues={initialValues}
        validate={async (values) => {
          const errors = {};
          if (values.name.trim()) {
            const ok = await duplicateNameChecker(values.name.trim(), template?.name);
            if (ok === false) {
              // this seems to be undefined the first time
              errors.name = 'Template with given name already exists';
            }
          }

          values?.categories.forEach((category, index) => {
            if (category.validationType === 'only-one') {
              const checked = category.permissions.filter((permission) => permission.enabled);

              if (checked.length > 1) {
                errors.categories = {
                  [index]: 'Only one permission may be selected for this category',
                };
              }
            }
          });

          if (values.errorIds && values.errorIds.length > 0) {
            errors.selectedSubagencies = 'Agency users attached to the template';
          }

          return errors;
        }}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmitTemplate(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleReset, touched, errors, values }) => {
          return (
            <>
              <Form onSubmit={handleSubmit}>
                <LotusSectionHeader
                  title="Template Details"
                  subtitle={subtitle}
                  actionButtonList={
                    <LotusButtonList>
                      {readOnly ? (
                        <>
                          <LotusSecondaryActionButton
                            startIcon={<Close />}
                            isDanger
                            onClick={() => denyClicked(template)}
                          >
                            Deny
                          </LotusSecondaryActionButton>
                          <LotusSecondaryActionButton
                            startIcon={<Check />}
                            isSuccess
                            onClick={() => approveRequest(template)}
                          >
                            Approve
                          </LotusSecondaryActionButton>
                        </>
                      ) : (
                        <>
                          <LotusCancelButton onClick={handleCancel ? handleCancel : handleReset} />
                          <LotusPrimaryActionButton onClick={handleSubmit}>Submit</LotusPrimaryActionButton>
                        </>
                      )}
                    </LotusButtonList>
                  }
                />
                <div style={{ width: 600 }}>
                  <LotusSubsectionContent excludeTopPadding>
                    <LotusStackedFormItems>
                      <LotusTextInput readOnly={readOnly} name="name" label="Template Name" required />
                      {agenciesList && (
                        <LotusMultiSelect
                          readOnly={readOnly}
                          name="selectedSubagencies"
                          label="Agencies"
                          helperText="Templates are automatically available to the grantee"
                          items={agenciesList}
                          showSelectAll
                          readOnlyRows={3}
                        />
                      )}
                      {!template && <StartingTemplatePanel values={values} />}
                    </LotusStackedFormItems>
                  </LotusSubsectionContent>
                  <CategoriesPanel values={values} errors={errors} touched={touched} readOnly={readOnly} />
                </div>
              </Form>
              {requestToProcess && (
                <TemplateActionRequestDenyDialog
                  open={showDenyRequest}
                  handleClose={() => setShowDenyRequest(false)}
                  handleSubmit={(denyReason) => {
                    setShowDenyRequest(false);
                    denyRequest(requestToProcess, denyReason);
                  }}
                />
              )}
            </>
          );
        }}
      </Formik>
    )
  );
}
