import React from 'react';
import LotusTextButton from '../LotusTextButton';


export default function LotusCancelButton({
  onClick,
  isDanger,
  isSuccess,
  disabled
}) {
  return (
    <LotusTextButton
      onClick={onClick}
      variant="text"
      isDanger={isDanger}
      isSuccess={isSuccess}
      disabled={disabled}
    >
      Cancel
    </LotusTextButton>
  );
}