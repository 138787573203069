import React from 'react';

export default function LotusPage({ ...props }) {

  return (
    <div style={{paddingLeft: 24, paddingTop: 48, paddingRight: 24, marginBottom: 10}}>
      {props.children}
    </div>
  );
}
