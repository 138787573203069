import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import moment from 'moment';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { Stack } from '@mui/system';
import SectionCommentsChain from './SectionCommentsChain';
import { useUserAgency } from 'contexts/UserAgencyContext';
import HealthPlanPanel from 'components/widgets/Composites/HealthPlanPanel';

const shouldUseHealthPlanDb = (insSourceKey, existingClientCfg) => {
  if (insSourceKey === 'aca_exchange') {
    return existingClientCfg.acaUsesHealthPlanDatabase;
  } else if (insSourceKey === 'employer') {
    return existingClientCfg.employerSponsoredUsesHealthPlanDatabase;
  } else if (insSourceKey === 'indiv_market') {
    return existingClientCfg.individualMarketplaceUsesHealthPlanDatabase;
  }
  return undefined;
}

const isMedicareCoverageTypeC = (medicareCoverageTypes, medicareCoverageType) => {
  return medicareCoverageTypes && 
         medicareCoverageTypes.filter(types => types.id === medicareCoverageType && types.itemKey === 'part_c')?.length > 0 ? true : false;
}

export const getInsuranceStaticFormSchema = (privateInsuranceStatuses, dentalInsuranceStatuses, visionInsuranceStatuses, medicareStatuses, medicarePartDStatuses, medicaidStatuses, privateInsuranceSources, existingClientConfig, medicareCoverageTypes) => {
  
  return Yup.object().shape({
    privateInsuranceStatusId: Yup.string().required("Private Insurance Status is required"),
    privateInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Date Effective is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    privateInsurancePolicySourceId: Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Policy Source is required' });
        }
        return true;
      }
    }),
    privateInsuranceCarrierName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Insurance Company Name is required' });
        }
        return true;
      }
    }),
    privateInsurancePlanName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          let insSrcKey = privateInsuranceSources.find(s => ctx.parent.privateInsurancePolicySourceId === s.id)?.itemKey;
          if (!shouldUseHealthPlanDb(insSrcKey, existingClientConfig)) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    privateInsuranceHealthPlanId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          let insSrcKey = privateInsuranceSources.find(s => ctx.parent.privateInsurancePolicySourceId === s.id)?.itemKey;
          if (shouldUseHealthPlanDb(insSrcKey, existingClientConfig)) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    privateInsurancePolicyHolderName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.privateInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Policy Holder First and Last Name is required' });
        }
        return true;
      }
    }),
    privateInsuranceACATaxCreditAmount: Yup.string().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Tax Credit Amount must be a number').min(0,'Tax Credit Amount must be non-negative').test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const acaEnabled = privateInsuranceSources && ctx.parent.privateInsurancePolicySourceId === privateInsuranceSources.find((s) => s.itemKey === 'aca_exchange').id
        if (acaEnabled && ctx.parent.privateInsuranceHasACATaxCredit && !value) {
          return ctx.createError({ message: 'ACA Tax Credit Amount is required' });
        }
        return true;
      }
    }),
    privateInsuranceNoACATaxCreditReason:  Yup.string().nullable().test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const acaEnabled = privateInsuranceSources && ctx.parent.privateInsurancePolicySourceId === privateInsuranceSources.find((s) => s.itemKey === 'aca_exchange').id
        if (acaEnabled && !ctx.parent.privateInsuranceHasACATaxCredit && !value) {
          return ctx.createError({ message: 'Reason for not receiving the ACA tax credit is required' });
        }
        return true;
      }
    }),
    privateInsuranceCapAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Insurance cap amount must be a number').min(0,'Insurance cap amount must be non-negative'),
    privateInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    privateInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    privateInsurancePremiumAmount: Yup.string().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Amount must be a number').min(0,'Premium Amount must be non-negative').test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && ctx.parent.privateInsuranceStatusId !== privateInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Premium Amount is required' });
        }
        return true;
      }
    }),
    privateInsurancePremiumPaymentFrequencyId: Yup.string().nullable().test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.privateInsuranceStatusId && ctx.parent.privateInsuranceStatusId !== privateInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Premium Payment Frequency is required' });
        }
        return true;
      }
    }),
    privateInsurancePremiumOverdueAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Overdue Amount must be a number').min(0,'Premium Overdue Amount must be non-negative'),
    privateInsurancePremiumOverdueMonths: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Overdue Months must be a number').integer("Premium Overdue Months must be an integer").min(0,'Premium Overdue Months must be non-negative'),
    dentalInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.dentalInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Dental Insurance Effective Date is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental Insurance Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental Insurance Effective Date is too old' });
          }
        }
        return true;
      }
    }),
    dentalInsuranceCarrierName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.dentalInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Insurance Company Name is required' });
        }
        return true;
      }
    }),
    dentalInsurancePlanName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.dentalInsuranceStatusId);
        if (clientIns && !value) {
          if (!existingClientConfig.dentalUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    dentalInsuranceHealthPlanId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.dentalInsuranceStatusId);
        if (clientIns && !value) {
          if (existingClientConfig.dentalUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    dentalInsuranceMemberId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.dentalInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Member ID is required' });
        }
        return true;
      }
    }),
    dentalInsurancePremiumAmount: Yup.string().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Dental Premium Amount must be a number').min(0,'Dental Premium Amount must be non-negative').test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && ctx.parent.dentalInsuranceStatusId !== dentalInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Dental Premium Amount is required' });
        }
        return true;
      }
    }),
    dentalInsurancePremiumPaymentFrequencyId: Yup.string().nullable().test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.dentalInsuranceStatusId && ctx.parent.dentalInsuranceStatusId !== dentalInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Dental Premium Payment Frequency is required' });
        }
        return true;
      }
    }),
    dentalInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    dentalInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.visionInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Vision Insurance Effective Date is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision Insurance Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision Insurance Effective Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsuranceCarrierName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.visionInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Insurance Company Name is required' });
        }
        return true;
      }
    }),
    visionInsurancePlanName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.visionInsuranceStatusId);
        if (clientIns && !value) {
          if (!existingClientConfig.visionUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    visionInsuranceHealthPlanId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.visionInsuranceStatusId);
        if (clientIns && !value) {
          if (existingClientConfig.visionUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    visionInsuranceMemberId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(ctx.parent.visionInsuranceStatusId);
        if (clientIns && !value) {
          return ctx.createError({ message: 'Member ID is required' });
        }
        return true;
      }
    }),
    visionInsurancePremiumAmount: Yup.string().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Vision Premium Amount must be a number').min(0,'Vision Premium Amount must be non-negative').test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && ctx.parent.visionInsuranceStatusId !== visionInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Vision Premium Amount is required' });
        }
        return true;
      }
    }),
    visionInsurancePremiumPaymentFrequencyId: Yup.string().nullable().test({
      name: 'presentIf',
      skipAbsent: false,
      test(value, ctx) {
        const clientIns = ctx.parent.visionInsuranceStatusId && ctx.parent.visionInsuranceStatusId !== visionInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientIns && !value) {
          return ctx.createError({ message: 'Vision Premium Payment Frequency is required' });
        }
        return true;
      }
    }),
    visionInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    insuranceCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
    medicareEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        const clientMedicare = ctx.parent.medicareStatusId && ctx.parent.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientMedicare && !value) {
          return ctx.createError({ message: 'Medicare Date Effective is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Medicare Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Medicare Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    medicareCarrierName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientMedicare = ctx.parent.medicareStatusId && ctx.parent.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id && 
        isMedicareCoverageTypeC(medicareCoverageTypes, ctx.parent.medicareCoverageType);
        if (clientMedicare && !value) {
          return ctx.createError({ message: 'Insurance Company Name is required' });
        }
        return true;
      }
    }),
    medicarePlanName:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientMedicare = ctx.parent.medicareStatusId && ctx.parent.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id && isMedicareCoverageTypeC(medicareCoverageTypes, ctx.parent.medicareCoverageType);
        if (clientMedicare && !value) {
          if (!existingClientConfig.medicareUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    medicareHealthPlanId:  Yup.string().nullable().test({
      name: 'requiredMaybe',
      skipAbsent: false,
      test(value, ctx) {
        const clientMedicare = ctx.parent.medicareStatusId && ctx.parent.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id && isMedicareCoverageTypeC(medicareCoverageTypes, ctx.parent.medicareCoverageType);
        if (clientMedicare && !value) {
          if (existingClientConfig.medicareUsesHealthPlanDatabase) {
            return ctx.createError({ message: 'Plan Name is required' });
          }
        }
        return true;
      }
    }),
    medicarePremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Premium Amount must be a number').min(0,'Medicare Premium Amount must be non-negative'),
    medicarePremiumOverdueAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Premium Overdue Amount must be a number').min(0,'Medicare Premium Overdue Amount must be non-negative'),
    medicarePartDEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        const clientMedicarePartD = ctx.parent.medicarePartDStatusId && ctx.parent.medicarePartDStatusId !== medicarePartDStatuses.find(s => s.itemKey === 'no_benefits').id;
        if (clientMedicarePartD && !value) {
          return ctx.createError({ message: 'Medicare Part D Date Effective is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Medicare Part D Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Medicare Part D Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    medicarePartDPremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Part D Premium Amount must be a number').min(0,'Medicare Part D Premium Amount must be non-negative'),
    medicaidLastVerificationCompletedDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if (ctx.parent.medicaidCheck && !value) {
          return ctx.createError({ message: 'Date Last Verification Completed is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Date Last Verification Completed must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Date Last Verification Completed is too old' });
          }
        }
        return true;
      }
    }),
  });
}

export const getInsuranceStaticFormSchemaExcludingRequiredChecks = () => {
  return Yup.object().shape({
    privateInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    privateInsuranceCapAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Insurance cap amount must be a number').min(0,'Insurance cap amount must be non-negative'),
    privateInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    privateInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    privateInsurancePremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Amount must be a number').min(0,'Premium Amount must be non-negative'),
    privateInsurancePremiumOverdueAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Overdue Amount must be a number').min(0,'Premium Overdue Amount must be non-negative'),
    privateInsurancePremiumOverdueMonths: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Premium Overdue Months must be a number').integer("Premium Overdue Months must be an integer").min(0,'Premium Overdue Months must be non-negative'),
    dentalInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental Insurance Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental Insurance Effective Date is too old' });
          }
        }
        return true;
      }
    }),
    dentalInsurancePremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Dental Premium Amount must be a number').min(0,'Dental Premium Amount must be non-negative'),
    dentalInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    dentalInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Dental COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Dental COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsuranceEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision Insurance Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision Insurance Effective Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsurancePremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Vision Premium Amount must be a number').min(0,'Vision Premium Amount must be non-negative'),
    visionInsuranceCobraStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision COBRA Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision COBRA Start Date is too old' });
          }
        }
        return true;
      }
    }),
    visionInsuranceCobraEndDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Vision COBRA End Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Vision COBRA End Date is too old' });
          }
        }
        return true;
      }
    }),
    medicareEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Medicare Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Medicare Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    medicarePremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Premium Amount must be a number').min(0,'Medicare Premium Amount must be non-negative'),
    medicarePremiumOverdueAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Premium Overdue Amount must be a number').min(0,'Medicare Premium Overdue Amount must be non-negative'),
    medicarePartDEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Medicare Part D Date Effective must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Medicare Part D Date Effective is too old' });
          }
        }
        return true;
      }
    }),
    medicaidEffectiveDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Medicaid Effective Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Medicaid Effective Date is too old' });
          }
        }
        return true;
      }
    }),
    medicarePartDPremiumAmount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Medicare Part D Premium Amount must be a number').min(0,'Medicare Part D Premium Amount must be non-negative'),
  });
}

export const buildInsuranceStaticFormSectionNames = (existingClientConfig) => {
  const lst = [
    "Private Insurance", 
  ];
  if (existingClientConfig.showDentalInsurance) {
    lst.push("Dental Insurance");
  }
  if (existingClientConfig.showVisionInsurance) {
    lst.push("Vision Insurance");
  }
  lst.push("Medicare Part A, B, C or D");
  lst.push("Medicaid");
  if (existingClientConfig.showBenefitSubsidies) {
    lst.push("Subsidies");
  }
  lst.push("Documentation");
  return lst;
}

export const initializeInsuranceFormData = (formData) => {
  if (!formData) {
    return formData;
  }
  // React gets pissed about null values bound to controls
  return {
    insuranceUpdateEffectiveDate: formData.insuranceUpdateEffectiveDate || null,
    privateInsuranceStatusId: formData.privateInsuranceStatusId || '', 
    privateInsuranceEffectiveDate: formData.privateInsuranceEffectiveDate || null, 
    privateInsurancePolicySourceId: formData.privateInsurancePolicySourceId || '', 
    privateInsuranceCarrierName: formData.privateInsuranceCarrierName || '', 
    privateInsurancePlanName: formData.privateInsurancePlanName || '', 
    privateInsuranceMemberId: formData.privateInsuranceMemberId || '', 
    privateInsuranceFamilyPlan: formData.privateInsuranceFamilyPlan !== null ? formData.privateInsuranceFamilyPlan : '', 
    privateInsuranceAllFamilyMembers: formData.privateInsuranceAllFamilyMembers !== null ? formData.privateInsuranceAllFamilyMembers : '', 
    privateInsurancePolicyHolderName: formData.privateInsurancePolicyHolderName || '', 
    privateInsuranceMedicalCoverage: formData.privateInsuranceMedicalCoverage !== null ? formData.privateInsuranceMedicalCoverage : '', 
    privateInsuranceMentalHealthCoverage: formData.privateInsuranceMentalHealthCoverage !== null ? formData.privateInsuranceMentalHealthCoverage : '', 
    privateInsuranceHasCap: formData.privateInsuranceHasCap !== null ? formData.privateInsuranceHasCap : '', 
    privateInsuranceCapAmount: formData.privateInsuranceCapAmount !== null ? formData.privateInsuranceCapAmount : '', 
    privateInsuranceCobraStartDate: formData.privateInsuranceCobraStartDate || null, 
    privateInsuranceCobraEndDate: formData.privateInsuranceCobraEndDate || null, 
    privateInsurancePremiumAmount: formData.privateInsurancePremiumAmount !== null ? formData.privateInsurancePremiumAmount : '', 
    privateInsurancePremiumPaymentFrequencyId: formData.privateInsurancePremiumPaymentFrequencyId || '', 
    privateInsurancePremiumOverdueAmount: formData.privateInsurancePremiumOverdueAmount !== null ? formData.privateInsurancePremiumOverdueAmount : '', 
    privateInsurancePremiumOverdueMonths: formData.privateInsurancePremiumOverdueMonths !== null ? formData.privateInsurancePremiumOverdueMonths : '', 
    privateInsurancePharmacyCoverage: formData.privateInsurancePharmacyCoverage !== null ? formData.privateInsurancePharmacyCoverage : false, 
    privateInsuranceScripDiscountCardOnly: formData.privateInsuranceScripDiscountCardOnly !== null ? formData.privateInsuranceScripDiscountCardOnly : '', 
    privateInsuranceHivMeds: formData.privateInsuranceHivMeds !== null ? formData.privateInsuranceHivMeds : '', 
    privateInsuranceRxBin: formData.privateInsuranceRxBin || '', 
    privateInsuranceRxPcn: formData.privateInsuranceRxPcn || '', 
    privateInsuranceRxGroup: formData.privateInsuranceRxGroup || '', 
    dentalInsuranceStatusId: formData.dentalInsuranceStatusId || '', 
    dentalInsuranceEffectiveDate: formData.dentalInsuranceEffectiveDate || null, 
    dentalInsurancePlanName: formData.dentalInsurancePlanName || '', 
    dentalInsuranceMemberId: formData.dentalInsuranceMemberId || '', 
    dentalInsuranceBillingId: formData.dentalInsuranceBillingId || '', 
    dentalInsurancePremiumAmount: formData.dentalInsurancePremiumAmount !== null ? formData.dentalInsurancePremiumAmount : '', 
    dentalInsurancePremiumPaymentFrequencyId: formData.dentalInsurancePremiumPaymentFrequencyId || '', 
    dentalInsuranceCobraStartDate: formData.dentalInsuranceCobraStartDate || null, 
    dentalInsuranceCobraEndDate: formData.dentalInsuranceCobraEndDate || null, 
    dentalInsuranceComments: formData.dentalInsuranceComments || '', 
    visionInsuranceStatusId: formData.visionInsuranceStatusId || '', 
    visionInsuranceEffectiveDate: formData.visionInsuranceEffectiveDate || null, 
    visionInsurancePlanName: formData.visionInsurancePlanName || '', 
    visionInsuranceMemberId: formData.visionInsuranceMemberId || '', 
    visionInsuranceBillingId: formData.visionInsuranceBillingId || '', 
    visionInsurancePremiumAmount: formData.visionInsurancePremiumAmount !== null ? formData.visionInsurancePremiumAmount : '', 
    visionInsurancePremiumPaymentFrequencyId: formData.visionInsurancePremiumPaymentFrequencyId || '', 
    visionInsuranceCobraStartDate: formData.visionInsuranceCobraStartDate || null, 
    visionInsuranceCobraEndDate: formData.visionInsuranceCobraEndDate || null,
    medicareEffectiveDate: formData.medicareEffectiveDate || null,
    medicareStatusId: formData.medicareStatusId || '', 
    medicareCarrierName: formData.medicareCarrierName || '', 
    medicarePlanName: formData.medicarePlanName || '', 
    medicareMemberId: formData.medicareMemberId || '',
    medicareIncludesPharmacyBenefit: formData.medicareIncludesPharmacyBenefit !== null ? formData.medicareIncludesPharmacyBenefit : '', 
    medicareComments: formData.medicareComments || '', 
    medicarePartDStatusId: formData.medicarePartDStatusId || '', 
    medicarePartDEffectiveDate: formData.medicarePartDEffectiveDate || null, 
    medicarePartDMemberId: formData.medicarePartDMemberId || '',
    medicarePartDPlanName: formData.medicarePartDPlanName || '', 
    medicarePartDComments: formData.medicarePartDComments || '', 
    medicaidStatusId: formData.medicaidStatusId || '', 
    medicaidCheck: formData.medicaidCheck !== null ? formData.medicaidCheck : false, 
    medicaidLastVerificationCompletedDate: formData.medicaidLastVerificationCompletedDate || null,
    medicaidPlanName: formData.medicaidPlanName || '', 
    medicaidIdNumber: formData.medicaidIdNumber || '', 
    medicaidQualifierCode: formData.medicaidQualifierCode || '',
    medicaidComments: formData.medicaidComments || '', 
    medicaidRxBin: formData.medicaidRxBin || '', 
    medicaidRxPcn: formData.medicaidRxPcn || '',
    medicaidRxGroup: formData.medicaidRxGroup || '', 
    medicarePrimaryPolicyHolderName: formData.medicarePrimaryPolicyHolderName || '', 
    medicareRxBin: formData.medicareRxBin || '', 
    medicareRxPcn: formData.medicareRxPcn || '',
    medicareRxGroup: formData.medicareRxGroup || '', 
    medicarePremiumAmount: formData.medicarePremiumAmount !== null ? formData.medicarePremiumAmount : '', 
    medicarePremiumPaymentFrequencyId: formData.medicarePremiumPaymentFrequencyId || '', 
    medicarePremiumOverdueAmount: formData.medicarePremiumOverdueAmount !== null ? formData.medicarePremiumOverdueAmount : '', 
    medicarePremiumOverdueMonths: formData.medicarePremiumOverdueMonths !== null ? formData.medicarePremiumOverdueMonths : '', 
    medicareLowIncomeSubsidyStatusId: formData.medicareLowIncomeSubsidyStatusId || '', 
    medicareLowIncomeSubsidyTypeId: formData.medicareLowIncomeSubsidyTypeId || '',
    fullLowIncomeSubsidyStatusId: formData.fullLowIncomeSubsidyStatusId || '', 
    vaHealthBenefitsStatusId: formData.vaHealthBenefitsStatusId || '', 
    ihsHealthBenefitsStatusId: formData.ihsHealthBenefitsStatusId || '', 
    medicarePartDRxBin: formData.medicarePartDRxBin || '',
    medicarePartDRxPcn: formData.medicarePartDRxPcn || '',
    medicarePartDRxGroup: formData.medicarePartDRxGroup || '',
    medicarePartDPremiumAmount: formData.medicarePartDPremiumAmount !== null ? formData.medicarePartDPremiumAmount : '', 
    medicarePartDPremiumPaymentFrequencyId: formData.medicarePartDPremiumPaymentFrequencyId || '', 
    privateInsuranceHealthPlanId: formData.privateInsuranceHealthPlanId || '', 
    dentalInsuranceHealthPlanId: formData.dentalInsuranceHealthPlanId || '',
    visionInsuranceHealthPlanId: formData.visionInsuranceHealthPlanId || '', 
    medicareHealthPlanId: formData.medicareHealthPlanId || '',
    medicarePartDHealthPlanId: formData.medicarePartDHealthPlanId || '', 
    medicaidHealthPlanId: formData.medicaidHealthPlanId || '',
    dentalInsuranceCarrierName: formData.dentalInsuranceCarrierName || '', 
    visionInsuranceCarrierName: formData.visionInsuranceCarrierName || '',
    medicarePartDCarrierName: formData.medicarePartDCarrierName || '', 
    medicaidCarrierName: formData.medicaidCarrierName || '',
    medicaidEffectiveDate: formData.medicaidEffectiveDate || null,
    insuranceDocumentation: formData.insuranceDocumentation || [],
    insuranceCommentsChain: formData.insuranceCommentsChain || [],
    privateInsuranceHasACATaxCredit: formData.privateInsuranceHasACATaxCredit !== null && formData.privateInsuranceHasACATaxCredit !== '' ? formData.privateInsuranceHasACATaxCredit : false,
    privateInsuranceACATaxCreditAmount: formData.privateInsuranceACATaxCreditAmount || '',
    privateInsuranceNoACATaxCreditReason: formData.privateInsuranceNoACATaxCreditReason || '',
    privateInsuranceMaxOutOfPocketAmount: formData.privateInsuranceMaxOutOfPocketAmount || '',
    privateInsuranceSubstanceAbuseCoverage: formData.privateInsuranceSubstanceAbuseCoverage !== null ? formData.privateInsuranceSubstanceAbuseCoverage : '',
    medicareCoverageType: formData.medicareCoverageType || '',
    medicaidMCOorHMOPlan: formData.medicaidMCOorHMOPlan !== null ? formData.medicaidMCOorHMOPlan : false,
    medicaidCategory: formData.medicaidCategory || '',
    privateInsuranceComments: formData.privateInsuranceComments || '',
  };
}

export const getInsuranceFormData = (formData) => {
    
  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    insuranceUpdateEffectiveDate,
    privateInsuranceStatusId, privateInsuranceEffectiveDate, privateInsurancePolicySourceId, privateInsuranceCarrierName, 
    privateInsurancePlanName, privateInsuranceMemberId, privateInsuranceFamilyPlan, privateInsuranceAllFamilyMembers, 
    privateInsuranceMedicalCoverage, privateInsuranceMentalHealthCoverage, privateInsuranceHasCap, privateInsuranceCapAmount, 
    privateInsuranceCobraStartDate, privateInsuranceCobraEndDate, privateInsurancePremiumAmount, privateInsurancePremiumPaymentFrequencyId, 
    privateInsurancePremiumOverdueAmount, privateInsurancePremiumOverdueMonths, privateInsurancePharmacyCoverage, 
    privateInsuranceScripDiscountCardOnly, privateInsuranceHivMeds, privateInsuranceRxBin, privateInsuranceRxPcn, 
    privateInsuranceRxGroup, privateInsurancePolicyHolderName,
    dentalInsuranceStatusId, dentalInsurancePlanName, dentalInsuranceMemberId, dentalInsuranceBillingId, 
    dentalInsuranceCobraStartDate, dentalInsuranceCobraEndDate, dentalInsuranceComments, dentalInsuranceEffectiveDate,
    dentalInsurancePremiumAmount, dentalInsurancePremiumPaymentFrequencyId, 
    visionInsuranceStatusId, visionInsurancePlanName, visionInsuranceMemberId, visionInsuranceBillingId, 
    visionInsuranceCobraStartDate, visionInsuranceCobraEndDate, visionInsuranceEffectiveDate,
    visionInsurancePremiumAmount, visionInsurancePremiumPaymentFrequencyId,
    medicareEffectiveDate, medicareMemberId,
    medicareStatusId, medicareCarrierName, medicarePlanName, medicareIncludesPharmacyBenefit,
    medicareComments, medicarePartDStatusId, medicarePartDEffectiveDate, medicarePartDMemberId,
    medicarePartDPlanName, medicarePartDComments, medicaidCheck, medicaidLastVerificationCompletedDate,
    medicaidStatusId, medicaidPlanName, medicaidIdNumber,
    medicaidQualifierCode,
    medicaidComments, medicaidRxBin, medicaidRxPcn,
    medicaidRxGroup, medicarePrimaryPolicyHolderName, medicareRxBin, medicareRxPcn,
    medicareRxGroup, medicarePremiumAmount, medicarePremiumPaymentFrequencyId,
    medicarePremiumOverdueAmount, medicarePremiumOverdueMonths, medicareLowIncomeSubsidyStatusId, medicareLowIncomeSubsidyTypeId,
    fullLowIncomeSubsidyStatusId, vaHealthBenefitsStatusId, ihsHealthBenefitsStatusId,
    medicarePartDRxBin, medicarePartDRxPcn, medicarePartDRxGroup,
    medicarePartDPremiumAmount, medicarePartDPremiumPaymentFrequencyId,
    privateInsuranceHealthPlanId, dentalInsuranceHealthPlanId,
    visionInsuranceHealthPlanId, medicareHealthPlanId,
    medicarePartDHealthPlanId, medicaidHealthPlanId,
    dentalInsuranceCarrierName, visionInsuranceCarrierName,
    medicarePartDCarrierName, medicaidCarrierName,
    medicaidEffectiveDate,
    insuranceDocumentation, insuranceCommentsChain, 
    privateInsuranceHasACATaxCredit, privateInsuranceACATaxCreditAmount, privateInsuranceNoACATaxCreditReason,
    privateInsuranceMaxOutOfPocketAmount, privateInsuranceSubstanceAbuseCoverage, medicareCoverageType, medicaidMCOorHMOPlan,
    medicaidCategory, privateInsuranceComments} = formData;

  const clientInsurance = {
    insuranceUpdateEffectiveDate,
    privateInsuranceStatusId, privateInsuranceEffectiveDate, privateInsurancePolicySourceId, privateInsuranceCarrierName, 
    privateInsurancePlanName, privateInsuranceMemberId, privateInsuranceFamilyPlan, privateInsuranceAllFamilyMembers, 
    privateInsuranceMedicalCoverage, privateInsuranceMentalHealthCoverage, privateInsuranceHasCap, privateInsuranceCapAmount, 
    privateInsuranceCobraStartDate, privateInsuranceCobraEndDate, privateInsurancePremiumAmount, privateInsurancePremiumPaymentFrequencyId, 
    privateInsurancePremiumOverdueAmount, privateInsurancePremiumOverdueMonths, privateInsurancePharmacyCoverage, 
    privateInsuranceScripDiscountCardOnly, privateInsuranceHivMeds, privateInsuranceRxBin, privateInsuranceRxPcn, 
    privateInsuranceRxGroup, privateInsurancePolicyHolderName,
    dentalInsuranceStatusId, dentalInsurancePlanName, dentalInsuranceMemberId, dentalInsuranceBillingId, 
    dentalInsuranceCobraStartDate, dentalInsuranceCobraEndDate, dentalInsuranceComments, dentalInsuranceEffectiveDate,
    dentalInsurancePremiumAmount, dentalInsurancePremiumPaymentFrequencyId, 
    visionInsuranceStatusId, visionInsurancePlanName, visionInsuranceMemberId, visionInsuranceBillingId, 
    visionInsuranceCobraStartDate, visionInsuranceCobraEndDate, visionInsuranceEffectiveDate,
    visionInsurancePremiumAmount, visionInsurancePremiumPaymentFrequencyId,
    medicareEffectiveDate, medicareMemberId,
    medicareStatusId, medicareCarrierName, medicarePlanName, medicareIncludesPharmacyBenefit,
    medicareComments, medicarePartDStatusId, medicarePartDEffectiveDate, medicarePartDMemberId,
    medicarePartDPlanName, medicarePartDComments, medicaidCheck, medicaidLastVerificationCompletedDate,
    medicaidStatusId, medicaidPlanName, medicaidIdNumber,
    medicaidQualifierCode,
    medicaidComments, medicaidRxBin, medicaidRxPcn,
    medicaidRxGroup, medicarePrimaryPolicyHolderName, medicareRxBin, medicareRxPcn,
    medicareRxGroup, medicarePremiumAmount, medicarePremiumPaymentFrequencyId,
    medicarePremiumOverdueAmount, medicarePremiumOverdueMonths, medicareLowIncomeSubsidyStatusId, medicareLowIncomeSubsidyTypeId,
    fullLowIncomeSubsidyStatusId, vaHealthBenefitsStatusId, ihsHealthBenefitsStatusId,
    medicarePartDRxBin, medicarePartDRxPcn, medicarePartDRxGroup,
    medicarePartDPremiumAmount, medicarePartDPremiumPaymentFrequencyId,
    privateInsuranceHealthPlanId, dentalInsuranceHealthPlanId,
    visionInsuranceHealthPlanId, medicareHealthPlanId,
    medicarePartDHealthPlanId, medicaidHealthPlanId,
    dentalInsuranceCarrierName, visionInsuranceCarrierName,
    medicarePartDCarrierName, medicaidCarrierName,
    medicaidEffectiveDate,
    insuranceDocumentation, insuranceCommentsChain, 
    privateInsuranceHasACATaxCredit, privateInsuranceACATaxCreditAmount, privateInsuranceNoACATaxCreditReason,
    privateInsuranceMaxOutOfPocketAmount, privateInsuranceSubstanceAbuseCoverage, medicareCoverageType, medicaidMCOorHMOPlan,
    medicaidCategory, privateInsuranceComments
  };
  return clientInsurance;
}

const EligibilityApplicationInsuranceStaticForm = ({clientId, existingClientConfig, eligibilityAppConfig, disabled}) => {

  const { medicareStatuses, medicarePartDStatuses, medicareLowIncomeSubsidyStatuses, medicareLowIncomeSubsidyTypes, medicaidStatuses,
    fullLowIncomeSubsidyStatuses, vaHealthBenefitsStatuses, ihsHealthBenefitsStatuses, premiumPaymentFrequencies, medicaidCategories,
    privateInsuranceStatuses, privateInsuranceSources, dentalInsuranceStatuses, visionInsuranceStatuses, medicareCoverageTypes } = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [privateInsuranceStatusList, setPrivateInsuranceStatusList] = useState();
  const [privateInsuranceSourceList, setPrivateInsuranceSourceList] = useState();
  const [dentalInsuranceStatusList, setDentalInsuranceStatusList] = useState();
  const [visionInsuranceStatusList, setVisionInsuranceStatusList] = useState();
  const [medicareStatusList, setMedicareStatusList] = useState();
  const [medicarePartDStatusList, setMedicarePartDStatusList] = useState();
  const [medicaidStatusList, setMedicaidStatusList] = useState();
  const [medicaidCategoryList, setMedicaidCategoryList] = useState();
  const [medicareLowIncomeSubsidyStatusList, setMedicareLowIncomeSubsidyStatusList] = useState();
  const [medicareLowIncomeSubsidyTypeList, setMedicareLowIncomeSubsidyTypeList] = useState();
  const [fullLowIncomeSubsidyStatusList, setFullLowIncomeSubsidyStatusList] = useState();
  const [vaHealthBenefitsStatusList, setVaHealthBenefitsStatusList] = useState();
  const [ihsHealthBenefitsStatusList, setIhsHealthBenefitsStatusList] = useState();
  
  const [premiumPaymentFrequencyList, setPremiumPaymentFrequencyList] = useState();
  const [medicareCoverageTypesList, setMedicareCoverageTypesList] = useState();

  const { values, setFieldValue } = useFormikContext();
  
  const onMedicaidCheckChanged = async (isChecked) => {
    if (!isChecked) {
      setFieldValue('medicaidLastVerificationCompletedDate', null);
      setFieldValue('medicaidStatusId', '');
      setFieldValue('medicaidPlanName', '');
      setFieldValue('medicaidIdNumber', '');
      setFieldValue('medicaidQualifierCode', '');
      setFieldValue('medicaidComments', '');
      setFieldValue('medicaidRxBin', '');
      setFieldValue('medicaidRxPcn', '');
      setFieldValue('medicaidRxGroup', '');
      setFieldValue('medicaidCategory', '');
      setFieldValue('medicaidCarrierName', '');
      setFieldValue('medicaidHealthPlanId', '');
      setFieldValue('medicaidMCOorHMOPlan', '');
      setFieldValue('medicaidEffectiveDate', null);
    }
  };

  useEffect(() => {
    if (medicareStatuses) {
      setMedicareStatusList(medicareStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicarePartDStatuses) {
      setMedicarePartDStatusList(medicarePartDStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicaidStatuses) {
      setMedicaidStatusList(medicaidStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicaidCategories) {
      setMedicaidCategoryList(medicaidCategories.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareLowIncomeSubsidyStatuses) {
      setMedicareLowIncomeSubsidyStatusList(medicareLowIncomeSubsidyStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareLowIncomeSubsidyTypes) {
      setMedicareLowIncomeSubsidyTypeList(medicareLowIncomeSubsidyTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (fullLowIncomeSubsidyStatuses) {
      setFullLowIncomeSubsidyStatusList(fullLowIncomeSubsidyStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (vaHealthBenefitsStatuses) {
      setVaHealthBenefitsStatusList(vaHealthBenefitsStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (ihsHealthBenefitsStatuses) {
      setIhsHealthBenefitsStatusList(ihsHealthBenefitsStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (privateInsuranceStatuses) {
      setPrivateInsuranceStatusList(privateInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (privateInsuranceSources) {
      setPrivateInsuranceSourceList(privateInsuranceSources.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
    if (dentalInsuranceStatuses) {
      setDentalInsuranceStatusList(dentalInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (visionInsuranceStatuses) {
      setVisionInsuranceStatusList(visionInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (premiumPaymentFrequencies) {
      setPremiumPaymentFrequencyList(premiumPaymentFrequencies.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareCoverageTypes) {
      setMedicareCoverageTypesList(medicareCoverageTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [medicareStatuses, medicarePartDStatuses, medicareLowIncomeSubsidyStatuses, medicareLowIncomeSubsidyTypes,
      fullLowIncomeSubsidyStatuses, vaHealthBenefitsStatuses, ihsHealthBenefitsStatuses, premiumPaymentFrequencies,
      privateInsuranceStatuses, privateInsuranceSources, dentalInsuranceStatuses, visionInsuranceStatuses, medicareCoverageTypes]);

  const isPolicySourceAca = (id) => {
    return privateInsuranceSources && privateInsuranceSources.filter(sources => sources.id === id && sources.itemKey === 'aca_exchange')?.length > 0 ? true : false;
  }

  useEffect(() => {
    if(!isPolicySourceAca(values.privateInsurancePolicySourceId)) {
      setFieldValue('privateInsuranceHasACATaxCredit', '');
    }
    if(values.privateInsuranceHasACATaxCredit) {
      setFieldValue('privateInsuranceNoACATaxCreditReason', '');
    } else {
      setFieldValue('privateInsuranceACATaxCreditAmount', '');
    }
    if(values.privateInsuranceStatusId !== privateInsuranceStatuses.find(s => s.itemKey === 'cobra').id) {
      setFieldValue('privateInsuranceCobraStartDate', null);
      setFieldValue('privateInsuranceCobraEndDate', null);
    }
    if(!values.privateInsuranceStatusId || values.privateInsuranceStatusId === privateInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('privateInsuranceEffectiveDate', null);
      setFieldValue('privateInsurancePolicySourceId', '');
      setFieldValue('privateInsuranceCarrierName', '');
      setFieldValue('privateInsurancePlanName', '');
      setFieldValue('privateInsuranceMemberId', '');
      setFieldValue('privateInsuranceFamilyPlan', '');
      setFieldValue('privateInsuranceAllFamilyMembers', '');
      setFieldValue('privateInsurancePolicyHolderName', '');
      setFieldValue('privateInsuranceMedicalCoverage', '');
      setFieldValue('privateInsuranceMentalHealthCoverage', '');
      setFieldValue('privateInsuranceHasCap', '');
      setFieldValue('privateInsuranceCapAmount', '');
      setFieldValue('privateInsuranceCobraStartDate', null);
      setFieldValue('privateInsuranceCobraEndDate', null);
      setFieldValue('privateInsurancePremiumAmount', '');
      setFieldValue('privateInsurancePremiumPaymentFrequencyId', '');
      setFieldValue('privateInsurancePremiumOverdueAmount', '');
      setFieldValue('privateInsurancePremiumOverdueMonths', '');
      setFieldValue('privateInsurancePharmacyCoverage', '');
      setFieldValue('privateInsuranceScripDiscountCardOnly', '');
      setFieldValue('privateInsuranceHivMeds', '');
      setFieldValue('privateInsuranceRxBin', '');
      setFieldValue('privateInsuranceRxPcn', '');
      setFieldValue('privateInsuranceRxGroup', '');
      setFieldValue('privateInsuranceHealthPlanId', '');
      setFieldValue('privateInsuranceHasACATaxCredit', '');
      setFieldValue('privateInsuranceACATaxCreditAmount', '');
      setFieldValue('privateInsuranceNoACATaxCreditReason', '');
      setFieldValue('privateInsuranceMaxOutOfPocketAmount', '');
      setFieldValue('privateInsuranceSubstanceAbuseCoverage', '');
      setFieldValue('privateInsuranceComments', '');
    }
    if(!values.privateInsuranceFamilyPlan) {
      setFieldValue('privateInsuranceAllFamilyMembers', '');
    }
    if(!values.privateInsuranceHasCap) {
      setFieldValue('privateInsuranceCapAmount', '');
    }

  }, [
      values.privateInsurancePolicySourceId, 
      values.privateInsuranceHasACATaxCredit, 
      values.privateInsuranceStatusId, 
      values.privateInsuranceFamilyPlan, 
      values.privateInsuranceHasCap
    ]);

  useEffect(() => {
    if(values.dentalInsuranceStatusId !== dentalInsuranceStatuses.find(s => s.itemKey === 'cobra').id) {
      setFieldValue('dentalInsuranceCobraStartDate', null);
      setFieldValue('dentalInsuranceCobraEndDate', null);
    }
    if(!values.dentalInsuranceStatusId || values.dentalInsuranceStatusId === dentalInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('dentalInsuranceEffectiveDate', null);
      setFieldValue('dentalInsuranceCarrierName', '');
      setFieldValue('dentalInsuranceHealthPlanId', '');
      setFieldValue('dentalInsurancePlanName', '');
      setFieldValue('dentalInsuranceCobraStartDate', null);
      setFieldValue('dentalInsuranceCobraEndDate', null);
      setFieldValue('dentalInsuranceMemberId', '');
      setFieldValue('dentalInsuranceBillingId', '');
      setFieldValue('dentalInsurancePremiumAmount', '');
      setFieldValue('dentalInsurancePremiumPaymentFrequencyId', '');
      setFieldValue('dentalInsuranceComments', '');
    }
  }, [values.dentalInsuranceStatusId]);

  useEffect(() => {
    if(values.visionInsuranceStatusId !== visionInsuranceStatuses.find(s => s.itemKey === 'cobra').id) {
      setFieldValue('visionInsuranceCobraStartDate', null);
      setFieldValue('visionInsuranceCobraEndDate', null);
    }
    if(!values.visionInsuranceStatusId || values.visionInsuranceStatusId === visionInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('visionInsuranceEffectiveDate', null);
      setFieldValue('visionInsuranceCarrierName', '');
      setFieldValue('visionInsuranceHealthPlanId', '');
      setFieldValue('visionInsurancePlanName', '');
      setFieldValue('visionInsuranceCobraStartDate', null);
      setFieldValue('visionInsuranceCobraEndDate', null);
      setFieldValue('visionInsuranceMemberId', '');
      setFieldValue('visionInsuranceBillingId', '');
      setFieldValue('visionInsurancePremiumAmount', '');
      setFieldValue('visionInsurancePremiumPaymentFrequencyId', '');
    }
  }, [values.visionInsuranceStatusId]);

  useEffect(() => {
    if(!values.medicareStatusId || values.medicareStatusId === medicareStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('medicareCoverageType', '');
      setFieldValue('medicareEffectiveDate', null);
      setFieldValue('medicareCarrierName', '');
      setFieldValue('medicareHealthPlanId', '');
      setFieldValue('medicarePlanName', '');
      setFieldValue('medicareMemberId', '');
      setFieldValue('medicarePremiumAmount', '');
      setFieldValue('medicarePremiumPaymentFrequencyId', '');
      setFieldValue('medicareIncludesPharmacyBenefit', '');
      setFieldValue('medicarePrimaryPolicyHolderName', '');
      setFieldValue('medicareRxBin', '');
      setFieldValue('medicareRxPcn', '');
      setFieldValue('medicareRxGroup', '');
      setFieldValue('medicarePremiumOverdueAmount', '');
      setFieldValue('medicarePremiumOverdueMonths', '');
      setFieldValue('medicareComments', '');
      setFieldValue('medicarePartDStatusId', '');
    }
    if(!isMedicareCoverageTypeC(medicareCoverageTypes, values.medicareCoverageType)) {
      setFieldValue('medicareCarrierName', '');
      setFieldValue('medicareHealthPlanId', '');
      setFieldValue('medicarePlanName', '');
      setFieldValue('medicarePremiumAmount', '');
      setFieldValue('medicarePremiumPaymentFrequencyId', '');
      setFieldValue('medicareIncludesPharmacyBenefit', '');
      setFieldValue('medicareRxBin', '');
      setFieldValue('medicareRxPcn', '');
      setFieldValue('medicareRxGroup', '');
      setFieldValue('medicarePremiumOverdueAmount', '');
      setFieldValue('medicarePremiumOverdueMonths', '');
    }
    if(!values.medicarePartDStatusId || values.medicarePartDStatusId === medicarePartDStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('medicarePartDEffectiveDate', null);
      setFieldValue('medicarePartDCarrierName', '');
      setFieldValue('medicarePartDHealthPlanId', '');
      setFieldValue('medicarePartDPlanName', '');
      setFieldValue('medicarePartDMemberId', '');
      setFieldValue('medicarePartDRxBin', '');
      setFieldValue('medicarePartDRxPcn', '');
      setFieldValue('medicarePartDRxGroup', '');
      setFieldValue('medicarePartDPremiumAmount', '');
      setFieldValue('medicarePartDPremiumPaymentFrequencyId', '');
      setFieldValue('medicarePartDComments', '');
    }
  }, [
    values.medicareStatusId, 
    values.medicarePartDStatusId,
    values.medicareCoverageType
  ]);

  useEffect(() => {
    if(!values.medicaidStatusId || values.medicaidStatusId === medicaidStatuses.find(s => s.itemKey === 'no_benefits').id) {
      setFieldValue('medicaidEffectiveDate', null);
      setFieldValue('medicaidMCOorHMOPlan', '');
      setFieldValue('medicaidCarrierName', '');
      setFieldValue('medicaidHealthPlanId', '');
      setFieldValue('medicaidPlanName', '');
      setFieldValue('medicaidIdNumber', '');
      setFieldValue('medicaidCategory', '');
      setFieldValue('medicaidQualifierCode', '');
      setFieldValue('medicaidRxBin', '');
      setFieldValue('medicaidRxPcn', '');
      setFieldValue('medicaidRxGroup', '');
      setFieldValue('medicaidComments', '');
    }

    if(!values.medicaidMCOorHMOPlan) {
      setFieldValue('medicaidCarrierName', '');
      setFieldValue('medicaidHealthPlanId', '');
      setFieldValue('medicaidPlanName', '');
      setFieldValue('medicaidRxBin', '');
      setFieldValue('medicaidRxPcn', '');
      setFieldValue('medicaidRxGroup', '');
    }

  }, [values.medicaidStatusId, values.medicaidMCOorHMOPlan]);

  return values && existingClientConfig && (
    <Stack direction="row">
      <LotusForm>
        <LotusFormSection name="Private Insurance" disabled={disabled}>
          <LotusFormItem>
            {privateInsuranceStatusList &&
              <LotusSelect
                name="privateInsuranceStatusId"
                label="Private Insurance Status"
                items={privateInsuranceStatusList}
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            }
          </LotusFormItem>
          {values.privateInsuranceStatusId && privateInsuranceStatuses && values.privateInsuranceStatusId !== privateInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
            <>
              <HealthPlanPanel
                effectiveDateFieldName={`privateInsuranceEffectiveDate`}
                policySourceIdFieldName={`privateInsurancePolicySourceId`}
                policySources={privateInsuranceSourceList}
                carrierFieldName={`privateInsuranceCarrierName`}
                planIdFieldName={`privateInsuranceHealthPlanId`}
                planNameFieldName={`privateInsurancePlanName`}
                mentalHealthCoverageFieldName={`privateInsuranceMentalHealthCoverage`}
                pharmacyCoverageFieldName={`privateInsurancePharmacyCoverage`}
                substanceAbuseCoverageFieldName={`privateInsuranceSubstanceAbuseCoverage`}
                medicalCoverageFieldName={`privateInsuranceMedicalCoverage`}
                existingClientConfig={existingClientConfig}
                disabled={disabled}
                effectiveDateRequired={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.privateInsuranceStatusId)}
                carrierAndPlanRequired={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.privateInsuranceStatusId)}
                dontCheckRequiredFields
              />
              {values.privateInsuranceStatusId && privateInsuranceStatuses && values.privateInsuranceStatusId === privateInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
                <>
                  <LotusFormItem>
                    <LotusDatePicker
                      name="privateInsuranceCobraStartDate"
                      label="COBRA Start Date"
                      disabled={disabled}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusDatePicker
                      name="privateInsuranceCobraEndDate"
                      label="COBRA End Date"
                      disabled={disabled}
                    />
                  </LotusFormItem>
                </>
              }
              <LotusFormItems>
                <LotusTextInput
                  name="privateInsuranceMemberId"
                  label="Private Member ID"
                  disabled={disabled}
                  maxLength={30}
                />
                <LotusTextInput
                  name="privateInsurancePremiumAmount"
                  label="Premium Amount"
                  disabled={disabled}
                  maxLength={16}
                  floatingPoint
                  adornment="$"
                  required
                  dontCheckRequiredFields
                />
                {premiumPaymentFrequencyList &&
                  <LotusSelect
                    name="privateInsurancePremiumPaymentFrequencyId"
                    label="Premium Payment Frequency"
                    items={premiumPaymentFrequencyList}
                    disabled={disabled}
                    required
                    dontCheckRequiredFields
                  />
                }
                {isPolicySourceAca(values.privateInsurancePolicySourceId) &&
                  <>
                    <LotusTrueFalseRadioGroup
                      name="privateInsuranceHasACATaxCredit"
                      label="Is the client receiving ACA tax credit?"
                      disabled={disabled}
                      required
                      dontCheckRequiredFields
                    />
                    {values.privateInsuranceHasACATaxCredit && 
                      <LotusFormItem style={{marginTop: 20}} lastOne>
                        <LotusTextInput
                          name="privateInsuranceACATaxCreditAmount"
                          label="Tax Credit Amount"
                          disabled={disabled}
                          maxLength={16}
                          floatingPoint
                          adornment="$"
                          required
                          dontCheckRequiredFields
                        />
                      </LotusFormItem>
                    }
                    {!values.privateInsuranceHasACATaxCredit && 
                      <LotusFormItem style={{marginTop: 20}} lastOne>
                        <LotusTextInput
                          name="privateInsuranceNoACATaxCreditReason"
                          label="Reason"
                          disabled={disabled}
                          maxLength={500}
                          multiline
                          rows={5}
                          required
                          dontCheckRequiredFields
                        />
                      </LotusFormItem>
                    }
                  </>
                }
                <LotusTextInput
                  name="privateInsuranceMaxOutOfPocketAmount"
                  label="Insurance Max out of pocket amount"
                  disabled={disabled}
                  maxLength={16}
                  floatingPoint
                  adornment="$"
                />
                <LotusTextInput
                  name="privateInsurancePolicyHolderName"
                  label="Policy Holder First and Last Name"
                  disabled={disabled}
                  maxLength={75}
                  required={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.privateInsuranceStatusId)}
                  dontCheckRequiredFields
                />
                <LotusTrueFalseSelect
                  name="privateInsuranceFamilyPlan"
                  label="Family Plan?"
                  disabled={disabled}
                />
                {values.privateInsuranceFamilyPlan &&
                  <LotusTrueFalseSelect
                    name="privateInsuranceAllFamilyMembers"
                    label="Are all family members on the plan?"
                    disabled={disabled}
                  />
                }
              </LotusFormItems>
              {values.privateInsurancePharmacyCoverage === true &&
                <LotusFormItems>
                  <LotusTrueFalseSelect
                    name="privateInsuranceScripDiscountCardOnly"
                    label="Does the plan offer a prescription discount card only?"
                    disabled={disabled}
                  />
                  <LotusTrueFalseSelect
                    name="privateInsuranceHivMeds"
                    label="Does the insurance plan cover HIV medications?"
                    disabled={disabled}
                  />
                  <LotusTextInput
                    name="privateInsuranceRxBin"
                    label="RX BIN"
                    disabled={disabled}
                    maxLength={30}
                  />
                  <LotusTextInput
                    name="privateInsuranceRxPcn"
                    label="RX PCN"
                    disabled={disabled}
                    maxLength={30}
                  />
                  <LotusTextInput
                    name="privateInsuranceRxGroup"
                    label="RX Group"
                    disabled={disabled}
                    maxLength={30}
                  />
                </LotusFormItems>
              }
              <LotusFormItems>
                <LotusTrueFalseSelect
                  name="privateInsuranceHasCap"
                  label="Does the insurance plan have a cap?"
                  disabled={disabled}
                />
                {values.privateInsuranceHasCap &&
                  <LotusTextInput
                    name="privateInsuranceCapAmount"
                    label="Insurance cap amount"
                    disabled={disabled}
                    maxLength={30}
                    floatingPoint
                    adornment="$"
                  />
                } 
                {eligibilityAppConfig.showPrivateInsurancePremiumOverdueAmount &&
                  <LotusTextInput
                    name="privateInsurancePremiumOverdueAmount"
                    label="Premium Overdue Amount"
                    disabled={disabled}
                    maxLength={16}
                    floatingPoint
                    adornment="$"
                  />
                }
                {eligibilityAppConfig.showPrivateInsurancePremiumOverdueMonths &&
                  <LotusTextInput
                    name="privateInsurancePremiumOverdueMonths"
                    label="Premium Overdue Months"
                    disabled={disabled}
                    maxLength={50}
                    integer
                  />
                }
                <LotusTextInput
                  name={`privateInsuranceComments`}
                  label="Private Insurance Comments"
                  disabled={disabled}
                  maxLength={500}
                  multiline
                  rows={5}
                />
              </LotusFormItems>
            </>
          }
        </LotusFormSection>
        {existingClientConfig.showDentalInsurance &&
          <LotusFormSection name="Dental Insurance" disabled={disabled}>
            {dentalInsuranceStatusList &&
              <LotusFormItem>
                <LotusSelect
                  name="dentalInsuranceStatusId"
                  label="Dental Insurance Status"
                  items={dentalInsuranceStatusList}
                  disabled={disabled}
                />
              </LotusFormItem>
            }
            {values.dentalInsuranceStatusId && dentalInsuranceStatuses && values.dentalInsuranceStatusId !== dentalInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
              <>
                <HealthPlanPanel
                  effectiveDateFieldName={`dentalInsuranceEffectiveDate`}
                  carrierFieldName={`dentalInsuranceCarrierName`}
                  planIdFieldName={`dentalInsuranceHealthPlanId`}
                  planNameFieldName={`dentalInsurancePlanName`}
                  disabled={disabled}
                  useHealthPlanDb={existingClientConfig.dentalUsesHealthPlanDatabase}
                  planType='DEN'
                  effectiveDateRequired={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.dentalInsuranceStatusId)}
                  carrierAndPlanRequired={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.dentalInsuranceStatusId)}
                  dontCheckRequiredFields
                />
                {values.dentalInsuranceStatusId && dentalInsuranceStatuses && values.dentalInsuranceStatusId === dentalInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
                  <>
                    <LotusFormItem>
                    <LotusDatePicker
                      name="dentalInsuranceCobraStartDate"
                      label="Dental COBRA Start Date"
                      disabled={disabled}
                    />
                    </LotusFormItem>
                    <LotusFormItem>
                    <LotusDatePicker
                      name="dentalInsuranceCobraEndDate"
                      label="Dental COBRA End Date"
                      disabled={disabled}
                    />
                    </LotusFormItem>
                  </>
                }
                <LotusFormItems>
                  <LotusTextInput
                    name="dentalInsuranceMemberId"
                    label="Member ID"
                    disabled={disabled}
                    maxLength={30}
                    required={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.dentalInsuranceStatusId)}
                    dontCheckRequiredFields
                  />
                  <LotusTextInput
                    name="dentalInsuranceBillingId"
                    label="Billing ID"
                    disabled={disabled}
                    maxLength={30}
                  />
                  <LotusTextInput
                    name="dentalInsurancePremiumAmount"
                    label="Dental Premium Amount"
                    disabled={disabled}
                    maxLength={30}
                    floatingPoint
                    adornment="$"
                    required
                    dontCheckRequiredFields
                  />
                  {premiumPaymentFrequencyList &&
                    <LotusSelect
                      name="dentalInsurancePremiumPaymentFrequencyId"
                      label="Premium Payment Frequency"
                      items={premiumPaymentFrequencyList}
                      disabled={disabled}
                      required
                      dontCheckRequiredFields
                    />
                  }
                  <LotusTextInput
                    name="dentalInsuranceComments"
                    label="Dental Insurance Comments"
                    disabled={disabled}
                    maxLength={500}
                    multiline
                    rows={5}
                  />
                </LotusFormItems>
              </>
            }
          </LotusFormSection>
        }
        {existingClientConfig.showVisionInsurance &&
          <LotusFormSection name="Vision Insurance" disabled={disabled}>
            <LotusFormItem>
              {visionInsuranceStatusList &&
                <LotusSelect
                  name="visionInsuranceStatusId"
                  label="Vision Insurance Status"
                  items={visionInsuranceStatusList}
                  disabled={disabled}
                />
              }
            </LotusFormItem>
            {values.visionInsuranceStatusId && visionInsuranceStatuses && values.visionInsuranceStatusId !== visionInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
              <>
                <HealthPlanPanel
                  effectiveDateFieldName={`visionInsuranceEffectiveDate`}
                  carrierFieldName={`visionInsuranceCarrierName`}
                  planIdFieldName={`visionInsuranceHealthPlanId`}
                  planNameFieldName={`visionInsurancePlanName`}
                  disabled={disabled}
                  useHealthPlanDb={existingClientConfig.visionUsesHealthPlanDatabase}
                  planType='VIS'
                  effectiveDateRequired={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.visionInsuranceStatusId)}
                  carrierAndPlanRequired={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.visionInsuranceStatusId)}
                  dontCheckRequiredFields
                />
                {values.visionInsuranceStatusId && visionInsuranceStatuses && values.visionInsuranceStatusId === visionInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
                  <>
                    <LotusFormItem>
                      <LotusDatePicker
                        name="visionInsuranceCobraStartDate"
                        label="Vision COBRA Start Date"
                        disabled={disabled}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusDatePicker
                        name="visionInsuranceCobraEndDate"
                        label="Vision COBRA End Date"
                        disabled={disabled}
                      />
                    </LotusFormItem>
                  </>
                }
                <LotusFormItems>
                  <LotusTextInput
                    name="visionInsuranceMemberId"
                    label="Member ID"
                    disabled={disabled}
                    maxLength={30}
                    required={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(values.visionInsuranceStatusId)}
                  />
                  <LotusTextInput
                    name="visionInsuranceBillingId"
                    label="Billing ID"
                    disabled={disabled}
                    maxLength={30}
                  />
                  <LotusTextInput
                    name="visionInsurancePremiumAmount"
                    label="Vision Premium Amount"
                    disabled={disabled}
                    maxLength={30}
                    floatingPoint
                    adornment="$"
                    required
                    dontCheckRequiredFields
                  />
                  {premiumPaymentFrequencyList &&
                    <LotusSelect
                      name="visionInsurancePremiumPaymentFrequencyId"
                      label="Premium Payment Frequency"
                      items={premiumPaymentFrequencyList}
                      disabled={disabled}
                      required
                      dontCheckRequiredFields
                    />
                  }
                </LotusFormItems>
              </>
            }
          </LotusFormSection>
        }
        <LotusFormSection name="Medicare Part A, B, C or D" disabled={disabled}>
          <LotusFormItem>
            {medicareStatusList &&
              <LotusSelect
                name="medicareStatusId"
                label="Medicare Status"
                items={medicareStatusList}
                disabled={disabled}
              />
            }
          </LotusFormItem>
          {values.medicareStatusId && medicareStatuses && values.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id &&
            <>
              <LotusFormItems>
                {medicareCoverageTypesList &&
                  <LotusSelect
                    name={`medicareCoverageType`}
                    label="Medicare Coverage Type"
                    items={medicareCoverageTypesList}
                    disabled={disabled}
                    required
                    dontCheckRequiredFields
                  />
                }
              </LotusFormItems>
              {values.medicareCoverageType &&
                <>
                  <HealthPlanPanel
                    effectiveDateFieldName={`medicareEffectiveDate`}
                    carrierFieldName={`medicareCarrierName`}
                    planIdFieldName={`medicareHealthPlanId`}
                    planNameFieldName={`medicarePlanName`}
                    medicareCompanyPlanFieldStatus={isMedicareCoverageTypeC(medicareCoverageTypes, values.medicareCoverageType)}
                    disabled={disabled}
                    useHealthPlanDb={existingClientConfig.medicareUsesHealthPlanDatabase}
                    planType='MEDS'
                    effectiveDateRequired={true}
                    carrierAndPlanRequired={true}
                    dontCheckRequiredFields
                  />
                  <LotusFormItems>
                    <LotusTextInput
                      name="medicareMemberId"
                      label="Medicare Member ID"
                      disabled={disabled}
                      maxLength={30}
                    />
                    {isMedicareCoverageTypeC(medicareCoverageTypes, values.medicareCoverageType) && 
                      <>
                        <LotusFormItem>
                          <LotusTextInput
                            name="medicarePremiumAmount"
                            label="Premium Amount"
                            disabled={disabled}
                            maxLength={16}
                            floatingPoint
                            adornment="$"
                          />
                        </LotusFormItem>
                        {premiumPaymentFrequencyList &&
                          <LotusFormItem>
                            <LotusSelect
                              name="medicarePremiumPaymentFrequencyId"
                              label="Premium Payment Frequency"
                              items={premiumPaymentFrequencyList}
                              disabled={disabled}
                            />
                          </LotusFormItem>
                        }
                        <LotusTrueFalseSelect
                          name="medicareIncludesPharmacyBenefit"
                          label="Includes Pharmacy Benefit?"
                          disabled={disabled}
                        />
                      </>
                    }
                    {existingClientConfig.showMedicarePolicyHolder &&
                      <LotusTextInput
                        name="medicarePrimaryPolicyHolderName"
                        label="Primary Policy Holder First and Last Name"
                        disabled={disabled}
                        maxLength={75}
                      />
                    }
                  {isMedicareCoverageTypeC(medicareCoverageTypes, values.medicareCoverageType) && existingClientConfig.showMedicareRx &&
                    <>
                      <LotusFormItem>
                        <LotusTextInput
                          name="medicareRxBin"
                          label="RX BIN"
                          disabled={disabled}
                          maxLength={30}
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusTextInput
                          name="medicareRxPcn"
                          label="RX PCN"
                          disabled={disabled}
                          maxLength={30}
                        />
                      </LotusFormItem>
                      <LotusFormItem lastOne>
                        <LotusTextInput
                          name="medicareRxGroup"
                          label="RX Group"
                          disabled={disabled}
                          maxLength={30}
                        />
                      </LotusFormItem>
                    </>
                  }
                  </LotusFormItems>
                    {isMedicareCoverageTypeC(medicareCoverageTypes, values.medicareCoverageType) &&
                      <LotusFormItems>
                          <LotusTextInput
                            name="medicarePremiumOverdueAmount"
                            label="Premium Overdue Amount"
                            disabled={disabled}
                            maxLength={16}
                            floatingPoint
                            adornment="$"
                          />
                          <LotusTextInput
                            name="medicarePremiumOverdueMonths"
                            label="Premium Overdue Months"
                            disabled={disabled}
                            maxLength={50}
                            integer
                          />
                      </LotusFormItems>
                    }
                    <LotusFormItem>
                    <LotusTextInput
                      name="medicareComments"
                      label="Medicare Comments"
                      disabled={disabled}
                      maxLength={500}
                      multiline
                      rows={5}
                    />
                    </LotusFormItem>
                  <LotusFormItem>
                    {medicarePartDStatusList &&
                      <LotusSelect
                        name="medicarePartDStatusId"
                        label="Medicare Part D Status"
                        items={medicarePartDStatusList}
                        disabled={disabled}
                      />
                    }
                  </LotusFormItem>
                  {values.medicarePartDStatusId && medicarePartDStatuses && values.medicarePartDStatusId !== medicarePartDStatuses.find(s => s.itemKey === 'no_benefits').id &&
                    <>
                      <HealthPlanPanel
                        effectiveDateFieldName={`medicarePartDEffectiveDate`}
                        carrierFieldName={`medicarePartDCarrierName`}
                        planIdFieldName={`medicarePartDHealthPlanId`}
                        planNameFieldName={`medicarePartDPlanName`}
                        disabled={disabled}
                        useHealthPlanDb={existingClientConfig.medicarePartDUsesHealthPlanDatabase}
                        planType='MEDD'
                        effectiveDateRequired={true}
                        carrierAndPlanRequired={false}
                      />
                      <LotusFormItems>
                        <LotusTextInput
                          name="medicarePartDMemberId"
                          label="Medicare Member ID"
                          disabled={disabled}
                          maxLength={11}
                        />
                        <LotusTextInput
                          name="medicarePartDRxBin"
                          label="RX BIN"
                          disabled={disabled}
                          maxLength={30}
                        />
                        <LotusTextInput
                          name="medicarePartDRxPcn"
                          label="RX PCN"
                          disabled={disabled}
                          maxLength={30}
                        />
                        <LotusTextInput
                          name="medicarePartDRxGroup"
                          label="RX Group"
                          disabled={disabled}
                          maxLength={30}
                        />
                        <LotusTextInput
                          name="medicarePartDPremiumAmount"
                          label="Medicare Part D Premium Amount"
                          disabled={disabled}
                          maxLength={16}
                          floatingPoint
                          adornment="$"
                        />
                        {premiumPaymentFrequencyList &&
                          <LotusSelect
                            name="medicarePartDPremiumPaymentFrequencyId"
                            label="Medicare Part D Premium Payment Frequency"
                            items={premiumPaymentFrequencyList}
                            disabled={disabled}
                          />
                        }
                        <LotusTextInput
                          name="medicarePartDComments"
                          label="Medicare Part D Comments"
                          disabled={disabled}
                          maxLength={500}
                          multiline
                          rows={5}
                        />
                      </LotusFormItems>
                    </>
                  }
                </>
              }
            </>
          }
        </LotusFormSection>
        <LotusFormSection name="Medicaid" disabled={disabled}>
          <LotusFormItem>
            <LotusTrueFalseRadioGroup
              name="medicaidCheck"
              label="Medicaid Checked?"
              disabled={disabled}
              required
              dontCheckRequiredFields
              onValueChange={onMedicaidCheckChanged}
            />
          </LotusFormItem>
          {values.medicaidCheck &&
            <>
              <LotusFormItems>
                <LotusDatePicker
                  name="medicaidLastVerificationCompletedDate"
                  label="Date Last Verification Completed"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
                {medicaidStatusList &&
                  <LotusSelect
                    name="medicaidStatusId"
                    label="Medicaid Status"
                    items={medicaidStatusList}
                    disabled={disabled}
                  />
                }
              </LotusFormItems>
              {values.medicaidStatusId && medicaidStatuses && values.medicaidStatusId !== medicaidStatuses.find(s => s.itemKey === 'no_benefits').id &&
                <>
                  <HealthPlanPanel
                    effectiveDateFieldName={`medicaidEffectiveDate`}
                    carrierFieldName={`medicaidCarrierName`}
                    planIdFieldName={`medicaidHealthPlanId`}
                    planNameFieldName={`medicaidPlanName`}
                    medicaidPlanFieldName={`medicaidMCOorHMOPlan`}
                    medicaidPanel={true}  
                    disabled={disabled}
                    useHealthPlanDb={existingClientConfig.medicaidUsesHealthPlanDatabase}
                    planType='MEDI'
                  />
                  <LotusFormItems>
                    <LotusTextInput
                      name="medicaidIdNumber"
                      label="Medicaid ID Number"
                      disabled={disabled}
                      maxLength={13}
                    />
                    {medicaidCategoryList &&
                      <LotusSelect
                        name="medicaidCategory"
                        label="Medicaid Category"
                        items={medicaidCategoryList}
                        disabled={disabled}
                        autoSort={false}
                      />
                    }
                    <LotusTextInput
                      name="medicaidQualifierCode"
                      label="Medicaid Qualifier Code"
                      disabled={disabled}
                      maxLength={30}
                    />
                  </LotusFormItems>
                  {values.medicaidMCOorHMOPlan && existingClientConfig.showMedicaidRx &&
                    <LotusFormItems>
                      <LotusTextInput
                        name="medicaidRxBin"
                        label="RX BIN"
                        disabled={disabled}
                        maxLength={30}
                      />
                      <LotusTextInput
                        name="medicaidRxPcn"
                        label="RX PCN"
                        disabled={disabled}
                        maxLength={30}
                      />
                      <LotusTextInput
                        name="medicaidRxGroup"
                        label="RX Group"
                        disabled={disabled}
                        maxLength={30}
                      />
                    </LotusFormItems>
                  }
                  <LotusFormItems>
                    <LotusTextInput
                      name="medicaidComments"
                      label="Medicaid Comments"
                      disabled={disabled}
                      maxLength={500}
                      multiline
                      rows={5}
                    />
                  </LotusFormItems>
                </>
              }
            </>
          }
        </LotusFormSection>
        {existingClientConfig.showBenefitSubsidies &&
          <LotusFormSection name="Subsidies" disabled={disabled}>
            <LotusFormItems>
              {medicareLowIncomeSubsidyStatusList &&
                <LotusSelect
                  name="medicareLowIncomeSubsidyStatusId"
                  label="Medicare Low Income Subsidy Status"
                  items={medicareLowIncomeSubsidyStatusList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
              {medicareLowIncomeSubsidyTypeList &&
                <LotusSelect
                  name="medicareLowIncomeSubsidyTypeId"
                  label="Medicare Low Income Subsidy Type"
                  items={medicareLowIncomeSubsidyTypeList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
              {fullLowIncomeSubsidyStatusList &&
                <LotusSelect
                  name="fullLowIncomeSubsidyStatusId"
                  label="Full Low Income Subsidy Status"
                  items={fullLowIncomeSubsidyStatusList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
              {vaHealthBenefitsStatusList &&
                <LotusSelect
                  name="vaHealthBenefitsStatusId"
                  label="VA Health Benefits Status"
                  items={vaHealthBenefitsStatusList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
              {ihsHealthBenefitsStatusList &&
                <LotusSelect
                  name="ihsHealthBenefitsStatusId"
                  label="IHS Health Benefits Status"
                  items={ihsHealthBenefitsStatusList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
            </LotusFormItems>
          </LotusFormSection>
        }
        <LotusFormSection name="Documentation" disabled={disabled}>
          <LotusFormItem>
            <LotusSpacedBlock>
              <div>Upload Proof of Insurance Documentation</div>
            </LotusSpacedBlock>
            <ClientInfoDocumentation
              documentationListFieldName="insuranceDocumentation"
              allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoInsuranceDocumentationTypes}
              clientId={clientId}
              disabled={disabled}
            />
          </LotusFormItem>
        </LotusFormSection>
      </LotusForm>
      <div>
        <SectionCommentsChain chainFieldName="insuranceCommentsChain"/>
      </div>
    </Stack>
  );
};

export function buildInsuranceStaticForm({...props}) {
  return (
      <EligibilityApplicationInsuranceStaticForm {...props}/>
  );
}