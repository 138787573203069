import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientSearchContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";

const initialState = {
  clients: undefined,
  clientFilters: undefined
};

const ClientSearchContext = createContext({defaultValue: initialState});

export const ClientSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientSearchContext.Provider>
  );
};

export const useClientSearch = () => {
  const { state, dispatch } = useContext(ClientSearchContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadClientsSync = async ({
    assignedToUserOnly,
    offset,
    limit,
    searchText,
    searchTextColumns,
    sortOrder,
    filters,
    searchAcrossGrantee
  }) => {
    try {
      addBusyBee('loadClients');
      const url = `/api/clients`;
      const activeFilters = filters ? filters.filter((f) => f.value) : [];

      const { data } = await axios.post(url, {
        operationName: 'searchClients',
        assignedToUserOnly,
        offset,
        limit,
        searchText,
        searchFields: searchTextColumns,
        sortBy: sortOrder ? [sortOrder] : [],
        filters: activeFilters,
        searchAcrossGrantee
      });
      return parseApiResult(data).body;
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadClients');
    }
    return [];
  };

  const loadClients = async ({
    assignedToUserOnly,
    offset,
    limit,
    searchText,
    searchTextColumns,
    sortOrder,
    filters,
    searchAcrossGrantee
  }) => {
    try {
      const searchResult = await loadClientsSync({
        assignedToUserOnly,
        offset,
        limit,
        searchText,
        searchTextColumns,
        sortOrder,
        filters,
        searchAcrossGrantee
      });
      const filterResults = searchResult.filters;
      const filterResult = {
        pronouns: filterResults ? [...filterResults.pronouns].sort(
          (a, b) => {
            return a > b ? 1 : -1;
          }
        ) : [],
        zips: filterResults ? [...filterResults.zips].sort((a, b) => {
          return a > b ? 1 : -1;
        }) : []
      }

      dispatch({
        type: messageTypes.LOADING_CLIENTS_SUCCESS,
        payload: {
          clients: searchResult && searchResult.clients ? searchResult.clients : [],
          clientFilters: filterResult,
          offset,
          limit,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  return {
    ...state,
    loadClientsSync,
    loadClients
  };
};
