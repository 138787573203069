import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './DataImportContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';

const DataImportContext = createContext();

const initialState = {
  settings: undefined,
  importTypes: undefined,
  importRequests: undefined,
};

export const DataImportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataImportContext.Provider value={{ state, dispatch }}>
      {children}
    </DataImportContext.Provider>
  );
};

export const useDataImport = () => {
  const { state, dispatch } = useContext(DataImportContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const submitImportRequest = async (
    agencyId,
    importTypeId,
    fileName,
    description,
    displayFileName,
    delimiter,
    isTestMode
  ) => {
    if (agencyId) {
      try {
        addBusyBee('submitImportRequest');

        const toPost = {
          operationName: 'submitImportRequest',
          agencyId,
          fileName,
          importTypeId,
          description,
          displayFileName,
          delimiter,
          isTestMode
        };

        const url = `/api/import`;
        const { data } = await axios.post(url, toPost);
        const newRequest = parseApiResult(data).body;

        dispatch({
          type: messageTypes.SUBMIT_IMPORT_REQUEST_SUCCESS,
          payload: {
            submittedRequest: newRequest,
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('submitImportRequest');
      }
    }
  };

  const resubmitImportRequest = async (agencyId, requestId) => {
    if (agencyId) {
      try {
        addBusyBee('resubmitImportRequest');

        const toPost = {
          operationName: 'resubmitImportRequest',
          requestId,
          agencyId
        };

        const url = `/api/import`;
        const { data } = await axios.post(url, toPost);
        const newRequest = parseApiResult(data).body;

        dispatch({
          type: messageTypes.SUBMIT_IMPORT_REQUEST_SUCCESS,
          payload: {
            submittedRequest: newRequest,
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('resubmitImportRequest');
      }
    }
  };

  const revertImportedData = async (agencyId, requestId) => {
    if (agencyId) {
      try {
        addBusyBee('revertImportedData');

        const toPost = {
          operationName: 'revertImportedData',
          requestId,
          agencyId
        };

        const url = `/api/import`;
        const { data } = await axios.post(url, toPost);
        const result = parseApiResult(data).body;

      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('revertImportedData');
      }
    }
  };

  const getImportTypes = async () => {
    try {
      addBusyBee('getImportTypes');

      const toPost = {
        operationName: 'getImportTypes',
      };

      const url = `/api/import`;
      const { data } = await axios.post(url, toPost);
      const importTypes = parseApiResult(data).body || [];

      dispatch({
        type: messageTypes.GET_IMPORT_TYPES_SUCCESS,
        payload: {
          importTypes: importTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('getImportTypes');
    }
  };

  const getImportRequests = async (agencyId) => {
    try {
      addBusyBee('getImportRequests');

      const toPost = {
        operationName: 'getImportRequests',
        agencyId
      };

      const url = `/api/import`;
      const { data } = await axios.post(url, toPost);
      const importRequests = parseApiResult(data).body || [];

      dispatch({
        type: messageTypes.GET_IMPORT_REQUESTS_SUCCESS,
        payload: {
          importRequests: importRequests,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('getImportRequests');
    }
  };

  if (state) {
    return {
      ...state,
      submitImportRequest,
      getImportTypes,
      getImportRequests,
      resubmitImportRequest,
      revertImportedData
    };
  }

  return {};
};
