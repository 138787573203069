import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function LotusShowHideButton({
  isInitiallyShowing = false,
  showText,
  showIcon,
  hideText,
  hideIcon,
  onShow,
  onHide,
}) {
  const [showing, setShowing] = useState(isInitiallyShowing || false);

  return (
    <Button
      onClick={() => {showing ? (onHide ? onHide() : null) : (onShow ? onShow() : null); setShowing(!showing); }}
      variant="text"
      endIcon={showing ? (hideIcon || <ExpandLessIcon/>) : (showIcon || <ExpandMoreIcon/>)}
    >
      {showing ? (hideText || 'Hide Details') : (showText || 'Show Details') }
    </Button>
  );
}