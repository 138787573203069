import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { GranteeAgencyMedicationDispensesView } from "./GranteeAgencyMedicationDispensesView";

export default function GranteeAgencyMedicationDispenses() {

  const {  agency, updateAgencyMedicationDispensesConfig } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  const saveMedicationDispensesConfig = async (values) => {
    await updateAgencyMedicationDispensesConfig(values);
    await loadUserAgency();
  };

  return agency && (
    <GranteeAgencyMedicationDispensesView
      medicationDispensesConfig={agency.medicationDispensesConfig}
      handleSave={saveMedicationDispensesConfig}
      handleCancel={() => {}} // staying on page, just reverting changes
    />
  );
}