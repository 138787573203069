import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { GranteeAgencyPremiumsPaidView } from "./GranteeAgencyPremiumsPaidView";

export default function GranteeAgencyPremiumsPaid() {

  const {  agency, updateAgencyPremiumsPaidConfig } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  const savePremiumsPaidConfig = async (values) => {
    await updateAgencyPremiumsPaidConfig(values);
    await loadUserAgency();
  };

  return agency && (
    <GranteeAgencyPremiumsPaidView
      premiumsPaidConfig={agency.premiumsPaidConfig}
      handleSave={savePremiumsPaidConfig}
      handleCancel={() => {}} // staying on page, just reverting changes
    />
  );
}