import React, {useState} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FieldArray, ErrorMessage } from 'formik';
import LotusCard from 'components/widgets/Layout/LotusCard';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import {Grid, Stack} from '@mui/material';
import LotusCardList from 'components/widgets/Layout/LotusCardList';

export default function EnrollmentStatusLinker({
  values,
  touched,
  errors,
  newStatusTemplate,
  handleChange,
  setFieldValue
}) {
  const [showRemoveMsg, setShowRemoveMsg] = useState(false);

  return (
      <FieldArray name="enrollmentStatuses">
        {({ push, remove }) => {
          const moveUp = (indexToMove) => {
            const newList = [];
            values.enrollmentStatuses.forEach((es, idx) => {
              if (idx === indexToMove) {
                return;
              }
              else if (idx+1 === indexToMove) {
                newList.push(values.enrollmentStatuses[indexToMove]);
                newList.push(es);
              }
              else {
                newList.push(es);
              }
            });
            setFieldValue('enrollmentStatuses', newList);
          };
          const moveDown = (indexToMove) => {
            const newList = [];
            values.enrollmentStatuses.forEach((es, idx) => {
              if (idx === indexToMove) {
                newList.push(values.enrollmentStatuses[indexToMove+1]);
                newList.push(es);
              }
              else if (idx === indexToMove+1) {
                return;
              }
              else {
                newList.push(es);
              }
            });

            setFieldValue('enrollmentStatuses', newList);
          };
          const removeStatusFromTransitions = (indexToRemove) => {
            const id = values.enrollmentStatuses[indexToRemove].id;
            values.enrollmentStatuses.forEach((es, idx) => {
              if (idx !== indexToRemove) {
                const foundIndex = es.allowedTransitionStatusIds.indexOf(id);
                if (foundIndex > -1) {
                  es.allowedTransitionStatusIds.splice(foundIndex, 1);
                }
              }
            });

            setFieldValue('enrollmentStatuses', values.enrollmentStatuses);
          }
          return (
          <>
            <div>Enrollment Statuses for Program</div>
            {showRemoveMsg && 
              <LotusFormItem style={{color:'#FFA500'}}>
                <div>Any client enrollments with a status that has been removed with be set to the default initial status</div>
              </LotusFormItem>
            }
            <LotusFormItem style={{color:'#b53f3f'}}>
              <ErrorMessage name="enrollmentStatusErrors" />
            </LotusFormItem>
            <LotusFormItem extraWide>
              <LotusCardList>
                {values.enrollmentStatuses.length > 0 && values.enrollmentStatuses.map((status, index) => (
                    <LotusCard key={status.id || index}>
                      <div style={{ marginBottom: 8}}>
                        <LotusTextInput
                          name={`enrollmentStatuses.${index}.name`}
                          label="Name"
                        />
                      </div>
                      <Grid container>
                        <Grid item xs={12} style={{ marginBottom: 8}}>
                          <LotusSwitch
                            name={`enrollmentStatuses.${index}.isActiveStatusType`}
                            label="Is Active Status Type"
                            labelPlacement="start"
                          />
                        </Grid>
                        {!status.isActiveStatusType &&
                          <Grid container style={{ marginBottom: 8}}>
                            <Grid item xs={6}>
                              <LotusSwitch
                                name={`enrollmentStatuses.${index}.requireCloseReason`}
                                label="Require Close Reason"
                                labelPlacement="start"
                              />
                            </Grid>
                            {status.requireCloseReason &&
                            <Grid item xs={6}>
                              <LotusSwitch
                                name={`enrollmentStatuses.${index}.closeReasonUsesPicklist`}
                                label="Close Reason Uses Picklist"
                                labelPlacement="start"
                              />
                            </Grid>
                            }
                          </Grid>
                        }
                        <Grid container style={{ marginBottom: 8}}>
                          <Grid item xs={6}>
                            <LotusSwitch
                              name={`enrollmentStatuses.${index}.isDefaultInitialStatus`}
                              label="Is Default Initial Status"
                              labelPlacement="start"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <LotusSwitch
                              name={`enrollmentStatuses.${index}.allowDocumentationUpdates`}
                              label="Allow Documentation Updates"
                              labelPlacement="start"
                            />
                          </Grid>
                        </Grid>
                        {values.enrollmentStatuses &&
                          <Grid item xs={12} style={{ marginBottom: 8}}>
                            <LotusMultiSelect
                              label="Allowed Transition Statuses"
                              name={`enrollmentStatuses.${index}.allowedTransitionStatusIds`}
                              items={values.enrollmentStatuses.filter(es => es.id !== status.id).map(es => {return {label: es.name, value: es.id}})}
                              value={status.allowedTransitionStatusIds}
                              onChange={handleChange}
                            />
                          </Grid>
                        }
                        <Grid item xs={12}>
                          <Stack justifyContent="flex-end" direction="row" spacing={1}>
                            {index > 0 && <LotusButton variant={'outlined'} onClick={() => moveUp(index)}>Move Up</LotusButton>}
                            {(index < values.enrollmentStatuses.length - 1) && <LotusButton variant={'outlined'}  onClick={() => moveDown(index)}>Move Down</LotusButton>}
                            <LotusButton garbageIcon variant={'outlined'} onClick={() => {setShowRemoveMsg(true); removeStatusFromTransitions(index); remove(index);}}>Remove Status</LotusButton>
                          </Stack>
                        </Grid>
                      </Grid>
                    </LotusCard>
                ))}
              </LotusCardList>
            </LotusFormItem>
            <LotusButton plusIcon onClick={() => push({...newStatusTemplate, id: uuidv4()})}>Add Status</LotusButton>
          </>)}}
        </FieldArray>
  );
}
