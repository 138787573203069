import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientDocumentationContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  contacts: {}, // key by program id
  activityLogs: {}, // key by program id
  progressNotes: {}, // key by program id
  meetings: {}, // key by program id
};

const ClientDocumentationContext = createContext();

export const ClientDocumentationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ClientDocumentationContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientDocumentationContext.Provider>
  );
};

export const useClientDocumentation = () => {
  const { state, dispatch } = useContext(ClientDocumentationContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadContactsForClientAndProgram = async (clientId, programId) => {
    addBusyBee('loadContactsForClientAndProgram');

    try {
      const toPost = {
        operationName: 'getContactsForClientAndProgram',
        clientId: clientId, 
        programId: programId 
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const contacts = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_CONTACTS_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          docs: contacts,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadContactsForClientAndProgram');
    }
  };

  const loadMeetingsForClientAndProgram = async (clientId, programId) => {
    addBusyBee('loadMeetingsForClientAndProgram');

    try {
      const toPost = {
        operationName: 'getMeetingsForClientAndProgram',
        clientId: clientId, 
        programId: programId 
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const meetings = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_MEETINGS_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          docs: meetings,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadMeetingsForClientAndProgram');
    }
  };

  const loadActivityLogsForClientAndProgram = async (clientId, programId) => {
    
    addBusyBee('loadActivityLogsForClientAndProgram');

    try {
      
      const toPost = {
        operationName: 'getActivityLogsForClientAndProgram',
        clientId: clientId, 
        programId: programId 
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const activityLogs = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_ACTIVITY_LOGS_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          docs: activityLogs,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadActivityLogsForClientAndProgram');
    }
  };

  const loadProgressNotesForClientAndProgram = async (clientId, programId) => {

    addBusyBee('loadProgressNotesForClientAndProgram');

    try {

      const toPost = {
        operationName: 'getProgressNotesForClientAndProgram',
        clientId: clientId, 
        programId: programId
      };
      const { data } = await axios.post('/api/clientDocumentation', toPost);

      let progressNotesData = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PROGRESS_NOTES_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          docs: progressNotesData,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProgressNotesForClientAndProgram');
    }
  };

  const upsertActivityLog = async (activityLog) => {
    
    try {
      addBusyBee('upsertActivityLog');

      const toPost = {
        operationName: 'upsertClientProgramActivityLog',
        activityLog
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const newActivityLog = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_ACTIVITY_LOG_SUCCESS,
        payload: {
          programId: activityLog.programId,
          doc: newActivityLog,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertActivityLog');
    }
  };

  const upsertContact = async (contact) => {

    try {
      addBusyBee('upsertContact');

      const toPost = {
        operationName: 'upsertClientProgramContact',
        contact
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const newContact = parseApiResult(data).body;
      
      dispatch({
        type: messageTypes.UPSERTING_CLIENT_CONTACT_SUCCESS,
        payload: {
          programId: contact.programId,
          doc: newContact,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertContact');
    }
  };

  const upsertMeeting = async (meeting) => {
    try {
      addBusyBee('upsertMeeting');

      const toPost = {
        operationName: 'upsertClientProgramMeeting',
        meeting
      };
      const url = `/api/clientDocumentation`;
      const { data } = await axios.post(url, toPost);
      const newMeeting = parseApiResult(data).body;

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_MEETING_SUCCESS,
        payload: {
          programId: meeting.programId,
          doc: newMeeting,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertMeeting');
    }
  };

  const upsertProgressNote = async (progressNote) => {
    try {
      addBusyBee('upsertProgressNote');
 
      const toPost = {
        operationName: 'upsertClientProgramProgressNote',
        progressNote
      };

      const url = `/api/clientDocumentation`;
      const result = await axios.post(url, toPost);
      const progressNoteResult = parseApiResult(result.data).body;      

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_PROGRESS_NOTE_SUCCESS,
        payload: {
          programId: progressNote.programId,
          doc: progressNoteResult,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertProgressNote');
    }
  };

  return {
    ...state,
    loadContactsForClientAndProgram,
    loadMeetingsForClientAndProgram,
    loadActivityLogsForClientAndProgram,
    loadProgressNotesForClientAndProgram,
    upsertActivityLog,
    upsertContact,
    upsertMeeting,
    upsertProgressNote
  };
};
