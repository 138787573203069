import { Grid, Stack } from '@mui/material';
import React from 'react';

export default function LotusPageSubSection({ header, actions, ...props }) {

  return (
    <div>
      <Grid container alignItems="center" style={{marginTop: 16, marginBottom: 16}}>
        <Grid item xs={5}>
          <div style={{fontSize: 20, fontWeight: 500}}>{header}</div>
        </Grid>
        <Grid item xs={7}>
          <Stack justifyContent='flex-end' direction="row" spacing={1}>
            {actions}
          </Stack>
        </Grid>
      </Grid>
      {props.children}
    </div>
  );
}
