import React from 'react';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusItemList from '../Forms/LotusItemList';

export default function IncomeSamplesPanel({ incomeSamplesFieldName, itemName, label, disabled, required, dontCheckRequiredFields }) {

  const emptyIncomeSample = {
    amount: '',
    sampleDate: null,
  };

  function buildIncomeSamplesPanel(incomeSample, index, disabled) {
    return (
      <div style={{padding: 8}}>
        <div style={{fontSize: 18, fontWeight: 400, marginBottom: 16}}>{itemName} {index + 1}</div>
        <div style={{marginBottom: 8}}>
          <LotusTextInput
            disabled={disabled}
            dontCheckRequiredFields={dontCheckRequiredFields}
            name={`${incomeSamplesFieldName}.${index}.amount`}
            label="Amount"
            floatingPoint
            adornment="$"
            required={required}
          />
        </div>
        <LotusDatePicker
          disabled={disabled}
          dontCheckRequiredFields={dontCheckRequiredFields}
          name={`${incomeSamplesFieldName}.${index}.sampleDate`}
          label="Date"
          required={required}
        />
      </div>
    );
  }

  return (
    <LotusItemList
      disabled={disabled} 
      name={incomeSamplesFieldName}
      itemName={itemName}
      label={label}
      buildItemDetail={(item, index, isDisabled) => buildIncomeSamplesPanel(item, index, isDisabled)}
      buildItemTemplate={() => {return {...emptyIncomeSample}}}
      isSubList={true}
    />
  );
}