import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import ConsentTypeLinker from '../linkers/ConsentTypeLinker';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramConsentTypesForm({ handleSubmit }) {

  const { currentProgram, consentTypeIds, loadProgramConsentTypes } = useProgramManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (currentProgram && !consentTypeIds) {
      loadProgramConsentTypes();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (consentTypeIds) {
      setInitialValues({
        selectedConsentTypes: consentTypeIds || []
      });
    }
  }, [consentTypeIds]);

  return (
    <LotusPageSection header='Consent Types'>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <ConsentTypeLinker
                    values={values}
                    handleChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusButton color="primary" variant="contained" type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
