import React from 'react';
import 'index.css';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import CssBaseline from "@mui/material/CssBaseline";
import '@lotus/components/style.css';
import { theme } from '@lotus/components';
import '@lotus/components/globals.css'
import { PropertiesProvider } from 'contexts/PropertiesContext';
import { AppStatusProvider } from 'contexts/AppStatusContext';
import AppRouter from './router';
import AppStatus from 'components/AppStatus';
import { ToastContainer } from 'react-toastify';

const initialize = () => {
  const root = createRoot(document.getElementById('root'));

  root.render(
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AppStatusProvider>
          <AppStatus />
          <ToastContainer />
          <PropertiesProvider>
            <AppRouter />
          </PropertiesProvider>
        </AppStatusProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

initialize();
