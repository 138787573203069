const ELIGIBILITY_APPLICATION_STATUS = Object.freeze({
  // Eligibility Application status as eligibility_application_statuses table
  NOT_STARTED: 'not_started',
  STARTED: 'started',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  IN_REVIEW: 'in_review',
  SUBMITTED: 'submitted',
  PENDING: 'pending',
  COMPLETED: 'completed',
  TERMINATED: 'terminated',
  RW_REVIEW_COMPLETED: 'rw_review_completed'
});

const TIMELINE_STEP = Object.freeze({
  APPLICATION_STEP: 'application',
  REVIEW_STEP: 'review',
  ELIGIBILITY_STEP: 'eligibility',
  RECERT_STEP: 'recert',
  EXPIRE_STEP: 'expiration',
  RW_REVIEW_STEP: 'rw_review',
  ADAP_REVIEW_STEP: 'adap_review'
});

// Timeline icon and text colors for different status
const TIMELINE_COLOR = Object.freeze({
  ACTIVE_GREY: '#294055',
  ACTIVE_STEP: 'primary',
  ACTIVE_ORANGE: 'darkorange',
  ACTIVE_RED: 'red',
  LIGHT_GREY: 'lightgrey',
  GREY: 'grey',
  BLUE_TEXT: '#42a5f5',
  WHITE: 'white',
  BLACK: 'black'
});

export { ELIGIBILITY_APPLICATION_STATUS, TIMELINE_STEP, TIMELINE_COLOR };