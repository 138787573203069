import { Grid } from '@mui/material';
import React from 'react';

export default function LotusFormItem({extraWide, lastOne, indent, style, ...props }) {
  return (
    <Grid item xs={12} style={{ marginBottom: lastOne ? 0 : 24, marginLeft: indent ? 40*indent : 0, ...style }}>
      <div style={extraWide ? {width: 800} : {}}>
        {props.children}
      </div>
    </Grid>
  );
}
