import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import GranteeAgencyAssessmentsView from "./GranteeAgencyAssessmentsView";
import GranteeAgencyDocumentTypesView from "./GranteeAgencyDocumentTypesView";
import GranteeAgencyProviderTypesView from "./GranteeAgencyProviderTypesView";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

export function GranteeAgencyCareManagementView({
  assessments,
  assessmentTypes,
  documentTypes,
  providerTypes,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (assessments && documentTypes && providerTypes) {
      let initAssessments = assessments.map(a => {
        return {
          id: a.id,
          name: a.name || '',
          assessmentTypeId: a.assessmentTypeId || '',
          expirationReminderDays: a.expirationReminderDays || '',
          assessmentTimeCycleDays: a.assessmentTimeCycleDays || '',
          waitTimeToReassessmentDays: a.waitTimeToReassessmentDays || '',
          startedAssessmentExpirationHours: a.startedAssessmentExpirationHours || ''
        }
      });

      setInitialValues({
        assessments: initAssessments,
        documentTypes: documentTypes,
        newDocumentType: '',
        newDocumentTypes: [],
        providerTypes: providerTypes.map(
          (apt) => {
            return {
              name: apt.name, 
              selected: true, 
              addedProviderSubtypeName: '',
              subtypes: apt.subtypes.map(s => {
                return {name: s.name, selected: true} })}}
        ),
        addedProviderTypeName: ''
      });
    }
  }, [assessments, documentTypes, providerTypes]);

  return initialValues && (
    <LotusStaticForm
      title="Care Management"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 700}}>
        <LotusSubsectionHeader title="Assessments" divider={false}/>
        <LotusSubsectionContent>
          <GranteeAgencyAssessmentsView assessmentTypes={assessmentTypes} />
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Document Types"/>
        <LotusSubsectionContent>
          <GranteeAgencyDocumentTypesView/>
        </LotusSubsectionContent>
        <LotusSubsectionHeader title="Provider Types"/>
        <LotusSubsectionContent>
          <GranteeAgencyProviderTypesView />
        </LotusSubsectionContent>
      </div>
    </LotusStaticForm>
  );
}