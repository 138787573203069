import { debounce } from "lodash";
import { useEffect, useRef, useMemo } from "react";

const { log: consLog } = console;

const utils = {
  ...console,
  whereami: () => {
    // let stack = new Error().stack.split('\n');
    // let loc = stack[2].split(':');
    // if ('local' === env.NODE_ENV) {
    //   consLog(`\t-> importing: ${loc[1].replace('///', '')}...`);
    // }
  },
  log: function () {
    let stack = new Error().stack.split('\n');
    let loc = stack[2].split(':');
    if ('' === loc[0]) {
      consLog('stack', stack);
      consLog('loc', loc);
    } else {
      consLog(`${loc[1].replace('///', '')}:${loc[2]}`, ...arguments);
    }
  },
  toUriString: (params) => {
    let rParams = [];
    for (let p in params) {
      rParams.push(`${p}=${encodeURI(params[p])}`);
    }
    return `?${rParams.join('&')}`;
  },
  parseApiResult: (formData) => {
    let res = normalizeData(formData);
    if (res.error || res.errorMessage || (res.body && res.body.error)) throw new Error(res.error || res.errorMessage|| res.body.error);
    return res;
  },
  normalizeData: (formData) => {
    return typeof formData === 'string' ? JSON.parse(formData) : formData;
  },
  groupBy: (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  },
  camelOrPascalToLabel: (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  },
  snakeToLabel: (str) => {
    return str
      .split('_')
      .map((w) => w.replace(/^./, (initial) => initial.toUpperCase()))
      .join(' ');
  },
  getFileType: filename => filename.split('.').pop(),
};

export const log = utils.log;
export const whereami = utils.whereami;
export const toUriString = utils.toUriString;
export const normalizeData = utils.normalizeData;
export const getStyleClasses = utils.getStyleClasses;
export const groupBy = utils.groupBy;
export const camelOrPascalToLabel = utils.camelOrPascalToLabel;
export const snakeToLabel = utils.snakeToLabel;
export const parseApiResult = utils.parseApiResult;
export const getFileType = utils.getFileType;

export const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 1000);
  }, []);

  return debouncedCallback;
};