import Grid from '@mui/material/Grid';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import LotusStateSelector from 'components/widgets/Forms/LotusStateSelector';
import LotusCountySelector from 'components/widgets/Forms/LotusCountySelector';
import { Autocomplete, Stack, TextField } from '@mui/material';
import { LocationClient, SearchPlaceIndexForSuggestionsCommand, GetPlaceCommand } from "@aws-sdk/client-location";
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import { debounce } from '@mui/material/utils';
import { useLists } from 'contexts/ListsContext';
import LotusReadOnlyFieldView from '../Forms/LotusReadOnlyFieldView';
import { useProperties } from 'contexts/PropertiesContext';

export default function AddressFieldPanelWithLocationSearch ({ disabled, name, style, title, required, address1Label, useSeedData = false, dontCheckRequiredFields, ...props }) {
  const { values } = useFormikContext();
  const [selectedState, setSelectedState] = useState();
  const [addressValue, setAddressValue] = useState();
  const { states, loadStates } = useLists();

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [locationClient, setLocationClient] = useState();

  const locationSearchIndex = 'LocationSearchIndex';
  const languageCode = 'en';
  const filterCountries = ["USA"];

  const {awsSecrets, loadAwsSecrets} = useProperties();

  useEffect(() => {
    if (!awsSecrets) {
      loadAwsSecrets();
    }
  }, []);

  useEffect(() => {
    if (awsSecrets) {
      const setLocClient = async () => {
        const apiKey = awsSecrets.secretString;
        const awsRegion = awsSecrets.region;
        const authHelper = await withAPIKey(apiKey);
        const cli = new LocationClient({
          region: awsRegion,
          credentials: () => Promise.resolve({}), // Location client should use the API Key defined above, but its still complaining for credentials 
          ...authHelper.getLocationClientConfig()
        });
        setLocationClient(cli);
      }

      setLocClient().catch(console.log);
    }
  }, [awsSecrets]);

  const getPlaceSuggestions = useMemo(
    () =>
      debounce(async (request, callback) => {
        const input = {
          IndexName: locationSearchIndex,
          Text: request.input,
          FilterCountries: filterCountries,
          Language: languageCode,
          Categories: [],
          MaxResults: 10
        };
        try {
          const command = new SearchPlaceIndexForSuggestionsCommand(input);
          callback(await locationClient.send(command));
        } catch(err) {
          console.error("Error generating suggestions for an address\n"+ err);
        }
      }, 500),
    [locationClient],
  );

  useEffect(() => {
    if(!states) {
      loadStates();
    }
  }, []);

  useEffect(() => {
    if (values) {
      const address = getFieldValue(name, values);
      address.zip = formatUSZipCode(address.zip);
      setAddressValue(address);
    }
  }, [values]);

  useEffect(() => {
    if (addressValue && addressValue.state !== selectedState) {
      setSelectedState(addressValue.state);
    }
  }, [addressValue]);

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    getPlaceSuggestions({ input: inputValue }, (results) => {
      results = results?.Results;
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        // to handle an address with no suggestion or suggestion is not selected
        if(inputValue) {
          setValue(inputValue);
          const address = getFieldValue(name, values);
          address.address1 = inputValue;
          setAddressValue(address);
        }
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, getPlaceSuggestions]);

  const getFieldValue = (name, values) => {
    if (name.includes('.') && Array.isArray(values[name.split('.')[0]])) {
      let parts = name.split('.');
      const arrayItem = values[parts[0]][parts[1]];
      return arrayItem ? arrayItem[parts[2]] : null;
    } else {
      return values[name];
    }
  };

  const formatUSZipCode = (zipCode) => {
    if(!zipCode) {
      return zipCode;
    }
    if (zipCode.length === 5) {
      return zipCode.slice(0, 5);
    } else if (zipCode.length >= 9) {
      zipCode = zipCode.replace(/[- ]/g, "");
      return zipCode.slice(0, 5) + "-" + zipCode.slice(5);
    }
    return zipCode;
  }
  // Get the address details by placeId
  const getAddressDetails = async (selectedAddr) => {
    if (selectedAddr && selectedAddr.PlaceId) {
      const input = {
        IndexName: locationSearchIndex,
        PlaceId: selectedAddr.PlaceId,
        Language: languageCode
      };
      try {
        const command = new GetPlaceCommand(input);
        const response = await locationClient.send(command);
        if (response && response.Place) {
          const address = getFieldValue(name, values);
          const address1 = (response.Place?.AddressNumber ?  response.Place.AddressNumber + " " : "") +
            (response.Place?.Street ? response.Place.Street : "");
            address.address1 = address1;
            setValue(address1);
            setInputValue(address1);
            address.address2 = (response.Place?.UnitType ? response.Place.UnitType + " " : "") +
                                  (response.Place?.UnitNumber ? response.Place.UnitNumber : "");
            address.city = response.Place?.Municipality;
            const stateCode = states.filter(state => state.name === response.Place?.Region)[0].abbreviation;
            address.state = stateCode;
            address.zip = formatUSZipCode(response.Place?.PostalCode);
            address.county = response.Place?.SubRegion;
            setAddressValue(address);
            setSelectedState(stateCode);
        }
      } catch(err) {
        console.error("Error finding an address details using place Id\n"+ err);
      }
    }
  }

  return addressValue && (
      <Grid item xs={12} container style={style ? style.main : {}}>
        {title &&
        <Grid item xs={12} style={{marginBottom: 6}}>
          <span className={disabled ? 'readOnlyFormLabel' : ''}>{title}:</span>
        </Grid>
        }
        <Grid item xs={12} style={{marginBottom: 12}}>
        {!disabled ?
          <Autocomplete
            size="small"
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.Text
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            freeSolo
            disabled={disabled}
            includeInputInList
            filterSelectedOptions
            value={getFieldValue(name, values).address1}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              getAddressDetails(newValue);
            }}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') {
                setInputValue(newInputValue);
              }
              if (reason === 'reset') {
                setInputValue('');
              }
              if(!newInputValue && reason === 'clear') {
                setOptions([]);
                setValue(null);
                const address = getFieldValue(name, values);
                address.address1 = '';
                address.address2 = '';
                address.city = '';
                address.state = '';
                address.zip = '';
                address.county = '';
                setAddressValue(address);
                setSelectedState('');
              }
            }}
            renderInput={(params) => (
              <TextField 
                  label={address1Label || 'Address' + (required ? ' * ' : '')}
                  name={`${name}.address1`}
                  variant="outlined"
                  margin="dense"
                  disabled={disabled}
                  size="small"
                  style={{width: '100%', ...style}}
                  maxLength={100}
                  onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                  {...params} 
                />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center" key={option.PlaceId + "1"}>
                    <Grid item sx={{ display: 'flex', width: 44 }} key={option.PlaceId + "2"}>
                      <LocationOnIcon key={option.PlaceId}/>
                    </Grid>
                    <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }} key={option.PlaceId + "3"}>
                        <Box
                          key={option.PlaceId +  "4"}
                          component="span"
                        >
                          {option && option.Text ? option.Text : option}
                        </Box>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          /> :
          <LotusReadOnlyFieldView label={address1Label || 'Address'} value={getFieldValue(name, values).address1} style={style} />
          }
        </Grid>

        <Grid item xs={12} style={{marginBottom: 12}}>
          <LotusTextInput
            name={`${name}.address2`}
            label="Apt / Lot / Floor"
            disabled={disabled}
            maxLength={100}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
          />
        </Grid>

        <Grid item xs={12} style={{marginBottom: 12}}>
          <LotusTextInput
            name={`${name}.city`}
            label="City"
            disabled={disabled}
            required={required}
            maxLength={100}
            dontCheckRequiredFields={dontCheckRequiredFields}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
          />
        </Grid>
      
        <Grid item xs={12} style={{marginBottom: 12}}>
          <Stack direction="row" spacing={1}>
            <LotusStateSelector
              name={`${name}.state`}
              disabled={disabled}
              useSeedData={useSeedData}
              label='State'
              required={required}
              style={{width: 200}}
              dontCheckRequiredFields={dontCheckRequiredFields}
              onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            />
            <LotusTextInput
              name={`${name}.zip`}
              label="Zip Code"
              disabled={disabled}
              required={required}
              style={{width: 290, marginTop: 8}} // undo margin removal so these line up
              maxLength={10}
              dontCheckRequiredFields={dontCheckRequiredFields}
              onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <LotusCountySelector
            name={`${name}.county`}
            disabled={disabled}
            useSeedData={useSeedData}
            label='County'
            required={required}
            state={selectedState}
            dontCheckRequiredFields={dontCheckRequiredFields}
            onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
          />
        </Grid>
      </Grid>
  );
}
