import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './PermissionTemplateManagementContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';

const PermissionTemplateManagementContext = createContext();

const initialState = {
  permissionTemplateToEdit: undefined,
  startingPermissionTemplate: undefined,
  permissionTemplateForReview: undefined
};

export const PermissionTemplateManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PermissionTemplateManagementContext.Provider value={{ state, dispatch }}>
      {children}
    </PermissionTemplateManagementContext.Provider>
  );
};

export const usePermissionTemplateManagement = () => {
  const { state, dispatch } = useContext(PermissionTemplateManagementContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadPermissionTemplateToEdit = async (templateId) => {
    try {
      addBusyBee('loadPermissionTemplateToEdit');
      const result = await axios.post(`/api/permissionTemplate`, {
        operationName: 'getPermissionTemplate',
        templateId
      });
      const resultObj = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PERMISSION_TEMPLATE_TO_EDIT_SUCCESS,
        payload: {
          permissionTemplateToEdit: resultObj.permissionTemplate,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPermissionTemplateToEdit');
    }
  };

  const loadStartingPermissionTemplate = async (templateId) => {
    try {
      addBusyBee('loadStartingPermissionTemplate');
      const result = await axios.post(`/api/permissionTemplate`, {
        operationName: 'getPermissionTemplate',
        templateId
      });
      const resultObj = parseApiResult(result.data).body;
      dispatch({
        type: messageTypes.LOADING_STARTING_PERMISSION_TEMPLATE_SUCCESS,
        payload: {
          startingPermissionTemplate: resultObj.permissionTemplate,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadStartingPermissionTemplate');
    }
  };

  const loadAllPermissionCategories = async () => {

    try {
      addBusyBee('loadAllPermissionCategories');
      
      const url = `/api/permissionTemplates`;
      const result = await axios.post(url, {operationName: 'getAllPermissionCategories'});
      const resultObj = parseApiResult(result.data).body;

      const tableData = resultObj.map((node) => {
        return {
          ...node,
          permissions: node.permissionsByCategoryId.map((node) => {
            return {
              ...node,
              enabled: false,
            };
          }),
        };
      });

      dispatch({
        type: messageTypes.LOADING_PERMISSION_CATEGORIES_SUCCESS,
        payload: {
          categories: tableData.filter(
            (cat) => 'general' === cat.categoryApplicationType
          ),
          programCategory: tableData.find(
            (cat) => 'programs' === cat.categoryApplicationType
          ),
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAllPermissionCategories');
    }
  };

  const loadPermissionTemplateForReview = async (requestId) => {
    try {
      addBusyBee('loadPermissionTemplateForReview');
      const result = await axios.post(`/api/permissionTemplate`, {
        operationName: 'getPermissionTemplateForReview',
        requestId
      });
      const resultObj = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_PERMISSION_TEMPLATE_TO_REVIEW_SUCCESS,
        payload: {
          permissionTemplateForReview: resultObj.permissionTemplate,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPermissionTemplateForReview');
    }
  };

  if (state) {
    return {
      ...state,
      loadPermissionTemplateToEdit,
      loadStartingPermissionTemplate,
      loadAllPermissionCategories,
      loadPermissionTemplateForReview
    };
  }

  return {};
};
