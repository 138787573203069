import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LotusForm from 'components/widgets/Forms/LotusForm';
import { ErrorMessage, Form, Formik } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useNavigate, useParams } from 'react-router-dom';
import { useClient } from 'contexts/ClientContext';
import moment from "moment";
import { PERMISSIONS } from "lib/permissionEnums";
import { useAuthState } from 'contexts/AuthProvider';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFileViewer from 'components/widgets/LotusFileViewer';
import { Alert, Button, Stack, Typography } from '@mui/material';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import { useAppStatus } from 'contexts/AppStatusContext';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from 'lib/documents';
import LotusPageSubSection from 'components/widgets/Layout/LotusPageSubSection';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { AdapEnrollmentsProvider, useAdapEnrollments } from 'contexts/AdapEnrollmentsContext';
import { isEqual } from 'lodash';
import { EventDetailsDocuments, EventDetailsField, EventDetailsSection, EventDetailsSubSection, HistoricalEvents } from 'components/widgets/Composites/HistoricalEvents';

function EnrollmentDetails({details}) {

  const { medicationAssistanceLevels, premiumPaymentFrequencies, privateInsuranceSources, loadClientInfoPicklists } = useLists();

  useEffect(() => {
    if (!medicationAssistanceLevels) {
      loadClientInfoPicklists();
    }
  }, [medicationAssistanceLevels]);

  return (
    <div style={{paddingLeft: 16, paddingRight: 16}}>
      <EventDetailsSection title="Insurance Documents">
        <EventDetailsDocuments details={details} showCategory={false}/>
      </EventDetailsSection>
      {details.insurance &&
        <EventDetailsSection title="Enrollment Details">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EventDetailsField label="Subprogram" value={details.subprograms.map(s => s.name).join(', ')} />
            <EventDetailsField label="Enrollment Effective Start Date" value={details.adapDecisionEffectiveDate} />
            <EventDetailsField label="Enrollment Effective End Date" value={details.endDate} />
          </Stack>
          {details.insurance.medicationAssistanceEnabled &&
            <EventDetailsSubSection title="Medication Assistance">
              <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
                <EventDetailsField label="Level" value={medicationAssistanceLevels.find(l => details.insurance.medicationAssistanceLevel === l.itemKey)?.itemName} />
              </Stack>
            </EventDetailsSubSection>
          }
          {details.insurance.premiumAssistanceEnabled &&
            <EventDetailsSubSection title="Premium Assistance">
              <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
                <EventDetailsField label="Insurance Start Date" value={details.insurance.authorizedAdapInsuranceStartDate} />
                <EventDetailsField label="Policy Source" value={privateInsuranceSources.find(f => details.insurance.authorizedAdapInsurancePolicySourceId === f.id)?.itemName} />
                <EventDetailsField label="Amount" value={details.insurance.authorizedAdapPremiumAmount ? `$${details.insurance.authorizedAdapPremiumAmount}` : ''} />
                <EventDetailsField label="Frequency" value={premiumPaymentFrequencies.find(f => details.insurance.authorizedAdapPremiumFrequencyId === f.id)?.itemName} />
                <EventDetailsField label="Insurance Carrier" value={details.insurance.authorizedAdapInsuranceCarrier} />
                <EventDetailsField label="Insurance Plan" value={details.insurance.authorizedAdapInsurancePlan} />
              </Stack>
            </EventDetailsSubSection>
          }
        </EventDetailsSection>
      }
    </div>
  );
}

function EnrollmentHistory({adapProgramEnrollments, clientId}) {

  const { adapServiceDenialReasons, adapEnrollmentTerminationReasons, loadClientInfoPicklists } = useLists();

  const { applications, appEnrollmentDetails, loadEnrollmentDetailsForApplication, loadApplicationsForClient } = useAdapEnrollments();
  const [ enrollmentHistory, setEnrollmentHistory ] = useState();

  useEffect(() => {
    if (!adapServiceDenialReasons) {
      loadClientInfoPicklists();
    }
  }, [adapServiceDenialReasons]);

  useEffect(() => {
    if (clientId) {
      loadApplicationsForClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (adapServiceDenialReasons && applications && adapProgramEnrollments) {
      let now = moment();
      let history = {periods: []};
      let sortedPeriods = [...adapProgramEnrollments.filter(e => moment(e.startDate,'MM/DD/YYYY') <= now.startOf('day')).sort((a,b) => moment(a.endDate,'MM/DD/YYYY') < moment(b.endDate,'MM/DD/YYYY') ? 1 : -1)];
      sortedPeriods.forEach(p => history.periods.push({
        label: p.subprograms.map(s => s.name).join(', '), 
        subprogramIds: p.subprograms.map(s => s.id), 
        startDate: p.startDate, 
        endDate: p.endDate,
        terminationReasonId: p.terminationReasonId,
        events: []
      }));
      // Combine contiguous periods with same subprograms
      let combinedPeriods = [];
      for (let p = 0; p < history.periods.length-1;) {
        let thisPeriod = history.periods[p];
        let prevPeriod = history.periods[p+1];
        if (isEqual(thisPeriod.subprogramIds, prevPeriod.subprogramIds) && thisPeriod.startDate === moment(prevPeriod.endDate,'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY')) {
          thisPeriod.startDate = prevPeriod.startDate;
          history.periods.splice(p+1, 1);
        } else {
          combinedPeriods.push(history.periods[p]);
          p++;
        }
      }
      if (history.periods.length > 0) {
        combinedPeriods.push(history.periods[history.periods.length-1]);
      }
      history.periods = combinedPeriods;

      let sortedApps = applications.sort((a,b) => moment(a.createDate,'MM/DD/YYYY HH:mm:ss') < moment(b.createDate,'MM/DD/YYYY HH:mm:ss') ? 1 : -1);
      sortedApps.forEach(a => {
        let period = history.periods.find(p => moment(p.startDate,'MM/DD/YYYY') <= moment(a.adapDecisionEffectiveDate || a.statusEffectiveDate, 'MM/DD/YYYY') && moment(p.endDate,'MM/DD/YYYY') >= moment(a.adapDecisionEffectiveDate || a.statusEffectiveDate, 'MM/DD/YYYY'));
        if (period) {
          if (a.statusKey === 'terminated' && a.isDeceased) {
            period.events.push({
              label: 'Enrollment Ended',
              icon: 'ended',
              subtext: 'Client Deceased',
              date: a.statusEffectiveDate,
              createDate: a.createDate,
              id: a.id,
              detailsUnavailable: true
            });
          }
          else if (a.statusKey === 'completed') {
            let label = null, subtext = null, icon = null, detailsUnavailable = undefined, overrideDate = null;
            if (a.isInitialApplication && !a.isInterimUpdate && a.rwEligible && a.areServicesAuthorized) {
              label = 'Enrollment Started';
              icon = 'started';
              overrideDate = a.adapDecisionEffectiveDate;
            }
            else if (a.isInitialApplication && !a.isInterimUpdate && !a.rwEligible) {
              label = 'Terminated';
              icon = 'ended';
              detailsUnavailable = true;
            }
            else if (a.isAdapOnlyApplication && !a.isInterimUpdate && a.rwEligible && a.areServicesAuthorized) {
              label = 'Enrollment Started';
              icon = 'started';
              overrideDate = a.adapDecisionEffectiveDate;
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && a.rwEligible && a.areServicesAuthorized) {
              label = 'Enrollment Started';
              icon = 'started';
              overrideDate = a.adapDecisionEffectiveDate;
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && a.rwEligible && !a.areServicesAuthorized && a.servicesNotAuthorizedReasonId) {
              label = 'Terminated';
              icon = 'ended';
              overrideDate = a.adapDecisionEffectiveDate;
              subtext = adapServiceDenialReasons.find(r => r.id === a.servicesNotAuthorizedReasonId)?.itemName;
              detailsUnavailable = true;
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && !a.rwEligible && period.endDate === a.eligibilityEndDate) {
              label = 'Terminated';
              subtext = 'Client is no longer Ryan White Eligible';
              icon = 'ended';
              detailsUnavailable = true;
            }
            else if (a.isInterimUpdate && a.rwEligible && a.hasInsuranceUpdates && a.areServicesAuthorized && moment(a.adapDecisionEffectiveDate,'MM/DD/YYYY') <= now.startOf('day')) {
              label = 'Enrollment Started';
              icon = 'started';
              overrideDate = a.adapDecisionEffectiveDate;
              subtext = 'Insurance Change';
            }
            else if (a.isInterimUpdate && a.rwEligible && a.hasInsuranceUpdates && !a.areServicesAuthorized && a.servicesNotAuthorizedReasonId) {
              label = 'Terminated';
              icon = 'ended';
              overrideDate = a.adapDecisionEffectiveDate;
              detailsUnavailable = true;
              subtext = adapServiceDenialReasons.find(r => r.id === a.servicesNotAuthorizedReasonId).itemName;
            }
            else if (a.isInterimUpdate && !a.rwEligible && period.endDate === a.eligibilityEndDate) {
              label = 'Terminated';
              icon = 'ended';
              subtext = 'Client is no longer Ryan White Eligible';
              overrideDate = a.eligibilityEndDate;
              detailsUnavailable = true;
            }

            if (label) {
              period.events.push({
                label,
                icon,
                subtext,
                date: overrideDate || a.statusEffectiveDate,
                createDate: a.createDate,
                id: a.id,
                detailsUnavailable
              });
            }
          }
        }
      });

      // Deal with terminations (except deceased since that was a special app type)
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.terminationReasonId && moment(period.events[0].date,'MM/DD/YYYY') <= now.startOf('day') && period.events[0].subtext !== 'Client Deceased') {
          period.events.unshift({
            label: 'Terminated',
            icon: 'ended',
            subtext: adapEnrollmentTerminationReasons.find(r => r.id === period.terminationReasonId).itemName,
            detailsUnavailable: true,
            date: period.endDate,
            createDate: now.format('MM/DD/YYYY HH:mm:ss')
          });
        }
      });

      // Deal with continuations in same period
      history.periods.forEach(period => {
        period.events.forEach((evt,evtIdx) => {
          if (evtIdx < period.events.length - 1 && evt.label === 'Enrollment Started') {
            if (evt.subtext === 'Insurance Change') {
              evt.label = 'Enrollment Updated';
              evt.icon = 'update';
            } else {
              evt.label = 'Enrollment Continued';
              evt.icon = 'renewed';
            }
          }
        });

      });

      // Deal with insurance changes that cause a change in enrollment to a different subprogram
      history.periods.forEach((period, periodIdx) => {
        if ((periodIdx+1) < history.periods.length) {
          let prevPeriod = history.periods[periodIdx + 1];
          if ((period.startDate === moment(prevPeriod.endDate, 'MM/DD/YYYY').add(1, 'day').format('MM/DD/YYYY')) &&
              (!isEqual(period.subprogramIds, prevPeriod.subprogramIds))) {
            prevPeriod.events.unshift({
              label: 'Enrollment Ended',
              icon: 'ended',
              subtext: 'Subprogram Switch',
              detailsUnavailable: true,
              date: prevPeriod.endDate,
              createDate: now.format('MM/DD/YYYY HH:mm:ss')
            });
            if (period.events.length > 0) {
              period.events[0].subtext = '';
            }
          }
        }
      });

      // Deal with changes that haven't reached the effective date yet
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.events[0].label === 'Terminated' && moment(period.events[0].date,'MM/DD/YYYY') > now.startOf('day')) {
          period.events.shift();
        }
      });

      setEnrollmentHistory(history);
    }
  }, [applications, adapProgramEnrollments, adapServiceDenialReasons]);

  const retrieveAppDetailsIfNecessary = async (appId) => {
    let found = false;
    enrollmentHistory.periods.forEach(p => {
      if (p.events) {
        let evt = p.events.find(e => e.id === appId);
        if (evt && evt.details) {
          found = true;
        }
      }
    });
    if (!found) {
      await loadEnrollmentDetailsForApplication(appId);
    }
  }

  useEffect(() => {
    if (appEnrollmentDetails && enrollmentHistory) {
      let newHist = {...enrollmentHistory};
      appEnrollmentDetails.forEach(ad => {
        newHist.periods.forEach(p => {
          for (let e = 0; e < p.events.length; e++) {
            let evt = p.events[e];
            if (evt.id === ad.appId) {
              if (e - 1 >= 0) {
                ad.details.endDate = p.events[e - 1].date;
                if (p.events[e - 1].label !== 'Terminated' && p.events[e - 1].label !== 'Enrollment Ended') {
                  ad.details.endDate = moment(ad.details.endDate,'MM/DD/YYYY').add(-1, 'day').format('MM/DD/YYYY');
                }
              }
              evt.details = ad.details;
            }
            if (e === 0 && evt.details) {
              evt.details.endDate = p.endDate;
            }
          }
        });
      });
      setEnrollmentHistory(newHist);
    }
  }, [appEnrollmentDetails]);

  return enrollmentHistory && enrollmentHistory.periods.length > 0 && (
    <LotusPageSubSection header="Enrollment History">
      <HistoricalEvents
        hist={enrollmentHistory} 
        periodTitleFunc={(p) => `${p.label}: ${p.startDate} - ${p.endDate}`}
        detailRetriever={retrieveAppDetailsIfNecessary} 
        EventDetailDisplayer={EnrollmentDetails}/>
    </LotusPageSubSection>
  );
}

function AdapEnrollmentPanel() {
  
  const {clientId, lvl3: programId} = useParams();
  const { client } = useClient();
  const {loadAdapEnrollmentsForClient, adapProgramEnrollments, terminateAdapProgramEnrollment} = useAdapEnrollments();
  const [initialValues, setInitialValues] = useState();
  const [enrollments, setEnrollments] = useState();
  const [showTerminationForm, setShowTerminationForm] = useState(false);
  const [terminationInfo, setTerminationInfo] = useState();
  const [fullTerminationReasonList, setFullTerminationReasonList] = useState();
  const [selectableTerminationReasonList, setSelectableTerminationReasonList] = useState();
  const navigate = useNavigate();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const isAdapProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR);

  const { 
    adapEnrollmentTerminationReasons, medicationAssistanceLevels, premiumPaymentFrequencies, 
    premiumAssistanceCoverageTypes, privateInsuranceSources, loadClientInfoPicklists } = useLists();

  useEffect(() => {
    if (!adapProgramEnrollments) {
      loadAdapEnrollmentsForClient(clientId);
    }
  }, []);

  useEffect(() => {
    if (adapEnrollmentTerminationReasons) {
      setFullTerminationReasonList(adapEnrollmentTerminationReasons.map(r => {return {value: r.id, label: r.itemName}}));
      setSelectableTerminationReasonList(adapEnrollmentTerminationReasons.filter(r => r.itemKey !== 'no_recert' && r.itemKey !== 'elig_crit_chg').map(r => {return {value: r.id, label: r.itemName}}));
    }
  }, [adapEnrollmentTerminationReasons]);

  useEffect(() => {
    if (adapProgramEnrollments && programId) {
      // Find current enrollment or most recent one
      let targetEnrollment = null;
      let futures = [];
      let now = moment().startOf('day');
      adapProgramEnrollments?.forEach(e => {
        let startDateMoment = moment(e.startDate,'MM/DD/YYYY');
        if (e.programId === programId && startDateMoment <= now && (!targetEnrollment || startDateMoment > moment(targetEnrollment.startDate,'MM/DD/YYYY'))) {
          targetEnrollment = e;
        }
        if (e.programId === programId && startDateMoment > now) {
          futures.push(e);
        }
      });
      let viewableEnrollments = [];
      if (targetEnrollment) {
        viewableEnrollments.push(targetEnrollment);
      }
      viewableEnrollments.push(...futures);
      setEnrollments(viewableEnrollments);
    }
  }, [programId, adapProgramEnrollments]);

  useEffect(() => {
    if (!medicationAssistanceLevels) {
      loadClientInfoPicklists();
    }
  }, [medicationAssistanceLevels]);

  useEffect(() => {
    if (enrollments && medicationAssistanceLevels && premiumPaymentFrequencies && premiumAssistanceCoverageTypes && privateInsuranceSources) {
      let formEnrollments = enrollments.map(enrollment => {
        return {
          id: enrollment.id,
          clientId: enrollment.clientId,
          programId: enrollment.programId,
          programName: enrollment.programName,
          startDate: enrollment.startDate,
          endDate: enrollment.endDate,
          subprogramNames: enrollment.subprograms.map(s => s.name).join(", "),
          medicationAssistanceEnabled: enrollment.medicationAssistanceEnabled,
          medicationAssistanceLevelText: medicationAssistanceLevels.find(l => enrollment.medicationAssistanceLevel === l.itemKey)?.itemName,
          premiumAssistanceEnabled: enrollment.premiumAssistanceEnabled,
          premiumAssistanceCoverageType: premiumAssistanceCoverageTypes.find(t => enrollment.premiumAssistanceCoverageType === t.itemKey)?.itemName,
          authorizedAdapPremiumAmount: enrollment.authorizedAdapPremiumAmount,
          authorizedAdapPremiumFrequencyName: premiumPaymentFrequencies.find(f => enrollment.authorizedAdapPremiumFrequencyId === f.id)?.itemName,
          authorizedAdapInsuranceStartDate: enrollment.authorizedAdapInsuranceStartDate,
          authorizedAdapInsuranceCarrier: enrollment.authorizedAdapInsuranceCarrier,
          authorizedAdapInsurancePlan: enrollment.authorizedAdapInsurancePlan,
          authorizedAdapInsurancePolicySource: privateInsuranceSources.find(f => enrollment.authorizedAdapInsurancePolicySourceId === f.id)?.itemName,
          terminationReasonId: enrollment.terminationReasonId,
          terminationComments: enrollment.terminationComments,
          terminationAttachments: enrollment.terminationAttachments,
        };
      });
      setInitialValues({enrollmentList: formEnrollments});
    }
  }, [enrollments, medicationAssistanceLevels, premiumPaymentFrequencies, premiumAssistanceCoverageTypes, privateInsuranceSources]);

  const viewTerminateEnrollment = (enr) => {
    setShowTerminationForm(true);
    setTerminationInfo({
      id: enr.id,
      programId: enr.programId,
      programName: enr.programName,
      clientId: enr.clientId,
      clientName: client.name,
      terminationReasonId: '',
      terminationComments: '',
      attachments: []
    });
  }

  const terminateEnrollment = async (values) => {
    await terminateAdapProgramEnrollment(values);
    navigate(`/client/${clientId}/enrollment/list`);
  }

  const isCurrentEnrollment = (enr) => {
    let now = moment().startOf('day');
    return (moment(enr.startDate,'MM/DD/YYYY') <= now && now <= moment(enr.endDate,'MM/DD/YYYY'));
  }

  const isFutureEnrollment = (enr) => {
    let now = moment().startOf('day');
    return (moment(enr.startDate,'MM/DD/YYYY') > now);
  }

  return initialValues && (
    <>
    <Formik
      enableReinitialize
      initialValues={initialValues}
    >
      {({values}) => {
        return values && (
          <>
            {values.enrollmentList.map((enr, index) => {
              return (
                <div key={enr.id} style={{backgroundColor: isFutureEnrollment(enr) ? '#E0F1F3' : 'inherit', padding: 10, marginBottom: 30}}>
                  <LotusPageSection 
                    header={`${enr.programName} Enrollment`}
                    actions={
                      <>
                      {isAdapProcessor && isCurrentEnrollment(enr) && !enr.terminationReasonId &&
                        <Button variant="outlined" color="error" onClick={() => {viewTerminateEnrollment(enr);}}>
                          <Typography variant="h6">Terminate Enrollment</Typography>
                        </Button>
                      }
                      {isFutureEnrollment(enr) &&
                        <Alert severity='info' sx={{ bgcolor: '#E0F1F3' }}>EFFECTIVE {enr.startDate}</Alert>
                      }
                      </>
                    }
                  >
                    <LotusForm>
                      <LotusFormItem>
                        <LotusTextInput
                          name={`enrollmentList.${index}.subprogramNames`}
                          label="Sub-Program"
                          disabled={true}
                        />
                      </LotusFormItem>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <LotusFormItem>
                            <LotusDatePicker
                              name={`enrollmentList.${index}.startDate`}
                              label="Enrollment Effective Start Date"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </Grid>
                        <Grid item xs={6}>
                          <LotusFormItem>
                            <LotusDatePicker
                              name={`enrollmentList.${index}.endDate`}
                              label="Enrollment Effective End Date"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </Grid>
                      </Grid>
                      {enr.medicationAssistanceEnabled &&
                        <LotusFormSection name='Medication Assistance'>
                          <LotusFormItem>
                            <LotusTextInput
                              name={`enrollmentList.${index}.medicationAssistanceLevelText`}
                              label="Level"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </LotusFormSection>
                      }
                      {enr.premiumAssistanceEnabled &&
                        <LotusFormSection name='Premium Assistance'>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <LotusFormItems>
                                <LotusDatePicker
                                  name={`enrollmentList.${index}.authorizedAdapInsuranceStartDate`}
                                  label="Insurance Start Date"
                                  disabled={true}
                                /> 
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapPremiumAmount`}
                                  label="Amount"
                                  disabled={true}
                                  maxLength={16}
                                  floatingPoint
                                  adornment="$"
                                />   
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapPremiumFrequencyName`}
                                  label="Frequency"
                                  disabled={true}
                                />
                              </LotusFormItems>
                            </Grid>
                            <Grid item xs={6}>
                              <LotusFormItems>
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsurancePolicySource`}
                                  label="Policy Source"
                                  disabled={true}
                                />
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsuranceCarrier`}
                                  label="Insurance Carrier"
                                  disabled={true}
                                />   
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsurancePlan`}
                                  label="Insurance Plan"
                                  disabled={true}
                                />    
                              </LotusFormItems>
                            </Grid>
                          </Grid>
                        </LotusFormSection>
                      }
                      {enr.terminationReasonId &&
                        <LotusFormSection name='Termination'>
                          <LotusFormItems>
                            {fullTerminationReasonList && 
                              <LotusSelect
                                name={`enrollmentList.${index}.terminationReasonId`}
                                label="Reason for Termination"
                                items={fullTerminationReasonList}
                                disabled
                              />
                            }
                            <LotusTextInput
                              name={`enrollmentList.${index}.terminationComments`}
                              multiline
                              minRows={5}
                              label="Comments"
                              disabled
                            />
                            <EnrollmentTerminationDocumentationList
                              clientId={client.id}
                              documents={enr.terminationAttachments} 
                              disabled={true}
                            />
                          </LotusFormItems>
                        </LotusFormSection>
                      }
                    </LotusForm>
                  </LotusPageSection>
                </div>
              );
            })}
            <ActionDialog
              open={showTerminationForm}
              maxWidth="lg"
              content={
                <Formik
                  initialValues={terminationInfo}
                  enableReinitialize
                  onSubmit={async (values) => {
                    await terminateEnrollment(values);
                    setShowTerminationForm(false);
                  }}
                >
                  {({handleSubmit, values, setFieldValue}) => {
                    const handleCancel = () => {
                      setShowTerminationForm(false);
                    }
                    const addAttachment = (attachment) => {
                      setFieldValue('attachments', [...values.attachments, attachment]);
                    }
                    return values && (
                      <LotusPageSection
                        header={`Terminate ${values.programName} Enrollment`}
                        actions={
                          <>
                          <LotusButton variant="outlined" closeIcon onClick={() => {handleCancel();}}>
                            <Typography variant="h6">Cancel</Typography>
                          </LotusButton>
                          <LotusButton variant="contained" color="error" onClick={() => {handleSubmit(values);}}>
                            <Typography variant="h6">Terminate</Typography>
                          </LotusButton>
                          </>
                        }
                      >
                        <Form onSubmit={handleSubmit}>
                          <LotusForm>
                            <LotusFormItems>
                              <span>Date: {moment().format('MMMM Do, YYYY')} | By: {loggedInUser.firstName + ' ' + loggedInUser.lastName}</span>
                              <span>Client Name: {values.clientName}</span>
                              
                              {selectableTerminationReasonList && 
                                <LotusSelect
                                  name="terminationReasonId"
                                  label="Reason for Termination"
                                  items={selectableTerminationReasonList}
                                  required
                                />
                              }
                              <LotusTextInput
                                name="terminationComments"
                                multiline
                                minRows={5}
                                label="Comments"
                              />
                              <EnrollmentTerminationDocumentationList
                                clientId={client.id}
                                documents={values.attachments} 
                                onDocumentAdded={addAttachment} 
                                disabled={false}
                              />
                            </LotusFormItems>
                          </LotusForm>
                        </Form>
                      </LotusPageSection>
                    );
                  }}
                </Formik>
              }
            />
          </>
        );
      }}
    </Formik>
    <EnrollmentHistory adapProgramEnrollments={adapProgramEnrollments} clientId={clientId} />
    </>
  );
}

function EnrollmentTerminationDocumentationList ({ clientId, documents, onDocumentAdded, disabled}) {

  const [showAddDocument, setShowAddDocument] = useState(false);
  const [allDocuments, setAllDocuments] = useState();
  const [documentToView, setDocumentToView] = useState();

  useEffect(() => {
    if (documents) {
      documents.sort((a, b) => (a.displayName > b.displayName ? -1 : 1));
      setAllDocuments(documents);
    }
  }, [documents]);

  const columns = [
    {
      label: 'File Name',
      name: 'displayName',
    }
  ];

  const addDocument = async (document) => {
    setShowAddDocument(false);

    if (onDocumentAdded) {
      onDocumentAdded(document);
    }
  };

  const viewDocumentForRow = async (rowData, rowMeta) => {
    const doc = allDocuments[rowMeta.dataIndex];
    setDocumentToView(doc);                 
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  return (
    <Grid item xs={12}>
      <LotusPageSubSection header="Termination Documentation" >
        <LotusSpacedBlock>
        {allDocuments && (
          <Table
            columns={columns}
            data={allDocuments}
            options={{
              print: false,
              download: false,
              filter: false,
              searchOpen: false,
              search: false,
              viewColumns: false,
              pagination: false
            }}
            handleRowClicked={viewDocumentForRow}
          />
        )}
        </LotusSpacedBlock>
        {!disabled &&
          <LotusButton variant="contained" plusIcon onClick={() => {setShowAddDocument(true);}}>
            Add Attachment
          </LotusButton>
        }
        <ActionDialog
          fullWidth={true}
          maxWidth="lg"
          open={Boolean(documentToView)}
          content={
            <LotusFileViewer 
              clientId={clientId} 
              subfolderName="adapTermDocs"
              title={documentToView?.displayName || ''} 
              file={[documentToView]} 
              handleClose={closeDocumentView}/>}
        />

        <ActionDialog
          maxWidth="sm"
          open={showAddDocument}
          content={
            <TerminationDocumentUploader
              clientId={clientId}
              handleCancel={() => {setShowAddDocument(false);}}
              handleSave={addDocument}
            />
          }
        />
      </LotusPageSubSection>
    </Grid>
  );
};

const TerminationDocumentUploader = ({clientId, handleCancel, handleSave}) => {
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [ thumbnails, setThumbnails ] = useState([]);
  const [ initialValues, setInitialValues ] = useState();

  useEffect(() => {
    setInitialValues({
      document: {}
    });
  }, []);

  return (
      <Grid container>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={async (values) => {
            let result = {};
            if (values.docErr) {
              result.docErr = values.docErr;
            }
            if (Object.keys(values.document).length === 0) {
              result.document = "Document is required";
            }
            return result;
          }}
          onSubmit={async (newValues, actions) => {
            await handleSave(newValues.document);
            actions.setSubmitting(false);
          }}
        >
          {({ values, setFieldTouched, handleSubmit, setFieldValue }) => {
            return values && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                    <Grid item xs={6}>
                      <Typography variant="h2">Upload Enrollment Termination Document</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                        <Typography variant="h6">Cancel</Typography>
                      </Button>
                     <Button onClick={() => {handleSubmit(values);}}>
                        <Typography variant="h6">Upload</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LotusFileUploader
                      name="terminationDocumentUploader"
                      maxFiles={1}
                      clientId={clientId}
                      subfolderName="adapTermDocs"
                      onFileAdded={async (newFile, base64Str) => {
                        console.log('Added', newFile);
                        try {
                          addBusyBee('generatingThumbnail');
                          const thumbnails = await generateThumbnailsForUrl( base64Str, newFile.type, [100]);
                          setThumbnails(thumbnails);
                          setFieldTouched('docErr');
                          setFieldValue('docErr', '');
                          setFieldTouched('document');
                          setFieldValue('document', newFile);
                        }
                        catch (err) {
                          console.log(err);
                          setFieldTouched('document');
                          setFieldTouched('docErr');
                          setFieldValue('document', {});
                          setFieldValue('docErr', 'An error occurred while generating thumbnail images for file');
                        } finally {
                          removeBusyBee('generatingThumbnail');
                        }
                      }}
                      onFileDeleted={(newFile) => {
                        console.log('Deleted', newFile);
                        setFieldTouched('document');
                        setFieldValue('document', {});
                        setThumbnails(null);
                      }}
                      onError={(err, action, msg) => {
                        console.log(err, msg);
                        setFieldTouched('document');
                        setFieldTouched('docErr');
                        const errMsg = msg || 'An error occurred while processing the document';
                        if (action !== 'preview') {
                          setFieldValue('document', {});
                        }
                        setFieldValue('docErr', errMsg);
                      }}
                    />
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="document" />
                    </div>
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="docErr" />
                    </div>
                  </Grid>
                </Grid>
                <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {thumbnails && thumbnails.map((t,idx) => {
                    return (<img key={idx} alt='Thumbnail of documents' src={t} style={{maxWidth: 100, maxHeight: 100, marginRight: 10}}/>);
                  })}
                </div>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    ) || <div></div>
}

export default function AdapEnrollmentViewer({ ...props }) {
  return (
      <AdapEnrollmentsProvider>
        <AdapEnrollmentPanel {...props} />
      </AdapEnrollmentsProvider>
  );
}
