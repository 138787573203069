import { Grid, Stack } from '@mui/material';
import React from 'react';

export default function LotusPageSection({ header, actions, ...props }) {

  return (
    <div>
      <Grid container alignItems="center" style={{marginTop: 24, paddingBottom: 8, borderBottom: header ? "1px solid grey" : "", marginBottom: 24}}>
        <Grid item xs={5}>
          <div style={{fontSize: 24, fontWeight: 400}}>{header}</div>
        </Grid>
        <Grid item xs={7}>
          <Stack justifyContent='flex-end' direction="row" spacing={1}>
            {actions}
          </Stack>
        </Grid>
      </Grid>
      {props.children}
    </div>
  );
}
