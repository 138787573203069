import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientCarePlanContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  barrierSummaries: {}, // key by program id
  goalAndInterventionSummaries: {}, // key by program id
  barrierDetails: {}, // key by program id
};

const ClientCarePlanContext = createContext();

export const ClientCarePlanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientCarePlanContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientCarePlanContext.Provider>
  );
};

export const useClientCarePlan = () => {
  const { state, dispatch } = useContext(ClientCarePlanContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadBarrierSummariesForClientAndProgram = async (clientId, programId) => {
    try {
      addBusyBee('loadBarrierSummariesForClientAndProgram');

      const toPost = {
        operationName: 'getBarrierSummariesForClientAndProgram',
        clientId: clientId, 
        programId: programId 
      };
      const result = await axios.post('/api/clientCarePlan', toPost);
      let barriersData = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_BARRIER_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          items: barriersData,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadBarrierSummariesForClientAndProgram');
    }
  };

  const loadGoalsAndInterventionSummariesForClientAndProgram = async (clientId, programId) => {
    try {
      addBusyBee('loadGoalsAndInterventionSummariesForClientAndProgram');

      const toPost = {
        operationName: 'getGoalsAndInterventionSummariesForClientAndProgram',
        clientId: clientId, 
        programId: programId 
      };
      const result = await axios.post('/api/clientCarePlan', toPost);
      let goalsData = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.LOADING_GOALS_AND_INTERVENTION_SUMMARIES_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          items: goalsData,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadGoalsAndInterventionSummariesForClientAndProgram');
    }
  };

  const loadBarrierDetailsForClientAndProgram = async (clientId, programId) => {
    try {
      addBusyBee('loadBarrierDetailsForClientAndProgram');

      const toPost = {
        operationName: 'getBarriersForClientAndProgram',
        clientId: clientId, 
        programId: programId
      };
      const result = await axios.post('/api/clientCarePlan', toPost);

      let barrierList = parseApiResult(result.data).body;

      const allGoals = [];

      barrierList.forEach((node) => {
        allGoals.push(...node.goals);
      });

      dispatch({
        type: messageTypes.LOADING_BARRIER_DETAILS_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          items: barrierList,
          programId,
          allGoalsForProgram: allGoals,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadBarrierDetailsForClientAndProgram');
    }
  };

  const upsertBarrier = async (barrier) => {

    try {
      addBusyBee('upsertBarrier');

      const toPost = {
        operationName: 'upsertClientProgramBarrier',
        barrier: barrier
      };

      const result = await axios.post('/api/clientCarePlan', toPost);
      const updatedBarrier = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_BARRIER_SUCCESS,
        payload: {
          programId: barrier.programId,
          barrier: updatedBarrier
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('upsertBarrier');
    }
  };

  const upsertBarriers = async (newValues, assessmentId) => {
    // no removing for now
    for (let i = 0; i < newValues.barriers.length; i++) {
      let b = newValues.barriers[i];
      await upsertBarrier(b, assessmentId, false);
    };
  }

  const upsertGoal = async (goal) => {
    try {
      addBusyBee('addGoal');

      const toPost = {
        operationName: 'upsertClientProgramGoal',
        goal
      };

      const result = await axios.post('/api/clientCarePlan', toPost);
      const newGoal = parseApiResult(result.data).body;

      dispatch({
        type: messageTypes.UPSERTING_CLIENT_GOAL_SUCCESS,
        payload: {
          programId: goal.programId,
          goal: newGoal
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('addGoal');
    }
  };

  return {
    ...state,
    loadBarrierSummariesForClientAndProgram,
    loadGoalsAndInterventionSummariesForClientAndProgram,
    loadBarrierDetailsForClientAndProgram,
    upsertBarriers,
    upsertBarrier,
    upsertGoal
  };
};
