import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import BarrierPanel from './BarrierPanel';

export default function BarrierList({ barriers, readOnly }) {
  return (
    <>
      {barriers &&
        barriers.map((item) => (
          <LotusSpacedBlock key={item.id}>
            <BarrierPanel barrier={item} readOnly={readOnly} />
          </LotusSpacedBlock>
        ))}
    </>
  );
}
