import React, { useEffect } from 'react';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function AssessmentLinker() {
  const { userAgency, userAgencyAssessments, loadUserAgencyAssessments } = useUserAgency();

  useEffect(() => {
    if (userAgency && !userAgencyAssessments) {
      loadUserAgencyAssessments();
    }
  }, [userAgency, userAgencyAssessments]);

  return userAgencyAssessments && (
    <LotusCheckboxList
      name="selectedAssessments"
      items={userAgencyAssessments.map(pt => {return {label: pt.name, value: pt.id}})}
    />
  );
}
