import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigation } from '../contexts/NavigationContext';
import { useProperties } from 'contexts/PropertiesContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid } from "@mui/material";
import { useAuthState } from 'contexts/AuthProvider';
import { Stack, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import _ from 'lodash';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { useUserManagement } from 'contexts/UserManagementContext';
import * as yup from 'yup';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import InlineStaticForm from 'components/Forms/InlineStaticForm';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import { PERMISSIONS } from 'lib/permissionEnums';
import ChangePasswordDialog from './authentication/ChangePasswordDialog';
import ResetMFADialog from './authentication/ResetMFADialog';

export default function MyAccount() {
  const { user, verifyPermission, reloadUserInfo, signOut } = useAuthState();
  const { setState } = useNavigation();
  const { updateUserData, userResetMFA } = useUserManagement();
  const [initialValues, setInitialValues] = useState();
  const [allowedStartPages, setAllowedStartPages] = useState();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetMFA, setShowResetMFA] = useState(false);
  const [showPasswordChangeSuccessMessage, setShowPasswordChangeSuccessMessage] = useState(false);
  const [showMFASuccssMessage, setShowMFASuccssMessage] = useState(false);
  const { userAgencyOptionalFeatures } = useUserAgency();  

  const { properties } = useProperties();
  const [mfaEnabled, setMfaEnabled] = useState();
  const [localAuth, setLocalAuth] = useState();
  
  useEffect(() => {
    if (properties) {
      const mfaCfg = properties['REACT_APP_MFA_CONFIGURATION'];
      setMfaEnabled(mfaCfg && mfaCfg === 'ON');
    }
  }, [properties]);
  
  useEffect(() => {
    if (userAgencyOptionalFeatures) {
      let isLocalAuth = true; 
      
      if (userAgencyOptionalFeatures.idp && userAgencyOptionalFeatures.idp !== 'local'){
        isLocalAuth = false;
      }
      setLocalAuth(isLocalAuth);
    }
  }, [userAgencyOptionalFeatures]);

  useEffect(() => {
    
    if (user) {
      setInitialValues({
        firstName: user.data.firstName || '',
        middleName: user.data.middleName || '',
        lastName: user.data.lastName || '',
        primaryPhone: user.data.primaryPhone || '',
        secondaryPhone: user.data.secondaryPhone || '',
        startPage: user.startPage || 'None'
      });
      setState({ selectedAccount: user.email });

      let userAllowedStartPages = [{value: "None", label: "None"}];
      if (verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) ||
          verifyPermission(PERMISSIONS.APPLICATION_COMPLETER) ||
          verifyPermission(PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY) ||
          verifyPermission(PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY) ||
          verifyPermission(PERMISSIONS.ADAP_PROCESSOR)) {
        userAllowedStartPages.push({value: "ApplicationList", label: "Application List"});
      }
      if (!user.isRoot) {
        userAllowedStartPages.push({value: "ClientList", label: "Client List"});
      }
      setAllowedStartPages(userAllowedStartPages);
    }
  }, [user]);

  const handleSubmit = async (values) => {
    await updateUserData(user.id, values);
    await reloadUserInfo();
  };

  const handleLogout = async () => {
    signOut();
  };

  const handleChangePassword = async (email) => {
    setShowResetPassword(true);
  };

  const handleCancelChangePassword = async (email) => {
    setShowResetPassword(false);
  };

  const showPasswordChangeSuccess = async () => {
    setShowPasswordChangeSuccessMessage(true);
  };

  const closePasswordChangeSuccess = async () => {
    setShowPasswordChangeSuccessMessage(false);
  };


  const handleResetMFA = async () => {
    setShowResetMFA(true);
  };

  const handleCancelResetMFA = async () => {
    setShowResetMFA(false);
  };

  const submitResetMFA = async () => {
    await userResetMFA(user.id, user.email);
    setShowResetMFA(false);
    setShowMFASuccssMessage(true);
  };

  const closeResetMFASuccess = async () => {
    setShowMFASuccssMessage(false);
    signOut();
  };


  const validationSchema = yup.object({
    firstName: yup.string()
      .required('First Name is required')
      .min(1, 'At least one character must be entered')
      .max(25, 'Maximum of 25 characters')
      .matches(/^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/, { message: 'Must be a valid name', excludeEmptyString: true }),
    lastName: yup.string()
      .required('Last Name is required')
      .min(2, 'At least two character must be entered')
      .max(25, 'Maximum of 25 characters')
      .matches(/^[A-Za-z]+((\s)?((\'|\-|\.)?([A-Za-z])+))*$/, { message: 'Must be a valid name', excludeEmptyString: true }),
  });

  const buildForm = (disabled) => {
    return (
      <LotusForm>
        <LotusFormItems>
          <div>Email: {user.email}</div>
          <div>Agency: {user.agencyName}</div>
          <LotusTextInput
            name="firstName"
            label="First Name"
            disabled={disabled}
          />
          <LotusTextInput
            name="middleName"
            label="Middle Name"
            disabled={disabled}
          />
          <LotusTextInput
            name="lastName"
            label="Last Name"
            disabled={disabled}
          />
          <LotusPhoneInput
            name='primaryPhone'
            label='Primary Phone'
            disabled={disabled}
          />
          <LotusPhoneInput
            name='secondaryPhone'
            label='Secondary Phone'
            disabled={disabled}
          />
          <LotusRadioGroup
            name="startPage"
            label="Start Page"
            items={allowedStartPages || []}
            disabled={disabled}
          />
        </LotusFormItems>
      </LotusForm>
    );
  }

  return initialValues && (
    <LotusPage>
      <LotusPageTitle title="My Account" />
      <LotusSpacedBlock>
        <Stack direction="row" spacing={2} alignItems="center">
          <AccountCircleIcon style={{fontSize: 110}} color="primary" />
          <Typography variant="h2" color="primary">
            {`${user.data.firstName} ${user.data.lastName}`}
          </Typography>
        </Stack>
      </LotusSpacedBlock>
        <LotusSpacedBlock>
        <InlineStaticForm
          allowEdit={true}
          headerName={`Account Details`}
          validationSchema={validationSchema}
          formValues={initialValues}
          onSubmitForm={handleSubmit}
          buildFormContent={buildForm} />
      </LotusSpacedBlock>
      <LotusSpacedBlock>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          {localAuth && (
            <Button onClick={handleChangePassword} variant="contained">
              Change Password
            </Button>
          )}
          {mfaEnabled && localAuth && (
            <Button onClick={handleResetMFA} variant="contained">
              Reset MFA
            </Button>
          )}
          <Button onClick={handleLogout} variant="contained">
            <LogoutIcon/>&nbsp;&nbsp;Logout
          </Button>
        </Stack>
      </LotusSpacedBlock>

      <ActionDialog
        maxWidth="sm"
        open={showResetPassword}
        content={
          <ChangePasswordDialog handleCancel={handleCancelChangePassword} onSuccess={showPasswordChangeSuccess} />
        } />

      <ActionDialog
        maxWidth="lg"
        open={showResetMFA}
        content={
          <ResetMFADialog handleCancel={handleCancelResetMFA} submitResetMFA={submitResetMFA}/>
        } />

      <ActionDialog
        title="Password Changed"
        content={
          <div>
            <Grid container direction="column" justifyContent="left" spacing={2} columns={1}>
              <Grid item>
                <div style={{ width: '100%' }}>Your password has been changed.</div>
              </Grid>
              <Grid item>
                <Button onClick={closePasswordChangeSuccess} variant="contained">
                  OK
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        open={showPasswordChangeSuccessMessage}
      />


      <ActionDialog
        title="MFA has been reset."
        content={
          <div>
            <Grid container direction="column" justifyContent="left" spacing={2} columns={1}>
              <Grid item>
                <div style={{ width: '100%' }}>Your MFA settings have been reset. You will now be logged out.</div>
               </Grid>
               <Grid item>
                <Button onClick={closeResetMFASuccess} variant="contained">
                  OK
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        open={showMFASuccssMessage}
      />

    </LotusPage>
  );
}
