import React from 'react';
import AgencyDynamicFormForm from './forms/AgencyDynamicFormForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function AddFormPanel({ handleBackClicked }) {
  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton variant="outlined" onClick={handleBackClicked}>
          Back
        </LotusButton>
      </LotusSpacedBlock>
      <LotusPageSection header="Add Form">
        <AgencyDynamicFormForm afterSave={handleBackClicked} />
      </LotusPageSection>
    </LotusPaper>
  );
}
