import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './UserSearchContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from '../lib/utils';

const UserSearchContext = createContext();
const initialState = {
  manageableUsers: undefined,
  totalUserCount: undefined
};

export const UserSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </UserSearchContext.Provider>
  );
};
export const useUserSearch = () => {
  const { state, dispatch } = useContext(UserSearchContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadUsers = async (paginationParams) => {
    const { offset, limit, sortOrder, searchText, filters } = paginationParams;

    const { data } = await axios.post('/api/users', {
      operationName: 'getManageableUsers',
      searchParameters: {
        limit,
        offset,
        searchText,
        filters,
        sortBy: sortOrder ? [sortOrder] : []
      }
    });

    const userResult = parseApiResult(data).body;

    return userResult;
  }

  const loadManageableUsers = async (paginationParams) => {
    try {
      addBusyBee('loadManageableUsers');

      const userResult = await loadUsers(paginationParams);

      dispatch({
        type: messageTypes.LOADING_MANAGEABLE_USERS_SUCCESS,
        payload: {
          users: userResult.users,
          totalUsers: Number(userResult.totalCount),
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadManageableUsers');
    }
  };

  const loadManageableUsersSync = async (paginationParams) => {
    try {
      addBusyBee('loadManageableUsersSync');

      const userResult = await loadUsers(paginationParams);

      return {
        users: userResult.users,
        totalUsers: userResult.totalCount,
      };
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadManageableUsersSync');
    }
  };

  return {
    ...state,
    loadManageableUsers,
    loadManageableUsersSync,
  };
};
