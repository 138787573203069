import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LotusAutocomplete from 'components/widgets/Forms/LotusAutocomplete';
import { Formik, Form } from 'formik';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import { Button } from '@lotus/components';
import { Typography } from '@mui/material';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { useLists } from 'contexts/ListsContext';
import { ProvidersProvider } from 'contexts/ProvidersContext';
import IndividualProviderSelector from 'components/widgets/Composites/IndividualProviderSelector';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusCardList from 'components/widgets/Layout/LotusCardList';
import LotusCard from 'components/widgets/Layout/LotusCard';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusSideBySide from 'components/widgets/Forms/LotusSideBySide';


const DiagnosesPanel = ({ diagnoses, onDiagnosisAdded, onDiagnosisEdited, disabled }) => {
  const [rowToEdit, setRowToEdit] = useState();
  const [rowIndexToEdit, setRowIndexToEdit] = useState();
  const [showForm, setShowForm] = useState(false);
  const { icd10Codes, loadIcd10Codes } = useLists();
  const [diagnosisOptions, setDiagnosisOptions] = useState();

  useEffect(() => {
    if (!icd10Codes) {
      loadIcd10Codes();
    }
  }, []);

  useEffect(() => {
    if (icd10Codes) {
      setDiagnosisOptions(icd10Codes);
    }
  }, [icd10Codes]);

  const handleAddClick = () => {
    const initialValues = {
      startDate: null,
      dateDiagnosed: null,
      comments: '',
      icdCode: null,
      provider: '',
    };
    setRowToEdit(initialValues);
    setShowForm(true);
    setRowIndexToEdit(undefined);
  };

  const handleEditClick = (row, index) => {
    setRowToEdit(row);
    setRowIndexToEdit(index);
    setShowForm(true);
  };

  return (
    <Grid item xs={12}>
      <PageSection 
        header="Diagnoses" 
        actions={
          <>
          {!disabled &&
          <Button onClick={handleAddClick} plusIcon>
            Add Diagnosis
          </Button>
          }
          </>
        }
      >
        {diagnoses && diagnoses.length === 0 && (
          <LotusSpacedBlock>
            <div>No diagnoses</div>
            </LotusSpacedBlock>
        )}
        <LotusCardList>
          {diagnoses && diagnoses.map((row, index) => (
              <LotusCard key={index}>
                <Grid container alignItems='center' style={{ paddingBottom: 20 }}>
                  <Grid item xs={9}>
                    <Typography variant="h3">{row.icdCode?.name}</Typography>
                  </Grid>
                  {!disabled &&
                  <Grid item xs={3} container justifyContent='flex-end'>
                    <Button onClick={() => handleEditClick(row, index)} pencilIcon>Edit</Button>
                  </Grid>
                  }
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1">Provider: {row.provider?.name || '--'}</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingLeft: '10px' }}>
                    <Typography variant="body1">Start Date: {row.startDate || '--'}</Typography>
                  </Grid>
                  <Grid item xs={3} style={{ paddingLeft: '10px' }}>
                    <Typography variant="body1">Date Diagnosed: {row.dateDiagnosed || '--'}</Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Typography variant="body1">Comments: {row.comments}</Typography>
                  </Grid>
                </Grid>
              </LotusCard>
          ))}

          <ActionDialog
            open={showForm && Boolean(rowToEdit)}
            maxWidth="lg"
            fullWidth={true}
            content={
              <Formik
                initialValues={rowToEdit}
                onSubmit={async (recordValues) => {
                  if (rowIndexToEdit !== undefined) {
                    onDiagnosisEdited(recordValues, rowIndexToEdit);
                  } else {
                    onDiagnosisAdded(recordValues);
                  }
                  setShowForm(false);
                  setRowToEdit(undefined);
                  setRowIndexToEdit(undefined);
                }}
              >
                {({handleSubmit, handleChange, values, setFieldValue}) => {
                  const handleCancel = () => {
                    setShowForm(false);
                    setRowToEdit(undefined);
                    setRowIndexToEdit(undefined);
                  }
                  return diagnosisOptions && (
                    <PageSection 
                      header={`${rowIndexToEdit !== undefined ? 'Edit' : 'Add new'} diagnosis`}
                      actions={
                        <>
                        <Button variant="text" onClick={() => {handleCancel();}}>
                          <Typography variant="h6">Cancel</Typography>
                        </Button>
                        <Button onClick={() => {handleSubmit(values);}}>
                          <Typography variant="h6">Save</Typography>
                        </Button>
                        </>
                      }
                    >
                      <Form onSubmit={handleSubmit}>
                        <LotusForm>
                          <LotusFormItems>
                            <LotusAutocomplete
                              label="Diagnosis"
                              name="icdCode"
                              options={diagnosisOptions}
                              value={values.icdCode}
                            />
                            <IndividualProviderSelector
                              name="provider"
                              label="Provider Name"
                              value={values.provider}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                            />
                            <LotusSideBySide
                              leftControl={<LotusDatePicker
                                label="Date Start"
                                name="startDate"
                                value={values.startDate}
                              />}
                              rightControl={<LotusDatePicker
                                label="Date Diagnosed"
                                name="dateDiagnosed"
                                value={values.dateDiagnosed}
                              />}
                            />
                            <LotusTextInput
                              name="comments"
                              multiline
                              minRows={5}
                              label="Comments"
                              value={values.comments}
                            />
                          </LotusFormItems>
                        </LotusForm>
                      </Form>
                    </PageSection>
                  );
                }}
              </Formik>
            }
          />
        </LotusCardList>
      </PageSection>
    </Grid>
  );
};

export default function Diagnoses({ ...props }) {
  return (
      <ProvidersProvider>
        <DiagnosesPanel {...props} />
      </ProvidersProvider>
  );
}
