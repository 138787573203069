import React, { useEffect, useState } from 'react';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { useClient } from 'contexts/ClientContext';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LotusSpacedBlock from './LotusSpacedBlock';
import LotusSpacer from './LotusSpacer';

export default function LotusProgramSwitcher({ currentProgramId, linkPrefix }) {
  const { enrolledPrograms, loadEnrollmentsForClient } = useEnrollments();
  const { client } = useClient();
  const [selectedProgramId, setSelectedProgramId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!enrolledPrograms) {
      loadEnrollmentsForClient(client.id, client.agencyId);
    }
  }, [client]);

  useEffect(() => {
    if (enrolledPrograms && client) {

      if (!currentProgramId && enrolledPrograms.length > 0) {
        navigate(`${linkPrefix}/${enrolledPrograms[0].programId}`);
      }

      if (currentProgramId && (!enrolledPrograms.find((e) => e.programId === currentProgramId))) {
        setSelectedProgramId(enrolledPrograms[0].programId);
      } else if (currentProgramId) {
        setSelectedProgramId(currentProgramId);
      }
    }
  }, [currentProgramId, enrolledPrograms]);

  const handleProgramChangedByUser = async (event, newValue) => {
    await navigate(`${linkPrefix}/${newValue}`);
  };

  return (
    <>
    {selectedProgramId && enrolledPrograms && enrolledPrograms.length > 1 &&
      <div>
        <ToggleButtonGroup value={selectedProgramId} onChange={handleProgramChangedByUser} exclusive>
          {enrolledPrograms.map((enrollment) => {
            return (
              <ToggleButton key={enrollment.programId} value={enrollment.programId}>
                {enrollment.programName}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
    }
    {enrolledPrograms && enrolledPrograms.length === 0 &&
      <>
      <div>
        <LotusSpacer/>
        <LotusSpacer/>
        <LotusSpacedBlock>
          No enrollments exist for this client.
        </LotusSpacedBlock>
      </div>
      </>
    }
    </>
  );
}
