import React, { useEffect, useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useField } from 'formik';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { Error } from '@mui/icons-material';
import { Stack } from '@mui/material';


export default function LotusRadioButtonGroup({
  name,
  label,
  helperText,
  horizontal=false,
  items,
  required, 
  dontCheckRequiredFields, 
  handleChange,
  disabled,
  readOnly,
  autoSort=true,
  excludeInactiveOrDeleted=false,
}) {
  const [allItems, setAllItems] = useState();

  const [field, meta, utils] = useField({name, validate: (val) => {
    if (required && !dontCheckRequiredFields && (val === undefined || val === null || val === '')) {
      return `${label} is required`;
    }
  }});

  const labelText = label + (required ? ' * ' : '');

  useEffect(() => {
    if (items) {
      let lst = [...items];
      if (autoSort) {
        lst = lst.sort((a, b) => {
          return a.label > b.label ? 1 : -1;
        });
      }
      if (excludeInactiveOrDeleted) {
        // Exclude inactive or deleted items unless the item is the current selection
        lst = lst.filter(itm => (itm.isActive && !itm.isDeleted) || itm.value === field.value);
      }
      setAllItems(lst);
    }
  }, [items]);

  const handleLocalChange = (e) => {
    let newValue = '';
    try {
      if (e.target.value.toLowerCase() === 'true' || e.target.value.toLowerCase() === 'false') {
        newValue = JSON.parse(e.currentTarget.value);
      } else {
        newValue = e.target.value;
      }
    } catch (e) {
      console.log(e);
    }
    if (handleChange) {
      handleChange(newValue);
    }
    utils.setTouched(true);
    utils.setValue(newValue);
  };

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly && 
        <>
          <FormLabel>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body1">
                {labelText}
              </Typography>
              {meta.touched && meta.error &&
                <Error color='error'/>
              }
            </Stack>
          </FormLabel>
          <RadioGroup
            {...field}
            row={horizontal}
            onChange={handleLocalChange}
          >
            {allItems &&
              allItems.map((option) => (
                <FormControlLabel
                  style={{paddingLeft: 14, width: 'fit-content'}}
                  key={option.value}
                  control={
                    <Radio
                      checked={field.value === option.value}
                      disabled={disabled}
                    />
                  }
                  label={option.label}
                  value={option.value.toString()}
                />
              ))}
          </RadioGroup>
        </>
      }
      {readOnly &&
       <LotusReadOnlyFieldView label={label} value={(items && items.find(x => x.value === field.value)?.label) || ''} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 14}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 14}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
