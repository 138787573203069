import React, { useEffect, useState } from 'react';
import LotusAutocomplete from 'components/widgets/Forms/LotusAutocomplete';
import { useLists } from 'contexts/ListsContext';

export default function LotusStateSelector({
  useSeedData,
  ...props
}) {
  
  const { states, loadStates } = useLists();
  const [stateList, setStateList] = useState();

  useEffect(() => {
    if (useSeedData) {
      setStateList(['WI','IL','MN']);
    } else if (!states) {
      loadStates();
    }
  }, [useSeedData]);

  useEffect(() => {
    if (states) {
      setStateList(states.map(s => s.abbreviation));
    }
  }, [states]);

  return (
    <LotusAutocomplete
      {...props}
      options={stateList || []}
    />
  );
}
