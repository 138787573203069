import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useFormikContext } from "formik";
import ClientInfoListSubsetLinker from "./ClientInfoListSubsetLinker";
import LotusRadioButtonGroup from "components/form/radioButton/LotusRadioButtonGroup";
import LotusCheckboxList from "components/form/checkbox/LotusCheckboxList";
import LotusMultiSelect from "components/form/select/LotusMultiSelect";
import LotusTextInput from "components/form/inputField/common/LotusTextInput";
import LotusItemList from "components/form/list/LotusItemList";
import LotusSelect from "components/form/select/LotusSelect";
import LotusIntegerInput from "components/form/inputField/common/LotusIntegerInput";
import LotusFloatingPointInput from "components/form/inputField/common/LotusFloatingPointInput";
import LotusStateSelector from "components/form/select/LotusStateSelector";
import LotusFormItemsRow from "components/form/LotusFormItemsRow";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";


export default function EligibilityConfigView({
  initialEligibilityStartDateList,
  annualCoveragePeriodList,
  eligibilityStartDateDayList,
  childAgenciesList,
  agencyLevelConsents,
  hivDiseaseStageList,
  medicationAssistanceLevelList,
  premiumAssistanceCoverageTypeList,
  adapServiceDenialReasons,
  eligibilityApplicationUrgencyTypes
}) {

  const {values} = useFormikContext();

  const emptyAdapSubprogram = {
    name: '',
    supportsMedicationAssistance: false,
    medicationAssistanceLevel: '',
    supportsPremiumAssistance: false,
    premiumAssistanceCoverageTypes: []
  };

  const emptyAgencyGroup = {
    name: '',
    selectedAgencies: []
  };

  const extensionOptions = [
    { label: 'No Extension', value: 'no_extension' },
    { label: 'Extension (Months)', value: 'extension_months' }
  ];
  const careApplicationOptions = [
    { label: 'All Ryan White Applications', value: 'rw_only' },
    { label: 'Applications grouped by agencies', value: 'group_by_agency' }
  ];
  const adapApplicationOptions = [
    { label: 'All Subprograms', value: 'all_subprograms' },
    { label: 'Group by Subprogram', value: 'group_by_subprogram' }
  ];

  function buildAdapSubprogramPanel(subprogram, index, disabled) {
    return (
      <LotusStackedFormItems>               
        <LotusTextInput
          disabled={disabled}
          name={`application.adapSubprograms.${index}.name`}
          label="Name"
          required
        />                 
        <LotusSwitch
          name={`application.adapSubprograms.${index}.supportsMedicationAssistance`}
          label="Supports Medication Assistance"
        />
        {medicationAssistanceLevelList && subprogram.supportsMedicationAssistance &&
          <div>       
            <LotusRadioButtonGroup
              name={`application.adapSubprograms.${index}.medicationAssistanceLevel`}
              label="Level of Assistance"
              items={medicationAssistanceLevelList}
              required
            />
          </div>
        }                  
        <LotusSwitch
          name={`application.adapSubprograms.${index}.supportsPremiumAssistance`}
          label="Supports Premium Assistance"
        />
        {premiumAssistanceCoverageTypeList && subprogram.supportsPremiumAssistance &&
          <div>       
            <LotusCheckboxList
              name={`application.adapSubprograms.${index}.premiumAssistanceCoverageTypes`}
              label="Assistance Coverage Type"
              items={premiumAssistanceCoverageTypeList}
              required
            />
          </div>
        }
      </LotusStackedFormItems>
    );
  }

  function buildAgencyGroupPanel(item, index, disabled) {
    return (
      <LotusStackedFormItems>                
        <LotusTextInput
          disabled={disabled}
          name={`application.agencyGroups.${index}.name`}
          label="Group Name"
          required
        />    
        <LotusMultiSelect
          name={`application.agencyGroups.${index}.selectedAgencies`}
          label="Select Agencies"
          items={childAgenciesList}
          value={item.selectedAgencies}
          required
        />
      </LotusStackedFormItems>
    );
  }

  return (
    <>
      <LotusSubsectionHeader title='Applicant - Basic Information' divider={false}/>
      <LotusSubsectionContent>
        <LotusSwitch
          name="application.showPreferredName"
          label="Show Preferred Name"
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Gender'/>
      <LotusSubsectionContent>
      <LotusSwitch
        name="application.showGenderPronouns"
        label="Show Gender Pronouns"
      />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Contact Phone & Email'/>
      <LotusSubsectionContent>
        <LotusSwitch
          name="application.showCommunicationNotes"
          label="Show Communication Notes"
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Languages'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.showSecondaryLanguage"
            label="Show Secondary Language"
          />
          <LotusSwitch
            name="application.showPreferredWrittenCommLanguageId"
            label="Show Preferred Written Communications Language"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Other Demographics'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.showLawfulResidentDate"
            label="Show Date became Lawfully Present Resident"
          />
          <LotusSwitch
            name="application.showCitizenshipStatus"
            label="Show Citizenship Status"
          />
          <LotusSwitch
            name="application.showIsTobaccoUser"
            label="Show Tobacco Use"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Additional Information'/>
      <LotusSubsectionContent>
        <LotusSwitch
          name="application.showAdditionalAssistanceInterests"
          label="Show Additional Assistance Interests"
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Applicant - Urgent Application'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.showIsUrgentApplication"
            value={values.application.showIsUrgentApplication}
            label="Show Is Urgent Application"
            labelPlacement="start"
          />
          {values.application.showIsUrgentApplication &&
            <ClientInfoListSubsetLinker
              name='application.selectedEligibilityApplicationUrgencyTypes' 
              masterList={eligibilityApplicationUrgencyTypes}
              title="Urgency Types for Agency"
            />
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Addresses'/>
      <LotusSubsectionContent>
        <LotusSwitch
          name="application.showSecondaryAddress"
          label="Show Secondary Address"
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Health'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.showGeneralHealthInfo"
            label="Show General Health Info"
          />
          <LotusSwitch
            name="application.showCurrentArvCount"
            label="Show Current ARV"
          />
          <LotusSwitch
            name="application.showCd4AndViralLoads"
            label="Show CD4 and Viral Loads"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Insurance'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.showPrivateInsurancePremiumOverdueAmount"
            label="Show Private Insurance Overdue Premium Amount"
          />
          <LotusSwitch
            name="application.showPrivateInsurancePremiumOverdueMonths"
            label="Show Private Insurance Permium Overdue Months"
          />
          <LotusSwitch
            name="application.showMedicarePremiumOverdueAmount"
            label="Show Medicare Overdue Premium Amount"
          />
          <LotusSwitch
            name="application.showMedicarePremiumOverdueMonths"
            label="Show Medicare Permium Overdue Months"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='ADAP Subprograms'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusSwitch
            name="application.allowMultipleAdapSubprogramEnrollments"
            label="Allow Multiple ADAP Subprogram Enrollments"
          />
          <LotusItemList
            name="application.adapSubprograms"
            itemName="ADAP Subprogram"
            buildItemDetail={(item, index, isDisabled) => buildAdapSubprogramPanel(item, index, isDisabled)}
            buildItemTemplate={() => {return {...emptyAdapSubprogram}}}
          />
          <ClientInfoListSubsetLinker
            name='application.selectedAdapServiceDenialReasons' 
            masterList={adapServiceDenialReasons}
            title="ADAP Service Denial Reasons for Agency"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Eligibility Configuration'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          {initialEligibilityStartDateList && 
            <LotusSelect
              name="application.selectedInitialEligibilityStartDateType"
              items={initialEligibilityStartDateList}
              label="Initial Eligibility Start Date"
              autoSort={false}
            />
          }
          {annualCoveragePeriodList &&
            <LotusSelect
              name="application.selectedAnnualCoveragePeriodType"
              items={annualCoveragePeriodList}
              label="Annual Coverage Type"
              autoSort={false}
            />
          }   
          {annualCoveragePeriodList && annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && s.label === 'Pick a Date'}).length > 0 &&
            <div>
              <LotusFormItemsRow>
                <div style={{width: 200}}>
                  <LotusSelect
                    name="application.selectedEligibilityStartDateMonth"
                    autoSort={false}
                    items={[
                      {label: 'January', value: '01'},
                      {label: 'February', value: '02'},
                      {label: 'March', value: '03'},
                      {label: 'April', value: '04'},
                      {label: 'May', value: '05'},
                      {label: 'June', value: '06'},
                      {label: 'July', value: '07'},
                      {label: 'August', value: '08'},
                      {label: 'September', value: '09'},
                      {label: 'October', value: '10'},
                      {label: 'November', value: '11'},
                      {label: 'December', value: '12'}
                    ]}
                    label="Month"
                    required
                  />
                </div>
                <div style={{width: 100}}>
                  <LotusSelect
                    name="application.selectedEligibilityStartDateDay"
                    autoSort={false}
                    items={eligibilityStartDateDayList}
                    label="Day"
                    required
                  />
                </div>
              </LotusFormItemsRow>
            </div>
          }
          {annualCoveragePeriodList && annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Pick a Date' || s.label === 'Birth Date')}).length > 0 &&
            <div>
              <LotusStackedFormItems>
                <LotusRadioButtonGroup
                  name="application.selectedInitialExtensionType"
                  label="Initial Coverage Period"
                  items={extensionOptions}
                  required
                />
                {values.application.selectedInitialExtensionType === 'extension_months' &&
                  <LotusIntegerInput
                    name="application.selectedExtensionMonths"
                    label="Number of Months"
                    required
                    maxValue={99}
                  />
                }
              </LotusStackedFormItems>
            </div>
          }
          <LotusIntegerInput
            name="application.selectedTimeBeforeRecert"
            label="Recertification Renewal Period (Months)"
            required
            maxValue={99}
          />
          <LotusIntegerInput
            name="application.expirationDaysForStartedButUnsubmittedApps"
            label="Automatic Cancellation Period for Started but Unsubmitted Applications (Days)"
            minValue={1}
            maxValue={365}
          />
          <LotusTextInput
            name="application.expirationDaysForPendingApps"
            label="Automatic Cancellation Period for Pended Applications (Days)"
            minValue={1}
            maxValue={365}
          />
          <LotusTextInput
            name="application.selectedAssistanceProgramName"
            label="Assistance Program Name"
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Ryan White Scoring'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusFloatingPointInput
            name="rwScoring.fplLimit"
            label="FPL % Limit"
          />
          <LotusStateSelector
            name="rwScoring.state"
            label='State'
          />
          {hivDiseaseStageList &&
            <LotusMultiSelect
              name="rwScoring.diseaseStages"
              label="Allowed Disease Stages"
              items={hivDiseaseStageList}
              readOnlyRows={2}
            />
          }
        </LotusStackedFormItems>
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Consents'/>
      <LotusSubsectionContent>
        <LotusMultiSelect
          name={`application.requiredConsents`}
          label="Required Consents"
          items={agencyLevelConsents}
        />
      </LotusSubsectionContent>
      <LotusSubsectionHeader title='Application List'/>
      <LotusSubsectionContent>
        <LotusStackedFormItems>
          <LotusRadioButtonGroup
            name="application.selectedCareApplicationType"
            label="Care Applications"
            items={careApplicationOptions}
            required
          />
          {values.application.selectedCareApplicationType === 'group_by_agency' &&
            <div>
              <LotusItemList
                name="application.agencyGroups"
                itemName="New Group"
                label="Agency Groups"
                buildItemDetail={(item, index, isDisabled) => buildAgencyGroupPanel(item, index, isDisabled)}
                buildItemTemplate={() => {return {...emptyAgencyGroup}}}
              />
            </div>
          }
          <LotusRadioButtonGroup
            name="application.selectedADAPApplicationType"
            label="ADAP Applications"
            items={adapApplicationOptions}
            required
          />
        </LotusStackedFormItems>
      </LotusSubsectionContent>
    </>
  );
}
