import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useParams, useNavigate } from 'react-router-dom';
import AssessmentsPage from './Assessments/AssessmentsPage';
import CarePlanPage from './CarePlan';
import DocumentationPage from './Documentation';
import { ClientAssessmentsProvider } from 'contexts/ClientAssessmentsContext';
import { useProgram } from 'contexts/ProgramContext';
import LotusMainSubTabs from 'components/widgets/Layout/LotusMainSubTabs';
import LotusProgramSwitcher from 'components/widgets/Layout/LotusProgramSwitcher';

export default function CareManagement() {
  const { clientId, lvl2: programId, lvl3: selectedTab } = useParams();
  const navigate = useNavigate();
  const { currentProgram, setProgram } = useProgram();
  const [tabs, setTabs] = useState();

  useEffect(() => {
    if (programId && (!currentProgram || currentProgram.id !== programId)) {
      setProgram(programId);
    }
  }, [programId]);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId) {
      
      if (!selectedTab) {
        navigate(`/client/${clientId}/caremanagement/${programId}/documentation/all`);
      }

      const tabs = [];
      
      if (currentProgram.careManagementOptions) {
        if (currentProgram.careManagementOptions['assessments']) {
          tabs.push({label: 'Assessments', link: `/client/${clientId}/caremanagement/${programId}/assessments/list/current`});
        }
        if (currentProgram.careManagementOptions['carePlan']) {
          tabs.push({label: 'Care Plan', link: `/client/${clientId}/caremanagement/${programId}/careplan/open`});
        }
        if (currentProgram.careManagementOptions['referrals']) {
          tabs.push({label: 'Referrals', link: `/client/${clientId}/caremanagement/${programId}/referrals`});
        }
        tabs.push({label: 'Documentation', link: `/client/${clientId}/caremanagement/${programId}/documentation/list/all`});
      } else {
        tabs.push({label: 'Documentation', link: `/client/${clientId}/caremanagement/${programId}/documentation/list/all`});
      }
      setTabs(tabs);
    }
  }, [currentProgram, programId]);

  return (
    <Grid container alignItems="center">
      {!programId && <LotusProgramSwitcher linkPrefix={`/client/${clientId}/caremanagement`} />}
      {programId && tabs && selectedTab && currentProgram && currentProgram.id === programId &&
        <>
        <Grid item xs={8}>
          <LotusMainSubTabs tabs={tabs} selectedTabName={selectedTab} />
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <LotusProgramSwitcher currentProgramId={programId} linkPrefix={`/client/${clientId}/caremanagement`} />
        </Grid>
        <Grid item xs={12}>
          {'careplan' === selectedTab && <CarePlanPage />}
          {'assessments' === selectedTab && (
            <ClientAssessmentsProvider>
              <AssessmentsPage />
            </ClientAssessmentsProvider>
          )}
          {'documentation' === selectedTab && <DocumentationPage />}
        </Grid>
        </>
      }
    </Grid>
  );
}
