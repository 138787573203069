import React from 'react';
import Typography from '@mui/material/Typography';

export default function MFAResetConfirmPanel() {
  return (
    <>
      <Typography variant="h6">Password and MFA Configuration Reset</Typography>

      <br />

      <p>
        <Typography variant="body">
          This user's password and MFA configuration have been reset. The user will receive an email to set a new password and will be prompted to configure their MFA application.
        </Typography>
      </p>
    </>
  );
}
