import React from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { TIMELINE_STEP, TIMELINE_COLOR } from 'lib/eligibilityEnums';
import EligibilityReviewStepTimeline from './EligibilityReviewStepTimeline';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';

const labelBaseStyles = (headerColor) => {
  return {
    "& .MuiStepLabel-root": {
      marginTop: '-40px'
    },
    "& .MuiStepLabel-label.Mui-disabled": {
      color: headerColor || TIMELINE_COLOR.GREY
    }
  }
};
const stepButtonStyles = {
  marginTop: 2
}
const timelineButtonStyles = {
  marginLeft: 10,
  marginTop: -2
}
const labelContainerStyles = {
  "& .MuiStepIcon-root": {
    display: 'none'
  },
  "& .MuiStepLabel-labelContainer": {
    marginTop: "-12%",
    marginLeft: "-30%"
  }
};
const stepStyles = (iconColor) => {
  return {
    "& .MuiStepConnector-root": {
      margin: "13% 23%"
    },
    "& .MuiStepIcon-root": {
      color: iconColor
    }
  }
};
const noStepIcon = () => {
  return {
    "& .MuiStepIcon-root": {
      display: "none",
    }
  }
}

export default function EligibiityStepper({
  activeStep,
  clientId,
  appId,
  stepperConfig,
  reviewTimelineConfig
}) {
  const navigate = useNavigate();
  const { createEligibilityApplication } = useEligibilityApplication();

  const viewOrStartNewApplication = async () => {
    if (appId) {
      navigate(`/client/${clientId}/eligibility/application/${appId}`);
    } else {
      const id = await createEligibilityApplication(clientId, true, false, false);
      if (id) {
        navigate(`/client/${clientId}/eligibility/application/${id}`);
      }
    }
  }

  const startRecertApplication = async () => {
    const id = await createEligibilityApplication(clientId, false, true, false);
    if (id) {
      navigate(`/client/${clientId}/eligibility/application/${id}`);
    }
  }

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {stepperConfig && stepperConfig.map((step, index) => {
        const stepProps = {};
        if (step.key === TIMELINE_STEP.REVIEW_STEP) {
          stepProps.optional = (
            <LotusFormItem>
              <EligibilityReviewStepTimeline timelineConfig={reviewTimelineConfig} />
              {step.buttonVisible &&
                <LotusButton
                    onClick={() => viewOrStartNewApplication()}
                    disabled={!step.buttonEnabled || activeStep < 0}
                    sx={timelineButtonStyles}>
                  {step.buttonText}
                </LotusButton>
              }
            </LotusFormItem>
          );
        } else {
          stepProps.optional = (
            <LotusFormItem>
              <Typography variant="body2" style={{color: step.labelColor || TIMELINE_COLOR.GREY}}>
                {step.label}
              </Typography>
              <Typography variant="body2" style={{color: step.dateColor || TIMELINE_COLOR.GREY}}>
                {step.dateVisible ? step.date : ''}
              </Typography>
              {step.buttonVisible &&
                <LotusButton
                    onClick={() => step.key === TIMELINE_STEP.APPLICATION_STEP ? viewOrStartNewApplication() : startRecertApplication()}
                    disabled={!step.buttonEnabled || activeStep < 0}
                    sx={stepButtonStyles}>
                  {step.buttonText}
                </LotusButton>
              }
            </LotusFormItem>
          );
        }
        return (
          <Step
              completed={index < activeStep}
              sx={step.hideIcon ? [stepStyles(step.iconColor), noStepIcon()] : stepStyles(step.iconColor)}
              key={step.header}>
            <StepLabel sx={step.key === TIMELINE_STEP.REVIEW_STEP ? [labelBaseStyles(step.headerColor), labelContainerStyles] : labelBaseStyles(step.headerColor)} {...stepProps}>
              {step.header}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  )
}
