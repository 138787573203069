import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@lotus/components';
import { useAuthState } from 'contexts/AuthProvider';
import { useClient } from 'contexts/ClientContext';
import { useProgram } from 'contexts/ProgramContext';
import { useClientDocumentation } from 'contexts/ClientDocumentationContext';
import DocumentList from './DocumentList';
import ProgressNoteForm from './ProgressNoteForm';
import ActivityLogForm from './ActivityLogForm';
import ContactForm from './ContactForm';
import MeetingForm from './MeetingForm';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusListFilterTabs from 'components/widgets/Layout/LotusListFilterTabs';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { linkifyTabName } from 'lib/formatting';

export default function Index() {
  const [backView, setBackView] = useState('all');
  const [readOnly, setReadOnly] = useState(true);
  const [documents, setDocuments] = useState();
  const [tag, setTag] = useState(moment()); // for use in hack to programmatically trigger close of expanded row

  const [tabs, setTabs] = useState();
  const { clientId, lvl2: programId, lvl4: view, lvl5: documentType } = useParams();
  const navigate = useNavigate();
  const { verifyPermission, verifyEditProgramPermission } = useAuthState();
  const { client } = useClient();
  const { currentProgram, currentProgramEnrollmentStatuses, loadProgramEnrollmentPicklists } = useProgram();
  const { enrolledPrograms, findEnrollment } = useEnrollments(); // assuming these are loaded via the proram switcher
  const [enrollmentStatus, setEnrollmentStatus] = useState();

  const [contactName, setContactName] = useState();
  const [contactNamePlural, setContactNamePlural] = useState();
  const [activityLogName, setActivityLogName] = useState();
  const [activityLogNamePlural, setActivityLogNamePlural] = useState();
  const [progressNoteName, setProgressNoteName] = useState();
  const [progressNoteNamePlural, setProgressNoteNamePlural] = useState();
  const [meetingName, setMeetingName] = useState();
  const [meetingNamePlural, setMeetingNamePlural] = useState();
  const [namesSet, setNamesSet] = useState();

  const {
    contacts, loadContactsForClientAndProgram,
    meetings, loadMeetingsForClientAndProgram,
    activityLogs, loadActivityLogsForClientAndProgram,
    progressNotes, loadProgressNotesForClientAndProgram
  } = useClientDocumentation();

  useEffect(() => {
    if (currentProgram && !currentProgramEnrollmentStatuses) {
      loadProgramEnrollmentPicklists();
    }
  }, [currentProgram]);
  
  useEffect(() => {
    if (currentProgram) {
      setContactName(`${currentProgram.contactConfig.displayName || 'Contact'}`);
      setContactNamePlural(`${currentProgram.contactConfig.displayNamePlural || 'Contacts'}`);
      setActivityLogName(`${currentProgram.activityLogConfig.displayName || 'Activity Log'}`);
      setActivityLogNamePlural(`${currentProgram.activityLogConfig.displayNamePlural || 'Activity Logs'}`);
      setProgressNoteName('Progress Note');
      setProgressNoteNamePlural('Progress Notes');
      setMeetingName(`${currentProgram.meetingConfig.meetingDisplayName || 'Meeting'}`);
      setMeetingNamePlural(`${currentProgram.meetingConfig.meetingDisplayName || 'Meeting'}s`);
      setNamesSet(true);
    }
  }, [currentProgram]);

  useEffect(() => {
    if (!view || !documentType) {
      navigate(`/client/${clientId}/caremanagement/${programId}/documentation/list/All`);
      return;
    }

    if (namesSet && currentProgram) {
      const tabList = [
        {label: 'All', link: `/client/${clientId}/caremanagement/${programId}/documentation/list/All/`}
      ];
      if (currentProgram.contactConfig.enabled) {
        tabList.push({label: contactNamePlural, link: `/client/${clientId}/caremanagement/${programId}/documentation/list/${linkifyTabName(contactNamePlural)}`});
      }
      if (currentProgram.activityLogConfig.enabled) {
        tabList.push({label: activityLogNamePlural, link: `/client/${clientId}/caremanagement/${programId}/documentation/list/${linkifyTabName(activityLogNamePlural)}`});
      }
      if (currentProgram.progressLogConfig.enabled) {
        tabList.push({label: progressNoteNamePlural, link: `/client/${clientId}/caremanagement/${programId}/documentation/list/${linkifyTabName(progressNoteNamePlural)}`});
      }
      if (currentProgram.meetingConfig.meetingsEnabled) {
        tabList.push({label: meetingNamePlural, link: `/client/${clientId}/caremanagement/${programId}/documentation/list/${linkifyTabName(meetingNamePlural)}`});
      }
      setTabs(tabList);
    }
  }, [view, documentType, currentProgram, contactName, namesSet]);

  useEffect(() => {
    if (currentProgram && currentProgramEnrollmentStatuses && enrolledPrograms) {
      const enrollment = findEnrollment(programId);
      const status = currentProgramEnrollmentStatuses.find(s => s.id === enrollment.programEnrollmentStatusId);
      setEnrollmentStatus(status);
    }
  }, [currentProgram, currentProgramEnrollmentStatuses, enrolledPrograms]);

  const addProgressNoteClick = async () => {
    setBackView(`list/${progressNoteNamePlural}`);
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/create/${progressNoteName}`);
  };

  const addActivityLogClick = async () => {
    setBackView(`list/${activityLogNamePlural}`);
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/create/${activityLogName}`);
  };

  const addContactClick = async () => {
    setBackView(`list/${contactNamePlural}`);
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/create/${contactName}`);
  };

  const addMeetingClick = async () => {
    setBackView(`list/${meetingNamePlural}`);
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/create/${meetingName}`);
  };

  const goBack = async () => {
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/${backView}`);
  };

  const afterCreateDocument = async () => {
    await navigate(`/client/${clientId}/caremanagement/${programId}/documentation/${backView}`);
  };

  const getDocumentTypeToShow = () => {
    if (linkifyTabName(documentType) === linkifyTabName(progressNoteNamePlural)) {
      return progressNoteName;
    }
    if (linkifyTabName(documentType) === linkifyTabName(activityLogNamePlural)) {
      return activityLogName;
    }
    if (linkifyTabName(documentType)=== linkifyTabName(contactNamePlural)) {
      return contactName;
    }
    if (linkifyTabName(documentType) === linkifyTabName(meetingNamePlural)) {
      return meetingName;
    }
    return null;
  };

  const updateTag = async () => {
    setTag(moment());
  };

  const updateTagAfterEdit = async () => {
    await updateTag();
  };

  // note: need to load in this component or else the list data always gets queried again
  // after a create because DocumentList gets unmounted and remounted
  useEffect(() => {
    if (client && currentProgram && enrollmentStatus) {
      if (currentProgram.contactConfig.enabled) {
        loadContactsForClientAndProgram(clientId, programId);
      }
      if (currentProgram.activityLogConfig.enabled) {
        loadActivityLogsForClientAndProgram(clientId, programId);
      }
      if (currentProgram.progressLogConfig.enabled) {
        loadProgressNotesForClientAndProgram(clientId, programId);
      }
      if (currentProgram.meetingConfig.meetingsEnabled) {
        loadMeetingsForClientAndProgram(clientId, programId);
      }

      if (enrollmentStatus) {
        setReadOnly(verifyPermission(PERMISSIONS.EDIT_CARE_MANAGEMENT) ? (!verifyEditProgramPermission(programId) || !enrollmentStatus.allowDocumentationUpdates) : false);
      }
    }
  }, [client, currentProgram, enrollmentStatus]);

  useEffect(() => {
    let docList = [];

    if (!view || !documentType || !namesSet) {
      return;
    }

    if (progressNotes && programId in progressNotes) {
      let progressNoteDocs = progressNotes[programId].map((note) => {
        let doc = {
          id: note.id,
          recordedDate: note.recordedDate,
          writtenByName: note.writtenByName,
          noteStart: note.notes && (note.notes.length > 47 ? note.notes.substr(0, 47) + ' ...' : note.notes),
          documentType: progressNoteName,
          tag: tag,
        };
        return doc;
      });

      docList.push(...progressNoteDocs);
    }

    if (activityLogs && programId in activityLogs) {
      let activityLogDocs = activityLogs[programId].map((log) => {
        let doc = {
          id: log.id,
          recordedDate: log.recordedDate,
          writtenByName: log.conductorName,
          noteStart: log.activityLogSubjectName,
          documentType: activityLogName,
          tag: tag,
        };
        return doc;
      });

      docList.push(...activityLogDocs);
    }

    if (contacts && programId in contacts) {
      let contactDocs = contacts[programId].map((contact) => {
        let doc = {
          id: contact.id,
          recordedDate: contact.recordedDate,
          writtenByName: contact.conductedByName || contact.interviewerName,
          noteStart: ((contact.briefDescription && 
                        (contact.briefDescription.length > 47 ? contact.briefDescription.substr(0, 47) + ' ...' : contact.briefDescription)) ||
                      (contact.fullDescription && 
                        (contact.fullDescription.length > 47 ? contact.fullDescription.substr(0, 47) + ' ...' : contact.fullDescription))),
          documentType: contactName,
          tag: tag,
        };
        return doc;
      });

      docList.push(...contactDocs);
    }

    if (meetings && programId in meetings) {
      let meetingDocs = meetings[programId].map((meeting) => {
        let doc = {
          id: meeting.id,
          recordedDate: meeting.dueDate,
          writtenByName: meeting.schedulerName,
          noteStart: meeting.notes && (meeting.notes.length > 47 ? meeting.notes.substr(0, 47) + ' ...' : meeting.notes),
          documentType: meetingName,
          tag: tag,
        };
        return doc;
      });

      docList.push(...meetingDocs);
    }

    let documentTypeToShow = getDocumentTypeToShow();
    docList = docList.filter((d) => !documentTypeToShow || d.documentType === documentTypeToShow);
    docList.sort((a, b) => {return moment(b.recordedDate,'MM/DD/YYYY') - moment(a.recordedDate,'MM/DD/YYYY');});

    setDocuments(docList);
  }, [progressNotes, contacts, meetings, activityLogs, tag, view, documentType, namesSet]);

  return currentProgram && view && documentType && tabs && namesSet && (
    <LotusPageSection 
      header="Documentation" 
      actions={
        <>
        {currentProgram.contactConfig.enabled && (
          <Button disabled={readOnly} onClick={addContactClick}>
            Add {contactName}
          </Button>
        )}
        {currentProgram.activityLogConfig.enabled && (
          <Button disabled={readOnly} onClick={addActivityLogClick}>
            Add {activityLogName}
          </Button>
        )}
        {currentProgram.progressLogConfig.enabled && (
        <Button disabled={readOnly} onClick={addProgressNoteClick}>
          Add {progressNoteName}
        </Button>
        )}
        {currentProgram.meetingConfig.meetingsEnabled && (
          <Button disabled={readOnly} onClick={addMeetingClick}>
            Add {meetingName}
          </Button>
        )}
        </>
      }>
        {view === 'list' && (
          <>
          <LotusListFilterTabs tabs={tabs} selectedTabName={documentType} />
          <DocumentList
            readOnly={readOnly}
            documents={documents}
            afterEdit={updateTagAfterEdit}
            afterCancel={updateTag}
            meetingDisplayName={meetingName}
            contactDisplayName={contactName}
            activityLogDisplayName={activityLogName}
            progressNoteDisplayName={progressNoteName}
          />
          </>
        )}
        {view === 'create' && documentType === progressNoteName && !readOnly && (
          <ProgressNoteForm
            readOnly={readOnly}
            afterSave={afterCreateDocument}
            handleCancel={goBack}
            progressNoteDisplayName={progressNoteName}
          />
        )}
        {view === 'create' && documentType === activityLogName && !readOnly && (
          <ActivityLogForm
            readOnly={readOnly}
            afterSave={afterCreateDocument}
            handleCancel={goBack}
            activityLogDisplayName={activityLogName}
          />
        )}
        {view === 'create' && documentType === contactName && !readOnly && (
          <ContactForm
            readOnly={readOnly}
            afterSave={afterCreateDocument}
            handleCancel={goBack}
            contactDisplayName={contactName}
          />
        )}
        {view === 'create' && documentType === meetingName && !readOnly && (
          <MeetingForm
            readOnly={readOnly}
            afterSave={afterCreateDocument}
            handleCancel={goBack}
            meetingDisplayName={meetingName}
          />
        )}
      </LotusPageSection>
  )
}
