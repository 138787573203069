import React, { useEffect } from 'react';
import { useAuthState } from 'contexts/AuthProvider';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function AgencyLinker() {
  const { userAgency } = useUserAgency();
  const { agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();
  const { verifyPermission } = useAuthState();

  useEffect(() => {
    if (!agenciesForGrantee) {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, [agenciesForGrantee]);

  return agenciesForGrantee && (
    <LotusCheckboxList
      name="selectedAgencies"
      items={agenciesForGrantee.filter(a => a.id !== userAgency.granteeAgencyId).map(pt => {return {label: pt.name, value: pt.id}})}
      disabled={!verifyPermission('modify_agency')}
    />
  );
}
