import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useAppStatus } from 'contexts/AppStatusContext';
import { FilesProvider, useFiles } from 'contexts/FilesContext';
import { useUserAgency } from 'contexts/UserAgencyContext';

import txtPng from 'components/icons/txt.png';
import csvPng from 'components/icons/csv.png';
import excelPng from 'components/icons/excel.png';
import wordPng from 'components/icons/word.png';
import tifPng from 'components/icons/tif.png';
import { DOC, DOCX, XLS, XLSX, TXT, TIF, TIFF, CSV, NO_PREVIEW_TYPES } from 'lib/docTypes';
import { Stack } from '@mui/material';
import { Button } from '@lotus/components';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const LotusFileViewerPanel = ({ agencyId, clientId, subfolderName, file, handleClose, title, metaData, compactMode }) => {
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  const { userAgency } = useUserAgency();
  const [data, setData] = useState([]);

  const [singleFile, setSingleFile] = useState([]);
  const [fileName, setFileName] = useState([]);

  const [openInTabDisabled, setOpenInTabDisabled] = useState(false);
  const [downloadDisabled, setDownloadDisabled] = useState(false)
  const { getSignedURLForDownload, downloadFileSync } = useFiles();


  const downloadToLocal = async (fileToDownload) => {
    const f = fileToDownload ? fileToDownload : data[0];
    const fileName = f.uniqueName || f.uniqueFileName;
    const displayName = f.fileName || f.displayName
    const base64 = await downloadFileSync(userAgency.granteeAgencyId, clientId, agencyId, subfolderName, fileName);

    let a = document.createElement("a");
    a.download = displayName;
    a.href = "data:" + f.fileType + ";base64," + base64;
    a.click();
  };

  const openInTab = (fileToDownload) => {
    const f = fileToDownload ? fileToDownload : data[0];

    if (f.fileType.startsWith('application/pdf')) {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        '<embed type="application/pdf" src=' + f.signedUrl + '#toolbar=0 width="100%" height="100%" />'
      )
    } else {
      let image = new Image();
      image.src = f.signedUrl;
      let w = window.open("");
      w.document.write(image.outerHTML);
    }
  };

  useEffect(() => {

    const loadFiles = async () => {
      try {
        addBusyBee('downloadingFile');

        let downloadedFiles = [];

         for await (const f of file) {  
          if (f.uniqueName || f.uniqueFileName) {
            const fileType = f.type || f.fileType;
            const fileName = f.uniqueName || f.uniqueFileName;
            const signedUrl = await getSignedURLForDownload(userAgency.granteeAgencyId, clientId, agencyId, subfolderName, fileName, fileType);
            let uploadedFile = { ...f, fileType: fileType, signedUrl: signedUrl }
            downloadedFiles.push(uploadedFile);
          } else {
            setOpenInTabDisabled(true);
            setDownloadDisabled(true);
          }
        }

        if (singleFile) {
          const t = file[0].type || file[0].fileType;
          if (file[0].type || file[0].fileType) {
            setOpenInTabDisabled(NO_PREVIEW_TYPES.includes(t));
          } else {
            setOpenInTabDisabled(true);
          }
          setFileName(file[0].fileName || file[0].displayName);
        }

        setData(downloadedFiles);
      } catch (error) {
        console.log(error);
        setError('Error viewing file');
      } finally {
        removeBusyBee('downloadingFile');
      }
    };

    if (file && userAgency) {
      if (file.length > 1) {
        setSingleFile(false);
      } else {
        setSingleFile(true);
      }
      loadFiles();
    }
  }, [userAgency]);

  return (
    <Grid container>
      <Grid item xs={12} container style={{ paddingBottom: 10 }}>
        <Grid item xs={12} alignItems="center" container style={{ paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20 }}>
          <Grid item xs={6}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent='flex-end'>
            {singleFile && <Button variant="outlined" disabled={downloadDisabled} onClick={() => { downloadToLocal(null); }}>DOWNLOAD<DownloadIcon /></Button>}
            {singleFile && !compactMode && <Button variant="outlined" disabled={openInTabDisabled} onClick={() => { openInTab(null); }}>OPEN IN TAB<OpenInNewIcon /></Button>}
            <Button variant="outlined" onClick={handleClose}>CLOSE<CloseIcon /></Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {singleFile &&
            <Grid item xs={12} style={{ paddingBottom: 20 }}>
              <Stack direction='row'>
                <Typography variant="body2">File:&nbsp;</Typography>
                <Typography variant='body2'>{fileName}</Typography>
              </Stack>
            </Grid>
          }

          {metaData &&
            <Grid container>
              <Grid item xs={12} container style={{ paddingBottom: 20 }}>
                {metaData.field1 &&
                  <Grid item xs={6}>
                    <Typography variant='body2'>{metaData.field1.label}&nbsp;{metaData.field1.value}</Typography>
                  </Grid>
                }
                {metaData.field2 &&
                  <Grid item xs={6}>
                    <Typography variant='body2'>{metaData.field2.label}&nbsp;{metaData.field2.value}</Typography>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12} container style={{ paddingBottom: 20 }}>
                {metaData.field3 &&
                  <Grid item xs={6}>
                    <Typography variant='body2'>{metaData.field3.label}&nbsp;{metaData.field3.value}</Typography>
                  </Grid>
                }
                {metaData.field4 &&
                  <Grid item xs={6}>
                    <Typography variant='body2'>{metaData.field4.label}&nbsp;{metaData.field4.value}</Typography>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12} container style={{ paddingBottom: 20 }}>
                {metaData.field5 &&

                  <Grid item xs={12} style={{ paddingBottom: 20 }}>
                    <Typography variant='body2'>{metaData.field5.label}&nbsp;{metaData.field5.value}</Typography>
                  </Grid>
                }

              </Grid>
            </Grid>
          }

          <Grid item xs={12}>
            <Grid item xs={12}>

              {data && data.map(f => {

                if (f.fileType && f.fileType.startsWith('image/') && !NO_PREVIEW_TYPES.includes(f.fileType)) {
                  return (
                    <Grid key={f.uniqueFileName}> {!singleFile &&
                      <Stack direction='row'>
                        <Typography variant="body2">File:&nbsp;</Typography>
                        <Typography width={500} variant='body2'>{f?.displayName}</Typography>
                        <Grid container justifyContent='flex-end'>
                          {!singleFile && <Button variant="outlined" onClick={() => { downloadToLocal(f); }}>DOWNLOAD<DownloadIcon /></Button>}
                          {!singleFile && !compactMode && <Button variant="outlined" onClick={() => { openInTab(f); }}>OPEN IN TAB<OpenInNewIcon /></Button>}
                        </Grid>
                      </Stack>
                    }
                      <img width='100%' height='100%' src={f.signedUrl} />
                    </Grid>
                  );
                }
                else if (NO_PREVIEW_TYPES.includes(f.fileType)) {

                  return (
                    <Grid key={f.uniqueFileName}> {!singleFile &&
                      <Stack direction='row'>
                        <Typography variant="body2">File:&nbsp;</Typography>
                        <Typography width={500} variant='body2'>{f?.displayName}</Typography>
                        <Grid container justifyContent='flex-end'>
                          {!singleFile && <Button variant="outlined" onClick={() => { downloadToLocal(f); }}>DOWNLOAD<DownloadIcon /></Button>}
                          {!singleFile && !compactMode && <Button variant="outlined" disabled={true} >OPEN IN TAB<OpenInNewIcon /></Button>}
                        </Grid>
                      </Stack>
                    }
                      {!compactMode &&
                      <img alt={f.fileName}
                        src={
                          f.fileType === TXT ? txtPng :
                            f.fileType === CSV ? csvPng :
                              [XLS, XLSX].includes(f.fileType) ? excelPng :
                                [TIF, TIFF].includes(f.fileType) ? tifPng :
                                  [DOC, DOCX].includes(f.fileType) ? wordPng :
                                    null}
                      />
                      }
                    </Grid>
                  )
                }
                else {
                  return (
                    <Grid key={f.uniqueFileName}> {!singleFile &&

                      <Stack direction='row'>
                        <Typography variant="body2">File:&nbsp;</Typography>
                        <Typography width={500} variant='body2'>{f?.displayName}</Typography>
                        <Grid container justifyContent='flex-end'>
                          {!singleFile && <Button variant="outlined" onClick={() => { downloadToLocal(f); }}>DOWNLOAD<DownloadIcon /></Button>}
                          {!singleFile && !compactMode && <Button variant="outlined" onClick={() => { openInTab(f); }}>OPEN IN TAB<OpenInNewIcon /></Button>}
                        </Grid>
                      </Stack>
                    }

                      <embed type="application/pdf" src={f.signedUrl} width="100%" height="500px" />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default function LotusFileViewer({ ...props }) {
  return (
    <FilesProvider>
      <LotusFileViewerPanel {...props} />
    </FilesProvider>
  );
}
