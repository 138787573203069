import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SubFormTabSelector from "../../SubformTabSelector";
import HybridForm from "./HybridForm";
import { linkifyTabName } from "lib/formatting";
import LotusSpacedBlock from "components/widgets/Layout/LotusSpacedBlock";
import LotusSpacer from "components/widgets/Layout/LotusSpacer";
import LotusMainTabSpacer from "components/widgets/Layout/LotusMainTabSpacer";

export default function TabbedMultiformPanel({clientId, selectedTab, headerText, linkPathPrefix, tabDefinitions, dynamicSubformDefinitions, clientForms, programId, assessmentId, disabled}) {

  const [allTabs, setAllTabs] = useState();
  const [theSelectedTab, setTheSelectedTab] = useState();

  useEffect(() => {
    setTheSelectedTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (tabDefinitions && dynamicSubformDefinitions && clientForms && linkPathPrefix) {
      const tabsToDisplay = [];
      let staticTabNames = Object.keys(tabDefinitions);
      let dynamicFormNames = dynamicSubformDefinitions ? dynamicSubformDefinitions.map(
        dynamicSubformDef => {return dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName}) : [];
      let allTabNames = new Set([...staticTabNames, ...dynamicFormNames]);
      const numTabs = allTabNames.size;

      staticTabNames.forEach(tabName => {
        const tabInfo = tabDefinitions[tabName];

        if (!tabInfo.afterDynamicTabs) {
          tabsToDisplay.push({
            label: tabName,
            component: tabInfo.component,
            link: `${linkPathPrefix}/${linkifyTabName(tabName)}`
          });
        }
      });
      
      if (dynamicSubformDefinitions && clientForms) {
        dynamicSubformDefinitions.forEach(dynamicSubformDef => {
          const dynSubformName = dynamicSubformDef.formSubtypeName || dynamicSubformDef.formSubtypeCustomName;
          if (!staticTabNames.includes(dynSubformName)) {
            tabsToDisplay.push({
              label: dynSubformName,
              component: 
                <HybridForm 
                  key={dynSubformName} 
                  title={numTabs > 1 ? dynSubformName : headerText} 
                  dynamicFormDefinition={dynamicSubformDef} 
                  dynamicFormValues={clientForms[dynSubformName]} 
                  clientId={clientId}
                  programId={programId}
                  assessmentId={assessmentId}
                  disabled={disabled}
                />,
              link: `${linkPathPrefix}/${linkifyTabName(dynSubformName)}`
            });
          }
        });
      }

      staticTabNames.forEach(tabName => {
        const tabInfo = tabDefinitions[tabName];

        if (tabInfo.afterDynamicTabs) {
          tabsToDisplay.push({
            label: tabName,
            component: tabInfo.component,
            link: `${linkPathPrefix}/${linkifyTabName(tabName)}`
          });
        }
      });
      

      setAllTabs(tabsToDisplay);
    }
  }, [headerText, tabDefinitions, dynamicSubformDefinitions, clientForms, linkPathPrefix, disabled]);
  
  return allTabs && (
    <Grid container>
      {allTabs && allTabs.length === 0 &&
        <div>
          <LotusSpacer/>
          <LotusSpacer/>
          <LotusSpacedBlock>
            There is nothing to display.
          </LotusSpacedBlock>
        </div>
      }
      {allTabs && allTabs.length > 1 && theSelectedTab &&
      <Grid item xs={12}>
        <LotusMainTabSpacer />
        <SubFormTabSelector headerText={headerText} selectedTabName={theSelectedTab} tabs={allTabs} onTabChange={(tabLabel) => setTheSelectedTab(tabLabel)}/>
      </Grid>
      }
      {allTabs && allTabs.length > 0 && theSelectedTab &&
        <Grid item xs={12}>
          {allTabs && allTabs.length > 0 && allTabs.find(t => linkifyTabName(t.label) === linkifyTabName(theSelectedTab))?.component}
        </Grid>
      }
    </Grid>
  )
}
