import { useState } from "react";
import LotusSectionHeader from "components/headers/LotusSectionHeader";
import LotusButtonList from "components/button/LotusButtonList";
import LotusAddButton from "components/button/common/LotusAddButton";
import LotusDialog from "components/feedback/dialog/LotusDialog";
import ConsentTypeListView from "./ConsentTypeListView";
import ConsentTypeView from "./ConsentTypeView";

export default function GranteeAgencyConsentTypesView({
  consentTypes,
  agency,
  agencyLanguages,
  handleSave
}) {
  
  const [addingConsentType, setAddingConsentType] = useState(false);

  const handleCancel = () => {
    setAddingConsentType(false);
  }

  const localHandleSave = async (values) => {
    await handleSave(values);
    setAddingConsentType(false);
  }

  return consentTypes && (
    <>
      <LotusSectionHeader 
        title="Consents"
        actionButtonList={
          <LotusButtonList>
            <LotusAddButton onClick={() => setAddingConsentType(true)} itemLabel="New Consent" />
          </LotusButtonList>
        }
      />
      <ConsentTypeListView
        consentTypes={consentTypes}
        agency={agency}
        agencyLanguages={agencyLanguages}
        handleSave={handleSave}
      />
      <LotusDialog
        open={addingConsentType}
        handleClose={handleCancel}
        content={
          <ConsentTypeView 
            agency={agency}
            agencyLanguages={agencyLanguages}
            handleSave={localHandleSave}
            handleCancel={handleCancel} />
        }
      />
    </>
  );
}