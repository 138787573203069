import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Typography from '@mui/material/Typography';
import { formatActionForAccountActionRequest } from 'lib/formatting';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';

const validationSchema = yup.object({
  denyReason: yup
    .string('Reason must be filled in')
    .required('Reason is required'),
});

const initialValues = {
  denyReason: '',
};

function DenyUserRequestForm({ actionRequest, inlineTable, onSave, onCancel }) {
  return actionRequest ? (
        <Formik
          initialValues={
            inlineTable
              ? { ...initialValues, denyReason: actionRequest.deniedReason }
              : initialValues
          }
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await onSave(actionRequest, values.denyReason);
            actions.setSubmitting(false);
            if (!inlineTable) {
              actions.resetForm();
            }
          }}
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  {!inlineTable && (
                    <LotusFormItem>
                      <Typography variant="body1">
                        <span>
                          {formatActionForAccountActionRequest(
                            'Deny',
                            actionRequest
                          )}
                        </span>
                      </Typography>
                    </LotusFormItem>
                  )}
                  <LotusFormItem>
                    <LotusTextInput
                      name="denyReason"
                      label="Denial Reason"
                      rows={4}
                      multiline={true}
                      onChange={handleChange}
                      required
                    />
                  </LotusFormItem>
                  {inlineTable && (
                    <div style={{ marginTop: 10, color: 'blue' }}>
                      <Typography
                        style={{
                          float: 'left',
                          paddingRight: 10,
                          cursor: 'pointer',
                        }}
                        variant="body2"
                        onClick={onCancel}
                      >
                        Cancel
                      </Typography>
                      <Typography
                        style={{ cursor: 'pointer' }}
                        variant="body2"
                        onClick={handleSubmit}
                      >
                        Update
                      </Typography>
                    </div>
                  )}
                  {!inlineTable && (
                    <div style={{ float: 'right' }}>
                      <LotusButton
                        onClick={handleSubmit}
                      >
                        Deny Request
                      </LotusButton>
                      <LotusButton
                        onClick={onCancel}
                        style={{ marginLeft: 10 }}
                      >
                        Cancel
                      </LotusButton>
                    </div>
                  )}
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
  ) : (
    ''
  );
}

export default DenyUserRequestForm;
