export const messageTypes = {
  LOADING_AGENCY_IMPORT_SETTINGS_SUCCESS:
    'LOADING_AGENCY_IMPORT_SETTINGS_SUCCESS',
  UPDATING_AGENCY_IMPORT_SETTINGS_SUCCESS:
    'UPDATING_AGENCY_IMPORT_SETTINGS_SUCCESS',
  SUBMIT_IMPORT_REQUEST_SUCCESS: 'SUBMIT_IMPORT_REQUEST_SUCCESS',
  GET_UPLOADED_IMPORT_FILES_SUCCESS: 'GET_UPLOADED_IMPORT_FILES_SUCCESS',
  GET_IMPORT_TYPES_SUCCESS: 'GET_IMPORT_TYPES_SUCCESS',
  GET_IMPORT_REQUESTS_SUCCESS: 'GET_IMPORT_REQUESTS_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_AGENCY_IMPORT_SETTINGS_SUCCESS:
    case messageTypes.UPDATING_AGENCY_IMPORT_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: payload.settings,
      };
    case messageTypes.GET_UPLOADED_IMPORT_FILES_SUCCESS:
      return {
        ...state,
        importFiles: payload.importFiles,
      };
    case messageTypes.GET_IMPORT_TYPES_SUCCESS:
      return {
        ...state,
        importTypes: payload.importTypes,
      };
    case messageTypes.GET_IMPORT_REQUESTS_SUCCESS:
      return {
        ...state,
        importRequests: payload.importRequests,
      };
    case messageTypes.SUBMIT_IMPORT_REQUEST_SUCCESS:
      const curRequests = state.importRequests || [];
      const newRequestsList = [
        ...curRequests.filter((r) => r.id !== payload.submittedRequest.id),
      ];
      newRequestsList.push(payload.submittedRequest);
      return {
        ...state,
        importRequests: newRequestsList,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
