import { useState } from "react";
import LotusDialog from "components/feedback/dialog/LotusDialog";
import ConsentTypeView from "./ConsentTypeView";
import LotusEditButton from "components/button/common/LotusEditButton";
import LotusDataGrid from "components/dataDisplay/datagrid/LotusDataGrid";
import { useTheme } from "@mui/material";


export default function ConsentTypeListView({
  consentTypes,
  agency,
  agencyLanguages,
  handleSave
}) {
  
  const [consentTypeToEdit, setConsentTypeToEdit] = useState(false);
  const theme = useTheme();

  const handleCancel = () => {
    setConsentTypeToEdit(null);
  }

  const localHandleSave = async (values) => {
    await handleSave(values);
    setConsentTypeToEdit(null);
  }

  const columns = [
    {
      headerName: 'Consent Name',
      field: 'name',
      minWidth: 150,
      type: 'string'
    },
    {
      headerName: 'Preview',
      field: 'defaultConsentDocumentThumbnail',
      display: 'flex',
      renderCell: (params) => {
        return <img src={params.row.defaultConsentDocumentThumbnail}/>;
      },
      minWidth: 100,
      hideSortIcons: true
    },
    {
      headerName: 'Status',
      field: 'status',
      valueGetter: (value, row) => {
        return row.isActive && row.isAgencyWide
          ? 'Active - Mandatory'
          : row.isActive
            ? 'Active'
            : 'Discontinued'
      },
      minWidth: 120
    },
    {
      headerName: 'Verbal',
      field: 'supportsVerbal',
      valueGetter: (value, row) => {
        return row.supportsVerbal ? 'Yes' : 'No'
      },
      minWidth: 80
    },
    {
      headerName: 'Written',
      field: 'supportsWritten',
      valueGetter: (value, row) => {
        return row.supportsWritten ? 'Yes' : 'No'
      },
      minWidth: 80
    },
    {
      headerName: 'Multilanguage',
      field: 'multilanguage',
      valueGetter: (value, row) => {
        return row.languageDocuments.length > 0
          ? row.languageDocuments.map((doc) => doc.languageName).join(',')
          : 'No'
      },
      minWidth: 150
    },
    {
      headerName: 'File name',
      field: 'defaultConsentDocumentFileName',
      minWidth: 150,
    },
    {
      headerName: 'Date last modified',
      field: 'lastModified',
      valueGetter: (value, row) => {
        return row.updatedDate ? row.updatedDate : row.createdDate
      },
      minWidth: 120
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      display: 'flex',
      renderCell: (params) => {
        return (
          <LotusEditButton
            onClick={() => setConsentTypeToEdit(params.row)}
          />
        );
      },
      minWidth: 100,
      hideSortIcons: true
    },
  ];

  return consentTypes && (
    <>
      <LotusDataGrid
        columns={columns}
        rows={consentTypes}
        disableColumnFilter
        rowClassNameFunc={(params) => params.row.isActive ? '' : 'consent-type--discontinued'}
        extraStyles={{
          '& .consent-type--discontinued': {
            color: theme.palette.text.disabled
          },
          // '& .MuiDataGrid-cell': {
          //   display: 'flex',
          //   alignItems: 'center',
          //   padding: 1
          // }
        }}
      />
      <LotusDialog
        open={consentTypeToEdit}
        handleClose={handleCancel}
        content={
          consentTypeToEdit && 
            <ConsentTypeView 
              consentType={consentTypeToEdit} 
              agency={agency}
              agencyLanguages={agencyLanguages}
              handleSave={localHandleSave}
              handleCancel={handleCancel} />
        }
      />
    </>
  );
}