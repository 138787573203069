import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './DataExportContextReducer';
import { parseApiResult } from 'lib/utils';
import axios from '../axiosInterceptor';
import { useAppStatus } from './AppStatusContext';

const DataExportContext = createContext();

const initialState = {
  exportTypes: undefined,
  exportRequests: undefined,
};

export const DataExportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataExportContext.Provider value={{ state, dispatch }}>
      {children}
    </DataExportContext.Provider>
  );
};

export const useDataExport = () => {
  const { state, dispatch } = useContext(DataExportContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const submitExportRequest = async (
    agencyId,
    exportTypeId,
    fileName,
    description,
    displayFileName,
    delimiter,
    startDate
  ) => {
    if (agencyId) {
      try {
        addBusyBee('submitExportRequest');

        const toPost = {
          operationName: 'submitExportRequest',
          agencyId,
          fileName,
          exportTypeId,
          description,
          displayFileName,
          delimiter,
          startDate
        };

        const url = `/api/export`;
        const { data } = await axios.post(url, toPost);
        const newRequest = parseApiResult(data).body;

        dispatch({
          type: messageTypes.SUBMIT_EXPORT_REQUEST_SUCCESS,
          payload: {
            submittedRequest: newRequest,
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('submitExportRequest');
      }
    }
  };

  const getExportTypes = async (agencyId) => {
    try {
      addBusyBee('getExportTypes');

      const toPost = {
        operationName: 'getExportTypes',
      };

      const url = `/api/export`;
      const { data } = await axios.post(url, toPost);
      const exportTypes = parseApiResult(data).body || [];

      dispatch({
        type: messageTypes.GET_EXPORT_TYPES_SUCCESS,
        payload: {
          exportTypes: exportTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('getExportTypes');
    }
  };

  const getExportRequests = async (agencyId) => {
    try {
      addBusyBee('getExportRequests');

      const toPost = {
        operationName: 'getExportRequests',
        agencyId: agencyId
      };

      const url = `/api/export`;
      const { data } = await axios.post(url, toPost);
      const exportRequests = parseApiResult(data).body || [];

      dispatch({
        type: messageTypes.GET_EXPORT_REQUESTS_SUCCESS,
        payload: {
          exportRequests: exportRequests,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('getExportRequests');
    }
  };

  if (state) {
    return {
      ...state,
      submitExportRequest,
      getExportTypes,
      getExportRequests,
    };
  }

  return {};
};
