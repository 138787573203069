import React from 'react';
import LotusSecondaryActionButton from '../LotusSecondaryActionButton';
import Edit from '@mui/icons-material/Edit';


export default function LotusEditButton({
  onClick,
  disabled,
  itemLabel
}) {
  return (
    <LotusSecondaryActionButton
      startIcon={<Edit/>}
      onClick={onClick}
      disabled={disabled}
    >
      Edit{itemLabel ? ` ${itemLabel}` : ''}
    </LotusSecondaryActionButton>
  );
}