export const messageTypes = {
  LOADING_ELIGIBILITY_TIMELINE_SUCCESS: 'LOADING_ELIGIBILITY_TIMELINE_SUCCESS',
  LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS: 'LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS',
  LOADING_ELIGIBILITY_PERIODS_FOR_CLIENT_SUCCESS: 'LOADING_ELIGIBILITY_PERIODS_FOR_CLIENT_SUCCESS',
  LOADING_ELIGIBILITY_DETAILS_FOR_APPLICATION_SUCCESS: 'LOADING_ELIGIBILITY_DETAILS_FOR_APPLICATION_SUCCESS'
};
  
export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ELIGIBILITY_TIMELINE_SUCCESS:
    case messageTypes.LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS:
    case messageTypes.LOADING_ELIGIBILITY_PERIODS_FOR_CLIENT_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.LOADING_ELIGIBILITY_DETAILS_FOR_APPLICATION_SUCCESS:
      let newAppDetails = state.appEligDetails ? [...state.appEligDetails.filter(ad => ad.appId !== payload.appId)] : [];
      newAppDetails.push({appId: payload.appId, details: payload.details});
      return {
        ...state,
        appEligDetails: newAppDetails
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
  