import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import UserForm from './forms/UserForm';
import AddUserConfirmPanel from './AddUserConfirmPanel';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useUserManagement } from 'contexts/UserManagementContext';

export default function AddUserPanel({ handleBackClicked }) {
  const { id } = useParams();
  const [savedUser, setSavedUser] = useState();
  const { userDetail, loadUserDetail } = useUserManagement();
  const [importedUser, setImportedUser] = useState();


  const showConfirmPanel = (user) => {
    setSavedUser(user);
  };

  useEffect(() => {
    if (id) {
      loadUserDetail(id);
    }
  }, [id]);

  useEffect(() => {
    if (userDetail) {
      if (userDetail?.statusKey === 'imported') {
        setImportedUser({
          firstName: userDetail?.data?.firstName, lastName: userDetail?.data?.lastName, middleName: userDetail?.data?.middleName,
          agencyId: userDetail?.agencyId, email: userDetail?.email, id: id,
          primaryPhone: userDetail?.data?.primaryPhone, secondaryPhone: userDetail?.data?.secondaryPhone
        });

      }
    }
  }, [userDetail]);

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton onClick={handleBackClicked} variant="outlined">Back</LotusButton>
      </LotusSpacedBlock>
      {!savedUser && importedUser && (
        <LotusPageSection header='Request New User'>
          <UserForm afterSave={showConfirmPanel} importedUser={importedUser} />
        </LotusPageSection>
      )}
      {savedUser && (
        <AddUserConfirmPanel email={savedUser.email} />
      )}
    </LotusPaper>
  );
}
