
import { useAgencyManagement } from "contexts/AgencyManagementContext";
import { useUserAgency } from "contexts/UserAgencyContext";
import { useEffect, useState } from "react";
import { GranteeAgencyCareManagementView } from "./GranteeAgencyCareManagementView";

export default function GranteeAgencyCareManagement() {

  const {
    agency,
    assessments,
    loadAgencyAssessments,
    createAgencyAssessment,
    updateAgencyAssessment,
    assessmentTypes,
    loadAgencyAssessmentTypes,
    documentTypes, 
    loadAgencyDocumentTypes,
    updateAgencyDocumentTypes,
    providerTypes, 
    loadAgencyProviderTypes,
    updateAgencyProviderTypes,
  } = useAgencyManagement();

  const [loadedAgencyAssessments, setLoadedAgencyAssessments] = useState();
  const [loadedAssessmentTypes, setLoadedAssessmentTypes] = useState();
  const { loadUserAgency } = useUserAgency();

  useEffect(() => {
    if (agency && !assessments) {
      loadAgencyAssessments();
    }
    if (agency && !assessmentTypes) {
      loadAgencyAssessmentTypes();
    }
    if (agency && !documentTypes) {
      loadAgencyDocumentTypes();
    }
    if (agency && !providerTypes) {
      loadAgencyProviderTypes();
    }
  }, [agency]);

  useEffect(() => {
    if (assessments) {
      const lst = [...assessments];
      lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyAssessments(lst);
    }
  }, [assessments]);

  useEffect(() => {
    if (assessmentTypes) {
      setLoadedAssessmentTypes(
        assessmentTypes.map(atype => {return {label: atype.name, value: atype.id}})
      );
    }
  }, [assessmentTypes]);

  const saveCareManagement = async (values) => {
    // Should really be done as a single transaction
    await updateAgencyDocumentTypes([
      ...values.documentTypes,
      ...values.newDocumentTypes,
    ]);

    let providerTypesToSave = values.providerTypes.filter(typ => typ.selected).map(typ => {
      return {
        name: typ.name,
        subtypeNames: typ.subtypes.filter(sub => sub.selected).map(sub => sub.name)
      }
    });
    await updateAgencyProviderTypes(providerTypesToSave);

    for (let i = 0; i < values.assessments.length; i++) {
      let assessment = values.assessments[i];
      if (assessment.id) {
        await updateAgencyAssessment(assessment);
      }
      else {
        await createAgencyAssessment(assessment);
      }
    }

    await loadUserAgency();
  };

  return (
    agency && loadedAgencyAssessments && loadedAssessmentTypes && (
      <GranteeAgencyCareManagementView
        assessments={loadedAgencyAssessments}
        assessmentTypes={loadedAssessmentTypes}
        documentTypes={documentTypes}
        providerTypes={providerTypes}
        handleSave={saveCareManagement}
        handleCancel={() => {}} // staying on page, just reverting changes
      />
    )
  );
}
