export function getProgramsForDisplay(userAgencyPrograms, enrolledPrograms, sortByEnrollmentStatus=false) {
  if (userAgencyPrograms && enrolledPrograms) {
    const availablePrograms = userAgencyPrograms;
    
    if (sortByEnrollmentStatus) {
      // Put programs with enrollments first
      availablePrograms.sort((a, b) => {
        let result = 0;
        if (enrolledPrograms.find((e) => e.programId === a.id) &&
            enrolledPrograms.find((e) => e.programId === b.id)) {
          result = a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        } else if (enrolledPrograms.find((e) => e.programId === a.id)) {
          result = -1;
        } else if (enrolledPrograms.find((e) => e.programId === b.id)) {
          result = 1;
        } else {
          result = a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        }
        return result;
      });
    } else {
      availablePrograms.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
    }

    return availablePrograms;
  } else {
    return undefined;
  }
}