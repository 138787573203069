import React, { useEffect, useState } from 'react';
import { useAgencyRequests } from 'contexts/AgencyRequestsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';
import LotusPageHeader from 'components/headers/LotusPageHeader';
import LotusButtonList from 'components/button/LotusButtonList';
import LotusPrimaryActionButton from 'components/button/LotusPrimaryActionButton';
import LotusTabs from 'components/navigation/LotusTabs';
import LotusPageContent from 'components/dataDisplay/content/LotusPageContent';
import { Stack } from '@mui/material';
import AgencyList from './/list/AgencyList';
import RequestsList from './requests/RequestsList';
import AddAgency from './create/AddAgency';
import EditAgency from './edit/EditAgency';
import LotusSecondaryActionButton from 'components/button/LotusSecondaryActionButton';
import { ArrowBack } from '@mui/icons-material';

export default function AgencyManagement() {

  const { page } = useParams();
  const [tabList, setTabList] = useState();

  const { verifyPermission } = useAuthState();
  const navigate = useNavigate();

  const hasRequestAgencyPermission = verifyPermission(PERMISSIONS.REQUEST_SUB_AGENCY);
  const hasApprovePermission = verifyPermission(PERMISSIONS.APPROVE_MODIFY_AGENCY) || verifyPermission(PERMISSIONS.APPROVE_SUB_AGENCY);

  const { pendingRequests, loadPendingAgencyRequests } = useAgencyRequests();

  // sigh, always need to get requests right away to display the badge
  useEffect(() => {
    let tabs = [
      {label: "Agency List", link: "/agencies/list", cleanName: "list"},
    ];

    if (hasApprovePermission) {
      loadPendingAgencyRequests();
      tabs.push({label: "Approval Requests", link: "/agencies/requests", cleanName: "requests"});
    }

    setTabList(tabs);
  }, []);

  useEffect(() => {
    if (pendingRequests && tabList) {
      let tab = tabList.find(t => t.label === "Approval Requests");
      if (tab) {
        const newBadge = pendingRequests.length || undefined;
        if (tab.badge !== newBadge) {
          tab.badge = newBadge;
          setTabList([...tabList]); // have to set to new list instance so component updates with new badge
        }
      }
    }
  }, [pendingRequests, tabList]);

  return tabList && (
    <>
      {(page === "list" || page === "requests" || page === "create") &&
        <>
          <LotusPageHeader 
            title="Agency Management" 
            actionButtonList={
              <LotusButtonList>
                {(page === "list" || page === "requests") &&
                  <LotusPrimaryActionButton onClick={() => navigate('/agencies/create')} disabled={!hasRequestAgencyPermission}>
                    Request New Agency
                  </LotusPrimaryActionButton>
                }
              </LotusButtonList>
            }
          />
          <LotusPageContent>
            <Stack direction="column" spacing={2}>
              {(page === "list" || page === "requests") &&
                <LotusTabs
                  selectedTabName={page}
                  tabs={tabList}
                  tabPropertyToMatch="cleanName"
                />
              }
              {page === "list" && <AgencyList />}
              {page === "requests" && <RequestsList requests={pendingRequests} />}
              {page === 'create' && <AddAgency />}
            </Stack>
          </LotusPageContent>
        </>
      }
      {page === 'edit' && <EditAgency />}
    </>
  );
}
