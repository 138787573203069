import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { FormGroup } from '@mui/material';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';

export default function LotusCheckboxList({ disabled, label, items, required, otherItemName, dontCheckRequiredFields, ...props }) {
  const [ allItems, setAllItems] = useState();

  const otherName = otherItemName || 'ignoreThis';
  const [otherFld] = useField(otherName);

  const labelText = label ? label + (required ? ' * ' : '') : null;

  const [ fld, meta ] = useField({...props, validate: (val) => {
    if (required && !dontCheckRequiredFields && !((val && val.length > 0) || (otherItemName && (otherFld.value)))) {
      return `${label} is required`;
    }
  }});

  useEffect(() => {
    if (items) {
      let lst = [...items];
      lst = lst.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
      setAllItems(lst);
    }
  }, [items]);

  return (
    <div>
      {!disabled ? 
      <>
      <Grid item xs={12}>
        <div>{labelText}</div>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          {fld.value && allItems &&
            allItems.map((node) => {
              return (
                <LotusCheckbox
                  key={`cbxitm-${node.value}`}
                  name={fld.name}
                  label={node.label}
                  value={node.value}
                  checked={fld.value.find((aId) => aId === node.value) !== undefined}
                />
              );
            })
          }
          {otherItemName &&
            <Stack direction="row">
              <LotusCheckbox name={`cbx${otherFld.name}`} label='' checked={Boolean(otherFld.value)} style={{marginRight: -15}}/>
              <LotusTextInput name={otherFld.name} value={otherFld.value || ''} placeholder='Other' maxLength={100}/>
            </Stack>
          }
          {meta.error &&
          <div style={{color:'#b53f3f', marginTop: 10}}>
            <ErrorMessage name={props.name} />
          </div>
          }
        </FormGroup>
      </Grid>
      </> :
      <LotusReadOnlyFieldView label={label} value={`${items && (fld.value || []).map(fv => items.find(x => x.value === fv)?.label).join(', ')}${otherItemName && otherFld && otherFld.value ? `${fld.value && fld.value.length > 0 ? ', ' : ''}${otherFld.value}` : ''}`} />
    }
    </div>
  );
}
