import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';
import { formatUsDate } from 'lib/formatting';
import { useClientSearch } from 'contexts/ClientSearchContext';
import { Table } from '@lotus/components';
import FilterDatePanel from './FilterDatePanel';
import MultiFieldSearch from './MultiFieldSearch';
import { useAuthState } from 'contexts/AuthProvider';

export default function ClientTable({ tableName, searchAcrossGrantee }) {
  const [rows, setRows] = useState();
  const [resetClientTable, setResetClientTable] = useState('allClientsTable' === tableName);
  const [displayedColumns, setDisplayedColumns] = useState([]);

  const [currentTableData, setCurrentTableData] = useState();
  const [searchNoMatch, setSearchNoMatch] = useState("A search can be performed using a combination of Name and DOB, or any associated Client Identifier.");
  const [noMatch] = useState("No matching clients found.");

  const navigate = useNavigate();
  const { clients, totalClientPages, loadClients, loadClientsSync } = useClientSearch();
  const { tablePreferences, updateTablePreferences } = useAuthState();

  const defaultSortOrder = { name: 'last_name', direction: 'asc' };

  useEffect(() => {
    if (tableName && tablePreferences && tablePreferences[tableName]) {
      setDisplayedColumns(tablePreferences[tableName].columns);
    }
  }, [tableName, tablePreferences]);

  const handleDataChange = async ({
    tableName,
    pageNumber,
    rowsPerPage,
    searchText,
    sortOrder,
    filters
  }) => {
    if (tableName) {
      setCurrentTableData({
        searchText,
        sortOrder,
        filters,
      });

      const newPageNumber = currentTableData?.searchText === searchText ? pageNumber : 0;

      await loadClients({
        assignedToUserOnly: 'myClientsTable' === tableName,
        offset: newPageNumber * rowsPerPage,
        limit: rowsPerPage,
        searchText,
        sortOrder,
        filters,
        searchAcrossGrantee
      });
    }
  };

  useEffect(() => {
    if (tableName) {
      if (resetClientTable) {
        setRows([]);
        return;
      }
      handleDataChange({
        pageNumber: 0,
        rowsPerPage: 10,
        tableName: tableName,
        sortOrder: defaultSortOrder
      });

      setCurrentTableData({
        sortOrder: defaultSortOrder,
      });

    }
  }, [tableName]);

  const handleRowClicked = (rowData) => {
    const id = rowData[0];
    return navigate(`/client/${id}/eligibility`);
  };

  useEffect(() => {
    if (resetClientTable) {
      setRows([]);
      setResetClientTable(false);
      return;
    }
    if (clients && clients.length > 0) {
      setRows(clients);
    } else {
      setRows([]);
      setSearchNoMatch(noMatch);
    }
  }, [clients]);

  const handleClientDataDownload = async (
    buildHead,
    buildBody,
    columns,
    options
  ) => {
    const downloadData = await loadClientsSync({
      assignedToUserOnly: 'myClientsTable' === tableName,
      offset: 0,
      limit: -1,
      ...options,
      ...currentTableData
    });

    const formattedData = downloadData.clients.map((data, index) => {
      const elements = columns.map((column) =>
        column.download ? data[column.name] : 'excluded'
      );
      const filteredElements = elements.filter((elem) => elem !== 'excluded');
      return {
        index,
        data: filteredElements,
      };
    });

    const downloadText = `${buildHead(headerNames)}${buildBody(
      formattedData
    )}`.trim();
    const file = new Blob([downloadText], { type: 'text/csv' });

    // anchor link
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = 'clients-' + Date.now() + '.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleApplyFilter = (values, filterList, index, onChange, column) => {
    if (values.startDate) {
      if (values.useFilter) {
        filterList[index] = [
          `${values.startDate} - ${values.endDate}`,
        ];
      }
      else {
        filterList[index] = [];
      }
    } else {
      const selected = Object.keys(values).filter((key) => {
        return values[key] === true;
      });
      filterList[index] = selected;
    }

    onChange(filterList[index], index, column);
  };

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      label: 'Client ID',
      name: 'client_id',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('client_id'),
      },
    },
    {
      label: 'Last Name',
      name: 'last_name',
      options: {
        display: displayedColumns.includes('last_name'),
        filter: false,
        sort: 'allClientsTable' === tableName ? false : true,
        downloadHeader: 'lastName',
        includeOnSearch: displayedColumns.includes('last_name'),
      },
    },
    {
      label: 'First Name',
      name: 'first_name',
      options: {
        filter: false,
        sort: 'allClientsTable' === tableName ? false : true,
        display: displayedColumns.includes('first_name'),
        downloadHeader: 'firstName',
        includeOnSearch: displayedColumns.includes('first_name'),
      },
    },
    {
      label: 'DOB',
      name: 'birth_date',
      getSortDefinition: (definition) => {
        return {
          ...definition,
          sortField: 'sortable_date',
        };
      },
      options: {
        filter: displayedColumns.includes('birth_date'),
        sort: 'allClientsTable' === tableName ? false : true,
        display: displayedColumns.includes('birth_date'),
        filterType: 'custom',
        downloadHeader: 'birthDate',
        customBodyRender: (value) => {
          return <div>{value ? formatUsDate(value) : 'N/A'}</div>;
        },
        filterOptions: {
          fullWidth: true,
          logic: (location, filters, row) => {
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let minDate = '1/1/1900';
            let maxDate = moment().format('MM/DD/YYYY');
            let initialUseFilter = false;

            // check if already filtered
            const filterData = filterList[index];
            if (filterData.length > 0) {
              const data = filterData[0].split(' - ');
              minDate = data[0];
              maxDate = data[1];
              initialUseFilter = true;
            }

            return (
              <FilterDatePanel
                headerText="Date of Birth Range"
                startDate={moment(minDate).format('MM/DD/YYYY')}
                endDate={moment(maxDate).format('MM/DD/YYYY')}
                initialUseFilter={initialUseFilter}
                handleApply={(values) =>
                  handleApplyFilter(values, filterList, index, onChange, column)
                }
              />
            );
          },
        },
      },
    },
    {
      label: 'Email',
      name: 'email_address',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('email_address'),
        includeOnSearch: true,
        downloadHeader: 'email',
      },
    },
    {
      label: 'Phone Number',
      name: 'phone_number',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('phone_number'),
        downloadHeader: 'phoneNumber',
      },
    },
    {
      label: 'Case Manager',
      name: 'case_manager',
      options: {
        filter: false,
        sort: true,
        display: 'myClientsTable' === tableName ? 'excluded' : displayedColumns.includes('case_manager'),
        downloadHeader: 'caseManager',
      },
    },
    {
      label: 'SSN',
      name: 'social_security_number',
      options: {
        filter: false,
        sort: false,
        display: displayedColumns.includes('social_security_number'),
        downloadHeader: 'ssn',
      },
    }
  ];

  const headerNames = columns
    .map((column) => {
      return {
        name: column.options.downloadHeader,
        download: column.options.display,
      };
    })
    .filter((item) => item.name !== undefined);

  const updatePreferences = debounce((prefs) => {
    updateTablePreferences(prefs);
  }, 2000);

  const handleViewColumnsChanged = (changedColumn, action) => {
    let newColumns = displayedColumns;
    if (action === 'add') {
      newColumns.push(changedColumn);
    } else {
      let idx = newColumns.indexOf(changedColumn);
      if (idx > -1) {
        newColumns.splice(idx, 1);
      }
    }
    setDisplayedColumns(newColumns);
    let prefs = tablePreferences;
    if (prefs) {
      prefs[tableName].columns = newColumns;
      updatePreferences(prefs);
    }
  };

  return <Table
    tableName={tableName}
    enabled={Boolean(rows)}
    options={{ 
      search: 'allClientsTable' === tableName ? false : true,
      serverSide: true, 
      customSearch: undefined, 
      download: 'allClientsTable' === tableName ? false : true, 
      print: 'allClientsTable' === tableName ? false : true,
      filter: 'allClientsTable' === tableName ? false : true,
      textLabels: {
          body: {
            noMatch: 'allClientsTable' === tableName ? searchNoMatch : noMatch
          }
      },
      pagination: Boolean(rows) && rows.length > 0
    }}
    columns={columns}
    data={rows}
    totalRows={rows && rows.length > 0 ? totalClientPages : null}
    defaultSortOrder={defaultSortOrder}
    headerNames={headerNames}
    handleDataChange={handleDataChange}
    handleRowClicked={handleRowClicked}
    handleDownload={handleClientDataDownload}
    handleViewColumnsChanged={handleViewColumnsChanged}
    customSearchFilterComponent={'allClientsTable' === tableName && searchAcrossGrantee ? MultiFieldSearch : null}
  />
}
