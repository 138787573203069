import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusPage from 'components/widgets/Layout/LotusPage';
import ClientTable from './ClientTable';
import { Button, Stack } from '@mui/material';
import LotusMainTabSpacer from 'components/widgets/Layout/LotusMainTabSpacer';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function ClientList() {
  
  const [tabs, setTabs] = useState();
  const {view} = useParams();
  const navigate = useNavigate();
  const [tableName, setTableName] = useState();
  const { user: loggedInUser, verifyPermission, tablePreferences, updateTablePreferences } = useAuthState();

  const searchAcrossGrantee = verifyPermission(PERMISSIONS.SEARCH_ACROSS_GRANTEE);

  useEffect(() => {
    if (!view) {
      navigate('/clients/list/MyClients');
    }
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      let preferences = tablePreferences;
      if (!preferences || !preferences['myClientsTable'] || !preferences['subAgencyClientsTable'] || !preferences['allClientsTable']) {
        const defaultDisplayedColumns = [
          'last_name',
          'first_name',
          'birth_date',
          'email_address',
          'phone_number',
          'case_manager'
        ];

        preferences = preferences || {};
        if (!preferences['myClientsTable']) {
          preferences['myClientsTable'] = {columns: [...defaultDisplayedColumns]};
        }
        if (!preferences['subAgencyClientsTable']) {
          preferences['subAgencyClientsTable'] = {columns: [...defaultDisplayedColumns]};
        }
        if (!preferences['allClientsTable']) {
          preferences['allClientsTable'] = {columns: [...defaultDisplayedColumns]};
        }
        updateTablePreferences(preferences);
      }
      const activeTabs = [
          {
            label: 'My Clients',
            link: '/clients/list/MyClients'
          },
          {
            label: (loggedInUser.agencyName ? loggedInUser.agencyName : 'SubAgency')  + ' Clients',
            link: '/clients/list/' + (loggedInUser.agencyName ? loggedInUser.agencyName.trim().replace(/ /g, "") : 'SubAgency')  + 'Clients'
          }
        ];
      if (searchAcrossGrantee) {
        activeTabs.push({
            label: 'All Clients',
            link: '/clients/list/AllClients'
          });
      }
      setTabs(activeTabs);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (view) {
      const lowerView = view.toLowerCase();
      if ('myclients' === lowerView) {
        setTableName('myClientsTable');
      } else if ('allclients' === lowerView) {
        setTableName('allClientsTable');
      } else if (lowerView === loggedInUser?.agencyName.trim().replace(/ /g, "").toLowerCase()+'clients') {
        setTableName('subAgencyClientsTable');
      }
    }
  }, [view]);

  const handleCreateClientClicked = async () => {
    navigate('/clients/create');
  };

  return tabs && view && (
    <LotusPage>
      <LotusPageTitle title="Client List">
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleCreateClientClicked}>
            Register Client
          </Button>
        </Stack> 
      </LotusPageTitle>
      <LotusMainTabs
        selectedTabName={view}
        tabs={tabs}
      />
      <LotusMainTabSpacer/>
      {tableName === 'myClientsTable' &&
        <ClientTable tableName={tableName}/>
      }
      {tableName === 'subAgencyClientsTable' &&
        <ClientTable tableName={tableName}/>
      }
      {tableName === 'allClientsTable' &&
        <ClientTable tableName={tableName} searchAcrossGrantee={searchAcrossGrantee}/>
      }
    </LotusPage>
  );
}
