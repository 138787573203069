import React, {createContext, useContext, useReducer} from "react";
import axios from '../axiosInterceptor';
import {
  reducer,
  messageTypes
} from "./EligibilityTimelineContextReducer";
import { useAppStatus } from "./AppStatusContext";
import { parseApiResult } from "lib/utils";

const initialState = {
  timeline: null
};

const EligibilityTimelineContext = createContext();

export const EligibilityTimelineProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EligibilityTimelineContext.Provider value={{ state, dispatch }}>
      {children}
    </EligibilityTimelineContext.Provider>
  );
};

export const useEligibilityTimeline = () => {
  const { state, dispatch } = useContext(EligibilityTimelineContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  
  const loadEligibilityTimeline = async (clientId) => {

    addBusyBee('loadEligibilityTimeline');

    try {

      const {data} = await axios.post(`/api/clientEligibilityApp`, {operationName: 'getEligibilityTimeline', clientId });
      let {timeline} = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_TIMELINE_SUCCESS,
        payload: {
          timeline
        },
      });

    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityTimeline');
    }
  };

  const loadApplicationsForClient = async (clientId) => {

    addBusyBee('loadApplicationsForClient');

    try {
      const { data } = await axios.post('/api/eligibilityApps', { operationName: 'getApplicationsForClient', clientId });
      const applications = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS,
        payload: {
          applications
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadApplicationsForClient');
    }
  };

  const loadEligibilityPeriodsForClient = async (clientId) => {

    addBusyBee('loadEligibilityPeriodsForClient');

    try {
      const {data} = await axios.post(`/api/clientEnrollments`, {operationName: 'getRyanWhiteEnrollments', clientId});
      let { enrollments } = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_PERIODS_FOR_CLIENT_SUCCESS,
        payload: {
          eligibilityPeriods: enrollments.map(e => { return {startDate: e.startDate, endDate: e.endDate} })
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityPeriodsForClient');
    }
  };

  const loadEligibilityDetailsForApplication = async (appId) => {

    addBusyBee('loadEligibilityDetailsForApplication');

    try {
      const {data} = await axios.post(`/api/clientEligibilityApp`, {operationName: 'getApplicationEligibilityDetails', appId});
      let details = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ELIGIBILITY_DETAILS_FOR_APPLICATION_SUCCESS,
        payload: {
          appId,
          details
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEligibilityDetailsForApplication');
    }
  };

  return {
    ...state,
    loadEligibilityTimeline,
    loadApplicationsForClient,
    loadEligibilityPeriodsForClient,
    loadEligibilityDetailsForApplication
  };
};
