import React from 'react';
import UserList from './UserList';
import AddUserPanel from './AddUserPanel';
import AddImportedUserPanel from './AddImportedUserPanel';

import EditUserPanel from './EditUserPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusButton from 'components/widgets/Forms/LotusButton';
import Stack from '@mui/material/Stack';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function UsersPage() {
  const navigate = useNavigate();
  const { page } = useParams();

  const { verifyPermission } = useAuthState();
  const hasRequestUserPermission = verifyPermission(PERMISSIONS.REQUEST_NEW_ACCOUNT);

  const addUserClick = () => {
    navigate('/users/create');
  };

  const showUserList = () => {
    navigate('/users/list');
  };

  return (
    <div>
        {page === 'list' && (
          <>
            <LotusSpacedBlock>
              <Stack justifyContent="flex-end" direction='row'>
                <LotusButton
                    disabled={!hasRequestUserPermission}
                    style={{ cursor: hasRequestUserPermission ? 'pointer': 'default'}}
                    onClick={() => hasRequestUserPermission && addUserClick()}>
                  Request User Invite
                </LotusButton>
              </Stack>
            </LotusSpacedBlock>
            <UserList />
          </>
        )}

        {page === 'create' && (
            <AddUserPanel
              handleBackClicked={showUserList}
            />
        )}

        {page === 'edit' && (
            <EditUserPanel
              handleBackClicked={showUserList}
            />
        )}

      {page === 'addimporteduser' && (
        <AddImportedUserPanel
          handleBackClicked={showUserList}
        />
      )}
    </div>
  );
}
