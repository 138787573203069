import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ClientAssessmentsContextReducer';
import { useAppStatus } from './AppStatusContext';
import { groupBy } from 'lib/utils';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  assessments: {}, // key by program id
};

const ClientAssessmentsContext = createContext();

export const ClientAssessmentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientAssessmentsContext.Provider value={{ state, dispatch }}>
      {children}
    </ClientAssessmentsContext.Provider>
  );
};

export const useClientAssessments = () => {
  const { state, dispatch } = useContext(ClientAssessmentsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadAssessmentsForClientAndProgram = async (clientId, programId) => {

    addBusyBee('loadAssessmentsForClientAndProgram');

    try {
      const toPost = {
        operationName: 'getAssessmentsForClientAndProgram',
        clientId: clientId, 
        programId: programId
      };
      const { data } = await axios.post('/api/clientAssessments', toPost);

      let clientAssessmentsData = parseApiResult(data).body;

      // determine which program assessment was last completed
      const assessmentsPerProgramAssessmentId = groupBy(
        clientAssessmentsData,
        (ca) => ca.programAssessmentId
      );
      for (let [id, values] of assessmentsPerProgramAssessmentId) {
        let last = values.sort((a, b) => {
          if (a.completedAt && b.completedAt) {
            return a.completedAt < b.completedAt ? 1 : -1;
          } else if (a.completedAt) {
            return -1;
          } else if (b.completedAt) {
            return 1;
          } else {
            return 0;
          }
        })[0];
        if (last.completedAt) {
          last['lastCompleted'] = true;
        }
      }

      dispatch({
        type: messageTypes.LOADING_ASSESSMENTS_FOR_CLIENT_AND_PROGRAM_SUCCESS,
        payload: {
          assessments: clientAssessmentsData,
          programId,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAssessmentsForClientAndProgram');
    }
  };

  const createClientProgramAssessment = async (programAssessmentId, previousCompletedAssessmentId, clientId, programId) => {
    try {
      addBusyBee('createClientProgramAssessment');

      const toPost = {
        operationName: 'addClientProgramAssessment',
        clientId: clientId, 
        programAssessmentId: programAssessmentId, 
        previousCompletedAssessmentId: previousCompletedAssessmentId
      };
      const result = await axios.post('/api/clientAssessments', toPost);

      const { clientAssessmentId } = parseApiResult(result.data).body;

      // Too many joins to build data for state, so just reretrieve
      await loadAssessmentsForClientAndProgram(clientId, programId);

      dispatch({
        type: messageTypes.CREATING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS,
      });

      return clientAssessmentId;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createClientProgramAssessment');
    }
  };

  const completeClientProgramAssessment = async (
    clientProgramAssessmentId,
    clientId,
    programId
  ) => {
    try {
      await addBusyBee('completeClientProgramAssessment');

      const toPost = {
        operationName: 'completeClientProgramAssessment',
        assessmentId: clientProgramAssessmentId, 
      };
      await axios.post('/api/clientAssessments', toPost);
      
      // Too many joins to build data for state, so just retrieve
      await loadAssessmentsForClientAndProgram(clientId, programId);

      dispatch({
        type: messageTypes.COMPLETING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS,
      });

      return;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      await removeBusyBee('completeClientProgramAssessment');
    }
  };

  return {
    ...state,
    loadAssessmentsForClientAndProgram,
    createClientProgramAssessment,
    completeClientProgramAssessment,
  };
};
