import React, { createContext, useContext, useReducer } from 'react';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from "lib/utils";

import { reducer, messageTypes } from './DynamicFormContextReducer';
import { useUserAgency } from './UserAgencyContext';

const DynamicFormContext = createContext();

const initialState = {
  formTypes: undefined,
  assessmentTypes: undefined,
  agencyAssessmentTypes: undefined,
  formDefinitions: undefined,
  formDefinitionCacheById: undefined,
  formDefinitions: undefined,
  agencyPrograms: undefined
};

export const DynamicFormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DynamicFormContext.Provider value={{ state, dispatch }}>
      {children}
    </DynamicFormContext.Provider>
  );
};

export const useFormDefinitions = () => {
  const { state, dispatch } = useContext(DynamicFormContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  const { userAgency } = useUserAgency();

  const buildFormDefinitionKey = (formTypeKey, programId, assessmentTypeId) => {
    return `${formTypeKey}-${programId}-${assessmentTypeId}`;
  }

  const loadFormDefinitionsForFormType = async ({
    formTypeKey,
    programId,
    assessmentTypeId
  }) => {

    const url = `/api/dynamicForms`;
    try {
      addBusyBee('loadFormDefinitionsForFormType');

      let formDefinitions;

      const toPost = { 
        operationName: 'getFormDefinitionsForFormType', 
        formTypeKey: formTypeKey,
        agencyId: userAgency.granteeAgencyId, 
        programId: programId,
        assessmentTypeId: assessmentTypeId
      };
        
      const { data }  = await axios.post(url, toPost);
      const result = parseApiResult(data).body;
      if (result) formDefinitions = result.formDefinitions;

      dispatch({
        type: messageTypes.LOADING_FORM_DEFINITIONS_FOR_FORM_TYPE_SUCCESS,
        payload: {
          key: buildFormDefinitionKey(formTypeKey, programId, assessmentTypeId),
          formDefinitions
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadFormDefinitionsForFormType');
    }
  };

  const loadAllAgencyFormDefinitions = async () => {
    const url = `/api/dynamicForms`;
    try {
      addBusyBee('loadAllAgencyFormDefinitions');

      const toPost = { operationName: 'getAllAgencyFormDefinitions', agencyId: userAgency.id };
      const { data }  = await axios.post(url, toPost);
      const formDefinitions = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_ALL_AGENCY_FORM_DEFINITIONS_SUCCESS,
        payload: {
          formDefinitions: formDefinitions,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAllAgencyFormDefinitions');
    }
  };

  const loadFormDefinitionById = async (id) => {
    const url = `/api/dynamicForms`;
    try {
      addBusyBee('loadFormDefinitionById');

      const toPost = { operationName: 'getFormDefinitionById', id: id };
      const { data }  = await axios.post(url, toPost);
      const formDefinition = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_FORM_DEFINITION_BY_ID_SUCCESS,
        payload: {
          definition: formDefinition,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadFormDefinitionById');
    }
  };

  const loadAssessmentTypes = async () => {
    try {
      addBusyBee('loadAssessmentTypes');
      
      const { data } = await axios.get(
        `api/staticList/assessment_types`
      );
      const assessmentTypesData = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_ASSESSMENT_TYPES_SUCCESS,
        payload: {
          assessmentTypes: assessmentTypesData,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAssessmentTypes');
    }
  };

  const loadFormTypes = async () => {
    
    const url = `/api/dynamicForms`;
    try {
      addBusyBee('loadFormTypes');

      const toPost = { operationName: 'getFormTypes' };
      const { data }  = await axios.post(url, toPost);
      const formTypes = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_FORM_TYPES_SUCCESS,
        payload: {
          formTypes: formTypes,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadFormTypes');
    }
  };

  const loadAgencyAssessmentTypes = async (agencyId) => {

    try {
      addBusyBee('loadAgencyAssessmentTypes');

      const url = `/api/agencies/${agencyId}`;
      const { data } = await axios.post(url, { operationName: 'getAssessmentTypes' });
      let agencyAssessmentTypesData = parseApiResult(data).body; 

      const picklist = agencyAssessmentTypesData.map(
        (node) => {
          return node.assessmentType;
        }
      );
      
      const unique = [
        ...new Map(picklist.map((item) => [item.id, item])).values(),
      ];

      dispatch({
        type: messageTypes.LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS,
        payload: {
          agencyAssessmentTypes: unique,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAgencyAssessmentTypes');
    }
  };

  const createAssessmentType = async (key, name) => {
    try {
      addBusyBee('createAssessmentType');

      const url = `/api/dynamicForms`;
      const toPost = {
        operationName: 'createAssessmentType',
        key: key,
        name: name,
      };

      const { data } = await axios.post(url, toPost);
      const result = parseApiResult(data).body;

      await loadAssessmentTypes();

      dispatch({
        type: messageTypes.CREATING_ASSESSMENT_TYPE_SUCCESS,
      });

      return result.id;
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createAssessmentType');
    }
  };

  const createDynamicForm = async (formValues) => {
    try {
      addBusyBee('createDynamicForm');

      const data = {
        operationName: 'createDynamicForm',
        formDefinition: formValues.formData,
        formTypeId: formValues.formTypeId,
        formSubtypeId: formValues.formSubtypeId,
        formSubtypeCustomName: formValues.formSubtypeCustomName,
        formSubtypeCustomSequence: formValues.formSubtypeCustomSequence,
        agencyId: userAgency.granteeAgencyId,
        programId: formValues.programId || null,
        assessmentTypeId: formValues.assessmentTypeId || null,
      };

      await axios.post(`/api/dynamicForms`, data);
      await loadAllAgencyFormDefinitions();

      dispatch({
        type: messageTypes.CREATING_DYNAMIC_FORM_SUCCESS,
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('createDynamicForm');
    }
  };

  const loadAgencyPrograms = async () => {
      try {
        addBusyBee('loadAgencyPrograms');

        const url = `/api/agency`;
        const { data } = await axios.post(url, {operationName: 'getAllAgencyPrograms', agencyId: userAgency.granteeAgencyId});
        const agencyPrograms = parseApiResult(data).body;

        dispatch({
          type: messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS,
          payload: {
            agencyPrograms,
          },
        });
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        removeBusyBee('loadAgencyPrograms');
      }
  };


  if (state) {
    return {
      ...state,
      loadAssessmentTypes,
      loadFormTypes,
      loadAllAgencyFormDefinitions,
      loadAgencyAssessmentTypes,
      loadFormDefinitionById,
      createAssessmentType,
      createDynamicForm,
      buildFormDefinitionKey,
      loadFormDefinitionsForFormType,
      loadAgencyPrograms
    };
  }

  return {};
};
