import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './PermissionTemplateSearchContextReducer';

import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from '../lib/utils';
import { useUserAgency } from './UserAgencyContext';

const PermissionTemplateSearchContext = createContext();
const initialState = {
  permissionTemplates: undefined,
  totalPermissionTemplateCount: undefined
};

export const PermissionTemplateSearchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PermissionTemplateSearchContext.Provider value={{ state, dispatch }}>
      {children}
    </PermissionTemplateSearchContext.Provider>
  );
};
export const usePermissionTemplateSearch = () => {
  const { state, dispatch } = useContext(PermissionTemplateSearchContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadPermissionTemplates = async () => {
    try {
      addBusyBee('loadPermissionTemplates');

      const { data } = await axios.post('/api/permissionTemplates', {
        operationName: 'getPermissionTemplates'
      });

      const permissionTemplateResult = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_PERMISSION_TEMPLATES_SUCCESS,
        payload: {
          permissionTemplates: permissionTemplateResult.permissionTemplates
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadPermissionTemplates');
    }
  };

  return {
    ...state,
    loadPermissionTemplates,
  };
};
