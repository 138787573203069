import FieldArrayPanel from './FieldArrayPanel';
import React from 'react';
import FieldView from './FieldView';

const FieldElementView = ({
  disabled,
  dontCheckRequiredFields,
  formDefinition,
  fieldDefinition,
  parentDefinition, // for array elements
  parentIndex, // for array elements
}) => {
  const fieldName = parentDefinition ? `${parentDefinition.id}.${parentIndex}.${fieldDefinition.id}` : fieldDefinition.id;
  switch (fieldDefinition.type) {
    case 'multi-object':
      return (
        <FieldArrayPanel
          disabled={disabled}
          dontCheckRequiredFields={dontCheckRequiredFields}
          formDefinition={formDefinition}
          fieldDefinition={fieldDefinition}
        />
      );
    default:
      return (
        <FieldView
          disabled={disabled}
          dontCheckRequiredFields={dontCheckRequiredFields}
          formDefinition={formDefinition}
          fieldDefinition={fieldDefinition}
          fieldName={fieldName}
          parentDefinition={parentDefinition}
          parentIndex={parentIndex}
        />
      );
  }
};

export default FieldElementView;
