// NOTE: This code is shared with /api/lib/eligibility/rwScorer.js.  Change here, change there.
export const determineRwEligibility = (values, rwScoringConfig, existingClientConfig) => {
  const fplLimit = values?.isGrandfathered ? 500 : rwScoringConfig.fplLimit;
  let incomeEligible = false;
  if (fplLimit === '') {
    incomeEligible = false;
  } else if (existingClientConfig.useMagi && Number(values.monthlyHouseholdModifiedAdjustedGrossIncome) <= 0) {
    incomeEligible = true;
  } else if (!existingClientConfig.useMagi && Number(values.monthlyHouseholdGrossIncome) <= 0) {
    incomeEligible = true;
  } else if (existingClientConfig.useMagi && values.magiHouseholdFederalPovertyLevelPct !== '') {
    incomeEligible = Number(fplLimit) >= Number(values.magiHouseholdFederalPovertyLevelPct);
  } else if (values.householdFederalPovertyLevelPct !== '') {
    incomeEligible = Number(fplLimit) >= Number(values.householdFederalPovertyLevelPct);
  }

  let residencyEligible = false;
  if (!rwScoringConfig.state) {
    residencyEligible = false;
  } else {
    residencyEligible = rwScoringConfig.state === values.primaryAddress.state;
  }

  let hivStatusEligible = false;
  if (!rwScoringConfig.diseaseStages || rwScoringConfig.diseaseStages.length === 0) {
    hivStatusEligible = false;
  } else {
    hivStatusEligible = rwScoringConfig.diseaseStages.includes(values.hivStatusId);
  }

  return {
    incomeEligible,
    residencyEligible,
    hivStatusEligible,
    rwEligible: incomeEligible && residencyEligible && hivStatusEligible
  };
}