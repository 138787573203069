export const messageTypes = {
  LOADING_AGENCY_SUCCESS: 'LOADING_AGENCY_SUCCESS',
  LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS: 'LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS',
  LOADING_AGENCY_ASSESSMENTS_SUCCESS: 'LOADING_AGENCY_ASSESSMENTS_SUCCESS',
  CREATING_AGENCY_ASSESSMENT_SUCCESS: 'CREATING_AGENCY_ASSESSMENT_SUCCESS',
  UPDATING_AGENCY_ASSESSMENT_SUCCESS: 'UPDATING_AGENCY_ASSESSMENTS_SUCCESS',
  LOADING_AGENCY_LANGUAGES_SUCCESS: 'LOADING_AGENCY_LANGUAGES_SUCCESS',
  UPDATING_AGENCY_LANGUAGES_SUCCESS: 'UPDATING_AGENCY_LANGUAGES_SUCCESS',
  LOADING_AGENCY_PROVIDER_TYPES_SUCCESS: 'LOADING_AGENCY_PROVIDER_TYPES_SUCCESS',
  UPDATING_AGENCY_PROVIDER_TYPES_SUCCESS: 'UPDATING_AGENCY_PROVIDER_TYPES_SUCCESS',
  LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS: 'LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS',
  UPDATING_AGENCY_DOCUMENT_TYPES_SUCCESS: 'UPDATING_AGENCY_DOCUMENT_TYPES_SUCCESS',
  LOADING_AGENCY_CONSENT_TYPES_SUCCESS: 'LOADING_AGENCY_CONSENT_TYPES_SUCCESS',
  UPSERTING_AGENCY_CONSENT_TYPE_SUCCESS: 'UPSERTING_AGENCY_CONSENT_TYPE_SUCCESS',
  UPDATING_AGENCY_OPTIONAL_FEATURES_SUCCESS: 'UPDATING_AGENCY_OPTIONAL_FEATURES_SUCCESS',
  UPDATING_AGENCY_CLIENT_INFO_CONFIG_SUCCESS: 'UPDATING_AGENCY_CLIENT_INFO_CONFIG_SUCCESS',
  UPDATING_AGENCY_ELIGIBILITY_CONFIG_SUCCESS: 'UPDATING_AGENCY_ELIGIBILITY_CONFIG_SUCCESS',
  UPDATING_AGENCY_DASHBOARD_CONFIG_SUCCESS: 'UPDATING_AGENCY_DASHBOARD_CONFIG_SUCCESS',
  LOADING_AGENCY_PROGRAMS_SUCCESS: 'LOADING_AGENCY_PROGRAMS_SUCCESS',
  UPDATING_AGENCY_PROGRAMS_SUCCESS: 'UPDATING_AGENCY_PROGRAMS_SUCCESS',
  UPDATING_AGENCY_TEST_RESULT_CONFIG_SUCCESS: 'UPDATING_AGENCY_TEST_RESULT_CONFIG_SUCCESS',
  UPDATING_AGENCY_PREMIUMS_PAID_CONFIG_SUCCESS: 'UPDATING_AGENCY_PREMIUMS_PAID_CONFIG_SUCCESS',
  UPDATING_AGENCY_MEDICATION_DISPENSES_CONFIG_SUCCESS: 'UPDATING_AGENCY_MEDICATION_DISPENSES_CONFIG_SUCCESS',
  LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS: 'LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS',
  UPDATING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS: 'UPDATING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS',
  LOADING_AGENCY_METRICS_SUCCES: 'LOADING_AGENCY_METRICS_SUCCES',
  UPSERTING_AGENCY_METRIC_SUCCESS: 'UPSERTING_AGENCY_METRIC_SUCCESS',
  UPDATING_AGENCY_PERMISSIONS_SUCCESS: 'UPDATING_AGENCY_PERMISSIONS_SUCCESS',
  LOADING_GRANTEE_PERMISSION_TEMPLATES_SUCCESS: 'LOADING_GRANTEE_PERMISSION_TEMPLATES_SUCCESS'
};

export const reducer = (state, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case messageTypes.LOADING_AGENCY_SUCCESS:
      // Clear out any previously loaded state
      return {
        ...payload
      };
    case messageTypes.LOADING_AGENCY_ASSESSMENTS_SUCCESS:
    case messageTypes.LOADING_AGENCY_ASSESSMENT_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_LANGUAGES_SUCCESS:
    case messageTypes.UPDATING_AGENCY_LANGUAGES_SUCCESS:
    case messageTypes.LOADING_AGENCY_PROVIDER_TYPES_SUCCESS:
    case messageTypes.UPDATING_AGENCY_PROVIDER_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_DOCUMENT_TYPES_SUCCESS:
    case messageTypes.UPDATING_AGENCY_DOCUMENT_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_CONSENT_TYPES_SUCCESS:
    case messageTypes.LOADING_AGENCY_PROGRAMS_SUCCESS:
    case messageTypes.LOADING_AGENCY_METRICS_SUCCES:
    case messageTypes.UPDATING_AGENCY_PROGRAMS_SUCCESS:
    case messageTypes.LOADING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS:
    case messageTypes.UPDATING_AGENCY_CLIENT_INFO_CUSTOM_LISTS_SUCCESS:
    case messageTypes.UPDATING_AGENCY_PERMISSIONS_SUCCESS:
    case messageTypes.LOADING_GRANTEE_PERMISSION_TEMPLATES_SUCCESS:
      return {
        ...state,
        ...payload
      };
    case messageTypes.CREATING_AGENCY_ASSESSMENT_SUCCESS:
      const newAssessments = state.assessments ? [...state.assessments, payload.assessment] : undefined;
      return {
        ...state,
        assessments: newAssessments
      };
    case messageTypes.UPDATING_AGENCY_ASSESSMENT_SUCCESS:
      const updAssessments = state.assessments ? [...state.assessments.filter(a => a.id !== payload.assessment.id), payload.assessment] : undefined;
      return {
        ...state,
        assessments: updAssessments
      };
    case messageTypes.UPSERTING_AGENCY_CONSENT_TYPE_SUCCESS:
      const updConsentTypes = state.consentTypes ? [...state.consentTypes.filter(a => a.id !== payload.consentType.id), payload.consentType] : undefined;
      return {
        ...state,
        consentTypes: updConsentTypes
      };
    case messageTypes.UPDATING_AGENCY_CLIENT_INFO_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, clientInfoConfig: payload.clientInfoConfig}
        }
      } 
      break;
    case messageTypes.UPDATING_AGENCY_ELIGIBILITY_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, eligibilityConfig: payload.eligibilityConfig}
        }
      }
      break; 
    case messageTypes.UPDATING_AGENCY_DASHBOARD_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, dashboardConfig: payload.dashboardConfig}
        }
      }
      break; 
    case messageTypes.UPDATING_AGENCY_OPTIONAL_FEATURES_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, optionalFeatures: payload.optionalFeatures}
        }
      }
      break;
    case messageTypes.UPDATING_AGENCY_TEST_RESULT_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, testResultConfig: payload.testResultConfig}
        }
      } 
      break;
    case messageTypes.UPDATING_AGENCY_PREMIUMS_PAID_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, premiumsPaidConfig: payload.premiumsPaidConfig}
        }
      } 
      break;
    case messageTypes.UPDATING_AGENCY_MEDICATION_DISPENSES_CONFIG_SUCCESS:
      if (state.agency) {
        return {
          ...state,
          agency: {...state.agency, medicationDispensesConfig: payload.medicationDispensesConfig}
        }
      } 
      break;
      
    case messageTypes.UPSERTING_AGENCY_METRIC_SUCCESS:
      const newAgencyMetrics = [
        ...state.agencyMetrics?.filter(
          (am) => am.id !== payload.agencyMetric.id
        ),
        payload.agencyMetric,
      ];
      return {
        ...state,
        agencyMetrics: newAgencyMetrics,
      };
      
    case messageTypes.DELETING_AGENCY_METRIC_SUCCESS:
      const newAgencyMetricsWithoutDeleted = [
        ...state.agencyMetrics?.filter(
          (am) => am.id !== payload.agencyMetric.id
        ),
      ];
      return {
        ...state,
        agencyMetrics: newAgencyMetricsWithoutDeleted,
      };
      
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
