export const messageTypes = {
  LOADING_ASSESSMENTS_FOR_CLIENT_AND_PROGRAM_SUCCESS: 'LOADING_ASSESSMENTS_FOR_CLIENT_AND_PROGRAM_SUCCESS',
  CREATING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS: 'CREATING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS',
  COMPLETING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS: 'COMPLETING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS',
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case messageTypes.LOADING_ASSESSMENTS_FOR_CLIENT_AND_PROGRAM_SUCCESS:
      let newAssessments = {};
      Object.entries(state.assessments || {}).forEach(([k, v]) => {
        newAssessments[k] = v;
      });
      newAssessments[payload.programId] = payload.assessments;
      return {
        ...state,
        clientProgramAssessments: newAssessments,
      };
    case messageTypes.CREATING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS:
    case messageTypes.COMPLETING_CLIENT_PROGRAM_ASSESSMENT_SUCCESS:
      return {
        ...state,
      };
    default:
      throw new Error('No reducer found for: ' + type);
  }
};
