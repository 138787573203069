import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { linkifyTabName } from 'lib/formatting';
import { useTheme } from '@mui/material';
import LotusBadge from 'components/dataDisplay/badge/LotusBadge';

const PREFIX = 'LotusTabs';

const classes = {
  noCaps: `${PREFIX}-noCaps`,
  nonactiveText: `${PREFIX}-nonactiveText`,
};

const StyledBox = styled(Box)(({ theme }) => {
  return {
    [`& .${classes.noCaps}`]: {
      textTransform: 'none'
    },
    [`& .${classes.nonactiveText}`]: {
      color: theme.palette.other.tabs,
    },
  };
});

const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    [`& .MuiTabs-scrollButtons.Mui-disabled`]: {
      opacity: 0.3
    },
    [`& .MuiTabs-scrollButtons`]: {
      width: 24
    }
  };
});

// Each tab in tabs is a {label, link, hasError, hasAlert, badge}
export default function LotusTabs({ 
  selectedTabName, 
  tabs, 
  tabPropertyToMatch, 
  isScrollable = false,
  dontCapitalize = false,
  useIndicatorIcon = false
}) {

  const theme = useTheme();

  const [currentTabIndex, setCurrentTabIndex] = useState();
  const navigate = useNavigate();

  const handleTabChange = async (event, tIdx) => {
    navigate(tabs[tIdx].link);
    setCurrentTabIndex(tIdx);
  };

  useEffect(() => {
    if (selectedTabName && tabs) {
      const idx = tabs.findIndex(t => (tabPropertyToMatch ? t[tabPropertyToMatch]?.toLowerCase() : linkifyTabName(t.label)) === selectedTabName.toLowerCase());
      if (idx >= 0) {
        setCurrentTabIndex(idx);
      } else {
        navigate(tabs[0].link);
      }
    }
  }, [selectedTabName, tabs]);

  return tabs && tabs.length > 0 && currentTabIndex >= 0 && (
    <StyledBox>
      <StyledTabs
        value={currentTabIndex}
        TabIndicatorProps={useIndicatorIcon ? { style: { backgroundColor: 'white' } } : { style: { backgroundColor: theme.palette.primary.main } }}
        onChange={handleTabChange}
        variant={isScrollable ? 'scrollable' : undefined}
        scrollButtons={isScrollable ? 'auto' : undefined}
        sx={{ minHeight: 50, height: 50 }} 
      >
        {tabs &&
          tabs.map((tab, index) => {
            const icon = (useIndicatorIcon && currentTabIndex === index) ? (<FiberManualRecordIcon style={{fontSize: 10}} />) : undefined;
            const errorIcon = tab.hasError === true ? <ErrorIcon color='error' style={{fontSize: 20}}  /> : undefined;
            const alertIcon = tab.hasAlert === true ? <ErrorOutlineIcon color='error'/> : undefined;
            return (
              <Tab
                id={tab.label}
                key={tab.label}
                tabIndex={index}
                className={classNames({
                  [classes.nonactiveText]: currentTabIndex !== index,
                  [classes.noCaps]: dontCapitalize
                })}
                label={tab.badge !== undefined ? 
                  <LotusBadge content={tab.badge} color="primary"><div style={{marginTop: 5, marginBottom: 5, marginRight: 10}}>{tab.label}</div></LotusBadge>: 
                  tab.label
                }
                icon={icon || errorIcon || alertIcon}
                iconPosition="start"
                sx={{ minHeight: 50, height: 50 }} 
              />
            );
          })
        }
      </StyledTabs>
    </StyledBox>
  )
}

