import React, { useEffect, useState } from 'react';
import { useDataExport } from 'contexts/DataExportContext';
import moment from 'moment';
import { Table } from '@lotus/components';
import ActionDialog from 'components/Dialogs/ActionDialog';
import LotusFileViewer from 'components/widgets/LotusFileViewer';
import { TXT, CSV } from '../../lib/docTypes';

export default function ExportsTable({ agencyId }) {
  const { exportRequests, getExportRequests } = useDataExport();
  const [requests, setRequests] = useState();
  const [metaData, setMetaData] = useState();
  const [documentToView, setDocumentToView] = useState();

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  const formatStatus = (r) => {
    if (r.errors) {
      return r.errors
    }
    if (r.complete_date) {
      return 'Export Complete'
    }
    else {
      return 'In Progress'
    }
  };

  const formatRequestorName = (r) => {
    if (r.name && r.name.trim()) {
      return r.name
    }
    return "Unknown";
  };


  const viewRequestDetailsForRow = async (rowData, rowMeta) => {
    const exportRequest = exportRequests.find(i => i.id === rowData[0]);
    const type = exportRequest.file_name.toLowerCase().endsWith('csv') ? CSV : TXT;

    let file = {
      type: type, uniqueFileName: exportRequest.file_name, displayName: exportRequest.display_file_name
    }
    const status = formatStatus(exportRequest);
    if (status === 'Export Complete') {
      setDocumentToView([file]);

      if (exportRequest?.complete_date) {
        setMetaData({
          field1: { label: 'Status:', value: formatStatus(exportRequest) },
          field2: { label: 'Initiator:', value: formatRequestorName(exportRequest) },
          field3: { label: 'Type:', value: exportRequest.export_type_name },
          field4: { label: 'Desc:', value: exportRequest.description },
          field5: { label: 'Delimiter:', value: exportRequest.delimiter },

        });
      } else {
        if (!exportRequest?.errors) {
          setMetaData({
            field1: { label: 'Status:', value: formatStatus(exportRequest) },
          });
        } else {
          setMetaData({
            field1: { label: 'Error:', value: exportRequest.errors[0] },
            field2: { label: 'Status:', value: 'Export Failed' },
          });
        }
      }
    }


  };

  useEffect(() => {
    if (agencyId) {
      getExportRequests(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (exportRequests) {
      const reqs = exportRequests.map((r) => {
        return {
          id: r.id,
          description: r.description,
          exportTypeName: r.export_type_name,
          requestDate: moment(r.request_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
          requestDateStr: moment(
            r.request_date,
            'YYYY-MM-DDTHH:mm:ss.SSSZ'
          ).format('MM/DD/YYYY HH:mm'),
          startDateStr: r.start_date
            ? moment(r.start_date, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
              'MM/DD/YYYY HH:mm'
            )
            : '',
          statusStr: formatStatus(r),
          nameStr: formatRequestorName(r),
          fileName: r.display_file_name || '',
        };
      });
      reqs.sort((a, b) => {
        return b.requestDate - a.requestDate;
      });
      setRequests(reqs);
    }
  }, [exportRequests]);

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      label: 'Description',
      name: 'description',
    },
    {
      label: 'Export Date/Time',
      name: 'requestDateStr',
    },
    {
      label: 'Type',
      name: 'exportTypeName',
    },
    {
      label: 'Status',
      name: 'statusStr',
    },
    {
      label: 'Initiator',
      name: 'nameStr',
    },
    {
      label: 'File Name',
      name: 'fileName',
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={requests}
        options={{
          pagination: true,
          filter: false,
          search: false,
          searchOpen: false,
          download: false,
          print: false,
          viewColumns: false,
        }}
        handleRowClicked={viewRequestDetailsForRow}
      />

      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentToView)}
        content={<LotusFileViewer agencyId={agencyId} subfolderName='exports' title={'Request Details'} file={documentToView} metaData={metaData} handleClose={closeDocumentView} compactMode={true} />}
      />
    </div>
  );
}