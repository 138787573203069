import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { Stack, Typography } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Button, Table } from '@lotus/components';
import LotusFileViewer  from 'components/widgets/LotusFileViewer';
import ConsentDocumentUploader from './components/ConsentDocumentUploader';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useAppStatus } from 'contexts/AppStatusContext';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { useClient } from 'contexts/ClientContext';
import { useLists } from 'contexts/ListsContext';
import LotusPageSubSection from 'components/widgets/Layout/LotusPageSubSection';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusCardList from 'components/widgets/Layout/LotusCardList';
import LotusCard from 'components/widgets/Layout/LotusCard';

export default function ConsentsPanel({
  consents,
  readOnly = false,
  programOnly = false,
  consentTypeIds,
  requiredConsentTypeIds,
  onConsentAdded,
  disabled
}) {
  const { userAgency, userAgencyConsentTypes, loadUserAgencyConsentTypes } = useUserAgency();
  const { client } = useClient();
  const { languages, loadLanguages } = useLists();
  const [loadedAgencyConsentTypes, setLoadedAgencyConsentTypes] = useState();
  const [latestConsents, setLatestConsents] = useState();
  const [allConsents, setAllConsents] = useState();
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();
  const [documentToView, setDocumentToView] = useState();
  const [metaData, setMetaData] = useState();
  const [consentTypeForUpload, setConsentTypeForUpload] = useState();
  const [consentTypeIdsToUse, setConsentTypeIdsToUse] = useState();
  const [languageId, setLanguageId] = useState();
  const [consentName, setConsentName] = useState();
  const [fileAgencyId, setFileAgencyId] = useState();
  const [fileClientId, setFileClientId] = useState();
  const { clientId } = useParams();

  useEffect(() => {
    if (consentTypeIds) {
      setConsentTypeIdsToUse(consentTypeIds);
    } else {
      setConsentTypeIdsToUse([]);
    }
  }, [consentTypeIds]);

  useEffect(() => {
    if (!languages) {
      loadLanguages();
    }
  }, []);

  useEffect(() => {
    if (client && languages) {
      setLanguageId(languages.find((l) => l.id === client.primaryLanguageId)?.id);
    }
  }, [client, languages]);

  useEffect(() => {
    if (!userAgencyConsentTypes) {
      loadUserAgencyConsentTypes();
    }
  }, [userAgency]);

  useEffect(() => {
    if (consentTypeIdsToUse && userAgencyConsentTypes) {
      let consentTypes = [
        ...userAgencyConsentTypes.filter(
          (act) =>
            (programOnly ? !act.isAgencyWide : act.isAgencyWide) &&
            (consentTypeIdsToUse.length === 0 ||
              consentTypeIdsToUse.includes(act.id))
        ),
      ];
      consentTypes.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyConsentTypes(consentTypes);
    }
  }, [userAgencyConsentTypes, consentTypeIdsToUse]);

  useEffect(() => {
    if (loadedAgencyConsentTypes && userAgencyConsentTypes && consents) {
      const clientConsents = consents;

      // Associate the latest consent for each consent type
      const latestConsentPerConsentType = {};
      const consentList = [];
      const now = moment();
      clientConsents.map((cc) => {
        let newcc = { ...cc };
        const consentType = userAgencyConsentTypes.find(
          (act) => act.id === newcc.agencyConsentTypeId
        );
        const progConsentType = loadedAgencyConsentTypes.find(
          (act) => act.id === newcc.agencyConsentTypeId
        );

        newcc.dateUploadedStr = moment(newcc.createdDate, 'MM/DD/YYYY').format('MM/DD/YYYY');
        newcc.dateUploadedSortStr = moment(newcc.createdDate, 'MM/DD/YYYY').format('YYYYMMDD');
        newcc.dateSignedStr = newcc.dateSigned || 'Not signed';
        newcc.dateSignedSortStr = newcc.dateSigned ? moment(newcc.dateSigned, 'MM/DD/YYYY').format('YYYYMMDD') : 'Not signed';
        if (progConsentType) {
          newcc.dateExpiring = progConsentType.expirationMonths && newcc.dateSigned
            ? moment(newcc.dateSigned, 'MM/DD/YYYY').add(progConsentType.expirationMonths, 'month')
            : null;
        }
        else {
          newcc.dateExpiring = null;
        }
        newcc.dateExpiringStr = newcc.dateExpiring ? newcc.dateExpiring.format('MM/DD/YYYY') : 'No Expiration';
        newcc.dateExpiringSortStr = newcc.dateExpiring ? newcc.dateExpiring.format('YYYYMMDD') : 'No Expiration';
        newcc.isExpired = newcc.dateExpiring && newcc.dateExpiring < now;
        newcc.consentType = consentType;
        newcc.preview = newcc.receivedAudibleConsent ? '' : newcc.consentType?.defaultConsentDocumentThumbnail;
        newcc.displayName = newcc.document?.displayName;
        if (newcc.dateSigned) {
          const latestForType = latestConsentPerConsentType[newcc.agencyConsentTypeId];
          if (!latestForType || moment(latestForType.dateSigned, 'MM/DD/YYYY') < moment(newcc.dateSigned, 'MM/DD/YYYY')) {
            latestConsentPerConsentType[newcc.agencyConsentTypeId] = newcc;
          }
        }
        consentList.push(newcc);
      });
      setLatestConsents(latestConsentPerConsentType);
      consentList.sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1));
      setAllConsents(consentList);
    }
  }, [loadedAgencyConsentTypes, userAgencyConsentTypes, consents]);

  const columns = [
    {
      label: 'Consent type',
      name: 'consentType.name',
    },
    {
      label: 'Preview',
      name: 'preview',
      options: {
        customBodyRender: (value) => {
          return value ? <img src={value} /> : 'Verbal';
        },
        filter: false,
        searchable: false,
        download: false,
      },
    },
    {
      label: 'Date signed',
      name: 'dateSignedSortStr',
      options: {
        customBodyRender: (value, tableMeta) => { return (<div>{tableMeta.rowData[6]}</div>); }
      }
    },
    {
      label: 'Expires',
      name: 'dateExpiringSortStr',
      options: {
        customBodyRender: (value, tableMeta) => { return (<div>{tableMeta.rowData[7]}</div>); }
      }
    },
    {
      label: 'File name',
      name: 'displayName',
    },
    {
      label: 'Date uploaded',
      name: 'dateUploadedSortStr',
      options: {
        customBodyRender: (value, tableMeta) => { return (<div>{tableMeta.rowData[8]}</div>); }
      }
    },
    {
      name: 'dateSignedStr',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'dateExpiringStr',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
    {
      name: 'dateUploadedStr',
      options: {
        display: 'excluded',
        filter: false,
        searchable: false,
      },
    },
  ];

  const viewLatestClientConsentForType = async (consentType) => {
    const latestConsentForType = latestConsents[consentType.id];
    await viewClientConsent(latestConsentForType);
  };

  const viewClientConsentForRow = async (rowData, rowMeta) => {
    const clientConsent = allConsents[rowMeta.rowIndex];
    await viewClientConsent(clientConsent);
  };

  const viewClientConsent = async (clientConsent) => {
    try {
      addBusyBee('viewingClientConsentFile');

      let file;
      if (clientConsent?.receivedAudibleConsent) {
        file = {};
      } else {
        file = {
          fileName: clientConsent?.document?.displayName, uniqueName: clientConsent?.document?.file?.name,
          createdDate: moment(clientConsent.dateSignedStr), type: clientConsent?.document?.type, filePath: 'consents'
        }
      }
      setFileAgencyId(null);
      setFileClientId(clientId);
      setConsentName(clientConsent?.consentType.name);
      setDocumentToView(file);
      const metaData = {
        field1: { label: 'Date Expiring:', value: clientConsent.dateExpiringStr },
        field2: { label: 'Date Signed:', value: clientConsent.dateSignedStr },
      };
      setMetaData(metaData);

    } catch (error) {
      console.log(error);
      setError('Error viewing client consent file');
    } finally {
      removeBusyBee('viewingClientConsentFile');
    }
  };

  const viewConsentTypeDocument = async (consentType) => {
    try {
      addBusyBee('viewingConsentTypeFile');
      const languageDocUniqueFileName = 
        consentType.languageDocuments?.find((ld) => ld.languageId === languageId)?.consentDocumentUniqueFileName || consentType.defaultConsentDocumentUniqueFileName;

      let file = {
        fileName: consentType.defaultConsentDocumentFileName, uniqueName: languageDocUniqueFileName,
        createdDate: consentType.createdDate, type: consentType.defaultConsentDocumentFileType, filePath: 'consents'
      }
      setFileAgencyId(userAgency.granteeAgencyId);
      setFileClientId(null);
      setConsentName(consentType.name);
      setDocumentToView(file);
      setMetaData(null); 
    } catch (error) {
      console.log(error);
      setError('Error viewing consent type file');
    } finally {
      removeBusyBee('viewingConsentTypeFile');
    }
  };

  const uploadConsentDocument = (consentType) => {
    setConsentTypeForUpload(consentType);
  };

  const addCompletedConsent = async (completedClientConsent) => {
    const clientConsent = {
      agencyConsentTypeId: consentTypeForUpload.id,
      createdDate: moment().format('MM/DD/YYYY'),
      dateSigned: completedClientConsent.dateSigned,
      receivedAudibleConsent: completedClientConsent.receivedAudibleConsent,
      document: completedClientConsent.document,
    };
    closeUploadView();

    if (onConsentAdded) {
      onConsentAdded(clientConsent);
    }
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  const closeUploadView = () => {
    setConsentTypeForUpload(null);
  };

  return (
    <div style={{marginTop: "-16px"}}>
        <LotusPageSubSection header="Current Consents">
          {loadedAgencyConsentTypes && loadedAgencyConsentTypes.length === 0 && (
            <LotusSpacedBlock>
              <div>No consents available</div>
            </LotusSpacedBlock>
          )}
          <LotusCardList>
          {latestConsents && loadedAgencyConsentTypes && loadedAgencyConsentTypes.filter(ct => ct.isActive).map((act) => {
            return (
              <LotusCard key={act.id}>
                <Grid container style={{paddingBottom: 10, borderBottom: '1px solid #0000003b'}}>
                  <Grid item xs={4}>
                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={{ xs: 3 }}>
                      <Typography variant="h5">{act.name}{requiredConsentTypeIds && requiredConsentTypeIds.includes(act.id) ? ' *' : ''}</Typography>
                      <Stack direction="column" justifyContent="flex-start" alignItems="center">
                        {latestConsents[act.id]?.isExpired && <HourglassBottomIcon style={{ color: 'b53f3f' }}/>}
                        <Typography variant='body2'>Date expiring: {latestConsents[act.id] ? latestConsents[act.id].dateExpiringStr : '--'}</Typography>
                      </Stack>
                      <Typography variant='body2'>Date signed: {latestConsents[act.id] ? latestConsents[act.id].dateSignedStr : 'Incomplete'}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={8} style={{ height: 150, overflow: 'hidden' }} >
                    <Grid container direction="row" justifyContent="center">
                      <img src={act.defaultConsentDocumentPreview} width='150' />
                    </Grid>
                  </Grid>
                </Grid>
                <Stack style={{ paddingTop: 10 }} direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                  <Button variant="text" color="primary" disabled={act.id in latestConsents === false} onClick={() => viewLatestClientConsentForType(act)}>
                    View
                  </Button>
                  {!disabled &&
                  <Button variant="outlined" color="primary" disabled={readOnly || !act.isActive} onClick={() => viewConsentTypeDocument(act)}>
                    Generate
                  </Button>
                  }
                  {!disabled &&
                  <Button variant="contained" color="primary" disabled={readOnly || !act.isActive} onClick={() => uploadConsentDocument(act)}>
                    Upload
                  </Button>
                  }
                </Stack>
              </LotusCard>
            );})}
          </LotusCardList>
        </LotusPageSubSection>
        {allConsents && (
          <LotusPageSubSection header="All Consents">
            <Table
              columns={columns}
              data={allConsents}
              options={{
                pagination: false,
                filter: false,
              }}
              handleRowClicked={viewClientConsentForRow}
            />
          </LotusPageSubSection>
        )}
        <ActionDialog
          fullWidth={true}
          maxWidth="lg"
          open={Boolean(documentToView)}
          content={<LotusFileViewer agencyId={fileAgencyId} clientId={fileClientId} subfolderName="consents" title={consentName} file={[documentToView]} metaData={metaData}  handleClose={closeDocumentView}/>}
        />
        <ActionDialog
          maxWidth="sm"
          open={Boolean(consentTypeForUpload)}
          content={
            <ConsentDocumentUploader
              consentType={consentTypeForUpload}
              clientId={clientId}
              handleCancel={closeUploadView}
              handleSave={addCompletedConsent}
            />
          }
        />
    </div>
  );
};