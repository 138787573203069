import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import HousingTypeFieldPanel from 'components/widgets/Composites/HousingTypeFieldPanel';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import moment from 'moment';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { Stack } from '@mui/system';
import SectionCommentsChain from './SectionCommentsChain';
import { determineRwEligibility } from 'lib/rwScorer';
import AddressFieldPanelWithLocationSearch from 'components/widgets/Composites/AddressFieldPanelWithLocationSearch';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';

export const getAddressesStaticFormSchema = (jailHousingTypeId) => {
  return Yup.object().shape({
    housingStatusId: Yup.string().required("Housing Status is required"),
    housingTypeId: Yup.string().required("Housing Type is required"),
    incarcerationDate: Yup.string().nullable().test({
      name: 'validDate',
      test(value, ctx) {
        if (ctx.parent.housingTypeId === jailHousingTypeId && !value) {
          return ctx.createError({ message: 'Incarceration Start Date is required'});
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Incarceration Start Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Incarceration Start Date is too old' });
          }
        }
        return true;
      }
    }),
    projectedIncarcerationReleaseDate: Yup.string().nullable().test({
      name: 'validDate',
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Projected Incarceration Release Date must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Projected Incarceration Release Date is too old' });
          }
        }
        return true;
      }
    }),
    mailingSameAsPrimary: Yup.bool().required("Is Mailing Address same as Primary Address? is required"),
    mailToName: Yup.string().required("Mail To Name is required"),
    primaryAddress: Yup.object().shape({
      address1: Yup.string().required("Address is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zip: Yup.string().required("Zip is required"),
      county: Yup.string().required("County is required"),
    }),
    mailingAddress: Yup.object().shape({
      address1: Yup.string().test("mailAddr1", "Address is required", function(value, ctx) {
        return ctx.from[1].value.mailingSameAsPrimary || value;
      }),
      city: Yup.string().test("mailCity", "City is required", function(value, ctx) {
        return ctx.from[1].value.mailingSameAsPrimary || value;
      }),
      state: Yup.string().test("mailSt", "State is required", function(value, ctx) {
        return ctx.from[1].value.mailingSameAsPrimary || value;
      }),
      zip: Yup.string().test("mailZip", "Zip is required", function(value, ctx) {
        return ctx.from[1].value.mailingSameAsPrimary || value;
      }),
      county: Yup.string().test("mailCounty", "County is required", function(value, ctx) {
        return ctx.from[1].value.mailingSameAsPrimary || value;
      })
    }),
    addressDocumentation: Yup.array().min(1, 'Address Documentation is required'),
    addressCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
  });
}

export const getAddressesStaticFormSchemaExcludingRequiredChecks = (eligibilityAppConfig) => {
  return Yup.object().shape({
    incarcerationDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Incarceration Start Date must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Incarceration Start Date is too old' });
        }
        return true;
      }
    }),
    projectedIncarcerationReleaseDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Projected Incarceration Release Date must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Projected Incarceration Release Date is too old' });
        }
        return true;
      }
    }),
  });
}

export const buildAddressesStaticFormSectionNames = (eligibilityAppConfig) => {
  const lst = ["Housing Status","Primary Address"];
  if (eligibilityAppConfig.showSecondaryAddress) {
    lst.push("Secondary Address");
  }
  lst.push("Documentation");
  return lst;
}

export const initializeAddressesFormData = (formData, clientName) => {
  if (!formData) {
    return formData;
  }
  // React gets pissed about null values bound to controls
  return {
    housingTypeId: formData.housingTypeId || '', 
    housingStatusId: formData.housingStatusId || '',
    addressesUpdateEffectiveDate: formData.addressesUpdateEffectiveDate || moment().format('MM/DD/YYYY'),  
    incarcerationDate: formData.incarcerationDate || null,
    projectedIncarcerationReleaseDate: formData.projectedIncarcerationReleaseDate || null,
    primaryAddress: {
      address1: formData.primaryAddress.address1 || '', 
      address2: formData.primaryAddress.address2 || '', 
      city: formData.primaryAddress.city || '', 
      state: formData.primaryAddress.state || '', 
      zip: formData.primaryAddress.zip || '', 
      county: formData.primaryAddress.county || ''
    },
    secondaryAddress: {
      address1: formData.secondaryAddress.address1 || '', 
      address2: formData.secondaryAddress.address2 || '', 
      city: formData.secondaryAddress.city || '', 
      state: formData.secondaryAddress.state || '', 
      zip: formData.secondaryAddress.zip || '', 
      county: formData.secondaryAddress.county || ''
    },
    mailingSameAsPrimary: formData.mailingSameAsPrimary !== null ? formData.mailingSameAsPrimary : false, 
    mailToName: formData.mailToName || clientName, 
    mailingAddress: {
      address1: formData.mailingAddress.address1 || '', 
      address2: formData.mailingAddress.address2 || '', 
      city: formData.mailingAddress.city || '', 
      state: formData.mailingAddress.state || '', 
      zip: formData.mailingAddress.zip || '', 
      county: formData.mailingAddress.county || ''
    },
    addressDocumentation: formData.addressDocumentation || [],
    addressCommentsChain: formData.addressCommentsChain || []
  };
}

export const getAddressesFormData = (formData) => {
    
  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    housingTypeId, housingStatusId, addressesUpdateEffectiveDate, incarcerationDate, projectedIncarcerationReleaseDate,
    primaryAddress, secondaryAddress, mailingSameAsPrimary, mailToName, mailingAddress, addressDocumentation, addressCommentsChain
  } = formData;

  const addresses = {
    housingTypeId, housingStatusId, addressesUpdateEffectiveDate, incarcerationDate, projectedIncarcerationReleaseDate,
    primaryAddress, secondaryAddress, mailingSameAsPrimary, mailToName, mailingAddress, addressDocumentation, addressCommentsChain
  };
  return addresses;
}

const EligibilityApplicationAddressesStaticForm = ({clientId, existingClientConfig, eligibilityAppConfig, rwScoringConfig, disabled, isInterimUpdate}) => {
 
  const {housingStatuses} = useLists();
  const [housingStatusList, setHousingStatusList] = useState();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (housingStatuses) {
      setHousingStatusList(housingStatuses.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
  }, [housingStatuses]);

  useEffect(() => {
    let result = determineRwEligibility(values, rwScoringConfig, existingClientConfig);
    setFieldValue("rwIncomeIneligible", !result.incomeEligible);
    setFieldValue("rwResidencyIneligible", !result.residencyEligible);
    setFieldValue("rwHivStatusIneligible", !result.hivStatusEligible);
    setFieldValue("rwEligible", result.rwEligible);
  }, [values.primaryAddress.state]);

  useEffect(() => {
    if(values.mailingSameAsPrimary) {
      values.mailingAddress = {
        address1: '', 
        address2: '', 
        city: '', 
        state: '', 
        zip: '', 
        county: ''
      }
    }
  }, [values.mailingSameAsPrimary]);

  return values && existingClientConfig && eligibilityAppConfig && rwScoringConfig && (
    <Stack direction="row">
      <LotusForm>
        {isInterimUpdate &&
          <LotusFormItem>
            <LotusDatePicker
              name="addressesUpdateEffectiveDate"
              label="Effective Date"
              disabled
            />
          </LotusFormItem>
        }
        <LotusFormSection name="Housing Status" disabled={disabled}>
          {housingStatusList &&
            <LotusFormItem>
              <LotusRadioGroup
                name="housingStatusId"
                label="Housing Status"
                items={housingStatusList}
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            </LotusFormItem>
          }
          <LotusFormItem>
            <HousingTypeFieldPanel
              housingStatusKey={values.housingStatusId ? housingStatuses.find(s => s.id === values.housingStatusId).itemKey : null}
              housingTypeFieldName="housingTypeId"
              incarcerationDateFieldName="incarcerationDate"
              projectedIncarcerationReleaseDateFieldName="projectedIncarcerationReleaseDate"
              disabled={disabled}
              required
              dontCheckRequiredFields={true}
            />
          </LotusFormItem>
        </LotusFormSection>
        <LotusFormSection name="Primary Address" disabled={disabled}>
          <LotusFormItems>
            <AddressFieldPanelWithLocationSearch
              name='primaryAddress' 
              disabled={disabled}
              required
              dontCheckRequiredFields={true}
            />
            <LotusTrueFalseRadioGroup
              name="mailingSameAsPrimary"
              label="Is Mailing Address same as Primary Address?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            />
            <LotusTextInput
              name="mailToName"
              label="Mail To Name"
              disabled={disabled}
              maxLength={70}
              required
              dontCheckRequiredFields={true}
            />
            {!values.mailingSameAsPrimary &&
              <AddressFieldPanelWithLocationSearch
                name='mailingAddress' 
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            } 
          </LotusFormItems>
        </LotusFormSection>
        {eligibilityAppConfig.showSecondaryAddress &&
          <LotusFormSection name="Secondary Address" disabled={disabled}>
            <LotusFormItem>
              <AddressFieldPanelWithLocationSearch
                name='secondaryAddress' 
                disabled={disabled}
              />
            </LotusFormItem>
          </LotusFormSection>
        }
        <LotusFormSection name="Documentation" disabled={disabled}>
          <LotusFormItem>
            <LotusSpacedBlock>
              <div>Upload Address Documentation *</div>
            </LotusSpacedBlock>
            <ClientInfoDocumentation 
              documentationListFieldName="addressDocumentation"
              allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoAddressDocumentationTypes}
              clientId={clientId}
              disabled={disabled}
            />
          </LotusFormItem>
        </LotusFormSection>
      </LotusForm>
      <div>
        <SectionCommentsChain chainFieldName="addressCommentsChain"/>
      </div>
    </Stack>
  );
};

export function buildAddressesStaticForm({...props}) {
  return <EligibilityApplicationAddressesStaticForm {...props}/>
}