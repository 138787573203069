import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GoalForm from './GoalForm';
import OpenIcon from '@mui/icons-material/FiberManualRecord';
import ClosedIcon from '@mui/icons-material/Done';
import LotusAccordion from 'components/widgets/Layout/LotusAccordion';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { useLists } from 'contexts/ListsContext';

export default function GoalPanel({ readOnly, goal, barrierId }) {
  const [goalExpanded, setGoalExpanded] = useState(false);
  const { goalStatuses } = useLists();

  const afterSave = async () => {
    setGoalExpanded(false);
  };

  const onExpandedChanged = (expanded) => {
    setGoalExpanded(expanded);
  }

  return (
    <LotusSpacedBlock>
      {goal && goalStatuses && (
        <LotusAccordion 
          isExpanded={goalExpanded}
          onExpandedChanged={onExpandedChanged}
          collapsedContent={
            <Grid container>
              <Grid item xs={5}>
                <Typography color="primary" variant="body1">
                  {goal.description}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={1}>
                  {goalStatuses.find(s => s.id === goal.statusId)?.name === 'Closed' && (
                    <ClosedIcon style={{color: '#8A8A8A', marginLeft: 15}} />
                  )}
                  {goalStatuses.find(s => s.id === goal.statusId)?.name === 'Open' && (
                    <OpenIcon style={{fontSize: 14, color: '#4A97A6', marginLeft: 20, marginTop: 5}} />
                  )}
                  <Typography color="primary" sx={{ marginLeft: '-45px' }} variant="body1">
                    {goalStatuses.find(s=> s.id === goal.statusId)?.name} {goal.lastStatusChangeDate}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Typography color="primary" variant="body1">
                  Target Date: {goal.targetDate}
                </Typography>
              </Grid>
            </Grid>
          }
          expandedContent={
            <GoalForm
              readOnly={readOnly}
              goal={goal}
              barrierId={barrierId}
              afterSave={afterSave}
              handleCancel={() => {setGoalExpanded(false)}}
            />
          }
        />
      )}
    </LotusSpacedBlock>
  );
}
