import React, { useState } from 'react'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import Typography from '@mui/material/Typography';
import QRCode from 'react-qr-code';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { Grid, Box, List, ListItem } from "@mui/material";
import LotusAccordion from 'components/widgets/Layout/LotusAccordion';
import LotusSpacer from 'components/widgets/Layout/LotusSpacer';
import authyIcon from './Authy.png';
import msAuthenticatorIcon from './msAuthenticator.png';
import googleAuthenticatorIcon from './googleAuthenticator.png';


export default function SetupMFAForm({
  values,
  handleChange,
  touched,
  errors,
}) {

  const [otp, setOtp] = useState('');
  const [learnExpanded, setLearnExpanded] = useState(false);

  const handleOtpChange = (newValue) => {
    values.otp = newValue;
    values.errorMessage = '';
    setOtp(newValue);
  }
  const onExpandedChanged = (expanded) => {
    setLearnExpanded(expanded);
  }

  return (
    <>
      {values.operation === 'setupMFA' && (
        <Box
          sx={{ flexDirection: 'column' }}
          display="flex"
          justifyContent="left"
          alignItems="left"
        >
          <List
            sx={{
              listStyleType: 'decimal',
              listStylePosition: 'inside',
            }}
          >

            <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '0px 0px' }}>
              Scan the QR code or type the secret key into your MFA application
            </ListItem>
            <ListItem sx={{ display: 'list-item', fontSize: 16, padding: '0px 0px' }}>
              Input the 6 digit code below
            </ListItem>

          </List>

          <LotusSpacer />
          <LotusAccordion
           
            isExpanded={learnExpanded}
            onExpandedChanged={onExpandedChanged}
            showBottomBorder={true}
            collapsedContent={
              <Grid item xs={6}>
                <Typography color="#006B79" variant="body1">Learn more about MFA</Typography>
              </Grid>
            }
            expandedContent={
              <Box
                sx={{ flexDirection: 'column' }}
                display="flex" xs
                justifyContent="left"
                alignItems="left"
              >
                <Typography style={{ width: 600, fontSize: 16 }}>Commonly used MFA applications include:</Typography>
                <LotusSpacer />

                <Grid container direction="row" alignItems="center" justifyContent="left" spacing={1} columns={2}>
                  <Grid item>
                    <img src={googleAuthenticatorIcon} style={{ height: 25, width: 25 }} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ width: 400, fontSize: 16 }}>Google Authenticator</Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justifyContent="left" spacing={1} columns={2}>
                  <Grid item>
                    <img src={msAuthenticatorIcon} style={{ height: 25, width: 25 }} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ width: 400, fontSize: 16 }}>Microsoft Authenticator</Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="left" spacing={1} columns={2}>
                  <Grid item>
                    <img src={authyIcon} style={{ height: 25, width: 25 }} />
                  </Grid>
                  <Grid item>
                    <Typography style={{ width: 400, fontSize: 16 }}>Authy</Typography>
                  </Grid>
                </Grid>
                <LotusSpacer />
                <Typography style={{ width: 600, fontSize: 16 }}>If you don’t already use an MFA application, find out which application is recommended by your organization. Then follow the installation and configuration instructions on the application’s website.</Typography>
              </Box>
            }
          />
          <LotusSpacer />
          <LotusSpacer />
          <LotusSpacer />

          {values.token &&
            <QRCode size={137} value={values.token} />
          }
          <LotusSpacer /> <LotusSpacer />
          {values.secretKey &&

            <LotusTextInput
              id="secretKey"
              name="secretKey"
              label="Secret Key"
              value={values.secretKey}
              disabled
            />
          }
          <LotusSpacer /> <LotusSpacer /> <LotusSpacer /><LotusSpacer />

          <Typography >6-Digit Code
          </Typography>
          <LotusSpacer />
          <Grid item style={{ width: "400px", border: '10px' }}>
            <MuiOtpInput autoFocus length={6} value={values.otp}
              onChange={handleOtpChange}
              TextFieldsProps={{ type: "password", autoComplete: "off", size: 'small' }}
              error={touched.otp && Boolean(errors.otp)} />
          </Grid>
          <LotusSpacer /><LotusSpacer /><LotusSpacer />

          <Typography variant="body" color='#b53f3f'>{values.errorMsg}</Typography>
          <LotusButton disabled={!Boolean(values?.otp?.length === 6)} type="submit">
            Log in
          </LotusButton>
          <LotusSpacer /> <LotusSpacer /> <LotusSpacer /><LotusSpacer />
        </Box>

      )
      }
    </>
  );
};
