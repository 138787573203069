import Grid from '@mui/material/Grid';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import LotusStateSelector from 'components/widgets/Forms/LotusStateSelector';
import LotusCountySelector from 'components/widgets/Forms/LotusCountySelector';
import { Stack } from '@mui/material';

export default function AddressFieldPanel ({ disabled, name, style, title, required, address1Label, useSeedData = false, dontCheckRequiredFields, ...props }) {
  const { values } = useFormikContext();
  const [selectedState, setSelectedState] = useState();
  const [addressValue, setAddressValue] = useState();

  useEffect(() => {
    if (values) {
      const address = getFieldValue(name, values);
      setAddressValue(address);
    }
  }, [values]);

  useEffect(() => {
    if (addressValue && addressValue.state !== selectedState) {
      setSelectedState(addressValue.state);
    }
  }, [addressValue]);

  const getFieldValue = (name, values) => {
    if (name.includes('.') && Array.isArray(values[name.split('.')[0]])) {
      let parts = name.split('.');
      const arrayItem = values[parts[0]][parts[1]];
      return arrayItem ? arrayItem[parts[2]] : null;
    } else {
      return values[name];
    }
  };

  return addressValue && (
      <Grid item xs={12} container style={style ? style.main : {}}>
        {title &&
        <Grid item xs={12} style={{marginBottom: 6}}>
          <span className={disabled ? 'readOnlyFormLabel' : ''}>{title}:</span>
        </Grid>
        }
        <Grid item xs={12} style={{marginBottom: 12}}>
          <LotusTextInput
            name={`${name}.address1`}
            label={address1Label || 'Address'}
            disabled={disabled}
            required={required}
            maxLength={100}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        </Grid>

        <Grid item xs={12} style={{marginBottom: 12}}>
          <LotusTextInput
            name={`${name}.address2`}
            label="Apt / Lot / Floor"
            disabled={disabled}
            maxLength={100}
          />
        </Grid>

        <Grid item xs={12} style={{marginBottom: 12}}>
          <LotusTextInput
            name={`${name}.city`}
            label="City"
            disabled={disabled}
            required={required}
            maxLength={100}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        </Grid>
      
        <Grid item xs={12} style={{marginBottom: 12}}>
          <Stack direction="row" spacing={1}>
            <LotusStateSelector
              name={`${name}.state`}
              disabled={disabled}
              useSeedData={useSeedData}
              label='State'
              required={required}
              style={{width: 200}}
              dontCheckRequiredFields={dontCheckRequiredFields}
            />
            <LotusTextInput
              name={`${name}.zip`}
              label="Zip Code"
              disabled={disabled}
              required={required}
              style={{width: 290, marginTop: 8}} // undo margin removal so these line up
              maxLength={10}
              dontCheckRequiredFields={dontCheckRequiredFields}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <LotusCountySelector
            name={`${name}.county`}
            disabled={disabled}
            useSeedData={useSeedData}
            label='County'
            required={required}
            state={selectedState}
            dontCheckRequiredFields={dontCheckRequiredFields}
          />
        </Grid>
      </Grid>
  );
}
