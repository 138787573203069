import React, {createContext, useContext, useReducer,} from 'react';
import axios from '../axiosInterceptor';
import { reducer, messageTypes } from './PropertiesContextReducer';
import { useAppStatus } from 'contexts/AppStatusContext';
import { parseApiResult } from 'lib/utils';

const initialState = {
  properties: undefined,
  awsSecrets: undefined
};

const PropertiesContext = createContext();

export const PropertiesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PropertiesContext.Provider value={{ state, dispatch }}>
      {children}
    </PropertiesContext.Provider>
  );
};

export const useProperties = () => {
  const { state, dispatch } = useContext(PropertiesContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadProperties = async (uri) => {
    try {
      addBusyBee('loadProperties');

      let serviceUri = uri || `/api/environment/variables`;

      const response = await axios.get(serviceUri);
      const variables = response.data.variables;

      const propertiesReturned = variables && Object.keys(variables).length > 0;

      if (propertiesReturned) {
        sessionStorage.setItem('CognitoIdentityServiceProvider.clientId', variables['REACT_APP_CLIENT_ID']);
        sessionStorage.setItem('CognitoIdentityServiceProvider.userPoolId', variables['REACT_APP_USER_POOL_ID']);
        
        dispatch({
          type: messageTypes.LOADING_PROPERTIES_SUCCESS,
          payload: {
            properties: variables,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadProperties');
    }
  };

  // To retrieve AWS location service API key and AWS Region
  const loadAwsSecrets = async () => {
    try {
      // Doing this in the background
      //addBusyBee('loadAwsSecrets');

      const response = await axios.get('/api/environment/aws');
      const resultObj = parseApiResult(response.data);

      dispatch({
        type: messageTypes.LOADING_AWS_SECRETS_SUCCESS,
        payload: {
          awsSecrets: resultObj,
        },
      });
      return resultObj;
    }
    catch (error) {
      console.log(error);
      setError(error);
    } finally {
      //removeBusyBee('loadAwsSecrets');
    }
  }

  return {
    ...state,
    loadProperties,
    loadAwsSecrets
  };
};
