import React, { useState } from 'react';
import { Table } from '@lotus/components';
import { useField } from 'formik';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import {Stack} from '@mui/material';
import { Button } from '@lotus/components';
import ActionDialog from 'components/Dialogs/ActionDialog';
import ClientLabResultViewer from './ClientLabResultViewer';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useClient } from 'contexts/ClientContext';
import { CONSTANTS } from './ClientServicesEnums';
import moment from 'moment';

export default function ClientLabResultsList({ testResultConfig, labResults, clientId }) {

    const { verifyPermission } = useAuthState();
    const { upsertClientLabResult } = useClient();

    const [labResultsField] = useField(labResults);
    const [labRecordView, setLabRecordView] = useState();
    const [modalType, setModalType] = useState(CONSTANTS.VIEW_MODAL_TYPE);
    const [rowId, setRowId] = useState();
    const editDisabled = !verifyPermission(PERMISSIONS.ADD_EDIT_DELETE_LAB_RESULTS);

    const closeLabResultViewer = () => {
        setLabRecordView(null);
        setRowId(null);
    };
    
    const updateClientLabResult = (values, type) => {
        upsertClientLabResult(clientId, rowId, type, values);
        setLabRecordView(null);
        setRowId(null);
    };

    const handleRowClicked = (rowData) => {
        const rowId = rowData[0];
        // get lab result using the row id
        setModalType(CONSTANTS.VIEW_MODAL_TYPE);
        setRowId(rowId);
        setLabRecordView(true);
    };

    const getLabResultById = (rowId) => {
        return labResultsField && labResultsField.value ? labResultsField.value.filter(result => result.id === rowId)[0] : null;
    }

    const getEntryMode = (rowId) => {
        const rowObj = getLabResultById(rowId);
        return rowObj && rowObj?.entryMode.toLowerCase();
    }

    // Standard fields
    let columns = [
        {
            name: 'id',
            options: {
                display: 'excluded'
            }
        },
        {
            name: 'clientId',
            options: {
                display: 'excluded'
            }
        },
        {
            label: 'Date Completed',
            name: 'sortable_test_completed_date',
            options: {
                customBodyRender: (value) => {
                    return <div>{value ? moment(value).format("MM/DD/YYYY") : 'N/A'}</div>;
                }
            }
        },
        {
            label: 'Test Name',
            name: 'testName',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Test Result',
            name: 'testResult',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Test Result Modifier',
            name: 'claTestResultModifier',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Test Measure',
            name: 'testMeasure',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        },
        {
            label: 'Entry Mode',
            name: 'entryMode',
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value ? value : 'N/A'
                }
            }
        }
    ];

    if(testResultConfig && testResultConfig?.enabled) {
        if(testResultConfig?.basicConfig) {
            // Configured basic fields
            const basicConfig = [
                {
                    label: 'Test Technology',
                    name: 'testTech',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testTech ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Completed Time',
                    name: 'testCompletedTime',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testCompletedTime ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Status',
                    name: 'testStatus',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testStatus ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Result Type',
                    name: 'testResultType',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testResultType ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Result Text',
                    name: 'testResultText',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testResultText ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Result Numeric',
                    name: 'testResultNumeric',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testResultNumeric ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Result Keyword',
                    name: 'testResultKeyword',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testResultKeyword ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Reference ID',
                    name: 'testReferenceId',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testReferenceId ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Source',
                    name: 'testSource',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testSource ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Facility',
                    name: 'testFacility',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testFacility ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test Completed By',
                    name: 'testCompletedBy',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.testCompletedBy ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Normalcy Status',
                    name: 'normalcyStatus',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.normalcyStatus ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Comments',
                    name: 'comments',
                    options: {
                        sort: false,
                        display: testResultConfig.basicConfig?.comments ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                }
            ];
            columns = columns.concat(basicConfig);
        }
        // Configured HL7 fields
        if(testResultConfig?.hl7Config) {
            const hl7Config = [
                {
                    label: 'Lab Identified Date Of Birth',
                    name: 'labIdentifiedDob',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.labIdentifiedDob ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Lab Identified Gender',
                    name: 'labIdentifiedGender',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.labIdentifiedGender ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Lab Identified LOINC',
                    name: 'labIdentifiedLoinc',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.labIdentifiedLoinc ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Lab Identified LOINC Description',
                    name: 'labIdentifiedLoincDesc',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.labIdentifiedLoincDesc ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Test CPT Code',
                    name: 'testCptCode',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.testCptCode ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Reference Range',
                    name: 'referenceRange',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.referenceRange ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                },
                {
                    label: 'Lab Panel ID',
                    name: 'labPanelId',
                    options: {
                        sort: false,
                        display: testResultConfig.hl7Config?.labPanelId ? false : 'excluded',
                        customBodyRender: (value) => {
                            return value ? value : 'N/A'
                        }
                    }
                }
            ];
            columns = columns.concat(hl7Config);
        }
    }

    return (
    <>
        <LotusFormItem extraWide style={{ marginTop: -50 }}>
            <Stack justifyContent="flex-end" direction="row">
                <Button onClick={() => {setModalType(CONSTANTS.ADD_MODAL_TYPE); setLabRecordView(true);}} plusIcon disabled={editDisabled}>
                Add Record
                </Button>
            </Stack>
        </LotusFormItem>
        <LotusFormItem extraWide>
            <Table
            columns={columns}
            data={labResultsField.value}
            options={{
                pagination: true,
                filter: false,
                search: false,
                searchOpen: false,
                download: false,
                print: false,
                viewColumns: true,
                searchAlwaysOpen: false,
                rowsPerPageOptions: [10, 20, 50],
                textLabels: {
                    body: {
                      noMatch: "Sorry, no lab results found for this client."
                    }
                }
            }}
            handleRowClicked={handleRowClicked}
            />
        </LotusFormItem>

        <ActionDialog
            maxWidth="lg"
            open={Boolean(labRecordView)}
            handleClose={(modalType === CONSTANTS.VIEW_MODAL_TYPE && getEntryMode(rowId) !== CONSTANTS.MANUAL_ENTRY_MODE) ? closeLabResultViewer : null}
            scroll="body"
            content={
                <ClientLabResultViewer
                    labResultId={rowId}
                    modalType={modalType}
                    editDisabled={editDisabled}
                    entryMode={getEntryMode(rowId)}
                    clientId={clientId}
                    labResultObj={modalType === CONSTANTS.VIEW_MODAL_TYPE ? getLabResultById(rowId) : []}
                    testResultConfig={testResultConfig}
                    handleCancel={closeLabResultViewer}
                    handleSave={updateClientLabResult}
              />
            }
        />
    </>
    );
}