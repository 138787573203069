import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { FilesProvider, useFiles } from 'contexts/FilesContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { FilePond, registerPlugin } from 'react-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import './LotusFileUploader.css'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
const defaultAllowedTypeMsg = 'PNG, JPG, or PDF allowed.';

// Register the plugins
registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType)


const PREFIX = 'LotusFileUploader';

const classes = {
  uploadContainer: `${PREFIX}-uploadContainer`,
  dropzoneContainer: `${PREFIX}-dropzoneContainer`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.uploadContainer}`]: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#282c34',
  },

  [`& .${classes.dropzoneContainer}`]: {
    position: 'relative',
    width: '100%',
    '&:hover': {
      outline: 'none !important',
    },
  },

}));

function getUniqueName(fileName, uniqueId) {
  const origFileName = fileName;
  const extIdx = origFileName.lastIndexOf('.');
  const uniqueFileName = `${origFileName.substring(
    0,
    extIdx
  )}.${uniqueId}${origFileName.substring(extIdx)}`;
  const cleanUniqueFileName = uniqueFileName.replace(/[^\w\d_\-\.]+/gi, '');
  return cleanUniqueFileName;
}

const LotusFileUploaderPanel = ({
  agencyId,
  clientId,
  subfolderName,
  existingFiles,
  name,
  disabled,
  maxFiles,
  acceptedFiles, // array like ["image/jpeg", "image/jpg", "image/png",  "application/pdf"]
  onFileAdded,
  onFileDeleted,
  allowedTypesMsg

}) => {
  const { userAgency } = useUserAgency();
  const [files, setFiles] = useState([]);
  const { uploadFile } = useFiles();
  const [uniqueId] = useState(
    `${moment().format('YYYYMMDDHHmmss')}-${uuidv4().slice(0, 8)}`
  );

  return (
    <StyledGrid container className={classes.uploadContainer}>
      <Grid name={name} item xs={12} className={classes.dropzoneContainer}>
        <FilePond
         
          onupdatefiles={setFiles}
          onremovefile={(error, file) => {
            const newFile = {
              displayName: file.filename,
              type: file.fileType,
              file: { name: file.filename },
            };

            if (!error && onFileDeleted) {
              onFileDeleted(newFile);
            } else {
              console.log(error);
            }
          }}
          files={files.length > 0 ? files : existingFiles}
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
              const reader = new FileReader()

              reader.onabort = () => console.log('file reading was aborted')
              reader.onerror = () => {
                console.log('file reading has failed');
                error('file reading has failed');
              }
              reader.onload = () => {
                const binary = reader.result;

                let temp = '';
                let bytes = new Uint8Array(binary);
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                  temp += String.fromCharCode(bytes[i]);
                }
                const base64String = btoa(temp);
                const uniqueName = getUniqueName(file.name, uniqueId);
                uploadFile(userAgency.granteeAgencyId, uniqueName, file.type, binary, clientId, agencyId, subfolderName, load, error);

                const newFile = {
                  displayName: file.name,
                  uniqueName: uniqueName,
                  type: file.type,
                  file: { name: uniqueName },
                };
                if (onFileAdded) {
                  onFileAdded(newFile, base64String);
                }
              }
              reader.readAsArrayBuffer(file);
            },
            revert: (uniqueFileId, load, error) => {
              console.log('file removed ' + uniqueFileId);
              load();
            },
          }}
          acceptedFileTypes={acceptedFiles || [
            'image/jpeg',
            'image/png',
            'application/pdf'
          ]
          }
          allowMultiple={true}
          imageResizeTargetWidth={256}
          instantUpload={true}
          maxFiles={maxFiles || 99}
          maxFileSize={'20MB'}
          disabled={disabled}
          credits
          
          labelTapToUndo=''
          labelTapToRetry=''
          labelTapToCancel=''
          fileValidateTypeLabelExpectedTypes=''
          styleButtonRemoveItemPosition='right'
          styleButtonRevertItemPosition='right'
          name="files"
          iconRemove='<svg aria-hidden="true" width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="black" fill-opacity="0.54"/> 
          </svg>'
          labelIdle={`<div><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" fill="#BDBDBD"/>
          <path d="M22 10H14C12.9 10 12.01 10.9 12.01 12L12 28C12 29.1 12.89 30 13.99 30H26C27.1 30 28 29.1 28 28V16L22 10ZM26 28H14V12H21V17H26V28ZM16 23.01L17.41 24.42L19 22.84V27H21V22.84L22.59 24.43L24 23.01L20.01 19L16 23.01Z" fill="#294055"/></svg></div>
          Click to upload, or drag and drop
          <p>${allowedTypesMsg || defaultAllowedTypeMsg}`}
        />
      </Grid>
    </StyledGrid>
  );
};

export default function LotusFileUploader({ ...props }) {
  return (
    <FilesProvider>
      <LotusFileUploaderPanel {...props} />
    </FilesProvider>
  );
}
