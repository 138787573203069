import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import Typography from '@mui/material/Typography';
import LotusReadOnlyFieldView from './LotusReadOnlyFieldView';
import moment from "moment";
import InputAdornment from '@mui/material/InputAdornment';

export default function LotusTextInput({
  label,
  required,
  dontCheckRequiredFields,
  disabled,
  style,
  maxLength,
  integer,
  floatingPoint,
  minValue,
  maxValue,
  time,
  militaryTime,
  adornment,
  letter,
  ...props
}) {
  const [field, meta, utils] = useField({...props, validate: (val) => {
    const lbl = label || 'Field';
    if (required && !dontCheckRequiredFields && !val) {
      return `${lbl} is required`;
    }
    if (time && val && !moment(val, "h:mm", true).isValid()) {
      return `${lbl} must be a time (h:mm)`;
    }
    if (militaryTime && val && !moment(val, "H:mm", true).isValid()) {
      return `${lbl} must be a time (H:mm)`;
    }
    if (integer && val) {
      const regex = RegExp(`^[0-9]+$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be an integer`;
      }
      if (!isNaN(minValue) && Number(val) < Number(minValue)) {
        return `${lbl} must be >= ${minValue}`;
      }
      if (!isNaN(maxValue) && Number(val) > Number(maxValue)) {
        return `${lbl} must be <= ${maxValue}`;
      }
    }
    if (letter && val) {
      const regex = RegExp(`^[a-zA-Z]+$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be a letter`;
      }
    }
    if (floatingPoint && val) {
      const regex = RegExp(`^([0-9]+([.][0-9]*)?|[.][0-9]+)$`,"g");
      if (!regex.test(val)) {
        return `${lbl} must be a number`;
      }
    }
  }});

  // Stupid react has inputProps and InputProps
  const labelText = label;
  const inputProps = {};
  if (maxLength) {
    inputProps.maxLength = maxLength;
  }
  const InputProps = {};
  if (adornment) {
    InputProps.startAdornment = <InputAdornment disableTypography position="start">{adornment}</InputAdornment>
  }

  return !disabled ? (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        margin="dense"
        size="small"
        required={required}
        style={{width: '100%', marginTop: 0, ...style}} // Mui-TextField has extra 8px margin top which make things look inconsistent with other controls
        onBlur={(e) => {utils.setValue(e.target.value.trim()); utils.setTouched(true, true);}}
        {...field}
        {...props}
        label={labelText}
        disabled={disabled}
        inputProps={inputProps}
        InputProps={InputProps}
      />
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </div>
  ) : (
    <div>
      <LotusReadOnlyFieldView adornment={adornment} label={labelText} value={field.value} multiline={props.multiline} rows={props.rows} style={style} />
      {meta.touched && meta.error && (
        <Typography variant="body2" color="error">
          {meta.error}
        </Typography>
      )}
    </div>
  );
}
