import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useProgramManagement } from 'contexts/ProgramManagementContext';

export default function ProgramInterpretationServicesForm({ handleSubmit }) {

  const { currentProgram, interpretationServices, loadProgramInterpretationServices } = useProgramManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (currentProgram && !interpretationServices) {
      loadProgramInterpretationServices();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (interpretationServices) {
      setInitialValues({
        currentInterpretationServiceNames: interpretationServices.map((s) => {
          return {name: s.name, active: true} || [];
        }),
        addedInterpretationServiceName: '',
      });
    }
  }, [interpretationServices]);

  return (
    <LotusPageSection header="Interpretation Services">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              {interpretationServices && (
                <LotusForm>
                  <LotusFormItem>
                    <GenericListLinker
                      name="currentInterpretationServiceNames"
                      itemName="Service"
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusButton color="primary" variant="contained" type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              )}
            </Form>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}
