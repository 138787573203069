import React, { useEffect, useState } from 'react';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function ConsentTypeLinker() {
  const { userAgency, userAgencyConsentTypes, loadUserAgencyConsentTypes } = useUserAgency();
  const [consentTypesForAgency, setConsentTypesForAgency] = useState();

  useEffect(() => {
    if (userAgency && !userAgencyConsentTypes) {
      loadUserAgencyConsentTypes();
    }
  }, [userAgency, userAgencyConsentTypes]);

  useEffect(() => {
    if (userAgencyConsentTypes) {
      const types = [
        ...userAgencyConsentTypes.filter((act) => !act.isAgencyWide),
      ];
      types.sort((a, b) => (a.name > b.name ? 1 : -1));
      setConsentTypesForAgency(types);
    }
  }, [userAgencyConsentTypes]);

  return consentTypesForAgency && (
    <LotusCheckboxList
      name="selectedConsentTypes"
      items={consentTypesForAgency.map(pt => {return {label: pt.name, value: pt.id}})}
    />
  );
}
