import React, {createContext, useContext, useReducer} from "react";
import axios from '../axiosInterceptor';
import { reducer, messageTypes } from "./AdapEnrollmentsContextReducer";
import { useAppStatus } from "./AppStatusContext";
import { parseApiResult } from "lib/utils";

const initialState = {
  adapProgramEnrollments: undefined,
  appEnrollmentDetails: undefined,
  applications: undefined
};

const AdapEnrollmentsContext = createContext();

export const AdapEnrollmentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AdapEnrollmentsContext.Provider value={{ state, dispatch }}>
      {children}
    </AdapEnrollmentsContext.Provider>
  );
};

export const useAdapEnrollments = () => {
  const { state, dispatch } = useContext(AdapEnrollmentsContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const loadAdapEnrollmentsForClient = async (clientId) => {

    addBusyBee('loadAdapEnrollmentsForClient');

    try {

      const {data} = await axios.post(`/api/clientEnrollments`, {operationName: 'getAdapEnrollments', clientId});
      let { enrollments } = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ADAP_ENROLLMENTS_FOR_CLIENT_SUCCESS,
        payload: {
          adapProgramEnrollments: enrollments
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadAdapEnrollmentsForClient');
    }
  };

  const terminateAdapProgramEnrollment = async (enrollmentValues) => {
    try {
      addBusyBee('terminateAdapProgramEnrollment');

      const toPost = {
        operationName: 'terminateAdapProgramEnrollment',
        enrollment: enrollmentValues,
      };

      const { data } = await axios.post(`/api/clientEnrollments`, toPost);
      const { enrollment } = parseApiResult(data).body;

      dispatch({
        type: messageTypes.TERMINATING_ADAP_PROGRAM_ENROLLMENT_SUCCESS,
        payload: {
          enrollment
        }
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('terminateAdapProgramEnrollment');
    }
  };

  const loadApplicationsForClient = async (clientId) => {

    addBusyBee('loadApplicationsForClient');

    try {
      const { data } = await axios.post('/api/eligibilityApps', { operationName: 'getApplicationsForClient', clientId });
      const applications = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_APPLICATIONS_FOR_CLIENT_SUCCESS,
        payload: {
          applications
        },
      });
    }
    catch( error ) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadApplicationsForClient');
    }
  };

  const loadEnrollmentDetailsForApplication = async (appId) => {

    addBusyBee('loadEnrollmentDetailsForApplication');

    try {
      const {data} = await axios.post(`/api/clientEligibilityApp`, {operationName: 'getApplicationAdapEnrollmentDetails', appId});
      let details = parseApiResult(data).body;
      dispatch({
        type: messageTypes.LOADING_ENROLLMENT_DETAILS_FOR_APPLICATION_SUCCESS,
        payload: {
          appId,
          details
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadEnrollmentDetailsForApplication');
    }
  };

  return {
    ...state,
    loadAdapEnrollmentsForClient,
    terminateAdapProgramEnrollment,
    loadApplicationsForClient,
    loadEnrollmentDetailsForApplication
  };
};
