import React, { createContext, useContext, useReducer } from 'react';
import { reducer, messageTypes } from './ProvidersContextReducer';
import { useAppStatus } from './AppStatusContext';
import axios from '../axiosInterceptor';
import { parseApiResult } from 'lib/utils';

const initialState = {
  individualProviders: undefined,
  organizationProviders: undefined,
  providerDetail: undefined
};

const ProvidersContext = createContext();

export const ProvidersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProvidersContext.Provider value={{ state, dispatch }}>
      {children}
    </ProvidersContext.Provider>
  );
};

export const useProviders = () => {
  const { state, dispatch } = useContext(ProvidersContext);
  const { addBusyBee, removeBusyBee, setError } = useAppStatus();

  const searchIndividualProviders = async (searchStr, limit) => {
    addBusyBee('searchIndividualProviders');

    try {
      const toPost = {
        operationName: 'searchIndividualProviders',
        searchStr: searchStr,
        limit: limit
      };
      const { data } = await axios.post(`api/providers`, toPost);
      const providers = parseApiResult(data).body;

      dispatch({
        type: messageTypes.SEARCHING_INDIVIDUAL_PROVIDERS_SUCCESS,
        payload: {
          individualProviders: providers,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('searchIndividualProviders');
    }
  };

  const loadIndividualProvider = async (id) => {
    addBusyBee('loadIndividualProvider');

    try {
      const toPost = {
        operationName: 'loadIndividualProvider',
        id: id
      };
      const { data } = await axios.post(`api/providers`, toPost);
      const providerRec = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_INDIVIDUAL_PROVIDER_SUCCESS,
        payload: {
          providerDetail: providerRec,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadIndividualProvider');
    }
  }

  const searchOrganizationProviders = async (subtypes, searchStr, limit) => {
    addBusyBee('searchOrganizationProviders');

    try {
      const toPost = {
        operationName: 'searchOrganizationProviders',
        subtypes: subtypes,
        searchStr: searchStr,
        limit: limit
      };
      const { data } = await axios.post(`api/providers`, toPost);
      const providers = parseApiResult(data).body;

      dispatch({
        type: messageTypes.SEARCHING_ORGANIZATION_PROVIDERS_SUCCESS,
        payload: {
          organizationProviders: providers,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('searchOrganizationProviders');
    }
  };

  const loadOrganizationProvider = async (id) => {
    addBusyBee('loadOrganizationProvider');

    try {
      const toPost = {
        operationName: 'loadOrganizationProvider',
        id: id
      };
      const { data } = await axios.post(`api/providers`, toPost);
      const providerRec = parseApiResult(data).body;

      dispatch({
        type: messageTypes.LOADING_ORGANIZATION_PROVIDER_SUCCESS,
        payload: {
          providerDetail: providerRec,
        },
      });
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      removeBusyBee('loadOrganizationProvider');
    }
  }

  return {
    ...state,
    searchIndividualProviders,
    loadIndividualProvider,
    searchOrganizationProviders,
    loadOrganizationProvider
  };
};
