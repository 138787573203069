import LotusStaticForm from "components/form/staticForm/LotusStaticForm";
import LotusSubsectionHeader from "components/headers/LotusSubsectionHeader";
import { useEffect, useState } from "react";
import LotusStackedFormItems from "components/form/LotusStackedFormItems";
import { useFormikContext } from "formik";
import LotusSwitch from "components/form/switch/LotusSwitch";
import LotusSubsectionContent from "components/dataDisplay/content/LotusSubsectionContent";

function MedicationDispensesView() {
  
  const { values } = useFormikContext();

  return (
    <>
      <LotusSubsectionContent excludeTopPadding>
        <LotusSwitch
          name="enabled"
          label="Medication Dispenses Enabled"
        />
      </LotusSubsectionContent>
      {values.enabled && (
        <>
          <LotusSubsectionHeader title='System Category Fields' />
          <LotusSubsectionContent>
            <LotusSwitch
              name="systemCategory.pbmClientId"
              label="PBM Client ID Enabled"
            />
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Type Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="typeCategory.reversal"
                label="Reversal Enabled"
              />
              <LotusSwitch
                name="typeCategory.reversalType"
                label="Reversal Type Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Prescriber Category Fields' />
          <LotusSubsectionContent>
            <LotusSwitch
              name="prescriberCategory.prescriberPhoneNumber"
              label="Prescriber Phone Number Enabled"
            />
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Pharmacy Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="pharmacyCategory.pharmacyName"
                label="Pharmacy Name Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyNo"
                label="Pharmacy No Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyAddress1"
                label="Pharmacy Address1 Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyAddress2"
                label="Pharmacy Address2 Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyCity"
                label="Pharmacy City Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyState"
                label="Pharmacy State Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyZipCode"
                label="Pharmacy Zip Code Enabled"
              />
              <LotusSwitch
                name="pharmacyCategory.pharmacyPhone"
                label="Pharmacy Phone Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Prescription Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="prescriptionCategory.ajudicationDate"
                label="Ajudication Date Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.cycleEndDate"
                label="Cycle End Date Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.rxNumber"
                label="Rx Number Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.rxNumberQual"
                label="Rx Number Qualifier Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.fillNumber"
                label="Fill Number Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.fillDate"
                label="Fill Date Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.datePrescriptionWritten"
                label="Date Prescription Written Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.dispenseAsWrittenCode"
                label="Dispense As Written Code Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.numberRefillsAuthorized"
                label="Number Refills Authorized Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.unitOfMeasure"
                label="Unit Of Measure Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.originalQuantity"
                label="Original Quantity Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.originalDaySupply"
                label="Original Day Supply Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.dawCode"
                label="DAW Code Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.productStrength"
                label="Product Strength Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.dosageFormCode"
                label="Dosage Form Code Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.drugType"
                label="Drug Type Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.drugCategoryCode"
                label="Drug Category Code Enabled"
              />
              <LotusSwitch
                name="prescriptionCategory.drugTier"
                label="Drug Tier Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
          <LotusSubsectionHeader title='Payment Category Fields' />
          <LotusSubsectionContent>
            <LotusStackedFormItems>
              <LotusSwitch
                name="paymentCategory.claimId"
                label="Claim ID Enabled"
              />
              <LotusSwitch
                name="paymentCategory.groupId"
                label="Group ID Enabled"
              />
              <LotusSwitch
                name="paymentCategory.carrierNumber"
                label="Carrier Number Enabled"
              />
              <LotusSwitch
                name="paymentCategory.amountSalesTax"
                label="Amount Sales Tax Enabled"
              />
              <LotusSwitch
                name="paymentCategory.patientPayAmount"
                label="Patient Pay Amount Enabled"
              />
            </LotusStackedFormItems>
          </LotusSubsectionContent>
        </>
      )}
    </>
  );
}

export function GranteeAgencyMedicationDispensesView({
  medicationDispensesConfig,
  handleSave,
  handleCancel
}) {

  const [initialValues, setInitialValues] = useState();
  
  useEffect(() => {
    if (medicationDispensesConfig) {
      const newInitialValues = {
        enabled: medicationDispensesConfig.enabled || false,
        // Medication Dispenses Component - System Category
        systemCategory: {
          pbmClientId: medicationDispensesConfig.systemCategory.pbmClientId || false
        },
        // Medication Dispenses Component - Type Category
        typeCategory: {
          reversal: medicationDispensesConfig.typeCategory.reversal || false,
          reversalType: medicationDispensesConfig.typeCategory.reversalType || false
        },
        // Medication Dispenses Component - Prescriber Category
        prescriberCategory: {
          prescriberPhoneNumber: medicationDispensesConfig.prescriberCategory.prescriberPhoneNumber || false
        },
        // Medication Dispenses Component - Pharmacy Category
        pharmacyCategory: {
          pharmacyName: medicationDispensesConfig.pharmacyCategory.pharmacyName || false,
          pharmacyNo: medicationDispensesConfig.pharmacyCategory.pharmacyNo || false,
          pharmacyAddress1: medicationDispensesConfig.pharmacyCategory.pharmacyAddress1 || false,
          pharmacyAddress2: medicationDispensesConfig.pharmacyCategory.pharmacyAddress2 || false,
          pharmacyCity: medicationDispensesConfig.pharmacyCategory.pharmacyCity || false,
          pharmacyState: medicationDispensesConfig.pharmacyCategory.pharmacyState || false,
          pharmacyZipCode: medicationDispensesConfig.pharmacyCategory.pharmacyZipCode || false,
          pharmacyPhone: medicationDispensesConfig.pharmacyCategory.pharmacyPhone || false
        },
        // Medication Dispenses Component - Prescription Category
        prescriptionCategory: {
          ajudicationDate: medicationDispensesConfig.prescriptionCategory.ajudicationDate || false,
          cycleEndDate: medicationDispensesConfig.prescriptionCategory.cycleEndDate || false,
          rxNumber: medicationDispensesConfig.prescriptionCategory.rxNumber || false,
          rxNumberQual: medicationDispensesConfig.prescriptionCategory.rxNumberQual || false,
          fillNumber: medicationDispensesConfig.prescriptionCategory.fillNumber || false,
          fillDate: medicationDispensesConfig.prescriptionCategory.fillDate || false,
          datePrescriptionWritten: medicationDispensesConfig.prescriptionCategory.datePrescriptionWritten || false,
          dispenseAsWrittenCode: medicationDispensesConfig.prescriptionCategory.dispenseAsWrittenCode || false,
          numberRefillsAuthorized: medicationDispensesConfig.prescriptionCategory.numberRefillsAuthorized || false,
          unitOfMeasure: medicationDispensesConfig.prescriptionCategory.unitOfMeasure || false,
          originalQuantity: medicationDispensesConfig.prescriptionCategory.originalQuantity || false,
          originalDaySupply: medicationDispensesConfig.prescriptionCategory.originalDaySupply || false,
          dawCode: medicationDispensesConfig.prescriptionCategory.dawCode || false,
          productStrength: medicationDispensesConfig.prescriptionCategory.productStrength || false,
          dosageFormCode: medicationDispensesConfig.prescriptionCategory.dosageFormCode || false,
          drugType: medicationDispensesConfig.prescriptionCategory.drugType || false,
          drugCategoryCode: medicationDispensesConfig.prescriptionCategory.drugCategoryCode || false,
          drugTier: medicationDispensesConfig.prescriptionCategory.drugTier || false
        },
        // Medication Dispenses Component - Payment Category
        paymentCategory: {
          claimId: medicationDispensesConfig.paymentCategory.claimId || false,
          groupId: medicationDispensesConfig.paymentCategory.groupId || false,
          carrierNumber: medicationDispensesConfig.paymentCategory.carrierNumber || false,
          amountSalesTax: medicationDispensesConfig.paymentCategory.amountSalesTax || false,
          patientPayAmount: medicationDispensesConfig.paymentCategory.patientPayAmount || false
        }
      };
      setInitialValues(newInitialValues);
    }
  }, [medicationDispensesConfig]);

  return initialValues && (
    <LotusStaticForm
      title="Medication Dispenses Configuration"
      initialFormValues={initialValues}
      handleSave={handleSave}
      handleCancel={handleCancel}
    >
      <div style={{width: 500}}>
        <MedicationDispensesView />
      </div>
    </LotusStaticForm>
  );
}