import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import LotusPage from 'components/widgets/Layout/LotusPage';
import ApplicationTable from './ApplicationTable';
import LotusMainTabSpacer from 'components/widgets/Layout/LotusMainTabSpacer';
import { useAuthState } from 'contexts/AuthProvider';
import { useApplicationSearch } from 'contexts/EligibilityApplicationSearchContext';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function ApplicationList() {
  const [tabs, setTabs] = useState();
  const {view} = useParams();
  const navigate = useNavigate();
  const [tableName, setTableName] = useState();
  const { verifyPermission, user: loggedInUser, tablePreferences, updateTablePreferences } = useAuthState();

  // find the account permissions to view the application list
  const submitter  = verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER);
  const completer = verifyPermission(PERMISSIONS.APPLICATION_COMPLETER);
  const rwProcessorSingleAgency = verifyPermission(PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY);
  const rwProcessorMultiAgency = verifyPermission(PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY);
  const adapProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR);
  const permissions = {
    submitter: submitter, 
    completer: completer, 
    rwProcessorSingleAgency: rwProcessorSingleAgency, 
    rwProcessorMultiAgency: rwProcessorMultiAgency,
    adapProcessor: adapProcessor
  };

  // find the new/pending applications to set the alert icon for case managers and processors
  const { pendingApplicationsCount, loadPendingApplicationsCount} = useApplicationSearch();

  // hmm, always need to get requests to know pending application status to display the badge
  useEffect(() => {
    if (!pendingApplicationsCount) {
      loadPendingApplicationsCount();
    }
  }, []);

  useEffect(() => {
    if (!view) {
      navigate('/applications/list/AllApplications');
    }
    setTabs([
      {
        label: 'All Applications',
        link: '/applications/list/AllApplications'
      },
      {
        label: 'Assigned To Me',
        link: '/applications/list/AssignedToMe',
        hasAlert: pendingApplicationsCount > 0
      }
    ]);
  }, [pendingApplicationsCount]);

  useEffect(() => {
    if (loggedInUser) {
      let preferences = tablePreferences;
      if (!preferences || !preferences['allApplicationsTable'] || !preferences['assignedToMeTable']) {
        const defaultDisplayedColumns = [
          'client_name',
          'original_submission_date',
          'isInitialApplication',
          'statusName',
          'urgency_name',
          'statusKey'
        ];

        preferences = preferences || {};
        if (!preferences['allApplicationsTable']) {
          preferences['allApplicationsTable'] = {columns: [...defaultDisplayedColumns]};
        }
        if (!preferences['assignedToMeTable']) {
          preferences['assignedToMeTable'] = {columns: [...defaultDisplayedColumns]};
        }
        updateTablePreferences(preferences);
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (view) {
      setTableName('allapplications' === view.toLowerCase() ? 'allApplicationsTable' : 'assignedToMeTable');
    }
  }, [view]);

  return tabs && view && pendingApplicationsCount && (
    <LotusPage>
      <LotusPageTitle title="Applications"/>
      <LotusMainTabs
        selectedTabName={view}
        tabs={tabs}
      />
      <LotusMainTabSpacer/>
      {tableName === 'allApplicationsTable' &&
        <ApplicationTable 
          tableName={tableName} 
          permissions={permissions} 
          loggedInUser={loggedInUser} 
        />
      }
      {tableName === 'assignedToMeTable' &&
        <ApplicationTable 
          tableName={tableName} 
          permissions={permissions} 
          loggedInUser={loggedInUser} 
        />
      }
    </LotusPage>
  );
}
