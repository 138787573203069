import React, { useEffect, useState } from 'react';
import Consents from 'components/Consents';
import { useClient } from 'contexts/ClientContext';
import { useProgram } from 'contexts/ProgramContext';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export const ProgramConsentsTab = ({ clientId, programId, disabled }) => {

  const [programConsents, setProgramConsents] = useState();
  const [programConsentTypes, setProgramConsentTypes] = useState();

  const {clientProgramConsents, loadClientProgramConsents, insertClientProgramConsents} = useClient();
  const {currentProgram, setProgram, currentProgramConsentTypeIds, loadProgramConsentTypes} = useProgram();

  useEffect(() => {
    if ((clientId && programId) && !clientProgramConsents) {
      loadClientProgramConsents(clientId, programId);
    }
  }, [clientId, programId]);

  useEffect(() => {
    if (!currentProgram || currentProgram.id !== programId) {
      setProgram(programId);
    }
  }, [programId]);

  useEffect(() => {
    if (currentProgram && currentProgram.id === programId && !currentProgramConsentTypeIds) {
      loadProgramConsentTypes();
    }
  }, [currentProgram]);

  useEffect(() => {
    if (clientProgramConsents) {
      if (clientProgramConsents.length === 1) {
        setProgramConsents(clientProgramConsents[0].data);
      } else {
        setProgramConsents([]);
      }
    }
  }, [programId, clientProgramConsents]);

  useEffect(() => {
    if (currentProgramConsentTypeIds) {
      setProgramConsentTypes(currentProgramConsentTypeIds);
    }
  }, [currentProgramConsentTypeIds]);

  const onConsentAdded = async (consent) => {
    await insertClientProgramConsents(clientId, programId, [consent]);
  };

  return (
    <LotusPageSection header="Consents">
      <Consents
        consents={programConsents}
        programOnly={true}
        consentTypeIds={programConsentTypes}
        onConsentAdded={onConsentAdded}
        disabled={disabled}
      />
    </LotusPageSection>
  );
};
