import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import LotusFileViewer  from 'components/widgets/LotusFileViewer';

export default function DocumentationList ({ clientId, data}) {
  
  const [documentationToView, setDocumentationToView] = useState();
  const [documentationToViewMetaData, setDocumentationToViewMetaData] = useState();
  const [documentationListForTable, setDocumentationListForTable] = useState();

  useEffect(() => {
    if (data) {
      const documentationList = data.map((doc) => {
        let newDoc = {
          displayName: doc.displayName,
          uniqueName: doc.uniqueName,
          type: doc.type,
          dateUploaded: doc.dateUploaded ? doc.dateUploaded : moment(new Date()).format("MM/DD/YYYY")
        };
        return newDoc;
      });
      documentationList.sort((a, b) => (a.dateUploaded > b.dateUploaded ? -1 : 1));
      setDocumentationListForTable(documentationList);
    }
  }, [data]);

  const columns = [
    {
      label: 'File Name',
      name: 'displayName',
      options: {
        sort: false
      },
    },
    {
      label: 'Upload date',
      name: 'dateUploaded',
      options: {
        sort: false
      },
    }
  ];

  const viewClientDocumentationForRow = async (rowData, rowMeta) => {
    const doc = documentationListForTable[rowMeta.dataIndex];
    setDocumentationToView(doc);

    const metaData = {
      field1:{label:'Documentation Type:', value: 'Lab Test Results'},
      field3:{label:'Upload Date:', value: doc?.dateUploaded}
    };
    setDocumentationToViewMetaData(metaData);                                
  };

  const closeDocumentationView = () => {
    setDocumentationToView(null);
    setDocumentationToViewMetaData(null);
  };

  return (
    <div>
      {documentationListForTable && (
        <Table
          columns={columns}
          data={documentationListForTable}
          options={{
            print: false,
            download: false,
            filter: false,
            search: false,
            viewColumns: false,
          }}
          handleRowClicked={viewClientDocumentationForRow}
        />
      )}

      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentationToView) && Boolean(documentationToViewMetaData)}
        content={
          <LotusFileViewer 
            clientId={clientId}
            subfolderName="documents"
            metaData={documentationToViewMetaData} 
            file={[documentationToView]}
            handleClose={closeDocumentationView}
          />
        }
      />
    </div>
  );
};