import React from 'react';
import { useField } from 'formik';
import Switch from '@mui/material/Switch';
import LotusReadOnlyFieldView from '../LotusReadOnlyFieldView';
import { FormControlLabel, Stack, Typography } from '@mui/material';


export default function LotusSwitch({
  name,
  label,
  helperText,
  disabled,
  readOnly,
  handleChange
}) {
  const [field, meta] = useField(name);

  const localHandleChange = (e) => {
    const newValue = e.target.checked;
    if (handleChange) {
      handleChange(newValue);
    }
    field.onChange(e);
  };

  return (
    <Stack style={{width: '100%'}}>
      {!readOnly &&
        <>
          <FormControlLabel
            control={
              <Switch 
                {...field}  
                checked={Boolean(field.value)} 
                onChange={localHandleChange}
                // Note there's other stuff done in the theme style for the switch
              />
            } 
            label={label}
            disabled={disabled} 
            style={{width: 'fit-content', paddingLeft: 4, paddingBottom: 4}}
          />
        </>
      }
      {readOnly &&
        <LotusReadOnlyFieldView label={label} value={field.value ? 'Yes' : 'No'} />
      }
      {!readOnly && !disabled && helperText && (
        <div>
          <Typography variant="helperText" style={{paddingLeft: 60}}>
            {helperText}
          </Typography>
        </div>
      )}
      {meta.touched && meta.error && (
        <div>
          <Typography variant="helperText" color="error" style={{paddingLeft: 60}}>
            {meta.error}
          </Typography>
        </div>
      )}
    </Stack>
  );
}
